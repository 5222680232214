import { ErrorOutlineRounded, InfoOutlined, WarningAmberRounded } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Card, Tooltip, darken, lighten, useTheme } from '@mui/material';
import { MouseEvent } from 'react';

export type SideBarAlertSeverity = 'error' | 'warning' | 'info';

export interface SideBarAlertProps {
  severity: SideBarAlertSeverity;
  title: string;
  message: string;
  /// Only shown when isCompact is true. Uses the message if none is provided
  tooltip?: string;
  isCompact: boolean;
  action?: { title: string; onClick: (e: MouseEvent<HTMLButtonElement>) => void };
}

export function SideBarAlert({ severity, title, message, tooltip, isCompact, action }: SideBarAlertProps) {
  const theme = useTheme();

  if (isCompact) {
    const getAlertBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getAlertColor = theme.palette.mode === 'light' ? darken : lighten;

    return (
      <Tooltip title={tooltip ?? message} placement="right" arrow>
        <Card
          elevation={0}
          sx={{
            backgroundColor: getAlertBackgroundColor(theme.palette[severity].light, 0.9),
            p: 2,
            color: getAlertColor(theme.palette[severity].light, 0.6)
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {getIconForSeverity(severity, theme.palette[severity].main)}
          </Box>
        </Card>
      </Tooltip>
    );
  } else {
    return (
      <Alert severity={severity} icon={getIconForSeverity(severity, undefined)}>
        <AlertTitle>{title}</AlertTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {message}

          {action && (
            <Button
              variant="contained-grey"
              color="inherit"
              size="small"
              sx={{ mt: 2 }}
              onClick={(e) => action?.onClick(e)}
            >
              {action.title}
            </Button>
          )}
        </Box>
      </Alert>
    );
  }
}
function getIconForSeverity(severity: SideBarAlertSeverity, color: string | undefined) {
  switch (severity) {
    case 'warning':
      return <WarningAmberRounded fontSize="small" sx={{ color }} />;
    case 'error':
      return <ErrorOutlineRounded fontSize="small" sx={{ color }} />;
    case 'info':
      return <InfoOutlined fontSize="small" sx={{ color }} />;
  }
}
