import { compareTimeOfDays, Day, dayToDate, timeOfDayToDate, WorkIcons } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService, DateService } from '@/services';
import { WorkDataStore } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { isToday as dateIsToday, differenceInCalendarDays, isBefore } from 'date-fns';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UserDashboardCalendarAnnotationInfo } from '../shared';
import {
  UserDashboardCalendarDayAllDayItemInfo,
  UserDashboardCalendarDayTimedItemInfo
} from './UserDashboardCalendarDayItems';

export type UserDashboardCalendarDaySpecificDateDataState = 'loading' | 'error' | 'fulfilled';

export interface UserDashboardCalendarDaySpecificDateViewModel {
  readonly date: Date;
  readonly isToday: boolean;
  readonly cycleDayName: string;
  readonly state: UserDashboardCalendarDaySpecificDateDataState;
  readonly scheduleCycleWithDraftId: string | undefined;
  readonly inboxWorkCount: number;
  showPastItems: boolean;
  readonly annotations: UserDashboardCalendarAnnotationInfo[];
  readonly allDayItems: UserDashboardCalendarDayAllDayItemInfo[];
  readonly pastItems: UserDashboardCalendarDayTimedItemInfo[];
  readonly currentOrUpcomingItems: UserDashboardCalendarDayTimedItemInfo[];
  readonly upcomingItems: UserDashboardCalendarDayTimedItemInfo[];
  readonly canCreateWork: boolean;
  readonly canPublishWork: boolean;
  readonly isReadOnly: boolean;

  reloadData(): Promise<void>;
}

export abstract class AppUserDashboardCalendarDaySpecificDateViewModel
  implements UserDashboardCalendarDaySpecificDateViewModel
{
  @observable protected _now: Date;
  @observable private _showPastItems = false;

  protected constructor(
    protected readonly _day: Day,
    public readonly isReadOnly: boolean,
    private readonly _reloadDayData: () => Promise<void>,
    private readonly _getScheduleCycleWithDraftId: () => string | undefined,
    protected readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);

    this._now = _dateService.now;
    setInterval(() => runInAction(() => (this._now = _dateService.now)), 60 * 1_000);
  }

  protected abstract get courseSections(): CourseSectionDetails[];

  @computed
  protected get courseSectionsById(): Map<string, CourseSectionDetails> {
    return new Map(this.courseSections.map((cs) => [cs.courseSection!.id, cs]));
  }

  @computed
  protected get workIcons(): WorkIcons {
    return this._workStore.workIcons.data;
  }

  @computed
  get date(): Date {
    return dayToDate(this._day);
  }

  @computed
  get isToday(): boolean {
    return dateIsToday(this.date);
  }

  @computed
  get scheduleCycleWithDraftId(): string | undefined {
    return this._getScheduleCycleWithDraftId();
  }

  abstract get inboxWorkCount(): number;

  @computed
  get showPastItems(): boolean {
    return (
      this._settings.calendarDayOptions.alwaysShowCurrentDatePastItems ||
      differenceInCalendarDays(this.date, this._dateService.now) < 0 ||
      this._showPastItems
    );
  }

  set showPastItems(value: boolean) {
    this._showPastItems = value;
  }

  abstract get cycleDayName(): string;

  abstract get annotations(): UserDashboardCalendarAnnotationInfo[];

  abstract get allDayItems(): UserDashboardCalendarDayAllDayItemInfo[];

  abstract get timedItems(): UserDashboardCalendarDayTimedItemInfo[];

  abstract get state(): UserDashboardCalendarDaySpecificDateDataState;

  @computed
  get pastItems(): UserDashboardCalendarDayTimedItemInfo[] {
    if (differenceInCalendarDays(this.date, this._dateService.now) < 0) {
      return this.timedItems;
    }

    if (differenceInCalendarDays(this.date, this._dateService.now) > 0) {
      return [];
    }

    return this.timedItems.filter((item) => isBefore(timeOfDayToDate(item.value.endTime), this._now));
  }

  @computed
  protected get allCurrentOrUpcomingItems(): UserDashboardCalendarDayTimedItemInfo[] {
    if (differenceInCalendarDays(this.date, this._dateService.now) < 0) {
      return [];
    }

    if (differenceInCalendarDays(this.date, this._dateService.now) > 0) {
      return this.timedItems;
    }

    return this.timedItems.filter((item) => !isBefore(timeOfDayToDate(item.value.endTime), this._now));
  }

  @computed
  get currentOrUpcomingItems(): UserDashboardCalendarDayTimedItemInfo[] {
    if (!this.isToday) {
      return [];
    }

    const items = this.allCurrentOrUpcomingItems;
    if (items.length === 0) {
      return [];
    }
    const startTime = items[0].value.startTime;

    if (!isBefore(timeOfDayToDate(startTime), this._now)) {
      // If next item is in the future, returning all items starting at the same time.
      return items.filter((item) => compareTimeOfDays(item.value.startTime, startTime) === 0);
    } else {
      // if next item is currently happening, returning all items also happening.
      return items.filter((item) => isBefore(timeOfDayToDate(item.value.startTime), this._now));
    }
  }

  @computed
  get upcomingItems(): UserDashboardCalendarDayTimedItemInfo[] {
    return this.allCurrentOrUpcomingItems.slice(this.currentOrUpcomingItems.length);
  }

  abstract get canCreateWork(): boolean;

  abstract get canPublishWork(): boolean;

  async reloadData() {
    await this._reloadDayData();
  }
}
