import { alpha, Box, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface StudentEmotionalSurveyCardOptionProps {
  sx?: SxProps;
  className?: string;
  isSelected: boolean;
  emoji: string;
  onSelect: () => void;
  disabled?: boolean;
}

export function StudentEmotionalSurveyCardOption({
  isSelected,
  emoji,
  sx = [],
  className,
  onSelect,
  disabled
}: StudentEmotionalSurveyCardOptionProps) {
  const theme = useTheme();

  return (
    <Box
      className={className}
      sx={{
        ...sx,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: isSelected ? theme.palette.primary.main : 'transparent',
        backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
        overflow: 'hidden'
      }}
    >
      <CardActionArea onClick={onSelect} disabled={disabled}>
        <Stack
          direction="column"
          spacing={0}
          sx={{
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: 34
            }}
          >
            {emoji}
          </Typography>
        </Stack>
      </CardActionArea>
    </Box>
  );
}
