export const notConcurrent = <T, Fn extends (...args: Parameters<Fn>) => PromiseLike<T>>(fn: Fn) => {
  let promise: Promise<T> | false = false;

  return (...args: Parameters<Fn>) => {
    if (!promise) {
      promise = (async () => {
        try {
          return await fn(...args);
        } finally {
          promise = false;
        }
      })();
    }
    return promise;
  };
};
