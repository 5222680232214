import { Locale } from './Locale';

export function localizedCompare(
  value1: string,
  value2: string,
  locale: Locale,
  order: 'asc' | 'desc' = 'asc',
  emptyValueOrder: 'asc' | 'desc' = 'asc'
) {
  if (value1.length === 0 || value2.length === 0) {
    const v = value1.length == 0 ? -1 : 1;
    return v * (emptyValueOrder === 'desc' ? -1 : 1);
  }

  const result = value1
    .toLocaleLowerCase(locale)
    .localeCompare(value2.toLocaleLowerCase(locale), locale, { sensitivity: 'base' });

  return result * (order === 'asc' ? 1 : -1);
}

export interface LocalizedCompareProperty {
  value1: string;
  value2: string;
  order?: 'asc' | 'desc';
  emptyValueOrder?: 'asc' | 'desc';
}

export function localizedCompareWithProperties(properties: LocalizedCompareProperty[], locale: Locale) {
  for (const property of properties) {
    const result = localizedCompare(property.value1, property.value2, locale, property.order, property.emptyValueOrder);
    if (result != null) {
      return result;
    }
  }

  return 0;
}
