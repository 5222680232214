import { useServices } from '@/hooks';
import { WorkDetailsDueDateViewModel } from '@/viewmodels';
import { OpenInNewRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { AlertDialog } from '../../utils';
import { WorkDetailsDueDateCount } from './WorkDetailsDueDateCount';

export interface WorkDetailsDueDateProps {
  sx?: SxProps;
  className?: string;
  viewModel: WorkDetailsDueDateViewModel;
  disabled?: boolean;
}

export const WorkDetailsDueDate = observer(
  ({ sx = [], className, viewModel, disabled = false }: WorkDetailsDueDateProps) => {
    const { dateService, localization } = useServices();
    const strings = localization.localizedStrings.work.details;

    const tooltipTitle = viewModel.hasDueDate
      ? format(viewModel.dueDate!, viewModel.isDueAllDay ? strings.dueDateAllDayFormat : strings.dueDateFullFormat)
      : strings.noDueDateSubtitle;

    const dateTitle = viewModel.hasDueDate
      ? strings.dueDateFormat(viewModel.dueDate!, dateService)
      : strings.noDueDateSubtitle;

    return (
      <>
        <Stack sx={sx} className={className} spacing={2}>
          <Tooltip title={tooltipTitle} placement="top" disableInteractive>
            <Stack>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: '500',
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {strings.dueDateSectionTitle}
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500'
                }}
              >
                {dateTitle}
              </Typography>

              {!viewModel.isDueAllDay && viewModel.dueDate != null && (
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: '500'
                  }}
                >
                  {format(viewModel.dueDate, 'p')}
                </Typography>
              )}
            </Stack>
          </Tooltip>

          {!viewModel.isCancelled && !viewModel.isCompleted && (
            <>
              <WorkDetailsDueDateCount viewModel={viewModel} />

              {!viewModel.isReadOnly && (
                <LoadingButton
                  variant="contained"
                  color="success"
                  onClick={() => void viewModel.completeWork()}
                  disabled={disabled || viewModel.isApplying}
                  loading={viewModel.isApplying}
                  endIcon={viewModel.isStatusChangedExternally && <OpenInNewRounded fontSize="small" />}
                >
                  {strings.doneButtonLabel}
                </LoadingButton>
              )}
            </>
          )}
        </Stack>
        {viewModel.showNoExternalUrlAlert && (
          <AlertDialog
            isOpen={true}
            title={strings.noExternalUrlTitle}
            message={strings.noExternalUrlMessage}
            onSubmit={() => (viewModel.showNoExternalUrlAlert = false)}
          />
        )}
      </>
    );
  }
);
