import { UserDashboardCalendarMonthDayItemInfo } from '@/viewmodels';
import { ReactElement } from 'react';
import { UserDashboardCalendarAnnotationView } from '../shared';
import { UserDashboardCalendarMonthCalendarEventView } from './UserDashboardCalendarMonthCalendarEventView';
import { UserDashboardCalendarMonthNoteView } from './UserDashboardCalendarMonthNoteView';
import { UserDashboardCalendarMonthPeriodView } from './UserDashboardCalendarMonthPeriodView';
import { UserDashboardCalendarMonthPlannedWorkView } from './UserDashboardCalendarMonthPlannedWorkView';
import { UserDashboardCalendarMonthWorkView } from './UserDashboardCalendarMonthWorkView';

export function renderUserDashboardCalendarMonthItem(item: UserDashboardCalendarMonthDayItemInfo): ReactElement {
  switch (item.case) {
    case 'annotation':
      return <UserDashboardCalendarAnnotationView key={item.value.id} annotation={item.value} />;

    case 'period':
      return <UserDashboardCalendarMonthPeriodView key={item.value.id} period={item.value} />;

    case 'note':
      return <UserDashboardCalendarMonthNoteView key={item.value.id} note={item.value} />;

    case 'work':
    case 'publishedWork':
      return <UserDashboardCalendarMonthWorkView key={item.value.id} work={item} />;

    case 'plannedWork':
      return <UserDashboardCalendarMonthPlannedWorkView key={item.value.id} plannedWork={item.value} />;

    case 'calendarEvent':
      return <UserDashboardCalendarMonthCalendarEventView key={item.value.id} event={item.value} />;
  }
}
