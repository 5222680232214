import { useSync, useViewModel } from '@/hooks';
import { AddRounded, EmailRounded } from '@mui/icons-material';
import { Alert, Box, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { UpdatablePresenter } from '../../../utils';
import { AdminContentCard } from '../../shared';
import { AdminSchoolInviteTeachersDialog } from '../AdminSchoolInviteTeachersDialog';
import { AdminSchoolTeachersTable } from './AdminSchoolTeachersTable';

interface LocationState {
  adminSchoolTeachersListPageShowImport?: boolean;
}

export interface AdminSchoolTeachersListPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolTeachersListPage = observer(({ sx = [], className }: AdminSchoolTeachersListPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const [showInviteTeachersMenu, setShowInviteTeachersMenu] = useState<HTMLElement>();
  const [sendInviteEmailResult, setSendInviteEmailResult] = useState<'success' | 'error'>();

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();

  const viewModel = useViewModel((viewModels) => viewModels.createAdminTeachersListViewModel(schoolId), [schoolId]);
  const strings = LocalizedStrings.admin.teachersList;

  function showImportDialog() {
    setShowInviteTeachersMenu(undefined);

    const newState: LocationState = { ...state, adminSchoolTeachersListPageShowImport: true };
    navigate(location, { state: newState });
  }

  function onInviteTeachersActionClick(e: MouseEvent<HTMLButtonElement>) {
    if (!viewModel.hasData) {
      return;
    }

    if (viewModel.teachersCount > 0) {
      setShowInviteTeachersMenu(e.currentTarget);
    } else {
      showImportDialog();
    }
  }

  async function sendEmailInviteToTeachers(teacherId: string | undefined) {
    setShowInviteTeachersMenu(undefined);
    try {
      await viewModel.sendEmailInvitationToTeachers(teacherId != null ? [teacherId] : []);
      setSendInviteEmailResult('success');
    } catch (e) {
      setSendInviteEmailResult('error');
      captureException(e);
    }
  }

  useSync('admin-teachers-list', () => viewModel.reloadData(), [viewModel]);

  return (
    <Box sx={sx} className={className}>
      <AdminContentCard
        title={strings.title()}
        searchValue={viewModel.hasData ? viewModel.searchText : undefined}
        onSearch={(value) => (viewModel.searchText = value)}
        actions={[
          {
            label: { case: 'text', value: strings.inviteActionTitle(), icon: <EmailRounded /> },
            onPress: onInviteTeachersActionClick,
            requiresActiveSubscription: true,
            isHidden: viewModel.isReadOnly || !viewModel.hasData
          }
        ]}
      >
        <UpdatablePresenter
          sx={{ px: 2, pt: 1, overflow: 'hidden' }}
          viewModel={viewModel}
          renderData={() => (
            <Box
              sx={{
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <AdminSchoolTeachersTable
                viewModel={viewModel}
                showImportTeachers={showImportDialog}
                sendTeacherInviteEmail={sendEmailInviteToTeachers}
              />
            </Box>
          )}
        />
      </AdminContentCard>
      {state.adminSchoolTeachersListPageShowImport === true && (
        <AdminSchoolInviteTeachersDialog isOpen={true} schoolId={schoolId} />
      )}
      <Menu
        open={showInviteTeachersMenu != null}
        anchorEl={showInviteTeachersMenu}
        onClose={() => setShowInviteTeachersMenu(undefined)}
      >
        <MenuItem onClick={showImportDialog}>
          <ListItemIcon>
            <AddRounded />
          </ListItemIcon>
          <ListItemText>{strings.inviteNewTeachersActionTitle()}</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => void sendEmailInviteToTeachers(undefined)}>
          <ListItemIcon>
            <EmailRounded />
          </ListItemIcon>
          <ListItemText>{strings.inviteExistingTeachersActionTitle()}</ListItemText>
        </MenuItem>
      </Menu>
      <Snackbar
        open={sendInviteEmailResult === 'error'}
        autoHideDuration={6_000}
        onClose={() => setSendInviteEmailResult(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSendInviteEmailResult(undefined)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {strings.inviteExistingTeachersErrorMessage()}
        </Alert>
      </Snackbar>
      <Snackbar
        open={sendInviteEmailResult === 'success'}
        autoHideDuration={3_000}
        onClose={() => setSendInviteEmailResult(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSendInviteEmailResult(undefined)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {strings.inviteExistingTeachersSuccessMessage()}
        </Alert>
      </Snackbar>
    </Box>
  );
});
