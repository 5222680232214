import i18n from 'translate';
import { WorkEditStrings } from './WorkEditStrings';

export const WorkAttachmentsAddFileDialogStrings = {
  title: () => i18n.t('work.attachments.addFileDialog.title'),
  cancelButtonTitle: () => i18n.t('work.attachments.addFileDialog.cancelButtonTitle')
};

export const WorkAttachmentsAddLinkDialogStrings = {
  inputPlaceholder: () => i18n.t('work.attachments.addLinkDialog.inputPlaceholder'),
  submitButtonTitle: () => i18n.t('work.attachments.addLinkDialog.submitButtonTitle'),
  title: () => i18n.t('work.attachments.addLinkDialog.title')
};

export const WorkAttachmentsStrings = {
  addAttachmentSectionTitle: () => i18n.t('work.attachments.addAttachmentSectionTitle'),
  addFileAttachmentOptionTitle: () => i18n.t('work.attachments.addFileAttachmentOptionTitle'),
  addFileDialog: WorkAttachmentsAddFileDialogStrings,
  addLinkAttachmentOptionTitle: () => i18n.t('work.attachments.addLinkAttachmentOptionTitle'),
  addLinkDialog: WorkAttachmentsAddLinkDialogStrings
};

export const WorkCreateOptionsMenuStrings = {
  noteOption: () => i18n.t('work.createOptionsMenu.noteOption'),
  pasteOption: () => i18n.t('work.createOptionsMenu.pasteOption'),
  personalTaskOption: () => i18n.t('work.createOptionsMenu.personalTaskOption'),
  publishedTaskOption: () => i18n.t('work.createOptionsMenu.publishedTaskOption')
};

export const WorkStrings = {
  attachments: WorkAttachmentsStrings,
  createOptionsMenu: WorkCreateOptionsMenuStrings,
  edit: WorkEditStrings
};
