import { ScheduleCycleCreateCopyDialogViewModel } from '@/viewmodels';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Subheader } from '../../../utils';
import { ScheduleCycleCreateCopyKeepSection, ScheduleCycleDetailsEditDateSection } from './details';

export interface ScheduleCycleCreateCopyProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleCreateCopyDialogViewModel;
}

export const ScheduleCycleCreateCopy = observer(({ sx = [], className, viewModel }: ScheduleCycleCreateCopyProps) => {
  const strings = LocalizedStrings.scheduleCycle.create;

  return (
    <Stack sx={{ ...sx }} className={className} spacing={1}>
      <Stack spacing={1}>
        <Subheader>{strings.scheduleNameHeader()}</Subheader>
        <TextField
          autoFocus
          value={viewModel.name}
          label={strings.scheduleNamePlaceholder()}
          onChange={(e) => (viewModel.name = e.target.value)}
          size="small"
        />
      </Stack>

      <ScheduleCycleDetailsEditDateSection
        startDate={viewModel.startDate}
        endDate={viewModel.endDate}
        onStartDateValueChanged={(v) => (viewModel.startDate = v)}
        onEndDateValueChanged={(v) => (viewModel.endDate = v)}
      />

      <ScheduleCycleCreateCopyKeepSection viewModel={viewModel} />
    </Stack>
  );
});
