import { UserTransportService } from '@/transports';
import { EmotionalPulse } from '@buf/studyo_studyo-today-sel.bufbuild_es/studyo/today/sel/v1/resources/emotional_pulse_pb';
import { BaseOptionalLoadableValue, LoadableValue } from './Loadable';

export type CurrentUserEmotionalPulseLoadable = LoadableValue<EmotionalPulse | undefined>;

export class AppCurrentUserEmotionalPulseLoadable
  extends BaseOptionalLoadableValue<EmotionalPulse>
  implements CurrentUserEmotionalPulseLoadable
{
  constructor(private readonly _userTransport: UserTransportService) {
    super();
  }

  protected async loadData(): Promise<EmotionalPulse | undefined> {
    return this._userTransport.getCurrentEmotionalPulse();
  }
}
