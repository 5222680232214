import { CycleDayEffect } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/cycle_day_effect_pb';
import i18n from 'translate';

export const CycleDayEffectStrings = {
  title: {
    [CycleDayEffect.PUSH]: () => i18n.t('models.cycleDayEffect.push'),
    [CycleDayEffect.RESET]: () => i18n.t('models.cycleDayEffect.reset'),
    [CycleDayEffect.SKIP]: () => i18n.t('models.cycleDayEffect.skip')
  } as Record<CycleDayEffect, () => string>
};

export const ExternalSourceStrings = {
  sourceName: {
    classroom: () => i18n.t('models.externalSource.sourceName.classroom'),
    'google-calendar': () => i18n.t('models.externalSource.sourceName.googleCalendar'),
    'today-curriculum': () => i18n.t('models.externalSource.sourceName.todayCurriculum'),
    'today-schools': () => i18n.t('models.externalSource.sourceName.todaySchools')
  } as Record<string, () => string>
};

export const ModelsStrings = {
  cycleDayEffect: CycleDayEffectStrings,
  externalSource: ExternalSourceStrings
};
