import { createContext } from 'react';
import { UserDashboardInfo } from '../../../../models';

export interface ScheduleCycleUserDashboardValues {
  readonly plannerId: string | undefined;
  readonly dashboard: UserDashboardInfo;
  readonly scheduleCycleId: string;
}

export const ScheduleCycleUserDashboardContext = createContext<ScheduleCycleUserDashboardValues>({
  plannerId: '',
  dashboard: { id: '', kind: 'planner' },
  scheduleCycleId: ''
});
