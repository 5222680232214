import { StudentInsightsWorkViewModel } from '@/viewmodels';
import { css } from '@emotion/css';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { WorkIcon } from '../../../lists';

export interface StudentInsightsWorksTableWorkDetailsCellProps {
  sx?: SxProps;
  className?: string;
  work: StudentInsightsWorkViewModel;
}

export function StudentInsightsWorksTableWorkDetailsCell({
  sx = [],
  className,
  work
}: StudentInsightsWorksTableWorkDetailsCellProps) {
  const isCompleted = work.completionDate != null;
  const titleStyle = css([
    {
      height: '100%',
      flex: 1,
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  ]);

  return (
    <Stack
      direction="row"
      spacing={1}
      className={className}
      sx={{
        alignItems: 'center',
        ...sx,
        width: '100%',
        display: 'flex',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word'
      }}
    >
      <WorkIcon icon={work.icon} size={36} />
      <Typography
        variant="body1"
        className={titleStyle}
        sx={{
          color: (theme) => theme.palette.text[isCompleted ? 'secondary' : 'primary']
        }}
      >
        {work.title}
      </Typography>
    </Stack>
  );
}
