import { Alert, Button } from '@mui/material';
import { ReactNode } from 'react';

export interface WorkStatusBannerProps {
  readonly isReadOnly: boolean;
  readonly actionIsEnabled: boolean;
  readonly actionTitle: string;
  readonly actionEndIcon?: ReactNode;
  readonly actionOnClick: () => void;
  readonly title: string;
  readonly kind: 'error' | 'success';
}

export const WorkStatusBanner = ({
  kind,
  title,
  actionIsEnabled,
  actionEndIcon,
  actionOnClick,
  actionTitle,
  isReadOnly
}: WorkStatusBannerProps) => {
  return (
    <Alert
      severity={kind}
      action={
        !isReadOnly && (
          <Button
            color="inherit"
            size="small"
            disabled={!actionIsEnabled}
            endIcon={actionEndIcon}
            onClick={() => actionOnClick()}
          >
            {actionTitle}
          </Button>
        )
      }
    >
      {title}
    </Alert>
  );
};
