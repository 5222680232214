import { PlannerTransportService } from '@/transports';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type PlannersLoadable = LoadableMap<Planner>;

export class AppPlannersLoadable extends BaseLoadableMap<Planner> implements PlannersLoadable {
  constructor(private readonly _plannerTransport: PlannerTransportService) {
    super();
  }

  protected async loadData(): Promise<Map<string, Planner>> {
    const [planners, demoPlanners] = await Promise.all([
      this._plannerTransport.getPlanners(),
      this._plannerTransport.getDemoPlanners()
    ]);
    const allPlanners = new Map<string, Planner>();

    planners.forEach((planner) => {
      allPlanners.set(planner.id, planner);
    });

    demoPlanners.forEach((planner) => {
      allPlanners.set(planner.id, planner);
    });

    return allPlanners;
  }
}
