import { PlannerItemsItem, PlannerItemsViewModel } from '@/viewmodels';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { Observer, observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';
import { List } from '../../lists';
import { PlannerItemsEmptyIndicator } from './PlannerItemsEmptyIndicator';
import { PlannerItemsHeader } from './PlannerItemsHeader';
import { PlannerItemsNoResultsIndicator } from './PlannerItemsNoResultsIndicator';
import { PlannerItemsNoSearchResultsIndicator } from './PlannerItemsNoSearchResultsIndicator';
import { PlannerItemsNoteView } from './PlannerItemsNoteView';
import { PlannerItemsPublishedWorkView } from './PlannerItemsPublishedWorkView';
import { PlannerItemsWorkView } from './PlannerItemsWorkView';

export interface PlannerItemsProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerItemsViewModel;
}

export const PlannerItems = observer(({ sx = [], className, viewModel }: PlannerItemsProps) => {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PlannerItemsHeader sx={{ p: 2 }} viewModel={viewModel} />
      <Observer>
        {() => {
          const dateFiltersTitle = titleForDateFilters(viewModel.minimumDate, viewModel.maximumDate);

          return dateFiltersTitle != null ? (
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                px: 3
              }}
            >
              {dateFiltersTitle}
            </Typography>
          ) : null;
        }}
      </Observer>
      <Observer>
        {() => (
          <List
            sx={{ width: '100%', height: '100%' }}
            keyForItem={(section, row) => keyForItem(section, row, viewModel)}
            sections={viewModel.sections}
            renderItem={(section, row) => renderItem(section, row, viewModel)}
            rowHeightForItem={(section, row) => heightForItemAtRow(section, row, viewModel, isExtraSmallScreen)}
          />
        )}
      </Observer>
    </Box>
  );
});

function keyForItem(section: number, row: number, viewModel: PlannerItemsViewModel): string {
  const { upcomingItems, pastItems, searchText, maximumDate, minimumDate } = viewModel;

  if (upcomingItems.length === 0 && pastItems.length === 0 && section === 0 && row === 0) {
    if (searchText.length > 0) {
      return 'no-search-results';
    } else if (minimumDate != null || maximumDate != null) {
      return 'no-results';
    } else {
      return 'empty';
    }
  }

  const item = section === 0 ? upcomingItems[row] : pastItems[row];
  return item.value.id;
}

function renderItem(section: number, row: number, viewModel: PlannerItemsViewModel) {
  const { upcomingItems, pastItems, searchText, maximumDate, minimumDate } = viewModel;

  if (upcomingItems.length === 0 && pastItems.length === 0 && section === 0 && row === 0) {
    if (searchText.length > 0) {
      return <PlannerItemsNoSearchResultsIndicator searchText={searchText} />;
    } else if (minimumDate != null || maximumDate != null) {
      return <PlannerItemsNoResultsIndicator />;
    } else {
      return <PlannerItemsEmptyIndicator />;
    }
  }

  const item = section === 0 ? upcomingItems[row] : pastItems[row];
  switch (item.kind) {
    case 'work':
      return (
        <PlannerItemsWorkView
          key={item.value.id}
          work={item.value}
          displayCourseColor={viewModel.displayCourseColor}
          isReadOnly={!viewModel.canCreateItem}
        />
      );

    case 'note':
      return (
        <PlannerItemsNoteView
          key={item.value.id}
          note={item.value}
          displayCourseColor={viewModel.displayCourseColor}
          isReadOnly={!viewModel.canCreateItem}
        />
      );

    case 'publishedWork':
      return (
        <PlannerItemsPublishedWorkView
          key={item.value.id}
          work={item.value}
          displayCourseColor={viewModel.displayCourseColor}
          isReadOnly={!viewModel.canCreateItem}
        />
      );
  }
}

function titleForDateFilters(minimumDate: Date | undefined, maximumDate: Date | undefined): string | undefined {
  const strings = LocalizedStrings.planner.items;
  if (minimumDate != null && maximumDate != null) {
    return strings.minimumAndMaximumFilterLabel(format(minimumDate, 'PPP'), format(maximumDate, 'PPP'));
  } else if (minimumDate != null) {
    return strings.minimumDateOnlyFilterLabel(format(minimumDate, 'PPP'));
  } else if (maximumDate != null) {
    return strings.maximumDateOnlyFilterLabel(format(maximumDate, 'PPP'));
  }

  return undefined;
}

function heightForItemAtRow(section: number, row: number, viewModel: PlannerItemsViewModel, isSmallScreen: boolean) {
  const { upcomingItems, pastItems } = viewModel;

  if (upcomingItems.length === 0 && pastItems.length === 0 && section === 0 && row === 0) {
    return ItemHeights.emptyIndicator;
  }

  const item = section === 0 ? upcomingItems[row] : pastItems[row];

  if (item.kind === 'note') {
    if (isSmallScreen && !item.value.hasDate) {
      return ItemHeights.noteSmall;
    }

    return ItemHeights.note;
  }

  const hasDescription = (getDescriptionForItem(item) ?? '').length > 0;
  const isScheduled = getItemIsScheduled(item);

  if (isSmallScreen) {
    if (hasDescription && item.value.hasDate) {
      return ItemHeights.threeLines;
    } else if (item.value.hasDate || hasDescription) {
      return ItemHeights.twoLine;
    } else {
      return ItemHeights.singleLine;
    }
  }

  if (hasDescription && isScheduled) {
    return ItemHeights.threeLines;
  }
  return hasDescription || isScheduled ? ItemHeights.twoLine : ItemHeights.singleLine;
}

function getDescriptionForItem(item: PlannerItemsItem) {
  switch (item.kind) {
    case 'publishedWork':
      return item.value.publishedWork.description?.text;
    case 'work':
      return item.value.work.description?.text;
    case 'note':
      return item.value.note.text?.text;
  }
}

function getItemIsScheduled(item: PlannerItemsItem) {
  switch (item.kind) {
    case 'publishedWork': {
      const { publishedWork } = item.value;
      return publishedWork.status === PublishedWorkStatus.SCHEDULED && publishedWork.scheduledPublishTime != null;
    }

    case 'work':
    case 'note':
      return false;
  }
}

const ItemHeights = {
  emptyIndicator: 48,
  threeLines: 96,
  twoLine: 68,
  singleLine: 60,
  note: 64,
  noteSmall: 52
};
