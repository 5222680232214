import { usePrevious } from '@/hooks';
import { ChipProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { find } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ChoicePickerInline } from './ChoicePickerInline';
import { ChoicePickerNormal } from './ChoicePickerNormal';
import { RadioChoicePicker } from './RadioChoicePicker';

export interface ChoicePickerOption<T> {
  key: string;
  value: T;
  label: string | ReactNode;
  icon?: (sx: SxProps) => ReactElement;
  isDefaultSelected?: boolean;
  isHighlighted?: boolean;
  isEnabled?: boolean;
}

export type ChoicePickerVariant = 'normal' | 'inline' | 'radio';

export interface ChoicePickerProps<T> {
  className?: string;

  /**
   * The options to display
   */
  options: ChoicePickerOption<T>[];

  /**
   * The label. Optional.
   */
  label?: string | JSX.Element;

  /**
   * Indicates to disable the choice picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Indicates to hide all the non-selected options when selecting an option. Optional.
   */
  hideOptionsWhenSelected?: boolean;

  /**
   * The variant to display. Optional.
   * The `normal` variant will use the `ChoicePickerNormal` component.
   * The `inline` variant will use the `ChoicePickerInline` component.
   */
  variant?: ChoicePickerVariant;

  /**
   * Current selected value. If set, the current selection is not controlled by this component.
   */
  value?: string | null;

  /**
   * Callback when selecting an option. Optional.
   * @param option The selected option.
   */
  onSelect?: (option?: ChoicePickerOption<T>) => void;

  /**
   * The props to pass to the option chip. Optional.
   * @param option The option for which to get the props.
   * @return MUI.ChipProps The option's chip props.
   */
  chipProps?: (option: ChoicePickerOption<T>) => ChipProps;

  helperText?: string;
}

export const ChoicePicker = observer(
  <T,>({
    options,
    hideOptionsWhenSelected,
    variant,
    value,
    onSelect,
    chipProps,
    label,
    disabled,
    className,
    helperText
  }: ChoicePickerProps<T>) => {
    const defaultSelected = useMemo(() => getDefaultSelectedOption(options)?.key ?? null, [options]);
    const [selected, setSelected] = useState(defaultSelected);
    const prevDefaultSelected = usePrevious(defaultSelected);

    const selectedValue = value !== undefined ? value : selected;

    useEffect(() => {
      if (selectedValue === prevDefaultSelected) {
        setSelected(defaultSelected);
      }
    }, [defaultSelected]);

    const onOptionClick = useCallback(
      (option: ChoicePickerOption<T>) => {
        const newSelected = option.key === selectedValue ? null : option;
        setSelected(newSelected?.key ?? null);
        onSelect?.(newSelected ?? undefined);
      },
      [selectedValue, onSelect]
    );

    const choicePickerOptions =
      hideOptionsWhenSelected && selectedValue != null ? options.filter((o) => o.key === selectedValue) : options;

    switch (variant) {
      case 'inline':
        return (
          <ChoicePickerInline
            options={choicePickerOptions}
            selectedOption={selectedValue ?? undefined}
            onOptionClick={onOptionClick}
            chipProps={chipProps}
            label={label}
            disabled={disabled}
            className={className}
            helperText={helperText}
          />
        );

      case 'radio':
        return (
          <RadioChoicePicker
            className={className}
            label={label}
            selectedOption={selectedValue ?? undefined}
            options={choicePickerOptions}
            disabled={disabled}
            onOptionClick={onOptionClick}
            helperText={helperText}
          />
        );

      case 'normal':
      default:
        return (
          <ChoicePickerNormal
            options={choicePickerOptions}
            selectedOption={selectedValue ?? undefined}
            onOptionClick={onOptionClick}
            chipProps={chipProps}
            label={label}
            disabled={disabled}
            className={className}
            helperText={helperText}
          />
        );
    }
  }
);

// prettier-ignore
const getDefaultSelectedOption = <T, >(options: ChoicePickerOption<T>[]): ChoicePickerOption<T> | undefined =>
  find(options, { isDefaultSelected: true });
