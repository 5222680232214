import { AuthProvider } from '@/models';
import { Stack } from '@mui/material';
import { AppleLoginButton, GoogleLoginButton, MicrosoftLoginButton } from '../utils';

export interface LoginProvidersProps {
  className?: string;
  signIn: (provider: AuthProvider) => Promise<void>;
}

export const LoginProviders = ({ className, signIn }: LoginProvidersProps) => {
  return (
    <Stack
      className={className}
      spacing={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <GoogleLoginButton onClick={() => void signIn('google')} />
      <MicrosoftLoginButton onClick={() => void signIn('microsoft')} />
      <AppleLoginButton onClick={() => void signIn('apple')} />
    </Stack>
  );
};
