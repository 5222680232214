import { dateToPBDate, dayToDate, UserDashboardKind } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { captureException } from '@sentry/react';
import { addWeeks, isBefore } from 'date-fns';
import { uniq } from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import {
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../../utils';

export interface ScheduleCycleCreateCopyDialogViewModel extends StaticDialogViewModel {
  name: string;
  startDate: Date;
  endDate: Date;
  shouldKeepTerms: boolean;
  shouldKeepPeriodSchedules: boolean;
  shouldKeepSpecialDays: boolean;
  shouldKeepSpecialDayOccurrences: boolean;
  shouldKeepActivitySchedules: boolean;
  shouldKeepActivityScheduleExceptions: boolean;

  readonly canSave: boolean;
  readonly createError: string | undefined;
}

export class AppScheduleCycleCreateCopyDialogViewModel implements ScheduleCycleCreateCopyDialogViewModel {
  private readonly _cancelButtonConfiguration: CancelDialogActionButtonConfiguration;
  private readonly _saveButtonConfiguration: SaveDialogActionButtonConfiguration;

  @observable private _name: string;
  @observable private _startDate: Date;
  @observable private _endDate: Date;
  @observable private _shouldKeepTerms = true;
  @observable private _shouldKeepPeriodSchedules = true;
  @observable private _shouldKeepSpecialDays = true;
  // Since they won't fit more often then they will, we suggest not to copy their
  // occurrences by default.
  @observable private _shouldKeepSpecialDayOccurrences = false;
  // Most schools have different class shcedules from one year to another.
  @observable private _shouldKeepActivitySchedules = false;
  // Exceptions occur during the school year. Should start empty.
  @observable private _shouldKeepActivityScheduleExceptions = false;

  @observable private _isSaving = false;
  @observable private _createError: string | undefined;

  readonly kind = 'static';
  readonly supplementaryActions: SaveDialogActionButtonConfiguration[] = [];

  constructor(
    private readonly _scheduleCycle: ScheduleCycle,
    private readonly _dashboardId: string,
    private readonly _dashboardKind: UserDashboardKind,
    private readonly _onDismiss: (scheduleId: string | undefined) => void,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);

    this._name = this.getNewName(_scheduleCycle);
    this._startDate = this.getNewStartDate(_scheduleCycle);
    this._endDate = this.getNewEndDate(_scheduleCycle);

    this._cancelButtonConfiguration = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._saveButtonConfiguration = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.scheduleCycle.create.saveButtonTitle(),
      'contained',
      () => this.save()
    );
  }

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfiguration.isEnabled = !this._isSaving;
    this._saveButtonConfiguration.isEnabled = this.canSave && !this._isSaving;
    this._saveButtonConfiguration.showLoading = this._isSaving;
    return [this._cancelButtonConfiguration, this._saveButtonConfiguration];
  }

  @computed
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @computed
  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
  }

  @computed
  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    if (isBefore(this.startDate, value)) {
      this._endDate = value;
    }
  }

  @computed
  get shouldKeepTerms() {
    return this._shouldKeepTerms;
  }

  set shouldKeepTerms(value: boolean) {
    this._shouldKeepTerms = value;
  }

  @computed
  get shouldKeepPeriodSchedules() {
    return this._shouldKeepPeriodSchedules;
  }

  set shouldKeepPeriodSchedules(value: boolean) {
    this._shouldKeepPeriodSchedules = value;
  }

  @computed
  get shouldKeepSpecialDays() {
    return this._shouldKeepSpecialDays;
  }

  set shouldKeepSpecialDays(value: boolean) {
    this._shouldKeepSpecialDays = value;
  }

  @computed
  get shouldKeepSpecialDayOccurrences() {
    return this.shouldKeepSpecialDays && this._shouldKeepSpecialDayOccurrences;
  }

  set shouldKeepSpecialDayOccurrences(value: boolean) {
    this._shouldKeepSpecialDayOccurrences = value;
  }

  @computed
  get shouldKeepActivitySchedules() {
    return this._shouldKeepActivitySchedules;
  }

  set shouldKeepActivitySchedules(value: boolean) {
    this._shouldKeepActivitySchedules = value;
  }

  @computed
  get shouldKeepActivityScheduleExceptions() {
    return this.shouldKeepActivitySchedules && this._shouldKeepActivityScheduleExceptions;
  }

  set shouldKeepActivityScheduleExceptions(value: boolean) {
    this._shouldKeepActivityScheduleExceptions = value;
  }

  @computed
  get canSave() {
    return this._name.length > 0;
  }

  @computed
  get createError() {
    return this._createError;
  }

  clearError() {
    this._createError = undefined;
  }

  async dismiss() {
    this._onDismiss(undefined);
    return await Promise.resolve();
  }

  private async save() {
    if (!this.canSave) {
      throw new Error('Cannot save');
    }

    runInAction(() => {
      this._isSaving = true;
      this._createError = undefined;
    });

    try {
      const scheduleCycle = await this._userStore.createScheduleCycleCopy(
        this._scheduleCycle,
        this.name,
        dateToPBDate(this.startDate),
        dateToPBDate(this.endDate),
        this.shouldKeepTerms,
        this.shouldKeepPeriodSchedules,
        this.shouldKeepSpecialDays,
        this.shouldKeepSpecialDayOccurrences,
        this.shouldKeepActivitySchedules,
        this.shouldKeepActivityScheduleExceptions,
        { id: this._dashboardId, kind: this._dashboardKind }
      );

      this._onDismiss(scheduleCycle.id);
    } catch (e) {
      captureException(e);
      runInAction(() => {
        this._isSaving = false;
        this._createError = (e as Error).message;
      });
    }
  }

  private getNewName(scheduleCycle: ScheduleCycle) {
    let name = scheduleCycle.name;
    const years = uniq([scheduleCycle.endDay!.year, scheduleCycle.startDay!.year]);

    for (const year of years) {
      const fullText = String(year);

      if (name.includes(fullText)) {
        name = name.replace(fullText, String(year + 1));
      } else {
        const halfText = fullText.substring(2);
        const halfNew = String(year + 1).substring(2);
        name = name.replace(halfText, halfNew);
      }
    }

    return name;
  }

  private getNewStartDate(scheduleCycle: ScheduleCycle) {
    // We do not add a year, we want the new dates to be aligned on the same day of week.
    return addWeeks(dayToDate(scheduleCycle.startDay!), 52);
  }

  private getNewEndDate(scheduleCycle: ScheduleCycle) {
    // We do not add a year, we want the new dates to be aligned on the same day of week.
    return addWeeks(dayToDate(scheduleCycle.endDay!), 52);
  }
}
