import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { action, computed, makeObservable, observable, override } from 'mobx';
import { ConfirmationDialogAction } from './ConfirmationDialogAction';
import { ConfirmationDialogChoice } from './ConfirmationDialogChoice';
import { AppConfirmationDialogViewModel, ConfirmationDialogViewModel } from './ConfirmationDialogViewModel';

export interface ConfirmationDialogWithChoicesViewModel extends ConfirmationDialogViewModel {
  readonly choices: ConfirmationDialogChoice[];
  readonly selectedKeys: string[];
  toggleSelectionForKey(key: string): void;
}

export class AppConfirmationDialogWithChoicesViewModel
  extends AppConfirmationDialogViewModel
  implements ConfirmationDialogWithChoicesViewModel
{
  @observable private readonly _selectedKeys: string[];

  constructor(
    private _onConfirm: ((selectedChoicesKeys: string[]) => Promise<void>) | undefined,
    onCancel: (() => Promise<void>) | undefined,
    showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    additionalActions: ConfirmationDialogAction[] | undefined,
    readonly choices: ConfirmationDialogChoice[],
    private readonly _requiresAtLeastOneSelection: boolean | undefined,
    initialSelectedChoicesKeys: string[] | undefined,
    isDestructive: boolean | undefined,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(
      () => this.confirm(),
      onCancel,
      showCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      isDestructive,
      localization
    );
    makeObservable(this);

    const allKeys = choices.map((c) => c.key);
    this._selectedKeys = initialSelectedChoicesKeys?.filter((key) => allKeys.includes(key)) ?? [];
  }

  @override
  get canConfirm(): boolean {
    return this._requiresAtLeastOneSelection == null || this._selectedKeys.length > 0;
  }

  @computed
  get selectedKeys(): string[] {
    return this._selectedKeys;
  }

  @action
  toggleSelectionForKey(key: string) {
    const keyIndex = this._selectedKeys.findIndex((k) => k === key);

    if (keyIndex >= 0) {
      this._selectedKeys.splice(keyIndex, 1);
    } else {
      this._selectedKeys.push(key);
    }
  }

  private async confirm() {
    await this._onConfirm?.(this.selectedKeys);
  }
}
