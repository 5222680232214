import { useServices } from '@/hooks';
import { MoreTimeRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface WorkDetailsNoUpcomingPlannedWorkProps {
  sx?: SxProps;
  className?: string;
  isReadOnly: boolean;
  onCreate: () => void;
}

export const WorkDetailsNoUpcomingPlannedWork = observer(
  ({ sx = [], className, onCreate, isReadOnly }: WorkDetailsNoUpcomingPlannedWorkProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;

    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Typography
          variant="subtitle2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            px: 1
          }}
        >
          {strings.plannedWorkCardNoPlannedWork}
        </Typography>
        {!isReadOnly && (
          <Button variant="contained-grey" onClick={() => onCreate()} startIcon={<MoreTimeRounded />}>
            {strings.plannedWorkCardAddPlannedWorkButtonTitle}
          </Button>
        )}
      </Stack>
    );
  }
);
