import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { computed, makeObservable, observable } from 'mobx';
import { ConfirmationDialogAction } from './ConfirmationDialogAction';
import { ConfirmationDialogChoice } from './ConfirmationDialogChoice';
import { AppConfirmationDialogViewModel, ConfirmationDialogViewModel } from './ConfirmationDialogViewModel';

export interface ConfirmationDialogWithSingleChoiceViewModel extends ConfirmationDialogViewModel {
  readonly choices: ConfirmationDialogChoice[];
  selectedKey: string;
}

export class AppConfirmationDialogWithSingleChoiceViewModel
  extends AppConfirmationDialogViewModel
  implements ConfirmationDialogWithSingleChoiceViewModel
{
  @observable private _selectedKey: string;

  constructor(
    protected _onConfirm: ((selectedKey: string) => Promise<void>) | undefined,
    onCancel: (() => Promise<void>) | undefined,
    showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    additionalActions: ConfirmationDialogAction[] | undefined,
    readonly choices: ConfirmationDialogChoice[],
    initialSelectedKey: string | undefined,
    isDestructive: boolean | undefined,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(
      () => this.confirm(),
      onCancel,
      showCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      isDestructive,
      localization
    );
    makeObservable(this);

    if (choices.length === 0) {
      throw new Error('Trying to display ConfirmationDialogWithSingleChoice without choices.');
    }

    this._selectedKey = choices[0].key;

    if (initialSelectedKey != null && choices.find((c) => c.key === initialSelectedKey)) {
      this._selectedKey = initialSelectedKey;
    }
  }

  @computed
  get selectedKey(): string {
    return this._selectedKey;
  }

  set selectedKey(value: string) {
    this._selectedKey = value;
  }

  private async confirm() {
    await this._onConfirm?.(this.selectedKey);
  }
}
