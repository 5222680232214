import { useServices } from '@/hooks';
import { DefaultCalendarWeekFilters } from '@/models';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PopoverProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarFiltersPopover } from '../shared';

export interface UserDashboardCalendarWeekFiltersPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
  courseSections: CourseSectionDetails[];
}

export const UserDashboardCalendarWeekFiltersPopover = observer(
  (props: UserDashboardCalendarWeekFiltersPopoverProps) => {
    const { settings, settingsStorage } = useServices();

    return (
      <UserDashboardCalendarFiltersPopover
        {...props}
        getFilters={() => settings.calendarWeekFilters}
        setFilters={(filters) => (settings.calendarWeekFilters = filters)}
        resetFilter={() => (settings.calendarWeekFilters = DefaultCalendarWeekFilters)}
        getSelectedCourseSectionIds={(dashboardId) =>
          settingsStorage.calendarWeekDisplayedCourseSectionsForUserDashboard(dashboardId)
        }
        setSelectedCourseSectionIds={(ids, dashboardId) =>
          settingsStorage.setCalendarWeekDisplayedCourseSectionsForUserDashboard(ids, dashboardId)
        }
      />
    );
  }
);
