import { useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../../../utils';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';
import { ScheduleCycleEditDetails } from './ScheduleCycleEditDetails';

export interface ScheduleCycleDetailsEditPageProps {
  sx?: SxProps;
  className?: string;
}

export const ScheduleCycleDetailsEditPage = observer(({ sx = [], className }: ScheduleCycleDetailsEditPageProps) => {
  const { dashboard, scheduleCycleId } = useScheduleCycleUserDashboard();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createScheduleEditDetailsPageViewModel(scheduleCycleId, dashboard),
    [scheduleCycleId]
  );

  return (
    <Stack
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack
        spacing={3}
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: 1000
        }}
      >
        <ScheduleCycleEditDetails
          sx={{ flex: 1, overflowY: 'auto' }}
          viewModel={viewModel.editViewModel}
          isReadOnly={viewModel.isReadOnly}
        />
      </Stack>
    </Stack>
  );
});
