import { PlannerNewSchoolsDialogViewModel } from '@/viewmodels';
import { SyncRounded } from '@mui/icons-material';
import { List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ConnectedAppIcon } from '../../connected-apps';
import { PlannerNewSchoolsClassroomConnectionSchoolItem } from './PlannerNewSchoolsClassroomConnectionSchoolItem';
import { PlannerNewSchoolsClassroomConnectionState } from './PlannerNewSchoolsClassroomConnectionState';

export interface PlannerNewSchoolsClassroomConnectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerNewSchoolsDialogViewModel;
}

export const PlannerNewSchoolsClassroomConnection = observer(
  ({ sx = [], className, viewModel }: PlannerNewSchoolsClassroomConnectionProps) => {
    const strings = LocalizedStrings.planner.newSchools.classroomConnection;
    const [connectError, setIsConnectError] = useState<Error>();
    const selectedSchools = viewModel.schools.filter((s) => !viewModel.ignoredSchoolIds.includes(s.school!.id));

    return (
      <Stack sx={sx} className={className} spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center'
          }}
        >
          <ConnectedAppIcon kind="classroom" />

          <Stack>
            <Typography
              sx={{
                fontWeight: '500'
              }}
            >
              {strings.title()}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            >
              {strings.subtitle(selectedSchools.length)}
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="body2">{viewModel.isTeacher ? strings.messageTeacher() : strings.message()}</Typography>
        {viewModel.isTeacher ? (
          <List>
            {selectedSchools.map((s) => (
              <PlannerNewSchoolsClassroomConnectionSchoolItem
                key={s.school!.id}
                school={s}
                connectedAppService={viewModel.getConnectedAppService(s.school!.id)}
              />
            ))}
          </List>
        ) : (
          <Stack spacing={1}>
            <PlannerNewSchoolsClassroomConnectionState
              isCompact={false}
              onConnectErrorChange={setIsConnectError}
              service={viewModel.plannerConnectedAppService}
            />

            {connectError != null && (
              <Typography
                variant="caption"
                sx={{
                  fontWeight: '500',
                  color: (theme) => theme.palette.error.main
                }}
              >
                {strings.errorMessage()}
              </Typography>
            )}

            {viewModel.plannerConnectedAppService.isSyncing && (
              <Typography
                variant="caption"
                sx={{
                  fontWeight: '500',
                  color: (theme) => theme.palette.text.secondary,
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <SyncRounded fontSize="inherit" color="inherit" sx={{ mr: 0.5 }} />
                {strings.fetchingDataMessage()}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    );
  }
);
