import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { Subscription } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/subscription_pb';
import { reaction } from 'mobx';
import { useEffect, useState } from 'react';
import { useServices } from './UseServicesHook';

export interface ActiveSubscriptionInfo {
  isSubscribed: boolean;
  isActive: boolean;
  isEntitledToSharedSchools: boolean;
}

export function useActiveSubscription(): ActiveSubscriptionInfo {
  const { userStore } = useServices();
  const [subscription, setSubscription] = useState<Subscription | undefined>();

  useEffect(() => {
    reaction(
      () => {
        if (userStore.subscription.hasData) {
          return userStore.subscription.data;
        }

        return undefined;
      },
      (val) => setSubscription(val?.subscription)
    );
  }, []);

  const isEntitledToSharedSchools = subscription?.entitlements.includes(Entitlement.SHARED_SCHOOLS) ?? false;

  return {
    isSubscribed: subscription != null,
    isActive: subscription?.isActive === true,
    isEntitledToSharedSchools
  };
}
