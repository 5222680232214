import { RouteTemplates } from '@/RouteTemplates';
import { useIsMatchPath, useServices } from '@/hooks';
import { StoredActiveDashboard } from '@/services';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { LoadingPlannerScreen, NotFoundScreen } from '../components';

export const OnboardedRoute = observer(() => {
  const { featureFlag, userStore, route, settingsStorage } = useServices();
  const params = useParams();
  const isAdmin = useIsMatchPath({ path: 'school-admin', end: false });

  if (isAdmin && featureFlag.isEnabled('shared-schools')) {
    return <Outlet />;
  }

  const plannerId = params.plannerId;

  if (plannerId != null) {
    const plannerLoadable = userStore.getPlannerLoadable(plannerId);

    if (plannerLoadable.hasData) {
      return <Outlet />;
    } else if (userStore.user.roles.includes('super-admin')) {
      return <LoadingPlannerScreen plannerLoadable={plannerLoadable} />;
    } else {
      return <NotFoundScreen />;
    }
  }

  let defaultDashboard: StoredActiveDashboard | undefined = settingsStorage.activeDashboard;
  if (defaultDashboard != null && userStore.plannersLoadable.data.get(defaultDashboard.id) == null) {
    // Stored dashboard doesn't exist, so we are not using it.
    defaultDashboard = undefined;
  }

  if (defaultDashboard == null) {
    const defaultPlanner = userStore.getDefaultPlanner();
    if (defaultPlanner != null) {
      defaultDashboard = { id: defaultPlanner.id, kind: 'planner' };
    }
  }

  if (defaultDashboard != null && defaultDashboard.kind === 'planner') {
    const location = route.resolvePlannerLocation(defaultDashboard.id);
    return <Navigate to={location} replace />;
  }

  // We couldn't find a dashboard to show. Redirecting to the onboarding.
  return <Navigate to={RouteTemplates.onboarding} replace />;
});
