import { Day, dayToDate, plannerHasAccessKindsForUser, timestampToTimeOfDay } from '@/models';
import { ServiceContainer } from '@/providers';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CalendarEvent } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { PlannerDayAnnotation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_annotation_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PlannerItem } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { PublishedWorkDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/published_work_details_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  plannerCalendarEventToInfo,
  plannerCourseSectionOccurrenceToInfo,
  plannerDayAnnotationToInfo,
  plannerNoteToInfo,
  plannerPlannedWorkToInfo,
  plannerWorkToInfo,
  publishedWorkToInfo
} from '../shared';
import { AppUserDashboardCalendarMonthDayViewModel } from './UserDashboardCalendarMonthDayViewModel';
import { UserDashboardCalendarMonthDayItemInfo } from './UserDashboardCalendarMonthItems';

export class AppPlannerUserDashboardCalendarMonthDayViewModel extends AppUserDashboardCalendarMonthDayViewModel {
  constructor(
    day: Day,
    private readonly _plannerId: string,
    private readonly _getPlannerDay: () => PlannerDay | undefined,
    displayedCourseSectionIds: () => string[] | undefined,
    workStore = ServiceContainer.services.workStore,
    settings = ServiceContainer.services.settings,
    private readonly _pasteboard = ServiceContainer.services.pasteboard,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _featureFlag = ServiceContainer.services.featureFlag
  ) {
    super(day, displayedCourseSectionIds, workStore, settings);
    makeObservable(this);
  }

  @computed
  protected get courseSections(): CourseSectionDetails[] {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId).values;
  }

  @computed
  private get plannerDay(): PlannerDay | undefined {
    return this._getPlannerDay();
  }

  @computed
  private get isReadOnly() {
    const planner = this._userStore.getPlannerForId(this._plannerId)!;
    return !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS);
  }

  @computed
  private get canPublishWork(): boolean {
    return this._plannerStore.getCanPublishWorkInPlanner(this._plannerId);
  }

  @computed
  get cycleDayName(): string {
    if (this.plannerDay == null) {
      return '';
    }

    return !this.plannerDay.isDayOfWeekCycleDayName ? this.plannerDay.cycleDayName : '';
  }

  @computed
  get items(): UserDashboardCalendarMonthDayItemInfo[] {
    if (this.plannerDay == null) {
      return [];
    }

    return this.getItemsFromDayContent(this.plannerDay);
  }

  private getItemsFromDayContent(dayContent: PlannerDay): UserDashboardCalendarMonthDayItemInfo[] {
    const annotations = this.filters.annotations ? dayContent.annotations.map((a) => this.mapAnnotationToInfo(a)) : [];

    const otherItems = chain(dayContent.items)
      .map((item) => {
        switch (item.item.case) {
          case 'calendarEvent':
            return this.filters.calendarEvents ? this.mapCalendarEventToInfo(item.item.value, item) : undefined;

          case 'work':
            if (!this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId)) {
              return undefined;
            }

            if (item.plannedWork != null) {
              return this.filters.plannedWorks
                ? this.mapPlannedWorkToInfo(item.plannedWork, item.item.value)
                : undefined;
            } else {
              return this.filters.works ? this.mapWorkToInfo(item.item.value, item) : undefined;
            }

          case 'note':
            return this.filters.notes && this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId)
              ? this.mapNoteToInfo(item.item.value, item)
              : undefined;

          case 'publishedWork':
            if (!this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId)) {
              return undefined;
            }

            return this.filters.works ? this.mapPublishedWorkToInfo(item.item.value, item) : undefined;

          case 'courseSectionOccurrence':
            return this.filters.periods && this.shouldDisplayItemWithCourseSectionId(item.item.value.courseSectionId)
              ? this.mapCourseOccurrenceToInfo(item.item.value, dayToDate(dayContent.day!))
              : undefined;

          default:
            return undefined;
        }
      })
      .compact()
      .value();

    return [...annotations, ...otherItems];
  }

  private mapAnnotationToInfo(annotation: PlannerDayAnnotation): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'annotation',
      value: plannerDayAnnotationToInfo(annotation)
    };
  }

  private mapCalendarEventToInfo(
    calendarEvent: CalendarEvent,
    item: PlannerItem
  ): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'calendarEvent',
      value: {
        ...plannerCalendarEventToInfo(calendarEvent, this._now),
        times: !item.isContextualAllDay
          ? {
              startTime: timestampToTimeOfDay(item.contextualStartTime!),
              endTime: timestampToTimeOfDay(item.contextualEndTime!)
            }
          : undefined
      }
    };
  }

  private mapWorkToInfo(work: Work, item: PlannerItem): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'work',
      value: {
        ...plannerWorkToInfo(
          work,
          this.courseSectionsById,
          this.workIcons,
          this._now,
          this.isReadOnly,
          this._pasteboard,
          this._workStore,
          this._plannerStore,
          this._featureFlag
        ),
        startTime: !item.isContextualAllDay ? timestampToTimeOfDay(item.contextualStartTime!) : undefined
      }
    };
  }

  private mapNoteToInfo(note: Note, item: PlannerItem): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'note',
      value: {
        ...plannerNoteToInfo(note, this.courseSectionsById, this._now, this._pasteboard, this._featureFlag),
        startTime: !item.isContextualAllDay ? timestampToTimeOfDay(item.contextualStartTime!) : undefined
      }
    };
  }

  private mapPublishedWorkToInfo(work: PublishedWorkDetails, item: PlannerItem): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'publishedWork',
      value: {
        ...publishedWorkToInfo(
          work,
          this.courseSectionsById,
          this.workIcons,
          this._now,
          this.isReadOnly,
          this._pasteboard,
          this._featureFlag
        ),
        startTime: !item.isContextualAllDay ? timestampToTimeOfDay(item.contextualStartTime!) : undefined
      }
    };
  }

  private mapPlannedWorkToInfo(plannedWork: PlannedWork, work: Work): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'plannedWork',
      value: plannerPlannedWorkToInfo(
        plannedWork,
        work,
        this.courseSectionsById,
        this.workIcons,
        this._now,
        this.isReadOnly,
        this._pasteboard,
        this._workStore,
        this._featureFlag
      )
    };
  }

  private mapCourseOccurrenceToInfo(
    courseOccurrence: CourseSectionOccurrence,
    date: Date
  ): UserDashboardCalendarMonthDayItemInfo {
    return {
      case: 'period',
      value: plannerCourseSectionOccurrenceToInfo(
        courseOccurrence,
        this.courseSectionsById,
        date,
        this._now,
        this.isReadOnly,
        this.canPublishWork,
        this._pasteboard,
        this._featureFlag
      )
    };
  }

  private shouldDisplayItemWithCourseSectionId(id: string): boolean {
    if (id.length === 0) {
      return true;
    }

    const courseSection = this.courseSectionsById.get(id);
    return (
      courseSection == null ||
      this.displayedCourseSectionIds == null ||
      this.displayedCourseSectionIds.includes(courseSection.courseSection!.id)
    );
  }
}
