import { useServices } from '@/hooks';
import NotFoundImageFallback from '@/resources/images/notFound/illustration_not_found.png';
import NotFoundImage from '@/resources/images/notFound/illustration_not_found.webp';
import { css } from '@emotion/css';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { Splash } from '../Splash';
import { LoginDialog } from '../onboarding';
import { Image, MediaQuery } from '../utils';

interface LocationState {
  notFoundShowLogin?: boolean;
}

export const NotFoundScreen = observer(() => {
  const { localization, user, authentication } = useServices();
  const strings = localization.localizedStrings.utils;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();

  function showLogin() {
    const newState: LocationState = { ...state, notFoundShowLogin: true };
    navigate(location, { state: newState });
  }

  const imageStyle = css([{ width: '100%', height: 'auto' }]);

  const content = (
    <>
      <Stack
        direction="column"
        spacing={6}
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Box>
          <Image
            sources={[
              { src: NotFoundImage, type: 'image/webp' },
              { src: NotFoundImageFallback, type: 'image/png' }
            ]}
            fallback={NotFoundImageFallback}
            alt={'Not found illustration'}
            className={imageStyle}
          />
        </Box>

        <Stack spacing={2}>
          <Typography variant="h5" align="center">
            {strings.notFoundTitle}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              textAlign: 'center'
            }}
          >
            {strings.notFoundText}
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Button variant="contained" onClick={() => navigate('/home')}>
            {strings.notFoundNavigateToDashboard}
          </Button>

          {user.currentUser != null && (
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {strings.notFoundConnectedAs(user.currentUser.fullName)}
                </Typography>
                <Avatar
                  src={user.currentUser.pictureUrl}
                  alt={user.currentUser.fullName}
                  sx={{ width: 32, height: 32 }}
                />
              </Stack>
              <Button variant="text" onClick={() => void authentication.signOut()}>
                {strings.signOut}
              </Button>
            </>
          )}

          {user.currentUser == null && <Button onClick={showLogin}>{strings.notFoundLogIn}</Button>}
        </Stack>
      </Stack>

      {(state.notFoundShowLogin ?? false) && <LoginDialog kind="log-in" isOpen={true} referrer={location.pathname} />}
    </>
  );

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        <Splash width="100%" height="100%" contentWidth={400}>
          {content}
        </Splash>
      </MediaQuery>
      <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            p: 2
          }}
        >
          {content}
        </Box>
      </MediaQuery>
    </>
  );
});
