import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService, UserService } from '@/services';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable } from 'mobx';
import { StudentEmotionalStatus, emotionalPulseRatingToStatus } from '../../models';
import { UserDataStore } from '../../stores';

export interface UserDashboardSideBarViewModel {
  readonly fullName: string;
  readonly email: string;
  readonly imageUrl: string;
  readonly userEmotionalStatusEmoji: string | undefined;
  readonly hasConnectedAppsWarning: boolean;
  readonly hasConnectedAppsError: boolean;
}

export abstract class AppUserDashboardSideBarViewModel implements UserDashboardSideBarViewModel {
  protected constructor(
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization,
    protected readonly _user: UserService = ServiceContainer.services.user,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    makeObservable(this);
  }

  @computed
  protected get emotionPulseRating(): number | undefined {
    const { emotionalPulse } = this._userStore;

    if (!emotionalPulse.hasData) {
      // Not returning undefined as the survey card would be displayed while fetching the pulse.
      return -1;
    }

    return emotionalPulse.data?.rating;
  }

  @computed
  get fullName(): string {
    return this._user.currentUser?.fullName ?? this._localization.localizedStrings.mainScreen.anonymousName;
  }

  @computed
  get email(): string {
    return this._user.currentUser?.emailAddress ?? this._localization.localizedStrings.mainScreen.anonymousEmail;
  }

  @computed
  get imageUrl(): string {
    return this._user.currentUser?.pictureUrl ?? '';
  }

  abstract get hasConnectedAppsWarning(): boolean;

  abstract get hasConnectedAppsError(): boolean;

  @computed
  get userEmotionalStatusEmoji(): string | undefined {
    const user = this._user.currentUser;

    if (user == null || user.persona !== UserPersona.STUDENT) {
      return undefined;
    }

    const emotionalRating = this.emotionPulseRating;
    if (emotionalRating == null || emotionalRating < 0) {
      return undefined;
    }

    const status = emotionalPulseRatingToStatus(emotionalRating);
    switch (status) {
      case StudentEmotionalStatus.SAD:
        return '🙁';
      case StudentEmotionalStatus.OK:
        return '😐';
      case StudentEmotionalStatus.HAPPY:
        return '😃';
    }
  }
}
