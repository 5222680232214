import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Subheader } from '../Subheader';

export interface OptionsPopoverSectionProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  title?: string;
}

export const OptionsPopoverSection = observer(({ sx = [], className, children, title }: OptionsPopoverSectionProps) => {
  return (
    <Stack sx={sx} className={className}>
      {title != null && title.length > 0 && <Subheader>{title}</Subheader>}
      {children}
    </Stack>
  );
});
