import { useServices } from '@/hooks';
import { CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Splash } from '../Splash';

export const InitializingScreen = observer(() => {
  const { localization } = useServices();
  const strings = localization?.localizedStrings.utils;

  return (
    <Splash width="100%" height="100%" logoIsLink={false} showOptions={false}>
      <Typography>{strings?.loadingDataLabel ?? 'Preparing…'}</Typography>
      <CircularProgress sx={{ mt: 3 }} size={32} />
    </Splash>
  );
});
