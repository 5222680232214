import { AdminSchoolAdminUsersListItem } from '@/components/admin/general/AdminSchoolAdminUsersListItem';
import { List, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useViewModel } from '../../../hooks';

export interface AdminSchoolAdminUsersListProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminSchoolAdminUsersList = observer(
  ({ sx = [], className, schoolId }: AdminSchoolAdminUsersListProps) => {
    const viewModel = useViewModel((p) => p.createAdminSchoolAdminUsersListViewModel(schoolId));

    return (
      <List sx={sx} className={className}>
        {viewModel.administrators.map((admin, i) => (
          <AdminSchoolAdminUsersListItem
            key={`admin-${i}-${admin.userId}`}
            userSummary={admin}
            isCurrentUser={admin.userId == viewModel.currentUserId}
            isOwner={viewModel.owners.some((o) => admin.userId == o.userId)}
            onMakeOwner={viewModel.isCurrentUserOwner ? () => void viewModel.changeOwner(admin) : undefined}
            onRemove={viewModel.isCurrentUserAdmin ? () => void viewModel.removeAdmin(admin) : undefined}
          />
        ))}
        {viewModel.error && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.error.main,
              mx: 2
            }}
          >
            {viewModel.error}
          </Typography>
        )}
      </List>
    );
  }
);
