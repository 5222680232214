import { useServices } from '@/hooks';
import { CardActionArea, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { getBlurredClassName } from '../utils';

export interface StudentPlannerBannerProps {
  sx?: SxProps;
  className?: string;
  onClick: () => void;
}

export const StudentPlannerBanner = observer(({ sx = [], className, onClick }: StudentPlannerBannerProps) => {
  const { localization } = useServices();
  const theme = useTheme();
  const backgroundStyle = getBlurredClassName('default', theme, theme.palette.secondary.main);

  return (
    <CardActionArea
      sx={{
        ...sx,
        py: 0.5,
        px: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      className={clsx(className, backgroundStyle)}
      onClick={onClick}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: theme.palette.secondary.contrastText
        }}
      >
        {localization.localizedStrings.utils.studentPlannerBannerLabel}
      </Typography>
    </CardActionArea>
  );
});
