import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PublishedWorkDetailsDueDateCard {
  sx?: SxProps;
  className?: string;
  dueDate: Date | undefined;
  isDueAllDay: boolean;
}

export const PublishedWorkDetailsDueDate = observer(
  ({ sx = [], className, dueDate, isDueAllDay }: PublishedWorkDetailsDueDateCard) => {
    const strings = LocalizedStrings.publishedWork.details;

    return (
      <Stack sx={sx} className={className} direction="column" spacing={2}>
        <Stack
          direction="column"
          sx={{
            flex: 1
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {strings.dueDateLabel()}
          </Typography>

          <Typography
            sx={{
              fontWeight: '500'
            }}
          >
            {dueDate != null ? format(dueDate, 'PPP') : strings.dueDateNone()}
          </Typography>

          {dueDate != null && !isDueAllDay && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: '500'
              }}
            >
              {format(dueDate, 'p')}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }
);
