import { WorkPlannedWorkInfo } from '@/viewmodels';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { ChecklistRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useServices } from '../../../hooks';
import { ActionMenu, ActionMenuButton } from '../../utils';
import { PlannedWorkEditDialog } from '../planning';

interface LocationState {
  showPlannedWorkEditForId?: string;
}

export interface WorkDetailsPlannedWorkActionButtonProps {
  sx?: SxProps;
  className?: string;
  info: WorkPlannedWorkInfo;
  canLinkSteps: boolean;
  onDelete: (plannedWork: PlannedWork) => Promise<void>;
  onLinkSteps: (plannedWork: PlannedWork) => void;
}

export const WorkDetailsPlannedWorkActionButton = observer(
  ({ sx = [], className, info, canLinkSteps, onDelete, onLinkSteps }: WorkDetailsPlannedWorkActionButtonProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;
    const [showActionMenu, setShowActionMenu] = useState(false);
    const actionMenuAnchorRef = useRef<HTMLButtonElement>(null);

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();

    function editPlannedWork() {
      setShowActionMenu(false);
      const newState: LocationState = { ...state, showPlannedWorkEditForId: info.plannedWork.id };
      navigate(location, { state: newState });
    }

    function linkSteps() {
      setShowActionMenu(false);
      onLinkSteps(info.plannedWork);
    }

    function deletePlannedWork() {
      setShowActionMenu(false);
      void onDelete(info.plannedWork);
    }

    return (
      <Box sx={sx} className={className}>
        <ActionMenuButton
          ref={actionMenuAnchorRef}
          onClick={() => setShowActionMenu(true)}
          buttonSize="small"
          iconSize="small"
        />

        <ActionMenu
          open={showActionMenu}
          anchorEl={actionMenuAnchorRef.current}
          onClose={() => setShowActionMenu(false)}
        >
          <MenuItem dense onClick={editPlannedWork}>
            <ListItemIcon>
              <EditRounded />
            </ListItemIcon>
            {strings.plannedWorkActionMenuEditOption}
          </MenuItem>

          {canLinkSteps && (
            <MenuItem dense onClick={linkSteps}>
              <ListItemIcon>
                <ChecklistRounded />
              </ListItemIcon>
              {strings.plannedWorkActionMenuLinkStepsOption}
            </MenuItem>
          )}

          <Divider />

          <MenuItem dense onClick={deletePlannedWork}>
            <ListItemIcon>
              <DeleteRounded color="error" />
            </ListItemIcon>
            <ListItemText
              primary={strings.plannedWorkActionMenuDeleteOption}
              primaryTypographyProps={{ color: 'error' }}
            />
          </MenuItem>
        </ActionMenu>

        {state.showPlannedWorkEditForId === info.plannedWork.id && (
          <PlannedWorkEditDialog isOpen={true} workId={info.workId} plannedWorkId={info.plannedWork.id} />
        )}
      </Box>
    );
  }
);
