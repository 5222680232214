import LocalizedStrings from 'strings';
import { SettingsSection } from './SettingsSection';

export function generalSettingsSection(): SettingsSection {
  const strings = LocalizedStrings.settings.sidebar.shared;

  return {
    title: strings.sharedSectionTitle(),
    items: [
      { link: 'profile', title: strings.profile() },
      { link: 'preferences', title: strings.preferences() },
      { link: 'account-management', title: strings.accountManagement() },
      { link: 'about', title: strings.about() }
    ]
  };
}
