import { alpha, lighten, styled } from '@mui/material';

export const ScheduleCycleSpecialDaysTable = styled('table')(({ theme }) => {
  const isDarkMode = theme.palette.mode === 'dark';
  const cellBackgroundColor = isDarkMode
    ? lighten(theme.palette.background.default, 0.5)
    : lighten(theme.palette.common.black, 0.5);

  return {
    'tr:first-of-type > td:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius
    },
    'tr:first-of-type > td:last-child': {
      borderTopRightRadius: theme.shape.borderRadius
    },
    'tr:last-child > td:first-of-type': {
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    'tr:last-child > td:last-child': {
      borderBottomRightRadius: theme.shape.borderRadius
    },
    th: {
      padding: theme.spacing(1)
    },
    td: {
      overflow: 'hidden',
      backgroundColor: alpha(cellBackgroundColor, isDarkMode ? 0.5 : 0.15)
    },
    'td.selectable:hover': {
      backgroundColor: alpha(cellBackgroundColor, isDarkMode ? 0.35 : 0.3)
    },
    'td.otherMonth': {
      backgroundColor: alpha(cellBackgroundColor, isDarkMode ? 0.2 : 0.3),
      color: theme.palette.text.secondary
    },
    'td.otherMonth.selectable:hover': {
      backgroundColor: alpha(cellBackgroundColor, isDarkMode ? 0.1 : 0.5)
    },
    'td.empty': {
      backgroundColor: 'transparent'
    },
    'td.selected': {
      backgroundColor: alpha(theme.palette.primary.main, isDarkMode ? 0.4 : 0.4)
    },
    'td.selected.selectable:hover': {
      backgroundColor: alpha(theme.palette.primary.main, isDarkMode ? 0.3 : 0.6)
    },
    'td > div': {
      height: 56,
      width: 56,

      [theme.breakpoints.down('lg')]: {
        height: 40,
        width: 40
      }
    }
  };
});
