import { useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { UserProperties } from '../../user';
import { SettingsCancelButton } from './SettingsCancelButton';
import { SettingsContent } from './SettingsContent';
import { SettingsSaveButton } from './SettingsSaveButton';

export interface ProfileEditProps {
  sx?: SxProps;
  className?: string;
}

export const ProfileEdit = observer(({ sx = [], className }: ProfileEditProps) => {
  const viewModel = useViewModel((viewModels) => viewModels.createUserPropertiesViewModel(), []);

  return (
    <SettingsContent sx={sx} className={className}>
      <Stack spacing={6}>
        <UserProperties viewModel={viewModel} />

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'flex-end'
          }}
        >
          <SettingsCancelButton onClick={() => viewModel.cancel()} disabled={viewModel.isApplying} />

          <SettingsSaveButton
            onClick={() => void viewModel.apply()}
            disabled={viewModel.isApplying || !viewModel.canApply}
            loading={viewModel.isApplying}
          />
        </Stack>
      </Stack>
    </SettingsContent>
  );
});
