import { Picture } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService } from '@/services';
import { computed, makeObservable } from 'mobx';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';

export interface BackgroundImageViewModel extends StaticDialogViewModel {
  readonly currentBackgroundImage: Picture | undefined;

  setImage(image: Picture): void;
  removeImage(): void;
}

export class AppBackgroundImageViewModel extends AppBaseStaticDialogViewModel implements BackgroundImageViewModel {
  constructor(
    close: () => Promise<void>,
    private readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings
  ) {
    super(close);
    makeObservable(this);
  }

  @computed
  get currentBackgroundImage(): Picture | undefined {
    return this._settings.backgroundImage;
  }

  setImage(image: Picture) {
    this._settings.backgroundImage = image;
  }

  removeImage() {
    this._settings.backgroundImage = undefined;
  }
}
