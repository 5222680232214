import { GoogleTagManagerService } from '@/services/contracts/GoogleTagManagerService';
import { EnvironmentService } from '../contracts';

declare global {
  interface Window {
    /**
     * Load Google Tag Manager with the provided id.
     * @param gtmId
     */
    loadGTM(gtmId: string): void;
  }
}

export class AppGoogleTagManagerService implements GoogleTagManagerService {
  constructor(private readonly _environment: EnvironmentService) {
    this.load();
  }

  private load() {
    window.loadGTM(this._environment.googleTagManagerId);
  }
}
