import { AccessTimeRounded, MoreTimeRounded } from '@mui/icons-material';
import { Box, Chip, Divider, InputBase, MenuItem, Popover, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useServices } from '../../../hooks';

const presetDurations = [5, 15, 30, 45];

export interface WorkStepDurationControlProps {
  sx?: SxProps;
  className?: string;
  duration: number;
  onDurationChange: (duration: number) => void;
}

export const WorkStepDurationControl = observer(
  ({ sx = [], className, duration, onDurationChange }: WorkStepDurationControlProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;
    const theme = useTheme();
    const [pickerAnchor, setPickerAnchor] = useState<HTMLElement | undefined>(undefined);
    const [customDuration, setCustomDuration] = useState(0);

    function showPicker(e: MouseEvent<HTMLDivElement>) {
      setPickerAnchor(e.currentTarget);
    }

    function didSelectPresetDuration(value: number) {
      setPickerAnchor(undefined);
      setCustomDuration(0);
      onDurationChange(value);
    }

    function submitCustomDuration() {
      setPickerAnchor(undefined);
      if (customDuration > 0) {
        onDurationChange(customDuration);
        setCustomDuration(0);
      }
    }

    function onClose() {
      setPickerAnchor(undefined);
      if (customDuration > 0) {
        onDurationChange(customDuration);
        setCustomDuration(0);
      }
    }

    return (
      <Box sx={sx} className={className}>
        <Chip
          sx={sx}
          className={className}
          size="small"
          icon={duration > 0 ? <AccessTimeRounded color="primary" /> : <MoreTimeRounded />}
          label={
            duration > 0
              ? strings.stepEditDurationPickerValueLabel(duration)
              : strings.stepEditDurationPickerNoValueLabel
          }
          variant="filled"
          onClick={(e) => showPicker(e)}
          onDelete={duration > 0 ? () => onDurationChange(0) : undefined}
        />
        {pickerAnchor != null && (
          <Popover
            open
            anchorEl={pickerAnchor}
            onClose={onClose}
            elevation={1}
            slotProps={{ paper: { sx: { minWidth: 320 } } }}
          >
            <Stack
              spacing={1}
              sx={{
                py: 1.5
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  px: 1.5,
                  fontWeight: '600'
                }}
              >
                {strings.stepEditDurationPickerTitle}
              </Typography>

              <InputBase
                sx={{ px: 2, ...theme.typography.body2 }}
                placeholder={strings.stepEditDurationPickerCustomValuePlaceholder}
                value={customDuration > 0 ? customDuration : ''}
                type="number"
                inputProps={{ min: 0, 'aria-valuemin': 0 }}
                fullWidth
                autoFocus
                onChange={(e) => {
                  if (isNaN(+e.target.value) || +e.target.value < 0) {
                    setCustomDuration(0);
                  } else {
                    setCustomDuration(+e.target.value);
                  }
                }}
                onKeyDown={(e) => e.key === 'Enter' && submitCustomDuration()}
              />

              <Divider />

              <Stack>
                {presetDurations.map((d) => (
                  <MenuItem key={d} selected={duration === d} onClick={() => didSelectPresetDuration(d)} dense>
                    {strings.stepEditDurationPickerValueLabel(d)}
                  </MenuItem>
                ))}
              </Stack>
            </Stack>
          </Popover>
        )}
      </Box>
    );
  }
);
