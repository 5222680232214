import { ConnectedAppKind } from '@/models';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../hooks';
import { ConnectedAppIcon } from './ConnectedAppIcon';

export interface ConnectedAppsSectionHeaderProps {
  sx?: SxProps;
  className?: string;
  kind: ConnectedAppKind;
}

export const ConnectedAppsSectionHeader = observer(({ sx = [], className, kind }: ConnectedAppsSectionHeaderProps) => {
  const { localization } = useServices();

  return (
    <ListItem sx={sx} className={className}>
      <ListItemIcon>
        <ConnectedAppIcon kind={kind} />
      </ListItemIcon>

      <ListItemText
        primary={localization.localizedStrings.connectedApps.connectedAppTitle(kind)}
        primaryTypographyProps={{ fontWeight: '600' }}
      />
    </ListItem>
  );
});
