import { PlannerContents, PlannerListKind } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, LocalizationService, RouteService } from '@/services';
import { Loadable, PlannerContentsLoadable, PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { isAfter } from 'date-fns';
import { sumBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';
import { PlannerPlanningTabsViewModel } from './PlannerPlanningTabsViewModel';
import {
  AppDonePlannerListViewModel,
  AppInboxPlannerListViewModel,
  AppTodayPlannerListViewModel,
  AppUpcomingPlannerListViewModel,
  PlannerListViewModel
} from './lists';

export interface PlannerPlanningViewModel extends UpdatableViewModel, PlannerPlanningTabsViewModel {
  readonly inboxViewModel: PlannerListViewModel;
  readonly todayViewModel: PlannerListViewModel;
  readonly upcomingViewModel: PlannerListViewModel;
  readonly doneViewModel: PlannerListViewModel;

  listViewModelForKind(kind: PlannerListKind): PlannerListViewModel;
  listItemsCountForKind(kind: PlannerListKind): number;
  reload(): void;
}

export class AppPlannerPlanningViewModel extends BaseUpdatableViewModel implements PlannerPlanningViewModel {
  readonly inboxViewModel: PlannerListViewModel;
  readonly todayViewModel: PlannerListViewModel;
  readonly upcomingViewModel: PlannerListViewModel;
  readonly doneViewModel: PlannerListViewModel;

  constructor(
    private readonly _plannerId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _routeService: RouteService = ServiceContainer.services.route,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    super();
    makeObservable(this);

    this.inboxViewModel = new AppInboxPlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );

    this.todayViewModel = new AppTodayPlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );

    this.upcomingViewModel = new AppUpcomingPlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );

    this.doneViewModel = new AppDonePlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [
      this._userStore.subscription,
      this._plannerStore.getCourseSectionsInPlanner(this._plannerId),
      this.plannerContentsLoadable,
      this._workStore.workIcons
    ];
  }

  @computed
  private get plannerContentsLoadable(): PlannerContentsLoadable {
    return this._plannerStore.getPlannerContentsInPlanner(this._plannerId);
  }

  @computed
  private get plannerContents(): PlannerContents {
    return this.plannerContentsLoadable.data;
  }

  @computed
  get inboxBadgeCount(): number {
    if (!this.hasData) {
      return 0;
    }

    return sumBy(this.inboxViewModel.groups, (group) => group.items.length);
  }

  @computed
  get todayBadgeCount(): number {
    if (!this.hasData) {
      return 0;
    }

    return sumBy(
      this.plannerContents.todayList.groups ?? [],
      (group) =>
        group.items.filter((item) => {
          switch (item.item.case) {
            case 'calendarEvent': {
              const now = this._dateService.now;
              const event = item.item.value;
              return !event.isAllDay && event.endTime != null && isAfter(event.endTime.toDate(), now);
            }

            case 'work': {
              const work = item.item.value;
              if (work == null || item.plannedWork?.timeSlot == null) {
                return false;
              }

              return work.status !== WorkStatus.COMPLETED && !item.plannedWork.timeSlot.isAllDay;
            }

            default:
              return false;
          }
        }).length
    );
  }

  listViewModelForKind(kind: PlannerListKind): PlannerListViewModel {
    switch (kind) {
      case 'inbox':
        return this.inboxViewModel;
      case 'today':
        return this.todayViewModel;
      case 'upcoming':
        return this.upcomingViewModel;
      case 'done':
        return this.doneViewModel;
      default:
        throw new Error('List kind not supported.');
    }
  }

  listItemsCountForKind(kind: PlannerListKind): number {
    if (!this.hasData) {
      return 0;
    }

    const vm = this.listViewModelForKind(kind);
    return sumBy(vm.groups ?? [], (group) => group.items.length);
  }

  reload() {
    void this.reloadData();
  }
}
