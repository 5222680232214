import {
  UserDashboardCalendarItemPosition,
  UserDashboardCalendarWeekDayViewModel,
  UserDashboardCalendarWeekPriority
} from '@/viewmodels';
import { Box, Divider } from '@mui/material';
import { SxProps } from '@mui/system';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { UserDashboardCalendarCurrentTimeIndicator } from '../shared';
import {
  UserDashboardCalendarWeekCalendarEvent,
  UserDashboardCalendarWeekPeriodView,
  UserDashboardCalendarWeekPlannedWork,
  UserDashboardCalendarWeekWork
} from './items';
import { UserDashboardCalendarWeekNoteView } from './items/UserDashboardCalendarWeekNoteView';

export interface UserDashboardCalendarWeekDayColumnProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardCalendarWeekDayViewModel;
  pointsPerHour: number;
  isFirstDay: boolean;
}

export const UserDashboardCalendarWeekDayColumn = observer(
  ({ sx = [], className, viewModel, pointsPerHour, isFirstDay }: UserDashboardCalendarWeekDayColumnProps) => {
    const { priorities, isToday } = viewModel;

    return (
      <Box sx={{ ...sx, position: 'relative', boxSizing: 'border-box' }} className={className}>
        <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
          {times(24).map((i) => (
            <Divider
              key={i}
              sx={{
                position: 'absolute',
                top: pointsPerHour * i,
                left: 0,
                right: 0,
                zIndex: 50
              }}
            />
          ))}

          {priorities.map((item) => renderItem(item))}

          {isToday && (
            <UserDashboardCalendarCurrentTimeIndicator pointsPerHour={pointsPerHour} isFirstDay={isFirstDay} />
          )}
        </Box>
      </Box>
    );
  }
);

function renderItem(item: UserDashboardCalendarWeekPriority): ReactElement | null {
  function getPositionStyleForPosition(position: UserDashboardCalendarItemPosition): SxProps {
    const itemOffset = item.rank / (item.othersCount + 1);

    return {
      position: 'absolute',
      top: position.top,
      left: `calc((100% - 0px) * ${itemOffset} + 4px)`,
      right: 4 + (item.rank < item.othersCount ? 8 : 0),
      zIndex: 80 + item.rank
    };
  }

  switch (item.item.case) {
    case 'period': {
      const period = item.item.value;
      return (
        <UserDashboardCalendarWeekPeriodView
          key={period.id}
          period={period}
          isOverlapping={item.othersCount > 0}
          sx={getPositionStyleForPosition(period.position)}
        />
      );
    }

    case 'calendarEvent': {
      const event = item.item.value;
      return (
        <UserDashboardCalendarWeekCalendarEvent
          key={event.id}
          event={event}
          sx={getPositionStyleForPosition(event.position)}
          showBorder={item.othersCount > 0}
        />
      );
    }

    case 'work':
    case 'publishedWork': {
      return (
        <UserDashboardCalendarWeekWork
          key={item.item.value.id}
          work={item.item}
          sx={getPositionStyleForPosition(item.item.value.position)}
          showBorder={item.othersCount > 0}
        />
      );
    }

    case 'note':
      return (
        <UserDashboardCalendarWeekNoteView
          key={item.item.value.id}
          note={item.item.value}
          sx={getPositionStyleForPosition(item.item.value.position)}
          showBorder={item.othersCount > 0}
        />
      );

    case 'plannedWork': {
      const plannedWork = item.item.value;
      return (
        <UserDashboardCalendarWeekPlannedWork
          key={plannedWork.id}
          plannedWork={plannedWork}
          showBorder={item.othersCount > 0}
          sx={getPositionStyleForPosition(plannedWork.position)}
        />
      );
    }

    default:
      return null;
  }
}
