import i18n from 'translate';

export const PublishedWorkDetailsCancelConfirmationDialogStrings = {
  confirmButtonLabel: () => i18n.t('publishedWork.details.cancelConfirmationDialog.confirmButtonLabel'),
  message: () => i18n.t('publishedWork.details.cancelConfirmationDialog.message'),
  title: () => i18n.t('publishedWork.details.cancelConfirmationDialog.title')
};

export const PublishedWorkDetailsStrings = {
  cancelConfirmationDialog: PublishedWorkDetailsCancelConfirmationDialogStrings,
  cancelWorkOptionLabel: () => i18n.t('publishedWork.details.cancelWorkOptionLabel'),
  copyWorkOptionLabel: () => i18n.t('publishedWork.details.copyWorkOptionLabel'),
  distributeWorkOptionLabel: () => i18n.t('publishedWork.details.distributeWorkOptionLabel'),
  dueDateLabel: () => i18n.t('publishedWork.details.dueDateLabel'),
  dueDateNone: () => i18n.t('publishedWork.details.dueDateNone'),
  duplicateWorkOptionLabel: () => i18n.t('publishedWork.details.duplicateWorkOptionLabel'),
  maxGradeLabel: () => i18n.t('publishedWork.details.maxGradeLabel'),
  maxGradeNone: () => i18n.t('publishedWork.details.maxGradeNone'),
  repeatWorkOptionLabel: () => i18n.t('publishedWork.details.repeatWorkOptionLabel'),
  scheduleTimeBannerLabel: (date: string) => i18n.t('publishedWork.details.scheduleTimeBannerLabel', { date }),
  title: () => i18n.t('publishedWork.details.title')
};
