import { SxProps } from '@mui/system';
import { MainScreenPlannerSideBar } from '../../planner';

export interface UserDashboardSideBarProps {
  sx?: SxProps;
  className?: string;
  id: string;

  forceFullScreenWidth?: boolean;
  onClose?: () => void;
}

export function UserDashboardSideBar(props: UserDashboardSideBarProps) {
  return <MainScreenPlannerSideBar {...props} />;
}
