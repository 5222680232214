import { Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';
import { ScheduleCycleBellTimesPage } from '../../shared';

export interface AdminSchoolScheduleCycleBellTimesPageProps {
  sx?: SxProps;
  className?: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleBellTimesPage = observer(
  ({ sx = [], className, isReadOnly }: AdminSchoolScheduleCycleBellTimesPageProps) => {
    return (
      <Stack
        className={className}
        sx={[
          {
            width: '100%',
            height: '100%'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Stack
          spacing={1}
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 1000
          }}
        >
          <Typography variant="h6">{LocalizedStrings.admin.schedule.bellTimesTitle()}</Typography>
          <Divider />

          <ScheduleCycleBellTimesPage isReadOnly={isReadOnly} />
        </Stack>
      </Stack>
    );
  }
);
