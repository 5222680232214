import { WbSunnyRounded } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDayStateIndicator } from './UserDashboardCalendarDayStateIndicator';

export interface UserDashboardCalendarDayEmptyIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarDayEmptyIndicator = observer(
  ({ sx = [], className }: UserDashboardCalendarDayEmptyIndicatorProps) => {
    const strings = LocalizedStrings.calendar.day;

    return (
      <UserDashboardCalendarDayStateIndicator
        sx={sx}
        className={className}
        title={strings.emptyIndicatorTitle()}
        subtitle={strings.emptyIndicatorSubtitle()}
        renderIcon={(size, color) => <WbSunnyRounded sx={{ width: size, height: size, color }} />}
      />
    );
  }
);
