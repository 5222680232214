export const PlannedWorkEditComponentIds = {
  weekBody: 'planned-work-edit-week-body',
  weekScrollableView: 'planned-work-edit-week-scrollable-view',
  interactionLayer: 'planned-work-edit-interaction-layer',
  infoView: 'planned-work-edit-info-view',
  infoViewIcon: 'planned-work-edit-info-view-icon',
  infoViewLabelStack: 'planned-work-edit-info-view-label-stack',
  infoViewStartTimeLabel: 'planned-work-edit-info-view-start-time-label',
  infoViewDurationLabel: 'planned-work-edit-info-view-duration-label',
  infoViewCompletedLabel: 'planned-work-edit-info-view-completed-label'
};
