import { CancelScheduleSendRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { SchoolSettingsDangerAction } from '../../school';
import { ConfirmationDialog } from '../../utils';

export interface AdminUnshareSchoolActionProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminUnshareSchoolAction = observer(({ sx = [], className, schoolId }: AdminUnshareSchoolActionProps) => {
  const { route, userStore } = useServices();
  const school = userStore.getSchoolForId(schoolId)!;
  const navigate = useNavigate();
  const strings = LocalizedStrings.admin.general.manageActions.unshare;

  const [showUnshareConfirmation, setShowUnshareConfirmation] = useState(false);
  const [isUnsharing, setIsUnsharing] = useState(false);
  const [unsharingError, setUnsharingError] = useState<Error | null>(null);

  async function onUnshareConfirmation(hasConfirmed: boolean) {
    setShowUnshareConfirmation(false);

    if (hasConfirmed) {
      setIsUnsharing(true);
      setUnsharingError(null);

      try {
        await userStore.unshareSchool(schoolId);
        const destinationPlannerId = userStore.idOfLastOwnedPlannerShowed;
        const destination = destinationPlannerId != null ? route.resolvePlannerLocation(destinationPlannerId) : '/';
        navigate(destination, { replace: true });
      } catch (error) {
        setUnsharingError(error as Error);
        captureException(error);
        setIsUnsharing(false);
      }
    }
  }

  return (
    <Box sx={sx} className={className}>
      <SchoolSettingsDangerAction
        title={strings.title()}
        subtitle={strings.subtitle()}
        buttonTitle={strings.actionTitle()}
        buttonIcon={<CancelScheduleSendRounded />}
        action={() => setShowUnshareConfirmation(true)}
        disabled={isUnsharing}
        isLoading={isUnsharing}
        error={unsharingError != null ? strings.errorMessage() : undefined}
      />

      {showUnshareConfirmation && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.confirmationTitle()}
          message={strings.confirmationMessage(school.school!.name)}
          confirmButtonLabel={strings.confirmationSubmit()}
          onSubmit={(hasConfirmed) => void onUnshareConfirmation(hasConfirmed)}
        />
      )}
    </Box>
  );
});
