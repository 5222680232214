import { formatDuration, intervalToDuration } from 'date-fns';
import i18n from 'translate';
import { DayOfWeek } from '../../models';

export const DateTimeStrings = {
  dayOfWeekSingleLetterTitle: {
    1: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.monday'),
    2: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.tuesday'),
    3: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.wednesday'),
    4: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.thursday'),
    5: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.friday'),
    6: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.saturday'),
    7: () => i18n.t('dateTime.dayOfWeekSingleLetterTitle.sunday')
  } as Record<DayOfWeek, () => string>,
  dayOfWeekShortTitle: {
    1: () => i18n.t('dateTime.dayOfWeekShortTitle.monday'),
    2: () => i18n.t('dateTime.dayOfWeekShortTitle.tuesday'),
    3: () => i18n.t('dateTime.dayOfWeekShortTitle.wednesday'),
    4: () => i18n.t('dateTime.dayOfWeekShortTitle.thursday'),
    5: () => i18n.t('dateTime.dayOfWeekShortTitle.friday'),
    6: () => i18n.t('dateTime.dayOfWeekShortTitle.saturday'),
    7: () => i18n.t('dateTime.dayOfWeekShortTitle.sunday')
  } as Record<DayOfWeek, () => string>,
  dayOfWeekTitle: {
    1: () => i18n.t('dateTime.dayOfWeekTitle.monday'),
    2: () => i18n.t('dateTime.dayOfWeekTitle.tuesday'),
    3: () => i18n.t('dateTime.dayOfWeekTitle.wednesday'),
    4: () => i18n.t('dateTime.dayOfWeekTitle.thursday'),
    5: () => i18n.t('dateTime.dayOfWeekTitle.friday'),
    6: () => i18n.t('dateTime.dayOfWeekTitle.saturday'),
    7: () => i18n.t('dateTime.dayOfWeekTitle.sunday')
  } as Record<DayOfWeek, () => string>,
  pickerClearButtonLabel: () => i18n.t('dateTime.pickerClearButtonLabel'),
  shortDurationFormat: (duration: number) => {
    const formatDistanceLocale: Record<string, (count: number) => string> = {
      xSeconds: (count) => i18n.t('dateTime.shortDurationFormat.seconds', { count }),
      xMinutes: (count) => i18n.t('dateTime.shortDurationFormat.minutes', { count }),
      xHours: (count) => i18n.t('dateTime.shortDurationFormat.hours', { count })
    };

    return formatDuration(intervalToDuration({ start: 0, end: duration * 60 * 1_000 }), {
      locale: { formatDistance: (token, count) => formatDistanceLocale[token](count) }
    });
  }
};
