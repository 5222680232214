import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { chain, last } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { Loadable, SchoolDataStore, SchoolTeacherAccountsLoadable, UserDataStore } from '../../stores';
import { localizedCompare, propertiesHaveMatchForSearchText } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminTeachersListViewModel extends UpdatableViewModel {
  readonly schoolId: string;
  readonly teachersCount: number;
  readonly teachers: Account[];
  readonly currentScheduleCycleId: string | undefined;
  readonly isReadOnly: boolean;
  searchText: string;
  sendEmailInvitationToTeachers(teacherIds: string[]): Promise<void>;
}

export class AppAdminTeachersListViewModel extends BaseUpdatableViewModel implements AdminTeachersListViewModel {
  @observable private _searchText = '';

  constructor(
    public readonly schoolId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get teachersLoadable(): SchoolTeacherAccountsLoadable {
    return this._schoolStore.getTeachers(this.schoolId);
  }

  @computed
  private get school(): SchoolInformation {
    return this._userStore.getSchoolForId(this.schoolId)!;
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.teachersLoadable];
  }

  @computed
  get teachersCount(): number {
    return this.teachersLoadable.values.length;
  }

  @computed
  get teachers(): Account[] {
    return chain(this.teachersLoadable.values)
      .filter((a) =>
        propertiesHaveMatchForSearchText(this._searchText, [
          a.fullName,
          a.emailAddress,
          { value: a.id, method: 'exact', caseSensitive: true }
        ])
      )
      .sort((a1, a2) => localizedCompare(a1.fullName, a2.fullName, this._localization.currentLocale))
      .value();
  }

  @computed
  get currentScheduleCycleId() {
    if (this.school.school == null) {
      return undefined;
    }

    return last(
      this.school.school.scheduleCycleIds.filter((id) => !this.school.school?.ignoredScheduleCycleIds.includes(id))
    );
  }

  @computed
  get isReadOnly(): boolean {
    return this.school.school?.isArchived === true;
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  async sendEmailInvitationToTeachers(teacherIds: string[]) {
    await this._schoolStore.sendTeacherInvitationEmail(this.schoolId, teacherIds);
  }
}
