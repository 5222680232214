export interface CourseSectionListStrings {
  readonly createCourseSection: string;
  readonly delete: string;
  readonly deleteConfirmButton: string;
  readonly deleteConfirmKeepWorks: string;
  readonly deleteConfirmMessage: string;
  readonly deleteConfirmTitle: string;
  readonly edit: string;
  readonly emptyIndicatorTitle: string;
  readonly manageClassroomCourseSections: string;
  readonly readOnlyLabel: string;
  readonly subtitle: string;
  readonly title: string;
  readonly sourceName: (externalSourceName: string | undefined) => string | undefined;
}

export const EnglishCourseSectionListStrings: CourseSectionListStrings = {
  createCourseSection: 'Add class',
  delete: 'Delete',
  deleteConfirmButton: 'Delete',
  deleteConfirmKeepWorks: 'Keep work assigned to this class',
  deleteConfirmMessage: 'The deletion of a class is a non-reversable action. Do you wish to proceed anyway?',
  deleteConfirmTitle: 'Delete class',
  edit: 'Edit',
  emptyIndicatorTitle: 'No classes',
  manageClassroomCourseSections: 'Manage external classes',
  subtitle: 'These classes are only visible to you and cannot be shared.',
  readOnlyLabel: 'You do not have the rights to edit the classes.',
  title: 'My personal classes',
  sourceName: (externalSourceName: string | undefined) =>
    externalSourceName != null ? `Defined in ${externalSourceName}` : undefined
};

export const FrenchCourseSectionListStrings: CourseSectionListStrings = {
  createCourseSection: 'Ajouter un cours',
  delete: 'Supprimer',
  deleteConfirmButton: 'Supprimer',
  deleteConfirmKeepWorks: 'Conserver les tâches assignées à ce cours',
  deleteConfirmMessage: 'La suppression d’un cours est une action non-réversible. Êtes-vous sûr de vouloir poursuivre?',
  deleteConfirmTitle: 'Supprimer le cours',
  edit: 'Modifier',
  emptyIndicatorTitle: 'Aucun cours',
  manageClassroomCourseSections: 'Gérer mes cours externes',
  readOnlyLabel: 'Vous ne possédez pas les droits nécessaires pour modifier les cours.',
  subtitle: 'Ces cours ne sont visibles que par vous et ne peuvent être partagés.',
  title: 'Mes cours personels',
  sourceName: (externalSourceName: string | undefined) =>
    externalSourceName != null ? `Défini dans ${externalSourceName}` : undefined
};
