import { Card, CardProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';

export interface SchoolScreenCardProps extends CardProps {
  forcedBackgroundColor?: string;
  forcedBorderColor?: string;
}

const ContentCardComponent = forwardRef<HTMLDivElement, SchoolScreenCardProps>(
  ({ forcedBackgroundColor, forcedBorderColor, ...props }: SchoolScreenCardProps, ref) => {
    const theme = useTheme();

    return (
      <Card
        {...props}
        elevation={0}
        ref={ref}
        sx={{
          ...props.sx,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: forcedBorderColor ?? theme.palette.divider,
          backgroundColor: forcedBackgroundColor ?? 'transparent',
          backgroundImage: 'none'
        }}
      />
    );
  }
);

ContentCardComponent.displayName = 'ContentCard';

export const ContentCard = observer(ContentCardComponent);
