import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { PlannerItem } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { chain } from 'lodash';
import { timeOfDayIsBetweenOthers } from '../TimeOfDay';
import { timestampToTimeOfDay } from '../Timestamp';

export function isItemDueDuringPeriodOccurrence(
  itemDate: Timestamp,
  courseSectionId: string,
  occurrence: CourseSectionOccurrence,
  allItems: PlannerItem[]
): boolean {
  const matchingOccurrences = chain(allItems)
    .map((item) => {
      if (item.item.case === 'courseSectionOccurrence') {
        const value = item.item.value;
        return timeOfDayIsBetweenOthers(timestampToTimeOfDay(itemDate), value.startTime!, value.endTime!, {
          endInclusive: false
        })
          ? value
          : undefined;
      }
    })
    .compact()
    .value();

  if (matchingOccurrences.length === 0) {
    return false;
  } else if (matchingOccurrences.length === 1) {
    return matchingOccurrences[0] === occurrence;
  } else {
    const resolvedOccurrence =
      matchingOccurrences.find((occ) => occ.courseSectionId === courseSectionId) ?? matchingOccurrences[0];
    return resolvedOccurrence === occurrence;
  }
}
