import { useServices, useViewModel } from '@/hooks';
import { AuthProvider } from '@/models';
import { Box, BoxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSxArray } from '../../utils';
import { ConfirmationDialog } from '../utils';
import { LoginProviders } from './LoginProviders';

export interface LoginProps extends BoxProps {
  className?: string;

  /**
   * Location to navigate to on successful login. Optional. Will navigate to /home by default.
   */
  referrer?: string;
}

export const Login = observer(({ sx = [], className, referrer, ...props }: LoginProps) => {
  const { localization, analytics } = useServices();
  const viewModel = useViewModel((viewModels) => viewModels.createLoginViewModel('sign-in'));

  const strings = localization.localizedStrings.login;
  const utilsStrings = localization.localizedStrings.utils;

  const navigate = useNavigate();

  async function signIn(provider: AuthProvider) {
    await viewModel.signIn(provider);
    if (viewModel.error == null) {
      navigate(referrer ?? '/home', { replace: true });
    }
  }

  useEffect(() => analytics.logScreenView_Onboarding_Login(), []);

  return (
    <Box
      {...props}
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <LoginProviders signIn={signIn} />
      {/* Error message */}
      {viewModel.error != null && (
        <Typography
          variant="caption"
          align="center"
          sx={{
            color: (theme) => theme.palette.error.main,
            mt: 3
          }}
        >
          {strings.errorMessage(viewModel.error)}
        </Typography>
      )}
      {viewModel.showExistingCredentialsAlertConfirmationForProvider && (
        <ConfirmationDialog
          isOpen={true}
          title={utilsStrings.credentialsAlreadyLinkedWarningTitle}
          message={utilsStrings.credentialsAlreadyLinkedWarningMessage}
          cancelButtonLabel={utilsStrings.credentialsAlreadyLinkedCancelButton}
          confirmButtonLabel={utilsStrings.credentialsAlreadyLinkedConnectButton}
          onSubmit={() => void viewModel.forceSignIn(viewModel.showExistingCredentialsAlertConfirmationForProvider!)}
        />
      )}
    </Box>
  );
});
