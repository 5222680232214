import { useServices } from '@/hooks';
import { Timestamp, timeOfDayToDate } from '@/models';
import { AllPlannerItemsPasteboardContentKind } from '@/services';
import { UserDashboardCalendarDaySpecificDateViewModel, UserDashboardCalendarDayTimedItemInfo } from '@/viewmodels';
import { AddRounded, ContentPasteRounded } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { differenceInMinutes, formatDistanceStrict, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../../utils';
import { CreateWorkOptionsMenu } from '../../CreateWorkOptionsMenu';
import { UserDashboardCalendarDayEmptyIndicator } from './UserDashboardCalendarDayEmptyIndicator';
import { UserDashboardCalendarDayTimedItemsStack } from './UserDashboardCalendarDayTimedItemsStack';
import { UserDashboardCalendarDayUpNextEmptyIndicator } from './UserDashboardCalendarDayUpNextEmptyIndicator';

export interface UserDashboardCalendarDaySpecificDateTimedItemsViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardCalendarDaySpecificDateViewModel;
}

export const UserDashboardCalendarDaySpecificDateTimedItemsView = observer(
  ({ sx = [], className, viewModel }: UserDashboardCalendarDaySpecificDateTimedItemsViewProps) => {
    const { dateService, pasteboard, navigation, settings } = useServices();
    const { showPastItems, pastItems, currentOrUpcomingItems, upcomingItems, isToday, isReadOnly, canPublishWork } =
      viewModel;
    const hasItems = pastItems.length > 0 || currentOrUpcomingItems.length > 0 || upcomingItems.length > 0;
    const pasteboardContent = pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind);

    const location = useLocation();
    const navigate = useNavigate();

    const strings = LocalizedStrings.calendar.day;
    const [createWorkMenuAnchor, setCreateWorkMenuAnchor] = useState<HTMLButtonElement | undefined>();

    function onCreateWorkButtonPressed(e: MouseEvent<HTMLButtonElement>) {
      setCreateWorkMenuAnchor(e.currentTarget);
    }

    function showCreateWork() {
      setCreateWorkMenuAnchor(undefined);
      navigation.navigateToWorkEdit(navigate, location, undefined, {
        dueTime: Timestamp.fromDate(set(viewModel.date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
        isDueAllDay: true
      });
    }

    function showCreateNote() {
      setCreateWorkMenuAnchor(undefined);
      navigation.navigateToNoteEdit(navigate, location, undefined, {
        time: Timestamp.fromDate(set(viewModel.date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
        isAllDay: true
      });
    }

    function showCreatePublishedWork() {
      setCreateWorkMenuAnchor(undefined);
      navigation.navigateToPublishedWorkEdit(navigate, location, undefined, {
        dueDate: set(viewModel.date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        isDueAllDay: true
      });
    }

    function onPaste() {
      setCreateWorkMenuAnchor(undefined);
      pasteboard.setPasteContext({ date: viewModel.date });
    }

    const showPastItemsHeaderAction = {
      title: showPastItems ? strings.hidePastItemsButtonTitle() : strings.showPastItemsButtonTitle(),
      onClick: () => (viewModel.showPastItems = !showPastItems)
    };

    return (
      <Box
        className={className}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {showPastItems && pastItems.length > 0 && (
          <UserDashboardCalendarDayTimedItemsStack
            items={pastItems}
            title={isToday ? strings.pastItemsSectionTitle() : undefined}
            isToday={isToday}
            isReadOnly={isReadOnly}
            canPublishWork={canPublishWork}
            headerAction={
              !settings.calendarDayOptions.alwaysShowCurrentDatePastItems ? showPastItemsHeaderAction : undefined
            }
          />
        )}
        {isToday && currentOrUpcomingItems.length > 0 && (
          <UserDashboardCalendarDayTimedItemsStack
            sx={{ mt: showPastItems ? 3 : 0 }}
            title={getCurrentOrUpcomingItemTitle(
              currentOrUpcomingItems[0],
              dateService.now,
              currentOrUpcomingItems.length > 1
            )}
            headerAction={
              !settings.calendarDayOptions.alwaysShowCurrentDatePastItems && pastItems.length > 0 && !showPastItems
                ? showPastItemsHeaderAction
                : undefined
            }
            items={currentOrUpcomingItems}
            isToday={isToday}
            canPublishWork={canPublishWork}
            isReadOnly={isReadOnly}
          />
        )}
        {hasItems && (isToday || upcomingItems.length > 0) && (
          <UserDashboardCalendarDayTimedItemsStack
            sx={{ mt: currentOrUpcomingItems.length > 0 || (showPastItems && pastItems.length > 0) ? 3 : 0 }}
            title={isToday ? strings.upNextSectionTitle() : undefined}
            items={upcomingItems}
            isToday={isToday}
            isReadOnly={isReadOnly}
            canPublishWork={canPublishWork}
            renderEmpty={isToday && hasItems ? () => <UserDashboardCalendarDayUpNextEmptyIndicator /> : undefined}
            headerAction={
              !settings.calendarDayOptions.alwaysShowCurrentDatePastItems &&
              pastItems.length > 0 &&
              !showPastItems &&
              currentOrUpcomingItems.length === 0
                ? showPastItemsHeaderAction
                : undefined
            }
          />
        )}
        {!hasItems && <UserDashboardCalendarDayEmptyIndicator sx={{ my: 0, ml: 1 }} />}
        {viewModel.canCreateWork && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'flex-end',
              mt: 3
            }}
          >
            {pasteboardContent != null && (
              <Button
                variant="contained-grey"
                onClick={onPaste}
                startIcon={<ContentPasteRounded />}
                sx={{ px: 2, borderRadius: 50 }}
              >
                {strings.pasteInDayButtonTitle()}
              </Button>
            )}

            <Button
              variant="contained"
              onClick={onCreateWorkButtonPressed}
              startIcon={<AddRounded />}
              sx={{ borderRadius: 50 }}
            >
              {strings.createWorkInDayButtonTitle()}
            </Button>
          </Stack>
        )}
        <CreateWorkOptionsMenu
          open={createWorkMenuAnchor != null}
          anchorEl={createWorkMenuAnchor}
          onClose={() => setCreateWorkMenuAnchor(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          canPublishWork={viewModel.canPublishWork}
          onCreatePersonalWorkOptionSelect={showCreateWork}
          onCreateNoteOptionSelect={showCreateNote}
          onPublishWorkOptionSelect={showCreatePublishedWork}
          onPaste={onPaste}
        />
      </Box>
    );
  }
);

function getCurrentOrUpcomingItemTitle(
  item: UserDashboardCalendarDayTimedItemInfo,
  now: Date,
  hasManyItems: boolean
): string {
  const strings = LocalizedStrings.calendar.day;

  if (item.case === 'work' || item.case === 'publishedWork') {
    const dueTime = timeOfDayToDate(item.value.startTime);
    return differenceInMinutes(dueTime, now) > 0
      ? strings.upcomingWorkDueInSectionTitle(formatDistanceStrict(dueTime, now))
      : strings.upcomingWorkDueNowSectionTitle();
  }

  return item.value.state === 'active'
    ? hasManyItems
      ? strings.currentItems()
      : strings.upcomingItemEndingInSectionTitle(formatDistanceStrict(timeOfDayToDate(item.value.endTime), now))
    : strings.upcomingItemStartingInSectionTitle(formatDistanceStrict(timeOfDayToDate(item.value.startTime), now));
}
