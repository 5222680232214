import { ViewModelProvider } from '@/providers';
import { DependencyList, useMemo } from 'react';
import { useServices } from './UseServicesHook';

export function useViewModel<T>(
  viewModel: (viewModels: ViewModelProvider) => Exclude<T, void>,
  deps: DependencyList | undefined = []
): T {
  const { viewModels } = useServices();
  return useMemo(() => viewModel(viewModels), deps);
}
