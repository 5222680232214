import i18n from 'translate';

export const CreateSchoolPickerStrings = {
  personalSchoolOptionSubtitle: () => i18n.t('school.create.picker.personalSchoolOptionSubtitle'),
  personalSchoolOptionTitle: () => i18n.t('school.create.picker.personalSchoolOptionTitle'),
  personalSchoolShareMessage: () => i18n.t('school.create.picker.personalSchoolShareMessage'),
  sharedSchoolOptionSubtitle: () => i18n.t('school.create.picker.sharedSchoolOptionSubtitle'),
  sharedSchoolOptionTitle: () => i18n.t('school.create.picker.sharedSchoolOptionTitle'),
  title: () => i18n.t('school.create.picker.title')
};

export const SchoolStrings = {
  create: {
    picker: CreateSchoolPickerStrings,
    subscribeTitle: () => i18n.t('school.create.subscribeTitle')
  }
};
