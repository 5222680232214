import { DialogContent as MUIDialogContent, DialogContentProps as MUIDialogContentProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface DialogContentPadding {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export interface DialogContentProps extends MUIDialogContentProps {
  /**
   * Overrides default value for padding.
   */
  padding?: DialogContentPadding;
}

export const DialogContent = ({ padding, ...props }: DialogContentProps) => {
  const defaultPadding = { xs: 2, sm: 2 };

  const sx: SxProps<Theme> = {
    ...props.sx,
    borderBottom: 'none',
    pl: padding?.left ?? defaultPadding,
    pr: padding?.right ?? defaultPadding,
    pb: padding?.bottom ?? defaultPadding,
    pt: padding?.top ?? defaultPadding
  };

  return <MUIDialogContent {...props} dividers sx={sx} />;
};
