import { useServices } from '@/hooks';
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { gridPageSizeSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { isSxArray } from '../../../utils';
import { TablePageSizeOptions } from './TableUtils';

export interface TablePaginationPageSizeSelectProps {
  sx?: SxProps;
  className?: string;
}

export const TablePaginationPageSizeSelect = ({ sx = [], className }: TablePaginationPageSizeSelectProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.utils;

  const gridApiRef = useGridApiContext();
  const pageSize = useGridSelector(gridApiRef, gridPageSizeSelector);

  function onPageSizeChange(event: SelectChangeEvent<number>) {
    const newPageSize = event.target.value as number;
    gridApiRef.current.setPageSize(newPageSize);
  }

  return (
    <Stack
      className={className}
      direction="row"
      spacing={1}
      sx={[
        {
          alignItems: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Typography variant="subtitle2">{strings.tablePageSizePickerLabel}</Typography>
      <Select
        value={pageSize}
        onChange={onPageSizeChange}
        size="small"
        inputProps={{ sx: { paddingTop: '10px' } }}
        sx={{ width: 100 }}
      >
        {TablePageSizeOptions.map((size) => (
          <MenuItem key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
