import { UserPreference, UserPreferenceKey } from '@/models';
import { UserTransportService } from '@/transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type UserPreferenceLoadable = LoadableValue<UserPreference>;

export class AppUserPreferenceLoadable extends BaseLoadableValue<UserPreference> implements UserPreferenceLoadable {
  constructor(
    private readonly _key: UserPreferenceKey,
    private readonly _userTransport: UserTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<UserPreference> {
    const value = await this._userTransport.getPreference(this._key);
    return { key: this._key, value };
  }
}
