import { Stack, Typography, TypographyProps } from '@mui/material';

export interface TypographyParagraphsProps extends TypographyProps {
  children: string;
}

export const TypographyParagraphs = ({ children, ...props }: TypographyParagraphsProps) => {
  return (
    <Stack spacing={1}>
      {children.split('\n').map((str, index) => (
        <Typography key={index} {...props}>
          {str}
        </Typography>
      ))}
    </Stack>
  );
};
