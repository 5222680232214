import { differenceInMinutesBetweenTimeOfDays, TimeOfDay, WorkIconInfo } from '@/models';
import { PlannedWorkEditInfo } from '@/viewmodels';
import { CheckCircleRounded } from '@mui/icons-material';
import { alpha, Box, CardActionArea, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isAfter } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useLayoutEffect } from 'react';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { dateFromDayAndTimeOfDay } from '../../../utils';
import { formatCalendarItemTimes, PlannedWorkIcon } from '../../shared';
import { updateDocumentElement } from '../../utils';
import { PlannedWorkEditComponentIds } from './PlannedWorkEditComponentIds';
import { usePlannedWorkEditInteractionContext } from './PlannedWorkEditInteractionContext';

export interface PlannedWorkEditInfoViewProps {
  sx?: SxProps;
  className?: string;
  id?: string;
  info: PlannedWorkEditInfo;
  isDimmed: boolean;
  color: string;
  icon: WorkIconInfo;
  disabled?: boolean;
  canShowCompletedLabel?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export const PlannedWorkEditInfoView = observer(
  ({
    sx = [],
    className,
    id,
    info,
    isDimmed,
    color,
    icon,
    onClick,
    disabled,
    canShowCompletedLabel = true
  }: PlannedWorkEditInfoViewProps) => {
    const { dateService } = useServices();
    const strings = LocalizedStrings.plannedWork.edit;
    const dateTimeStrings = LocalizedStrings.dateTime;
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const { isCreatingNewInfo } = usePlannedWorkEditInteractionContext();
    const infoId = info.id;
    const minutesDuration = Math.abs(differenceInMinutesBetweenTimeOfDays(info.startTime, info.endTime));
    const formattedDates = formatCalendarItemTimes(info.startTime, info.endTime, false);
    const formattedDuration = dateTimeStrings.shortDurationFormat(minutesDuration);
    const endDate = dateFromDayAndTimeOfDay(info.day, info.endTime);
    const isInThePast = canShowCompletedLabel && isAfter(dateService.now, endDate);

    useLayoutEffect(() => {
      // Using shared function to make sure the same style is applied whether we are interacting with the view or not.
      updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(info.id, info.startTime, info.endTime, theme);
    }, [info]);

    return (
      <CardActionArea
        id={id}
        className={className}
        onClick={onClick}
        disabled={disabled}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0.5,
          overflow: 'hidden',
          opacity: isDimmed ? 0.5 : 1,
          border: `1px solid ${isDarkTheme ? alpha(color, 0.4) : color}`,
          boxShadow: isCreatingNewInfo && !isDimmed ? 8 : isDimmed ? 0 : 1,
          ...sx
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            alignItems: 'stretch',
            overflow: 'hidden'
          }}
        >
          <PlannedWorkIcon
            id={`${PlannedWorkEditComponentIds.infoViewIcon}-${infoId}`}
            color={color}
            icon={icon}
            sx={{ my: '4px', ml: 0.5 }}
          />

          <Stack
            id={`${PlannedWorkEditComponentIds.infoViewLabelStack}-${infoId}`}
            spacing="2px"
            sx={{
              flex: 1,
              borderRadius: 0.5,
              overflow: 'hidden'
            }}
          >
            <Typography
              variant="caption"
              noWrap
              id={`${PlannedWorkEditComponentIds.infoViewStartTimeLabel}-${infoId}`}
              sx={{
                fontWeight: '600',
                fontSize: 12,
                lineHeight: 1.1,
                pt: 0.5,
                color: theme.palette.text[isInThePast ? 'secondary' : 'primary']
              }}
            >
              {formattedDates}
            </Typography>

            <Typography
              variant="caption"
              id={`${PlannedWorkEditComponentIds.infoViewDurationLabel}-${infoId}`}
              sx={{
                fontSize: 10,
                lineHeight: 1.1,
                fontWeight: '500',
                color: theme.palette.text[isInThePast ? 'secondary' : 'primary']
              }}
            >
              {formattedDuration}
            </Typography>
          </Stack>

          {isInThePast && (
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                alignItems: 'center',
                position: 'absolute',
                bottom: 0,
                right: 2
              }}
            >
              <CheckCircleRounded color="success" sx={{ fontSize: 14 }} />

              <Typography
                variant="caption"
                id={`${PlannedWorkEditComponentIds.infoViewCompletedLabel}-${infoId}`}
                sx={{
                  fontWeight: '500',
                  color: theme.palette.success.main
                }}
              >
                {strings.completedSessionLabel()}
              </Typography>
            </Stack>
          )}
        </Box>
      </CardActionArea>
    );
  }
);

export function updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(
  id: string,
  startTime: TimeOfDay,
  endTime: TimeOfDay,
  theme: Theme
) {
  const strings = LocalizedStrings.dateTime;
  const duration = Math.abs(differenceInMinutesBetweenTimeOfDays(startTime, endTime));
  const isSmall = duration < 35;
  const showIcon = duration >= 20;

  // Using "display: none" to hide elements instead of having conditions in component as we need to manipulate their
  // presence while an interaction is happening.
  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewIcon}-${id}`, (element) => {
    element.style.display = !showIcon ? 'none' : 'flex';
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewLabelStack}-${id}`, (element) => {
    element.style.marginLeft = !showIcon ? theme.spacing(0.5) : '0px';
    element.style.justifyContent = isSmall ? 'center' : 'flex-start';
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewStartTimeLabel}-${id}`, (label) => {
    label.textContent = formatCalendarItemTimes(startTime, endTime, false);
    label.style.display = isSmall ? 'none' : 'block';
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewDurationLabel}-${id}`, (label) => {
    label.textContent = strings.shortDurationFormat(duration);
  });

  updateDocumentElement(`${PlannedWorkEditComponentIds.infoViewCompletedLabel}-${id}`, (label) => {
    label.style.display = isSmall ? 'none' : 'block';
  });
}
