import {
  comparePlannerCourseSectionInfos,
  compareTerms,
  compareTimeOfDays,
  CourseSectionInfo,
  courseSectionInfoToActivity,
  DayOfWeek,
  dayToDate,
  plannerHasAccessKindsForUser,
  schoolHasAccessKindsForUser,
  timeOfDayToDate,
  UserDashboard,
  UserDashboardInfo,
  UserDashboardPlanner,
  UserDashboardSchool
} from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { Loadable, ScheduleCycleDataStore, UserDataStore } from '@/stores';
import { AccessKind as PlannerAccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { ActivitySchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_schedule_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { AccessKind as SchoolAccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { differenceInCalendarDays, format } from 'date-fns';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import LocalizedStrings from 'strings';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../../UpdatableViewModel';
import { getScheduleCycleKind, titleForCycleDay } from '../ScheduleCycleUtils';

export interface ScheduleCycleActivitySchedulesCourseSectionInfo {
  readonly id: string;
  readonly title: string;
  readonly section: string;
  readonly color: string;
  readonly numberOfSchedules: number;
}

export interface ScheduleCycleActivitySchedulesSelectedCourseSectionInfo {
  activity: Activity;
  title: string;
  section: string;
  color: string;
  activitySchedules: ScheduleCycleActivitySchedulesActivityInfo[];
}

export interface ScheduleCycleActivitySchedulesActivityInfo {
  readonly id: string;
  readonly primaryText: string;
  readonly secondaryText: string;
}

export interface ScheduleCycleActivitySchedulesViewModel extends UpdatableViewModel {
  readonly isReadOnly: boolean;
  readonly courseInfos: ScheduleCycleActivitySchedulesCourseSectionInfo[];
  getActivityScheduleInfosForCourseId(id: string): ScheduleCycleActivitySchedulesSelectedCourseSectionInfo | undefined;
  canEditActivitySchedulesForCourseId(id: string): boolean;

  removeActivitySchedule(id: string): Promise<void>;
}

export abstract class AppBaseScheduleCycleActivitySchedulesViewModel
  extends BaseUpdatableViewModel
  implements ScheduleCycleActivitySchedulesViewModel
{
  protected constructor(
    protected readonly _plannerId: string | undefined,
    protected readonly _dashboard: UserDashboardInfo,
    protected readonly _scheduleCycleId: string,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  protected abstract get courseSectionsLoadable(): Loadable<unknown>;

  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionsLoadable];
  }

  protected abstract get courseSections(): CourseSectionInfo[];

  @computed
  protected get currentDashboard(): UserDashboard {
    switch (this._dashboard.kind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboard.id)!;
        return { kind: 'planner', planner } as UserDashboardPlanner;
      }

      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboard.id)!;
        return { kind: 'school', school } as UserDashboardSchool;
      }
    }
  }

  @computed
  protected get isSingleWeekSchedule(): boolean {
    return (
      this.scheduleCycleStore.scheduleCycle.isDayOfWeekAligned &&
      this.scheduleCycleStore.scheduleCycle.cycleDayCount === 7
    );
  }

  @computed
  get isReadOnly(): boolean {
    const { userId } = this._userStore.user;

    switch (this.currentDashboard.kind) {
      case 'planner':
        return !plannerHasAccessKindsForUser(userId, this.currentDashboard.planner, PlannerAccessKind.FULL_ACCESS);

      case 'school': {
        const { school } = this.currentDashboard;
        const hasFullAccess = !schoolHasAccessKindsForUser(userId, school, SchoolAccessKind.FULL_ACCESS);

        return !hasFullAccess || school.isStudent;
      }
    }
  }

  @computed
  get courseInfos(): ScheduleCycleActivitySchedulesCourseSectionInfo[] {
    return chain(this.courseSections)
      .sort((c1, c2) => comparePlannerCourseSectionInfos(c1, c2, this._localization.currentLocale))
      .map((course) => ({
        id: course.id,
        color: course.color,
        title: course.title,
        section: course.section,
        numberOfSchedules: this.activitySchedulesForCourse(course).length
      }))
      .value();
  }

  getActivityScheduleInfosForCourseId(id: string): ScheduleCycleActivitySchedulesSelectedCourseSectionInfo | undefined {
    if (id.length === 0) {
      return undefined;
    }

    const course = this.courseSections.find((cs) => cs.id === id);
    if (course == null) {
      return undefined;
    }

    const termsById = Object.fromEntries(this.scheduleCycleStore.scheduleCycle.terms.map((term) => [term.id, term]));

    const activitySchedules = this.activitySchedulesForCourse(course)
      .sort((a1, a2) => this.compareActivitySchedules(a1, a2, termsById))
      .map((activitySchedule) => {
        return {
          id: activitySchedule.id,
          primaryText: this.primaryTextForActivitySchedule(activitySchedule),
          secondaryText: this.secondaryTextForActivitySchedule(activitySchedule, termsById)
        };
      });

    return {
      activity: courseSectionInfoToActivity(course),
      title: course.title,
      section: course.section,
      color: course.color,
      activitySchedules
    };
  }

  abstract canEditActivitySchedulesForCourseId(id: string): boolean;

  async removeActivitySchedule(id: string): Promise<void> {
    await this.scheduleCycleStore.removeActivitySchedule(id);
  }

  private activitySchedulesForCourse(course: CourseSectionInfo): ActivitySchedule[] {
    return this.scheduleCycleStore.scheduleCycle.activitySchedules.filter(
      (a) =>
        !a.shouldDelete &&
        a.activity?.activityId === (course.externalSource?.externalId ?? course.id) &&
        a.activity?.sourceName === (course.externalSource?.sourceName ?? '')
    );
  }

  private primaryTextForActivitySchedule(activitySchedule: ActivitySchedule): string {
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules;
    const components: string[] = [];

    switch (activitySchedule.when.case) {
      case 'cycleDay':
        components.push(
          titleForCycleDay(
            activitySchedule.when.value,
            getScheduleCycleKind(this.scheduleCycleStore.scheduleCycle),
            'long',
            true,
            this.scheduleCycleStore.scheduleCycle.cycleDayNames
          )
        );
        break;

      case 'dayOfWeek': {
        const dow = LocalizedStrings.dateTime.dayOfWeekTitle[activitySchedule.when.value as DayOfWeek]();
        const text = this.isSingleWeekSchedule ? `${dow} (${strings.scheduleDayOfWeekSuffix()})` : dow;
        components.push(text);
        break;
      }

      case 'day': {
        const date = dayToDate(activitySchedule.when.value);
        components.push(format(date, 'PPP'));
        break;
      }

      default:
        break;
    }

    switch (activitySchedule.at.case) {
      case 'periodLabel':
        components.push(strings.schedulePeriodLabel(activitySchedule.at.value));
        break;

      case 'period': {
        const period = activitySchedule.at.value;
        const startTime = timeOfDayToDate(period.startTime!);
        const formattedStartTime = format(startTime, 'p');
        const endTime = timeOfDayToDate(period.endTime!);
        const formattedEndTime = format(endTime, 'p');

        components.push(strings.schedulePeriodTimes(formattedStartTime, formattedEndTime));
        break;
      }

      default:
        break;
    }

    return components.join(' - ');
  }

  private secondaryTextForActivitySchedule(
    activitySchedule: ActivitySchedule,
    termsById: Record<string, Term>
  ): string {
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules;
    const components: string[] = [];

    if (activitySchedule.termId.length > 0) {
      const term = termsById[activitySchedule.termId];
      // An activity might still be pointing to a deleted term.
      if (term != null) {
        components.push(strings.scheduleTerm(term.name));
      }
    }

    if (activitySchedule.at.case === 'period' && activitySchedule.at.value.label.length > 0) {
      components.push(strings.scheduleDisplayedLabel(activitySchedule.at.value.label));
    }

    if (activitySchedule.roomName.length > 0) {
      components.push(strings.scheduleRoomName(activitySchedule.roomName));
    }

    if (activitySchedule.scheduleTag.length > 0) {
      components.push(strings.scheduleTagLabel(activitySchedule.scheduleTag));
    }

    return components.join('\n');
  }

  private compareActivitySchedules(
    a1: ActivitySchedule,
    a2: ActivitySchedule,
    termsById: Record<string, Term>
  ): number {
    if (a1.when.case !== a2.when.case) {
      // Displaying cycleDay occurrences first/
      return a1.when.case === 'cycleDay' ? -1 : 1;
    }

    if (a1.when.case === 'cycleDay' && a2.when.case === 'cycleDay') {
      const diff = a1.when.value - a2.when.value;
      // If they are not the same cycleDay, we sort by cycleDay.
      if (diff !== 0) {
        return diff;
      }
    }

    if (a1.when.case === 'day' && a2.when.case === 'day') {
      const diff = differenceInCalendarDays(dayToDate(a1.when.value), dayToDate(a2.when.value));
      // If they are not the same day, we sort by day.
      if (diff !== 0) {
        return diff;
      }
    }

    if (a1.at.case !== a2.at.case) {
      // Displaying periodLabel occurrences first.
      return a1.at.case === 'periodLabel' ? -1 : 1;
    }

    if (a1.at.case === 'periodLabel' && a2.at.case === 'periodLabel') {
      // Sorting by periodLabel alphabetically.
      const diff = a1.at.value.localeCompare(a2.at.value, this._localization.currentLocale, {
        sensitivity: 'base'
      });

      if (diff !== 0) {
        return diff;
      }
    }

    if (a1.at.case === 'period' && a2.at.case === 'period') {
      // Sorting by start time, or end time if necessary.
      const diff =
        compareTimeOfDays(a1.at.value.startTime!, a2.at.value.startTime!) ||
        compareTimeOfDays(a1.at.value.endTime!, a2.at.value.endTime!);

      if (diff !== 0) {
        return diff;
      }
    }

    if (a1.termId === a2.termId) {
      return 0;
    } else if (a1.termId.length > 0 && a2.termId.length > 0) {
      const a1Term = termsById[a1.termId];
      const a2Term = termsById[a2.termId];
      return compareTerms(a1Term, a2Term, this._localization.currentLocale);
    } else {
      return a1.termId.length === 0 ? -1 : 1;
    }
  }
}
