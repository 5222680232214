import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { PlannerListKind } from '@/models';
import { Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { BackgroundImageScreenPaper, MediaQuery } from '../../utils';
import { PlannerPlanningListsGrid } from './PlannerPlanningListsGrid';
import { PlannerPlanningListsPage } from './PlannerPlanningListsPage';
import { PlannerPlanningListsTabs } from './PlannerPlanningListsTabs';

export interface PlannerPlanningProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerPlanning = observer(({ sx = [], className }: PlannerPlanningProps) => {
  const { settings } = useServices();
  const theme = useTheme();
  const [currentListKind, setCurrentListKind] = useState<PlannerListKind>('inbox');
  const plannerId = useActivePlannerId();
  const viewModel = useViewModel((viewModels) => viewModels.createPlannerPlanningViewModel(plannerId), [plannerId]);

  useEffect(() => {
    void viewModel.reloadData();
  }, [plannerId]);

  useSync(
    'PlannerPlanning',
    async () => {
      await viewModel.reloadData();
    },
    [plannerId]
  );

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
        <PlannerPlanningListsGrid
          viewModel={viewModel}
          sx={{ ...sx, flex: 1, p: settings.backgroundImage == null && theme.palette.mode === 'dark' ? 2 : 0 }}
          className={className}
          currentListKind={currentListKind}
          setCurrentListKind={setCurrentListKind}
        />
      </MediaQuery>

      <MediaQuery mediaQuery={(th) => th.breakpoints.down('md')}>
        <BackgroundImageScreenPaper sx={{ ...sx, flex: 1, width: '100%', height: '100%' }} className={className}>
          <Stack sx={{ height: '100%', width: '100%' }} spacing={2}>
            <PlannerPlanningListsTabs
              viewModel={viewModel}
              currentListKind={currentListKind}
              setCurrentListKind={setCurrentListKind}
            />
            <PlannerPlanningListsPage
              viewModel={viewModel}
              sx={{ flex: 1 }}
              setCurrentListKind={setCurrentListKind}
              currentListKind={currentListKind}
            />
          </Stack>
        </BackgroundImageScreenPaper>
      </MediaQuery>
    </>
  );
});
