import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import RetryIcon from '@mui/icons-material/RefreshRounded';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../hooks';
import { SchoolLoadable } from '../../stores';
import { isError } from '../../utils';
import { Splash } from '../Splash';

export interface LoadingSchoolScreenProps {
  schoolLoadable: SchoolLoadable;
}

export const LoadingSchoolScreen = observer(({ schoolLoadable }: LoadingSchoolScreenProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.utils;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    void schoolLoadable.fetch(true);
  }, [schoolLoadable]);

  useEffect(() => {
    const disposer = when(
      () => schoolLoadable.hasData,
      () => navigate(location, { replace: true })
    );

    return () => disposer();
  }, [schoolLoadable]);

  return (
    <Splash width="100%" height="100%" logoIsLink={false} showOptions={false}>
      {isError(schoolLoadable.state) ? (
        <Stack spacing={2}>
          <ErrorIcon color="error" fontSize="large" sx={{ alignSelf: 'center' }} />

          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              color: (theme) => theme.palette.error.main
            }}
          >
            {strings.loadingPlannerErrorMessage}
          </Typography>

          <Button variant="contained" startIcon={<RetryIcon />} onClick={() => void schoolLoadable.fetch(true)}>
            {strings.loadingPlannerErrorRetryLabel}
          </Button>

          <Button onClick={() => navigate('/')}>{strings.loadingPlannerErrorGoHomeLabel}</Button>
        </Stack>
      ) : (
        <>
          <Typography>{strings.loadingPlannerLabel}</Typography>
          <CircularProgress sx={{ mt: 3 }} size={32} />
        </>
      )}
    </Splash>
  );
});
