import { UserDashboardCalendarItemState, UserDashboardCalendarWeekTimedCalendarEventInfo } from '@/viewmodels';
import { Box, Card, CardActionArea, Stack, Tooltip, Typography, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CalendarEventIcon, ColorIndicator } from '../../../../lists';
import { MultilineTooltipTitle } from '../../../../utils';
import { ContextMenuHandler } from '../../../ContextMenuHandler';
import { formatCalendarItemTimes } from '../../shared';

export interface UserDashboardCalendarWeekCalendarEventProps {
  sx?: SxProps;
  className?: string;
  event: UserDashboardCalendarWeekTimedCalendarEventInfo;
  showBorder: boolean;
}

export const UserDashboardCalendarWeekCalendarEvent = observer(
  ({ sx = [], className, event, showBorder }: UserDashboardCalendarWeekCalendarEventProps) => {
    const { height } = event.position;
    const formattedTime = formatCalendarItemTimes(event.startTime, event.endTime, height >= 29);

    return (
      <ContextMenuHandler actions={event.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip title={<MultilineTooltipTitle lines={[event.title, formattedTime]} />}>
            <StyledCalendarEventView
              sx={{ ...sx, height }}
              className={className}
              // Prevents background click event when user clicks on the left or right padding of the period.
              onClick={(e) => e.stopPropagation()}
              showBorder={showBorder}
              onContextMenu={contextMenuHandler}
            >
              <Card className="card" elevation={0}>
                <CardActionArea
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="cardAction"
                  component="a"
                  href={event.externalUrl}
                  disabled={event.externalUrl == null}
                  target="_blank"
                >
                  <Box
                    className="mainContainer"
                    sx={{
                      alignItems: height < 49 ? 'center' : undefined
                    }}
                  >
                    <Box className="colorIndicatorContainer">
                      <ColorIndicator color={event.color} isRounded sx={{ height: '100%' }} />
                    </Box>

                    <Stack className="contentContainer" spacing={-0.5}>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          alignItems: 'center'
                        }}
                      >
                        <CalendarEventIcon size={12} />

                        <Typography
                          variant="caption"
                          noWrap
                          color={getColorForTitle(event.state)}
                          sx={{
                            flex: 1,
                            fontWeight: '700',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {event.title}
                        </Typography>

                        {height < 29 && (
                          <Typography
                            noWrap
                            variant="caption"
                            sx={{
                              fontSize: 10
                            }}
                          >
                            {formattedTime}
                          </Typography>
                        )}
                      </Stack>

                      {height >= 29 && (
                        <Typography
                          noWrap
                          variant="caption"
                          sx={{
                            fontSize: 10
                          }}
                        >
                          {formattedTime}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            </StyledCalendarEventView>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

interface StyledCalendarEventViewProps {
  showBorder: boolean;
}

const StyledCalendarEventView = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBorder'
})<StyledCalendarEventViewProps>(({ theme, showBorder }) => ({
  '.badge': {
    height: '100%',
    width: '100%'
  },

  '.card': {
    height: '100%',
    width: '100%',
    backgroundImage: 'none',
    backgroundColor: !showBorder ? 'transparent' : undefined,
    border: showBorder ? `1px solid ${theme.palette.divider}` : undefined,
    borderRadius: theme.shape.borderRadius
  },

  '.cardAction': {
    paddingRight: '6px',
    overflow: 'hidden',
    height: '100%'
  },

  '.mainContainer': {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  },

  '.colorIndicatorContainer': {
    marginRight: theme.spacing(0.5),
    height: '100%',
    padding: '4px'
  },

  '.contentContainer': {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    paddingTop: '2px',
    paddingBottom: '2px',
    minWidth: 120
  }
}));

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    default:
      return 'textPrimary';
  }
}
