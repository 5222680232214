import { CheckRounded } from '@mui/icons-material';
import { ListItemText, MenuItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';

export interface OptionsPopoverItemProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  isSelected: boolean;
  onClick: () => void;
  startElement?: ReactElement;
}

export const OptionsPopoverItem = observer(
  ({ sx = [], className, title, subtitle, isSelected, startElement, onClick }: OptionsPopoverItemProps) => {
    return (
      <MenuItem sx={sx} className={className} onClick={() => onClick()}>
        {startElement}
        <ListItemText
          primary={title}
          primaryTypographyProps={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}
          secondary={subtitle}
          secondaryTypographyProps={{ overflow: 'hidden', noWrap: true }}
        />
        {isSelected && <CheckRounded color="primary" fontSize="small" sx={{ ml: 1 }} />}
      </MenuItem>
    );
  }
);
