import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { computed, makeObservable } from 'mobx';
import { SchoolAccountCourseSections } from '../../models';
import { ServiceContainer } from '../../providers';
import { Loadable, SchoolAccountCourseSectionsLoadable, SchoolAccountLoadable, SchoolDataStore } from '../../stores';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminStudentDetailsViewModel extends UpdatableViewModel {
  readonly account: Account;
  readonly courseSections: SchoolAccountCourseSections;
}

export class AppAdminStudentDetailsViewModel extends BaseUpdatableViewModel implements AdminStudentDetailsViewModel {
  constructor(
    private readonly _accountId: string,
    private readonly _schoolId: string,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get accountLoadable(): SchoolAccountLoadable {
    return this._schoolStore.getAccount(this._accountId, this._schoolId);
  }

  @computed
  private get courseSectionsLoadable(): SchoolAccountCourseSectionsLoadable {
    return this._schoolStore.getCourseSectionsForAccount(this._schoolId, this._accountId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.accountLoadable, this.courseSectionsLoadable];
  }

  @computed
  get account(): Account {
    return this.accountLoadable.data;
  }

  @computed
  get courseSections(): SchoolAccountCourseSections {
    return this.courseSectionsLoadable.data;
  }
}
