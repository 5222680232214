import i18n from 'translate';

export const CalendarPeriodInfoStrings = {
  closeButtonTitle: () => i18n.t('calendar.periodInfo.closeButtonTitle'),
  createWorkButtonTitle: () => i18n.t('calendar.periodInfo.createWorkButtonTitle'),
  noClass: () => i18n.t('calendar.periodInfo.periodOccurrenceNoClass'),
  occurrenceCount: (occurrence: number) =>
    i18n.t('calendar.periodInfo.occurrenceCount', { count: occurrence, ordinal: true }),
  pasteItemButtonTitle: () => i18n.t('calendar.periodInfo.pasteItemButtonTitle'),
  skippedMessage: () => i18n.t('calendar.periodInfo.skippedMessage'),
  times: (start: string, end: string) => i18n.t('calendar.periodInfo.times', { start, end })
};
