import { UserDashboardCalendarAnnotationInfo } from '@/viewmodels';
import { Box, CardActionArea, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { InlineScheduleSymbolView } from './symbol';

export interface UserDashboardCalendarAnnotationViewProps {
  sx?: SxProps;
  className?: string;
  annotation: UserDashboardCalendarAnnotationInfo;
}

export function UserDashboardCalendarAnnotationView({
  sx = [],
  className,
  annotation
}: UserDashboardCalendarAnnotationViewProps) {
  const theme = useTheme();
  const color = annotation.color || theme.palette.action.focus;

  return (
    <Tooltip title={annotation.title} disableInteractive>
      <Box className={className} sx={sx}>
        {/* Disabling for now. We may want to show more details when clicking on the annotation in the future. */}
        <CardActionArea sx={{ borderRadius: 0.5, px: 1, backgroundColor: theme.palette.action.focus }} disabled>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              alignItems: 'center'
            }}
          >
            <InlineScheduleSymbolView symbol={annotation.symbol} color={color} />

            <Typography
              variant="caption"
              noWrap
              sx={{
                flex: 1,
                fontWeight: '500'
              }}
            >
              {annotation.title}
            </Typography>
          </Stack>
        </CardActionArea>
      </Box>
    </Tooltip>
  );
}
