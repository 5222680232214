export const ScheduleCyclePeriodScheduleGridConstants = {
  timesColumnWidth: 60,
  timesColumnZIndex: 200,
  headerZIndex: 201,
  navigationButtonZIndex: 203,
  eventsZIndex: 103,
  dayColumnClickHandlerZIndex: 102,
  hourLinesZIndex: 101,
  dayColumnZIndex: 100,
  dayColumnWidth: 150,
  topLeftViewZIndex: 202,
  pointsPerHour: 60
};
