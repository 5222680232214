import { useServices } from '@/hooks';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { Suspense, useEffect, useState } from 'react';
import { TableNoRowsOverlay } from './TableNoRowsOverlay';
import { TablePagination } from './TablePagination';
import { TableAction, TableEmptyParams, TablePageSizeOption, TablePageSizeOptions } from './TableUtils';

export interface TableProps<V> {
  sx?: SxProps;
  className?: string;

  tableKey: string;
  rows: V[];
  searchText: string;
  columns: GridColDef[];
  initialRowIndex?: number;
  initialPageSize?: TablePageSizeOption;
  actions?: TableAction[];
  scrollToCurrentInitialRowActionTitle?: string;
  emptyOverlayParams: TableEmptyParams;
  getRowId?: (item: V) => string;
}

export const Table = observer(
  <V,>({
    sx = [],
    className,
    tableKey,
    searchText,
    rows,
    columns,
    initialRowIndex,
    initialPageSize,
    actions,
    scrollToCurrentInitialRowActionTitle,
    emptyOverlayParams,
    getRowId
  }: TableProps<V>) => {
    const { settingsStorage } = useServices();
    const theme = useTheme();
    const [pageSize, setPageSize] = useState<number | undefined>();
    const [initialPage, setInitialPage] = useState<number | undefined>();

    const fetchCachedPageSize = async () => {
      const value: TablePageSizeOption =
        ((await settingsStorage.tablePageSizeForKey(tableKey)) as TablePageSizeOption) ?? initialPageSize ?? 25;
      setPageSize(value);
    };

    useEffect(() => {
      void fetchCachedPageSize();
    }, []);

    useEffect(() => {
      if (pageSize == null) {
        return;
      }

      const value = initialRowIndex != null ? Math.floor(initialRowIndex / pageSize) : 0;
      setInitialPage(value);
    }, [initialRowIndex, pageSize]);

    function onPaginationModelChange(pageModel: GridPaginationModel) {
      settingsStorage.setTablePageSizeForKey(tableKey, pageModel.pageSize);
    }

    return (
      <Box sx={sx} className={className}>
        <Suspense>
          {initialPage != null && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                getRowId={getRowId}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: initialPage,
                      pageSize
                    }
                  }
                }}
                pageSizeOptions={TablePageSizeOptions}
                onPaginationModelChange={onPaginationModelChange}
                disableRowSelectionOnClick
                disableVirtualization
                slots={{
                  pagination: () => (
                    <TablePagination
                      actions={actions}
                      showGoToInitialPageAction={searchText.length === 0 && rows.length > 0 && initialRowIndex != null}
                      initialPage={initialPage}
                      scrollToCurrentInitialRowActionTitle={scrollToCurrentInitialRowActionTitle}
                    />
                  ),
                  noRowsOverlay: () => <TableNoRowsOverlay searchText={searchText} params={emptyOverlayParams} />
                }}
                sx={{
                  border: 'none',
                  // This value represents the height of a table with 5 rows. Prevents having a small table when searching
                  // and when displaying NoRows overlay.
                  minHeight: 376,
                  color: theme.palette.text.primary,

                  // There is an issue with the DataGrid component where the noRowsOverlay is clipped as the height is
                  // set to rowHeight * 2 when `autoHeight` is set. To fix this, a css property was introduced.
                  '--DataGrid-overlayHeight': '376px',

                  '& .MuiDataGrid-cell': {
                    paddingY: 0.5,
                    borderBottomColor: theme.palette.divider
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    // Prevents outline when selecting cell.
                    outline: 'none'
                  },
                  '& .MuiDataGrid-container--top [role=row]': {
                    backgroundColor: 'transparent'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottomColor: theme.palette.divider
                  },
                  '& .MuiDataGrid-columnHeader:focus-within': {
                    // Prevents outline when selecting cell.
                    outline: 'none'
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none'
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    lineHeight: 1
                  },
                  '& .MuiDataGrid-footerContainer': {
                    borderTopColor: theme.palette.divider
                  }
                }}
              />
            </div>
          )}
        </Suspense>
      </Box>
    );
  }
);
