import { LoadingButton } from '@mui/lab';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CSSProperties, MouseEvent, ReactNode, useState } from 'react';

export interface EmptyListIndicatorButton {
  title: string;
  icon?: ReactNode;
  action: (e: MouseEvent<HTMLElement>) => Promise<void>;
}

export interface EmptyListIndicatorProps {
  className?: string;
  style?: CSSProperties;
  sx?: SxProps;
  /**
   * The title. Optional.
   */
  title?: string;
  /**
   * The subtitle. Optional.
   */
  subtitle?: string;

  /**
   * The icon to display.
   * @param className The icon's className
   * @return JSX.Element The icon element
   */
  renderIcon: (className: string) => ReactNode;
  /**
   * The button. Optional.
   */
  button?: EmptyListIndicatorButton;
}

export const EmptyListIndicator = observer(
  ({ className, style, sx = [], renderIcon, title, subtitle = '', button }: EmptyListIndicatorProps) => {
    const [isProcessing, setIsProcessing] = useState(false);

    async function onButtonClick(e: MouseEvent<HTMLElement>, action: (e: MouseEvent<HTMLElement>) => Promise<void>) {
      setIsProcessing(true);
      await action(e);
      setIsProcessing(false);
    }

    return (
      <Root
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={className}
        style={style}
        sx={sx}
      >
        {renderIcon(classes.icon)}
        <Box
          sx={{
            marginTop: 2
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textAlign: 'center'
            }}
          >
            {title}
          </Typography>

          {subtitle.length > 0 && <pre className={classes.subtitle}>{subtitle}</pre>}
        </Box>
        {button != null && (
          <Box
            sx={{
              marginTop: 2
            }}
          >
            <LoadingButton
              loading={isProcessing}
              variant="contained-grey"
              onClick={(e) => void onButtonClick(e, button.action)}
              startIcon={button.icon}
            >
              {button.title}
            </LoadingButton>
          </Box>
        )}
      </Root>
    );
  }
);

const PREFIX = 'EmptyListIndicator';
const classes = {
  icon: `${PREFIX}-icon`,
  subtitle: `${PREFIX}-subtitle`
};

const Root = styled((props: BoxProps) => <Box {...props} />)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.text.secondary,
    fontSize: 60,
    maxWidth: '100%',
    height: 'auto'
  },

  [`& .${classes.subtitle}`]: {
    margin: 0,
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    font: theme.typography.body2.font,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
  }
}));
