import { useActivePlannerId, useIsMatchPath, useServices } from '@/hooks';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { SettingsContent } from '../SettingsContent';

export interface ScheduleCycleSettingsPageProps {
  sx?: SxProps;
  className?: string;
}

export const ScheduleCycleSettingsPage = observer(({ sx = [], className }: ScheduleCycleSettingsPageProps) => {
  const { route } = useServices();
  const plannerId = useActivePlannerId();
  const isRoot = useIsMatchPath({
    path: route.resolvePlannerSettingsLocation('schedules', plannerId),
    end: true
  });

  return (
    <SettingsContent sx={sx} className={className} fluidWidth={!isRoot} topPadding={!isRoot ? 0.5 : undefined}>
      <Outlet />
    </SettingsContent>
  );
});
