import { Snapshot } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/resources/snapshot_pb';
import { Demo } from '@buf/studyo_studyo-today-demo.connectrpc_es/studyo/today/demo/v1/demo_connect';
import { DemoTransportService } from '../contracts/DemoTransportService';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppDemoTransportService extends AppBaseTransportService implements DemoTransportService {
  async createDemoSchoolSnapshot(schoolId: string, name: string): Promise<void> {
    await this.performWriteRequest('createDemoSchoolSnapshot', Demo, Demo.methods.createDemoSchoolSnapshot, {
      schoolId,
      name
    });
  }

  async listDemoSchoolSnapshots(schoolId: string): Promise<Snapshot[]> {
    const response = await this.performReadRequest(
      'listDemoSchoolSnapshots',
      Demo,
      Demo.methods.listDemoSchoolSnapshots,
      { schoolId }
    );
    return response.snapshots;
  }

  async restoreDemoSchoolSnapshot(schoolId: string, name: string, dayOffset: number): Promise<void> {
    await this.performWriteRequest('restoreDemoSchoolSnapshot', Demo, Demo.methods.restoreDemoSchoolSnapshot, {
      schoolId,
      name,
      dayOffset
    });
  }
}
