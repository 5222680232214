import { plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService } from '@/services';
import { PlannerDataStore, PlannerDetailedCourseSectionsLoadable, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppBaseCourseSectionListViewModel, CourseSectionDetailsViewModel } from './CourseSectionListViewModel';

class AppCourseSectionDetailsViewModel implements CourseSectionDetailsViewModel {
  @observable private _courseSection: CourseSection | undefined;
  constructor(
    private readonly _plannerStore: PlannerDataStore,
    private readonly _plannerId: string,
    private readonly _details: CourseSectionDetails,
    private readonly _onError: (message: string) => void
  ) {
    makeObservable(this);
  }

  @computed
  get courseSection(): CourseSection {
    return this._courseSection ?? this._details.courseSection!;
  }

  async delete(shouldAlsoCancelWorks: boolean): Promise<void> {
    try {
      await this._plannerStore.deleteCourseSection(
        this.courseSection.id,
        this.courseSection.syncToken,
        this._plannerId,
        shouldAlsoCancelWorks
      );
    } catch (e) {
      captureException(e);
      const error = e as Error;
      this._onError(error.message);
    }
  }

  @action
  async toggleVisibility(): Promise<void> {
    try {
      const newCourseSection = await this._plannerStore.changeCourseSectionVisibility(
        this.courseSection.id,
        this.courseSection.syncToken,
        this._plannerId,
        !this.courseSection.isVisible
      );
      runInAction(() => (this._courseSection = newCourseSection));
    } catch (e) {
      captureException(e);
      const error = e as Error;
      this._onError(error.message);
    }
  }
}

export class AppPlannerCourseSectionListViewModel extends AppBaseCourseSectionListViewModel<PlannerDetailedCourseSectionsLoadable> {
  constructor(
    plannerId: string,
    private readonly _kind: 'personal' | 'external',
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super(plannerId, undefined, localization, connectedApps);
    makeObservable(this);
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  protected get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  protected get courseSectionsInfos(): CourseSectionDetails[] {
    return this.courseSectionsLoadable.values.filter((cs) =>
      this._kind === 'personal' ? cs.courseSection?.externalSource == null : cs.courseSection?.externalSource != null
    );
  }

  @computed
  get canEditCourseSections(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  protected createCourseSectionDetailsViewModel(details: CourseSectionDetails): CourseSectionDetailsViewModel {
    return new AppCourseSectionDetailsViewModel(this._plannerStore, this._plannerId, details, (m) =>
      runInAction(() => (this._error = m))
    );
  }
}
