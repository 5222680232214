import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApplicationSettingsStorage, SelectableThemeVariant, ThemeService, ThemeVariant } from '../contracts';

export class AppThemeService implements ThemeService {
  @observable private _systemPreferredVariant: ThemeVariant = 'light';

  constructor(private readonly _settingsStorage: ApplicationSettingsStorage) {
    makeObservable(this);

    // No event is triggered on load, so setting initial value outside the EventListener.
    const colorScheme: ThemeVariant = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    runInAction(() => (this._systemPreferredVariant = colorScheme));

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      const colorScheme: ThemeVariant = e.matches ? 'dark' : 'light';
      runInAction(() => (this._systemPreferredVariant = colorScheme));
    });
  }

  @computed
  get variant(): ThemeVariant {
    const selectedVariant = this.selectedVariant;
    if (selectedVariant === 'system') {
      return this._systemPreferredVariant;
    }
    return selectedVariant;
  }

  @computed
  get selectedVariant(): SelectableThemeVariant {
    return (this._settingsStorage.themeVariant as SelectableThemeVariant) ?? 'system';
  }

  @action
  setVariant(variant: SelectableThemeVariant) {
    this._settingsStorage.themeVariant = variant;
  }
}
