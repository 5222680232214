import { ServiceContainer } from '@/providers';
import { LocalizationService, RouteService } from '@/services';
import { PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import { PlannerList } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_list_pb';
import { computed, makeObservable } from 'mobx';
import { AppPlannerListViewModel } from './PlannerListViewModel';

export class AppUpcomingPlannerListViewModel extends AppPlannerListViewModel {
  constructor(
    plannerId: string,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    workStore: WorkDataStore = ServiceContainer.services.workStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    route: RouteService = ServiceContainer.services.route
  ) {
    super(plannerId, userStore, plannerStore, workStore, localization, route);
    makeObservable(this);
  }

  @computed
  protected get plannerList(): PlannerList {
    return this.plannerContents.upcomingList;
  }

  protected readonly showGroupTitles = true;
}
