import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';

export type PasteboardContentKind = 'work' | 'note' | 'published-work';
export type PasteboardContent =
  | { case: 'work'; value: Work }
  | { case: 'note'; value: Note }
  | { case: 'published-work'; value: PublishedWork };

export const AllPlannerItemsPasteboardContentKind = ['work', 'note', 'published-work'] as PasteboardContentKind[];

export interface PasteboardPasteContext {
  readonly date?: Date;
  readonly period?: CourseSectionOccurrence;
  readonly courseSectionId?: string;
}

export interface PasteboardService {
  readonly content: PasteboardContent | undefined;
  readonly pasteContext: PasteboardPasteContext | undefined;

  currentContentForKinds(supportedKinds: PasteboardContentKind[]): PasteboardContent | undefined;
  setContent(content: PasteboardContent): void;
  clearContent(): void;
  getSummaryTextForContent(content: PasteboardContent): string;

  setPasteContext(context: PasteboardPasteContext): void;
  clearPasteContext(): void;
}
