import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { computed, makeObservable } from 'mobx';
import { isDemoId } from '../../models';

export type PlannerSideBarCourseSectionItemViewModelKind = 'planner' | 'school';

export interface PlannerSideBarCourseSectionItemViewModel {
  readonly id: string;
  readonly kind: PlannerSideBarCourseSectionItemViewModelKind;
  readonly plannerId: string;
  readonly schoolId: string;
  readonly schoolCourseSectionId: string;
  readonly courseTitle: string;
  readonly section: string;
  readonly color: string | undefined;
  readonly studentUsingTodayCount: number;
  readonly totalStudentCount: number;
  readonly canInviteStudents: boolean;
  readonly isDemo: boolean;
}

export class AppPlannerSideBarCourseSectionItemViewModel implements PlannerSideBarCourseSectionItemViewModel {
  constructor(private readonly _courseSection: CourseSectionDetails) {
    makeObservable(this);
  }

  @computed
  get id(): string {
    return this._courseSection.courseSection!.id;
  }

  @computed
  get kind(): PlannerSideBarCourseSectionItemViewModelKind {
    return this._courseSection.schoolsCourseSection != null && this._courseSection.role == CourseSectionRole.TEACHER
      ? 'school'
      : 'planner';
  }

  @computed
  get plannerId(): string {
    return this._courseSection.courseSection!.plannerId;
  }

  @computed
  get schoolId(): string {
    return this._courseSection.schoolsCourseSection?.schoolId ?? '';
  }

  @computed
  get schoolCourseSectionId(): string {
    return this._courseSection.schoolsCourseSection?.id ?? '';
  }

  @computed
  get courseTitle(): string {
    return this._courseSection.courseSection!.title;
  }

  @computed
  get section(): string {
    return this._courseSection.courseSection!.section;
  }

  @computed
  get color(): string | undefined {
    const color = this._courseSection.courseSection!.color;
    return color.length > 0 ? color : undefined;
  }

  @computed
  get studentUsingTodayCount(): number {
    return this._courseSection.schoolsCourseSection?.details?.todayStudentCount ?? 0;
  }

  @computed
  get totalStudentCount(): number {
    return this._courseSection.schoolsCourseSection?.details?.studentCount ?? 0;
  }

  @computed
  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get canInviteStudents() {
    // TODO
    return false;
    // return !this._dataStore.isReadOnly && this.studentInvitedToUseTodayCount < this.totalStudentCount;
  }

  @computed
  get isDemo(): boolean {
    return isDemoId(this.id);
  }
}
