import {
  CourseSectionEditStrings,
  EnglishCourseSectionEditStrings,
  FrenchCourseSectionEditStrings
} from './CourseSectionEditStrings';
import {
  CourseSectionListStrings,
  EnglishCourseSectionListStrings,
  FrenchCourseSectionListStrings
} from './CourseSectionListStrings';

export interface CourseSectionStrings {
  readonly edit: CourseSectionEditStrings;
  readonly lists: CourseSectionListStrings;
}

export const EnglishCourseSectionStrings: CourseSectionStrings = {
  edit: EnglishCourseSectionEditStrings,
  lists: EnglishCourseSectionListStrings
};

export const FrenchCourseSectionStrings: CourseSectionStrings = {
  edit: FrenchCourseSectionEditStrings,
  lists: FrenchCourseSectionListStrings
};
