import { useServices } from '@/hooks';
import { PopoverProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { OptionsPopover, OptionsPopoverItem, OptionsPopoverSection } from '../../../utils';

export interface UserDashboardCalendarWeekOptionsPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
}

export const UserDashboardCalendarWeekOptionsPopover = observer(
  (props: UserDashboardCalendarWeekOptionsPopoverProps) => {
    const { settings } = useServices();
    const density = settings.calendarWeekDensity;
    const showWeekends = settings.calendarWeekShowWeekends;
    const calendarShowPeriodLabels = settings.calendarShowPeriodLabels;
    const strings = LocalizedStrings.calendar.week.options;

    function resetOptions() {
      settings.calendarWeekDensity = 'regular';
      settings.calendarWeekShowWeekends = true;
      settings.calendarShowPeriodLabels = true;
    }

    return (
      <OptionsPopover {...props} title={strings.title()} onResetOptions={resetOptions}>
        <OptionsPopoverSection title={strings.densityTitle()}>
          <OptionsPopoverItem
            title={strings.densityCompact()}
            isSelected={density === 'small'}
            onClick={() => (settings.calendarWeekDensity = 'small')}
          />
          <OptionsPopoverItem
            title={strings.densityRegular()}
            isSelected={density === 'regular'}
            onClick={() => (settings.calendarWeekDensity = 'regular')}
          />
          <OptionsPopoverItem
            title={strings.densityLarge()}
            isSelected={density === 'large'}
            onClick={() => (settings.calendarWeekDensity = 'large')}
          />
        </OptionsPopoverSection>

        <OptionsPopoverSection title={strings.showOptionsSectionTitle()}>
          <OptionsPopoverItem
            title={strings.showWeekends()}
            isSelected={showWeekends}
            onClick={() => (settings.calendarWeekShowWeekends = !showWeekends)}
          />

          <OptionsPopoverItem
            title={strings.showPeriodLabels()}
            isSelected={calendarShowPeriodLabels}
            onClick={() => (settings.calendarShowPeriodLabels = !calendarShowPeriodLabels)}
          />

          <OptionsPopoverItem
            title={strings.showFreePeriods()}
            isSelected={settings.calendarShowFreePeriods}
            onClick={() => (settings.calendarShowFreePeriods = !settings.calendarShowFreePeriods)}
          />
        </OptionsPopoverSection>
      </OptionsPopover>
    );
  }
);
