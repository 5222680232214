import { BaseLoadableValue, LoadableValue } from '@/stores/loadables/Loadable';
import { PlannerTransportService } from '@/transports';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';

export type PlannerLoadable = LoadableValue<Planner>;

export class AppPlannerLoadable extends BaseLoadableValue<Planner> implements PlannerLoadable {
  constructor(
    private readonly _plannerId: string,
    initialValue: Planner | undefined,
    private readonly _plannerTransport: PlannerTransportService
  ) {
    super(initialValue);
  }

  protected loadData(): Promise<Planner> {
    return this._plannerTransport.getPlanner(this._plannerId);
  }
}
