import { Loadable } from '@/stores';
import { isError } from '@/utils';
import { computed, makeObservable } from 'mobx';

export type UpdatableViewModelState = 'fulfilled' | 'pending' | Error | undefined;

export interface UpdatableViewModel {
  /**
   * Current state.
   */
  readonly state: UpdatableViewModelState;

  /**
   * Indicates whether the data is available or not. This can be useful in a scenario where we want to reload the data
   * while still display the existing one.
   */
  readonly hasData: boolean;

  /**
   * Reloads the data necessary for the viewModel. Changes the state appropriately based on the result.
   */
  reloadData: () => Promise<void>;
}

export abstract class BaseUpdatableViewModel implements UpdatableViewModel {
  protected abstract get loadables(): Loadable<unknown>[];

  protected constructor() {
    makeObservable(this);
  }

  @computed
  get hasData(): boolean {
    return this.loadables.every((loadable) => loadable.hasData);
  }

  @computed
  get state(): UpdatableViewModelState {
    if (this.loadables.some((loadable) => loadable.state === 'pending')) {
      return 'pending';
    } else if (this.loadables.every((loadable) => loadable.hasData)) {
      return 'fulfilled';
    } else {
      const loadableWithError = this.loadables.find((loadable) => isError(loadable.state));
      return (loadableWithError?.state as Error) ?? undefined;
    }
  }

  async reloadData() {
    const refreshFns = this.loadables.map(async (loadable) => await loadable.fetch(true));
    await Promise.all(refreshFns);
  }
}
