import { Divider } from '@mui/material';
import { SxProps } from '@mui/system';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridHourLineProps {
  sx?: SxProps;
  className?: string;
}

export function ScheduleCyclePeriodScheduleGridHourLine({
  sx = [],
  className
}: ScheduleCyclePeriodScheduleGridHourLineProps) {
  return (
    <Divider
      sx={{
        ...sx,
        zIndex: ScheduleCyclePeriodScheduleGridConstants.hourLinesZIndex,
        borderBottomStyle: 'dashed'
      }}
      className={className}
      onClick={(e) => e.preventDefault()}
    />
  );
}
