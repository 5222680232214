import { timeOfDayToDate } from '@/models';
import { Period } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { Box, ClickAwayListener, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';

export interface ScheduleCyclePeriodEditSuggestionProps {
  sx?: SxProps;
  className?: string;
  suggestion: Period;
  onClick: (suggestion: Period) => void;
}

export const ScheduleCyclePeriodEditSuggestion = observer(
  ({ sx = [], className, suggestion, onClick }: ScheduleCyclePeriodEditSuggestionProps) => {
    const [showCopySuggestionSnackbar, setShowCopySuggestionSnackbar] = useState(false);

    const startDate = timeOfDayToDate(suggestion.startTime!);
    const endDate = timeOfDayToDate(suggestion.endTime!);
    const formattedTime = `${format(startDate, 'p')} - ${format(endDate, 'p')}`;

    return (
      <ClickAwayListener onClickAway={() => setShowCopySuggestionSnackbar(false)}>
        <Box sx={sx} className={className}>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={() => setShowCopySuggestionSnackbar(false)}
            open={showCopySuggestionSnackbar}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={LocalizedStrings.scheduleCycle.edit.bellTimes.period.timeSuggestionAppliedConfirmation()}
            placement="top"
          >
            <ListItemButton
              sx={{ borderRadius: 1 }}
              onClick={() => {
                onClick(suggestion);
                setShowCopySuggestionSnackbar(true);
                setTimeout(() => setShowCopySuggestionSnackbar(false), 500);
              }}
            >
              <ListItemText
                primary={formattedTime}
                primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
              />
            </ListItemButton>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    );
  }
);
