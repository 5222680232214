import { useSync, useViewModel } from '@/hooks';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { UpdatablePresenter } from '../../../utils';
import { AdminContentCard } from '../../shared';
import { AdminSchoolStudentsTable } from './AdminSchoolStudentsTable';

export interface AdminSchoolStudentsListPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolStudentsListPage = observer(({ sx = [], className }: AdminSchoolStudentsListPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';

  const viewModel = useViewModel((viewModels) => viewModels.createAdminStudentsListViewModel(schoolId), [schoolId]);
  const strings = LocalizedStrings.admin.studentsList;

  useSync('admin-students-list', () => viewModel.reloadData(), [viewModel]);

  return (
    <Box sx={sx} className={className}>
      <AdminContentCard
        title={strings.title()}
        searchValue={viewModel.hasData ? viewModel.searchText : undefined}
        onSearch={(value) => (viewModel.searchText = value)}
      >
        <UpdatablePresenter
          sx={{ px: 2, pt: 1, overflow: 'hidden' }}
          viewModel={viewModel}
          renderData={() => (
            <Box
              sx={{
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <AdminSchoolStudentsTable viewModel={viewModel} />
            </Box>
          )}
        />
      </AdminContentCard>
    </Box>
  );
});
