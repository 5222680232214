import { emojiForEmotionalPulseRating, emotionalPulseRatingToStatus, StudentEmotionalStatus } from '@/models';
import { DateService, LocalizationService } from '@/services';
import { differenceInCalendarDays } from 'date-fns';
import { StudentBehaviorLevel } from './StudentBehavior';

export function getInboxBehaviorTooltip(level: StudentBehaviorLevel, localization: LocalizationService): string {
  const strings = localization.localizedStrings.schoolCourseSection;

  switch (level) {
    case 'none':
      return strings.studentInboxCountNoneTooltip;
    case 'low':
      return strings.studentInboxCountGreenTooltip;
    case 'medium':
      return strings.studentInboxCountOrangeTooltip;
    case 'high':
      return strings.studentInboxCountRedTooltip;
  }
}

export function getInboxBehaviorLevel(count: number | undefined): StudentBehaviorLevel {
  if (count == null) {
    return 'none';
  }

  // TODO sc15916: Add real thresholds
  if (count <= 2) {
    return 'low';
  } else if (count <= 4) {
    return 'medium';
  } else {
    return 'high';
  }
}

export function getDailyActivityBehaviorTooltip(
  lastSeenDate: Date | undefined,
  localization: LocalizationService,
  dateService: DateService
): string {
  return localization.localizedStrings.schoolCourseSection.studentLastConnectionWithPrefix(lastSeenDate, dateService);
}

export function getDailyActivityBehaviorLevel(
  lastSeenDate: Date | undefined,
  dateService: DateService
): StudentBehaviorLevel {
  if (lastSeenDate == null) {
    return 'none';
  }

  const daysSinceLastConnection = differenceInCalendarDays(dateService.now, lastSeenDate);
  if (daysSinceLastConnection <= 2) {
    return 'low';
  } else if (daysSinceLastConnection <= 5) {
    return 'medium';
  } else {
    return 'high';
  }
}

export function getStudentEmotionalStatusLevel(answer: number | undefined): StudentBehaviorLevel {
  if (answer == null || answer < 0) {
    return 'none';
  }

  const status = emotionalPulseRatingToStatus(answer);
  switch (status) {
    case StudentEmotionalStatus.HAPPY:
      return 'low';
    case StudentEmotionalStatus.OK:
      return 'medium';
    case StudentEmotionalStatus.SAD:
      return 'high';
  }
}

export function getStudentEmotionalStatusTooltip(
  statusRating: number | undefined,
  localization: LocalizationService
): string {
  const strings = localization.localizedStrings.utils;

  if (statusRating == null || statusRating < 0) {
    return strings.studentLastEmotionalStatusNoneTooltip;
  }

  const status = emotionalPulseRatingToStatus(statusRating);
  const emoji = emojiForEmotionalPulseRating(status);
  return strings.studentLastEmotionalStatusDateTooltip(emoji);
}
