import { Product } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_pb';
import { FeatureFlagService } from '../../services';
import { SubscriptionsTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SubscriptionProductsLoadable = LoadableValue<Product[]>;

export class AppSubscriptionProductsLoadable
  extends BaseLoadableValue<Product[]>
  implements SubscriptionProductsLoadable
{
  constructor(
    private readonly _subscriptionsTransport: SubscriptionsTransportService,
    private readonly _featureFlags: FeatureFlagService
  ) {
    super();
  }

  protected async loadData(): Promise<Product[]> {
    const results = await this._subscriptionsTransport.getProducts();
    return results.filter((product) => product.featureFlag === '' || this._featureFlags.isEnabled(product.featureFlag));
  }
}
