import { Snapshot } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/resources/snapshot_pb';
import { computed, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { DemoSchoolSnapshotsLoadable, Loadable, SchoolDataStore } from '../../stores';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminDemoSchoolSnapshotsViewModel extends UpdatableViewModel {
  readonly isDemoSchool: boolean;
  readonly snapshots: Snapshot[];

  createSnapshot(name: string): Promise<void>;
  restoreSnapshot(snapshot: Snapshot, dayOffset: number): Promise<void>;
}

export class AppAdminDemoSchoolSnapshotsViewModel
  extends BaseUpdatableViewModel
  implements AdminDemoSchoolSnapshotsViewModel
{
  constructor(
    private readonly _schoolId: string,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get isDemoSchool(): boolean {
    return this.snapshotsLoadable.data !== false;
  }

  @computed
  get snapshots(): Snapshot[] {
    return this.snapshotsLoadable.data === false ? [] : this.snapshotsLoadable.data;
  }

  async createSnapshot(name: string): Promise<void> {
    await this._schoolStore.createDemoSchoolSnapshot(this._schoolId, name);
    await this.snapshotsLoadable.invalidate(true);
  }

  async restoreSnapshot(snapshot: Snapshot, dayOffset: number): Promise<void> {
    await this._schoolStore.restoreDemoSchoolSnapshot(this._schoolId, snapshot.name, dayOffset);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.snapshotsLoadable];
  }

  @computed
  private get snapshotsLoadable(): DemoSchoolSnapshotsLoadable {
    return this._schoolStore.getDemoSchoolSnapshots(this._schoolId);
  }
}
