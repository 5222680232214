import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { computed, makeObservable, observable } from 'mobx';

export interface WorkStepInfo {
  readonly id: string;
  readonly title: string;
  readonly duration: number;
  isCompleted: boolean;
  readonly step: WorkStep;
}

export class AppWorkStepInfo implements WorkStepInfo {
  @observable private _isCompleted: boolean;

  constructor(
    private readonly _step: WorkStep,
    private readonly _onChange: (isCompleted: boolean) => void
  ) {
    makeObservable(this);
    this._isCompleted = _step.isCompleted;
  }

  @computed
  get id(): string {
    return this._step.id;
  }

  @computed
  get title(): string {
    return this._step.title;
  }

  @computed
  get duration(): number {
    return this._step.duration;
  }

  @computed
  get isCompleted(): boolean {
    return this._isCompleted;
  }

  set isCompleted(value: boolean) {
    this._isCompleted = value;
    this._step.isCompleted = value;
    this._onChange(value);
  }

  @computed
  get step(): WorkStep {
    return this._step;
  }
}
