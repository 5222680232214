import { ClassroomSyncBlocker, ConnectedAppUserDashboard, SyncStatusInfo } from '@/models';
import { ClassroomTransportService } from '@/transports';
import { Course } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/course_pb';
import { SyncBlocker } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_blocker_pb';
import { computed, makeObservable, override } from 'mobx';
import { UserDataStore } from '../../../stores';
import { AutoSyncService, ClassroomConnectedAppService, GapiService, UserService } from '../../contracts';
import { BaseConnectedAppService } from './BaseConnectedAppService';

export class AppClassroomConnectedAppService
  extends BaseConnectedAppService<ClassroomSyncBlocker>
  implements ClassroomConnectedAppService
{
  constructor(
    userDashboard: ConnectedAppUserDashboard,
    user: UserService,
    autoSync: AutoSyncService,
    private readonly _transport: ClassroomTransportService,
    private readonly _gapi: GapiService,
    private readonly _userStore: UserDataStore
  ) {
    super(userDashboard, user, autoSync, 'classroom');

    makeObservable(this);
  }

  @override
  get hasSyncError(): boolean {
    return (
      this._syncFailed ||
      this.allSyncBlockers.includes('internal-error') ||
      this.allSyncBlockers.includes('unspecified') ||
      this.allSyncBlockers.includes('unexpected-error')
    );
  }

  @computed
  get isConnected(): boolean {
    return (
      this._syncStatus != null &&
      !this.allSyncBlockers.includes('no-google-offline-access') &&
      !this.allSyncBlockers.includes('missing-google-access-scopes')
    );
  }

  @computed
  get warningSyncBlockers(): ClassroomSyncBlocker[] {
    return this.allSyncBlockers.filter((syncBlocker) => {
      switch (syncBlocker) {
        case 'internal-error':
        case 'unspecified':
        case 'unexpected-error':
        case 'no_classroom_access':
          return true;

        case 'no-google-offline-access':
          return false;

        case 'missing-google-access-scopes':
        case 'insufficient-entitlements':
          return !this.allSyncBlockers.includes('no-google-offline-access');
      }
    });
  }

  async connect(refreshPlannerData: boolean) {
    const offlineAccessCode = await this._gapi.grantOfflineAccess(this._syncStatus?.missingGoogleScopes ?? []);
    await this._transport.registerOfflineAccessCode(offlineAccessCode, this.userDashboard);

    if (refreshPlannerData) {
      // Updating list of schools as new one can be available following connection to classroom.
      void this._userStore.participatingSchools.fetch(true);
    }
  }

  async disconnect() {
    await this._transport.revokeOfflineAccessCode(this.userDashboard);
    await this.sync();
  }

  async getCourses(): Promise<Course[]> {
    return await this._transport.getCourses(this.userDashboard);
  }

  async updateCourseSelection(courseIds: string[]): Promise<Course[]> {
    const courses = await this._transport.updateCourseSelection(courseIds, this.userDashboard);
    await this.sync();
    return courses;
  }

  protected async getSyncStatus(): Promise<SyncStatusInfo<ClassroomSyncBlocker>> {
    const syncStatus = await this._transport.getSyncStatus(this.userDashboard);
    const blockers = syncStatus.syncBlockers.map((b) => this.syncBlockerToString(b));

    return {
      isCurrentlySynchronizing: syncStatus.isCurrentlySynchronizing,
      lastSuccessfulSyncTime: syncStatus.lastSuccessfulSyncTime,
      syncBlockers: blockers,
      missingGoogleScopes: syncStatus.missingGoogleScopes
    };
  }

  protected loadSync(): Promise<void> {
    return this._transport.synchronize(this.userDashboard);
  }

  private syncBlockerToString(blocker: SyncBlocker): ClassroomSyncBlocker {
    switch (blocker) {
      case SyncBlocker.INSUFFICIENT_ENTITLEMENTS:
        return 'insufficient-entitlements';
      case SyncBlocker.INTERNAL_ERROR:
        return 'internal-error';
      case SyncBlocker.NO_CLASSROOM_ACCESS:
        return 'no_classroom_access';
      case SyncBlocker.MISSING_GOOGLE_ACCESS_SCOPES:
        return 'missing-google-access-scopes';
      case SyncBlocker.NO_GOOGLE_OFFLINE_ACCESS:
        return 'no-google-offline-access';
      case SyncBlocker.UNEXPECTED_ERROR:
        return 'unexpected-error';
      case SyncBlocker.UNSPECIFIED:
        return 'unspecified';
    }
  }
}
