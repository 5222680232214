import { SxProps } from '@mui/system';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { CalendarDatePicker, CalendarDatePickerKind } from './CalendarDatePicker';

export interface DatePickerProps {
  sx?: SxProps;
  className?: string;

  /**
   * The selected dateTime.
   */
  value: Date | undefined;

  /**
   * The date format.
   */
  dateFormat: string;

  /**
   * The date picker field label. Optional.
   */
  datePickerLabel?: string;

  /**
   * Kind of the date picker. Optional.
   */
  datePickerKind?: CalendarDatePickerKind;

  canClearDate?: boolean;

  /**
   * Dates to highlight in date picker. Optional. Only affects picker when kind is 'user-dashboard-calendar'.
   */
  highlightedDates?: (startDate: Date, endDate: Date) => Date[];

  /**
   * Indicates whether the field should be disabled. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when the dateTime value changes.
   * @param value The new dateTime
   * @param withTime Indicates if the time has been selected.
   */
  onChange: (value: Date | undefined) => void;

  error?: string;
}

export const DatePicker = observer(
  ({
    sx = [],
    className,
    value,
    dateFormat,
    datePickerLabel,
    datePickerKind,
    disabled = false,
    highlightedDates,
    onChange,
    canClearDate = true,
    error
  }: DatePickerProps) => {
    const [hasDateError, setHasDateError] = useState(false);

    function onDateChange(date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) {
      if (context.validationError != null) {
        setHasDateError(true);
        return;
      }

      setHasDateError(false);
      onChange(date ?? undefined);
    }
    return (
      <CalendarDatePicker
        sx={sx}
        className={className}
        value={value ?? null}
        disabled={disabled}
        label={datePickerLabel}
        format={dateFormat}
        slotProps={{
          actionBar: { actions: value != null && canClearDate ? ['clear'] : [] },
          textField: { error: error != null || hasDateError }
        }}
        onChange={(v, context) => onDateChange(v, context)}
        showDaysOutsideCurrentMonth
        kind={datePickerKind}
        highlightedDates={highlightedDates}
      />
    );
  }
);
