import { useCaretPosition, useDelayedEffect } from '@/hooks';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { useState } from 'react';

export interface MaskedTextFieldProps extends Omit<BaseTextFieldProps, 'value' | 'onChange'> {
  /**
   * The current value to set in the text field.
   */
  value?: string;

  /**
   * Callback when changing the text field value.
   * @param value The new value, formatted with `formatValue` and stripped from characters that do not match `regex`.
   */
  onChange: (value: string) => void;

  /**
   * Format value on changed.
   * @param value
   */
  formatValue: (value: string) => string;
}

export const MaskedTextField = ({ onChange, formatValue, ...props }: MaskedTextFieldProps) => {
  const [value, setValue] = useState(props.value ?? '');
  const { ref, updateCaret } = useCaretPosition();

  // Fix for field not being focused in dialog due to the dialog animation causing a delay.
  useDelayedEffect(
    () => {
      if (props.autoFocus) {
        ref.current?.focus();
      }
    },
    200,
    [props.autoFocus]
  );

  return (
    <TextField
      {...props}
      inputRef={ref}
      value={value}
      onChange={(event) => {
        const newValue = event.target.value;
        // Always allow backspace
        const formattedValue =
          newValue.length === value.length - 1 && value.slice(undefined, newValue.length) === newValue
            ? newValue
            : formatValue(newValue);

        setValue(formattedValue);
        onChange(formattedValue);
        updateCaret(formattedValue.length - newValue.length);
      }}
    />
  );
};
