import i18n from 'translate';

export const StartTrialStrings = {
  existingSubscription: {
    goToPlanner: () => i18n.t('startTrial.existingSubscription.goToPlanner'),
    manage: () => i18n.t('startTrial.existingSubscription.manage'),
    subtitle: () => i18n.t('startTrial.existingSubscription.subtitle'),
    title: () => i18n.t('startTrial.existingSubscription.title')
  },
  noUser: {
    login: () => i18n.t('startTrial.noUser.login'),
    subtitle: () => i18n.t('startTrial.noUser.subtitle'),
    title: () => i18n.t('startTrial.noUser.title')
  },
  subscribe: {
    loggedInUserEmail: (email: string) => i18n.t('startTrial.subscribe.loggedInUserEmail', { email }),
    title: () => i18n.t('startTrial.subscribe.title')
  }
};
