import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface NavigationButtonProps {
  className?: string;
  sx?: SxProps;

  title: string;
  subtitle?: string;
  icon?: (sx: SxProps) => ReactNode;
  onClick: () => void;
}

/**
 * Represents an outlined button with the title align left and without an all uppercase title. Also, a right arrow
 * is displayed on the right.
 */
export const NavigationButton = observer(
  ({ className, sx = [], title, subtitle, onClick, icon }: NavigationButtonProps) => {
    const theme = useTheme();

    return (
      <CardActionArea
        className={className}
        sx={{
          ...sx,
          border: `1px solid ${theme.palette.action.disabledBackground}`,
          textTransform: 'none',
          justifyContent: 'space-between',
          px: 3,
          py: 1.5,
          borderRadius: 1,
          '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,

            '*': {
              color: 'inherit'
            }
          }
        }}
        onClick={() => onClick()}
      >
        <Stack
          direction="row"
          spacing={3}
          sx={{
            alignItems: 'center'
          }}
        >
          {icon?.({ color: theme.palette.text.secondary })}

          <Stack
            sx={{
              flex: 1
            }}
          >
            <Typography variant="body1">{title}</Typography>
            <Typography
              className="subtitle"
              variant="body2"
              sx={{
                color: theme.palette.text.secondary
              }}
            >
              {subtitle}
            </Typography>
          </Stack>

          <ArrowForwardIosIcon sx={{ fontSize: 16, color: theme.palette.action.disabled }} />
        </Stack>
      </CardActionArea>
    );
  }
);
