import { UserDashboardCalendarWeekPeriodInfoItem } from '@/viewmodels';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../../../utils';
import { ColorIndicator } from '../../../../lists';

export interface UserDashboardCalendarWeekPeriodMoreItemsProps {
  sx?: SxProps;
  className?: string;
  items: UserDashboardCalendarWeekPeriodInfoItem[];
  isAllItems: boolean;
}

export const UserDashboardCalendarWeekPeriodMoreItems = observer(
  ({ sx = [], className, items, isAllItems }: UserDashboardCalendarWeekPeriodMoreItemsProps) => {
    // Only showing a maximum of 3 colors.
    const colors = items.map((item) => item.value.color).slice(0, 3);
    const strings = LocalizedStrings.calendar.week;

    return (
      <Stack
        className={className}
        direction="row"
        spacing={0.5}
        sx={[
          {
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {colors.length > 0 && (
          <Stack
            direction="row"
            spacing="2px"
            sx={{
              height: 14
            }}
          >
            {colors.map((color, index) => (
              <ColorIndicator key={index} color={color} isRounded />
            ))}
          </Stack>
        )}
        <Typography
          noWrap
          sx={{
            fontSize: 12,
            flex: 1,
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {isAllItems ? strings.periodItemsCount(items.length) : strings.periodMoreWorks(items.length)}
        </Typography>
      </Stack>
    );
  }
);
