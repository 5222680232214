import { WorkIconInfo } from '@/models';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { css } from '@emotion/css';
import { FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { WorkIcon as WorkIconView } from '../../lists';

export interface WorkIconPickerProps {
  className?: string;

  workIcons: WorkIconInfo[];

  /**
   * The selected icon.
   */
  value: WorkIcon;

  /*
  The label. Optional.
   */
  label?: string;

  /**
   * Indicates whether to disable the picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when the selected icon changes.
   * @param iconId The new selected icon id.
   */
  onChange: (iconId: string) => void;

  /**
   * Elevation for the select menu.
   */
  elevation?: number;
}

export const WorkIconPicker = observer(
  ({ className, workIcons, value, label, disabled, onChange, elevation }: WorkIconPickerProps) => {
    const theme = useTheme();

    return (
      <FormControl className={className} sx={{ width: '100%' }} disabled={disabled} size="small">
        <InputLabel id="work-icon-picker-label">{label}</InputLabel>
        <Select
          classes={{ select: selected }}
          value={value.iconId}
          label={label}
          labelId="work-icon-picker-label"
          MenuProps={{ elevation }}
          onChange={(event) => onChange(event.target.value)}
        >
          {workIcons.map((icon, i) => (
            <MenuItem key={`work-icon-menu-item-${i}`} value={icon.id} sx={{ paddingLeft: theme.spacing(1) }}>
              <ListItemIcon>
                <WorkIconView key={icon.id} icon={icon} displayCourseSectionColor={false} />
              </ListItemIcon>
              <ListItemText>{icon.title}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

// If we don't specify "!important" for the display property, the value is not respected.
const selected = css([{ display: 'flex !important', alignItems: 'center', flexDirection: 'row' }]);
