import { Alert, Box, Collapse, List, Snackbar, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { ScheduleCycleUserDashboardContext } from '../../shared';
import { DrawerItem, UpdatablePresenter } from '../../utils';
import { AdminSchoolScheduleCycleSpecialDaysPage } from './AdminScheduleScheduleCycleSpecialDaysPage';
import { AdminSchoolScheduleCycleBellTimesPage } from './AdminSchoolScheduleCycleBellTimesPage';
import { AdminSchoolScheduleCycleDetailsPage } from './AdminSchoolScheduleCycleDetailsPage';
import { AdminSchoolScheduleCyclePendingChanges } from './AdminSchoolScheduleCyclePendingChanges';
import { AdminSchoolScheduleCycleClassSchedulesPage } from './class-schedules';

type Tab = 'details' | 'calendar' | 'bell-times' | 'class-schedules';

export interface AdminSchoolScheduleCyclePageProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCyclePage = observer(
  ({ sx = [], className, schoolId, scheduleCycleId, isReadOnly }: AdminSchoolScheduleCyclePageProps) => {
    const viewModel = useViewModel(
      (viewModels) => viewModels.createScheduleCycleDetailsViewModel(scheduleCycleId, schoolId, 'school'),
      [scheduleCycleId]
    );

    const [showHasDroppedChangesMessage, setShowHasDroppedChangesMessage] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTab: Tab = (searchParams.get('display') as Tab) ?? 'details';
    const strings = LocalizedStrings.admin.schedule.sidebar;

    function setSelectedTab(tab: Tab) {
      searchParams.set('display', tab);
      setSearchParams(searchParams);
    }

    return (
      <ScheduleCycleUserDashboardContext.Provider
        value={{ plannerId: undefined, dashboard: { id: schoolId, kind: 'school' }, scheduleCycleId }}
      >
        <UpdatablePresenter
          sx={sx}
          className={className}
          viewModel={viewModel}
          renderData={() => (
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden'
              }}
            >
              <Stack
                direction="row"
                spacing={3}
                sx={{
                  flex: 1,
                  overflow: 'hidden'
                }}
              >
                <List sx={{ width: 160, py: 2, overflow: 'auto' }}>
                  <DrawerItem
                    dense
                    data-intercom-target="school-admin-schedule-cycle-details-tab"
                    title={strings.details()}
                    selected={selectedTab === 'details'}
                    onClick={() => setSelectedTab('details')}
                    disablePadding
                  />
                  <DrawerItem
                    dense
                    data-intercom-target="school-admin-schedule-cycle-calendar-tab"
                    title={strings.calendar()}
                    selected={selectedTab === 'calendar'}
                    onClick={() => setSelectedTab('calendar')}
                    disablePadding
                  />
                  <DrawerItem
                    dense
                    data-intercom-target="school-admin-schedule-cycle-bell-times-tab"
                    title={strings.bellTimes()}
                    selected={selectedTab === 'bell-times'}
                    onClick={() => setSelectedTab('bell-times')}
                    disablePadding
                  />
                  <DrawerItem
                    dense
                    data-intercom-target="school-admin-schedule-cycle-class-schedules-tab"
                    title={strings.classSchedules()}
                    selected={selectedTab === 'class-schedules'}
                    onClick={() => setSelectedTab('class-schedules')}
                    disablePadding
                  />
                </List>

                <Box
                  sx={{
                    py: 1,
                    flex: 1,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Collapse in={viewModel.hasChanges}>
                    <AdminSchoolScheduleCyclePendingChanges
                      sx={{ mb: 2 }}
                      onUndo={() => viewModel.undo()}
                      onCancel={() => viewModel.cancel()}
                      onSave={() => viewModel.save()}
                      showHasDroppedChangesMessage={() => setShowHasDroppedChangesMessage(true)}
                    />
                  </Collapse>

                  {renderTab(selectedTab, schoolId, scheduleCycleId, isReadOnly)}

                  <Snackbar
                    open={showHasDroppedChangesMessage}
                    autoHideDuration={6000}
                    onClose={() => setShowHasDroppedChangesMessage(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  >
                    <Alert
                      onClose={() => setShowHasDroppedChangesMessage(false)}
                      severity="warning"
                      variant="filled"
                      sx={{ width: '100%' }}
                    >
                      {LocalizedStrings.admin.schedule.pendingChanges.publishDroppedChangesMessage()}
                    </Alert>
                  </Snackbar>
                </Box>
              </Stack>
            </Stack>
          )}
        />
      </ScheduleCycleUserDashboardContext.Provider>
    );
  }
);

function renderTab(tab: Tab, schoolId: string, scheduleCycleId: string, isReadOnly: boolean) {
  switch (tab) {
    case 'details':
      return (
        <AdminSchoolScheduleCycleDetailsPage
          sx={{ flex: 1 }}
          scheduleCycleId={scheduleCycleId}
          schoolId={schoolId}
          isReadOnly={isReadOnly}
        />
      );

    case 'calendar':
      return (
        <AdminSchoolScheduleCycleSpecialDaysPage
          sx={{ flex: 1 }}
          scheduleCycleId={scheduleCycleId}
          schoolId={schoolId}
          isReadOnly={isReadOnly}
        />
      );

    case 'bell-times':
      return <AdminSchoolScheduleCycleBellTimesPage sx={{ flex: 1 }} isReadOnly={isReadOnly} />;

    case 'class-schedules':
      return (
        <AdminSchoolScheduleCycleClassSchedulesPage
          sx={{ flex: 1 }}
          scheduleCycleId={scheduleCycleId}
          schoolId={schoolId}
          isReadOnly={isReadOnly}
        />
      );
  }
}
