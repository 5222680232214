import { Calendar } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/calendar_pb';
import { computed, makeObservable, observable } from 'mobx';

export interface GoogleCalendarViewModel {
  readonly id: string;
  readonly title: string;
  readonly hasChanges: boolean;

  isSelected: boolean;
}

export class AppGoogleCalendarViewModel implements GoogleCalendarViewModel {
  @observable private _isSelected: boolean;

  constructor(private readonly _calendar: Calendar) {
    makeObservable(this);
    this._isSelected = _calendar.isSelected;
  }

  @computed
  get id(): string {
    return this._calendar.googleCalendarId;
  }

  @computed
  get title(): string {
    return this._calendar.title;
  }

  @computed
  get hasChanges(): boolean {
    return this._calendar.isSelected !== this.isSelected;
  }

  @computed
  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }
}
