import { isError, isSxArray } from '@/utils';
import { UpdatableViewModel } from '@/viewmodels';
import { Box, BoxProps } from '@mui/material';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';
import { UpdatablePresenterError } from './UpdatablePresenterError';
import { UpdatablePresenterLoading } from './UpdatablePresenterLoading';

export interface UpdatablePresenterProps extends BoxProps {
  viewModel: UpdatableViewModel;

  /**
   * The loading message. Optional. Used when `customLoadingComponent` is not specified.
   */
  loadingMessage?: string;

  /**
   * The loading and error indicators size. Optional. Default is `normal`.
   */
  size?: 'small' | 'normal';

  /**
   * The error message. Optional.
   * @param error The error from which to get the message.
   * @return The error message or undefined.
   */
  errorMessageSelector?: (error: Error) => string | undefined;

  /**
   * Render the loaded data.
   */
  renderData: () => ReactNode;

  /**
   * Indicates if loading component should have a minHeight of 200. Optional. Defaults to true.
   */
  hasLoadingMinimumHeight?: boolean;

  /**
   * The component to display when loading the data. Optional. `LoadingIndicator` will be used if not specified.
   */
  customLoadingComponent?: () => JSX.Element;

  /**
   * Indicates if error component should have a minHeight of 200. Optional. Defaults to true.
   */
  hasErrorMinimumHeight?: boolean;

  /**
   * Indicates if the container for renderData should be of display flex. Defaults to true
   */
  isFlex?: boolean;

  /**
   * The component to display when an error occurs. Optional. `ErrorIndicator` will be used if not specified.
   * @param message The error message. Optional.
   */
  customErrorComponent?: (message?: string) => JSX.Element;
}

export const UpdatablePresenter = observer(
  ({
    sx = [],
    viewModel,
    renderData,
    hasLoadingMinimumHeight,
    customLoadingComponent,
    loadingMessage,
    size,
    hasErrorMinimumHeight,
    customErrorComponent,
    errorMessageSelector,
    isFlex,
    ...props
  }: UpdatablePresenterProps) => {
    useEffect(() => {
      // Loading data when component is first shown.
      void viewModel.reloadData();
    }, [viewModel]);

    useEffect(() => {
      if (isError(viewModel.state)) {
        captureException(viewModel.state);
      }
    }, [viewModel.state]);

    return (
      <>
        {viewModel.hasData ? (
          <Box
            {...props}
            sx={[
              {
                display: (isFlex ?? true) ? 'flex' : undefined,
                position: 'relative'
              },
              ...(isSxArray(sx) ? sx : [sx])
            ]}
          >
            {renderData()}
          </Box>
        ) : (
          <Box
            {...props}
            sx={[
              {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              },
              ...(isSxArray(sx) ? sx : [sx])
            ]}
          >
            {isError(viewModel.state) ? (
              <UpdatablePresenterError
                error={viewModel.state}
                customErrorComponent={customErrorComponent}
                errorMessageSelector={errorMessageSelector}
                reloadData={() => void viewModel.reloadData()}
                sx={{ minHeight: (hasErrorMinimumHeight ?? true) ? 200 : undefined, aspectRatio: '1/1' }}
              />
            ) : (
              <UpdatablePresenterLoading
                customLoadingComponent={customLoadingComponent}
                loadingMessage={loadingMessage}
                size={size}
                sx={{ minHeight: (hasLoadingMinimumHeight ?? true) ? 200 : undefined, aspectRatio: '1/1' }}
              />
            )}
          </Box>
        )}
      </>
    );
  }
);
