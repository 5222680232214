import { useServices, useViewModel } from '@/hooks';
import { ConnectedAppKind } from '@/models';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '../utils';
import { EditableConnectedApp } from './EditableConnectedApp';

export interface ConnectedAppEditDialogProps {
  /**
   * The connected app kind
   */
  connectedAppKind: ConnectedAppKind;
  /**
   * Indicates to start the synchronization the first time we load the data
   */
  startSync: boolean;

  plannerId: string;
  schoolId: string | undefined;

  isOpen: boolean;
  /**
   * Custom location to navigate to when closing dialog after saving changes. Not used when cancelling or user didn't
   * make changes.
   */
  closeLocationForChanges?: string;
}

export const ConnectedAppEditDialog = observer(
  ({
    connectedAppKind,
    startSync,
    isOpen,
    closeLocationForChanges,
    plannerId,
    schoolId
  }: ConnectedAppEditDialogProps) => {
    const { localization } = useServices();
    const navigate = useNavigate();

    function cancel() {
      navigate(-1);
      return Promise.resolve();
    }

    function close(hasChanges: boolean) {
      if (hasChanges && closeLocationForChanges) {
        navigate(closeLocationForChanges);
      } else {
        navigate(-1);
      }

      return Promise.resolve();
    }

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createConnectedAppEditDialogViewModel(
          connectedAppKind,
          plannerId,
          schoolId,
          startSync,
          close,
          cancel
        ),
      [plannerId]
    );

    return (
      <Dialog
        viewModel={viewModel}
        isOpen={isOpen}
        title={localization.localizedStrings.connectedApps.editDialogTitle}
        width="xs"
        contentPadding={{ left: 0, right: 0 }}
        renderData={() => <EditableConnectedApp viewModel={viewModel.editViewModel.editableConnectedApp} />}
      />
    );
  }
);
