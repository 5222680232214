import { useServices } from '@/hooks';
import { Paper, PaperProps, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { getBlurredClassName } from './BlurredStyle';

export interface BackgroundImageScreenPaperProps extends PaperProps {
  bordered?: boolean;
  preventBlur?: boolean;
}

export const BackgroundImageScreenPaper = observer(
  ({ bordered, square = false, preventBlur = false, ...props }: BackgroundImageScreenPaperProps) => {
    const { settings } = useServices();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    return (
      <Paper
        {...props}
        elevation={
          !isSmallScreen && !preventBlur && (settings.backgroundImage != null || theme.palette.mode === 'light') ? 1 : 0
        }
        square={square || isSmallScreen}
        classes={{
          ...props.classes,
          root: clsx(
            !isSmallScreen &&
              !preventBlur &&
              settings.backgroundImage != null &&
              !settings.reduceTransparency &&
              getBlurredClassName('paper', theme),
            props.classes?.root,
            bordered && 'bordered'
          )
        }}
      />
    );
  }
);
