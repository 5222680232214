import { WorkTransportService } from '@/transports';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type SchoolCourseSectionPublishedWorksLoadable = LoadableMap<PublishedWork>;

export class AppSchoolCourseSectionPublishedWorksLoadable
  extends BaseLoadableMap<PublishedWork>
  implements SchoolCourseSectionPublishedWorksLoadable
{
  constructor(
    private readonly _courseSectionId: string,
    private readonly _schoolId: string,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<Map<string, PublishedWork>> {
    const works = await this._workTransport.getPublishedWorks(this._schoolId, this._courseSectionId);
    return new Map(works.map((w) => [w.id, w]));
  }
}
