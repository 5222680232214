import { ItemRepeatPatternCycleDayKind } from '@/models';
import { getScheduleCycleKind, PlannerRepeatScheduleCycleInfo, titleForCycleDay } from '@/viewmodels';
import { Box, Chip, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { Subheader } from '../../../utils';

export interface PlannerRepeatItemCycleDayPatternOptionsProps {
  sx?: SxProps;
  className?: string;
  scheduleCycles: PlannerRepeatScheduleCycleInfo[];
  pattern: ItemRepeatPatternCycleDayKind;
  onChange: (pattern: ItemRepeatPatternCycleDayKind) => void;
}

export const PlannerRepeatItemCycleDayPatternOptions = observer(
  ({ sx = [], className, scheduleCycles, onChange, pattern }: PlannerRepeatItemCycleDayPatternOptionsProps) => {
    const showScheduleCycleTitle = scheduleCycles.length > 1;

    function toggleCycleDay(cycleDay: number, scheduleCycleId: string) {
      const newSelection = [...pattern.cycleDays];

      const existingScheduleIndex = newSelection.findIndex((v) => v.scheduleCycleId === scheduleCycleId);

      if (existingScheduleIndex >= 0) {
        const cycleDays = newSelection[existingScheduleIndex]?.cycleDays ?? [];
        const existingCycleDayIndex = cycleDays.indexOf(cycleDay) ?? -1;

        if (existingCycleDayIndex >= 0) {
          cycleDays.splice(existingCycleDayIndex, 1);
        } else {
          cycleDays.push(cycleDay);
        }

        newSelection[existingScheduleIndex] = { cycleDays, scheduleCycleId };
      } else {
        newSelection.push({ cycleDays: [cycleDay], scheduleCycleId });
      }

      onChange({ cycleDays: newSelection, case: 'cycleDay' });
    }

    return (
      <Stack sx={sx} className={className} spacing={1}>
        {scheduleCycles.map((info) => (
          <Fragment key={info.userDashboard.id}>
            {info.scheduleCycles.map((sc) => (
              <Stack key={sc.scheduleCycle.id}>
                {showScheduleCycleTitle && (
                  <Subheader>{`${sc.scheduleCycle.name} - ${info.userDashboard.title}`}</Subheader>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    rowGap: 1,
                    columnGap: 1,
                    overflow: 'hidden',
                    flexWrap: 'wrap'
                  }}
                >
                  {times(sc.scheduleCycle.cycleDayCount).map((cycleDay) => {
                    const isSelected = pattern.cycleDays.find(
                      (v) => v.scheduleCycleId === sc.scheduleCycle.id && v.cycleDays.includes(cycleDay + 1)
                    );

                    return (
                      <Chip
                        key={cycleDay}
                        onClick={() => toggleCycleDay(cycleDay + 1, sc.scheduleCycle.id)}
                        label={titleForCycleDay(
                          cycleDay + 1,
                          getScheduleCycleKind(sc.scheduleCycle),
                          'long',
                          true,
                          sc.scheduleCycle.cycleDayNames
                        )}
                        color={isSelected ? 'primary' : 'default'}
                        variant={isSelected ? 'filled' : 'outlined'}
                      />
                    );
                  })}
                </Box>
              </Stack>
            ))}
          </Fragment>
        ))}
      </Stack>
    );
  }
);
