import { ScheduleCycleActivitySchedulesDayColumnInfo } from '@/viewmodels';
import { WarningAmberRounded } from '@mui/icons-material';
import { CardActionArea, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent } from 'react';
import LocalizedStrings from 'strings';
import { AlertTooltip } from '../../../../utils';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridDayHeaderProps {
  sx?: SxProps;
  column: ScheduleCycleActivitySchedulesDayColumnInfo;
  onClick?: (column: ScheduleCycleActivitySchedulesDayColumnInfo, event: MouseEvent<HTMLElement>) => void;
  isReadOnly: boolean;
}

export const ScheduleCyclePeriodScheduleGridDayHeader = observer(
  ({ sx = [], column, onClick, isReadOnly }: ScheduleCyclePeriodScheduleGridDayHeaderProps) => {
    const strings = LocalizedStrings.scheduleCycle.edit.masterSchedule;
    const multiplePeriodsTooltip = column.hasMultiplePeriodsWithSameLabel
      ? strings.multiplePeriodsWithSameLabelWarning()
      : undefined;
    const otherOccurrencesTooltip = column.hasOtherOccurrencesWithSameTarget
      ? strings.hasOtherOccurrencesWithSameTarget()
      : undefined;

    return (
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          alignItems: 'center',
          ...sx,
          width: ScheduleCyclePeriodScheduleGridConstants.dayColumnWidth
        }}
      >
        <CardActionArea
          sx={{ p: column.subtitle ? 0.5 : 1 }}
          onClick={onClick != null ? (e) => onClick(column, e) : undefined}
          disabled={onClick == null || isReadOnly}
        >
          <Stack
            sx={{
              flex: 1
            }}
          >
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: (theme) => theme.palette.text[column.canEdit ? 'primary' : 'secondary']
              }}
            >
              {column.title}
            </Typography>
            <Typography
              variant="caption"
              noWrap
              sx={{
                color: (theme) => theme.palette.text.secondary,
                fontStyle: !column.canEdit ? 'italic' : undefined
              }}
            >
              {column.subtitle}
            </Typography>
          </Stack>
        </CardActionArea>
        {(multiplePeriodsTooltip != null || otherOccurrencesTooltip != null) && (
          <AlertTooltip titles={[multiplePeriodsTooltip, otherOccurrencesTooltip]} title="" severity="warning">
            <WarningAmberRounded color="warning" fontSize="small" />
          </AlertTooltip>
        )}
      </Stack>
    );
  }
);
