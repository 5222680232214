import { AttachmentKind as PlannerAttachmentKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/attachment_kind_pb';
import { AttachmentKind as SchoolAttachmentKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/attachment_kind_pb';

export type AttachmentInfoKind = 'link' | 'file' | 'shared_file' | 'form' | 'video' | 'unspecified';

export interface AttachmentInfo {
  readonly kind: AttachmentInfoKind;
  readonly title: string | undefined;
  readonly thumbnailUrl: string | undefined;
  readonly url: string;
  readonly externalId: string | undefined;
  readonly externalKind: string | undefined;
}

export function plannerAttachmentKindToAttachmentInfoKind(kind: PlannerAttachmentKind): AttachmentInfoKind {
  switch (kind) {
    case PlannerAttachmentKind.FILE:
      return 'file';
    case PlannerAttachmentKind.LINK:
      return 'link';
    case PlannerAttachmentKind.FORM:
      return 'form';
    case PlannerAttachmentKind.UNSPECIFIED:
      return 'unspecified';
    case PlannerAttachmentKind.SHARED_FILE:
      return 'shared_file';
    case PlannerAttachmentKind.VIDEO:
      return 'video';
  }
}

export function plannerAttachmentInfoKindToAttachmentInfo(kind: AttachmentInfoKind): PlannerAttachmentKind {
  switch (kind) {
    case 'file':
      return PlannerAttachmentKind.FILE;
    case 'link':
      return PlannerAttachmentKind.LINK;
    case 'form':
      return PlannerAttachmentKind.FORM;
    case 'unspecified':
      return PlannerAttachmentKind.UNSPECIFIED;
    case 'shared_file':
      return PlannerAttachmentKind.SHARED_FILE;
    case 'video':
      return PlannerAttachmentKind.VIDEO;
  }
}

export function schoolAttachmentKindToAttachmentInfoKind(kind: SchoolAttachmentKind): AttachmentInfoKind {
  switch (kind) {
    case SchoolAttachmentKind.FILE:
      return 'file';
    case SchoolAttachmentKind.LINK:
      return 'link';
    case SchoolAttachmentKind.FORM:
      return 'form';
    case SchoolAttachmentKind.UNSPECIFIED:
      return 'unspecified';
    case SchoolAttachmentKind.SHARED_FILE:
      return 'shared_file';
    case SchoolAttachmentKind.VIDEO:
      return 'video';
  }
}

export function schoolAttachmentInfoKindToAttachmentInfo(kind: AttachmentInfoKind): SchoolAttachmentKind {
  switch (kind) {
    case 'file':
      return SchoolAttachmentKind.FILE;
    case 'link':
      return SchoolAttachmentKind.LINK;
    case 'form':
      return SchoolAttachmentKind.FORM;
    case 'unspecified':
      return SchoolAttachmentKind.UNSPECIFIED;
    case 'shared_file':
      return SchoolAttachmentKind.SHARED_FILE;
    case 'video':
      return SchoolAttachmentKind.VIDEO;
  }
}
