import { UserDashboardCalendarDaySpecificDateViewModel } from '@/viewmodels';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { addDays, format, subDays } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { UserDashboardCalendarHeaderView } from '../shared';
import { UserDashboardCalendarDayOptionsPopover } from './UserDashboardCalendarDayOptionsPopover';

export interface UserDashboardCalendarDayHeaderViewProps {
  sx?: SxProps;
  className?: string;
  day: UserDashboardCalendarDaySpecificDateViewModel;
  hasCalendarSyncError: boolean;
  retryFetchData: () => Promise<void>;
}

export const UserDashboardCalendarDayHeaderView = observer(
  ({ sx = [], className, day, hasCalendarSyncError, retryFetchData }: UserDashboardCalendarDayHeaderViewProps) => {
    const previousDate = subDays(day.date, 1);
    const nextDate = addDays(day.date, 1);
    const [optionsButtonRef, setOptionsButtonRef] = useState<HTMLButtonElement | undefined>();

    return (
      <Box sx={sx} className={className}>
        <UserDashboardCalendarHeaderView
          previousDate={previousDate}
          nextDate={nextDate}
          isDisplayingToday={day.isToday}
          title={format(day.date, 'PPPP')}
          subtitle={day.cycleDayName.length > 0 ? day.cycleDayName : undefined}
          showTodayIndicator={day.isToday}
          onOptionsButtonClick={(e) => setOptionsButtonRef(e.currentTarget)}
          scheduleCycleWithDraftId={day.scheduleCycleWithDraftId}
          hasCalendarSyncError={hasCalendarSyncError}
          retryFetchData={() => retryFetchData()}
        />

        <UserDashboardCalendarDayOptionsPopover
          open={optionsButtonRef != null}
          anchorEl={optionsButtonRef}
          close={() => setOptionsButtonRef(undefined)}
        />
      </Box>
    );
  }
);
