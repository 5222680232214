import { UserDashboardCalendarItemState, UserDashboardCalendarNoteInfo } from '@/viewmodels';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../../../utils';
import { ColorIndicator, NoteIcon } from '../../../../lists';
import { ContextMenuHandler } from '../../../ContextMenuHandler';

export interface UserDashboardCalendarWeekPeriodNoteProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarNoteInfo;
}

export const UserDashboardCalendarWeekPeriodNote = observer(
  ({ sx = [], className, note }: UserDashboardCalendarWeekPeriodNoteProps) => {
    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Stack
            className={className}
            direction="row"
            spacing={0.5}
            onContextMenu={contextMenuHandler}
            sx={[
              {
                alignItems: 'stretch',
                overflow: 'hidden'
              },
              ...(isSxArray(sx) ? sx : [sx])
            ]}
          >
            <Box sx={{ height: '100%', py: 0.5 }}>
              <ColorIndicator isRounded color={note.color} sx={{ height: '100%' }} />
            </Box>

            <NoteIcon size={14} sx={{ py: 0.5 }} />

            <Stack
              spacing={-0.5}
              sx={{
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <Typography
                noWrap
                color={getColorForTitle(note.state)}
                sx={{
                  fontSize: 10
                }}
              >
                {note.text}
              </Typography>
            </Stack>
          </Stack>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
