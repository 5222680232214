import { useServices, useViewModel } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../utils';
import { UserProperties } from './UserProperties';

export interface UserPropertiesDialogProps {
  isOpen: boolean;
}

export const UserPropertiesDialog = observer(({ isOpen }: UserPropertiesDialogProps) => {
  const { localization } = useServices();

  function close() {
    // Nothing to do. It will automatically close as the user now has a name.
    return Promise.resolve();
  }

  const viewModel = useViewModel((viewModels) =>
    viewModels.createUserPropertiesDialogViewModel(
      () => close(),
      () => close()
    )
  );

  const strings = localization.localizedStrings.user;

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      disableClose={viewModel.viewModel.isForRequiredName}
      title={strings.editPropertiesDialogTitle}
      width="sm"
      contentPadding={{ top: 2 }}
      renderData={() => <UserProperties viewModel={viewModel.viewModel} />}
    />
  );
});
