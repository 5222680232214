import { Locale, localizedCompare } from '@/utils';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { AccessKind as SchoolAccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';

export function schoolHasAccessKindsForUser(
  userId: string,
  school: SchoolInformation,
  ...accessKinds: SchoolAccessKind[]
): boolean {
  return school.school!.accessRights.some((a) => a.userId === userId && accessKinds.includes(a.kind));
}

export function isAdminOfSharedSchool(userId: string, school: SchoolInformation): boolean {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    school.school!.sharingMode === SchoolSharingMode.SHARED && school.administrators.some((a) => a.userId === userId)
  );
}

export function compareSchoolCourseSections(first: CourseSection, second: CourseSection, locale: Locale): number {
  return localizedCompare(first.title, second.title, locale) || localizedCompare(first.section, second.section, locale);
}
