import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { chain } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { Loadable, SchoolDataStore, SchoolStudentAccountsLoadable } from '../../stores';
import { localizedCompare, propertiesHaveMatchForSearchText } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminStudentsListViewModel extends UpdatableViewModel {
  readonly studentsCount: number;
  readonly students: Account[];
  searchText: string;
}

export class AppAdminStudentsListViewModel extends BaseUpdatableViewModel implements AdminStudentsListViewModel {
  @observable private _searchText = '';

  constructor(
    private readonly _schoolId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get studentsLoadable(): SchoolStudentAccountsLoadable {
    return this._schoolStore.getStudents(this._schoolId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.studentsLoadable];
  }

  @computed
  get studentsCount(): number {
    return this.studentsLoadable.values.length;
  }

  @computed
  get students(): Account[] {
    return chain(this.studentsLoadable.values)
      .filter((a) =>
        propertiesHaveMatchForSearchText(this._searchText, [
          a.fullName,
          a.emailAddress,
          { value: a.id, method: 'exact', caseSensitive: true }
        ])
      )
      .sort((a1, a2) => localizedCompare(a1.fullName, a2.fullName, this._localization.currentLocale))
      .value();
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }
}
