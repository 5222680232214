import { UserTransportService } from '@/transports';
import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { Intercom, showNewMessage, shutdown, trackEvent, update } from '@intercom/messenger-js-sdk';
import { captureException } from '@sentry/react';
import { EnvironmentService, IntercomData, IntercomService, UserService } from '../contracts';

export class AppIntercomService implements IntercomService {
  private _booted = false;
  private _bootedUserId: string | undefined;
  private _isVisible = true;

  constructor(
    private readonly _environment: EnvironmentService,
    private readonly _userTransport: UserTransportService,
    private readonly _user: UserService
  ) {}

  get isBooted() {
    return this._booted;
  }

  get bootedUserId() {
    return this._bootedUserId;
  }

  async boot(user: UserProfile | undefined): Promise<void> {
    let userHash: string | undefined;

    // If Intercom is not booted (first start), no need to check for user. We always want to boot.
    if (this._booted) {
      if (this._bootedUserId === user?.userId) {
        // Already booted for this user. No need to do anything.
        return;
      } else {
        // Different user. Shutting down current Intercom session.
        this.shutdown();
      }
    }

    try {
      if (user != null) {
        userHash = await this._userTransport.getIntercomUserHash();
      }
    } catch (e) {
      captureException(e);
      console.error(`Could not get Intercom user hash: ${(e as Error).message}`);
    } finally {
      Intercom({
        app_id: this._environment.intercomAppId,
        app_version: this._environment.version,
        app_build_number: this._environment.buildNumber,
        hide_default_launcher: !this._isVisible,
        user_id: user?.userId,
        user_hash: userHash
      });

      this._booted = true;
      this._bootedUserId = user?.userId;
    }
  }

  async update(data: IntercomData) {
    if (!this._booted) {
      return;
    }

    const user = this._user.currentUser;
    let userHash: string | undefined;

    try {
      if (user != null) {
        userHash = await this._userTransport.getIntercomUserHash();
      }
    } catch (e) {
      captureException(e);
      console.error(`Could not get Intercom user hash: ${(e as Error).message}`);
    } finally {
      update({
        ...data,
        app_id: this._environment.intercomAppId,
        app_version: this._environment.version,
        app_build_number: this._environment.buildNumber,
        hide_default_launcher: !this._isVisible,
        user_id: user?.userId,
        user_hash: userHash
      });
    }
  }

  hide() {
    if (this._isVisible) {
      this._isVisible = false;
      void this.update({});
    }
  }

  show() {
    if (!this._isVisible) {
      this._isVisible = true;
      void this.update({});
    }
  }

  showChat(message?: string) {
    showNewMessage(message ?? '');
  }

  trackEvent(event: string, metadata?: unknown) {
    trackEvent(event, metadata);
  }

  shutdown() {
    shutdown();
    this._isVisible = false;
    this._booted = false;
    this._bootedUserId = undefined;
  }
}
