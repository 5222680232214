import { WorkIcons } from '@/models';
import { WorkTransportService } from '@/transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type WorkIconsLoadable = LoadableValue<WorkIcons>;

export class AppWorkIconsLoadable extends BaseLoadableValue<WorkIcons> implements WorkIconsLoadable {
  constructor(private readonly _workTransport: WorkTransportService) {
    super();
  }

  protected async loadData(): Promise<WorkIcons> {
    return await this._workTransport.getWorkIcons();
  }
}
