import { ServiceContainer } from '@/providers';
import LocalizedStrings from '@/resources/strings';
import { LocalizationService } from '@/services';
import { Loadable, SchoolDataStore, SchoolTeacherAccountsLoadable } from '@/stores';
import { localizedCompare, propertiesHaveMatchForSearchText } from '@/utils';
import { AccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { captureException } from '@sentry/react';
import _ from 'lodash';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface AdminSchoolAddAdminDialogViewModel extends UpdatableDialogViewModel {
  readonly availableTeachers: Account[];
  selectedTeacher: Account | undefined;
  searchText: string;
}

export class AppAdminSchoolAddAdminDialogViewModel
  extends AppBaseUpdatableDialogViewModel
  implements AdminSchoolAddAdminDialogViewModel
{
  @observable private _selectedTeacher: Account | undefined;
  @observable private _searchText = '';
  @observable private _isSubmitting = false;

  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    private readonly _schoolId: string,
    onDismiss: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super(localization, onDismiss);
    makeObservable(this);

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._saveButtonConfig = new SaveDialogActionButtonConfiguration(
      'main',
      this._localization,
      () => this.addSelectedAdmin(),
      () => LocalizedStrings.admin.general.addAdmin.addButton(),
      'add'
    );
  }

  @computed
  private get teachersLoadable(): SchoolTeacherAccountsLoadable {
    return this._schoolStore.getTeachers(this._schoolId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.teachersLoadable];
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isSubmitting;
    this._saveButtonConfig.isEnabled = !this.isSubmitting && (this._selectedTeacher?.userId.length ?? 0) > 0;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get availableTeachers(): Account[] {
    if (this.teachersLoadable.hasData) {
      return _.chain(this.teachersLoadable.values)
        .filter((t) => propertiesHaveMatchForSearchText(this._searchText, [t.fullName, t.emailAddress]))
        .sort((t1, t2) => localizedCompare(t1.fullName, t2.fullName, this._localization.currentLocale))
        .value();
    }

    return [];
  }

  @computed
  get selectedTeacher(): Account | undefined {
    return this._selectedTeacher;
  }

  set selectedTeacher(value: Account | undefined) {
    this._selectedTeacher = value;
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get state(): UpdatableViewModelState {
    return this.teachersLoadable.state;
  }

  @computed
  get hasData(): boolean {
    return this.teachersLoadable.hasData;
  }

  @computed
  get hasChanges(): boolean {
    return this._selectedTeacher != null;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  async reloadData(): Promise<void> {
    await this.teachersLoadable.fetch(false);
  }

  @action
  private async addSelectedAdmin(): Promise<void> {
    if (this._selectedTeacher != null) {
      this._isSubmitting = true;
      this._error = undefined;

      try {
        await this._schoolStore.addSchoolAccess(this._schoolId, this._selectedTeacher.userId, AccessKind.FULL_ACCESS);
        await this._onDismiss();
      } catch (error) {
        captureException(error);
        runInAction(() => (this._error = LocalizedStrings.admin.general.addAdmin.unexpectedErrorMessage()));
      } finally {
        runInAction(() => (this._isSubmitting = false));
      }
    }
  }
}
