import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface AccountManagementViewModel {
  readonly isDeleting: boolean;
  readonly error: { title: string; message: string } | undefined;
  deleteAccount(): Promise<void>;
  dismissError(): void;
}

export class AppAccountManagementViewModel implements AccountManagementViewModel {
  @observable private _isDeleting = false;
  @observable private _error: { title: string; message: string } | undefined;

  constructor(
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  get isDeleting(): boolean {
    return this._isDeleting;
  }

  @computed
  get error(): { title: string; message: string } | undefined {
    return this._error;
  }

  async deleteAccount() {
    if (this._isDeleting) {
      return;
    }

    runInAction(() => {
      this._isDeleting = true;
    });

    try {
      // This signs out the user.
      await this._userStore.permanentlyDeleteCurrentUserData();
    } catch (error) {
      captureException(error);
      const strings = this._localization.localizedStrings.user;

      runInAction(
        () =>
          (this._error = {
            title: strings.deleteAccountUnexpectedErrorTitle,
            message: strings.deleteAccountUnexpectedErrorMessage
          })
      );
    } finally {
      runInAction(() => (this._isDeleting = false));
    }
  }

  @action
  dismissError() {
    this._error = undefined;
  }
}
