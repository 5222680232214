import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerItemsNoResultsIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerItemsNoResultsIndicator = observer(
  ({ sx = [], className }: PlannerItemsNoResultsIndicatorProps) => {
    return (
      <Typography
        className={className}
        variant="body1"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          ...sx,
          px: 3,
          py: 2
        }}
      >
        {LocalizedStrings.planner.items.emptyNoResultsLabel()}
      </Typography>
    );
  }
);
