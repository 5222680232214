import { ConnectedAppKind } from '@/models';
import { ClassroomIcon, GoogleCalendarIcon } from '@/resources/images/connectedApps';
import { merge } from 'lodash';
import { CSSProperties, SVGProps } from 'react';

export interface ConnectedAppIconProps {
  /**
   * The connected app kind. Used to determine which icon to render.
   */
  kind: ConnectedAppKind;

  className?: string;

  style?: CSSProperties;
  width?: number;
}

export const ConnectedAppIcon = ({ className, style, kind, width = 32 }: ConnectedAppIconProps) => {
  const svgProps: SVGProps<SVGSVGElement> = {
    className,
    style: merge({ width, height: '100%' }, { ...style })
  };

  switch (kind) {
    case 'classroom':
      return <ClassroomIcon {...svgProps} />;

    case 'googleCalendar':
      return <GoogleCalendarIcon {...svgProps} />;
  }
};
