import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { differenceInCalendarDays, isBefore, isEqual } from 'date-fns';

export type UserDashboardCalendarItemState = 'completed' | 'active' | 'upcoming' | 'late';

export function getUserDashboardCalendarItemState(
  itemStartDate: Date,
  itemEndDate: Date | undefined,
  isAllDay: boolean,
  currentDate: Date,
  workStatus?: WorkStatus
): UserDashboardCalendarItemState {
  if (workStatus != null && workStatus === WorkStatus.COMPLETED) {
    return 'completed';
  }

  if (isAllDay) {
    const dayDiff = differenceInCalendarDays(currentDate, itemStartDate);
    return dayDiff <= 0 ? 'upcoming' : 'completed';
  }

  if (isBefore(currentDate, itemStartDate)) {
    return 'upcoming';
  }

  if (
    isEqual(currentDate, itemStartDate) ||
    (itemEndDate != null && (isBefore(currentDate, itemEndDate) || isEqual(currentDate, itemEndDate)))
  ) {
    return 'active';
  }

  return workStatus != null ? 'late' : 'completed';
}
