import { isError } from '@/utils';
import { LoadableState } from './Loadable';

export function mergeLoadableStates(states: LoadableState[]): LoadableState {
  if (states.some((state) => state === 'pending')) {
    return 'pending';
  } else if (states.every((state) => state === 'fulfilled')) {
    return 'fulfilled';
  } else {
    return states.find((state) => isError(state)) ?? undefined;
  }
}
