import { GoogleCalendarSyncBlocker } from '@/models';
import { ServiceContainer } from '@/providers';
import { GoogleCalendarConnectedAppService, LocalizationService } from '@/services';
import { PlannerDataStore } from '@/stores';
import { computed, makeObservable } from 'mobx';
import {
  AppConnectedAppUserDashboardViewModel,
  ConnectedAppUserDashboardViewModel
} from '../ConnectedAppUserDashboardViewModel';

export type GoogleCalendarConnectedAppViewModel = ConnectedAppUserDashboardViewModel;

export class AppGoogleCalendarConnectedAppViewModel
  extends AppConnectedAppUserDashboardViewModel<GoogleCalendarSyncBlocker>
  implements GoogleCalendarConnectedAppViewModel
{
  constructor(
    connectedApp: GoogleCalendarConnectedAppService,
    plannerId: string,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(connectedApp, plannerId, plannerStore);

    makeObservable(this);
  }

  readonly isEntitled = true;

  @computed
  get warnings(): string[] {
    const strings = this._localization.localizedStrings.connectedApps;
    return this._connectedApp.warningSyncBlockers.map(strings.googleCalendarSyncBlockerError);
  }
}
