import i18n from 'translate';

export const CalendarWeekOptionsStrings = {
  densityCompact: () => i18n.t('calendar.week.options.densityCompact'),
  densityLarge: () => i18n.t('calendar.week.options.densityLarge'),
  densityRegular: () => i18n.t('calendar.week.options.densityRegular'),
  densityTitle: () => i18n.t('calendar.week.options.densityTitle'),
  showFreePeriods: () => i18n.t('calendar.week.options.showFreePeriods'),
  showOptionsSectionTitle: () => i18n.t('calendar.week.options.showOptionsSectionTitle'),
  showPeriodLabels: () => i18n.t('calendar.week.options.showPeriodLabels'),
  showWeekends: () => i18n.t('calendar.week.options.showWeekends'),
  title: () => i18n.t('calendar.week.options.title')
};

export const CalendarWeekStrings = {
  allDayMoreItems: (count: number) => i18n.t('calendar.week.allDayMoreItems', { count }),
  allDayMorePopoverTitle: () => i18n.t('calendar.week.allDayMorePopoverTitle'),
  options: CalendarWeekOptionsStrings,
  periodOccurrenceNoClass: () => i18n.t('calendar.week.periodOccurrenceNoClass'),
  periodItemsCount: (count: number) => i18n.t('calendar.week.periodItemsCount', { count }),
  periodMoreWorks: (count: number) => i18n.t('calendar.week.periodMoreWorks', { count })
};
