import { useServices } from '@/hooks';
import { TimeOfDay, dateToTimeOfDay } from '@/models';
import { Box, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

export interface UserDashboardCalendarCurrentTimeIndicatorProps {
  pointsPerHour: number;
  isFirstDay: boolean;
}

export function UserDashboardCalendarCurrentTimeIndicator({
  pointsPerHour,
  isFirstDay
}: UserDashboardCalendarCurrentTimeIndicatorProps) {
  const theme = useTheme();
  const { dateService } = useServices();

  const [currentTimeOffset, setCurrentTimeOffset] = useState(
    getOffsetForTimeOfDay(dateToTimeOfDay(dateService.now), pointsPerHour)
  );

  function updateCurrentTimeOffset() {
    const newOffset = getOffsetForTimeOfDay(dateToTimeOfDay(dateService.now), pointsPerHour);
    setCurrentTimeOffset(newOffset);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      updateCurrentTimeOffset();
    }, 60 * 1_000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    updateCurrentTimeOffset();
  }, [pointsPerHour]);

  return (
    <Fragment>
      <Box
        sx={{
          position: 'absolute',
          top: currentTimeOffset - 5.5,
          // If it is the first day of the week, the circle is clipped by the times column. Using zIndex didn't fix it,
          // so changing the alignment instead for the first day. Not ideal, but better than nothing.
          left: isFirstDay ? 0 : -6.5,
          zIndex: 100,
          width: 12,
          height: 12,
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%'
        }}
      />
      <Box
        sx={{
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          position: 'absolute',
          top: currentTimeOffset - 1,
          left: isFirstDay ? 6.5 : 0,
          right: 0,
          zIndex: 100
        }}
      />
    </Fragment>
  );
}

function getOffsetForTimeOfDay(timeOfDay: TimeOfDay, pointsPerHour: number) {
  return timeOfDay.hours * pointsPerHour + (timeOfDay.minutes * pointsPerHour) / 60;
}
