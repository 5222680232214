import { WorkStepInfo } from '@/viewmodels';
import { CheckCircleRounded, CircleOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../utils';

export interface WorkDetailsPlannedWorkStepProps {
  sx?: SxProps;
  className?: string;
  step: WorkStepInfo;
  isReadOnly: boolean;
}

export const WorkDetailsPlannedWorkStep = observer(
  ({ sx = [], step, isReadOnly, className }: WorkDetailsPlannedWorkStepProps) => {
    return (
      <Stack
        className={className}
        direction="row"
        spacing={1}
        sx={[
          {
            alignItems: 'center',
            overflow: 'hidden'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <IconButton
          size="small"
          disabled={isReadOnly}
          onClick={() => (step.isCompleted = !step.isCompleted)}
          color={step.isCompleted ? 'primary' : undefined}
        >
          {step.isCompleted ? <CheckCircleRounded fontSize="small" /> : <CircleOutlined fontSize="small" />}
        </IconButton>
        <Typography
          variant="body2"
          noWrap
          sx={{
            flex: 1,
            color: step.isCompleted ? (theme) => theme.palette.text.secondary : undefined,
            textDecoration: step.isCompleted ? 'line-through' : undefined
          }}
        >
          {step.title}
        </Typography>
      </Stack>
    );
  }
);
