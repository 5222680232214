import { plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { arrayMoveImmutable } from '../../utils';
import { AppWorkStepEditViewModel, WorkStepEditViewModel } from './WorkStepEditViewModel';
import { AppWorkStepInfo, WorkStepInfo } from './WorkStepInfo';

export interface WorkStepsViewModel {
  readonly workId: string;
  readonly plannerId: string;
  readonly isReadOnly: boolean;
  readonly steps: WorkStepInfo[];
  readonly completedStepsCount: number;
  readonly isSaving: boolean;
  readonly saveError: string | undefined;

  moveStep(oldIndex: number, newIndex: number): void;
  makeStepEditViewModel(step: WorkStep | undefined, onSave: () => void, onCancel: () => void): WorkStepEditViewModel;
}

export class AppWorkStepsViewModel implements WorkStepsViewModel {
  @observable private _hasChangedSteps = false;
  @observable private _isSaving = false;
  @observable private _saveError: string | undefined;
  @observable private _steps: WorkStepInfo[];

  constructor(
    private readonly _work: () => Work,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
    this._steps = this._work().steps.map((step) => new AppWorkStepInfo(step, () => this.onChange()));
  }

  @computed
  private get work() {
    return this._work();
  }

  @computed
  get workId(): string {
    return this.work.id;
  }

  @computed
  get plannerId(): string {
    return this.work.plannerId;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get saveError(): string | undefined {
    return this._saveError;
  }

  @computed
  get isReadOnly(): boolean {
    const planner = this._userStore.getPlannerForId(this.work.plannerId);

    if (planner == null) {
      return true;
    }

    return !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get steps(): WorkStepInfo[] {
    return this._steps;
  }

  @computed
  get completedStepsCount(): number {
    return this._steps.filter((s) => s.isCompleted).length;
  }

  @action
  moveStep(oldIndex: number, newIndex: number) {
    this._steps = arrayMoveImmutable(this._steps, oldIndex, newIndex);
  }

  makeStepEditViewModel(step: WorkStep | undefined, onSave: () => void, onCancel: () => void): WorkStepEditViewModel {
    return new AppWorkStepEditViewModel(
      () => this.work,
      step,
      (step, plannedWorkIds) => {
        this.addOrUpdateStep(step, plannedWorkIds);
        onSave();
      },
      onCancel,
      step != null ? () => this.deleteStep(step.id) : undefined
    );
  }

  @action
  private addOrUpdateStep(step: WorkStep, plannedWorkIds: string[]) {
    const newStep = new AppWorkStepInfo(step, () => this.onChange());
    const existingIndex = this._steps.findIndex((step) => step.id === newStep.id);

    if (existingIndex >= 0) {
      this._steps[existingIndex] = newStep;
    } else {
      this._steps.push(newStep);
    }

    this.onChange(async () => {
      // Updating stepsIds property of selected PlannedWorks.
      for (const id of plannedWorkIds) {
        const plannedWork = this.work.plannedWorks.find((pw) => pw.id === id);

        if (plannedWork != null) {
          const stepIds = [...plannedWork.workStepIds, step.id];
          await this.updatePlannedWorkWithStepIds(plannedWork, stepIds);
        }
      }
    });
  }

  @action
  private deleteStep(id: string) {
    const existingIndex = this._steps.findIndex((s) => s.id === id);
    if (existingIndex >= 0) {
      this._steps.splice(existingIndex, 1);
    }

    this.onChange(async (newSteps) => {
      const stepIds = newSteps.map((s) => s.id);

      for (const plannedWork of this.work.plannedWorks) {
        const newStepIds = plannedWork.workStepIds.filter((id) => stepIds.includes(id));

        if (newStepIds.length != plannedWork.workStepIds.length) {
          // If some stepId reference in the planned work are not available in the new list of step, we remove them.
          await this.updatePlannedWorkWithStepIds(plannedWork, newStepIds);
        }
      }
    });
  }

  private onChange(additionalAction?: (newSteps: WorkStep[]) => Promise<void>) {
    // Called whenever a change occurs in the step list (reorder, complete step, etc.).
    this._hasChangedSteps = true;
    void this.saveChanges(additionalAction);
  }

  private async saveChanges(additionalAction?: (newSteps: WorkStep[]) => Promise<void>) {
    if (!this._hasChangedSteps) {
      return;
    }

    runInAction(() => (this._hasChangedSteps = false));

    try {
      runInAction(() => (this._isSaving = true));
      const steps = this.steps.map((s) => s.step);
      await this._workStore.setWorkSteps(this.workId, steps, this.work.syncToken);
      void additionalAction?.(steps);
      // Not awaiting fetch of planner contents
      void this._plannerStore.fetchPlannerContents(this.plannerId);
      runInAction(() => (this._saveError = undefined));
    } catch (e) {
      captureException(e);
      const error = e as Error;
      this._saveError = error.message;
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }

  private async updatePlannedWorkWithStepIds(plannedWork: PlannedWork, stepIds: string[]): Promise<void> {
    await this._workStore.updatePlannedWork(
      plannedWork.id,
      this.workId,
      plannedWork.timeSlot!,
      stepIds,
      this.work.syncToken
    );
  }
}
