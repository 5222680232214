import { Chip, ChipProps, useTheme } from '@mui/material';
import { ChoicePickerOption } from './ChoicePicker';

export interface ChoicePickerOptionChipProps<T> {
  className?: string;

  /**
   * The option to display.
   */
  option: ChoicePickerOption<T>;

  /**
   * Indicates if the option is selected.
   */
  isSelected: boolean;

  /**
   * Indicates to disable the option. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when clicking on an option.
   * @param option The clicked option.
   */
  onOptionClick: (option: ChoicePickerOption<T>) => void;

  /**
   * The props to pass to the option chip. Optional.
   * @param option The option for which to get the props.
   * @return MUI.ChipProps The option's chip props.
   */
  chipProps?: (option: ChoicePickerOption<T>) => ChipProps;
}

export const ChoicePickerOptionChip = <T,>(props: ChoicePickerOptionChipProps<T>) => {
  const { option, onOptionClick, isSelected, disabled } = props;
  const chipProps = props.chipProps?.(option) ?? {};
  const theme = useTheme();
  return (
    <Chip
      label={option.label}
      disabled={disabled}
      clickable
      onClick={() => onOptionClick(option)}
      color={isSelected ? 'primary' : 'default'}
      icon={option.icon?.({})}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: option.isHighlighted ? theme.palette.primary.main : 'transparent'
      }}
      {...chipProps}
    />
  );
};
