import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService } from '@/services';
import { PlannerDataStore, PlannerDetailedCourseSectionsLoadable } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { computed, makeObservable } from 'mobx';
import { AppBaseCourseSectionListViewModel, CourseSectionDetailsViewModel } from './CourseSectionListViewModel';

class AppSchoolCourseSectionDetailsViewModel implements CourseSectionDetailsViewModel {
  constructor(private readonly _courseSection: CourseSectionDetails) {}

  get courseSection(): CourseSection {
    return this._courseSection.courseSection!;
  }

  delete(): Promise<void> {
    throw new Error("Cannot delete a school's course.");
  }
  toggleVisibility(): Promise<void> {
    throw new Error("Cannot change visibility of a school's course.");
  }
}

export class AppSchoolCourseSectionListViewModel extends AppBaseCourseSectionListViewModel<PlannerDetailedCourseSectionsLoadable> {
  constructor(
    schoolId: string,
    plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super(plannerId, schoolId, localization, connectedApps);
    makeObservable(this);
  }

  @computed
  protected get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  protected get courseSectionsInfos(): CourseSectionDetails[] {
    return this.courseSectionsLoadable.values.filter((cs) => cs.schoolsCourseSection?.schoolId === this._schoolId);
  }

  protected createCourseSectionDetailsViewModel(details: CourseSectionDetails): CourseSectionDetailsViewModel {
    return new AppSchoolCourseSectionDetailsViewModel(details);
  }

  readonly canEditCourseSections = false;
}
