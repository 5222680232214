import { Day, TimeOfDay } from '@/models';
import { isAfter } from 'date-fns';
import { ObservableSet, action, computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { dateFromDayAndTimeOfDay } from '../../utils';

export interface PlannedWorkEditInfo {
  readonly shouldBeCreated: boolean;
  readonly id: string;
  readonly day: Day;
  readonly startTime: TimeOfDay;
  readonly endTime: TimeOfDay;
  readonly stepIds: string[];
}

export class EditablePlannedWorkEditInfo {
  readonly original: PlannedWorkEditInfo;
  @observable private _day: Day;
  @observable private _startTime: TimeOfDay;
  @observable private _endTime: TimeOfDay;
  private readonly _stepIds: ObservableSet<string>;

  constructor(
    readonly isNew: boolean,
    info: PlannedWorkEditInfo,
    private readonly _dateService = ServiceContainer.services.dateService
  ) {
    this.original = info;
    this._day = info.day;
    this._startTime = info.startTime;
    this._endTime = info.endTime;
    this._stepIds = observable.set(info.stepIds);
    makeObservable(this);
  }

  @computed
  get id(): string {
    return this.original.id;
  }

  @computed
  get shouldBeCreated(): boolean {
    return this.original.shouldBeCreated;
  }

  @computed
  get day(): Day {
    return this._day;
  }

  set day(day: Day) {
    this._day = day;
  }

  @computed
  get startTime(): TimeOfDay {
    return this._startTime;
  }

  set startTime(startTime: TimeOfDay) {
    this._startTime = startTime;
  }

  @computed
  get startDate(): Date {
    return dateFromDayAndTimeOfDay(this.day, this.endTime);
  }

  @computed
  get endTime(): TimeOfDay {
    return this._endTime;
  }

  set endTime(endTime: TimeOfDay) {
    this._endTime = endTime;
  }

  @computed
  get endDate(): Date {
    return dateFromDayAndTimeOfDay(this.day, this.endTime);
  }

  @computed
  get isInThePast(): boolean {
    return isAfter(this._dateService.now, this.endDate);
  }

  @computed
  get stepIds(): string[] {
    return Array.from(this._stepIds);
  }

  @action
  toggleStepId(stepId: string) {
    if (this._stepIds.has(stepId)) {
      this._stepIds.delete(stepId);
    } else {
      this._stepIds.add(stepId);
    }
  }
}
