import {
  Box,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grow,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  styled
} from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { ColorPalette } from './ColorPalette';

export interface ColorPickerProps {
  className?: string;

  sx?: SxProps;

  /**
   * The selected hex color.
   */
  color: string | undefined;

  /**
   * The field label.
   */
  label: string;

  /**
   * The field helper text. Optional.
   */
  helperText?: ReactNode;

  /**
   * The color palette. Optional.
   */
  palette?: string[];

  /**
   * Callback when changing color.
   * @param color The hex color.
   */
  onChange: (color: string) => void;

  /**
   * Callback for onMouseUp and onTouchEnd
   */
  onMouseUp?: () => void;

  /**
   * If true, the component will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * The size of the component.
   */
  size?: 'small' | 'medium';

  /**
   * Elevation of the popover.
   */
  elevation?: number;
}

export const ColorPicker = ({
  className,
  sx = [],
  color,
  label,
  helperText,
  onChange,
  onMouseUp,
  fullWidth,
  elevation,
  size,
  palette = []
}: ColorPickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <Stack className={className} sx={sx}>
      {/* Field */}
      <FormControl fullWidth={fullWidth} size={size}>
        <InputLabel id="color-picker-input-label" shrink>
          {label}
        </InputLabel>

        {/* Using an empty select to keep the styling of our customized Material-UI field */}
        <StyledSelect
          classes={{ select: classes.select, icon: classes.selectIcon }}
          label={label}
          value={'~'}
          labelId="color-picker-input-label"
          open={false}
          ref={anchorRef}
          renderValue={() => <Box sx={{ backgroundColor: color, flex: 1, height: 24, borderRadius: 0.5 }} />}
          onClick={() => setIsOpen(true)}
        >
          {/* Having a random menu item to remove warning about the value not being in the available options. */}
          {/* As the Select menu is never displayed, it doesn't matter what we use as value. */}
          <MenuItem value={'~'}></MenuItem>
        </StyledSelect>

        {helperText != null && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      {/* Color picker */}
      <Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-start" transition sx={{ zIndex: 10_000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <PopperPaper elevation={elevation} sx={{ border: '1px solid rgba(255, 255, 255, 0.12)' }}>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Box
                  sx={{
                    width: 215
                  }}
                >
                  <HexColorPicker
                    color={color}
                    onChange={onChange}
                    onMouseUp={onMouseUp}
                    onTouchEnd={onMouseUp}
                    style={{ width: 'inherit' }}
                  />

                  {palette.length > 0 && (
                    <ColorPalette
                      palette={palette}
                      onChange={(value) => {
                        onChange(value);
                        onMouseUp?.();
                      }}
                      sx={{ p: 1 }}
                    />
                  )}
                </Box>
              </ClickAwayListener>
            </PopperPaper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

const PREFIX = 'ColorPicker';
const classes = {
  select: `${PREFIX}-select`,
  selectIcon: `${PREFIX}-selectIcon`
};

const PopperPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 8,
  margin: `${theme.spacing(1, 0)}!important`,

  ['& .react-colorful__last-control']: {
    borderRadius: 0
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  [`& .${classes.select}`]: {
    padding: `${theme.spacing(1)} !important`
  },

  [`& .${classes.selectIcon}`]: {
    display: 'none'
  }
}));
