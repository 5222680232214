import { ConnectedAppKind, plannerHasAccessKindsForUser, UserDashboardInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppSyncInfoViewModel, SyncInfoViewModel } from './SyncInfoViewModel';

export interface ConnectedAppUserDashboardViewModel {
  readonly syncInfo: SyncInfoViewModel;

  readonly kind: ConnectedAppKind;
  readonly userDashboardInfo: UserDashboardInfo;
  readonly isReadOnly: boolean;
  readonly canEdit: boolean;
  readonly canSync: boolean;
  readonly isLegacy: boolean;
  readonly isInitialized: boolean;
  readonly isConnected: boolean;
  readonly isEntitled: boolean;
  readonly isApplying: boolean;
  readonly warnings: string[];
  readonly hasSyncError: boolean;
  readonly error?: string;
  readonly isActionEnabled: boolean;

  readonly requiresRefresh: boolean;

  clearError(): void;
  connect(): Promise<void>;
  disconnect(): Promise<void>;
}

export abstract class AppConnectedAppUserDashboardViewModel<T extends string>
  implements ConnectedAppUserDashboardViewModel
{
  @observable protected _isApplying = false;
  @observable protected _error: string | undefined;
  private _requiresRefresh = false;

  protected constructor(
    protected readonly _connectedApp: ConnectedAppService<T>,
    private readonly _plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
    // Making sure to have a sync status when entering dialog.
    void this.syncInfo.refreshSyncStatus();
  }

  abstract get isEntitled(): boolean;

  abstract get warnings(): string[];

  @computed
  get userDashboardInfo(): UserDashboardInfo {
    const { userDashboard } = this._connectedApp;
    return userDashboard.case === 'planner'
      ? { kind: 'planner', id: userDashboard.plannerId }
      : { kind: 'school', id: userDashboard.schoolId };
  }

  @computed
  get kind(): ConnectedAppKind {
    return this._connectedApp.kind;
  }

  @computed
  get isLegacy(): boolean {
    return this._connectedApp.userDashboard.case === 'school';
  }

  @computed
  get isReadOnly(): boolean {
    const planner = this._userStore.getPlannerForId(this._plannerId);

    return planner != null
      ? !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS)
      : true;
  }

  @computed
  get isInitialized(): boolean {
    return this._connectedApp.hasSyncStatus;
  }

  @computed
  get isConnected(): boolean {
    return this._connectedApp.isConnected;
  }

  @computed
  get canEdit(): boolean {
    return this.isEntitled && this.isConnected;
  }

  @computed
  get canSync(): boolean {
    return this.isEntitled && this.isConnected;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get hasSyncError(): boolean {
    return this._connectedApp.hasSyncError;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get isActionEnabled(): boolean {
    return this.canEdit || (!this.syncInfo.isFetching && !this.syncInfo.isSyncing);
  }

  get requiresRefresh(): boolean {
    return this._requiresRefresh;
  }

  @computed
  get syncInfo(): SyncInfoViewModel {
    return new AppSyncInfoViewModel(this._connectedApp);
  }

  @action
  async connect() {
    try {
      this._isApplying = true;
      this._error = undefined;

      await this._connectedApp.connect(true);
      await this._connectedApp.sync();
      await this.ensureCompletedCustomWork();
    } catch (e) {
      if (e !== 'popup_closed') {
        captureException(e);
        runInAction(() => (this._error = (e as Error).message));
      }
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  async disconnect() {
    try {
      this._isApplying = true;
      this._error = undefined;

      await this._connectedApp.disconnect();
    } catch (e) {
      captureException(e);
      runInAction(() => (this._error = (e as Error).message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  clearError() {
    this._error = undefined;
  }

  private async ensureCompletedCustomWork() {
    try {
      // For the moment, the only possible value for kind is 'classroom'. Once we
      // have more, we should have a switch that maps kind to effect, and proceed
      // if effect is not 'unspecified'.
      if (this.kind === 'classroom') {
        await this._plannerStore.ensureCustomWorksCompleted(
          this._plannerId,
          CustomActionEffect.CONNECT_GOOGLE_CLASSROOM
        );

        this._requiresRefresh = true;
      }
    } catch (error) {
      captureException(error);
      console.error('Failed to ensure special work is completed');
      console.error(error);
    }
  }
}
