import {
  DatePicker,
  DatePickerProps,
  DateView,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  usePickerLayout
} from '@mui/x-date-pickers';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarDatePicker } from '../shared';

export type CalendarDatePickerKind = 'user-dashboard-calendar' | 'default';

export interface CalendarDatePickerProps extends DatePickerProps<Date> {
  kind?: CalendarDatePickerKind;
  highlightedDates?: (startDate: Date, endDate: Date) => Date[];
}

export const CalendarDatePicker = observer(
  ({ kind = 'default', highlightedDates, onChange, ...props }: CalendarDatePickerProps) => {
    return (
      <DatePicker
        {...props}
        onChange={
          onChange != null
            ? (date, context) => (context.validationError != null ? onChange(null, context) : onChange(date, context))
            : undefined
        }
        slots={{
          layout:
            kind === 'user-dashboard-calendar'
              ? (p) =>
                  UserDashboardCalendarPickerLayout(p, highlightedDates ?? (() => []), props.minDate, props.maxDate)
              : undefined
        }}
      />
    );
  }
);

function UserDashboardCalendarPickerLayout(
  props: PickersLayoutProps<Date | null, Date, DateView>,
  highlightedDates: (startDate: Date, endDate: Date) => Date[],
  minDate: Date | undefined,
  maxDate: Date | undefined
) {
  const { toolbar, tabs, actionBar } = usePickerLayout(props);

  return (
    <PickersLayoutRoot ownerState={props}>
      {toolbar}
      {actionBar}
      <PickersLayoutContentWrapper>
        {tabs}
        <UserDashboardCalendarDatePicker
          sx={{ width: 350 }}
          initialDate={props.value ?? undefined}
          selectedDates={() => {
            return props.value != null ? [props.value] : [];
          }}
          highlightedDates={highlightedDates}
          onSelectDate={(date) => {
            props.onChange(date);
            props.onClose();
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}
