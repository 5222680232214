import {
  TimeOfDay,
  addMinutesToTimeOfDay,
  compareTimeOfDays,
  dateToPBDate,
  dayToDate,
  differenceInMinutesBetweenTimeOfDays,
  timeOfDayToSeconds
} from '@/models';
import {
  EditablePlannedWorkEditInfo,
  PlannedWorkEditDisplayedInfo,
  PlannedWorkEditInfo,
  PlannedWorkEditViewModel
} from '@/viewmodels';
import { DragEvent } from '@interactjs/actions/drag/plugin';
import { ResizeEvent } from '@interactjs/actions/resize/plugin';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isAfter, isSameDay } from 'date-fns';
import interact from 'interactjs';
import { chain, min, orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { MouseEvent, PointerEvent, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useServices } from '../../../hooks';
import strings from '../../../resources/strings';
import { dateFromDayAndTimeOfDay } from '../../../utils';
import { ConfirmationDialog, updateDocumentElement } from '../../utils';
import { PlannedWorkEditComponentIds } from './PlannedWorkEditComponentIds';
import { PlannedWorkEditInfoDialog } from './PlannedWorkEditInfoDialog';
import {
  PlannedWorkEditInfoView,
  updateStyleOfPlannedWorkEditInfoViewForTemporaryValues
} from './PlannedWorkEditInfoView';
import { usePlannedWorkEditInteractionContext } from './PlannedWorkEditInteractionContext';

interface CreationDragPosition {
  startX: number;
  endX: number;
  startY: number;
  endY: number;
}

interface ResizeValues {
  edge: 'top' | 'bottom';
  startY: number;
  endY: number;
  limitY: number | undefined;
}

interface DragValues {
  id: string;
  x: number;
  y: number;
}

const PreviewInfo: PlannedWorkEditInfo = {
  id: 'planned-work-creation-preview',
  startTime: new TimeOfDay(),
  endTime: new TimeOfDay(),
  stepIds: [],
  day: { day: 1, month: 1, year: 1 },
  shouldBeCreated: false
};

export interface PlannedWorkEditInteractionLayerProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannedWorkEditViewModel;
  dates: Date[];
}

export const PlannedWorkEditInteractionLayer = observer(
  ({ sx = [], className, viewModel, dates }: PlannedWorkEditInteractionLayerProps) => {
    const {
      isCreatingNewInfo,
      isResizing,
      isDragging,
      containerSize,
      setIsCreatingNewInfo,
      setIsResizing,
      setIsDragging
    } = usePlannedWorkEditInteractionContext();
    const { dateService } = useServices();
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const creationDragPosition = useRef<CreationDragPosition | undefined>();
    const resizeValues = useRef<ResizeValues | undefined>(undefined);
    const dragValues = useRef<DragValues | undefined>(undefined);
    const scrollVerticalOffset = useRef(0);
    const [showDragToPastConfirmation, setShowDragToPastConfirmation] = useState(false);

    const color = viewModel.color.length > 0 ? viewModel.color : theme.palette.action.disabled;
    const { pointsPerHour } = viewModel;
    const dayWidth = containerSize.width / dates.length;

    function getHorizontalOffset(index: number): number {
      return index * dayWidth + 2;
    }

    function getPositionStyleForInfo(
      info: PlannedWorkEditInfo,
      dayIndex: number
    ): { top: number; left: number; width: number; height: number } {
      const top = viewModel.verticalOffsetFromTimeOfDay(info.startTime);
      const height = viewModel.getHeightForTimeOfDays(info.startTime, info.endTime);
      const left = getHorizontalOffset(dayIndex);
      const width = dayWidth - 4;
      return { top, left, width, height };
    }

    const resolvedInfos: PlannedWorkEditDisplayedInfo[] = chain(viewModel.infos)
      .map((info) => {
        const dayIndex = viewModel.getDayIndexInDates(info.day, dates);
        return dayIndex >= 0 ? { dayIndex, info } : undefined;
      })
      .compact()
      .value();

    const resolvedEditableInfo: { info: EditablePlannedWorkEditInfo; dayIndex: number } | undefined = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (viewModel.editableInfo == null || !viewModel.editableInfo.isNew) {
        return undefined;
      }

      const dayIndex = viewModel.getDayIndexInDates(viewModel.editableInfo.day, dates);
      if (dayIndex >= 0) {
        return { dayIndex, info: viewModel.editableInfo };
      }

      return undefined;
    }, [viewModel.editableInfo, dates]);

    // ------------------------------
    // Update views once interaction is done

    useEffect(() => {
      if (isResizing || isCreatingNewInfo || isDragging) {
        return;
      }

      resolvedInfos.forEach((info, i) => {
        updateDocumentElement(info.info.id, (element) => {
          const { top, left, width, height } = getPositionStyleForInfo(info.info, info.dayIndex);

          element.style.left = `${left}px`;
          element.style.top = `${top}px`;
          element.style.width = `${width}px`;
          element.style.height = `${height}px`;
          element.style.display = 'flex';
          updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(
            info.info.id,
            info.info.startTime,
            info.info.endTime,
            theme
          );
          // Resetting transform after an interaction ended
          element.style.transform = 'none';
          element.style.zIndex = i.toString();
        });
      });
    }, [resolvedInfos, dayWidth]);

    function onClick(e: MouseEvent) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
      if ((e.nativeEvent as any).pointerType !== 'touch') {
        e.preventDefault();
        return;
      }

      const offsetY = viewModel.roundVerticalOffset(e.nativeEvent.offsetY);
      const offsetX = e.nativeEvent.offsetX;
      createNewInfo(offsetX, offsetY, offsetY);
    }

    function createNewInfo(offsetX: number, startOffsetY: number, endOffsetY: number) {
      const { startTime, endTime } = viewModel.getStartAndEndTimesForVerticalOffsets(startOffsetY, endOffsetY);

      const date = viewModel.getDateForHorizontalOffset(offsetX, containerSize.width, dates);

      if (date != null) {
        const day = dateToPBDate(date);
        let info: PlannedWorkEditInfo = {
          shouldBeCreated: true,
          day,
          startTime,
          endTime,
          id: uuidV4(),
          stepIds: []
        };
        info = viewModel.adjustInfoForConflictIfNeeded(info);
        viewModel.editInfo(true, info);
      }
    }

    // ------------------------------
    // Creation using drag events

    function onPointerDown(e: PointerEvent) {
      // Only supported with mouse
      if (e.pointerType === 'touch') {
        return;
      }

      // Only trigger for mouse left button
      if (e.button !== 0 || isResizing) {
        return;
      }

      if (isCreatingNewInfo) {
        setIsCreatingNewInfo(false);
      } else {
        e.currentTarget.setPointerCapture(e.pointerId);
        const offsetY = viewModel.roundVerticalOffset(e.nativeEvent.offsetY);
        const offsetX = e.nativeEvent.offsetX;
        const columnIndex = Math.floor(offsetX / dayWidth);
        const startX = columnIndex * dayWidth + 2;
        const endX = startX + dayWidth - 5;
        creationDragPosition.current = { startY: offsetY, endY: offsetY, startX, endX };
        setIsCreatingNewInfo(true);
        updateCreationPreview(creationDragPosition.current);
      }
    }

    function onPointerMove(e: PointerEvent) {
      if (!isCreatingNewInfo || creationDragPosition.current == null) {
        return;
      }

      let offsetY = e.nativeEvent.offsetY;
      const maxY = pointsPerHour * 24;
      if (offsetY < 0) {
        offsetY = 0;
      } else if (offsetY > maxY) {
        offsetY = maxY;
      }

      const newPosition: CreationDragPosition = { ...creationDragPosition.current, endY: offsetY };
      creationDragPosition.current = newPosition;
      updateCreationPreview(newPosition);
    }

    function onPointerUp(e: PointerEvent<HTMLDivElement>) {
      e.currentTarget.releasePointerCapture(e.pointerId);

      if (!isCreatingNewInfo) {
        return;
      }

      setIsCreatingNewInfo(false);
      updateCreationPreview(undefined);

      if (creationDragPosition.current != null) {
        const offsetX = creationDragPosition.current.startX;
        createNewInfo(offsetX, creationDragPosition.current.startY, creationDragPosition.current.endY);
        creationDragPosition.current = undefined;
      }
    }

    function onPointerCancel(e: PointerEvent) {
      e.currentTarget.releasePointerCapture(e.pointerId);
      setIsCreatingNewInfo(false);
      updateCreationPreview(undefined);
    }

    // ------------------------------
    // Resize Events

    const onResizeStart = (event: ResizeEvent) => {
      const id = event.target.id;
      const info = resolvedInfos.find((i) => i.info.id === id);
      if (info == null) {
        return;
      }

      const edge = event.edges?.top === true ? 'top' : 'bottom';
      const otherInfosInDay = resolvedInfos.filter((i) => {
        if (i.dayIndex !== info.dayIndex) {
          return false;
        }

        return edge === 'top'
          ? compareTimeOfDays(i.info.startTime, info.info.startTime) < 0
          : compareTimeOfDays(i.info.endTime, info.info.endTime) > 0;
      });

      // We want to prevent resizing past an existing planned work in the same day.
      // Depending on whether which edge we are resizing from, we find the closest time and store it as the limit.
      // Calculating it at the start to prevent having the overhead from re-calculating at on resize.
      let limitTime: TimeOfDay | undefined;
      if (edge === 'top') {
        const sortedInfos = orderBy(otherInfosInDay, (i) => timeOfDayToSeconds(i.info.endTime), 'desc');
        limitTime = sortedInfos.at(-1)?.info.endTime;
      } else {
        const sortedInfos = orderBy(otherInfosInDay, (i) => timeOfDayToSeconds(i.info.startTime), 'asc');
        limitTime = sortedInfos.at(0)?.info.startTime;
      }

      const limitY = limitTime != null ? viewModel.verticalOffsetFromTimeOfDay(limitTime) : undefined;

      // Making sure the element is above all other views.
      event.target.style.zIndex = '9999';
      setIsResizing(true);
      resizeValues.current = {
        edge,
        startY: viewModel.verticalOffsetFromTimeOfDay(info.info.startTime),
        endY: viewModel.verticalOffsetFromTimeOfDay(info.info.endTime),
        limitY
      };
    };

    const onResizeMove = (event: ResizeEvent) => {
      if (resizeValues.current == null) {
        return;
      }

      const { startY, endY, edge, limitY } = resizeValues.current;
      let newStartY = startY;
      let newEndY = endY;

      if (edge === 'top') {
        newStartY += event.deltaRect?.top ?? 0;

        if (newStartY < 0) {
          newStartY = 0;
        } else if (limitY != null && newStartY < limitY) {
          newStartY = limitY;
        }

        // Making sure the height is not below the minimum. Can happen while resizing with a conflict in the day.
        if (newEndY - newStartY < viewModel.gridIncrement) {
          newStartY = newEndY - viewModel.gridIncrement;
        }
      } else {
        newEndY += event.deltaRect?.bottom ?? 0;

        if (newEndY > containerSize.height) {
          newEndY = containerSize.height;
        } else if (limitY != null && newEndY > limitY) {
          newEndY = limitY;
        }

        // Making sure the height is not below the minimum. Can happen while resizing with a conflict in the day.
        if (newEndY - newStartY < viewModel.gridIncrement) {
          newEndY = newStartY + viewModel.gridIncrement;
        }
      }

      const newHeight = newEndY - newStartY;
      resizeValues.current = {
        edge,
        limitY,
        startY: edge === 'top' ? newStartY : startY,
        endY: edge === 'bottom' ? newEndY : endY
      };

      Object.assign(event.target.style, {
        height: `${newHeight}px`,
        top: `${newStartY}px`
      });

      const startTime = viewModel.timeOfDayFromVerticalOffset(newStartY);
      const endTime = viewModel.timeOfDayFromVerticalOffset(newEndY);
      updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(event.target.id, startTime, endTime, theme);
    };

    const onResizeEnd = (event: ResizeEvent) => {
      setIsResizing(false);

      if (resizeValues.current != null) {
        const id = event.target.id;
        const info = resolvedInfos.find((i) => i.info.id === id);
        if (info == null) {
          return;
        }

        const startTime = viewModel.timeOfDayFromVerticalOffset(resizeValues.current.startY);
        const endTime = viewModel.timeOfDayFromVerticalOffset(resizeValues.current.endY);
        viewModel.updateInfo({ ...info.info, startTime, endTime });
        resizeValues.current = undefined;
      }
    };

    const onResizeCancel = () => {
      setIsResizing(false);
      resizeValues.current = undefined;
    };

    // ------------------------------
    // Drag Events

    const onDragStart = (event: DragEvent) => {
      setIsDragging(true);
      dragValues.current = { id: event.target.id, x: 0, y: 0 };
      // Making sure the element is above all other views.
      event.target.style.zIndex = '9999';
    };

    const onDragMove = (event: DragEvent) => {
      if (dragValues.current == null) {
        return;
      }

      dragValues.current.x += event.dx;
      dragValues.current.y += event.dy;
      const id = event.target.id;
      updateDraggedElementPosition(id);
      updateDraggedElementStyle(id);
    };

    const onDragEnd = () => {
      if (dragValues.current == null) {
        onDragCancel();
        return;
      }

      const updatedInfo = getUpdatedInfoForDrop(dragValues.current);

      if (updatedInfo == null) {
        onDragCancel();
        return;
      }

      const newDate = dateFromDayAndTimeOfDay(updatedInfo.day, updatedInfo.endTime);
      if (isAfter(dateService.now, newDate)) {
        setShowDragToPastConfirmation(true);
      } else {
        onDrop();
      }
    };

    const onDragCancel = () => {
      setIsDragging(false);
      dragValues.current = undefined;
    };

    const onDrop = () => {
      if (dragValues.current == null) {
        return;
      }

      const updatedInfo = getUpdatedInfoForDrop(dragValues.current);

      if (updatedInfo == null) {
        onDragCancel();
        return;
      }

      viewModel.updateInfo(updatedInfo);
      onDragCancel();
    };

    const getUpdatedInfoForDrop = (dragValues: DragValues) => {
      const info = getInfoForId(dragValues.id);
      if (info == null) {
        return undefined;
      }

      const newDate = getTargetDateForDrag(info.info, dragValues);
      if (newDate == null) {
        return undefined;
      }

      const duration = Math.abs(differenceInMinutesBetweenTimeOfDays(info.info.startTime, info.info.endTime));

      let verticalOffset = viewModel.verticalOffsetFromTimeOfDay(info.info.startTime);
      verticalOffset += dragValues.y;

      const startTime = viewModel.timeOfDayFromVerticalOffset(verticalOffset);
      const endTime = addMinutesToTimeOfDay(startTime, duration);

      const updatedInfo: PlannedWorkEditInfo = { ...info.info, day: dateToPBDate(newDate), startTime, endTime };
      return viewModel.adjustInfoForConflictIfNeeded(updatedInfo);
    };

    const getInfoForId = (id: string) => resolvedInfos.find((i) => i.info.id === id);

    const getTargetDateForDrag = (info: PlannedWorkEditInfo, dragValues: DragValues): Date | undefined => {
      const date = dayToDate(info.day);
      const initialDateIndex = dates.findIndex((d) => isSameDay(d, date));

      if (initialDateIndex < 0) {
        return undefined;
      }

      let horizontalOffset = getHorizontalOffset(initialDateIndex);
      horizontalOffset += dragValues.x;
      const newDateIndex = Math.floor(horizontalOffset / dayWidth);
      return dates.at(newDateIndex);
    };

    const onContainerScroll = () => {
      const scrollableElement = document.getElementById(PlannedWorkEditComponentIds.weekScrollableView);
      if (scrollableElement == null) {
        return;
      }

      // If a scroll happens, we adjust the dragged view position to still be anchored to the pointers offset.
      const topOffset = viewModel.roundVerticalOffset(scrollableElement.scrollTop);
      const dy = topOffset - scrollVerticalOffset.current;

      if (dragValues.current != null) {
        const id = dragValues.current.id;
        const info = resolvedInfos.find((i) => i.info.id === id);
        if (info == null) {
          return;
        }

        const currentTop = viewModel.verticalOffsetFromTimeOfDay(info.info.startTime);
        dragValues.current.y += dy;

        const estimatedTop = currentTop + dragValues.current.y;
        const height = viewModel.getHeightForTimeOfDays(info.info.startTime, info.info.endTime);
        const estimatedBottom = estimatedTop + height;

        // Making sure that the dragged view is still in bounds
        if (estimatedTop < 0) {
          dragValues.current.y += Math.abs(estimatedTop);
        } else if (estimatedBottom > containerSize.height) {
          dragValues.current.y -= estimatedBottom - containerSize.height;
        }

        updateDraggedElementPosition(id);
        updateDraggedElementStyle(id);
      }

      scrollVerticalOffset.current = topOffset;
    };

    // ------------------------------
    // Style Updates

    const updateDraggedElementPosition = (id: string) => {
      updateDocumentElement(id, (element) => {
        if (dragValues.current != null) {
          element.style.transform = `translate(${dragValues.current.x}px, ${dragValues.current.y}px)`;
        }
      });
    };

    const updateDraggedElementStyle = (id: string) => {
      if (dragValues.current == null) {
        return;
      }

      const info = resolvedInfos.find((i) => i.info.id === id);
      if (info == null) {
        return;
      }

      let verticalOffset = viewModel.verticalOffsetFromTimeOfDay(info.info.startTime);
      verticalOffset += dragValues.current.y;

      const startTime = viewModel.timeOfDayFromVerticalOffset(verticalOffset);
      const duration = Math.abs(differenceInMinutesBetweenTimeOfDays(info.info.startTime, info.info.endTime));
      const endTime = addMinutesToTimeOfDay(startTime, duration);
      updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(id, startTime, endTime, theme);
    };

    function updateCreationPreview(newPosition: CreationDragPosition | undefined) {
      if (newPosition != null) {
        const { startTime, endTime } = viewModel.getStartAndEndTimesForVerticalOffsets(
          newPosition.startY,
          newPosition.endY
        );

        const left = min([newPosition.startX, newPosition.endX]) ?? 0;
        const width = Math.abs(newPosition.startX - newPosition.endX);

        updatePreviewInfoViewStyle(PreviewInfo.id, { startTime, endTime, left, width: width + 1 });
      } else {
        updatePreviewInfoViewStyle(PreviewInfo.id, undefined);
      }
    }

    function updatePreviewInfoViewStyle(
      id: string,
      args: { startTime: TimeOfDay; endTime: TimeOfDay; left: number; width: number } | undefined
    ) {
      updateDocumentElement(id, (view) => {
        if (args != null) {
          const resolvedMinY = viewModel.verticalOffsetFromTimeOfDay(args.startTime);
          const resolvedMaxY = viewModel.verticalOffsetFromTimeOfDay(args.endTime);
          const height = Math.abs(resolvedMaxY - resolvedMinY);

          view.style.display = 'flex';
          view.style.top = `${resolvedMinY}px`;
          view.style.height = `${height}px`;
          view.style.left = `${args.left}px`;
          view.style.width = `${args.width}px`;

          updateStyleOfPlannedWorkEditInfoViewForTemporaryValues(id, args.startTime, args.endTime, theme);
        } else {
          view.style.display = 'none';
        }
      });
    }

    // ------------------------------
    // Setup interactions

    useEffect(() => {
      const interactable = interact(`.${PlannedWorkEditComponentIds.infoView}`);

      interactable.resizable({
        edges: {
          top: true,
          left: false,
          bottom: true,
          right: false
        },
        // No way to prevent this interaction based on pointerType, so disabling it on smaller screens.
        enabled: !isExtraSmallScreen,
        modifiers: [
          // Limits the resizing to the bounds of the grid.
          interact.modifiers.restrictRect({
            elementRect: { ...containerSize, top: 0, left: 0, right: 0, bottom: 0 }
          }),
          // Prevents the element from being resized smaller than the minimum (15 min.)
          interact.modifiers.restrictSize({ min: { width: 0, height: viewModel.gridIncrement } }),
          // Snap the resize to intervals of 15 minutes.
          interact.modifiers.snapSize({
            targets: [interact.snappers.grid({ width: 1, height: viewModel.gridIncrement })]
          })
        ],
        listeners: {
          start: onResizeStart,
          move: onResizeMove,
          end: onResizeEnd,
          cancel: onResizeCancel
        }
      });

      interactable.draggable({
        autoScroll: {
          container: `#${PlannedWorkEditComponentIds.weekScrollableView}`,
          margin: 100,
          distance: viewModel.gridIncrement * 2,
          interval: 10,
          speed: 300
        },
        // No way to prevent this interaction based on pointerType, so disabling it on smaller screens.
        enabled: !isExtraSmallScreen,
        cursorChecker: (_action, _interactable, _element, interacting) => {
          return interacting ? 'move' : 'pointer';
        },
        modifiers: [
          // Limits the dragged view to the bounds of the grid.
          interact.modifiers.restrictRect({
            restriction: `#${PlannedWorkEditComponentIds.interactionLayer}`
          }),
          // Snap the top of the dragged view to intervals of 15 minutes
          // and the left to the day in which the cursor is currently on.
          interact.modifiers.snapSize({
            targets: [interact.snappers.grid({ width: dayWidth, height: viewModel.gridIncrement })]
          })
        ],
        listeners: {
          start: onDragStart,
          move: onDragMove,
          end: onDragEnd,
          cancel: onDragCancel
        }
      });

      // Listening to scroll of grid and adjusting drag position
      const scrollableElement = document.getElementById(PlannedWorkEditComponentIds.weekScrollableView);
      scrollableElement?.addEventListener('scroll', onContainerScroll);

      return () => {
        scrollableElement?.removeEventListener('scroll', onContainerScroll);
      };
    }, [resolvedInfos, isExtraSmallScreen]);

    // ------------------------------
    // Component render

    return (
      <>
        <Box
          sx={{ ...sx, backgroundColor: 'transparent' }}
          className={className}
          id={PlannedWorkEditComponentIds.interactionLayer}
        >
          <Box
            sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            onPointerCancel={onPointerCancel}
            onClick={onClick}
          />

          <PlannedWorkEditInfoView
            id={PreviewInfo.id}
            info={PreviewInfo}
            isDimmed={false}
            color={color}
            icon={viewModel.workIcon}
            canShowCompletedLabel={false}
            sx={{
              zIndex: 9_999,
              position: 'absolute',
              boxShadow: 12,
              display: 'none'
            }}
          />

          {resolvedInfos.map((info, index) => (
            <PlannedWorkEditInfoView
              key={info.info.id}
              id={info.info.id}
              info={info.info}
              className={PlannedWorkEditComponentIds.infoView}
              disabled={isDragging || isResizing}
              onClick={() => viewModel.editInfo(false, info.info)}
              isDimmed={
                (viewModel.editableInfo != null && viewModel.editableInfo.id !== info.info.id) || isCreatingNewInfo
              }
              color={color}
              icon={viewModel.workIcon}
              sx={{
                zIndex: index,
                position: 'absolute',
                boxShadow: viewModel.editableInfo?.isNew === false ? 12 : 0
              }}
            />
          ))}

          {resolvedEditableInfo && (
            <PlannedWorkEditInfoView
              id={resolvedEditableInfo.info.id}
              info={resolvedEditableInfo.info.original}
              isDimmed={false}
              color={color}
              icon={viewModel.workIcon}
              sx={{
                position: 'absolute',
                ...getPositionStyleForInfo(resolvedEditableInfo.info, resolvedEditableInfo.dayIndex),
                backgroundColor: color,
                borderRadius: 0.5,
                boxShadow: 12
              }}
            />
          )}

          {showDragToPastConfirmation && (
            <ConfirmationDialog
              isOpen={true}
              title={strings.plannedWork.edit.moveToPastConfirmationTitle()}
              message={strings.plannedWork.edit.moveToPastConfirmationMessage()}
              confirmButtonLabel={strings.plannedWork.edit.moveToPastConfirmationSubmit()}
              onSubmit={(hasConfirmed) => {
                setShowDragToPastConfirmation(false);
                if (hasConfirmed) {
                  onDrop();
                } else {
                  onDragCancel();
                }
              }}
            />
          )}
        </Box>

        {viewModel.editableInfo != null && (
          <PlannedWorkEditInfoDialog
            isOpen
            editable={viewModel.editableInfo}
            onSave={() => viewModel.saveEditableInfo(viewModel.editableInfo!)}
            onCancel={() => viewModel.cancelEditableInfo()}
            onDelete={() => viewModel.deleteInfo(viewModel.editableInfo!.id)}
            getIsConflicting={(info) => viewModel.getInfoHasConflict(info)}
            steps={viewModel.work.steps}
          />
        )}
      </>
    );
  }
);
