import { usePlannerUserHasAccess } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface PlannerShowForAccessKindsProps {
  readonly accessKinds: AccessKind[];
  readonly children: ReactNode;
}

export const PlannerShowForAccessKinds = observer(({ accessKinds, children }: PlannerShowForAccessKindsProps) => {
  const hasAccess = usePlannerUserHasAccess(accessKinds);
  return hasAccess ? <>{children}</> : null;
});
