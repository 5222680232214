import {
  PlannerItemsPage,
  PlannerPlanning,
  PlannerSettings,
  PublishedWorkDetailsDialog,
  SchoolCourseSectionDetailsPage,
  SubscriptionsDialog,
  UserDashboardCalendarDayView,
  UserDashboardCalendarMonthView,
  UserDashboardCalendarWeekView,
  WorkDetailsDialog
} from '@/components';
import { Navigate } from 'react-router';
import { RouteObject } from 'react-router-dom';
import { PlannerSettingsRoutes } from './PlannerSettingsRoutes';
import { SchoolCourseSectionRoutes } from './SchoolCourseSectionRoutes';

export const PlannerRoutes: RouteObject[] = [
  { path: 'w/:workId', element: <WorkDetailsDialog isOpen /> },
  { path: 'c/:courseSectionId', element: <PlannerItemsPage /> },
  { path: 'subscribe', element: <SubscriptionsDialog isOpen /> },
  { path: 'items', element: <PlannerItemsPage /> },
  { path: 'plan', element: <PlannerPlanning /> },
  { path: 'month', element: <UserDashboardCalendarMonthView /> },
  { path: 'week', element: <UserDashboardCalendarWeekView /> },
  { path: 'home', element: <UserDashboardCalendarDayView /> },
  {
    path: ':schoolId',
    children: [
      {
        path: 'c/:courseId',
        children: [
          {
            path: 'students',
            element: (
              <SchoolCourseSectionDetailsPage
                sx={{ width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden', display: 'flex' }}
              />
            ),
            children: SchoolCourseSectionRoutes
          }
        ]
      },
      {
        path: 'w/:workId',
        element: <PublishedWorkDetailsDialog isOpen={true} />
      }
    ]
  },
  {
    path: 'settings',
    element: <PlannerSettings />,
    children: PlannerSettingsRoutes
  },
  { path: '', element: <Navigate to="home" replace /> }
];
