import { DialogActionButtonConfiguration, DialogActionButtonIconKind } from '@/viewmodels';
import {
  AddRounded,
  ArrowBackRounded,
  ArrowForwardRounded,
  CheckRounded,
  CloseFullscreenRounded,
  CloseRounded,
  EditRounded,
  OpenInFullRounded,
  OpenInNewRounded,
  RestartAltRounded,
  SendRounded
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

export type DialogActionButtonKind = 'icon' | 'text';

export interface DialogActionButtonProps {
  className?: string;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles. Optional.
   */
  sx?: SxProps;
  kind?: DialogActionButtonKind;
  configuration: DialogActionButtonConfiguration;
  defaultColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const DialogActionButton = ({
  className,
  sx = [],
  configuration,
  kind,
  defaultColor = 'primary'
}: DialogActionButtonProps) => {
  const hrefProps =
    configuration.url != null ? { href: configuration.url, target: '_blank', rel: 'noreferrer' } : undefined;
  const location = useLocation();
  const navigate = useNavigate();

  function onClick() {
    if (configuration.action instanceof Function) {
      void configuration.action();
    } else {
      const state: unknown = { ...(location.state ?? {}), ...(configuration.action.state || {}) };
      const newLocation = configuration.action.url ?? location;
      navigate(newLocation, { state, replace: configuration.action.replace });
    }
  }

  const button =
    kind === 'icon' && configuration.icon ? (
      <IconButton
        key={configuration.title()}
        color={configuration.color ?? defaultColor}
        disabled={!configuration.isEnabled}
        onClick={onClick}
        type={configuration.type}
        sx={configuration.tooltip == null ? sx : undefined}
      >
        {iconForKind(configuration.icon)}
      </IconButton>
    ) : (
      <LoadingButton
        loading={configuration.showLoading}
        className={className}
        type={configuration.type}
        variant={configuration.variant}
        color={configuration.color ?? defaultColor}
        disabled={!configuration.isEnabled}
        onClick={onClick}
        startIcon={
          configuration.icon != null && configuration.desktopIconPlacement === 'start'
            ? iconForKind(configuration.icon)
            : undefined
        }
        endIcon={
          configuration.icon != null && configuration.desktopIconPlacement === 'end'
            ? iconForKind(configuration.icon)
            : undefined
        }
        sx={{ ...(configuration.tooltip == null ? sx : {}), minWidth: 100 }}
        {...hrefProps}
      >
        {configuration.title()}
      </LoadingButton>
    );

  if (configuration.tooltip != null) {
    return (
      <Tooltip sx={sx} title={configuration.tooltip()}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

function iconForKind(kind: DialogActionButtonIconKind) {
  switch (kind) {
    case 'check':
      return <CheckRounded />;
    case 'close':
      return <CloseRounded />;
    case 'edit':
      return <EditRounded />;
    case 'fullscreen':
      return <OpenInFullRounded />;
    case 'reduce':
      return <CloseFullscreenRounded />;
    case 'open-in':
      return <OpenInNewRounded />;
    case 'reset':
      return <RestartAltRounded />;
    case 'add':
      return <AddRounded />;
    case 'back':
      return <ArrowBackRounded />;
    case 'forward':
      return <ArrowForwardRounded />;
    case 'send':
      return <SendRounded />;
  }
}
