import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerItemsEmptyIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerItemsEmptyIndicator = observer(({ sx = [], className }: PlannerItemsEmptyIndicatorProps) => {
  return (
    <Typography
      className={className}
      variant="subtitle2"
      sx={{
        color: (theme) => theme.palette.text.secondary,
        ...sx,
        px: 3,
        py: 2
      }}
    >
      {LocalizedStrings.planner.items.emptyLabel()}
    </Typography>
  );
});
