import { Day } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService } from '@/services';
import { PlannerDataStore } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { computed, makeObservable } from 'mobx';
import { AppPlannerCalendarWeekDayViewModel } from './PlannerCalendarWeekDayViewModel';
import { UserDashboardCalendarWeekDayViewModel } from './UserDashboardCalendarWeekDayViewModel';
import { AppUserDashboardCalendarWeekViewModel } from './UserDashboardCalendarWeekViewModel';

export class AppPlannerCalendarWeekViewModel extends AppUserDashboardCalendarWeekViewModel<PlannerDay> {
  constructor(
    plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    settings: ApplicationSettingsService = ServiceContainer.services.settings
  ) {
    const calendarStore = _plannerStore.getCalendarStore(plannerId);
    super(plannerId, calendarStore, settings);
    makeObservable(this);
  }

  @computed
  get courseSections(): CourseSectionDetails[] {
    return this._plannerStore.getCourseSectionsInPlanner(this._dashboardId).visibleCourseSections;
  }

  protected makeViewModelForDay(day: Day, dayString: string): UserDashboardCalendarWeekDayViewModel {
    return new AppPlannerCalendarWeekDayViewModel(
      day,
      this._dashboardId,
      () => this._calendarStore.days.get(dayString),
      () => this.pointsPerHour,
      () => this.courseSectionsFilter
    );
  }
}
