import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { computed, makeObservable, override } from 'mobx';
import {
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration
} from './DialogActionButtonConfiguration';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from './DialogViewModel';

export type ConfirmationDialogViewModel = StaticDialogViewModel;

export class AppConfirmationDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements ConfirmationDialogViewModel
{
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;
  protected readonly _confirmButtonConfig: SaveDialogActionButtonConfiguration;

  constructor(
    protected readonly _confirm: (() => void) | undefined,
    private readonly _cancel: (() => void) | undefined,
    private readonly _showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    isDestructive: boolean | undefined,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(async () => {
      _cancel?.();
      await Promise.resolve();
    });
    makeObservable(this);

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, async () => {
      this._cancel?.();
      await Promise.resolve();
    });

    this._confirmButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, async () => {
      this._confirm?.();
      await Promise.resolve();
    });

    this._confirmButtonConfig.title = () =>
      confirmButtonLabel ?? _localization.localizedStrings.utils.confirmationDialogConfirmButton;
    this._confirmButtonConfig.color = isDestructive ? 'error' : undefined;
    this._cancelButtonConfig.title = () =>
      cancelButtonLabel ?? _localization.localizedStrings.utils.confirmationDialogCancelButton;
    this._cancelButtonConfig.color = 'primary';
  }

  @computed
  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get canConfirm(): boolean {
    return true;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._confirmButtonConfig.isEnabled = this.canConfirm;
    const actions = [this._confirmButtonConfig];

    if (this._showCancel) {
      actions.unshift(this._cancelButtonConfig);
    }
    return actions;
  }
}
