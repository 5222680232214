import { useEffect } from 'react';
import { KeyboardShortcut } from '../utils';

export function useKeyboardShortcuts(shortcuts: KeyboardShortcut[]) {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [shortcuts]);

  const handleKeyPress = (e: KeyboardEvent) => {
    // Finding first shortcut matching current keyboard event.
    const matchingShortcut = shortcuts.find((shortcut) => {
      const config = shortcut.configuration;

      if (!config.enabled) {
        return;
      }

      const ctrlMatches = () => {
        if (!config.ctrl) {
          return !e.ctrlKey;
        }
        return e.ctrlKey || (config.supportMetaAsCtrl && e.metaKey);
      };

      return e.code === config.code && e.altKey === config.alt && e.shiftKey === config.shift && ctrlMatches();
    });

    // If we have a match, we trigger the shortcut action.
    if (matchingShortcut != null) {
      // Prevents triggering default browser action for this shortcut.
      e.preventDefault();
      matchingShortcut.action();
    }
  };
}
