import { StudentBehavior, StudentBehaviorKind, StudentBehaviorLevel } from '@/viewmodels';
import { Circle } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/InboxRounded';
import HighEmotionalStatusIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import MediumEmotionalStatusIcon from '@mui/icons-material/SentimentNeutralRounded';
import LowEmotionalStatusIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import DailyActivityIcon from '@mui/icons-material/VisibilityRounded';
import { Box, Theme, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface StudentBehaviorDotProps {
  sx?: SxProps;
  className?: string;
  isCompact: boolean;
  size: number;
  behavior: StudentBehavior;
}

export function StudentBehaviorDot({ sx = [], className, size, behavior }: StudentBehaviorDotProps) {
  const theme = useTheme();
  const color = getLevelColor(behavior.level, theme);

  return (
    <Tooltip title={behavior.tooltip} disableInteractive>
      <Box sx={{ ...sx, width: size, height: size, position: 'relative' }} className={className}>
        <Circle sx={{ color, position: 'absolute', top: 0, left: 0, width: size, height: size, zIndex: 1 }} />

        <Box
          sx={{
            position: 'absolute',
            top: size * 0.2,
            left: size * 0.2,
            bottom: size * 0.2,
            right: size * 0.2,
            zIndex: 2,
            display: 'flex'
          }}
        >
          {renderIcon(behavior.kind, behavior.level, size * 0.6, theme.palette.getContrastText(color))}
        </Box>
      </Box>
    </Tooltip>
  );
}

function getLevelColor(level: StudentBehaviorLevel, theme: Theme): string {
  switch (level) {
    case 'low':
      return theme.palette.success.main;
    case 'medium':
      return theme.palette.warning.main;
    case 'high':
      return theme.palette.error.main;
    case 'none':
      return theme.palette.text.disabled;
  }
}

function renderIcon(kind: StudentBehaviorKind, level: StudentBehaviorLevel, size: number, color: string) {
  switch (kind) {
    case 'inbox':
      return <InboxIcon sx={{ width: size, height: size, color }} />;
    case 'daily-activity':
      return <DailyActivityIcon sx={{ width: size, height: size, color }} />;
    case 'last-emotional-status':
      return getIconForLastEmotionalStatusLevel(level, size, color);
  }
}

function getIconForLastEmotionalStatusLevel(level: StudentBehaviorLevel, size: number, color: string) {
  switch (level) {
    case 'low':
      return <LowEmotionalStatusIcon sx={{ width: size, height: size, color }} />;
    case 'medium':
    case 'none':
      return <MediumEmotionalStatusIcon sx={{ width: size, height: size, color }} />;
    case 'high':
      return <HighEmotionalStatusIcon sx={{ width: size, height: size, color }} />;
  }
}
