export interface ExSettingsStrings {
  readonly dialogTitle: string;
  readonly backgroundImageSectionTitle: string;
  readonly languagePickerLabel: string;
  readonly reduceTransparency: string;
  readonly selectBackgroundImage: string;
  readonly themePickerDark: string;
  readonly themePickerLabel: string;
  readonly themePickerLight: string;
  readonly themePickerSystem: string;
  readonly customThemeSectionTitle: string;
  readonly useCustomPrimaryColor: string;

  readonly backgroundImageDialogTitle: string;
  readonly backgroundImageDialogCurrentImageAlt: string;
  readonly backgroundImageDialogNoImage: string;
  readonly backgroundImageDialogRemove: string;
  readonly backgroundImageDialogSelectFromUnsplash: string;

  readonly unsplashCancelDialog: string;
  readonly unsplashDialogTitle: string;
  readonly unsplashError: string;
  readonly unsplashNoResultsSubtitle: string;
  readonly unsplashNoResultsTitle: string;
  readonly unsplashOpenUnsplash: string;
  readonly unsplashPicker: string;
  readonly unsplashSaveDialog: string;
  readonly unsplashSearchPlaceholder: string;
}

export const EnglishSettingsStrings: ExSettingsStrings = {
  dialogTitle: 'Settings',
  backgroundImageSectionTitle: 'Background image',
  languagePickerLabel: 'Select language',
  reduceTransparency: 'Reduce transparency',
  selectBackgroundImage: 'Select a background image…',
  themePickerDark: 'Dark',
  themePickerLabel: 'Display mode',
  themePickerLight: 'Light',
  themePickerSystem: 'System',
  customThemeSectionTitle: 'Theme',
  useCustomPrimaryColor: 'Use custom accent color',

  backgroundImageDialogTitle: 'Background image',
  backgroundImageDialogCurrentImageAlt: 'Current background image',
  backgroundImageDialogNoImage: 'No image selected',
  backgroundImageDialogRemove: 'Remove',
  backgroundImageDialogSelectFromUnsplash: 'Select from Unsplash',

  unsplashCancelDialog: 'Close',
  unsplashDialogTitle: 'Select image from Unsplash',
  unsplashError: 'An error occurred while searching for Unsplash images. Please close the dialog and try again.',
  unsplashNoResultsSubtitle: 'Search for your next favorite background!',
  unsplashNoResultsTitle: 'No Results',
  unsplashOpenUnsplash: 'Open Unsplash',
  unsplashPicker: 'Select an image from Unsplash',
  unsplashSaveDialog: 'Ok',
  unsplashSearchPlaceholder: 'Search…'
};

export const FrenchSettingsStrings: ExSettingsStrings = {
  dialogTitle: 'Réglages',
  backgroundImageSectionTitle: 'Image d’arrière-plan',
  languagePickerLabel: 'Sélectionner une langue',
  reduceTransparency: 'Réduire la transparence',
  selectBackgroundImage: 'Choisir une image…',
  themePickerDark: 'Sombre',
  themePickerLabel: 'Mode d’affichage',
  themePickerLight: 'Clair',
  themePickerSystem: 'Système',
  customThemeSectionTitle: 'Thème',
  useCustomPrimaryColor: 'Utiliser une couleur d’accent personnalisée',

  backgroundImageDialogTitle: 'Image d’arrière-plan',
  backgroundImageDialogCurrentImageAlt: 'Image d’arrière-plan courante',
  backgroundImageDialogNoImage: 'Aucune image sélectionnée',
  backgroundImageDialogSelectFromUnsplash: 'Choisir depuis Unsplash',
  backgroundImageDialogRemove: 'Retirer',

  unsplashCancelDialog: 'Fermer',
  unsplashDialogTitle: 'Sélectionner une image Unsplash',
  unsplashError:
    'Une erreur est survenue lors de la recherche d’images Unsplash. Veuillez fermer la boîte de dialogue et réessayez.',
  unsplashNoResultsSubtitle: 'Cherche ton prochain arrière-plan favori!',
  unsplashNoResultsTitle: 'Aucun résultat',
  unsplashOpenUnsplash: 'Ouvrir Unsplash',
  unsplashPicker: 'Sélectionner une image depuis Unsplash',
  unsplashSaveDialog: 'Ok',
  unsplashSearchPlaceholder: 'Chercher…'
};
