import { LocalizationService } from '@/services';
import { StudentBehaviorLevel } from '@/viewmodels';
import { LegendSection } from '../../../utils';
import { StudentBehaviorDot } from '../../student';

export function makeSchoolCourseSectionStudentsLegendSections(localization: LocalizationService): LegendSection[] {
  const strings = localization.localizedStrings.schoolCourseSection;
  const iconSize = 30;

  const inboxDot = (size: number, level: StudentBehaviorLevel) => (
    <StudentBehaviorDot size={size} isCompact={false} behavior={{ kind: 'inbox', level }} />
  );

  const dailyActivityDot = (size: number, level: StudentBehaviorLevel) => (
    <StudentBehaviorDot size={size} isCompact={false} behavior={{ kind: 'daily-activity', level }} />
  );

  return [
    {
      title: strings.detailsStudentsGridLegendInboxBehaviorSectionTitle,
      items: [
        {
          title: strings.detailsStudentsGridLegendInboxBehaviorLow,
          iconSize,
          icon: (size) => inboxDot(size, 'low')
        },
        {
          title: strings.detailsStudentsGridLegendInboxBehaviorMedium,
          iconSize,
          icon: (size) => inboxDot(size, 'medium')
        },
        {
          title: strings.detailsStudentsGridLegendInboxBehaviorHigh,
          iconSize,
          icon: (size) => inboxDot(size, 'high')
        },
        {
          title: strings.detailsStudentsGridLegendInboxBehaviorNone,
          iconSize,
          icon: (size) => inboxDot(size, 'none')
        }
      ]
    },
    {
      title: strings.detailsStudentsGridLegendDailyActivityBehaviorSectionTitle,
      items: [
        {
          title: strings.detailsStudentsGridLegendDailyActivityBehaviorLow,
          iconSize,
          icon: (size) => dailyActivityDot(size, 'low')
        },
        {
          title: strings.detailsStudentsGridLegendDailyActivityBehaviorMedium,
          iconSize,
          icon: (size) => dailyActivityDot(size, 'medium')
        },
        {
          title: strings.detailsStudentsGridLegendDailyActivityBehaviorHigh,
          iconSize,
          icon: (size) => dailyActivityDot(size, 'high')
        },
        {
          title: strings.detailsStudentsGridLegendDailyActivityBehaviorNone,
          iconSize,
          icon: (size) => dailyActivityDot(size, 'none')
        }
      ]
    }
  ];
}
