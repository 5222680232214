import {
  AssignmentRounded,
  AttachFileRounded,
  FolderSharedRounded,
  InsertDriveFileRounded,
  LinkRounded,
  OndemandVideoRounded
} from '@mui/icons-material';
import { IconProps } from '@mui/material';
import { AttachmentInfo } from '../../../models';

export interface AttachmentKindIconProps extends IconProps {
  /**
   * The attachment for which to render the icon.
   */
  attachment: AttachmentInfo;
  className?: string;
}

export const AttachmentKindIcon = ({ attachment, className }: AttachmentKindIconProps) => {
  switch (attachment.kind) {
    case 'file':
      return <InsertDriveFileRounded className={className} />;
    case 'shared_file':
      return <FolderSharedRounded className={className} />;
    case 'video':
      return <OndemandVideoRounded className={className} />;
    case 'unspecified':
      return <AttachFileRounded className={className} />;
    case 'form':
      return <AssignmentRounded className={className} />;
    case 'link':
      return <LinkRounded className={className} />;
  }
};
