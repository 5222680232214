import { useServices } from '@/hooks';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { ArchiveRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ConfirmationDialog } from '../../../utils';
import { SchoolSettingsDangerAction } from './SchoolSettingsDangerAction';

export interface SchoolSettingsArchiveActionProps {
  sx?: SxProps;
  className?: string;
  school: SchoolInformation;
}

export const SchoolSettingsArchiveAction = observer(({ school, className, sx }: SchoolSettingsArchiveActionProps) => {
  const { userStore } = useServices();
  const strings = LocalizedStrings.settings.school.details.dangerActions.archive;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isTogglingState, setIsTogglingState] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function onConfirmation(hasConfirmed: boolean) {
    setShowConfirmation(false);

    if (hasConfirmed) {
      setIsTogglingState(true);
      setError(null);

      try {
        await userStore.archiveSchool(school.school!.id);
      } catch (error) {
        setError(error as Error);
        captureException(error);
        setIsTogglingState(false);
      }
    }
  }

  return (
    <Box sx={sx} className={className}>
      <SchoolSettingsDangerAction
        title={strings.title()}
        subtitle={strings.subtitle()}
        buttonTitle={strings.actionTitle()}
        buttonIcon={<ArchiveRounded />}
        action={() => setShowConfirmation(true)}
        disabled={isTogglingState}
        isLoading={isTogglingState}
        error={error != null ? strings.errorMessage() : undefined}
      />

      {showConfirmation && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.confirmationTitle()}
          message={strings.confirmationMessage(school.school!.name)}
          confirmButtonLabel={strings.confirmationSubmit()}
          isDestructive
          onSubmit={(hasConfirmed) => void onConfirmation(hasConfirmed)}
        />
      )}
    </Box>
  );
});
