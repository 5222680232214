import { useServices } from '@/hooks';
import { getOptionalDashConcatenation } from '@/models';
import { PlannerSideBarCourseSectionItemViewModel } from '@/viewmodels';
import { ListItemButton, ListItemText, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../lists';

export interface MainScreenPlannerSideBarSchoolCourseItemCompactProps {
  sx?: SxProps;
  className?: string;
  course: PlannerSideBarCourseSectionItemViewModel;
  plannerId: string;
  onSelect?: () => void;
}

export const MainScreenPlannerSideBarSchoolCourseItemCompact = observer(
  ({ sx = [], className, course, plannerId, onSelect }: MainScreenPlannerSideBarSchoolCourseItemCompactProps) => {
    const { route } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.sidebar.school;

    const params = useParams();
    const activeCourseSectionId = params.courseSectionId ?? '';
    const activeCourseId = params.courseId ?? '';
    const isActive = course.id === activeCourseSectionId || course.schoolCourseSectionId === activeCourseId;

    const title = getOptionalDashConcatenation(course.courseTitle, course.section);
    const secondaryTooltipText = course.isDemo
      ? strings.demoCourseSubtitle()
      : strings.courseSubtitle(course.studentUsingTodayCount, course.totalStudentCount);

    return (
      <Tooltip
        title={
          <span>
            {title}
            <br />
            {secondaryTooltipText}
          </span>
        }
        placement="right"
        arrow
      >
        <ListItemButton
          sx={{
            ...sx,
            borderRadius: 1,
            px: 1,
            [':hover']: {
              backgroundColor: !isActive ? theme.palette.action.focus : 'transparent'
            }
          }}
          className={className}
          component={Link}
          to={route.resolveSchoolCourseSectionDetailsLocation(plannerId, course.schoolId, course.schoolCourseSectionId)}
          onClick={onSelect}
          dense
        >
          <ColorIndicator sx={{ height: 26, mr: 1 }} color={course.color} />
          <ListItemText
            sx={{ flex: 1 }}
            primary={title}
            primaryTypographyProps={{ color: isActive ? 'primary' : undefined, noWrap: true }}
          />
        </ListItemButton>
      </Tooltip>
    );
  }
);
