import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { useServices } from '../../../../hooks';
import { PlannerListItemInfo, PlannerListViewModel } from '../../../../viewmodels';
import { CreateItemButton, List, ListHeader } from '../../../lists';
import { BackgroundImageScreenPaper, MediaQuery, UpdatablePresenter, getBlurredClassName } from '../../../utils';
import { PlannerShowForAccessKinds } from '../../PlannerShowForAccessKinds';

export interface PlannerListProps {
  sx?: SxProps;
  className?: string;

  viewModel: PlannerListViewModel;

  /**
   * List title.
   */
  title: string;

  /**
   * List subtitle
   */
  subtitle: string;

  /**
   * Render for an empty list.
   */
  renderEmptyIndicator: () => ReactElement;
  renderItem: (item: PlannerListItemInfo) => ReactElement;
  rowHeightForItem?: (item: PlannerListItemInfo) => number;

  /**
   * Whether to show the create work button if the list size is higher than the xs breakpoint.
   */
  showCreateWorkIfDesktop?: boolean;
  bottomPadding?: number;
}

export const PlannerList = observer(
  ({
    sx = [],
    className,
    viewModel,
    title,
    subtitle,
    renderEmptyIndicator,
    showCreateWorkIfDesktop = false,
    renderItem,
    rowHeightForItem,
    bottomPadding = 20
  }: PlannerListProps) => {
    const theme = useTheme();
    const { localization, settings } = useServices();
    const strings = localization.localizedStrings.workList;

    const blurredStyle = getBlurredClassName('paper', theme);
    const isSmall = useMediaQuery(() => theme.breakpoints.down('md'));
    const backgroundShouldBeBlurred = !isSmall && settings.backgroundImage != null && !settings.reduceTransparency;

    return (
      <BackgroundImageScreenPaper
        className={className}
        sx={{
          ...sx,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          [theme.breakpoints.only('xs')]: {
            mt: 0
          },
          [theme.breakpoints.only('sm')]: {
            backgroundColor: 'transparent'
          }
        }}
        classes={{ root: clsx(backgroundShouldBeBlurred && blurredStyle) }}
        elevation={0}
        bordered={!isSmall}
        preventBlur={isSmall}
      >
        <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
          <ListHeader sx={{ mx: 2, my: 1 }} title={title} subtitle={subtitle} />
        </MediaQuery>
        {!isSmall && <Divider variant="middle" />}
        <Box
          sx={{
            flex: 1
          }}
        >
          <UpdatablePresenter
            sx={{ width: '100%', height: '100%' }}
            loadingMessage={strings.loading}
            errorMessageSelector={() => strings.genericErrorMessage}
            viewModel={viewModel}
            renderData={() => (
              <>
                <List
                  sx={{ width: '100%' }}
                  sections={viewModel.groups}
                  renderEmptyIndicator={renderEmptyIndicator}
                  keyForItem={(section, row) => viewModel.groups[section].items[row].uniqueId}
                  renderItem={(section, index) => {
                    const item = viewModel.groups[section].items[index];
                    return renderItem(item);
                  }}
                  rowHeightForItem={(section, index) => {
                    const item = viewModel.groups[section].items[index];
                    if (rowHeightForItem != null) {
                      return rowHeightForItem(item);
                    } else {
                      // Base height
                      let height = 61;

                      if (item.stepsInfo != null && item.stepsInfo.steps.length > 0) {
                        height += 30 * item.stepsInfo.steps.length + 4;
                      }

                      return height;
                    }
                  }}
                  bottomPadding={bottomPadding}
                />

                <PlannerShowForAccessKinds accessKinds={[AccessKind.FULL_ACCESS]}>
                  <>
                    <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
                      <CreateItemButton
                        sx={{ position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) }}
                        canPublishWork={viewModel.canPublishWork}
                      />
                    </MediaQuery>

                    <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
                      {showCreateWorkIfDesktop && (
                        <CreateItemButton
                          sx={{ position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) }}
                          canPublishWork={viewModel.canPublishWork}
                        />
                      )}
                    </MediaQuery>
                  </>
                </PlannerShowForAccessKinds>
              </>
            )}
          />
        </Box>
      </BackgroundImageScreenPaper>
    );
  }
);
