import { SubscriptionInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { AnalyticsService, AuthenticationService, IntercomService, RouteService } from '@/services';
import { UserDataStore } from '@/stores';
import { Browser } from '@capacitor/browser';
import { computed, makeObservable } from 'mobx';

export interface AdminProfileMenuViewModel {
  readonly fullName: string;
  readonly email: string;
  readonly pictureUrl: string;
  readonly plannerLocation: string;
  readonly canManageSubscription: boolean;
  openHelp(): void;
  logout(): Promise<void>;
  manageSubscription(): Promise<void>;
}

export class AppAdminProfileMenuViewModel implements AdminProfileMenuViewModel {
  constructor(
    private readonly _analytics: AnalyticsService = ServiceContainer.services.analytics,
    private readonly _authentication: AuthenticationService = ServiceContainer.services.authentication,
    private readonly _intercom: IntercomService = ServiceContainer.services.intercom,
    private readonly _route: RouteService = ServiceContainer.services.route,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  private get userProfile() {
    return this._userStore.user;
  }

  @computed
  private get subscription(): SubscriptionInfo | undefined {
    return this._userStore.subscription.data;
  }

  @computed
  get canManageSubscription(): boolean {
    return this.subscription != null;
  }

  @computed
  get fullName(): string {
    return this.userProfile.fullName;
  }

  @computed
  get email(): string {
    return this.userProfile.emailAddress;
  }

  @computed
  get pictureUrl(): string {
    return this.userProfile.pictureUrl;
  }

  @computed
  get plannerLocation(): string {
    const plannerId = this._userStore.idOfLastOwnedPlannerShowed;
    return plannerId != null ? this._route.resolvePlannerLocation(plannerId) : '/';
  }

  openHelp() {
    this._analytics.logHelpEvent('menu');
    this._intercom.showChat();
  }

  async logout() {
    await this._authentication.signOut();
  }

  async manageSubscription(): Promise<void> {
    const url = await this._userStore.getManageSubscriptionUrl(window.location.href);
    await Browser.open({ url });
  }
}
