import { ScheduleCyclePeriodSchedulePeriodEditViewModel } from '@/viewmodels';
import { Popover, PopoverOrigin, PopoverPosition } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { AsyncPresenter } from '../../../../utils';
import { ScheduleCyclePeriodEdit } from './ScheduleCyclePeriodEdit';

export interface ScheduleCyclePeriodEditPopoverProps {
  sx?: SxProps;
  getViewModel: () => Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel>;
  supportsActivities: boolean;
  dismiss: () => void;
  isOpen: boolean;
  anchorEl: null | Element | (() => Element) | undefined;
  anchorOrigin?: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  transformOrigin?: PopoverOrigin;
}

export const ScheduleCyclePeriodEditPopover = observer(
  ({
    sx = [],
    getViewModel,
    supportsActivities,
    dismiss,
    isOpen,
    anchorEl,
    anchorOrigin,
    anchorPosition,
    transformOrigin
  }: ScheduleCyclePeriodEditPopoverProps) => {
    return (
      <Popover
        sx={sx}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        anchorPosition={anchorPosition}
        transformOrigin={transformOrigin}
        slotProps={{ paper: { sx: { width: 400, height: supportsActivities ? 700 : undefined, overflow: 'hidden' } } }}
        onClose={() => dismiss()}
      >
        <AsyncPresenter request={getViewModel} sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
          {(viewModel) => <ScheduleCyclePeriodEdit viewModel={viewModel} dismiss={() => dismiss()} />}
        </AsyncPresenter>
      </Popover>
    );
  }
);
