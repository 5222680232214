import { ClassroomCourseViewModel } from '@/viewmodels';
import { CheckRounded } from '@mui/icons-material';
import { ListItemButton, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ColorIndicator } from '../../lists';

export interface ClassroomCourseProps {
  viewModel: ClassroomCourseViewModel;

  /**
   * Indicates to disable the checkbox. Optional. Default is `false`.
   */
  disabled?: boolean;

  className?: string;

  sx?: SxProps;
}

export const ClassroomCourse = observer(({ viewModel, disabled, className, sx }: ClassroomCourseProps) => {
  return (
    <ListItemButton
      className={className}
      sx={sx}
      disabled={disabled}
      onClick={() => (viewModel.isSelected = !viewModel.isSelected)}
    >
      <ColorIndicator color={viewModel.color} sx={{ height: viewModel.description.length > 0 ? 42 : 28, mr: 1 }} />
      <ListItemText primary={viewModel.title} secondary={viewModel.description} sx={{ flex: 1 }} />

      {viewModel.isSelected && <CheckRounded color="primary" />}
    </ListItemButton>
  );
});
