import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface ListHeaderProps {
  className?: string;
  sx?: SxProps;
  /**
   * Header's title.
   */
  title: string;
  /**
   * Header's subtitle.
   */
  subtitle: string;
}

export const ListHeader = observer(({ className, title, subtitle, sx }: ListHeaderProps) => (
  <Stack sx={sx} className={className}>
    <Typography
      variant="h6"
      sx={{
        color: (theme) => theme.palette.text.primary,
        fontWeight: '500'
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="subtitle2"
      sx={{
        color: (theme) => theme.palette.text.secondary
      }}
    >
      {subtitle}
    </Typography>
  </Stack>
));
