import { useKeyboardShortcuts, useServices } from '@/hooks';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { KeyboardShortcut } from '../../utils';

export function useArrowNavigation(onPrevious: () => void, onNext: () => void) {
  const { route } = useServices();
  const location = useLocation();
  const state = location.state as unknown;

  // Disable navigation if one or more dialogs are shown.
  const isEnabled =
    route.dialogPresentedCount === 0 && (state == null || Object.getOwnPropertyNames(state).length === 0);

  const previousShortcut: KeyboardShortcut = useMemo(
    () => ({
      configuration: {
        alt: false,
        code: 'ArrowLeft',
        ctrl: false,
        shift: false,
        enabled: isEnabled,
        supportMetaAsCtrl: false
      },
      action: () => onPrevious()
    }),
    [isEnabled, onPrevious]
  );

  const nextShortcut: KeyboardShortcut = useMemo(
    () => ({
      configuration: {
        alt: false,
        code: 'ArrowRight',
        ctrl: false,
        shift: false,
        enabled: isEnabled,
        supportMetaAsCtrl: false
      },
      action: () => onNext()
    }),
    [isEnabled, onNext]
  );

  useKeyboardShortcuts([previousShortcut, nextShortcut]);
}
