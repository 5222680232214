import { ScheduleSymbolKind } from '@/models';
import { ScheduleSymbol } from './ScheduleSymbol';

export interface InlineScheduleSymbolViewProps {
  symbol: string;
  color: string;
}

export function InlineScheduleSymbolView({ symbol, color }: InlineScheduleSymbolViewProps) {
  if (symbol.startsWith(':')) {
    return <ScheduleSymbol symbolKind={symbol as ScheduleSymbolKind} size={16} color={color} />;
  }

  return <span>{symbol}</span>;
}
