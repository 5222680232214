import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';

export type ScheduleCycleActivitySchedulesCoursesFilter =
  | { case: 'teacher'; value: string }
  | { case: 'course'; value: string };

export function filterPlannerCourseSectionsForActivitySchedules(
  courseSection: CourseSectionDetails,
  filters: ScheduleCycleActivitySchedulesCoursesFilter
): boolean {
  switch (filters.case) {
    case 'teacher': {
      if (courseSection.schoolsCourseSection?.details == null) {
        return false;
      }

      return courseSection.schoolsCourseSection.details.teachers.map((t) => t.accountId).includes(filters.value);
    }

    case 'course': {
      return courseSection.courseSection?.id === filters.value;
    }
  }
}

export function filterSchoolCourseSectionForActivitySchedules(
  courseSection: CourseSection,
  filters: ScheduleCycleActivitySchedulesCoursesFilter
): boolean {
  switch (filters.case) {
    case 'teacher': {
      if (courseSection.details == null) {
        return false;
      }

      return courseSection.details.teachers.map((t) => t.accountId).includes(filters.value);
    }

    case 'course': {
      return courseSection.id === filters.value;
    }
  }
}
