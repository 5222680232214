import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';

export type LoginDialogViewModel = StaticDialogViewModel;

export class AppLoginDialogViewModel extends AppBaseStaticDialogViewModel implements LoginDialogViewModel {
  constructor(onClose: () => void) {
    super(async () => {
      onClose();
      await Promise.resolve();
    });
  }
}
