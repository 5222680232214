import { useServices } from '@/hooks';
import { PlannerListKind } from '@/models';
import { ExLocalizedStrings } from '@/resources/strings/ExLocalizedStrings';
import { PlannerListViewModel, PlannerPlanningViewModel } from '@/viewmodels';
import { css } from '@emotion/css';
import { Grid2 } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { PlannerDoneList, PlannerInboxList, PlannerListSummary, PlannerTodayList, PlannerUpcomingList } from './lists';

export const PlannerListGridItem = observer(
  ({
    kind,
    showsSummaryLists,
    currentListKind,
    viewModel,
    setCurrentListKind
  }: {
    kind: PlannerListKind;
    showsSummaryLists: boolean;
    viewModel: PlannerPlanningViewModel;
    currentListKind: PlannerListKind;
    setCurrentListKind: (kind: PlannerListKind) => void;
  }) => {
    const { localization, settings } = useServices();

    const listClassName = css({
      height: '100%',
      width: '100%'
    });

    function getColumnValue(kind: PlannerListKind): number {
      return currentListKind === kind ? 6 : 2;
    }

    function showFullList(kind: PlannerListKind): boolean {
      return !showsSummaryLists || currentListKind === kind;
    }

    return (
      <Grid2
        size={{ xs: 12, sm: getColumnValue(kind), lg: settings.isSchoolSidebarCollapsed ? 3 : undefined, xl: 3 }}
        sx={{ height: '100%' }}
      >
        {showFullList(kind) ? (
          renderPlannerList(kind, viewModel.listViewModelForKind(kind), listClassName)
        ) : (
          <PlannerListSummary
            title={titleForKind(kind, localization.localizedStrings)}
            itemCount={viewModel.listItemsCountForKind(kind)}
            onSelect={() => setCurrentListKind(kind)}
            className={listClassName}
          />
        )}
      </Grid2>
    );
  }
);

function renderPlannerList(kind: PlannerListKind, viewModel: PlannerListViewModel, className: string) {
  switch (kind) {
    case 'inbox':
      return <PlannerInboxList viewModel={viewModel} className={className} />;
    case 'today':
      return <PlannerTodayList viewModel={viewModel} className={className} />;
    case 'upcoming':
      return <PlannerUpcomingList viewModel={viewModel} className={className} />;
    case 'done':
      return <PlannerDoneList viewModel={viewModel} className={className} />;
    default:
      throw new Error('List kind not supported.');
  }
}

function titleForKind(kind: PlannerListKind, localizedStrings: ExLocalizedStrings) {
  const strings = localizedStrings.workList;

  switch (kind) {
    case 'inbox':
      return strings.inboxTitle;
    case 'today':
      return strings.todayTitle;
    case 'upcoming':
      return strings.upcomingTitle;
    case 'done':
      return strings.doneTitle;
    default:
      throw new Error('List kind not supported.');
  }
}
