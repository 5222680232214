import { useServices } from '@/hooks';
import { PopoverProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { OptionsPopover, OptionsPopoverItem, OptionsPopoverSection } from '../../../utils';

export interface UserDashboardCalendarMonthOptionsPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
}

export const UserDashboardCalendarMonthOptionsPopover = observer(
  (props: UserDashboardCalendarMonthOptionsPopoverProps) => {
    const { settings } = useServices();
    const calendarShowPeriodLabels = settings.calendarShowPeriodLabels;
    const strings = LocalizedStrings.calendar.month.options;

    function resetOptions() {
      settings.calendarShowPeriodLabels = true;
    }

    return (
      <OptionsPopover {...props} title={strings.title()} onResetOptions={resetOptions}>
        <OptionsPopoverSection title={strings.showOptionsSectionTitle()}>
          <OptionsPopoverItem
            title={strings.showPeriodLabels()}
            isSelected={calendarShowPeriodLabels}
            onClick={() => (settings.calendarShowPeriodLabels = !calendarShowPeriodLabels)}
          />

          <OptionsPopoverItem
            title={strings.showFreePeriods()}
            isSelected={settings.calendarShowFreePeriods}
            onClick={() => (settings.calendarShowFreePeriods = !settings.calendarShowFreePeriods)}
          />
        </OptionsPopoverSection>
      </OptionsPopover>
    );
  }
);
