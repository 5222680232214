import { ServiceContainer } from '@/providers';
import { AnalyticsService, LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface RenamePlannerViewModel {
  readonly relationshipKind: PlannerRelationshipKind;
  title: string;

  readonly isApplying: boolean;
  readonly canApply: boolean;
  apply(): Promise<void>;
  resetChanges(): void;
}

export class AppRenamePlannerViewModel implements RenamePlannerViewModel {
  @observable private _originalTitle = '';
  @observable private _title = '';
  @observable private _isApplying = false;

  constructor(
    protected readonly _plannerId: string,
    protected readonly _onClose: (() => void) | undefined,
    private readonly _onSaveError: ((error: Error) => void) | undefined,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization,
    protected readonly _analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    makeObservable(this);
    this._title = this.planner.title;
    this._originalTitle = this._title;
  }

  @computed
  private get planner(): Planner {
    const planner = this._userStore.getPlannerForId(this._plannerId);

    if (planner == null) {
      throw new Error("Can't rename planner not available to user.");
    }

    return planner;
  }

  @computed
  get relationshipKind(): PlannerRelationshipKind {
    return (
      this.planner.relationships.find(
        (r) => r.kind !== PlannerRelationshipKind.CREATOR && r.userId == this._userStore.user.userId
      )?.kind ?? PlannerRelationshipKind.UNSPECIFIED
    );
  }

  @computed
  get canApply(): boolean {
    return this._originalTitle != this._title;
  }

  @computed
  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @action
  async apply() {
    this._isApplying = true;

    try {
      await this._userStore.renamePlanner(this._plannerId, this.title);
      this._onClose?.();
      runInAction(() => (this._originalTitle = this._title));
    } catch (error) {
      captureException(error);
      this._onSaveError?.(error as Error);
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  resetChanges() {
    this._title = this._originalTitle;
  }
}
