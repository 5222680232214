import i18n from 'translate';

export const SideBarPlannerStrings = {
  demoClassesSectionTitle: () => i18n.t('sidebar.planner.demoClassesSectionTitle'),
  emotionalSurveyOptionCompactTitle: () => i18n.t('sidebar.planner.emotionalSurveyOptionCompactTitle'),
  items: () => i18n.t('sidebar.planner.items'),
  noVisibleClasses: () => i18n.t('sidebar.planner.noVisibleClasses'),
  pendingTeacherRequestsTitle: () => i18n.t('sidebar.planner.pendingTeacherRequestsTitle'),
  pendingTeacherRequestsTooltip: (count: number) => i18n.t('sidebar.planner.pendingTeacherRequestsTooltip', { count }),
  personalClassesSectionTitle: () => i18n.t('sidebar.planner.personalClassesSectionTitle'),
  planWork: () => i18n.t('sidebar.planner.planWork')
};

export const SideBarSchoolStrings = {
  courseStudents: () => i18n.t('sidebar.school.courseStudents'),
  courseSubtitle: (studentsUsingToday: number, total: number) =>
    i18n.t('sidebar.school.courseSubtitle', { count: studentsUsingToday, total }),
  courseWorks: () => i18n.t('sidebar.school.courseWorks'),
  demoCourseSubtitle: () => i18n.t('sidebar.school.demoCourseSubtitle'),
  hideDemoCoursesTitle: () => i18n.t('sidebar.school.hideDemoCoursesTitle'),
  hideDemoCoursesTooltip: () => i18n.t('sidebar.school.hideDemoCoursesTooltip'),
  noClasses: () => i18n.t('sidebar.school.noClasses'),
  openStudentPlannerTitle: () => i18n.t('sidebar.school.openStudentPlannerTitle'),
  openStudentPlannerTooltip: () => i18n.t('sidebar.school.openStudentPlannerTooltip'),
  showDemoCoursesTitle: () => i18n.t('sidebar.school.showDemoCoursesTitle'),
  showDemoCoursesTooltip: () => i18n.t('sidebar.school.showDemoCoursesTooltip')
};

export const SideBarSharedStrings = {
  configure: () => i18n.t('sidebar.shared.configure'),
  month: () => i18n.t('sidebar.shared.month'),
  today: () => i18n.t('sidebar.shared.today'),
  week: () => i18n.t('sidebar.shared.week')
};

export const SideBarStrings = {
  planner: SideBarPlannerStrings,
  school: SideBarSchoolStrings,
  shared: SideBarSharedStrings
};
