import { CircularProgress, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, ReactElement } from 'react';

export interface FormPopoverHeaderProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  actions?: { icon: ReactElement; tooltip?: string; onClick: (e: MouseEvent<HTMLButtonElement>) => void }[];
}

export const FormPopoverHeader = observer(
  ({ sx = [], className, title, subtitle, actions, isLoading }: FormPopoverHeaderProps) => {
    return (
      <Stack sx={sx} className={className}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            px: 2,
            py: (subtitle ?? (actions?.length ?? 0) > 0) ? 1 : 1.5
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              flex: 1,
              alignItems: 'center'
            }}
          >
            <Stack>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '600'
                }}
              >
                {title}
              </Typography>

              {subtitle && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>

            {isLoading && <CircularProgress size={12} />}
          </Stack>

          {actions?.map((action, index) => (
            <Tooltip key={`header-action-${index}`} title={action.tooltip} disableInteractive>
              <IconButton onClick={(e) => action.onClick(e)}>{action.icon}</IconButton>
            </Tooltip>
          ))}
        </Stack>
        <Divider />
      </Stack>
    );
  }
);
