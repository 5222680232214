import {
  Day,
  TimeOfDay,
  isItemDueDuringPeriodOccurrence,
  plannerHasAccessKindsForUser,
  timeOfDayIsBetweenOthers,
  timestampToTimeOfDay
} from '@/models';
import { ServiceContainer } from '@/providers';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CalendarEvent } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PlannerItem } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PublishedWorkDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/published_work_details_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AppWorkStepInfo, WorkStepViewModel } from '../../../work';
import {
  UserDashboardCalendarAnnotationInfo,
  plannerCalendarEventToInfo,
  plannerCourseSectionOccurrenceToInfo,
  plannerNoteToInfo,
  plannerPlannedWorkToInfo,
  plannerWorkToInfo,
  publishedWorkToInfo
} from '../shared';
import {
  UserDashboardCalendarDayAllDayItemInfo,
  UserDashboardCalendarDayPeriodInfoItem,
  UserDashboardCalendarDayTimedItemInfo
} from './UserDashboardCalendarDayItems';
import {
  AppUserDashboardCalendarDaySpecificDateViewModel,
  UserDashboardCalendarDaySpecificDateDataState
} from './UserDashboardCalendarDaySpecificDateViewModel';

export class AppPlannerUserDashboardCalendarDaySpecificDateViewModel extends AppUserDashboardCalendarDaySpecificDateViewModel {
  constructor(
    day: Day,
    isReadOnly: boolean,
    private readonly _plannerId: string,
    private readonly _getPlannerDay: () => PlannerDay | 'error' | undefined,
    reloadDayData: () => Promise<void>,
    getScheduleCycleWithDraftId: () => string | undefined,
    workStore = ServiceContainer.services.workStore,
    settings = ServiceContainer.services.settings,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _pasteboard = ServiceContainer.services.pasteboard,
    private readonly _featureFlag = ServiceContainer.services.featureFlag
  ) {
    super(day, isReadOnly, reloadDayData, getScheduleCycleWithDraftId, workStore, settings);
    makeObservable(this);
  }

  @computed
  protected get courseSections(): CourseSectionDetails[] {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId).values;
  }

  @computed
  private get plannerDay(): PlannerDay | undefined {
    const day = this._getPlannerDay();

    if (day != null && day !== 'error') {
      return day;
    }

    return undefined;
  }

  @computed
  private get hasError(): boolean | undefined {
    return this._getPlannerDay() === 'error';
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  get inboxWorkCount(): number {
    const loadable = this._plannerStore.getInboxWorkCountInPlanner(this._plannerId);
    return loadable.hasData ? loadable.data : 0;
  }

  @computed
  get cycleDayName(): string {
    const day = this.plannerDay;

    if (day == null || day.isDayOfWeekCycleDayName) {
      return '';
    }

    return day.longCycleDayName;
  }

  @computed
  get annotations(): UserDashboardCalendarAnnotationInfo[] {
    return (
      this.plannerDay?.annotations.map((annotation) => ({
        id: annotation.title,
        title: annotation.title,
        color: annotation.color,
        symbol: annotation.symbol
      })) ?? []
    );
  }

  @computed
  get state(): UserDashboardCalendarDaySpecificDateDataState {
    if (this.plannerDay != null) {
      return 'fulfilled';
    }

    return this.hasError ? 'error' : 'loading';
  }

  @computed
  get allDayItems(): UserDashboardCalendarDayAllDayItemInfo[] {
    if (this.plannerDay == null) {
      return [];
    }

    return chain(this.plannerDay.items)
      .map<UserDashboardCalendarDayAllDayItemInfo | undefined>((item) => {
        if (!item.isContextualAllDay) {
          return undefined;
        }

        switch (item.item.case) {
          case 'calendarEvent':
            return { case: 'calendarEvent', value: plannerCalendarEventToInfo(item.item.value, this._now) };

          case 'publishedWork':
            return {
              case: 'publishedWork',
              value: publishedWorkToInfo(
                item.item.value,
                this.courseSectionsById,
                this.workIcons,
                this._now,
                this.isReadOnly,
                this._pasteboard,
                this._featureFlag
              )
            };

          case 'note':
            return {
              case: 'note',
              value: plannerNoteToInfo(
                item.item.value,
                this.courseSectionsById,
                this._now,
                this._pasteboard,
                this._featureFlag
              )
            };

          case 'work':
            return {
              case: 'work',
              value: plannerWorkToInfo(
                item.item.value,
                this.courseSectionsById,
                this.workIcons,
                this._now,
                this.isReadOnly,
                this._pasteboard,
                this._workStore,
                this._plannerStore,
                this._featureFlag
              )
            };

          default:
            return undefined;
        }
      })
      .compact()
      .value();
  }

  @computed
  get timedItems(): UserDashboardCalendarDayTimedItemInfo[] {
    if (this.plannerDay == null) {
      return [];
    }

    const items = this.getTimedItems(this.plannerDay.items);
    const periodTimes = chain(items)
      .map((item) => {
        if (item.item.case === 'courseSectionOccurrence') {
          const period = item.item.value;
          return [period.startTime!, period.endTime!];
        }

        return undefined;
      })
      .compact()
      .value();

    return chain(items)
      .map<UserDashboardCalendarDayTimedItemInfo | undefined>((item, _, allItems) => {
        switch (item.item.case) {
          case 'calendarEvent':
            return this.mapTimedCalendarEventToInfo(item.item.value, item);

          case 'publishedWork': {
            const publishedWorkDetails = item.item.value;
            return !this.isTimedItemInAPeriod(publishedWorkDetails.publishedWork!.dueTime!, periodTimes)
              ? this.mapTimedPublishedWorkToInfo(publishedWorkDetails, item)
              : undefined;
          }

          case 'work': {
            const work = item.item.value;
            return item.plannedWork != null
              ? this.mapTimedPlannedWorkToInfo(item.plannedWork, work)
              : !this.isTimedItemInAPeriod(work.dueTime!, periodTimes)
                ? this.mapTimedWorkToInfo(work, item)
                : undefined;
          }

          case 'courseSectionOccurrence':
            return this.mapCourseOccurrenceToInfo(item.item.value, item, Array.from(allItems));

          case 'note':
            return !this.isTimedItemInAPeriod(item.item.value.time!, periodTimes)
              ? this.mapTimedNoteToInfo(item.item.value, item)
              : undefined;

          default:
            return undefined;
        }
      })
      .compact()
      .value();
  }

  @computed
  get canCreateWork(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get canPublishWork(): boolean {
    return this._plannerStore.getCanPublishWorkInPlanner(this._plannerId);
  }

  private getTimedItems(items: PlannerItem[]): PlannerItem[] {
    return items.filter((item) => !item.isContextualAllDay);
  }

  private getItemsForCourseSectionOccurrence(
    occurrence: CourseSectionOccurrence,
    items: PlannerItem[]
  ): UserDashboardCalendarDayPeriodInfoItem[] {
    return chain(items)
      .map<UserDashboardCalendarDayPeriodInfoItem | undefined>((item) => {
        switch (item.item.case) {
          case 'work': {
            const work = item.item.value;
            return item.plannedWork == null &&
              isItemDueDuringPeriodOccurrence(work.dueTime!, work.courseSectionId, occurrence, items)
              ? this.mapWorkToPeriodTimedInfo(work, item)
              : undefined;
          }

          case 'publishedWork': {
            const publishedWorkDetails = item.item.value;
            return isItemDueDuringPeriodOccurrence(
              publishedWorkDetails.publishedWork!.dueTime!,
              publishedWorkDetails.courseSectionId,
              occurrence,
              items
            )
              ? this.mapPublishedWorkToPeriodTimedInfo(publishedWorkDetails, item)
              : undefined;
          }

          case 'note': {
            const note = item.item.value;
            return isItemDueDuringPeriodOccurrence(note.time!, note.courseSectionId, occurrence, items)
              ? this.mapNoteToPeriodTimedInfo(note, item)
              : undefined;
          }

          default: {
            return undefined;
          }
        }
      })
      .compact()
      .value();
  }

  private isTimedItemInAPeriod(time: Timestamp, periodTimes: TimeOfDay[][]): boolean {
    return periodTimes.some((times) =>
      timeOfDayIsBetweenOthers(timestampToTimeOfDay(time), times[0], times[1], {
        endInclusive: false
      })
    );
  }

  private mapTimedCalendarEventToInfo(
    calendarEvent: CalendarEvent,
    item: PlannerItem
  ): UserDashboardCalendarDayTimedItemInfo {
    return {
      case: 'calendarEvent',
      value: {
        ...plannerCalendarEventToInfo(calendarEvent, this._now),
        startTime: timestampToTimeOfDay(item.contextualStartTime!),
        endTime: timestampToTimeOfDay(item.contextualEndTime!)
      }
    };
  }

  private mapCourseOccurrenceToInfo(
    occurrence: CourseSectionOccurrence,
    item: PlannerItem,
    allItems: PlannerItem[]
  ): UserDashboardCalendarDayTimedItemInfo {
    return {
      case: 'period',
      value: {
        ...plannerCourseSectionOccurrenceToInfo(
          occurrence,
          this.courseSectionsById,
          this.date,
          this._now,
          this.isReadOnly,
          this.canPublishWork,
          this._pasteboard,
          this._featureFlag
        ),
        startTime: timestampToTimeOfDay(item.contextualStartTime!),
        endTime: timestampToTimeOfDay(item.contextualEndTime!),
        items: this.getItemsForCourseSectionOccurrence(occurrence, allItems)
      }
    };
  }

  private mapTimedPlannedWorkToInfo(plannedWork: PlannedWork, work: Work): UserDashboardCalendarDayTimedItemInfo {
    return {
      case: 'plannedWork',
      value: {
        ...plannerPlannedWorkToInfo(
          plannedWork,
          work,
          this.courseSectionsById,
          this.workIcons,
          this._now,
          this.isReadOnly,
          this._pasteboard,
          this._workStore,
          this._featureFlag
        ),
        description: work.description?.text ?? '',
        steps: this.getStepsForPlannedWork(plannedWork, work)
      }
    };
  }

  private mapWorkToPeriodTimedInfo(work: Work, item: PlannerItem): UserDashboardCalendarDayPeriodInfoItem {
    return {
      case: 'work',
      value: {
        ...plannerWorkToInfo(
          work,
          this.courseSectionsById,
          this.workIcons,
          this._now,
          this.isReadOnly,
          this._pasteboard,
          this._workStore,
          this._plannerStore,
          this._featureFlag
        ),
        startTime: timestampToTimeOfDay(item.contextualStartTime!),
        endTime: timestampToTimeOfDay(item.contextualEndTime!)
      }
    };
  }

  private mapTimedWorkToInfo(work: Work, item: PlannerItem): UserDashboardCalendarDayTimedItemInfo {
    return this.mapWorkToPeriodTimedInfo(work, item);
  }

  private mapPublishedWorkToPeriodTimedInfo(
    publishedWorkDetails: PublishedWorkDetails,
    item: PlannerItem
  ): UserDashboardCalendarDayPeriodInfoItem {
    return {
      case: 'publishedWork',
      value: {
        ...publishedWorkToInfo(
          publishedWorkDetails,
          this.courseSectionsById,
          this.workIcons,
          this._now,
          this.isReadOnly,
          this._pasteboard,
          this._featureFlag
        ),
        startTime: timestampToTimeOfDay(item.contextualStartTime!),
        endTime: timestampToTimeOfDay(item.contextualEndTime!)
      }
    };
  }

  private mapTimedPublishedWorkToInfo(
    publishedWorkDetails: PublishedWorkDetails,
    item: PlannerItem
  ): UserDashboardCalendarDayTimedItemInfo {
    return this.mapPublishedWorkToPeriodTimedInfo(publishedWorkDetails, item);
  }

  private mapNoteToPeriodTimedInfo(note: Note, item: PlannerItem): UserDashboardCalendarDayPeriodInfoItem {
    return {
      case: 'note',
      value: {
        ...plannerNoteToInfo(note, this.courseSectionsById, this._now, this._pasteboard, this._featureFlag),
        startTime: timestampToTimeOfDay(item.contextualStartTime!),
        endTime: timestampToTimeOfDay(item.contextualEndTime!)
      }
    };
  }

  private mapTimedNoteToInfo(note: Note, item: PlannerItem): UserDashboardCalendarDayTimedItemInfo {
    return this.mapNoteToPeriodTimedInfo(note, item);
  }

  private getStepsForPlannedWork(plannedWork: PlannedWork, work: Work): WorkStepViewModel[] {
    return work.steps
      .filter((s) => plannedWork.workStepIds.includes(s.id))
      .map<WorkStepViewModel>((step) => {
        const info = new AppWorkStepInfo(step, (isCompleted) => this.onStepStateChange(step, isCompleted, work));

        return {
          id: step.id,
          info,
          isOverPlannedWorkDuration: () => false,
          isLate: false,
          isReadOnly: false,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onEdit: () => {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          delete: () => {}
        };
      });
  }

  private onStepStateChange(editedStep: WorkStep, isCompleted: boolean, work: Work) {
    const steps = work.steps.map((step) =>
      step.id === editedStep.id ? new WorkStep({ ...editedStep, isCompleted }) : step
    );

    void this._workStore.setWorkSteps(work.id, steps, work.syncToken);
  }
}
