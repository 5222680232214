import { addDays, differenceInCalendarDays, endOfMonth, endOfWeek, set, startOfMonth, startOfWeek } from 'date-fns';
import { times } from 'lodash';
import { Day, TimeOfDay, dayToDate } from '../models';

export function computeDatesForMonthByWeek(date: Date) {
  const start = startOfWeek(startOfMonth(date));
  const end = endOfWeek(endOfMonth(date));
  const numberOfDays = differenceInCalendarDays(end, start) + 1;

  const currentDayIndex = differenceInCalendarDays(date, start);

  return times(numberOfDays / 7).map((weekIndex) => {
    return times(7).map((dayIndex) => addDays(date, weekIndex * 7 + dayIndex - currentDayIndex));
  });
}

export function dateFromDayAndTimeOfDay(day: Day, timeOfDay: TimeOfDay): Date {
  return set(dayToDate(day), { hours: timeOfDay.hours, minutes: timeOfDay.minutes, seconds: timeOfDay.seconds });
}
