import { LocalizationService } from '@/services';
import { SchoolTransportService } from '@/transports';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolCourseSectionLoadable = LoadableValue<CourseSection>;

export class AppSchoolCourseSectionLoadable
  extends BaseLoadableValue<CourseSection>
  implements SchoolCourseSectionLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _courseSectionId: string,
    private readonly _schoolTransport: SchoolTransportService,
    private readonly _localization: LocalizationService
  ) {
    super();
  }

  protected async loadData(): Promise<CourseSection> {
    return this._schoolTransport.getCourseSectionInSchool(
      this._schoolId,
      this._courseSectionId,
      this._localization.currentTimezone
    );
  }
}
