import { useServices } from '@/hooks';
import { Card, Grid2, ListItem, ListItemText, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface WorkAttachmentEmptyListProps {
  className?: string;
  sx?: SxProps;
}

export const WorkAttachmentEmptyList = ({ className, sx }: WorkAttachmentEmptyListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.work.details;
  const theme = useTheme();

  return (
    <Grid2 className={className} sx={sx} size={{ xs: 12, sm: 6 }}>
      <Card elevation={theme.palette.mode === 'light' ? 1 : 8}>
        {/* ContainerComponent prevent having the black dot next to the item */}
        <ListItem sx={{ height: 72 }}>
          <ListItemText primary={strings.noAttachmentsMessage} primaryTypographyProps={{ noWrap: true }} />
        </ListItem>
      </Card>
    </Grid2>
  );
};
