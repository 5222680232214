import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface SpecialDayEditBellTimesPickerProps {
  sx?: SxProps;
  className?: string;
  selectedSchedules: PeriodSchedule[];
  possibleSchedules: PeriodSchedule[];
  updateSelection: (ids: string[]) => void;
}

export const SpecialDayEditBellTimesPicker = observer(
  ({
    sx = [],
    className,
    selectedSchedules,
    possibleSchedules,
    updateSelection
  }: SpecialDayEditBellTimesPickerProps) => {
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

    return (
      <FormControl sx={sx} className={className} size="small">
        <InputLabel id="special-day-custom-schedule-label-id">{strings.bellTimesPickerLabel()}</InputLabel>

        <Select
          multiple
          value={selectedSchedules.map((s) => s.id)}
          label={strings.bellTimesPickerLabel()}
          labelId="special-day-custom-schedule-label-id"
          MenuProps={{ elevation: 2 }}
          onChange={(e) => {
            const value = e.target.value;
            const ids = typeof value === 'string' ? value.split(',') : value;
            updateSelection(ids);
          }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={possibleSchedules.find((s) => s.id === value)!.name} />
              ))}
            </Box>
          )}
        >
          <MenuItem disabled>{strings.bellTimesPickerTitle()}</MenuItem>

          {possibleSchedules.map((s) => (
            <MenuItem key={`special-day-edit-schedule-${s.id}`} value={s.id}>
              <Checkbox
                sx={{ p: 0, pr: 1 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                checked={selectedSchedules.findIndex((selected) => selected.id === s.id) >= 0}
              />
              <ListItemText primary={s.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
