import { CurriculumDataStore, PlannerDataStore, UserDataStore } from '@/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AutoSyncService, ConnectedAppsService, SyncEventListener, SyncService, UserService } from '../contracts';

export class AppSyncService implements SyncService {
  private readonly _listeners = new Map<string, SyncEventListener>();
  @observable private _isSyncing = false;

  constructor(
    private readonly _autoSync: AutoSyncService,
    private readonly _curriculumStore: CurriculumDataStore,
    private readonly _userStore: UserDataStore,
    private readonly _user: UserService,
    private readonly _plannerStore: PlannerDataStore,
    private readonly _connectedApps: ConnectedAppsService
  ) {
    makeObservable(this);
  }

  @computed
  get isSyncing(): boolean {
    return this._isSyncing;
  }

  startAutoSyncForPlanner(plannerId: string) {
    this.startAutoSync(this.getAutoSyncKeyForDashboard(plannerId), async () => await this.syncPlanner(plannerId, true));
  }

  startAutoSync(key: string, sync: () => Promise<void>) {
    this._autoSync.addHandler({
      key,
      threshold: 60000,
      checkInterval: 5000,
      sync: () => void sync()
    });
  }

  stopAutoSyncForPlanner(plannerId: string) {
    const syncKey = this.getAutoSyncKeyForDashboard(plannerId);
    this.stopAutoSync(syncKey);
  }

  stopAutoSync(key: string) {
    this._autoSync.removeHandler(key);
  }

  addListener(key: string, listener: SyncEventListener) {
    this._listeners.set(key, listener);
  }

  removeListener(key: string) {
    this._listeners.delete(key);
  }

  async syncPlanner(plannerId: string, syncConnectedApps: boolean) {
    await this.syncData(plannerId, syncConnectedApps);
  }

  async sync() {
    await this.syncData(undefined, false);
  }

  private getAutoSyncKeyForDashboard(plannerId: string) {
    return `sync-${plannerId}`;
  }

  private async syncDashboard(plannerId: string, syncConnectedApps: boolean) {
    await this._connectedApps.refreshSyncStatus(plannerId);

    if (syncConnectedApps) {
      await this._connectedApps.sync(plannerId);
    }

    await this._plannerStore.getCourseSectionsInPlanner(plannerId).fetch(true);

    await Promise.all([
      this._plannerStore.getInboxWorkCountInPlanner(plannerId).fetch(true),
      this._curriculumStore.getCourses().fetch(true)
    ]);
  }

  private async syncData(plannerId: string | undefined, syncConnectedApps: boolean) {
    if (this._isSyncing || this._user.currentUser == null) {
      return;
    }

    runInAction(() => (this._isSyncing = true));

    await Promise.all([
      this._userStore.plannersLoadable.fetch(true),
      this._userStore.subscription.fetch(true),
      this._userStore.emotionalPulse.fetch(true),
      this._userStore.getPreference('seen-curriculum-course-ids').fetch(true)
    ]);

    if (plannerId != null) {
      await this.syncDashboard(plannerId, syncConnectedApps);
    }

    await Promise.all(Array.from(this._listeners.values()).map((l) => l()));
    runInAction(() => (this._isSyncing = false));
  }
}
