import { PublishScheduleCycleChangesResult } from '@/stores';
import { CheckRounded, ClearRounded, PublishRounded } from '@mui/icons-material';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, CircularProgress, IconButton, Snackbar, Stack, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ConfirmationDialog, MediaQuery } from '../../../utils';

export interface ScheduleCycleBottomActionsProps {
  sx?: SxProps;
  className?: string;
  onUndo: () => Promise<void>;
  onCancel: () => Promise<void>;
  onSave: () => Promise<PublishScheduleCycleChangesResult>;
  showHasDroppedChangesMessage: () => void;
}

export const ScheduleCycleBottomActions = observer(
  ({ sx = [], className, onUndo, onSave, onCancel, showHasDroppedChangesMessage }: ScheduleCycleBottomActionsProps) => {
    const [isUndoing, setIsUndoing] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    async function undo() {
      setIsUndoing(true);
      try {
        await onUndo();
      } catch (e) {
        setError(strings.undoErrorMessage());
        captureException(e);
      } finally {
        setIsUndoing(false);
      }
    }

    async function cancel(hasConfirmed: boolean) {
      setShowCancelConfirmation(false);

      if (!hasConfirmed) {
        return;
      }

      setIsCancelling(true);

      try {
        await onCancel();
      } catch (e) {
        setError(strings.discardErrorMessage());
        captureException(e);
      } finally {
        setIsCancelling(false);
      }
    }

    async function save() {
      setIsSaving(true);
      try {
        const { hasDroppedChanges } = await onSave();
        if (hasDroppedChanges) {
          showHasDroppedChangesMessage();
        }
      } catch (e) {
        setError(strings.publishErrorMessage());
        captureException(e);
      } finally {
        setIsSaving(false);
      }
    }

    const disableActions = isUndoing || isSaving || isCancelling;
    const strings = LocalizedStrings.scheduleCycle.edit.actions;

    return (
      <Box sx={sx} className={className}>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            alignItems: 'center'
          }}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
            <LoadingButton loading={isUndoing} onClick={() => void undo()} disabled={disableActions}>
              {strings.undo()}
            </LoadingButton>

            <Box
              sx={{
                flex: 1
              }}
            />

            <LoadingButton
              loading={isCancelling}
              onClick={() => setShowCancelConfirmation(true)}
              color="error"
              variant="contained-grey"
              sx={{ minWidth: 150 }}
              disabled={disableActions}
            >
              {strings.discardChanges()}
            </LoadingButton>

            <LoadingButton
              loading={isSaving}
              color="primary"
              variant="contained"
              sx={{ minWidth: 150 }}
              onClick={() => void save()}
              disabled={disableActions}
              startIcon={<PublishRounded />}
            >
              {strings.publishChanges()}
            </LoadingButton>
          </MediaQuery>

          <MediaQuery mediaQuery={(th) => th.breakpoints.down('sm')}>
            <Tooltip title={strings.undo()} disableInteractive>
              <IconButton onClick={() => void undo()} disabled={disableActions}>
                {isUndoing ? <CircularProgress size="1em" color="inherit" /> : <ReplayRoundedIcon color="inherit" />}
              </IconButton>
            </Tooltip>

            <Box
              sx={{
                flex: 1
              }}
            />

            <Tooltip title={strings.discardChanges()} disableInteractive>
              <IconButton color="error" onClick={() => setShowCancelConfirmation(true)} disabled={disableActions}>
                <ClearRounded />
              </IconButton>
            </Tooltip>

            <Tooltip title={strings.publishChanges()} disableInteractive>
              <IconButton color="primary" onClick={() => void save()} disabled={disableActions}>
                {isSaving ? <CircularProgress size="1em" color="inherit" /> : <CheckRounded color="inherit" />}
              </IconButton>
            </Tooltip>
          </MediaQuery>
        </Stack>
        {showCancelConfirmation && (
          <ConfirmationDialog
            isOpen
            message={strings.discardChangesConfirmation.message()}
            title={strings.discardChangesConfirmation.title()}
            onSubmit={(hasConfirmed) => void cancel(hasConfirmed)}
            isDestructive
            cancelButtonLabel={strings.discardChangesConfirmation.cancelButtonLabel()}
            confirmButtonLabel={strings.discardChangesConfirmation.submitButtonLabel()}
          />
        )}
        <Snackbar
          open={error != null}
          autoHideDuration={6000}
          onClose={() => setError(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setError(undefined)} severity="error" variant="filled" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
);
