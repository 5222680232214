import { ProductsListViewModel } from '@/viewmodels';
import { Button, Divider, Grid2, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from '../../resources/strings';
import { UpdatablePresenter } from '../utils';
import { Product } from './Product';

export interface ProductsListProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProductsListViewModel;
}

export const ProductsList = observer(({ sx = [], className, viewModel }: ProductsListProps) => {
  const theme = useTheme();
  const strings = LocalizedStrings.subscriptions.manage;

  return (
    <UpdatablePresenter
      sx={sx}
      className={className}
      viewModel={viewModel}
      renderData={() => (
        <Grid2
          container
          spacing={1}
          sx={{
            px: 2
          }}
        >
          {viewModel.products.map((product) => (
            <Grid2 key={`subscription-product-${product.id}`} size={{ xs: 12, sm: 10 }} offset={{ sm: 1 }}>
              <Product sx={{ height: '100%' }} viewModel={product} />
            </Grid2>
          ))}

          <Grid2 size={{ xs: 12, sm: 10 }} offset={{ sm: 1 }}>
            <Stack
              spacing={1}
              sx={{
                px: 2
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  textAlign: 'center',
                  color: theme.palette.text.secondary
                }}
              >
                {strings.legalText()}
              </Typography>

              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button
                  size="small"
                  onClick={() => (viewModel.currency = 'usd')}
                  color="inherit"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {`🇺🇸 ${strings.usdPriceOptionTitle()}`}
                </Button>

                <Divider orientation="vertical" sx={{ height: 22 }} />

                <Button
                  size="small"
                  onClick={() => (viewModel.currency = 'cad')}
                  color="inherit"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {`🇨🇦 ${strings.cadPriceOptionTitle()}`}
                </Button>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      )}
    />
  );
});
