import { BaseLoadableValue, LoadableValue } from '@/stores/loadables/Loadable';
import { DemoTransportService, SchoolTransportService } from '@/transports';
import { Snapshot } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/resources/snapshot_pb';

export type DemoSchoolSnapshotsLoadable = LoadableValue<Snapshot[] | false>;

export class AppDemoSchoolSnapshotsLoadable
  extends BaseLoadableValue<Snapshot[] | false>
  implements DemoSchoolSnapshotsLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _demoTransport: DemoTransportService,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<Snapshot[] | false> {
    const school = await this._schoolTransport.getSchool(this._schoolId);

    if (!school.isDemo) {
      return false;
    }

    return await this._demoTransport.listDemoSchoolSnapshots(this._schoolId);
  }
}
