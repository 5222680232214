import { useServices } from '@/hooks';
import { SchoolEditViewModel } from '@/viewmodels';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface SchoolDetailsEditProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolEditViewModel;
}

export const SchoolDetailsEdit = observer(({ sx = [], className, viewModel }: SchoolDetailsEditProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.school;

  return (
    <Stack spacing={3} sx={sx} className={className}>
      <TextField
        label={strings.editSchoolNameInputLabel}
        value={viewModel.name}
        placeholder={strings.editSchoolNameInputPlaceholder}
        disabled={viewModel.isApplying}
        onChange={(e) => (viewModel.name = e.target.value)}
      />

      <TextField
        label={strings.editSchoolSubtitleInputLabel}
        value={viewModel.subtitle}
        placeholder={strings.editSchoolSubtitleInputPlaceholder}
        disabled={viewModel.isApplying}
        onChange={(e) => (viewModel.subtitle = e.target.value)}
      />
    </Stack>
  );
});
