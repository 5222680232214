import { FirebaseContract } from '../contracts';
import { BaseFirebaseService } from '../implementations';

export class DevFirebaseService extends BaseFirebaseService implements FirebaseContract {
  setUser(id: string, properties?: Record<string, string>) {
    console.log(`Firebase: Would set user id to ${id}, ${properties != null ? 'with' : 'without'} properties`);
  }

  resetUser() {
    console.log('Firebase: Would reset user');
  }

  logError(message: string, isFatal?: boolean) {
    console.log(`Firebase: Would fire error ${message}. Is fatal: ${isFatal}`);
  }
}
