import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { MailRounded } from '@mui/icons-material';
import { Card, Chip, Link, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface AdminAccountDetailsHeaderProps {
  sx?: SxProps;
  className?: string;
  account: Account;
}

export const AdminAccountDetailsHeader = observer(({ sx = [], className, account }: AdminAccountDetailsHeaderProps) => {
  const strings = LocalizedStrings.admin;
  const resolvedName = account.fullName.length > 0 ? account.fullName : `(${strings.accountDetailsNoName()})`;

  return (
    <Card sx={{ ...sx, py: 2, px: 3, border: 0, boxShadow: 0 }} className={className} square>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center'
        }}
      >
        <Stack
          spacing={2}
          sx={{
            flex: 1,
            alignItems: 'flex-start'
          }}
        >
          <Stack direction="row" spacing={1}>
            <Typography
              variant="h5"
              sx={{
                color: account.fullName.length === 0 ? (theme) => theme.palette.text.secondary : undefined
              }}
            >
              {resolvedName}
            </Typography>
          </Stack>

          {account.emailAddress.length > 0 && (
            <Chip
              sx={{ mt: 2 }}
              label={account.emailAddress}
              icon={<MailRounded fontSize="small" />}
              // The Link will handle the action, but we still want the effect offered by using onClick.
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => {}}
              component={Link}
              href={`mailto:${account.emailAddress}`}
              target="_blank"
              rel="noreferrer"
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
});
