import { ScheduleSymbolKind } from '@/models';
import Badge from '@/resources/icons/special-day/badge.svg?react';
import Circle from '@/resources/icons/special-day/circle.svg?react';
import CloseSquareBracket from '@/resources/icons/special-day/close-square-bracket.svg?react';
import FivePointStar from '@/resources/icons/special-day/five-point-star.svg?react';
import Hexagon from '@/resources/icons/special-day/hexagon.svg?react';
import InversedFivePointStar from '@/resources/icons/special-day/inversed-five-point-star.svg?react';
import Lozenge from '@/resources/icons/special-day/lozenge.svg?react';
import OpenSquareBracket from '@/resources/icons/special-day/open-square-bracket.svg?react';
import OutlinedCircle from '@/resources/icons/special-day/outlined-circle.svg?react';
import OutlinedHexagon from '@/resources/icons/special-day/outlined-hexagon.svg?react';
import OutlinedLozenge from '@/resources/icons/special-day/outlined-lozenge.svg?react';
import OutlinedSquare from '@/resources/icons/special-day/outlined-square.svg?react';
import SixPointStar from '@/resources/icons/special-day/six-point-star.svg?react';
import Square from '@/resources/icons/special-day/square.svg?react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties } from 'react';

export interface ScheduleSymbolProps {
  symbolKind: ScheduleSymbolKind;
  size: number;
  color: string;
  style?: CSSProperties;
  className?: string;
}

export const ScheduleSymbol = observer(({ symbolKind, size, color, style, className }: ScheduleSymbolProps) => {
  const SymbolComponent = getSymbolImageComponent(symbolKind);
  return (
    <SymbolComponent
      className={className}
      style={{ ...style, display: 'flex', width: size, height: size, fill: color }}
      color={color}
    />
  );
});

type SvgComponent = React.FunctionComponent<React.ComponentProps<'svg'> & { title?: string }>;

function getSymbolImageComponent(kind: ScheduleSymbolKind): SvgComponent {
  switch (kind) {
    case ':badge:':
      return Badge;
    case ':circle:':
      return Circle;
    case ':close-square-bracket:':
      return CloseSquareBracket;
    case ':five-point-star:':
      return FivePointStar;
    case ':hexagon:':
      return Hexagon;
    case ':inversed-five-point-star:':
      return InversedFivePointStar;
    case ':lozenge:':
      return Lozenge;
    case ':open-square-bracket:':
      return OpenSquareBracket;
    case ':outlined-circle:':
      return OutlinedCircle;
    case ':outlined-hexagon:':
      return OutlinedHexagon;
    case ':outlined-lozenge:':
      return OutlinedLozenge;
    case ':outlined-square:':
      return OutlinedSquare;
    case ':six-point-star:':
      return SixPointStar;
    case ':square:':
      return Square;
  }
}
