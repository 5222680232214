import { format } from 'date-fns';

export interface ParticipationRequestsStrings {
  readonly title: string;
  readonly subtitle: string;
  readonly emptyLabel: string;
  readonly errorMessage: string;
  readonly requestedOnDateLabel: (date: Date) => string;
  readonly denyButtonLabel: string;
  readonly denyConfirmationMessage: (name: string) => string;
  readonly denyConfirmationButtonLabel: string;
  readonly grantButtonLabel: string;
  readonly grantConfirmationMessage: (name: string) => string;
  readonly grantConfirmationButtonLabel: string;
}

export const EnglishParticipationRequestsStrings: ParticipationRequestsStrings = {
  title: 'Requests',
  subtitle: 'These teachers would like to obtain readonly access to your planner.',
  emptyLabel: 'No requests',
  errorMessage: 'Something went wrong. Please try again or contact support',
  requestedOnDateLabel: (date) => `Request on ${format(date, 'PPP')}`,
  denyButtonLabel: 'Deny',
  denyConfirmationMessage: (name) => `Are you sure you wish to deny ${name}'s request?`,
  denyConfirmationButtonLabel: 'Deny request',
  grantButtonLabel: 'Accept',
  grantConfirmationMessage: (name) => `Are you sure you wish to accept ${name}'s request?`,
  grantConfirmationButtonLabel: 'Grant access'
};

export const FrenchParticipationRequestsStrings: ParticipationRequestsStrings = {
  title: 'Demandes d’accès',
  subtitle: 'Ces enseignants aimeraient obtenir un accès en lecture seule à ton planificateur.',
  emptyLabel: 'Aucune demande',
  errorMessage: 'Une erreur est survenue. Veuillez réessayer ou contacter le support.',
  requestedOnDateLabel: (date) => `Demande faite le ${format(date, 'PPP')}`,
  denyButtonLabel: 'Refuser',
  denyConfirmationMessage: (name) => `Êtes-vous sûr de vouloir refuser la demande de ${name}?`,
  denyConfirmationButtonLabel: 'Refuser la demande',
  grantButtonLabel: 'Accepter',
  grantConfirmationMessage: (name) => `Êtes-vous sûr de vouloir accepter la demande de ${name}?`,
  grantConfirmationButtonLabel: 'Permettre l’accès'
};
