import i18n from 'translate';

export const NoteDetailsStrings = {
  archivedAlertActionTitle: () => i18n.t('note.details.archivedAlertActionTitle'),
  archivedAlertTitle: () => i18n.t('note.details.archivedAlertTitle'),
  archiveNote: () => i18n.t('note.details.archiveNote'),
  archiveNoteConfirmationButton: () => i18n.t('note.details.archiveNoteConfirmationButton'),
  archiveNoteConfirmationMessage: () => i18n.t('note.details.archiveNoteConfirmationMessage'),
  archiveNoteConfirmationTitle: () => i18n.t('note.details.archiveNoteConfirmationTitle'),
  title: () => i18n.t('note.details.title')
};
