import { CloseRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode, useState } from 'react';
import LocalizedStrings from 'strings';

export type FormPopoverAdditionalAction = FormPopoverAdditionalActionButton | FormPopoverAdditionalActionMenu;

export interface FormPopoverAdditionalActionMenu {
  readonly kind: 'menu';
  readonly id: string;
  readonly title: string;
  readonly tooltip?: string;
  readonly hidden?: boolean;
  readonly options: {
    readonly id: string;
    readonly icon?: ReactElement;
    readonly title: string;
    readonly onSelect: () => Promise<void>;
  }[];
}

export interface FormPopoverAdditionalActionButton {
  readonly kind: 'button';
  readonly id: string;
  readonly title: string;
  readonly tooltip?: string;
  readonly onClick: () => Promise<void>;
  readonly hidden?: boolean;
  readonly isDestructive?: boolean;
}

export interface FormPopoverActionsProps {
  sx?: SxProps;
  className?: string;
  onCancel?: () => Promise<void>;
  onSubmit: () => Promise<void>;
  canSubmit: boolean;
  customSubmitTitle?: string;
  customSubmitIcon?: ReactNode;
  additionalActions?: FormPopoverAdditionalAction[];
}

export const FormPopoverActions = observer(
  ({
    sx = [],
    className,
    onSubmit,
    canSubmit,
    onCancel,
    customSubmitTitle,
    customSubmitIcon,
    additionalActions = []
  }: FormPopoverActionsProps) => {
    const [pressedAction, setPressedAction] = useState<string | undefined>();
    const [displayedMenu, setDisplayedMenu] = useState<{ id: string; ref: HTMLButtonElement } | undefined>();
    const disableActions = pressedAction != null;

    async function saveButtonPressed() {
      setPressedAction('save');
      await onSubmit();
      setPressedAction(undefined);
    }

    async function cancelButtonPressed() {
      setPressedAction('cancel');
      await onCancel?.();
      setPressedAction(undefined);
    }

    async function additionalActionButtonPressed(action: FormPopoverAdditionalActionButton) {
      setPressedAction(action.id);
      await action.onClick();
      setPressedAction(undefined);
    }

    async function additionalActionMenuOptionPressed(actionId: string, onSelect: () => Promise<void>) {
      setDisplayedMenu(undefined);
      setPressedAction(actionId);
      await onSelect();
      setPressedAction(undefined);
    }

    return (
      <Stack sx={sx} className={className}>
        <Stack
          spacing={1}
          direction="row"
          sx={{
            p: 2,
            pt: 1
          }}
        >
          {additionalActions.length > 0 && (
            <Stack spacing={1} direction="row">
              {additionalActions.map((action) => {
                if (action.hidden === true) {
                  return null;
                }

                switch (action.kind) {
                  case 'menu':
                    return (
                      <Box key={action.id}>
                        <Tooltip key={action.id} title={action.tooltip}>
                          <LoadingButton
                            variant="text"
                            onClick={(e) => setDisplayedMenu({ id: action.id, ref: e.currentTarget })}
                            disabled={disableActions}
                            loading={pressedAction === action.id}
                          >
                            {action.title}
                          </LoadingButton>
                        </Tooltip>

                        <Menu
                          open={displayedMenu != null && displayedMenu.id === action.id}
                          anchorEl={displayedMenu?.ref}
                          onClose={() => setDisplayedMenu(undefined)}
                          elevation={2}
                          slotProps={{ paper: { sx: { minWidth: 250 } } }}
                        >
                          {action.options.map((option) => (
                            <MenuItem
                              key={option.id}
                              onClick={() => void additionalActionMenuOptionPressed(action.id, option.onSelect)}
                            >
                              {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                              <ListItemText>{option.title}</ListItemText>
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                    );

                  case 'button':
                    return (
                      <Tooltip key={action.id} title={action.tooltip}>
                        <LoadingButton
                          variant="text"
                          onClick={() => void additionalActionButtonPressed(action)}
                          disabled={disableActions}
                          loading={pressedAction === action.id}
                          color={action.isDestructive === true ? 'error' : undefined}
                        >
                          {action.title}
                        </LoadingButton>
                      </Tooltip>
                    );
                }
              })}
            </Stack>
          )}

          <Stack
            spacing={1}
            direction="row"
            sx={{
              flex: 1,
              justifyContent: 'flex-end'
            }}
          >
            {onCancel != null && (
              <LoadingButton
                sx={{ minWidth: 100 }}
                variant="contained-grey"
                onClick={() => void cancelButtonPressed()}
                disabled={disableActions}
                loading={pressedAction === 'cancel'}
                startIcon={<CloseRounded />}
              >
                {LocalizedStrings.utils.formPopoverCancelActionTitle()}
              </LoadingButton>
            )}

            <LoadingButton
              sx={{ minWidth: 100 }}
              variant={onCancel != null ? 'contained' : 'contained-grey'}
              onClick={() => void saveButtonPressed()}
              disabled={!canSubmit || pressedAction != null}
              loading={pressedAction === 'save'}
              startIcon={customSubmitIcon}
            >
              {customSubmitTitle ?? LocalizedStrings.utils.formPopoverSaveActionDefaultTitle()}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    );
  }
);
