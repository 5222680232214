import { usePrevious, useServices } from '@/hooks';
import { ContentPasteRounded } from '@mui/icons-material';
import { Box, Button, Card, Fade, Snackbar, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { getBlurredClassName } from '../utils';

export interface PasteboardContentBannerProps {
  sx?: SxProps;
  className?: string;
}

export const PasteboardContentBanner = observer(({ sx = [], className }: PasteboardContentBannerProps) => {
  const { pasteboard } = useServices();
  const theme = useTheme();
  const strings = LocalizedStrings.utils.pasteboardBanner;

  const message = useMemo(() => {
    if (pasteboard.content == null) {
      return undefined;
    }

    const summaryText = pasteboard.getSummaryTextForContent(pasteboard.content);

    switch (pasteboard.content?.case) {
      case 'work':
        return strings.workMessage(summaryText);

      case 'note':
        return strings.noteMessage(summaryText);

      case 'published-work':
        return strings.publishedWorkMessage(summaryText);
    }
  }, [pasteboard.content]);

  // Prevent resize of snackbar while it is being dismissed on clear.
  const previousMessage = usePrevious(message);

  return (
    <Snackbar
      sx={sx}
      className={className}
      open={pasteboard.content != null}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      TransitionComponent={Fade}
    >
      <Card sx={{ backgroundColor: 'transparent' }} elevation={12}>
        <Box
          className={getBlurredClassName('paper', theme, theme.palette.common.white)}
          sx={{
            p: 1,
            pl: 2,
            color: theme.palette.common.black
          }}
        >
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              alignItems: 'center'
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center'
              }}
            >
              <ContentPasteRounded fontSize="small" />
              <Typography variant="subtitle2">{message ?? previousMessage ?? ''}</Typography>
            </Stack>

            <Button size="small" color="primary" onClick={() => pasteboard.clearContent()}>
              {strings.clearButtonTitle()}
            </Button>
          </Stack>
        </Box>
      </Card>
    </Snackbar>
  );
});
