import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';

export interface ExOnboardingStrings {
  readonly backButtonLabel: string;
  readonly completionButtonLabel: string;
  readonly completionTitle: string;
  readonly continue: string;
  readonly defaultSchoolName: string;
  readonly letsStartButtonAnonymous: string;
  readonly letsStartButtonLoggedIn: string;
  readonly createSchoolStepTitle: string;
  readonly expectSharingInvitationCodeLabel: string;
  readonly nextButtonLabel: string;
  readonly no: string;
  readonly personaLabel: string;
  readonly alreadyHaveAccount: string;
  readonly signIn: string;
  readonly signOut: string;
  readonly useSharingInvitationCodeHeader: string;
  readonly welcome: string;
  readonly welcomeDescriptionAnonymous: string;
  readonly welcomeDescriptionLoggedIn: string;
  readonly welcomeErrorMessage: string;
  readonly welcomeNextButtonLabelStudentPendingParticipationRequests: string;
  readonly yes: string;

  applyPersonaErrorMessage: (error: string) => string;
  completeOnboardingErrorMessage: (error: string) => string;
  createPlannerErrorMessage: (error: string) => string;
  initialWorkCreationInfo: (persona: UserPersona) => string;
  persona: (persona: UserPersona) => string;
  personaDetail: (persona: UserPersona) => string;
  sharePlannerSubtitle: (persona: UserPersona) => string;
  welcomeUserName: (name: string) => string;
}

export const EnglishOnboardingStrings: ExOnboardingStrings = {
  backButtonLabel: 'Back',
  completionButtonLabel: 'Start using Studyo Go',
  completionTitle: "You're all set!",
  continue: 'Continue',
  defaultSchoolName: 'Unnamed School',
  letsStartButtonAnonymous: 'Sign in / sign up',
  letsStartButtonLoggedIn: 'Continue',
  createSchoolStepTitle: 'Create your school',
  expectSharingInvitationCodeLabel: 'Did you receive a sharing code?',
  nextButtonLabel: 'Next',
  no: 'No',
  personaLabel: 'I will be using Studyo Go as a…',
  alreadyHaveAccount: 'I already have an account',
  signIn: 'Sign in / sign up',
  signOut: 'Sign Out',
  useSharingInvitationCodeHeader: 'Use sharing code',
  welcome: 'Welcome to Studyo Go!',
  welcomeDescriptionAnonymous: 'Organize homework, study time and chores from all sources in one place.',
  welcomeDescriptionLoggedIn: 'Please help us personalize Studyo Go to your needs by answering a few simple questions.',
  welcomeErrorMessage: 'An error occurred. Please try again or contact support.',
  welcomeNextButtonLabelStudentPendingParticipationRequests: 'Go to my planner',
  yes: 'Yes',

  applyPersonaErrorMessage: (error) => `An error occurred while applying the selected role. Details: ${error}`,
  completeOnboardingErrorMessage: (error) => `An error occurred while completing the onboarding. Details: ${error}`,
  createPlannerErrorMessage: (error) => `An error occurred while creating the planner. Details: ${error}`,
  initialWorkCreationInfo: (persona) => {
    switch (persona) {
      case UserPersona.PARENT:
        return "Studyo Go allows you and your child to enter their homework, exams and chores to plan them. You can also connect Google Classroom to automatically create their homework.\nLet's get started by creating a first item in your child's planner!";
      case UserPersona.TEACHER:
        return "Studyo Go allows you and your students to enter their homework, exams and chores to plan them. You can also connect Google Classroom to automatically create their homework.\nLet's get started by creating a first item in your student's planner!";
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
      case UserPersona.STUDENT:
        return "Studyo Go allows you to enter your homework, exams and chores to plan them. You can even connect Google Classroom and automatically create your homeworks.\nLet's get started by creating a first item in your planner!";
    }
  },
  persona: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Student';
      case UserPersona.PARENT:
        return 'Parent';
      case UserPersona.TEACHER:
        return 'Educator or school personnel';
      default:
        return 'Unknown';
    }
  },
  personaDetail: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'I want to get organized using Studyo Go.';
      case UserPersona.PARENT:
        return 'I want to help my child get organized using Studyo Go.';
      case UserPersona.TEACHER:
        return 'I want to help my students get organized using Studyo Go.';
      case UserPersona.OTHER:
        return '';
      default:
        return 'Unknown';
    }
  },
  sharePlannerSubtitle: (persona) => {
    switch (persona) {
      case UserPersona.PARENT:
        return "Great! Let's get your child connected now.";
      case UserPersona.TEACHER:
        return "Great! Let's get your student connected now.";
      case UserPersona.STUDENT:
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
      default:
        return "Great! Let's share your planner now.";
    }
  },
  welcomeUserName: (fullName) =>
    fullName.trim().length === 0 ? 'Welcome to Studyo Go!' : `Welcome ${fullName.trim()}!`
};

export const FrenchOnboardingStrings: ExOnboardingStrings = {
  backButtonLabel: 'Précédant',
  completionButtonLabel: 'Commencer à utliser Studyo Go',
  completionTitle: 'Tout est prêt!',
  continue: 'Continuer',
  defaultSchoolName: 'École sans nom',
  letsStartButtonAnonymous: 'Se connecter / créer un compte',
  letsStartButtonLoggedIn: 'Continuer',
  createSchoolStepTitle: 'Créez votre école',
  expectSharingInvitationCodeLabel: 'Avez-vous reçu un code de partage?',
  nextButtonLabel: 'Suivant',
  no: 'Non',
  personaLabel: 'Je vais utiliser Studyo Go en tant que…',
  alreadyHaveAccount: 'Je possède déjà un compte',
  signIn: 'Se connecter / créer un compte',
  signOut: 'Déconnexion',
  useSharingInvitationCodeHeader: 'Utiliser un code de partage',
  welcome: 'Bienvenue sur Studyo Go!',
  welcomeDescriptionAnonymous:
    'Un outil de gestion des tâches, devoirs et études de toutes sources qui encourage l’organisation.',
  welcomeDescriptionLoggedIn:
    'Veuillez nous aider à personnaliser Studyo Go pour répondre à vos besoins avec les quelques questions suivantes.',
  welcomeErrorMessage: 'Une erreur est survenue. Veuillez réessayer ou contacter le support.',
  welcomeNextButtonLabelStudentPendingParticipationRequests: 'Naviger vers mon planificateur',
  yes: 'Oui',

  applyPersonaErrorMessage: (error) => `Une erreur est survenue lors de la sauvegarde du rôle. Détails : ${error}`,
  completeOnboardingErrorMessage: (error) =>
    `Une erreur est survenue lors de la finalisation de l’embarquement. Détails : ${error}`,
  createPlannerErrorMessage: (error) =>
    `Une erreur est survenue lors de la création du planificateur. Détails : ${error}`,
  initialWorkCreationInfo: (persona) => {
    switch (persona) {
      case UserPersona.PARENT:
        return 'Studyo Go permet à vous et votre enfant d’entrer leurs devoirs, examens et tâches pour les planifier. Vous pouvez également connecter Google Classroom pour automatiquement ajouter les devoirs.\nCommençons par entrer un premier item dans l’agenda de votre enfant!';
      case UserPersona.TEACHER:
        return 'Studyo Go permet à vous et vos élèves d’entrer leurs devoirs, examens et tâches pour les planifier. Vous pouvez également connecter Google Classroom pour automatiquement ajouter les devoirs.\nCommençons par entrer un premier item dans l’agenda de votre élève!';
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
      case UserPersona.STUDENT:
        return 'Studyo Go te permet d’entrer tes devoirs, examens et tâches pour les planifier. Tu peux même connecter Google Classroom et automatiquement ajouter tes devoirs.\nCommençons par entrer un premier item dans ton agenda!';
    }
  },
  persona: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Élève';
      case UserPersona.PARENT:
        return 'Parent';
      case UserPersona.TEACHER:
        return 'Éducateur ou autre personnel d’école';
      default:
        return 'Inconnu';
    }
  },
  personaDetail: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Je veux m’organiser avec Studyo Go.';
      case UserPersona.PARENT:
        return 'Je veux aider mon enfant à s’organiser avec Studyo Go.';
      case UserPersona.TEACHER:
        return 'Je veux aider mes élèves à s’organiser avec Studyo Go.';
      case UserPersona.OTHER:
        return '';
      default:
        return 'Unknown';
    }
  },
  sharePlannerSubtitle: (persona) => {
    switch (persona) {
      case UserPersona.PARENT:
        return 'Super! Connectons votre enfant maintenant.';
      case UserPersona.TEACHER:
        return 'Super! Connectons votre étudiant maintenant.';
      case UserPersona.STUDENT:
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
      default:
        return 'Super! Partageons votre planificateur maintenant.';
    }
  },
  welcomeUserName: (fullName) =>
    fullName.trim().length === 0 ? 'Bienvenue sur Studyo Go!' : `Bienvenue ${fullName.trim()}!`
};
