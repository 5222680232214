import { DetailedHTMLProps, FormEventHandler, FormHTMLAttributes } from 'react';

export interface FormProps extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  /**
   * Callback when submitting the form.
   */
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

export const Form = ({ children, onSubmit, ...props }: FormProps) => {
  return (
    <form
      {...props}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit?.(event);
      }}
    >
      {children}
    </form>
  );
};
