import { ConnectedAppUserDashboard } from '@/models';
import { Course } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/course_pb';
import { SyncSchoolSubject } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_school_subject_pb';
import { SyncStatus } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_status_pb';
import { Classroom } from '@buf/studyo_studyo-today-classroom.connectrpc_es/studyo/today/classroom/v1/classroom_connect';
import { ClientAppKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/client_app_kind_pb.js';
import { ClassroomTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

type DashboardSubject =
  | { value: string; case: 'plannerId' }
  | { value: string; case: 'schoolId' }
  | { value: SyncSchoolSubject; case: 'schoolSubject' }
  | { case: undefined; value?: undefined };

export class AppClassroomTransportService extends AppBaseTransportService implements ClassroomTransportService {
  async getSyncStatus(dashboard: ConnectedAppUserDashboard): Promise<SyncStatus> {
    return await this.performReadRequest(
      'getSyncStatus',
      Classroom,
      Classroom.methods.getSyncStatus,
      { subject: this.dashboardToSubject(dashboard) },
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async synchronize(dashboard: ConnectedAppUserDashboard) {
    await this.performReadRequest(
      'synchronize',
      Classroom,
      Classroom.methods.synchronize,
      { subject: this.dashboardToSubject(dashboard) },
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async registerOfflineAccessCode(code: string, dashboard: ConnectedAppUserDashboard) {
    await this.performWriteRequest(
      'registerOfflineAccessCode',
      Classroom,
      Classroom.methods.registerOfflineAccessCode,
      { code, clientAppKind: ClientAppKind.WEB, subject: this.dashboardToSubject(dashboard) },
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async revokeOfflineAccessCode(dashboard: ConnectedAppUserDashboard) {
    await this.performWriteRequest(
      'revokeOfflineAccessCode',
      Classroom,
      Classroom.methods.revokeOfflineAccessCode,
      { subject: this.dashboardToSubject(dashboard) },
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async getCourses(dashboard: ConnectedAppUserDashboard): Promise<Course[]> {
    const response = await this.performReadRequest(
      'getCourses',
      Classroom,
      Classroom.methods.getCourses,
      { subject: this.dashboardToSubject(dashboard) },
      { isDemoObject: this.dashboardIsDemo(dashboard) }
    );

    return response.courses;
  }

  async updateCourseSelection(courseIds: string[], dashboard: ConnectedAppUserDashboard): Promise<Course[]> {
    const response = await this.performWriteRequest(
      'updateCourseSelection',
      Classroom,
      Classroom.methods.updateCourseSelection,
      { selectedCourseIds: courseIds, subject: this.dashboardToSubject(dashboard) },
      { isDemoObject: this.dashboardIsDemo(dashboard) }
    );

    return response.courses;
  }

  private dashboardToSubject(dashboard: ConnectedAppUserDashboard): DashboardSubject {
    switch (dashboard.case) {
      case 'planner':
        return { case: 'plannerId', value: dashboard.plannerId };
      case 'school':
        return { case: 'schoolId', value: dashboard.schoolId };
      case 'plannerSchool':
        return { case: 'schoolSubject', value: new SyncSchoolSubject({ ...dashboard }) };
    }
  }

  private dashboardIsDemo(dashboard: ConnectedAppUserDashboard): boolean {
    if (dashboard.case === 'school') {
      return this.isDemoId(dashboard.schoolId);
    } else {
      return this.isDemoId(dashboard.plannerId);
    }
  }
}
