import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Width } from '@/utils';
import EmptyListIcon from '@mui/icons-material/EmojiNatureRounded';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { EmptyListIndicator, MediaQuery } from '../../../utils';
import { StudentsPlannerSummaryList } from './StudentsPlannerSummaryList';
import { StudentsPlannerSummaryTable } from './StudentsPlannerSummaryTable';

export interface StudentsPlannerSummaryProps {
  sx?: SxProps;
  className?: string;

  /**
   * Max width that is considered to be small.
   */
  smallScreenWidth: Width;
}

export const StudentsPlannerSummary = observer(
  ({ sx = [], className, smallScreenWidth }: StudentsPlannerSummaryProps) => {
    const { localization } = useServices();
    const plannerId = useActivePlannerId();
    const strings = localization.localizedStrings.schoolCourseSection;

    const params = useParams();
    const schoolId = params.schoolId ?? '';
    const courseSectionId = params.courseId ?? '';

    const viewModel = useViewModel(
      (viewModels) => viewModels.createCourseSectionDetailsViewModel(courseSectionId, plannerId, schoolId),
      [courseSectionId]
    );

    const desktopMediaQuery = (th: Theme) => th.breakpoints.up(smallScreenWidth);
    const mobileMediaQuery = (th: Theme) => th.breakpoints.down(smallScreenWidth);
    const hasStudents = viewModel.studentsPlannerSummary.allStudents.length > 0;

    return (
      <Box
        sx={{
          ...sx,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          pb: !hasStudents ? 2 : undefined
        }}
        className={className}
      >
        {hasStudents && (
          <>
            <MediaQuery mediaQuery={desktopMediaQuery}>
              <StudentsPlannerSummaryTable viewModel={viewModel.studentsPlannerSummary} />
            </MediaQuery>

            <MediaQuery mediaQuery={mobileMediaQuery}>
              <StudentsPlannerSummaryList viewModel={viewModel.studentsPlannerSummary} sx={{ flex: 1 }} />
            </MediaQuery>
          </>
        )}
        {!hasStudents && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2
            }}
          >
            <EmptyListIndicator
              renderIcon={(className) => <EmptyListIcon className={className} />}
              title={strings.detailsNoStudentsTitle}
              subtitle={strings.detailsNoStudentsSubtitle}
            />
          </Box>
        )}
      </Box>
    );
  }
);
