import { useServices } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../../../utils';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridTimesColumnProps {
  sx?: SxProps;
  className?: string;
}

export const ScheduleCyclePeriodScheduleGridTimesColumn = observer(
  ({ sx = [], className }: ScheduleCyclePeriodScheduleGridTimesColumnProps) => {
    const { dateService } = useServices();

    function getFormattedTimeForIndex(index: number): string {
      if (index <= 0 || index > 23) {
        return '';
      }

      const date = set(dateService.now, { hours: index, minutes: 0 });
      // Removing minutes as they are not important.
      return format(date, 'p').replace(':00', '');
    }

    return (
      <Stack
        className={className}
        direction="row"
        spacing={0.5}
        sx={[
          {
            position: 'sticky',
            left: 0,
            width: ScheduleCyclePeriodScheduleGridConstants.timesColumnWidth,
            zIndex: ScheduleCyclePeriodScheduleGridConstants.timesColumnZIndex,
            height: ScheduleCyclePeriodScheduleGridConstants.pointsPerHour * 24
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {Array(25)
          .fill(null)
          .map((_, index) => (
            <Typography
              key={index}
              variant="caption"
              sx={{
                fontSize: 10,
                fontWeight: '600',
                position: 'absolute',
                top: index * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour - 8.3,
                left: 8,
                right: 8,
                textAlign: 'right'
              }}
            >
              {getFormattedTimeForIndex(index)}
            </Typography>
          ))}
      </Stack>
    );
  }
);
