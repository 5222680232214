import { WorkStepEditViewModel, WorkStepInfo, WorkStepsViewModel } from '@/viewmodels';
import { AddRounded, TaskAltRounded } from '@mui/icons-material';
import { Box, Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';
import { useServices } from '../../../hooks';
import { Gauge, ReorderableList } from '../../utils';
import { SortableWorkDetailsStep } from './SortableWorkDetailsStep';
import { WorkDetailsCardHeader } from './WorkDetailsCardHeader';
import { WorkDetailsStepEditCard } from './WorkDetailsStepEditCard';

export interface WorkDetailsStepsListProps {
  sx?: SxProps;
  className?: string;
  disabled?: boolean;
  viewModel: WorkStepsViewModel;
}

export const WorkDetailsStepsList = observer(
  ({ sx = [], className, viewModel, disabled = false }: WorkDetailsStepsListProps) => {
    const theme = useTheme();
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;
    const [editStep, setEditStep] = useState<WorkStepEditViewModel | undefined>(undefined);

    function gaugeColorForPercentage(percentageCompleted: number) {
      if (percentageCompleted <= 0.33) {
        return theme.palette.error.main;
      } else if (percentageCompleted <= 0.67) {
        return theme.palette.warning.main;
      } else {
        return theme.palette.success.main;
      }
    }

    function startEditOfStep(step: WorkStepInfo) {
      const vm = viewModel.makeStepEditViewModel(
        step.step,
        () => setEditStep(undefined),
        () => cancelStepEdit()
      );
      setEditStep(vm);
    }

    function cancelStepEdit() {
      setEditStep(undefined);
    }

    function onAddStepClick() {
      const vm = viewModel.makeStepEditViewModel(
        undefined,
        () => onAddStepClick(),
        () => cancelStepEdit()
      );

      setEditStep(vm);
    }

    function onOrderChange(oldIndex: number, newIndex: number) {
      viewModel.moveStep(oldIndex, newIndex);
    }

    return (
      <Card className={className} sx={{ ...sx, p: 2, pt: 1.5 }} elevation={theme.palette.mode === 'light' ? 1 : 8}>
        <Stack spacing={1}>
          <WorkDetailsCardHeader
            sx={{ flex: 1 }}
            icon={<TaskAltRounded />}
            title={strings.stepsListTitle}
            subtitle={strings.stepsListSubtitle}
            rightElement={
              viewModel.steps.length > 0
                ? () => (
                    <Gauge
                      size={28}
                      value={viewModel.completedStepsCount}
                      maxValue={viewModel.steps.length}
                      lineColor={gaugeColorForPercentage}
                      showFullIcon
                    />
                  )
                : undefined
            }
          />

          {viewModel.steps.length > 0 && (
            <ReorderableList
              items={viewModel.steps}
              onOrderChanged={onOrderChange}
              renderItem={(step) => (
                <Fragment key={step.id}>
                  {editStep?.id === step.id ? (
                    <Box
                      sx={{
                        py: 1
                      }}
                    >
                      <WorkDetailsStepEditCard step={editStep} />
                    </Box>
                  ) : (
                    <SortableWorkDetailsStep isReadOnly={viewModel.isReadOnly} step={step} onEdit={startEditOfStep} />
                  )}
                </Fragment>
              )}
            />
          )}

          {!viewModel.isReadOnly && editStep?.isNew !== true && (
            <CardActionArea
              sx={{ flex: 1, py: 0.5, px: 0.5, borderRadius: 0.5, color: theme.palette.text.secondary }}
              onClick={onAddStepClick}
              disabled={disabled}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center'
                }}
              >
                <AddRounded fontSize="small" />
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    flex: 1
                  }}
                >
                  {strings.stepsListAddButtonTitle}
                </Typography>
              </Stack>
            </CardActionArea>
          )}

          {editStep?.isNew === true && <WorkDetailsStepEditCard step={editStep} />}
        </Stack>
      </Card>
    );
  }
);
