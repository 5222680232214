import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { OnboardingScreenInfo, OnboardingViewModel } from '../../viewmodels';
import { Form } from '../utils/Form';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

export interface OnboardingStepperProps {
  className?: string;
  onboardingState: OnboardingViewModel;
  style?: CSSProperties;
  renderScreen: (info: OnboardingScreenInfo) => ReactNode;
}

export const OnboardingStepper = observer(
  ({ className, onboardingState, style, renderScreen }: OnboardingStepperProps) => {
    const activeScreen = useMemo(
      () => onboardingState.screens[onboardingState.activeScreenIndex],
      [onboardingState.screens, onboardingState.activeScreenIndex]
    );
    const direction = useMemo(
      () => onboardingState.activeScreenIndex - onboardingState.previousScreenIndex,
      [activeScreen]
    );

    return (
      <Form
        className={className}
        style={{ ...style, width: '100%', height: '100%', overflowX: 'hidden' }}
        onSubmit={() => void activeScreen.viewModel.submit()}
      >
        <AnimatePresence initial={false} custom={direction} mode="popLayout">
          <motion.div
            key={onboardingState.activeScreenIndex}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ opacity: { duration: 0.2 }, ease: 'easeInOut' }}
          >
            {renderScreen(activeScreen)}
          </motion.div>
        </AnimatePresence>
      </Form>
    );
  }
);
