import { TimeOfDay, UserDashboardInfo, compareTimeOfDays, timeOfDaySpanOverlaps } from '@/models';
import { ServiceContainer } from '@/providers';
import { ScheduleCycleDataStore, UserDataStore } from '@/stores';
import { Period } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { action, computed, makeObservable, observable } from 'mobx';

export interface ScheduleCyclePeriodSchedulePeriodEditViewModel {
  readonly isNewPeriod: boolean;
  readonly scheduleName: string;
  periodLabel: string;
  startTime: TimeOfDay;
  endTime: TimeOfDay;
  readonly isTimeValid: boolean;
  readonly hasConflict: boolean;
  readonly suggestions: Period[];
  readonly canSave: boolean;
  readonly isReadOnly: boolean;

  applySuggestion(suggestion: Period): void;
  delete(): Promise<void>;
  save(): Promise<void>;
}

export class AppScheduleCyclePeriodSchedulePeriodEditViewModel
  implements ScheduleCyclePeriodSchedulePeriodEditViewModel
{
  @observable protected _periodLabel = '';
  @observable protected _startTime: TimeOfDay = new TimeOfDay();
  @observable protected _endTime: TimeOfDay = new TimeOfDay();

  constructor(
    protected readonly _dashboard: UserDashboardInfo,
    protected readonly _scheduleCycleId: string,
    protected readonly _periodScheduleId: string,
    protected readonly _period: Period,
    public readonly scheduleName: string,
    public readonly suggestions: Period[],
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
    this._periodLabel = _period.label;
    this._startTime = _period.startTime!;
    this._endTime = _period.endTime!;
  }

  @computed
  protected get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  protected get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  get isNewPeriod(): boolean {
    return this._period.id.length === 0;
  }

  @computed
  get canSave(): boolean {
    return this._periodLabel.length > 0 && this.isTimeValid && !this.hasConflict;
  }

  @computed
  get isTimeValid(): boolean {
    return compareTimeOfDays(this._startTime, this._endTime) < 0;
  }

  @computed
  get hasConflict(): boolean {
    if (!this.isTimeValid) {
      return false;
    }

    const { scheduleCycle } = this.scheduleCycleStore;
    const schedule = scheduleCycle.periodSchedules.find((s) => !s.shouldDelete && s.id === this._periodScheduleId)!;
    return schedule.periods.some(
      (p) =>
        p.id !== this._period.id &&
        timeOfDaySpanOverlaps(this._startTime, this._endTime, p.startTime!, p.endTime!, false)
    );
  }

  @computed
  get isReadOnly(): boolean {
    if (this._dashboard.kind === 'planner') {
      return false;
    }

    const school = this._userStore.getSchoolForId(this._dashboard.id)!;
    return !school.administrators.some((a) => a.userId === this._userStore.user.userId);
  }

  @computed
  get periodLabel(): string {
    return this._periodLabel;
  }

  set periodLabel(value: string) {
    this._periodLabel = value;
  }

  @computed
  get startTime(): TimeOfDay {
    return this._startTime;
  }

  set startTime(value: TimeOfDay) {
    this._startTime = value;
  }

  @computed
  get endTime(): TimeOfDay {
    return this._endTime;
  }

  set endTime(value: TimeOfDay) {
    this._endTime = value;
  }

  @action
  applySuggestion(suggestion: Period) {
    this._startTime = suggestion.startTime!;
    this._endTime = suggestion.endTime!;
  }

  async delete() {
    if (this._period.id.length === 0) {
      return;
    }

    await this.scheduleCycleStore.deletePeriod(this._period.id);
  }

  async save() {
    await this.scheduleCycleStore.createOrUpdatePeriod(
      this._periodScheduleId,
      this._period.id.length > 0 ? this._period.id : undefined,
      this._periodLabel,
      this.startTime,
      this._endTime
    );
  }
}
