import { AdminStrings } from './AdminStrings';
import { CalendarStrings } from './CalendarStrings';
import { DateTimeStrings } from './DateTimeStrings';
import { ModelsStrings } from './ModelsStrings';
import { OnboardingStrings } from './OnboardingStrings';
import { PlannedWorkStrings } from './PlannedWorkStrings';
import { PlannerStrings } from './PlannerStrings';
import { SchoolStrings } from './SchoolStrings';
import { SettingsStrings } from './SettingsStrings';
import { SideBarStrings } from './SideBarStrings';
import { StartTrialStrings } from './StartTrialStrings';
import { SubscriptionsStrings } from './SubscriptionsStrings';
import { UtilsStrings } from './UtilsStrings';
import { ScheduleCycleStrings } from './schedule-cycle/ScheduleCycleStrings';
import { NoteStrings } from './work/NoteStrings';
import { PublishedWorkStrings } from './work/PublishedWorkStrings';
import { WorkStrings } from './work/WorkStrings';

const LocalizedStrings = {
  admin: AdminStrings,
  calendar: CalendarStrings,
  dateTime: DateTimeStrings,
  models: ModelsStrings,
  note: NoteStrings,
  onboarding: OnboardingStrings,
  plannedWork: PlannedWorkStrings,
  planner: PlannerStrings,
  publishedWork: PublishedWorkStrings,
  scheduleCycle: ScheduleCycleStrings,
  school: SchoolStrings,
  settings: SettingsStrings,
  sidebar: SideBarStrings,
  startTrial: StartTrialStrings,
  subscriptions: SubscriptionsStrings,
  utils: UtilsStrings,
  work: WorkStrings
};

export default LocalizedStrings;
