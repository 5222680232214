import { CurriculumTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type CurriculumEnrolledCourseIdsInPlannerLoadable = LoadableValue<string[]>;

export class AppCurriculumEnrolledCourseIdsInPlannerLoadable
  extends BaseLoadableValue<string[]>
  implements CurriculumEnrolledCourseIdsInPlannerLoadable
{
  constructor(
    private readonly _plannerId: string,
    private readonly _curriculumTransport: CurriculumTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<string[]> {
    return this._curriculumTransport.getEnrolledCourseIdsInPlanner(this._plannerId);
  }
}
