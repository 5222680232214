export class NoMatchingPlannerError extends Error {}

export class CredentialsAlreadyLinkedError extends Error {
  static codes: string[] = ['auth/credential-already-in-use', 'auth/provider-already-linked'];
}

export class ForbiddenError extends Error {}

export function isError(error: unknown): error is Error {
  if (error) {
    return (error as Error).message != null;
  }

  return false;
}
