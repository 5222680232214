import { WorkTransportService } from '@/transports';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { makeObservable } from 'mobx';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type CourseSectionWorksLoadable = LoadableValue<Work[]>;

export class AppCourseSectionWorksLoadable extends BaseLoadableValue<Work[]> implements CourseSectionWorksLoadable {
  constructor(
    private readonly _plannerId: string,
    private readonly _courseSectionId: string,
    private readonly _courseSectionExternalSourceName: string | undefined,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  protected async loadData(): Promise<Work[]> {
    return await this._workTransport.getWorks(
      this._plannerId,
      this._courseSectionId,
      this._courseSectionExternalSourceName,
      false
    );
  }
}
