export interface InvitationStrings {
  defaultDescription: string;
  defaultTitle: string;
  linkTitle: string;
}

export const EnglishInvitationStrings: InvitationStrings = {
  defaultDescription:
    'The Studyo Go app will allow you to see all the assignments that your teachers post in one convenient location.\n\nYou will be able to plan when you want to work on your assignments and how much time you want to spend spend on each one. Using Today will help you manage your workload and gain control of your time.\n\nStart using Today… today. 😉',
  defaultTitle: 'Explore the Studyo Go app',
  linkTitle: 'Visit Studyo Go'
};

export const FrenchInvitationStrings: InvitationStrings = {
  defaultDescription:
    'L’application Studyo Go te permettra de voir tous les devoirs que tes enseignants publient au même endroit.\n\nTu pourras planifier à quel moment tu veux travailler sur chaque devoir, et combien de temps tu veux consacrer à chacun. Ceci t’aidera à gérer ta charge de travail et prendre contrôle de ton horaire d’étude.\n\nBonne planification!',
  defaultTitle: 'Rejoins Studyo Go',
  linkTitle: 'Visite Studyo Go'
};
