import { isDemoId } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppService, ConnectedAppsService, LocalizationService } from '@/services';
import { Loadable, SchoolCourseSectionLoadable, SchoolDataStore, mergeLoadableStates } from '@/stores';
import { notConcurrent } from '@/utils';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { ExternalSource } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/external_source_pb';
import { computed, makeObservable, override } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel, UpdatableViewModelState } from '../shared';
import { updatableViewModelStateForConnectedApps } from '../utils';
import { AppStudentsPlannerSummaryViewModel, StudentsPlannerSummaryViewModel } from './StudentsPlannerSummaryViewModel';

export interface SchoolCourseSectionDetailsViewModel extends UpdatableViewModel {
  readonly title: string;
  readonly section: string;
  readonly color: string | undefined;
  readonly studentsPlannerSummary: StudentsPlannerSummaryViewModel;
  readonly externalSource: ExternalSource | undefined;
  readonly studentsUsingAppCount: number;
  readonly totalStudentsCount: number;
  readonly isDemo: boolean;
  forceReloadData(): Promise<void>;
}

export class AppSchoolCourseSectionDetailsViewModel
  extends BaseUpdatableViewModel
  implements SchoolCourseSectionDetailsViewModel
{
  private readonly _forceReloadData = notConcurrent(async () => {
    await this.courseSectionLoadable.fetch(true);
  });

  private readonly _reloadData = notConcurrent(async () => {
    await this.courseSectionLoadable.fetch(true);
  });

  constructor(
    private readonly _plannerId: string,
    private readonly _schoolId: string,
    private readonly _courseSectionId: string,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super();
    makeObservable(this);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionLoadable];
  }

  @computed
  private get connectedAppServices(): ConnectedAppService<string>[] {
    return this._connectedApps.getConnectedAppServices(undefined, this._plannerId, this._schoolId);
  }

  @override
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([
      this.courseSectionLoadable.state,
      updatableViewModelStateForConnectedApps(this.connectedAppServices)
    ]);
  }

  @computed
  private get courseSectionLoadable(): SchoolCourseSectionLoadable {
    return this._schoolStore.getCourseSection(this._schoolId, this._courseSectionId);
  }

  @computed
  private get courseSection(): CourseSection {
    return this.courseSectionLoadable.data;
  }

  @computed
  get title(): string {
    return this.courseSection.title;
  }

  @computed
  get section(): string {
    return this.courseSection.section;
  }

  @computed
  get color(): string | undefined {
    return this.courseSection.color.length > 0 ? this.courseSection.color : undefined;
  }

  @computed
  get studentsUsingAppCount(): number {
    return this.courseSection.details?.todayStudentCount ?? 0;
  }

  @computed
  get totalStudentsCount(): number {
    return this.courseSection.details?.studentCount ?? 0;
  }

  @computed
  get studentsPlannerSummary(): StudentsPlannerSummaryViewModel {
    return new AppStudentsPlannerSummaryViewModel(
      this.courseSection.details?.students ?? [],
      this._courseSectionId,
      this._localization,
      this.studentsUsingAppCount,
      this.totalStudentsCount
    );
  }

  @computed
  get externalSource(): ExternalSource | undefined {
    return this.courseSection.externalSource;
  }

  readonly hasChanges = false;

  get hasData(): boolean {
    return this.courseSectionLoadable.hasData;
  }

  @computed
  get isDemo(): boolean {
    return isDemoId(this.courseSection.id);
  }

  async reloadData(): Promise<void> {
    await this._reloadData();
  }

  async forceReloadData(): Promise<void> {
    await this._forceReloadData();
  }
}
