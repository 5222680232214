export interface LoginStrings {
  readonly closeDialog: string;
  readonly dialogTitleSignIn: string;
  readonly dialogTitleSignUp: string;
  readonly dialogTitleUnknown: string;
  readonly errorMessage: (error: string) => string;
  readonly title: string;
  readonly loginButtonLabel: string;
}

export const EnglishLoginStrings: LoginStrings = {
  closeDialog: 'Close',
  dialogTitleSignIn: 'Log in',
  dialogTitleSignUp: 'Create my Studyo Go account',
  dialogTitleUnknown: 'Sign in / sign up',
  errorMessage: (error) => `An error occurred while attempting to sign in. Details: ${error}`,
  title: 'Take control of your workload!',
  loginButtonLabel: 'Sign in with Google'
};

export const FrenchLoginStrings: LoginStrings = {
  closeDialog: 'Fermer',
  dialogTitleSignIn: 'Connexion',
  dialogTitleSignUp: 'Créer mon compte Studyo Go',
  dialogTitleUnknown: 'Connexion',
  errorMessage: (error) => `Une erreur est survenue lors de la connexion. Détails : ${error}`,
  title: 'Deviens maître de ta charge de travail!',
  loginButtonLabel: 'Connexion avec Google'
};
