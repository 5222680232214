import { EditableWorkStep, plannedWorkIsCompleted } from '@/models';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { chain, orderBy } from 'lodash';
import { ObservableSet, action, computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { arraysAreEqual } from '../../utils';

export interface WorkStepEditViewModelPlannedWorks {
  readonly upcoming: PlannedWork[];
  readonly past: PlannedWork[];
}

export interface WorkStepEditViewModel {
  readonly id: string;
  readonly isNew: boolean;
  isCompleted: boolean;
  title: string;
  duration: number;
  readonly canSave: boolean;
  readonly canDelete: boolean;
  readonly availablePlannedWorks: WorkStepEditViewModelPlannedWorks;
  readonly selectedPlannedWorkIds: string[];
  toggleSelectionOfPlannedWork(id: string): void;
  delete(): void;
  cancel(): void;
  save(): void;
}

export class AppWorkStepEditViewModel implements WorkStepEditViewModel {
  private readonly _initialSelectedPlannedWorkIds: string[];
  private readonly _selectedPlannedWorkIds: ObservableSet<string>;
  private readonly _editableStep: EditableWorkStep;

  constructor(
    private readonly _work: () => Work,
    private readonly _step: WorkStep | undefined,
    private readonly _onSave: (step: WorkStep, plannedWorkIds: string[]) => void,
    private readonly _onCancel: () => void,
    private readonly _onDelete: (() => void) | undefined,
    private readonly _dateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
    this._editableStep = new EditableWorkStep(_step);

    const plannedWorkIds =
      _step != null
        ? chain(_work().plannedWorks)
            .map((pw) => (pw.workStepIds.includes(_step.id) ? pw.id : undefined))
            .compact()
            .value()
        : [];
    this._initialSelectedPlannedWorkIds = plannedWorkIds;
    this._selectedPlannedWorkIds = observable.set(plannedWorkIds);
  }

  @computed
  private get allAvailablePlannedWorks(): PlannedWork[] {
    return this._work().plannedWorks;
  }

  @computed
  get id() {
    return this._editableStep.id;
  }

  @computed
  get isNew(): boolean {
    return this._step == null;
  }

  @computed
  get canSave(): boolean {
    const plannedWorksSelectionIsChanged = !arraysAreEqual(
      this._initialSelectedPlannedWorkIds,
      this.selectedPlannedWorkIds
    );
    return (this._editableStep.hasChanges || plannedWorksSelectionIsChanged) && this.title.length > 0;
  }

  @computed
  get canDelete(): boolean {
    return this._onDelete != null;
  }

  @computed
  get isCompleted(): boolean {
    return this._editableStep.isCompleted;
  }

  set isCompleted(value: boolean) {
    this._editableStep.isCompleted = value;
  }

  @computed
  get title(): string {
    return this._editableStep.title;
  }

  set title(value: string) {
    this._editableStep.title = value;
  }

  @computed
  get duration(): number {
    return this._editableStep.duration;
  }

  set duration(value: number) {
    this._editableStep.duration = value;
  }

  @computed
  get availablePlannedWorks(): WorkStepEditViewModelPlannedWorks {
    let upcoming: PlannedWork[] = [];
    let past: PlannedWork[] = [];

    this.allAvailablePlannedWorks.forEach((pw) => {
      if (plannedWorkIsCompleted(pw, this._dateService)) {
        past.push(pw);
      } else {
        upcoming.push(pw);
      }
    });

    upcoming = orderBy(upcoming, (pw) => pw.timeSlot?.startTime?.toDate(), 'asc');
    past = orderBy(past, (pw) => pw.timeSlot?.endTime?.toDate(), 'desc');
    return { upcoming, past };
  }

  @computed
  get selectedPlannedWorkIds(): string[] {
    return Array.from(this._selectedPlannedWorkIds);
  }

  delete() {
    this._onDelete?.();
  }

  cancel() {
    this._onCancel();
  }

  save() {
    this._onSave(this._editableStep.updatedModel, this.selectedPlannedWorkIds);
  }

  @action
  toggleSelectionOfPlannedWork(id: string) {
    if (this._selectedPlannedWorkIds.has(id)) {
      this._selectedPlannedWorkIds.delete(id);
    } else {
      this._selectedPlannedWorkIds.add(id);
    }
  }
}
