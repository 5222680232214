import { Day, DayOfWeek, ScheduleCycleInfo, SuggestedPeriod, TimeOfDay, UserDashboardInfo } from '@/models';
import { PlannerSchedules } from '@buf/studyo_studyo-today-planners.connectrpc_es/studyo/today/planners/v1/planner_schedules_connect';
import { Planners } from '@buf/studyo_studyo-today-planners.connectrpc_es/studyo/today/planners/v1/planners_connect';
import { Calendar } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_pb';
import { PartialActivitySchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/partial_activity_schedule_pb';
import { PeriodScheduleCreationMode } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_creation_mode_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { Schedules } from '@buf/studyo_studyo-today-schedules.connectrpc_es/studyo/today/schedules/v1/schedules_connect';
import { VolatileSchedules } from '@buf/studyo_studyo-today-schedules.connectrpc_es/studyo/today/schedules/v1/volatile_schedules_connect';
import { SchoolSchedules } from '@buf/studyo_studyo-today-schools.connectrpc_es/studyo/today/schools/v1/school_schedules_connect';
import { Schools } from '@buf/studyo_studyo-today-schools.connectrpc_es/studyo/today/schools/v1/schools_connect';
import { ScheduleCycleTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppScheduleCycleTransportService extends AppBaseTransportService implements ScheduleCycleTransportService {
  async getScheduleCycles(ids: string[]): Promise<ScheduleCycleInfo[]> {
    return await Promise.all(
      ids.map((id) => this.getScheduleCycle(id, { case: 'allowDraftScheduleCycle', value: true }))
    );
  }

  async getScheduleCycle(
    scheduleCycleId: string,
    draft: { case: 'draftSessionId'; value: string } | { case: 'allowDraftScheduleCycle'; value: boolean }
  ): Promise<ScheduleCycleInfo> {
    const response = await this.performReadRequest('getScheduleCycle', Schedules, Schedules.methods.getScheduleCycle, {
      scheduleCycleId,
      draft
    });

    return {
      scheduleCycle: response.scheduleCycle!,
      draftSessionId: response.draftSessionId,
      availableDraftSessionId: response.availableDraftSessionId
    };
  }

  async getSuggestedPeriods(
    periodScheduleId: string,
    time: TimeOfDay,
    scheduleCycle: ScheduleCycle
  ): Promise<SuggestedPeriod> {
    const response = await this.performReadRequest(
      'getSuggestedPeriods',
      VolatileSchedules,
      VolatileSchedules.methods.suggestPeriods,
      { where: { case: 'periodScheduleId', value: periodScheduleId }, time, scheduleCycle }
    );

    return { period: response.suggestedPeriod!, otherPeriods: response.otherPeriods };
  }

  async getVolatileCalendar(minimumDay: Day, maximumDay: Day, scheduleCycles: ScheduleCycle[]): Promise<Calendar> {
    const response = await this.performReadRequest(
      'getVolatileCalendar',
      VolatileSchedules,
      VolatileSchedules.methods.generateCalendar,
      { minimumDay, maximumDay, scheduleCycles, activities: [] }
    );

    return response.calendar!;
  }

  async createOrUpdateCycleDayActivity(
    scheduleCycle: ScheduleCycle,
    cycleDay: number,
    label: string,
    startTime: TimeOfDay,
    endTime: TimeOfDay,
    originalPeriodId: string,
    activitySchedules: PartialActivitySchedule[]
  ): Promise<ScheduleCycle> {
    const response = await this.performReadRequest(
      'createOrUpdateCycleDayActivities',
      VolatileSchedules,
      VolatileSchedules.methods.createOrUpdateCycleDayActivities,
      { scheduleCycle, cycleDay, label, startTime, endTime, originalPeriodId, activitySchedules }
    );

    return response.scheduleCycle!;
  }

  async createScheduleCycle(
    name: string,
    startDay: Day,
    endDay: Day,
    cycleDayCount: number,
    isDayOfWeekAligned: boolean,
    firstCycleDay: number,
    terms: Term[],
    activityDaysOfWeek: DayOfWeek[],
    periodScheduleCreationMode: PeriodScheduleCreationMode
  ): Promise<ScheduleCycle> {
    const response = await this.performReadRequest(
      'createScheduleCycle',
      VolatileSchedules,
      VolatileSchedules.methods.createSchoolScheduleCycle,
      {
        name,
        isDayOfWeekAligned,
        cycleDayCount,
        firstCycleDay,
        terms,
        startDay,
        endDay,
        activityDaysOfWeek,
        periodScheduleCreationMode
      }
    );

    return response.scheduleCycle!;
  }

  async createScheduleCycleCopy(
    scheduleCycle: ScheduleCycle,
    name: string,
    startDay: Day,
    endDay: Day,
    shouldKeepTerms: boolean,
    shouldKeepPeriodSchedules: boolean,
    shouldKeepSpecialDays: boolean,
    shouldKeepSpecialDayOccurrences: boolean,
    shouldKeepActivitySchedules: boolean,
    shouldKeepActivityScheduleExceptions: boolean
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest(
      'createScheduleCycleCopy',
      VolatileSchedules,
      VolatileSchedules.methods.createScheduleCycleCopy,
      {
        scheduleCycle,
        name,
        startDay,
        endDay,
        shouldKeepTerms,
        shouldKeepPeriodSchedules,
        shouldKeepSpecialDays,
        shouldKeepSpecialDayOccurrences,
        shouldKeepActivitySchedules,
        shouldKeepActivityScheduleExceptions
      }
    );

    return response.scheduleCycle!;
  }

  async attachScheduleCycleToUserDashboard(
    scheduleCycleId: string,
    userDashboard: UserDashboardInfo
  ): Promise<string[]> {
    if (userDashboard.kind === 'school') {
      const response = await this.performWriteRequest(
        'attachScheduleCycleToSchool',
        Schools,
        Schools.methods.attachScheduleCycleToSchool,
        { scheduleCycleId, schoolId: userDashboard.id }
      );

      return response.scheduleCycleIds;
    } else {
      const response = await this.performWriteRequest(
        'attachScheduleCycleToPlanner',
        Planners,
        Planners.methods.attachScheduleCycleToPlanner,
        { scheduleCycleId, plannerId: userDashboard.id }
      );

      return response.scheduleCycleIds;
    }
  }

  async detachScheduleCycleFromUserDashboard(
    scheduleCycleId: string,
    userDashboard: UserDashboardInfo
  ): Promise<string[]> {
    if (userDashboard.kind === 'school') {
      const response = await this.performWriteRequest(
        'detachScheduleCycleFromSchool',
        Schools,
        Schools.methods.detachScheduleCycleFromSchool,
        { scheduleCycleId, schoolId: userDashboard.id }
      );

      return response.scheduleCycleIds;
    } else {
      const response = await this.performWriteRequest(
        'detachScheduleCycleFromPlanner',
        Planners,
        Planners.methods.detachScheduleCycleFromPlanner,
        { scheduleCycleId, plannerId: userDashboard.id }
      );

      return response.scheduleCycleIds;
    }
  }

  async saveDraftScheduleCycleForPlanner(
    scheduleCycle: ScheduleCycle,
    draftSessionId: string,
    plannerId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle | undefined;
    hasMoreRecentRevision: boolean;
  }> {
    const response = await this.performWriteRequest(
      'saveDraftScheduleCycleForPlanner',
      PlannerSchedules,
      PlannerSchedules.methods.saveDraftScheduleCycle,
      { scheduleCycle, draftSessionId, plannerId }
    );

    return { scheduleCycle: response.scheduleCycle, hasMoreRecentRevision: response.hasMoreRecentRevision };
  }

  async saveDraftScheduleCycleForSchool(
    scheduleCycle: ScheduleCycle,
    draftSessionId: string,
    schoolId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle | undefined;
    hasMoreRecentRevision: boolean;
  }> {
    const response = await this.performWriteRequest(
      'saveDraftScheduleCycleForSchool',
      SchoolSchedules,
      SchoolSchedules.methods.saveDraftScheduleCycle,
      { scheduleCycle, draftSessionId, schoolId }
    );

    return { scheduleCycle: response.scheduleCycle, hasMoreRecentRevision: response.hasMoreRecentRevision };
  }

  async publishDraftScheduleCycleForPlanner(
    scheduleCycleId: string,
    draftSessionId: string,
    plannerId: string
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest(
      'publishDraftScheduleCycleForPlanner',
      PlannerSchedules,
      PlannerSchedules.methods.publishDraftScheduleCycle,
      { scheduleCycleId, draftSessionId, plannerId }
    );

    return response.scheduleCycle!;
  }

  async publishDraftScheduleCycleForSchool(
    scheduleCycleId: string,
    draftSessionId: string,
    schoolId: string
  ): Promise<{ scheduleCycle: ScheduleCycle; hasDroppedChanges: boolean }> {
    const response = await this.performWriteRequest(
      'publishDraftScheduleCycleForSchool',
      SchoolSchedules,
      SchoolSchedules.methods.publishDraftScheduleCycle,
      { scheduleCycleId, draftSessionId, schoolId }
    );

    return { scheduleCycle: response.scheduleCycle!, hasDroppedChanges: response.hasDroppedChanges };
  }

  async publishInitialScheduleCycleForPlanner(scheduleCycle: ScheduleCycle, plannerId: string): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest(
      'publishInitialScheduleCycleForPlanner',
      PlannerSchedules,
      PlannerSchedules.methods.publishInitialScheduleCycle,
      { scheduleCycle, plannerId }
    );

    return response.scheduleCycle!;
  }

  async publishInitialScheduleCycleForSchool(scheduleCycle: ScheduleCycle, schoolId: string): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest(
      'publishInitialScheduleCycleForSchool',
      SchoolSchedules,
      SchoolSchedules.methods.publishInitialScheduleCycle,
      { scheduleCycle, schoolId }
    );

    return response.scheduleCycle!;
  }

  async discardLastDraftScheduleCycleForPlanner(
    scheduleCycleId: string,
    draftSessionId: string,
    plannerId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle;
    isDraft: boolean;
  }> {
    const response = await this.performWriteRequest(
      'discardLastDraftScheduleCycleForPlanner',
      PlannerSchedules,
      PlannerSchedules.methods.discardLastDraftScheduleCycle,
      { scheduleCycleId, draftSessionId, plannerId }
    );

    return { scheduleCycle: response.scheduleCycle!, isDraft: response.isDraft };
  }

  async discardLastDraftScheduleCycleForSchool(
    scheduleCycleId: string,
    draftSessionId: string,
    schoolId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle;
    isDraft: boolean;
  }> {
    const response = await this.performWriteRequest(
      'discardLastDraftScheduleCycleForSchool',
      SchoolSchedules,
      SchoolSchedules.methods.discardLastDraftScheduleCycle,
      { scheduleCycleId, draftSessionId, schoolId }
    );

    return { scheduleCycle: response.scheduleCycle!, isDraft: response.isDraft };
  }

  async discardAllDraftScheduleCyclesForPlanner(
    scheduleCycleId: string,
    draftSessionId: string,
    plannerId: string
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest(
      'discardAllDraftScheduleCyclesForPlanner',
      PlannerSchedules,
      PlannerSchedules.methods.discardAllDraftScheduleCycles,
      { scheduleCycleId, draftSessionId, plannerId }
    );

    return response.scheduleCycle!;
  }

  async discardAllDraftScheduleCyclesForSchool(
    scheduleCycleId: string,
    draftSessionId: string,
    schoolId: string
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest(
      'discardAllDraftScheduleCyclesForSchool',
      SchoolSchedules,
      SchoolSchedules.methods.discardAllDraftScheduleCycles,
      { scheduleCycleId, draftSessionId, schoolId }
    );

    return response.scheduleCycle!;
  }
}
