import { useServices } from '@/hooks';
import { StudentsPlannerSummaryViewModel } from '@/viewmodels';
import EmptyListIcon from '@mui/icons-material/EmojiNatureRounded';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { createRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { EmptyListIndicator, LegendButton } from '../../../utils';
import { SchoolCourseSectionDetailsStudentGridItem } from './SchoolCourseSectionDetailsStudentGridItem';
import { makeSchoolCourseSectionStudentsLegendSections } from './SchoolCourseSectionDetailsUtils';

export interface SchoolCourseSectionDetailsStudentGridProps {
  sx?: SxProps;
  className?: string;
  viewModel: StudentsPlannerSummaryViewModel;
}

export const SchoolCourseSectionDetailsStudentGrid = observer(
  ({ sx = [], className, viewModel }: SchoolCourseSectionDetailsStudentGridProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;

    const studentListRef = createRef<FixedSizeList>();
    const itemWidth = 240;
    const itemHeight = 130;
    const totalWidth = itemWidth * viewModel.students.length;
    const [currentOffset, setCurrentOffset] = useState(0);
    const [currentGridWidth, setCurrentGridWidth] = useState(0);
    const maxOffset = totalWidth - currentGridWidth;

    function skipForward() {
      if (currentOffset >= maxOffset) {
        return;
      }

      const itemsNb = Math.floor(currentGridWidth / itemWidth);
      const newOffset = Math.min(currentOffset + itemsNb * itemWidth, maxOffset);
      studentListRef.current?.scrollTo(newOffset);
    }

    function skipBackward() {
      if (currentOffset <= 0) {
        return;
      }

      const itemsNb = Math.floor(currentGridWidth / itemWidth);
      const newOffset = Math.max(currentOffset - itemsNb * itemWidth, 0);
      studentListRef.current?.scrollTo(newOffset);
    }

    return (
      <Stack sx={sx} className={className}>
        {viewModel.allStudents.length > 0 && (
          <Stack
            spacing={1}
            direction="row"
            sx={{
              alignItems: 'center',
              px: 2
            }}
          >
            <Stack
              spacing={1}
              direction="row"
              sx={{
                alignItems: 'center'
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '500'
                }}
              >
                {strings.detailsStudentsGridTitle}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {'— '}
                {strings.detailsStudentsUsingToday(viewModel.studentsUsingAppCount, viewModel.totalStudentsCount)}
              </Typography>

              <LegendButton
                sections={makeSchoolCourseSectionStudentsLegendSections(localization)}
                isIconOnly
                popoverTitle={strings.detailsStudentsGridLegendTitle}
              />
            </Stack>

            <Box
              sx={{
                flex: 1
              }}
            />

            <Stack
              spacing={0}
              direction="row"
              sx={{
                alignItems: 'center'
              }}
            >
              <IconButton color="inherit" onClick={skipBackward} disabled={currentOffset === 0}>
                <ArrowLeftIcon />
              </IconButton>

              <IconButton
                color="inherit"
                onClick={skipForward}
                disabled={currentOffset === totalWidth - currentGridWidth || totalWidth <= currentGridWidth}
              >
                <ArrowRightIcon />
              </IconButton>
            </Stack>
          </Stack>
        )}
        <Box
          sx={{
            px: 2,
            overflowY: 'visible'
          }}
        >
          {viewModel.students.length > 0 && (
            <Box
              sx={{
                width: '100%',
                overflowY: 'visible'
              }}
            >
              <AutoSizer disableHeight onResize={(size) => setCurrentGridWidth(size.width ?? 0)}>
                {(size) => (
                  <FixedSizeList
                    ref={studentListRef}
                    style={{ overflowY: 'visible' }}
                    itemSize={itemWidth}
                    height={itemHeight}
                    itemCount={viewModel.students.length}
                    width={size.width ?? 0}
                    layout="horizontal"
                    onScroll={(p) => setCurrentOffset(p.scrollOffset)}
                  >
                    {({ style, index }) => {
                      const student = viewModel.students[index];
                      return (
                        <SchoolCourseSectionDetailsStudentGridItem key={student.id} style={style} student={student} />
                      );
                    }}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Box>
          )}

          {viewModel.allStudents.length > 0 && viewModel.students.length === 0 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                p: 2
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontWeight: '500'
                }}
              >
                {strings.detailsStudentsGridNoResults(viewModel.searchText)}
              </Typography>
            </Box>
          )}

          {viewModel.allStudents.length === 0 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2
              }}
            >
              <EmptyListIndicator
                renderIcon={(className) => <EmptyListIcon className={className} />}
                title={strings.detailsNoStudentsTitle}
                subtitle={strings.detailsNoStudentsSubtitle}
              />
            </Box>
          )}
        </Box>
      </Stack>
    );
  }
);
