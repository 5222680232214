import { ServiceContainer } from '@/providers';
import { LocalizationService, RouteService } from '@/services';
import { PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import {
  AppDonePlannerListViewModel,
  AppInboxPlannerListViewModel,
  AppTodayPlannerListViewModel,
  AppUpcomingPlannerListViewModel,
  PlannerListViewModel
} from '../planner';

export interface StudentPlannerViewModel {
  readonly inboxViewModel: PlannerListViewModel;
  readonly todayViewModel: PlannerListViewModel;
  readonly upcomingViewModel: PlannerListViewModel;
  readonly doneViewModel: PlannerListViewModel;
  reloadData(): Promise<void>;
}

export class AppStudentPlannerViewModel implements StudentPlannerViewModel {
  readonly inboxViewModel: PlannerListViewModel;
  readonly todayViewModel: PlannerListViewModel;
  readonly upcomingViewModel: PlannerListViewModel;
  readonly doneViewModel: PlannerListViewModel;

  constructor(
    private readonly _plannerId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _routeService: RouteService = ServiceContainer.services.route
  ) {
    this.inboxViewModel = new AppInboxPlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );

    this.todayViewModel = new AppTodayPlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );

    this.upcomingViewModel = new AppUpcomingPlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );

    this.doneViewModel = new AppDonePlannerListViewModel(
      this._plannerId,
      this._userStore,
      this._plannerStore,
      this._workStore,
      this._localization,
      this._routeService
    );
  }

  async reloadData(): Promise<void> {
    if (this._plannerId.length > 0) {
      await this._plannerStore.getPlannerContentsInPlanner(this._plannerId).fetch(true);
    }
  }
}
