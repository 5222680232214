import { privacyPolicyEnURL, privacyPolicyFrURL, termsAndConditionEnURL, termsAndConditionFrURL } from '@/config';

export interface WelcomeStrings {
  readonly appBarSignInButtonTitle: string;
  readonly appBarSignUpButtonTitle: string;
  readonly existingAccountMessage: string;
  readonly featuresDescription: string;
  readonly mainSignInButtonTitle: string;
  readonly mainSignUpButtonTitle: string;
  readonly title: string;
  readonly learnMoreButtonLabel: string;
  readonly learnMoreUrl: string;
  readonly cookiePreferencesLabel: string;
  readonly privacyPolicyAlertText: string;
  readonly privacyPolicyConfirmMessage: (linkColor: string) => string;
}

export const EnglishWelcomeStrings: WelcomeStrings = {
  appBarSignInButtonTitle: 'Log in',
  appBarSignUpButtonTitle: 'Sign up',
  existingAccountMessage: 'Already have an account?',
  featuresDescription:
    'Students plan and break down assignments.\nTeachers can track organization skills.\nParents avoid the homework scavenger hunt.\nWorks with Google Classroom (optional).',
  mainSignInButtonTitle: 'Log in',
  mainSignUpButtonTitle: 'Create my account',
  title: 'The planner that helps students get things done',
  learnMoreButtonLabel: 'Learn more',
  learnMoreUrl: 'https://studyo.co/en/school-planner/studyo-go',
  cookiePreferencesLabel: 'Manage cookie consent',
  privacyPolicyAlertText: 'You must agree to the terms in order to create an account:',
  privacyPolicyConfirmMessage: (linkColor) =>
    `I have read and agree to Studyo's <a href=${privacyPolicyEnURL} target="_blank" style="color: ${linkColor}">privacy policy</a> and <a href=${termsAndConditionEnURL} target="_blank" rel="noreferrer" style="color: ${linkColor}">terms of service</a>.`
};

export const FrenchWelcomeStrings: WelcomeStrings = {
  appBarSignInButtonTitle: 'Connexion',
  appBarSignUpButtonTitle: 'Créer un compte',
  existingAccountMessage: 'Déjà un compte?',
  featuresDescription:
    'Les élèves planifient et morcèlent leurs travaux.\nLes enseignants suivent l’organisation des élèves.\nLes parents évitent la chasse aux devoirs.\nFonctionne avec Google Classroom (optionnel).',
  mainSignInButtonTitle: 'Se connecter',
  mainSignUpButtonTitle: 'Créer mon compte',
  title: 'Le planificateur scolaire qui aide les élèves à s’organiser',
  learnMoreButtonLabel: 'En apprendre plus',
  learnMoreUrl: 'https://studyo.co/fr/school-planner/studyo-go',
  cookiePreferencesLabel: 'Gérer le consentement des témoins',
  privacyPolicyAlertText: 'Vous devez consentir à nos politiques pour créer un compte:',
  privacyPolicyConfirmMessage: (linkColor) =>
    `J’ai lu et j’accepte la <a href=${privacyPolicyFrURL} target="_blank" style="color: ${linkColor}">politique de confidentialité</a> et les <a href=${termsAndConditionFrURL} target="_blank" rel="noreferrer" style="color: ${linkColor}">conditions d’utilisation</a> de Studyo.`
};
