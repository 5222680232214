import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { CSSProperties } from 'react';

export function getGradientClassName(theme: Theme, withPadding?: boolean): string {
  const style: CSSProperties = {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  };

  if (withPadding) {
    style.padding = theme.spacing(1, 2);
  }

  return css({ ...style });
}
