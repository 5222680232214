import { Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface ChoicePickerLabelProps {
  /**
   * The label. Optional.
   */
  label?: string | ReactNode;
  className?: string;
}

export const ChoicePickerLabel = ({ label, className }: ChoicePickerLabelProps) => {
  return typeof label === 'string' ? (
    <Typography
      variant="subtitle2"
      className={className}
      sx={{
        fontWeight: 600
      }}
    >
      {label}
    </Typography>
  ) : (
    (label ?? null)
  );
};
