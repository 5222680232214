export function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key];
}

export function setProperty<T extends Record<string, string>, K extends keyof T, V extends T[K]>(
  obj: T,
  key: K,
  value: V
) {
  obj[key] = value;
}
