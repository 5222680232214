import { ServicesContextValues } from '@/contexts';
import { computed, makeObservable } from 'mobx';
import { AppViewModelProvider } from './AppViewModelProvider';
import { AppServiceProviderLoader, ServiceProviderLoader } from './ServiceProvider';

export class ServiceContainer {
  private static _instance: ServiceContainer | undefined;

  static get instance(): ServiceContainer {
    if (this._instance == null) {
      this._instance = new ServiceContainer();
    }

    return this._instance;
  }

  static get services(): ServicesContextValues {
    return this.instance.services;
  }

  private readonly _serviceProviderLoader: ServiceProviderLoader = new AppServiceProviderLoader();

  private constructor() {
    makeObservable(this);
  }

  @computed
  get isLoaded(): boolean {
    return this._serviceProviderLoader.isLoaded;
  }

  @computed
  get services(): ServicesContextValues {
    if (!this.isLoaded) {
      throw new Error('Wait for services to be loaded before accessing them.');
    }

    const viewModels = new AppViewModelProvider(this._serviceProviderLoader.serviceProvider!);
    return { ...this._serviceProviderLoader.serviceProvider!, viewModels };
  }
}
