import mixpanel from 'mixpanel-browser';
import { autorun } from 'mobx';
import { EnvironmentService, MixpanelService, UserService } from '../contracts';

export class AppMixpanelService implements MixpanelService {
  constructor(
    environment: EnvironmentService,
    private readonly _user: UserService
  ) {
    mixpanel.init(environment.mixpanelToken);

    autorun(() => {
      const currentUser = this._user.currentUser;
      if (currentUser != null) {
        mixpanel.identify(currentUser.userId);
      }
    });
  }

  trackEvent(eventName: string, metadata?: Record<string, unknown>): void {
    mixpanel.track(eventName, { ...metadata });
  }
}
