import { useServices } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../../utils';

export interface StudentDetailsUnknownProps {
  sx?: SxProps;
  className?: string;
}

export const StudentDetailsUnknown = observer(({ sx = [], className }: StudentDetailsUnknownProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.schoolCourseSection;

  return (
    <Stack
      className={className}
      sx={[
        {
          p: 3
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          fontWeight: '500'
        }}
      >
        {strings.studentDetailsUnknown}
      </Typography>
    </Stack>
  );
});
