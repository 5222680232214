import { Analytics, getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { EnvironmentService, FirebaseContract, LocalizationService } from '../contracts';
import { BaseFirebaseService } from './BaseFirebaseService';

export class FirebaseService extends BaseFirebaseService implements FirebaseContract {
  private readonly _analytics: Analytics;

  constructor(environment: EnvironmentService, localization: LocalizationService) {
    super(environment, localization);
    this._analytics = getAnalytics();
  }

  setUser(id: string, properties?: Record<string, string>) {
    setUserId(this._analytics, id);
    setUserProperties(this._analytics, properties ?? {});
  }

  resetUser() {
    setUserId(this._analytics, '');
    setUserProperties(this._analytics, {});
  }

  logError(message: string, isFatal?: boolean) {
    logEvent(this._analytics, 'exception', { description: message, fatal: isFatal });
  }
}
