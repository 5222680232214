import { DateService } from '@/services';
import { Locale } from '@/utils';
import { formatDistanceStrict, formatDuration, formatRelative, intervalToDuration, isSameDay, subDays } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

export interface DateStrings {
  readonly calendarDateFormat: (date: Date, dateService: DateService) => string;
  readonly calendarDateTimeFormat: (date: Date, dateService: DateService) => string;
  readonly shortCalendarDateFormat: (date: Date, dateService: DateService, withPrefix?: boolean) => string;
  readonly shortDateFormat: (date: Date, dateService: DateService) => string;
  readonly durationFormat: (duration: number, separator?: string) => string;
  readonly relativeDateFormat: (date: Date, dateService: DateService, locale: Locale) => string;
  readonly shortDurationFormat: (duration: number, separator?: string) => string;
  readonly numericDateFormat: string;
  readonly shortTimeFormat: string;
  readonly mediumTimeFormat: string;
}

export const EnglishDateStrings: DateStrings = {
  calendarDateFormat: (date, dateService) => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: 'PPPP',
      yesterday: `'Yesterday' PPP`,
      today: `'Today' PPP`,
      tomorrow: `'Tomorrow' PPP`,
      nextWeek: 'PPPP',
      other: 'PPPP'
    };

    return formatRelative(date, dateService.now, {
      locale: { ...enUS, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  calendarDateTimeFormat: (date, dateService) => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: 'PPPPp',
      yesterday: `'Yesterday' PPPp`,
      today: `'Today' PPPp`,
      tomorrow: `'Tomorrow' PPPp`,
      nextWeek: 'PPPPp',
      other: 'PPPPp'
    };

    return formatRelative(date, dateService.now, {
      locale: { ...enUS, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  shortCalendarDateFormat: (date, dateService) => {
    const baseFormat = 'do';
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: `eeee, ${baseFormat}`,
      yesterday: "'Yesterday'",
      today: "'Today'",
      tomorrow: "'Tomorrow'",
      nextWeek: `eeee ${baseFormat}`,
      other: `eeee ${baseFormat}`
    };

    return formatRelative(date, dateService.now, {
      locale: { ...enUS, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  shortDateFormat: (date, dateService, format = 'PP') => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: format,
      yesterday: "'Yesterday'",
      today: "'Today'",
      tomorrow: "'Tomorrow'",
      nextWeek: format,
      other: format
    };

    return formatRelative(date, dateService.now, {
      locale: { ...enUS, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  durationFormat: (duration) => formatDuration(intervalToDuration({ start: 0, end: duration * 60 * 1_000 })),
  relativeDateFormat: (date, dateService: DateService) =>
    isSameDay(date, dateService.now)
      ? 'today'
      : isSameDay(date, subDays(dateService.now, 1))
        ? 'yesterday'
        : formatDistanceStrict(date, dateService.now, {
            addSuffix: true,
            roundingMethod: 'floor'
          }),
  shortDurationFormat: (duration) => {
    const formatDistanceLocale: Record<string, string> = {
      xSeconds: '{{count}} sec',
      xMinutes: '{{count}} min',
      xHours: '{{count}} h',
      xDays: '{{count}} d',
      xMonths: '{{count}} mo',
      xYears: '{{count}} yr'
    };

    return formatDuration(intervalToDuration({ start: 0, end: duration * 60 * 1_000 }), {
      locale: {
        ...enUS,
        formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', `${count}`)
      }
    });
  },
  numericDateFormat: 'P',
  shortTimeFormat: 'h:mm a',
  mediumTimeFormat: 'hh:mm a'
};

export const FrenchDateStrings: DateStrings = {
  calendarDateFormat: (date, dateService) => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: 'PPPP',
      yesterday: `'Hier' PPP`,
      today: `'Aujourd’hui' PPP`,
      tomorrow: `'Demain' PPP`,
      nextWeek: 'PPPP',
      other: 'PPPP'
    };

    return formatRelative(date, dateService.now, {
      locale: { ...fr, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  calendarDateTimeFormat: (date, dateService) => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: 'PPPPp',
      yesterday: `'Hier' PPPp`,
      today: `'Aujourd’hui' PPPp`,
      tomorrow: `'Demain' PPPp`,
      nextWeek: 'PPPPp',
      other: 'PPPPp'
    };

    return formatRelative(date, dateService.now, {
      locale: { ...fr, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  shortCalendarDateFormat: (date, dateService) => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: `EEEE d`,
      yesterday: "'Hier'",
      today: "'Aujourd’hui'",
      tomorrow: "'Demain'",
      nextWeek: `EEEE d`,
      other: `EEEE d`
    };

    return formatRelative(date, dateService.now, {
      locale: { ...fr, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  shortDateFormat: (date, dateService, format = 'PP') => {
    const formatRelativeLocale: Record<string, string> = {
      lastWeek: format,
      yesterday: "'Hier'",
      today: "'Aujourd’hui'",
      tomorrow: "'Demain'",
      nextWeek: format,
      other: format
    };

    return formatRelative(date, dateService.now, {
      locale: { ...fr, formatRelative: (token) => formatRelativeLocale[token] }
    });
  },
  durationFormat: (duration) => formatDuration(intervalToDuration({ start: 0, end: duration * 60 * 1_000 })),
  relativeDateFormat: (date, dateService: DateService) =>
    isSameDay(date, dateService.now)
      ? 'aujourd’hui'
      : isSameDay(date, subDays(dateService.now, 1))
        ? 'hier'
        : formatDistanceStrict(date, dateService.now, {
            addSuffix: true,
            roundingMethod: 'floor'
          }),
  shortDurationFormat: (duration) => {
    const formatDistanceLocale: Record<string, string> = {
      xSeconds: '{{count}} sec',
      xMinutes: '{{count}} min',
      xHours: '{{count}} h',
      xDays: '{{count}} j',
      xMonths: '{{count}} mo',
      xYears: '{{count}} an'
    };

    return formatDuration(intervalToDuration({ start: 0, end: duration * 60 * 1_000 }), {
      locale: { ...fr, formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', `${count}`) }
    });
  },
  numericDateFormat: 'P',
  shortTimeFormat: 'H:mm',
  mediumTimeFormat: 'HH:mm'
};
