import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';

export type SchoolKindsExplanationDialogViewModel = StaticDialogViewModel;

export class AppSchoolKindsExplanationDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements SchoolKindsExplanationDialogViewModel
{
  constructor(onDismiss: () => Promise<void>) {
    super(onDismiss);
  }
}
