import { ServiceContainer } from '@/providers';
import LocalizedStrings from '@/resources/strings';
import { AnalyticsService, AuthenticationService, IntercomService, LocalizationService, UserService } from '@/services';
import { ConnectError } from '@connectrpc/connect';
import { captureException } from '@sentry/react';
import { computed, makeObservable } from 'mobx';

export interface ServerErrorScreenViewModel {
  readonly traceId: string | undefined;
  logError(): void;
  contactSupport(): void;
  retry(): void;
  signOut(): Promise<void>;
}

export class AppServerErrorScreenViewModel implements ServerErrorScreenViewModel {
  private _errorId: string | undefined;

  constructor(
    private readonly _error: Error,
    private readonly _intercom: IntercomService = ServiceContainer.services.intercom,
    private readonly _analytics: AnalyticsService = ServiceContainer.services.analytics,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _authentication: AuthenticationService = ServiceContainer.services.authentication,
    private readonly _user: UserService = ServiceContainer.services.user
  ) {
    makeObservable(this);
  }

  @computed
  private get errorMessage(): string {
    return this._error.message || '(no message)';
  }

  @computed
  get traceId(): string | undefined {
    if (this._error instanceof ConnectError) {
      return this._error.metadata.get('X-Today-Traceid') ?? undefined;
    }

    return undefined;
  }

  logError() {
    console.error(this._error);
    this._analytics.logError(`Server error: ${this.errorMessage}`, true);
    captureException(this._error);
  }

  contactSupport() {
    const strings = this._localization.localizedStrings.utils;

    const body = strings.errorDetailsMessage + `%0D%0A[${this._errorId}: ${this.errorMessage}]`;
    this._analytics.logHelpEvent('menu');
    window.open(`mailto:support@studyo.co?subject=${LocalizedStrings.utils.supportSubject()}&body=${body}`, '_blank');
  }

  retry() {
    window.location.reload();
  }

  async signOut() {
    await this._authentication.signOut();
  }
}
