import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable } from 'mobx';
import { ConnectedAppKind } from '../../models';
import { ServiceContainer } from '../../providers';
import {
  ClassroomConnectedAppService,
  ConnectedAppService,
  ConnectedAppsService,
  GoogleCalendarConnectedAppService,
  LocalizationService
} from '../../services';
import { PlannerDataStore, UserDataStore } from '../../stores';
import { ConnectedAppUserDashboardViewModel } from './ConnectedAppUserDashboardViewModel';
import { AppClassroomConnectedAppViewModel } from './classroom';
import { AppGoogleCalendarConnectedAppViewModel } from './googleCalendar';

export interface ConnectedAppViewModel {
  readonly kind: ConnectedAppKind;
  userDashboards: ConnectedAppUserDashboardViewModel[];
}

export class AppConnectedAppViewModel implements ConnectedAppViewModel {
  constructor(
    public readonly kind: ConnectedAppKind,
    private readonly _plannerId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);
  }

  @computed
  private get isStudent(): boolean {
    return this._userStore.user.persona === UserPersona.STUDENT;
  }

  @computed
  private get services(): ConnectedAppService<string>[] {
    const services = this._connectedApps.getAllServicesForPlanner(this._plannerId, this.kind);
    const isStudent = this.isStudent;

    const hasPlannerSchoolServices = services.some(
      (s) => s.userDashboard.case === 'plannerSchool' && (s.isConnected || !isStudent)
    );

    return services.filter((s) => {
      if (s.isConnected) {
        return true;
      }

      switch (s.userDashboard.case) {
        case 'plannerSchool':
          // We do not show connection to school if user is a student
          return !isStudent;
        case 'school':
          // Only showing if connected or has warning (shown as "legacy" connection)
          return s.hasWarning;
        case 'planner':
          // If a 'plannerSchool' connection is possible, we only show if its already connected.
          return !hasPlannerSchoolServices;
      }
    });
  }

  @computed
  get userDashboards(): ConnectedAppUserDashboardViewModel[] {
    return this.services.map((service) => {
      switch (service.kind) {
        case 'classroom':
          return new AppClassroomConnectedAppViewModel(
            service as ClassroomConnectedAppService,
            this._plannerId,
            this._plannerStore,
            this._localization
          );

        case 'googleCalendar':
          return new AppGoogleCalendarConnectedAppViewModel(
            service as GoogleCalendarConnectedAppService,
            this._plannerId,
            this._plannerStore
          );
      }
    });
  }
}
