import { dayToDate, getPrimaryColorForSymbols, ScheduleSymbolKind } from '@/models';
import { UserDashboardCalendarDatePickerDayInfo } from '@/viewmodels';
import { alpha, Box, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isToday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MultilineTooltipIconTitle } from '../../../utils';
import { InlineScheduleSymbolView, ScheduleSymbolStack } from '../shared';

export interface UserDashboardCalendarDatePickerDayProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  dayInfo: UserDashboardCalendarDatePickerDayInfo;
  isCurrentMonth: boolean;
  canBeSelected: boolean;
  onSelect?: (day: Date) => void;
}

export const UserDashboardCalendarDatePickerDay = observer(
  ({
    sx = [],
    className,
    date,
    dayInfo,
    isCurrentMonth,
    canBeSelected,
    onSelect
  }: UserDashboardCalendarDatePickerDayProps) => {
    const theme = useTheme();
    const { day, cycleDayName, annotations, isDisabled } = dayInfo;
    let displayedAnnotations: { kind: ScheduleSymbolKind; color: string }[] = annotations
      .filter((a) => a.symbol.startsWith(':'))
      .map((a) => ({ kind: a.symbol as ScheduleSymbolKind, color: a.color }));

    const dateIsToday = isToday(dayToDate(day));
    if (dateIsToday) {
      displayedAnnotations = [{ kind: ':circle:', color: theme.palette.error.main }];
    }

    let dayNumberTextColor = theme.palette.text.primary;

    if (displayedAnnotations.length > 0 && !dateIsToday) {
      dayNumberTextColor = theme.palette.common.white;
    }

    if (dateIsToday) {
      dayNumberTextColor = theme.palette.getContrastText(theme.palette.error.main);
    } else if (displayedAnnotations.length > 0) {
      const symbolColor = getPrimaryColorForSymbols(displayedAnnotations);

      if (symbolColor.length > 0) {
        dayNumberTextColor = theme.palette.getContrastText(symbolColor);
      }
    } else if (isDisabled) {
      dayNumberTextColor = theme.palette.text.disabled;
    } else if (!isCurrentMonth) {
      dayNumberTextColor = theme.palette.text.secondary;
    }

    const isClickEnabled = canBeSelected && !isDisabled;

    return (
      <Tooltip
        title={
          annotations.length > 0 ? (
            <MultilineTooltipIconTitle
              lines={annotations.map((a) => ({
                title: a.title,
                icon: <InlineScheduleSymbolView symbol={a.symbol} color={a.color} />
              }))}
            />
          ) : (
            ''
          )
        }
        disableInteractive
      >
        <Box
          className={className}
          sx={{
            ...sx,
            display: 'flex',
            flexDirection: 'column',
            width: 32,
            height: 45,
            overflow: 'visible'
          }}
          onClick={isClickEnabled && onSelect ? () => onSelect(date) : undefined}
        >
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'visible',
              position: 'relative',
              width: 32,
              height: 32,
              cursor: isClickEnabled ? 'pointer' : undefined,
              borderRadius: 0.5,
              backgroundColor: dayInfo.isSelected ? alpha(theme.palette.primary.main, 0.2) : undefined,

              '&:hover': isClickEnabled
                ? {
                    backgroundColor: dayInfo.isSelected
                      ? alpha(theme.palette.primary.main, 0.4)
                      : theme.palette.action.hover
                  }
                : undefined
            }}
          >
            <Box sx={{ width: 28, height: 28, position: 'absolute', top: 2, left: 2, right: 2, zIndex: 1_000 }}>
              <ScheduleSymbolStack symbols={displayedAnnotations} size={28} />
            </Box>

            <Typography
              variant="subtitle2"
              sx={{
                flex: 1,
                color: dayNumberTextColor,
                userSelect: 'none',
                textAlign: 'center',
                zIndex: 1_001
              }}
            >
              {day.day}
            </Typography>

            {cycleDayName && (
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary,
                  position: 'absolute',
                  left: 32,
                  top: 2,
                  lineHeight: '14px',
                  fontSize: 10
                }}
              >
                {cycleDayName}
              </Typography>
            )}
          </Box>

          {dayInfo.isHighlighted && (
            <Box
              sx={{
                width: 5,
                height: 5,
                mb: 0.5,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                alignSelf: 'center'
              }}
            />
          )}
        </Box>
      </Tooltip>
    );
  }
);
