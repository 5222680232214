import { ErrorRounded } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDayStateIndicator } from './UserDashboardCalendarDayStateIndicator';

export interface UserDashboardCalendarDayErrorIndicatorProps {
  sx?: SxProps;
  className?: string;
  retry: () => Promise<void>;
}

export const UserDashboardCalendarDayErrorIndicator = observer(
  ({ sx = [], className, retry }: UserDashboardCalendarDayErrorIndicatorProps) => {
    const strings = LocalizedStrings.calendar.day;

    return (
      <UserDashboardCalendarDayStateIndicator
        sx={sx}
        className={className}
        title={strings.dataFetchErrorMessage()}
        renderIcon={(size, color) => <ErrorRounded sx={{ width: size, height: size, color }} />}
        action={{ title: strings.dataFetchErrorRetryButtonTitle(), onClick: () => retry() }}
      />
    );
  }
);
