import { DayAnnotation } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/day_annotation_pb';
import { useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { chunk, times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ScheduleCycleKind, titleForCycleDay } from '../../../../../viewmodels';
import { Droppable } from '../../../../utils';
import { ScheduleCycleSpecialDaysTable } from './ScheduleCycleSpecialDaysTable';
import { ScheduleCycleSpecialDaysTableCell } from './ScheduleCycleSpecialDaysTableCell';

export interface ScheduleCycleSpecialDaysCycleDaysTableProps {
  sx?: SxProps;
  className?: string;
  cycleDayCount: number;
  scheduleCycleKind: ScheduleCycleKind;
  selectedCycleDay?: number;
  dayAnnotationsForCycleDay: (cycleDay: number) => DayAnnotation[];
  onCycleDaySelection: (cycleDay: number) => void;
  cycleDayNames: string[];
}

export const ScheduleCycleSpecialDaysCycleDaysTable = observer(
  ({
    sx = [],
    className,
    cycleDayCount,
    selectedCycleDay,
    dayAnnotationsForCycleDay,
    onCycleDaySelection,
    scheduleCycleKind,
    cycleDayNames
  }: ScheduleCycleSpecialDaysCycleDaysTableProps) => {
    const cycleDaysRows = chunk(times(cycleDayCount), 7);
    const theme = useTheme();

    return (
      <ScheduleCycleSpecialDaysTable sx={sx} className={className}>
        <tbody>
          {cycleDaysRows.map((row, index) => (
            <tr key={`cycle-day-row-${index}`}>
              {row.map((cycleDayIndex) => {
                const cycleDay = cycleDayIndex + 1;
                const label = titleForCycleDay(cycleDay, scheduleCycleKind, 'short', false, cycleDayNames);
                const annotations = dayAnnotationsForCycleDay(cycleDay);

                return (
                  <Droppable
                    key={`cycle-day-cell-${cycleDay}`}
                    id={`cycleDay/${cycleDay}`}
                    acceptedTypes={['special-day']}
                  >
                    {(setNodeRef, isOver) => (
                      <td
                        ref={setNodeRef}
                        className={clsx(selectedCycleDay === cycleDay && 'selected')}
                        style={{
                          borderStyle: 'solid',
                          borderWidth: 2,
                          borderColor: isOver ? theme.palette.primary.main : 'transparent'
                        }}
                      >
                        <ScheduleCycleSpecialDaysTableCell
                          label={label}
                          cycleDayLabel={undefined}
                          onClick={() => onCycleDaySelection(cycleDay)}
                          annotations={annotations}
                        />
                      </td>
                    )}
                  </Droppable>
                );
              })}
              {times(7 - row.length).map((v) => (
                <td key={`empty-cycle-day-${v}`} className="empty" />
              ))}
            </tr>
          ))}
        </tbody>
      </ScheduleCycleSpecialDaysTable>
    );
  }
);
