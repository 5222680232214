import { useServices } from '@/hooks';
import { getOptionalDashConcatenation } from '@/models';
import { UserDashboardCalendarItemState, UserDashboardCalendarPeriodInfo } from '@/viewmodels';
import { Box, Card, CardActionArea, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import strings from '../../../../resources/strings';
import { isSxArray } from '../../../../utils';
import { ColorIndicator } from '../../../lists';
import { MultilineTooltipTitle } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { UserDashboardPeriodInfoPopover, formatCalendarItemTimesLong, formatItemTime } from '../shared';

interface LocationState {
  userDashboardCalendarMonthPeriodViewShowInfo?: string;
}

export interface UserDashboardCalendarMonthPeriodViewProps {
  sx?: SxProps;
  className?: string;
  period: UserDashboardCalendarPeriodInfo;
}

export const UserDashboardCalendarMonthPeriodView = observer(
  ({ sx = [], className, period }: UserDashboardCalendarMonthPeriodViewProps) => {
    const { settings } = useServices();
    const theme = useTheme();
    const color = period.courseSection?.courseSection?.color ?? theme.palette.action.focus;
    const resolvedCourseTitle = getOptionalDashConcatenation(
      period.courseSection?.courseSection?.title ?? strings.calendar.month.periodOccurrenceNoClass(),
      period.courseSection?.courseSection?.section
    );
    const resolvedTitle =
      settings.calendarShowPeriodLabels && period.periodLabel.length > 0
        ? `${period.periodLabel} - ${resolvedCourseTitle}`
        : resolvedCourseTitle;

    const actionAreaRef = useRef<HTMLButtonElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();

    const tooltipItems = [
      resolvedTitle,
      formatCalendarItemTimesLong(period.startTime, period.endTime),
      period.roomName,
      period.ordinal > 0 ? `#${period.ordinal}` : ''
    ];

    return (
      <ContextMenuHandler actions={period.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={<MultilineTooltipTitle lines={tooltipItems.filter((item) => item.length > 0)} />}
            disableInteractive
            disableHoverListener
            open={showTooltip && state.userDashboardCalendarMonthPeriodViewShowInfo == null}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Box
              className={className}
              sx={[
                {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'stretch'
                },
                ...(isSxArray(sx) ? sx : [sx])
              ]}
            >
              <Card
                elevation={0}
                sx={{ height: '100%', width: '100%', backgroundImage: 'none', backgroundColor: 'transparent' }}
              >
                <CardActionArea
                  sx={{ borderRadius: 0.5, px: 0.5 }}
                  ref={actionAreaRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newState: LocationState = {
                      ...state,
                      userDashboardCalendarMonthPeriodViewShowInfo: period.id
                    };
                    navigate(location, { state: newState });
                  }}
                  onContextMenu={contextMenuHandler}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      alignItems: 'center'
                    }}
                  >
                    <ColorIndicator color={color} isRounded sx={{ height: 16 }} />

                    <Typography
                      noWrap
                      color={getColorForTitle(period.state)}
                      sx={{
                        fontSize: 13,
                        flex: 1
                      }}
                    >
                      {resolvedTitle}
                    </Typography>

                    <Typography
                      variant="caption"
                      noWrap
                      sx={{
                        color: theme.palette.text.secondary
                      }}
                    >
                      {formatItemTime(period.startTime)}
                    </Typography>
                  </Stack>
                </CardActionArea>

                {state.userDashboardCalendarMonthPeriodViewShowInfo === period.id && (
                  <UserDashboardPeriodInfoPopover
                    isOpen={true}
                    date={period.date}
                    startTime={period.startTime}
                    endTime={period.endTime}
                    periodLabel={period.periodLabel}
                    courseSectionId={period.courseSection?.courseSection?.id}
                    onClose={() => navigate(-1)}
                    anchorEl={actionAreaRef.current}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left'
                    }}
                  />
                )}
              </Card>
            </Box>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'active':
      return 'primary';
    default:
      return 'textPrimary';
  }
}
