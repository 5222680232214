import { ServiceContainer } from '@/providers';
import { AnalyticsService, UserService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { computed, makeObservable } from 'mobx';
import { AppObservableDataViewModel, ObservableDataViewModel } from '../shared';

export interface BaseSharePlannerViewModel {
  readonly userRelationshipKind: PlannerRelationshipKind;
  readonly targetRelationshipKind: PlannerRelationshipKind;
  readonly sharingInvitationCode: ObservableDataViewModel<string>;
}

export class AppBaseSharePlannerViewModel implements BaseSharePlannerViewModel {
  private readonly _sharingInvitationCodeData: AppObservableDataViewModel<string>;

  constructor(
    readonly targetRelationshipKind: PlannerRelationshipKind,
    private readonly _plannerId: string,
    private readonly _userRelationshipKind: PlannerRelationshipKind | undefined,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    protected readonly _user: UserService = ServiceContainer.services.user,
    protected readonly _analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    makeObservable(this);
    this._sharingInvitationCodeData = new AppObservableDataViewModel(() => this.loadSharingInvitationCode());
  }

  @computed
  get userRelationshipKind(): PlannerRelationshipKind {
    if (this._userRelationshipKind != null) {
      return this._userRelationshipKind;
    }

    const planner = this._userStore.getPlannerForId(this._plannerId);
    const relationship = planner?.relationships.find(
      (r) => r.kind !== PlannerRelationshipKind.CREATOR && r.userId === this._user.currentUser?.userId
    );
    return relationship?.kind ?? PlannerRelationshipKind.UNSPECIFIED;
  }

  @computed
  get sharingInvitationCode(): ObservableDataViewModel<string> {
    return this._sharingInvitationCodeData;
  }

  protected async loadSharingInvitationCode(): Promise<string> {
    void this._analytics.logAdConversionOnce('invite-created');
    return await this._plannerStore.getSharingInvitationCode(
      this._plannerId,
      AccessKind.FULL_ACCESS,
      this.targetRelationshipKind
    );
  }
}
