import { dateToPBDate, Day, dayToString } from '@/models';
import { ServiceContainer } from '@/providers';
import { Loadable, UserDashboardCalendarStore, WorkDataStore } from '@/stores';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { computed, makeObservable, observable } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../../UpdatableViewModel';
import { UserDashboardCalendarDaySpecificDateViewModel } from './UserDashboardCalendarDaySpecificDateViewModel';

export interface UserDashboardCalendarDayViewModel extends UpdatableViewModel {
  readonly hasCalendarSyncError: boolean;
  getViewModelForDay(day: Day): UserDashboardCalendarDaySpecificDateViewModel;
  fetchDays(date: Date, force: boolean): Promise<void>;
}

export abstract class AppUserDashboardCalendarDayViewModel<DayContent>
  extends BaseUpdatableViewModel
  implements UserDashboardCalendarDayViewModel
{
  protected _viewModelByDay = observable.map<string, UserDashboardCalendarDaySpecificDateViewModel>();

  protected constructor(
    protected readonly _dashboardId: string,
    protected readonly _calendarStore: UserDashboardCalendarStore<DayContent>,
    protected readonly _workStore: WorkDataStore = ServiceContainer.services.workStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this._workStore.workIcons];
  }

  @computed
  get hasCalendarSyncError(): boolean {
    return this._calendarStore.error != null;
  }

  getViewModelForDay(day: Day): UserDashboardCalendarDaySpecificDateViewModel {
    const key = dayToString(day);
    const existing = this._viewModelByDay.get(key);

    if (existing != null) {
      return existing;
    }

    const viewModel = this.makeViewModelForDay(day, key);
    this._viewModelByDay.set(key, viewModel);
    return viewModel;
  }

  async fetchDays(date: Date, force: boolean): Promise<void> {
    const startDate = startOfMonth(subMonths(date, 1));
    const endDate = endOfMonth(addMonths(date, 1));
    await this._calendarStore.fetchDays(dateToPBDate(startDate), dateToPBDate(endDate), force);
  }

  protected abstract makeViewModelForDay(day: Day, dayString: string): UserDashboardCalendarDaySpecificDateViewModel;
}
