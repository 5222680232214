import { Stack } from '@mui/material';
import { TablePaginationActions } from './TablePaginationActions';
import { TablePaginationControls } from './TablePaginationControls';
import { TablePaginationPageSizeSelect } from './TablePaginationPageSizeSelect';
import { TableAction } from './TableUtils';

export interface TablePaginationProps {
  actions?: TableAction[];
  showGoToInitialPageAction: boolean;
  initialPage?: number;
  scrollToCurrentInitialRowActionTitle?: string;
}

export function TablePagination({
  actions,
  showGoToInitialPageAction,
  initialPage,
  scrollToCurrentInitialRowActionTitle
}: TablePaginationProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: 'center',
        mx: 2,
        my: 1,
        width: '100%'
      }}
    >
      <TablePaginationActions
        actions={actions}
        showGoToInitialPageAction={showGoToInitialPageAction}
        initialPage={initialPage}
        scrollToCurrentInitialRowActionTitle={scrollToCurrentInitialRowActionTitle}
      />
      <TablePaginationPageSizeSelect />
      <TablePaginationControls />
    </Stack>
  );
}
