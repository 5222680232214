import { PictureSourceKind, PictureUrls } from '@/models';
import { UnsplashTransportService } from '@/services';
import { computed, makeObservable } from 'mobx';
import { Basic } from 'unsplash-js/dist/methods/photos/types';

export interface UnsplashPictureViewModel {
  readonly id: string;
  readonly source: PictureSourceKind;
  readonly urls: PictureUrls;
  readonly alt: string;
  readonly authorUrl: string;
  readonly authorName: string;
  readonly color: string | undefined;

  download(): Promise<void>;
}

export class AppUnsplashPictureViewModel implements UnsplashPictureViewModel {
  readonly source: PictureSourceKind = 'unsplash';

  constructor(
    private readonly _picture: Basic,
    private readonly _unsplash: UnsplashTransportService
  ) {
    makeObservable(this);
  }

  get id(): string {
    return this._picture.id;
  }

  get urls(): PictureUrls {
    const urls = this._picture.urls;
    return {
      large: urls.full,
      original: urls.raw,
      preview: urls.thumb,
      regular: urls.regular,
      small: urls.small
    };
  }

  @computed
  get alt(): string {
    return this._picture.alt_description ?? '';
  }

  @computed
  get authorUrl(): string {
    return `https://unsplash.com/@${this._picture.user.username}?utm_source=today&utm_medium=referral`;
  }

  @computed
  get authorName(): string {
    return this._picture.user.name;
  }

  @computed
  get color(): string | undefined {
    return this._picture.color ?? undefined;
  }

  async download() {
    await this._unsplash.download(this._picture);
  }
}
