import { ConnectedAppKind, ConnectedAppUserDashboard, SyncStatusInfo } from '@/models';
import { notConcurrent } from '@/utils';
import { captureException } from '@sentry/react';
import { isBefore } from 'date-fns';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { AutoSyncService, ConnectedAppService, UserService } from '../../contracts';

export abstract class BaseConnectedAppService<TSyncBlocker extends string>
  implements ConnectedAppService<TSyncBlocker>
{
  @observable protected _syncStatus: SyncStatusInfo<TSyncBlocker> | undefined;
  @observable protected _lastSyncStatus: SyncStatusInfo<TSyncBlocker> | undefined;
  @observable protected _syncFailed = false;
  @observable protected _isSyncing = false;
  @observable private _isFetching = false;

  private get uniqueKey(): string {
    let key = this.kind;

    if (this.userDashboard.case !== 'school') {
      key += `-${this.userDashboard.plannerId}`;
    }

    if (this.userDashboard.case !== 'planner') {
      key += `-${this.userDashboard.schoolId}`;
    }

    return key;
  }

  protected constructor(
    public readonly userDashboard: ConnectedAppUserDashboard,
    protected readonly _user: UserService,
    protected readonly _autoSync: AutoSyncService,
    readonly kind: ConnectedAppKind
  ) {
    makeObservable(this);

    autorun(() => {
      if (!_user.isLoggedIn) {
        this.reset();
      }
    });
  }

  abstract get isConnected(): boolean;

  abstract get warningSyncBlockers(): TSyncBlocker[];

  @computed
  get hasSyncStatus(): boolean {
    return this._syncStatus != null;
  }

  @computed
  get allSyncBlockers(): TSyncBlocker[] {
    return this._syncStatus?.syncBlockers ?? [];
  }

  @computed
  get hasSyncBlocker(): boolean {
    return this.allSyncBlockers.length > 0;
  }

  @computed
  get hasWarning(): boolean {
    return this.warningSyncBlockers.length > 0;
  }

  @computed
  get hasSyncError(): boolean {
    return this._syncFailed;
  }

  @computed
  get hasNewData(): boolean {
    // True when the last sync status `lastSuccessfulSyncTime` is older than the new sync status `lastSuccessfulSyncTime`.
    return (
      this._syncStatus?.lastSuccessfulSyncTime != null &&
      this._lastSyncStatus?.lastSuccessfulSyncTime != null &&
      isBefore(this._lastSyncStatus.lastSuccessfulSyncTime.toDate(), this._syncStatus.lastSuccessfulSyncTime.toDate())
    );
  }

  @computed
  get isSyncing(): boolean {
    // Don't consider as syncing when sync status is currently synchronizing and a sync blocker or error happened
    if (!this._isSyncing && this._syncStatus?.isCurrentlySynchronizing && (this.hasSyncBlocker || this.hasSyncError)) {
      return false;
    }
    return this._isSyncing || (this._syncStatus?.isCurrentlySynchronizing ?? false);
  }

  @computed
  get lastSuccessfulSyncTime(): Date | undefined {
    return this._syncStatus?.lastSuccessfulSyncTime?.toDate();
  }

  @computed
  get isFetching(): boolean {
    return this._isFetching;
  }

  @action
  async refreshSyncStatus() {
    await this._refreshSyncStatus();
  }

  abstract connect(refreshPlannerData: boolean): Promise<void>;

  abstract disconnect(): Promise<void>;

  @action
  async sync() {
    await this._sync();
  }

  @action
  reset() {
    this._syncStatus = undefined;
    this._lastSyncStatus = undefined;
    this._isFetching = false;
  }

  protected abstract getSyncStatus(): Promise<SyncStatusInfo<TSyncBlocker>>;

  protected abstract loadSync(): Promise<void>;

  private _refreshSyncStatus = notConcurrent(async (): Promise<void> => {
    if (this._isFetching) {
      console.warn('The Sync Status is already being refreshed.');
      return;
    }

    try {
      this._isFetching = true;
      this._syncFailed = false;

      const syncStatus = await this.getSyncStatus();
      runInAction(() => {
        this._lastSyncStatus = this._syncStatus;
        this._syncStatus = syncStatus;
      });
    } catch (e) {
      console.error(`Cannot refresh sync status. Details: ${(e as Error).message}`);
      captureException(e);
      runInAction(() => (this._syncFailed = true));
    } finally {
      runInAction(() => (this._isFetching = false));
    }
  });

  private _sync = notConcurrent(async () => {
    try {
      this._isSyncing = true;
      this._syncFailed = false;

      await this.loadSync();
      await this.refreshSyncStatus();

      return new Promise<void>((resolve) => {
        // Refresh sync status every 2 seconds until it has finished syncing
        this._autoSync.addHandler({
          key: `ConnectedAppServiceSync-${this.uniqueKey}`,
          checkInterval: 500,
          threshold: 2000,
          sync: () => void this.refreshSyncStatus(),
          clearWhen: () => !this.isSyncing,
          onClear: resolve,
          ignoreSuspendCount: true
        });
      });
    } catch (e) {
      console.error(`Cannot sync. Details: ${(e as Error).message}`);
      captureException(e);
      runInAction(() => (this._syncFailed = true));
    } finally {
      runInAction(() => (this._isSyncing = false));
    }
  });
}
