import { useServices } from '@/hooks';
import { PlannerListKind } from '@/models';
import { LocalizationService } from '@/services';
import { PlannerPlanningTabsViewModel } from '@/viewmodels';
import { Badge, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface PlannerPlanningListsTabsProps {
  className?: string;
  viewModel: PlannerPlanningTabsViewModel;
  currentListKind: PlannerListKind;
  setCurrentListKind: (kind: PlannerListKind) => void;
}

export const PlannerPlanningListsTabs = observer(
  ({ className, viewModel, currentListKind, setCurrentListKind }: PlannerPlanningListsTabsProps) => {
    const { localization } = useServices();
    const badgeColor = 'primary';

    return (
      <Tabs
        className={className}
        value={currentListKind}
        onChange={(_, value: PlannerListKind) => setCurrentListKind(value)}
        aria-label="work list type"
        variant="scrollable"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab
          value="inbox"
          label={
            <Badge badgeContent={viewModel.inboxBadgeCount} color={badgeColor}>
              {getTitleForListType('inbox', localization)}
            </Badge>
          }
          id={`work-list-tab-type-inbox`}
        />

        <Tab
          value="today"
          label={
            <Badge badgeContent={viewModel.todayBadgeCount} color={badgeColor}>
              {getTitleForListType('today', localization)}
            </Badge>
          }
          id={`work-list-tab-type-today`}
        />

        <Tab
          value="upcoming"
          label={getTitleForListType('upcoming', localization)}
          id={`work-list-tab-type-upcoming`}
        />

        <Tab value="done" label={getTitleForListType('done', localization)} id={`work-list-tab-type-done`} />
      </Tabs>
    );
  }
);

const getTitleForListType = (type: PlannerListKind, localization: LocalizationService) => {
  const strings = localization.localizedStrings.workList;

  switch (type) {
    case 'done':
      return strings.doneTitleSmall;
    case 'inbox':
      return strings.inboxTitleSmall;
    case 'today':
      return strings.todayTitleSmall;
    case 'upcoming':
      return strings.upcomingTitleSmall;
  }
};
