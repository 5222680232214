import i18n from 'translate';

export const AdminTeachersListInviteStrings = {
  addButtonLabel: () => i18n.t('admin.teachersList.invite.addButtonLabel'),
  emailInputLabel: () => i18n.t('admin.teachersList.invite.emailInputLabel'),
  invalidEmailMessage: () => i18n.t('admin.teachersList.invite.invalidEmailMessage'),
  noEmailsLabel: () => i18n.t('admin.teachersList.invite.noEmailsLabel'),
  submitButton: () => i18n.t('admin.teachersList.invite.submitButton'),
  title: () => i18n.t('admin.teachersList.invite.title'),
  unexpectedErrorMessage: () => i18n.t('admin.teachersList.invite.unexpectedErrorMessage')
};

export const AdminTeachersListStrings = {
  editScheduleAction: () => i18n.t('admin.teachersList.editScheduleAction'),
  emailColumnHeader: () => i18n.t('admin.teachersList.emailColumnHeader'),
  emptyButtonTitle: () => i18n.t('admin.teachersList.emptyButtonTitle'),
  emptyTitle: () => i18n.t('admin.teachersList.emptyTitle'),
  hasUserTooltip: () => i18n.t('admin.teachersList.hasUserTooltip'),
  invite: AdminTeachersListInviteStrings,
  inviteActionTitle: () => i18n.t('admin.teachersList.inviteActionTitle'),
  inviteExistingTeachersActionTitle: () => i18n.t('admin.teachersList.inviteExistingTeachersActionTitle'),
  inviteExistingTeachersErrorMessage: () => i18n.t('admin.teachersList.inviteExistingTeachersErrorMessage'),
  inviteExistingTeachersSuccessMessage: () => i18n.t('admin.teachersList.inviteExistingTeachersSuccessMessage'),
  inviteNewTeachersActionTitle: () => i18n.t('admin.teachersList.inviteNewTeachersActionTitle'),
  inviteTeacherActionTitle: () => i18n.t('admin.teachersList.inviteTeacherActionTitle'),
  nameColumnHeader: () => i18n.t('admin.teachersList.nameColumnHeader'),
  noNameValue: () => i18n.t('admin.studentsList.noNameValue'),
  showDetailsAction: () => i18n.t('admin.teachersList.showDetailsAction'),
  title: () => i18n.t('admin.teachersList.title')
};
