import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../utils';
import { AdminArchiveStateSchoolAction } from './AdminArchiveStateSchoolAction';
import { AdminDemoSchoolSnapshots } from './AdminDemoSchoolSnapshots';
import { AdminUnshareSchoolAction } from './AdminUnshareSchoolAction';

export interface AdminManageSchoolActionsProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminManageSchoolActions = observer(({ sx = [], className, schoolId }: AdminManageSchoolActionsProps) => {
  return (
    <Stack
      className={className}
      spacing={3}
      sx={[
        {
          p: 2
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <AdminUnshareSchoolAction schoolId={schoolId} />
      <AdminArchiveStateSchoolAction schoolId={schoolId} />
      <AdminDemoSchoolSnapshots schoolId={schoolId} />
    </Stack>
  );
});
