import { GoogleCalendarViewModel } from '@/viewmodels';
import { CheckRounded } from '@mui/icons-material';
import { ListItemButton, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface GoogleCalendarProps {
  viewModel: GoogleCalendarViewModel;

  /**
   * Indicates to disable the checkbox. Optional. Default is `false`.
   */
  disabled?: boolean;

  className?: string;

  sx?: SxProps;
}

export const GoogleCalendar = observer(({ className, sx = [], viewModel, disabled }: GoogleCalendarProps) => (
  <ListItemButton
    className={className}
    sx={sx}
    disabled={disabled}
    onClick={() => (viewModel.isSelected = !viewModel.isSelected)}
  >
    <ListItemText sx={{ flex: 1 }} primary={viewModel.title} />

    {viewModel.isSelected && <CheckRounded color="primary" />}
  </ListItemButton>
));
