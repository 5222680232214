import { CardActionArea, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { BackgroundImageScreenPaper } from '../../../utils';

export interface PlannerListSummaryProps {
  sx?: SxProps;
  className?: string;
  title: string;
  itemCount: number;
  onSelect: () => void;
}

export const PlannerListSummary = observer(
  ({ sx = [], className, title, itemCount, onSelect }: PlannerListSummaryProps) => {
    return (
      <BackgroundImageScreenPaper sx={{ ...sx, overflow: 'hidden' }} className={className} onClick={onSelect} bordered>
        <CardActionArea
          sx={{
            width: '100%',
            height: '100%',
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <Stack
            spacing={1}
            sx={{
              width: '100%',
              overflow: 'hidden',
              alignItems: 'stretch'
            }}
          >
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                textAlign: 'center',
                textOverflow: 'ellipsis',
                width: '100%'
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                textAlign: 'center'
              }}
            >
              {itemCount}
            </Typography>
          </Stack>
        </CardActionArea>
      </BackgroundImageScreenPaper>
    );
  }
);
