import { useServices } from '@/hooks';
import { UserDashboardPlannerItemsLocationState } from '@/services';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { NoteEditDialog } from '../notes';
import { PlannerDistributeItemDialog, PlannerPastePlannerItemDialog, PlannerRepeatItemDialog } from '../planner';
import { PublishedWorkEditDialog } from '../published-works';
import { PlannedWorkEditDialog, WorkEditDialog } from '../work';

export const UserDashboardLayoutDialogs = observer(() => {
  const { pasteboard } = useServices();
  const location = useLocation();
  const state = (location.state ?? {}) as UserDashboardPlannerItemsLocationState;

  useEffect(() => {
    // Resetting pasteboard "paste context" on any navigation.
    pasteboard.clearPasteContext();
  }, [location]);

  return (
    <>
      {state.workEdit != null && (
        <WorkEditDialog
          isOpen
          workId={state.workEdit.workId}
          newWorkDefaultValues={state.workEdit.newWorkDefaultValues}
          locationStateKey={'workEdit'}
        />
      )}

      {state.plannedWorkEdit != null && (
        <PlannedWorkEditDialog workId={state.plannedWorkEdit.workId} plannedWorkId={state.plannedWorkEdit.id} isOpen />
      )}

      {state.noteEdit != null && (
        <NoteEditDialog noteId={state.noteEdit.id} newNoteDefaultValues={state.noteEdit.newNoteDefaultValues} isOpen />
      )}

      {state.publishedWorkEdit != null && (
        <PublishedWorkEditDialog
          isOpen
          ids={
            state.publishedWorkEdit.ids != null
              ? { publishedWorkId: state.publishedWorkEdit.ids.id, schoolId: state.publishedWorkEdit.ids.schoolId }
              : undefined
          }
          newPublishedWorkInfo={state.publishedWorkEdit.newPublishedWorkInfo}
        />
      )}

      {state.distributeItem != null && <PlannerDistributeItemDialog isOpen itemKind={state.distributeItem.kind} />}
      {state.repeatItem != null && <PlannerRepeatItemDialog isOpen itemKind={state.repeatItem.kind} />}
      {pasteboard.pasteContext != null && <PlannerPastePlannerItemDialog context={pasteboard.pasteContext} />}
    </>
  );
});
