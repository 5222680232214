import { WorkTransportService } from '@/transports';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { action, makeObservable } from 'mobx';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export interface WorkLoadable extends LoadableValue<Work> {
  // Some actions can result in obtaining an updated version of the Work model. Instead of doing a fetch, we can
  // simply update the value stored.
  updateWithValue(newValue: Work): void;
}

export class AppWorkLoadable extends BaseLoadableValue<Work> implements WorkLoadable {
  constructor(
    private readonly _workId: string,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  @action
  updateWithValue(newValue: Work) {
    this._data = newValue;
    this._state = 'fulfilled';
  }

  protected async loadData(): Promise<Work> {
    return await this._workTransport.getWork(this._workId);
  }
}
