export interface ImageSource {
  readonly src: string;
  readonly type: 'image/webp' | 'image/png' | 'image/jpeg';
}

export interface ImageProps {
  sources: ImageSource[];
  fallback: string;
  alt: string;
  className?: string;
}

export function Image({ sources, fallback, alt, className }: ImageProps) {
  return (
    <picture>
      {sources.map((imageSource) => (
        <source
          key={imageSource.src}
          srcSet={imageSource.src}
          className={className}
          type={imageSource.type}
          aria-label={alt}
        />
      ))}

      <img src={fallback} className={className} alt={alt} />
    </picture>
  );
}
