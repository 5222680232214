import i18n from 'translate';

export const ScheduleCycleCreateStrings = {
  copyTitle: () => i18n.t('scheduleCycle.create.copyTitle'),
  cycleDayNamesCustomizeButtonTitle: () => i18n.t('scheduleCycle.create.cycleDayNamesCustomizeButtonTitle'),
  cycleDayNamesEditMessage: () => i18n.t('scheduleCycle.create.cycleDayNamesEditMessage'),
  cycleDayNamesEditTitle: () => i18n.t('scheduleCycle.create.cycleDayNamesEditTitle'),
  cycleDayNamesNone: () => i18n.t('scheduleCycle.create.cycleDayNamesNone'),
  cycleDayNamesSectionTitle: () => i18n.t('scheduleCycle.create.cycleDayNamesSectionTitle'),
  cycleDayNumberLabel: () => i18n.t('scheduleCycle.create.cycleDayNumberLabel'),
  datesSectionTitle: () => i18n.t('scheduleCycle.create.datesSectionTitle'),
  endDatePickerLabel: () => i18n.t('scheduleCycle.create.endDatePickerLabel'),
  errorMessage: () => i18n.t('scheduleCycle.create.errorMessage'),
  keepActivityScheduleExceptionsLabel: () => i18n.t('scheduleCycle.create.keepActivityScheduleExceptionsLabel'),
  keepActivitySchedulesLabel: () => i18n.t('scheduleCycle.create.keepActivitySchedulesLabel'),
  keepPeriodSchedulesLabel: () => i18n.t('scheduleCycle.create.keepPeriodSchedulesLabel'),
  keepSectionTitle: () => i18n.t('scheduleCycle.create.keepSectionTitle'),
  keepSpecialDayOccurrencesLabel: () => i18n.t('scheduleCycle.create.keepSpecialDayOccurrencesLabel'),
  keepSpecialDaysLabel: () => i18n.t('scheduleCycle.create.keepSpecialDaysLabel'),
  keepTermsLabel: () => i18n.t('scheduleCycle.create.keepTermsLabel'),
  multipleWeeksCountLabel: () => i18n.t('scheduleCycle.create.multipleWeeksCountLabel'),
  multipleWeeksStartWeekLabel: () => i18n.t('scheduleCycle.create.multipleWeeksStartWeekLabel'),
  nextButtonTitle: () => i18n.t('scheduleCycle.create.nextButtonTitle'),
  perDayPeriodSchedulesLabel: () => i18n.t('scheduleCycle.create.perDayPeriodSchedulesLabel'),
  saveButtonTitle: () => i18n.t('scheduleCycle.create.saveButtonTitle'),
  scheduleKindCycleDayLabel: () => i18n.t('scheduleCycle.create.scheduleKindCycleDayLabel'),
  scheduleKindMultipleWeeksLabel: () => i18n.t('scheduleCycle.create.scheduleKindMultipleWeeksLabel'),
  scheduleKindPickerLabel: () => i18n.t('scheduleCycle.create.scheduleKindPickerLabel'),
  scheduleKindSectionTitle: () => i18n.t('scheduleCycle.create.scheduleKindSectionTitle'),
  scheduleKindWeekLabel: () => i18n.t('scheduleCycle.create.scheduleKindWeekLabel'),
  scheduleNameHeader: () => i18n.t('scheduleCycle.create.scheduleNameHeader'),
  scheduleNamePlaceholder: () =>
    i18n.t('scheduleCycle.create.scheduleNamePlaceholder', {
      startYear: String(new Date().getFullYear()),
      endYear: String(new Date().getFullYear() + 1).substring(2)
    }),
  startCycleDayLabel: () => i18n.t('scheduleCycle.create.startCycleDayLabel'),
  startDatePickerLabel: () => i18n.t('scheduleCycle.create.startDatePickerLabel'),
  title: () => i18n.t('scheduleCycle.create.title')
};
