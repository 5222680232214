import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService, LocalizationService, SelectableThemeVariant, ThemeService } from '@/services';
import { UserDataStore } from '@/stores';
import { Locale } from '@/utils';
import { computed, makeObservable } from 'mobx';

export interface PreferencesViewModel {
  locale: Locale;
  selectedThemeVariant: SelectableThemeVariant;
  reduceTransparency: boolean;
  useCustomPrimaryColor: boolean;
  customPrimaryColor: string | undefined;
}

export class AppPreferencesViewModel implements PreferencesViewModel {
  constructor(
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _theme: ThemeService = ServiceContainer.services.theme
  ) {
    makeObservable(this);
  }

  @computed
  get locale(): Locale {
    return this._localization.currentLocale;
  }

  set locale(value: Locale) {
    void this.updateLocale(value);
  }

  @computed
  get selectedThemeVariant(): SelectableThemeVariant {
    return this._theme.selectedVariant;
  }

  set selectedThemeVariant(value: SelectableThemeVariant) {
    this._theme.setVariant(value);
  }

  @computed
  get reduceTransparency(): boolean {
    return this._settings.reduceTransparency;
  }

  set reduceTransparency(value: boolean) {
    this._settings.reduceTransparency = value;
  }

  @computed
  get useCustomPrimaryColor(): boolean {
    return this._settings.useCustomPrimaryColor;
  }

  set useCustomPrimaryColor(value: boolean) {
    this._settings.useCustomPrimaryColor = value;
  }

  @computed
  get customPrimaryColor(): string | undefined {
    return this._settings.customPrimaryColor;
  }

  set customPrimaryColor(value: string | undefined) {
    this._settings.customPrimaryColor = value;
  }

  private async updateLocale(locale: Locale) {
    await this._userStore.updateCurrentUserLocale(locale);
  }
}
