import { useServices } from '@/hooks';
import { UserPropertiesViewModel } from '@/viewmodels';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface UserPropertiesFileInputProps {
  className?: string;
  viewModel: UserPropertiesViewModel;
}

export const UserPropertiesFileInput = observer(({ className, viewModel }: UserPropertiesFileInputProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.user;

  return (
    <label htmlFor="contained-button-file" className={className}>
      <input
        style={{ display: 'none' }}
        accept="image/*"
        id="contained-button-file"
        type="file"
        onChange={(e) => void viewModel.onFileChanged(e.target.files ?? undefined)}
      />
      <Button variant="contained" component="span">
        {strings.changeImageButton}
      </Button>
    </label>
  );
});
