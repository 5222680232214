import { useServices } from '@/hooks';
import { getSortedLocales, Locale, LocaleFlags, LocaleTitles } from '@/utils';
import TranslateIcon from '@mui/icons-material/TranslateRounded';
import { FormControl, ListItemIcon, ListItemText, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { Subheader } from '../utils';

export interface LanguagePickerProps {
  className?: string;
  sx?: SxProps;

  /**
   * The selected locale.
   */
  locale: Locale;
  /**
   * Callback when a locale is selected.
   * @param locale The new locale.
   */
  onChange: (locale: Locale) => void;
}

export const LanguagePicker = observer(({ className, sx = [], locale, onChange }: LanguagePickerProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.settings;
  const theme = useTheme();
  const sortedLocales = useMemo(() => getSortedLocales(), []);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const label = strings.languagePickerLabel;

  return (
    <Stack>
      <Subheader renderIcon={(sx) => <TranslateIcon sx={sx} />}>{label}</Subheader>

      <FormControl className={className} sx={sx}>
        <Select
          open={open}
          value={locale}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={(event) => onChange(event.target.value as Locale)}
          renderValue={(locale) => <LocaleOptionPreview locale={locale} />}
        >
          {sortedLocales.map((locale) => (
            <MenuItem key={locale} value={locale}>
              <ListItemIcon sx={{ color: theme.palette.text.primary }}> {LocaleFlags[locale]}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  color: localization.currentLocale === locale && open ? 'primary' : 'inherit'
                }}
              >
                {/* Not localized */}
                {LocaleTitles[locale]}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
});

function LocaleOptionPreview({ locale }: { locale: Locale }) {
  const theme = useTheme();

  return (
    <MenuItem value={locale} disableGutters sx={{ m: 0, p: 0, ':hover': { backgroundColor: 'transparent' } }}>
      <ListItemIcon sx={{ color: theme.palette.text.primary }}> {LocaleFlags[locale]}</ListItemIcon>
      <ListItemText>
        {/* Not localized */}
        {LocaleTitles[locale]}
      </ListItemText>
    </MenuItem>
  );
}
