import { ConnectedAppService } from '@/services';
import { action, computed, makeObservable } from 'mobx';

export interface SyncInfoViewModel {
  readonly isSyncing: boolean;
  readonly lastSync?: Date;
  readonly isFetching: boolean;

  sync(): Promise<void>;
  refreshSyncStatus(): Promise<void>;
}

export class AppSyncInfoViewModel<T extends string> implements SyncInfoViewModel {
  constructor(
    protected readonly _connectedApp: ConnectedAppService<T>,
    protected readonly _onSync?: () => Promise<void>
  ) {
    makeObservable(this);
  }

  @computed
  get isSyncing(): boolean {
    return this._connectedApp.isSyncing;
  }

  @computed
  get lastSync(): Date | undefined {
    return this._connectedApp.lastSuccessfulSyncTime;
  }

  @computed
  get isFetching(): boolean {
    return this._connectedApp.isFetching;
  }

  @action
  async sync() {
    await this._connectedApp.sync();
    await this._onSync?.();
  }

  async refreshSyncStatus() {
    await this._connectedApp.refreshSyncStatus();
  }
}
