import { computed, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService, RouteService } from '../../services';
import { Loadable, ParticipatingSchoolsLoadable, PlannersLoadable, UserDataStore } from '../../stores';
import { AppSubscriptionsViewModel } from './SubscriptionsViewModel';

export class AppStartTrialSubscriptionViewModel extends AppSubscriptionsViewModel {
  constructor(
    onSubscribe: (sharedSchoolIds: string[]) => Promise<void>,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    route: RouteService = ServiceContainer.services.route,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(undefined, onSubscribe, userStore, route, localization);
    makeObservable(this);
  }

  @computed
  private get plannersLoadable(): PlannersLoadable {
    return this._userStore.plannersLoadable;
  }

  @computed
  private get schoolsLoadable(): ParticipatingSchoolsLoadable {
    return this._userStore.participatingSchools;
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.plannersLoadable, this.schoolsLoadable, ...super.loadables];
  }
}
