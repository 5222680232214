import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { isSxArray } from '../../../utils';

export interface RadioChoicePickerOptionLabelProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  icon?: (sx: SxProps) => ReactNode;
  isSelected: boolean;
  disabled?: boolean;
}

export function RadioChoicePickerOptionLabel({
  sx = [],
  className,
  title,
  subtitle,
  icon,
  isSelected,
  disabled = false
}: RadioChoicePickerOptionLabelProps) {
  return (
    <Stack
      className={className}
      direction="row"
      spacing={2.5}
      sx={[
        {
          alignItems: 'center',
          py: 1
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {icon?.({ width: 24, color: 'inherit' })}
      <Stack>
        <Typography
          variant="body1"
          sx={{
            color: 'inherit'
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: isSelected || disabled ? 'inherit' : (theme) => theme.palette.text.secondary
          }}
        >
          {subtitle ?? ''}
        </Typography>
      </Stack>
    </Stack>
  );
}
