import { CurriculumTransportService } from '@/transports';
import { observable } from 'mobx';
import { getOrCreateInObservableMap } from '../../utils';
import { CurriculumDataStore, PlannerDataStore } from '../contracts';
import {
  AppCurriculumCoursesLoadable,
  AppCurriculumEnrolledCourseIdsInPlannerLoadable,
  CurriculumCoursesLoadable,
  CurriculumEnrolledCourseIdsInPlannerLoadable
} from '../loadables';

export class AppCurriculumDataStore implements CurriculumDataStore {
  private readonly _coursesLoadable: CurriculumCoursesLoadable;
  private readonly _enrolledCourseIdsLoadableByPlannerId = observable.map<
    string,
    CurriculumEnrolledCourseIdsInPlannerLoadable
  >();

  constructor(
    private readonly _plannerStore: PlannerDataStore,
    private readonly _curriculumTransport: CurriculumTransportService
  ) {
    this._coursesLoadable = new AppCurriculumCoursesLoadable(_curriculumTransport);
  }

  getCourses(): CurriculumCoursesLoadable {
    return this._coursesLoadable;
  }

  getEnrolledCourseIdsInPlanner(plannerId: string): CurriculumEnrolledCourseIdsInPlannerLoadable {
    return getOrCreateInObservableMap(
      this._enrolledCourseIdsLoadableByPlannerId,
      plannerId,
      () => new AppCurriculumEnrolledCourseIdsInPlannerLoadable(plannerId, this._curriculumTransport)
    );
  }

  async setEnrolledCourseIdsInPlanner(plannerId: string, courseIds: string[]): Promise<void> {
    const loadable = this.getEnrolledCourseIdsInPlanner(plannerId);
    let currentValue: string[] | undefined;

    if (loadable.hasData) {
      // Updating local value for snappiness of UI.
      currentValue = loadable.data;
      loadable.setValue(courseIds);
    }

    try {
      await this._curriculumTransport.setEnrolledCourseIdsInPlanner(plannerId, courseIds);
      await this.reloadPlannerData(plannerId);
    } catch (e) {
      if (currentValue != null) {
        // If an error occurred, resetting the value.
        loadable.setValue(currentValue);
      }

      // Rethrowing error so that UI can handle it
      throw e;
    }
  }

  private async reloadPlannerData(plannerId: string) {
    // We first have to fetch the update classes list.
    const courseSectionsLoadable = this._plannerStore.getCourseSectionsInPlanner(plannerId);
    await courseSectionsLoadable.fetch(true);

    // Then we fetch contents related data.
    const plannerContentsLoadable = this._plannerStore.getPlannerContentsInPlanner(plannerId);
    const inboxCountLoadable = this._plannerStore.getInboxWorkCountInPlanner(plannerId);
    await Promise.all([plannerContentsLoadable.fetch(true), inboxCountLoadable.fetch(true)]);
  }
}
