import {
  emojiForEmotionalPulseRating,
  emotionalPulseRatingToStatus,
  UserDailyActivity,
  UserDailyEmotionalStatus
} from '@/models';
import { isEqual } from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface StudentInsightsDailyActivityInfo extends UserDailyActivity {
  readonly bullet: string;
}

export interface StudentInsightsDailyActivityViewModel {
  readonly userActivity: StudentInsightsDailyActivityInfo[];
}

export class AppStudentInsightsDailyActivityViewModel implements StudentInsightsDailyActivityViewModel {
  constructor(
    private readonly _userActivity: UserDailyActivity[],
    private readonly _emotionalStatus: UserDailyEmotionalStatus[]
  ) {
    makeObservable(this);
  }

  @computed
  get userActivity(): StudentInsightsDailyActivityInfo[] {
    return this._userActivity.map((u, index) => {
      // TODO: Find proper way to display emotional status in StudentInsights.
      const dailyEmotionalStatus = this._emotionalStatus[index];
      const emotionalStatus =
        dailyEmotionalStatus != null && dailyEmotionalStatus.value >= 0 && isEqual(dailyEmotionalStatus.date, u.date)
          ? emotionalPulseRatingToStatus(dailyEmotionalStatus.value)
          : undefined;
      const emotionalStatusEmoji = emojiForEmotionalPulseRating(emotionalStatus);
      return { ...u, bullet: emotionalStatusEmoji };
    });
  }
}
