import { Student } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_pb';
import { StudentConnectionState } from '../Enums';

export function connectionStateForStudent(student: Student): StudentConnectionState {
  if (student.userId.length === 0 || (student.plannerId?.length ?? 0) === 0) {
    return 'not-connected';
  }

  return student.isPlannerAccessAuthorized ? 'connected' : 'connected-no-access';
}
