import i18n from 'translate';

export const SettingsSideBarPlannerStrings = {
  access: () => i18n.t('settings.sidebar.planner.access'),
  connectedApps: () => i18n.t('settings.sidebar.planner.connectedApps'),
  courses: () => i18n.t('settings.sidebar.planner.courses'),
  curriculumCourses: () => i18n.t('settings.sidebar.planner.curriculumCourses'),
  edit: () => i18n.t('settings.sidebar.planner.edit'),
  generalSettingsSectionTitle: () => i18n.t('settings.sidebar.planner.generalSettingsSectionTitle'),
  schedule: () => i18n.t('settings.sidebar.planner.schedule'),
  schools: () => i18n.t('settings.sidebar.planner.schools')
};

export const SettingsSideBarSchoolStrings = {
  courses: () => i18n.t('settings.sidebar.school.courses'),
  editSchool: () => i18n.t('settings.sidebar.school.editSchool'),
  generalSettingsSectionTitle: () => i18n.t('settings.sidebar.school.generalSettingsSectionTitle'),
  schedules: () => i18n.t('settings.sidebar.school.schedules')
};

export const SettingsSideBarSharedStrings = {
  about: () => i18n.t('settings.sidebar.shared.about'),
  accountManagement: () => i18n.t('settings.sidebar.shared.accountManagement'),
  preferences: () => i18n.t('settings.sidebar.shared.preferences'),
  profile: () => i18n.t('settings.sidebar.shared.profile'),
  sharedSectionTitle: () => i18n.t('settings.sidebar.shared.sharedSectionTitle')
};

export const SettingsSideBarStrings = {
  planner: SettingsSideBarPlannerStrings,
  school: SettingsSideBarSchoolStrings,
  shared: SettingsSideBarSharedStrings
};
