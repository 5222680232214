import { action, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService, RouteService } from '../../services';
import { UserDataStore } from '../../stores';
import { DialogActionButtonConfiguration, UpdatableDialogViewModel } from '../utils';
import { AppSubscriptionsViewModel, SubscriptionsViewModel } from './SubscriptionsViewModel';

export interface SubscriptionsDialogViewModel extends SubscriptionsViewModel, UpdatableDialogViewModel {
  readonly error: string | undefined;
  confirm(): Promise<void>;
  close(): Promise<void>;
}

export class AppSubscriptionsDialogViewModel extends AppSubscriptionsViewModel implements SubscriptionsDialogViewModel {
  readonly kind = 'updatable';
  readonly showDismissConfirmAlert = false;
  readonly actions: DialogActionButtonConfiguration[] = [];
  readonly supplementaryActions: DialogActionButtonConfiguration[] = [];

  constructor(
    plannerId: string | undefined,
    private readonly _close: () => Promise<void>,
    onSubscribe: (sharedSchoolIds: string[]) => Promise<void>,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    route: RouteService = ServiceContainer.services.route,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(plannerId, onSubscribe, userStore, route, localization);
    makeObservable(this);
  }

  closeDismissConfirmAlert() {
    // Alert is never shown
  }

  errorMessageSelector(): string | undefined {
    return undefined;
  }

  forceDismiss(): Promise<void> {
    return Promise.resolve();
  }

  @action
  clearError() {
    this._error = undefined;
  }

  dismiss() {
    return this._close();
  }

  confirm() {
    return this._close();
  }

  close() {
    return this._close();
  }
}
