import { compareSchoolCourseSections } from '@/models';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { action, computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { Loadable, SchoolAccountCourseSectionsLoadable, SchoolDataStore } from '../../stores';
import { propertiesHaveMatchForSearchText } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export type AdminAccountClassesListKind = 'teacher' | 'student';

export interface AdminAccountClassesListViewModel extends UpdatableViewModel {
  readonly searchText: string;
  readonly courseSections: CourseSection[];
  readonly filteredCourseSections: CourseSection[];
  setSearchText(value: string): void;
}

export class AppAdminAccountClassesListViewModel
  extends BaseUpdatableViewModel
  implements AdminAccountClassesListViewModel
{
  @observable private _searchText = '';

  constructor(
    private readonly _accountId: string,
    private readonly _schoolId: string,
    private readonly _kind: AdminAccountClassesListKind,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get courseSectionsLoadable(): SchoolAccountCourseSectionsLoadable {
    return this._schoolStore.getCourseSectionsForAccount(this._schoolId, this._accountId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionsLoadable];
  }

  @computed
  private get courseSectionForKind(): CourseSection[] {
    switch (this._kind) {
      case 'teacher':
        return this.courseSectionsLoadable.data.teacher;
      case 'student':
        return this.courseSectionsLoadable.data.student;
    }
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  @computed
  get courseSections(): CourseSection[] {
    return Array.from(this.courseSectionForKind).sort((cs1, cs2) =>
      compareSchoolCourseSections(cs1, cs2, this._localization.currentLocale)
    );
  }

  @computed
  get filteredCourseSections(): CourseSection[] {
    return this.courseSections.filter((cs) =>
      propertiesHaveMatchForSearchText(this._searchText, [
        cs.title,
        cs.section,
        { value: cs.id, caseSensitive: true, method: 'exact' }
      ])
    );
  }

  @action
  setSearchText(value: string) {
    this._searchText = value;
  }
}
