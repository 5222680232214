import { Day } from '@/models';
import { captureException } from '@sentry/react';
import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { mergeObservableMaps } from '../../utils';
import { StoreInvalidator, UserDashboardCalendarStore } from '../contracts';

export abstract class AppUserDashboardCalendarStore<DayContent> implements UserDashboardCalendarStore<DayContent> {
  private _lastFetchedDays: { start: Day; end: Day } | undefined;

  private _days = observable.map<string, DayContent>();
  @observable protected _moreRecentDraftScheduleCycleIds: string[] = [];
  @observable private _error: string | undefined;
  @observable private _fetchingCount = 0;

  protected constructor(storeInvalidator: StoreInvalidator) {
    makeObservable(this);

    reaction(
      () => storeInvalidator.calendarRevision,
      () => this.invalidate()
    );
  }

  @computed
  get isFetching(): boolean {
    return this._fetchingCount > 0;
  }

  @computed
  get days(): Map<string, DayContent> {
    return new Map(this._days);
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get moreRecentDraftScheduleCycleIds(): string[] {
    return this._moreRecentDraftScheduleCycleIds;
  }

  async fetchDays(startDay: Day, endDay: Day, force = false): Promise<void> {
    if (
      !force &&
      this._lastFetchedDays != null &&
      isEqual(this._lastFetchedDays.start, startDay) &&
      isEqual(this._lastFetchedDays.end, endDay)
    ) {
      return;
    }

    // Prevents infinite load.
    this._lastFetchedDays = { start: startDay, end: endDay };

    runInAction(() => {
      this._fetchingCount++;
      this._error = undefined;
    });

    const newValues = observable.map<string, DayContent>();

    try {
      const days = await this.loadData(startDay, endDay);
      days.forEach((day) => {
        const key = this.keyForDayContent(day);
        if (key.length === 0) {
          return;
        }

        newValues.set(key, day);
      });

      runInAction(() => {
        mergeObservableMaps(this._days, newValues);
      });
    } catch (e) {
      captureException(e);
      runInAction(() => {
        this._error = (e as Error).message;
      });
    } finally {
      runInAction(() => this._fetchingCount--);
    }
  }

  @action
  invalidate() {
    this._lastFetchedDays = undefined;
  }

  protected abstract loadData(startDay: Day, endDay: Day): Promise<DayContent[]>;

  protected abstract keyForDayContent(dayContent: DayContent): string;
}
