import { CourseSectionOccurrenceInfo, Day, courseSectionOccurrenceToInfo, dayToDate, dayToString } from '@/models';
import { PlannerCalendarStore } from '@/stores';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { captureException } from '@sentry/react';
import { chain } from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UpdatableViewModel, UpdatableViewModelState } from '../shared';

export type PlannerPastePlannerItemTimeOfDay =
  | { case: 'period'; value: CourseSectionOccurrenceInfo }
  | { case: 'time'; value: Date; hasValidationError: boolean }
  | { case: 'none' };

export interface PlannerPastePlannerItemTimeOfDaySelectionViewModel extends UpdatableViewModel {
  readonly availablePeriods: CourseSectionOccurrenceInfo[];
  selection: PlannerPastePlannerItemTimeOfDay | undefined;
}

export class AppPlannerPastePlannerItemTimeOfDaySelectionViewModel
  implements PlannerPastePlannerItemTimeOfDaySelectionViewModel
{
  @observable private _state: UpdatableViewModelState = undefined;
  @observable private _plannerDay: PlannerDay | undefined;

  constructor(
    private readonly _day: Day | undefined,
    private readonly _calendarStore: PlannerCalendarStore,
    private readonly _getSelection: () => PlannerPastePlannerItemTimeOfDay | undefined,
    private readonly _onSelectionChange: (selection: PlannerPastePlannerItemTimeOfDay) => void
  ) {
    makeObservable(this);
  }

  @computed
  get availablePeriods(): CourseSectionOccurrenceInfo[] {
    if (this._day == null || this._plannerDay == null) {
      return [];
    }

    const date = dayToDate(this._day);

    return chain(this._plannerDay.items)
      .map((item) =>
        item.item.case === 'courseSectionOccurrence' ? courseSectionOccurrenceToInfo(item.item.value, date) : undefined
      )
      .compact()
      .value();
  }

  @computed
  get selection(): PlannerPastePlannerItemTimeOfDay | undefined {
    return this._getSelection();
  }

  set selection(value: PlannerPastePlannerItemTimeOfDay) {
    this._onSelectionChange(value);
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  @computed
  get hasData(): boolean {
    return this._state === 'fulfilled';
  }

  async reloadData() {
    if (this._day == null) {
      runInAction(() => (this._state = 'fulfilled'));
      return;
    }

    const dayAsString = dayToString(this._day);
    const existingPlannerDay = this._calendarStore.days.get(dayAsString);

    if (existingPlannerDay != null) {
      runInAction(() => {
        this._plannerDay = existingPlannerDay;
        this._state = 'fulfilled';
      });
    }

    runInAction(() => (this._state = 'pending'));

    try {
      await this._calendarStore.fetchDays(this._day, this._day);

      runInAction(() => {
        this._plannerDay = this._calendarStore.days.get(dayAsString);
        this._state = 'fulfilled';
      });
    } catch (e) {
      captureException(e);
      runInAction(() => (this._state = e as Error));
    }
  }
}
