export interface ExSchoolStrings {
  readonly connectToExternalSourceConnectedImageAlt: string;
  readonly connectToExternalSourceConnectedMessage: string;
  readonly connectToExternalSourceConnecting: string;
  readonly connectToExternalSourceConnectionErrorMessage: string;
  readonly connectToExternalSourceNotConnectedMessage: string;
  readonly connectToExternalSourceNotSubscribedMessage: string;
  readonly connectToExternalSourcePrivacyPolicy: string;
  readonly connectToExternalSourceSubscribeButtonTitle: string;
  readonly connectToExternalSourceTitle: string;

  readonly createPersonalSchoolTitle: string;
  readonly createSchoolErrorMessage: string;
  readonly createSchoolErrorTitle: string;
  readonly createSchoolSaveButtonLabel: string;
  readonly createSchoolTitle: string;
  readonly createSharedSchoolTitle: string;

  readonly editSchoolErrorMessage: string;
  readonly editSchoolErrorTitle: string;
  readonly editSchoolNameInputLabel: string;
  readonly editSchoolNameInputPlaceholder: string;
  readonly editSchoolSaveButtonLabel: string;
  readonly editSchoolSubtitleInputLabel: string;
  readonly editSchoolSubtitleInputPlaceholder: string;
  readonly editSchoolTitle: string;

  readonly inviteStudentsDialogTitle: string;
  readonly inviteStudentsErrorMessage: string;
  readonly inviteStudentsInviteButtonTitle: string;
  readonly inviteStudentsMessage: string;
  readonly inviteStudentsSuccessMessage: string;
  readonly inviteStudentsWithCodeMessage: string;

  readonly noCoursesConnectExternalSourceButtonTitle: string;
  readonly noCoursesManageExternalSourceButtonTitle: string;
  readonly noCoursesMessage: string;
  readonly noCoursesTitle: string;

  readonly settingsCoursesTitle: string;
  readonly settingsGeneralSectionTitle: string;
  readonly settingsSchoolEditTitle: string;

  readonly sidebarCollapse: string;
  readonly sidebarConnectClassroomSubtitle: string;
  readonly sidebarConnectClassroomTitle: string;
  readonly sidebarConnectClassroomTooltip: string;
  readonly sidebarDemoCourseSubtitle: string;
  readonly sidebarEditSchoolTitle: string;
  readonly sidebarEditSchoolTooltip: string;
  readonly sidebarExpandTooltip: string;
  readonly sidebarHideDemoCoursesTitle: string;
  readonly sidebarHideDemoCoursesTooltip: string;

  readonly sidebarManageClassesSubtitle: string;
  readonly sidebarManageClassesTitle: string;
  readonly sidebarManageClassesTooltip: string;
  readonly sidebarMobileShowButtonTitle: string;
  readonly sidebarNoClasses: string;
  readonly sidebarOpenStudentPlannerTitle: string;
  readonly sidebarOpenStudentPlannerTooltip: string;
  readonly sidebarReloadTooltip: string;
  readonly sidebarShowDemoCoursesTitle: string;
  readonly sidebarShowDemoCoursesTooltip: string;

  readonly sidebarStudentCountLabel: (studentsUsingToday: number, total: number) => string;
  readonly sidebarTitle: string;
}

export const EnglishSchoolStrings: ExSchoolStrings = {
  connectToExternalSourceConnectedImageAlt:
    'Connected illustration, a phone displaying a checkmark with Billie Bot at its side',
  connectToExternalSourceConnectedMessage:
    'You have already connected your school to Google Classroom!\nSelect a class and see how your students are planning their work.',
  connectToExternalSourceConnecting: 'Connecting…',
  connectToExternalSourceConnectionErrorMessage:
    'An error occurred while connecting. Try again or contact support if the error persists.',
  connectToExternalSourceNotConnectedMessage:
    'You will be asked to login to allow Studyo Go to get your list of classes.\nYou may have to let your admin know you wish to do this and enable Studyo Go for your school or district.',
  connectToExternalSourceNotSubscribedMessage:
    'You must have an active subscription to connect your school to Google Classroom.',
  connectToExternalSourcePrivacyPolicy: 'Privacy policy',
  connectToExternalSourceSubscribeButtonTitle: 'Subscribe',
  connectToExternalSourceTitle: 'Google Classroom',

  createPersonalSchoolTitle: 'Create a personal school',
  createSchoolErrorMessage:
    'An unexpected error occurred while creating the school.\nPlease try again later or contact support if it persists.',
  createSchoolErrorTitle: 'Oops',
  createSchoolSaveButtonLabel: 'Create',
  createSchoolTitle: 'Create a school',
  createSharedSchoolTitle: 'Create a shared school',

  editSchoolErrorMessage:
    'An unexpected error occurred while editing the school.\nPlease try again later or contact support if it persists.',
  editSchoolErrorTitle: 'Oops',
  editSchoolNameInputLabel: 'Name of your school',
  editSchoolNameInputPlaceholder: 'Name',
  editSchoolSaveButtonLabel: 'Save',
  editSchoolSubtitleInputLabel: 'Subtitle (optional)',
  editSchoolSubtitleInputPlaceholder: 'Subtitle',
  editSchoolTitle: 'Edit school details',

  inviteStudentsDialogTitle: 'Invite your students',
  inviteStudentsErrorMessage:
    'An error occurred while sending the invite. Please try again later or contact support if it persists',
  inviteStudentsInviteButtonTitle: 'Invite my students',
  inviteStudentsMessage:
    'Your students will receive an invitation in their planner. Once accepted, you will have a read-only access to it.',
  inviteStudentsSuccessMessage:
    'The invitation has been sent! Now, get your students to connect to Studyo Go and they will see the invitation.',
  inviteStudentsWithCodeMessage:
    "You can also share this code with your students. Once they've used it, you will have access to their planner.",

  noCoursesConnectExternalSourceButtonTitle: 'Add my classes',
  noCoursesManageExternalSourceButtonTitle: 'Manage my classes',
  noCoursesMessage: 'You do not currently have any classes connected to Studyo Go',
  noCoursesTitle: 'No classes',

  settingsCoursesTitle: 'Classes',
  settingsGeneralSectionTitle: 'School settings',
  settingsSchoolEditTitle: 'School details',

  sidebarCollapse: 'Collapse',
  sidebarConnectClassroomSubtitle: 'from Google Classroom',
  sidebarConnectClassroomTitle: 'Add my classes',
  sidebarConnectClassroomTooltip: 'Add my classes from Google Classroom',
  sidebarDemoCourseSubtitle: 'Explore what Studyo Go has to offer!',
  sidebarEditSchoolTitle: 'School details',
  sidebarEditSchoolTooltip: 'School details',
  sidebarExpandTooltip: 'Expand',
  sidebarHideDemoCoursesTitle: 'Hide demo class',
  sidebarHideDemoCoursesTooltip: 'Hide demo class',
  sidebarManageClassesSubtitle: 'from Google Classroom',
  sidebarManageClassesTitle: 'Manage my classes',
  sidebarManageClassesTooltip: 'Manage my classes from Google Classroom',
  sidebarMobileShowButtonTitle: 'Show all my classes',
  sidebarNoClasses: 'No classes',
  sidebarOpenStudentPlannerTitle: "Open a student's planner",
  sidebarOpenStudentPlannerTooltip: "Open a student's planner",
  sidebarReloadTooltip: 'Reload classes',
  sidebarShowDemoCoursesTitle: 'Show demo class',
  sidebarShowDemoCoursesTooltip: 'Show demo class',
  sidebarStudentCountLabel: (studentsUsingToday, total) =>
    `${studentsUsingToday}/${total} student${studentsUsingToday === 1 || total === 1 ? ' is' : 's are'} using Studyo Go`,
  sidebarTitle: 'My classes'
};

export const FrenchSchoolStrings: ExSchoolStrings = {
  connectToExternalSourceConnectedImageAlt:
    'Illustration lorsque connecté, un téléphone affichant un crochet avec Billie Bot à ses côtés',
  connectToExternalSourceConnectedMessage:
    'Vous avez déjà connecté votre école à Google Classroom!\nChoisissez un cours et voyez comment vos élèves planifient leurs travaux.',
  connectToExternalSourceConnecting: 'Connexion en cours…',
  connectToExternalSourceConnectionErrorMessage:
    'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le support si l’erreur persiste.',
  connectToExternalSourceNotConnectedMessage:
    'Lors de la connexion, une demande vous sera faite d’autoriser Studyo Go à obtenir la liste de vos cours Classroom.\nIl se pourrait que vous deviez demander à votre administrateur d’activer Studyo Go pour votre école ou district.',
  connectToExternalSourceNotSubscribedMessage:
    'Vous devez posséder une souscription active pour pouvoir connecter votre école avec Google Classroom.',
  connectToExternalSourcePrivacyPolicy: 'Politique de confidentialité',
  connectToExternalSourceSubscribeButtonTitle: 'S’abonner',
  connectToExternalSourceTitle: 'Google Classroom',

  createPersonalSchoolTitle: 'Créer une école personnelle',
  createSchoolErrorMessage:
    'Une erreur inattendue est survenue lors de la création de l’école.\nVeuillez essayer un peu plus tard ou contactez le support si l’erreur persiste.',
  createSchoolErrorTitle: 'Oups',
  createSchoolSaveButtonLabel: 'Créer',
  createSchoolTitle: 'Créer une école',
  createSharedSchoolTitle: 'Créer une école partagée',

  editSchoolErrorMessage:
    'Une erreur inattendue est survenue lors de la modification de l’école.\nVeuillez essayer un peu plus tard ou contactez le support si l’erreur persiste.',
  editSchoolErrorTitle: 'Oups',
  editSchoolNameInputLabel: 'Nom de votre école',
  editSchoolNameInputPlaceholder: 'Nom',
  editSchoolSaveButtonLabel: 'Sauvegarder',
  editSchoolSubtitleInputLabel: 'Sous-titre (optionnel)',
  editSchoolSubtitleInputPlaceholder: 'Sous-titre',
  editSchoolTitle: 'Modifier les détails de l’école',

  inviteStudentsDialogTitle: 'Inviter vos élèves',
  inviteStudentsErrorMessage:
    'Une erreur est survenue lors de l’envoi de l’invitation.\nVeuillez essayer un peu plus tard ou contactez le support si l’erreur persiste.',
  inviteStudentsInviteButtonTitle: 'Inviter mes élèves',
  inviteStudentsMessage:
    'Vos élèves recevront une demande dans leur planificateur. Une fois acceptée, vous aurez accès à ce dernier en lecture seule.',
  inviteStudentsSuccessMessage:
    'L’invitation a été envoyée! Vous pouvez maintenant demander à vos élèves de se connecter à Studyo Go et ils verront l’invitation.',
  inviteStudentsWithCodeMessage:
    'Vous pouvez aussi partager ce code avec vos élèves. Une fois utilisé, vous aurez accès à leur planificateur.',

  noCoursesConnectExternalSourceButtonTitle: 'Ajouter mes cours',
  noCoursesManageExternalSourceButtonTitle: 'Gérer mes cours',
  noCoursesMessage: 'Vous n’avez actuellement aucun cours connecté à Studyo Go',
  noCoursesTitle: 'Aucun cours',

  settingsCoursesTitle: 'Cours',
  settingsGeneralSectionTitle: 'Réglages de l’école',
  settingsSchoolEditTitle: 'Détails de l’école',

  sidebarCollapse: 'Réduire',
  sidebarConnectClassroomSubtitle: 'de Google Classroom',
  sidebarConnectClassroomTitle: 'Ajouter mes cours',
  sidebarConnectClassroomTooltip: 'Ajouter mes cours de Google Classroom',
  sidebarDemoCourseSubtitle: 'Explorez ce que Studyo Go a à offrir!',
  sidebarEditSchoolTitle: 'Détails de l’école',
  sidebarEditSchoolTooltip: 'Détails de l’école',
  sidebarExpandTooltip: 'Agrandir',
  sidebarHideDemoCoursesTitle: 'Cacher le cours exemple',
  sidebarHideDemoCoursesTooltip: 'Cacher le cours exemple',
  sidebarManageClassesSubtitle: 'de Google Classroom',
  sidebarManageClassesTitle: 'Gérer mes cours',
  sidebarManageClassesTooltip: 'Gérer mes cours de Google Classroom',
  sidebarMobileShowButtonTitle: 'Afficher tous mes cours',
  sidebarNoClasses: 'Aucun cours',
  sidebarOpenStudentPlannerTitle: 'Ouvrir l’agenda d’un élève',
  sidebarOpenStudentPlannerTooltip: 'Ouvrir l’agenda d’un élève',
  sidebarReloadTooltip: 'Rafraîchir mes cours',
  sidebarShowDemoCoursesTitle: 'Afficher le cours exemple',
  sidebarShowDemoCoursesTooltip: 'Afficher le cours exemple',
  sidebarStudentCountLabel: (studentsUsingToday, total) =>
    `${studentsUsingToday}/${total} élève${studentsUsingToday > 1 ? 's utilisent' : ' utilise'} Studyo Go`,

  sidebarTitle: 'Mes cours'
};
