import { ServiceContainer } from '@/providers';
import { AnalyticsService, LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { computed, makeObservable } from 'mobx';
import {
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';
import { AppRenamePlannerViewModel, RenamePlannerViewModel } from './RenamePlannerViewModel';

export interface RenamePlannerDialogViewModel extends RenamePlannerViewModel, StaticDialogViewModel {}

export class AppRenamePlannerDialogViewModel extends AppRenamePlannerViewModel implements RenamePlannerDialogViewModel {
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.apply());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  constructor(
    plannerId: string,
    onClose: (() => void) | undefined,
    onSaveError: ((error: Error) => void) | undefined,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    super(plannerId, onClose, onSaveError, userStore, localization, analytics);
    makeObservable(this);

    this._saveButtonConfig.title = () => localization.localizedStrings.planners.renameButton;
  }

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isApplying;
    this._saveButtonConfig.isEnabled = !this.isApplying && this.canApply;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  readonly kind = 'static' as const;

  readonly supplementaryActions: DialogActionButtonConfiguration[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearError() {}

  async dismiss() {
    this._onClose?.();
    await Promise.resolve();
  }
}
