import { ServiceContainer } from '@/providers';
import { AnalyticsService, LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { computed, makeObservable } from 'mobx';
import {
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';
import { AppCreatePlannerViewModel, CreatePlannerViewModel } from './CreatePlannerViewModel';

export interface CreatePlannerDialogViewModel extends CreatePlannerViewModel, StaticDialogViewModel {}

export class AppCreatePlannerDialogViewModel extends AppCreatePlannerViewModel implements CreatePlannerDialogViewModel {
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.apply());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  constructor(
    onClose: (newPlannerId: string | undefined) => void,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    super(userStore, localization, analytics, onClose);
    makeObservable(this);

    this._saveButtonConfig.title = () => localization.localizedStrings.planners.createButton;
  }

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isApplying;
    this._saveButtonConfig.isEnabled = !this.isApplying;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  readonly kind = 'static' as const;

  readonly supplementaryActions: DialogActionButtonConfiguration[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearError() {}

  async dismiss() {
    this._onClose(undefined);
    await Promise.resolve();
  }
}
