import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { makeObservable } from 'mobx';
import { WorkTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type NotesLoadable = LoadableValue<Note[]>;

export class AppNotesLoadable extends BaseLoadableValue<Note[]> implements NotesLoadable {
  constructor(
    private readonly _plannerId: string,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  protected async loadData(): Promise<Note[]> {
    return await this._workTransport.getNotes(this._plannerId, undefined, false);
  }
}
