import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { SettingsCard } from '../../shared';
import { SchoolCourseSections } from './SchoolCourseSections';
import { SchoolEdit } from './SchoolEdit';
import { SchoolScheduleCycleList } from './SchoolScheduleCycleList';
import { SchoolSettingsArchivedSchoolBanner } from './SchoolSettingsArchivedSchoolBanner';
import { SchoolSettingsDangerActions } from './danger-actions';

export interface SchoolSettingsProps {
  sx?: SxProps;
  className?: string;
  school: SchoolInformation;
}

export const SchoolSettings = observer(({ school, sx = [], className }: SchoolSettingsProps) => {
  const { featureFlag, userStore } = useServices();
  const schoolId = school.school!.id;
  const isArchivedSchool = school?.school?.isArchived === true;
  const isSharedSchoolFeatureEnabled = featureFlag.isEnabled('shared-schools');
  const userId = userStore.user.userId;

  const isSchoolAdmin = school.administrators.find((a) => a.userId === userId) != null;

  return (
    <Box sx={{ ...sx, overflow: 'auto', pb: 2, pt: 1 }} className={className}>
      <Stack spacing={2}>
        {isArchivedSchool && <SchoolSettingsArchivedSchoolBanner school={school} canUnarchive={isSchoolAdmin} />}

        <SettingsCard
          sx={sx}
          className={className}
          title={LocalizedStrings.settings.school.details.generalInformation.title()}
          canBeCollapsed
        >
          <SchoolEdit schoolId={schoolId} sx={{ p: 2 }} />
        </SettingsCard>

        {!isArchivedSchool && <SchoolCourseSections schoolId={schoolId} />}

        {isSharedSchoolFeatureEnabled && !isArchivedSchool && (
          <SchoolScheduleCycleList schoolId={schoolId} showSource={false} />
        )}

        {isSchoolAdmin && !isArchivedSchool && <SchoolSettingsDangerActions school={school} />}
      </Stack>
    </Box>
  );
});
