import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { isBefore, isSameDay } from 'date-fns';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { Day, UserDashboardInfo, compareTerms, dateToPBDate, dayToDate } from '../../../models';
import { ServiceContainer } from '../../../providers';
import { LocalizationService } from '../../../services';
import { ScheduleCycleDataStore, UserDataStore } from '../../../stores';
import { ScheduleCycleKind } from './ScheduleCycleUtils';

export interface ScheduleCycleDetailsEditViewModel {
  readonly isNew: boolean;
  name: string | undefined;
  startDate: Date;
  endDate: Date;
  scheduleKind: ScheduleCycleKind;
  daysPerCycle: number;
  firstCycleDay: number;
  isPerDayPeriodSchedules: boolean;
  canSave: boolean;
  readonly terms: Term[];
  readonly canEditFirstCycleDay: boolean;
  cycleDayNames: string[];
  readonly canEditCycleNames: boolean;

  addOrUpdateTerm(termId: string | undefined, name: string, startDay: Day, endDay: Day): void;
  deleteTerm(id: string): void;
}

export class AppScheduleCycleDetailsEditViewModel implements ScheduleCycleDetailsEditViewModel {
  @observable private _name: string | undefined;
  @observable private _startDate: Date;
  @observable private _endDate: Date;
  @observable private _scheduleKind: ScheduleCycleKind;
  @observable private _daysPerCycle = 9;
  @observable private _firstCycleDay = 1;

  constructor(
    private readonly _scheduleCycleId: string,
    private readonly _userDashboard: UserDashboardInfo,
    private readonly _useStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);

    this._name = this.scheduleCycle.name;
    this._startDate = dayToDate(this.scheduleCycle.startDay!);
    this._endDate = dayToDate(this.scheduleCycle.endDay!);
    this._daysPerCycle = this.scheduleCycle.cycleDayCount;

    if (this.scheduleCycle.isDayOfWeekAligned) {
      this._scheduleKind = this.scheduleCycle.cycleDayCount === 7 ? 'week' : 'multiple-week';
    } else {
      this._scheduleKind = 'cycle-day';
    }

    reaction(
      () => [this._name, this._startDate, this._endDate, this._firstCycleDay, this._scheduleKind, this._daysPerCycle],
      () => this.updateScheduleCycle()
    );
  }

  isNew = false;

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._useStore.getScheduleCycleStore(this._scheduleCycleId, this._userDashboard);
  }

  @computed
  private get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  get name(): string | undefined {
    return this._name;
  }

  set name(value: string | undefined) {
    this._name = value;
  }

  @computed
  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    if (!isSameDay(value, this._startDate)) {
      this._startDate = value;
    }
  }

  @computed
  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    if (isBefore(this.startDate, value) && !isSameDay(value, this._endDate)) {
      this._endDate = value;
    }
  }

  @computed
  get scheduleKind(): ScheduleCycleKind {
    return this._scheduleKind;
  }

  set scheduleKind(value: ScheduleCycleKind) {
    this._scheduleKind = value;

    if (value === 'week') {
      this.daysPerCycle = 7;
    } else if (value === 'multiple-week') {
      this.daysPerCycle = 14;
    }
  }

  @computed
  get daysPerCycle(): number {
    return this._daysPerCycle;
  }

  set daysPerCycle(value: number) {
    this._daysPerCycle = value;
  }

  @computed
  get firstCycleDay(): number {
    return this._firstCycleDay;
  }

  set firstCycleDay(value: number) {
    this._firstCycleDay = value;
  }

  get isPerDayPeriodSchedules() {
    return false;
  }

  set isPerDayPeriodSchedules(value: boolean) {
    throw new Error('Not supported');
  }

  @computed
  get canSave(): boolean {
    return (this.name ?? '').length > 0;
  }

  readonly canEditCycleNames = true;

  @computed
  get terms(): Term[] {
    return Array.from(this.scheduleCycle.terms)
      .filter((t) => !t.shouldDelete)
      .sort((a, b) => compareTerms(a, b, this._localization.currentLocale));
  }

  @computed
  get cycleDayNames(): string[] {
    return this.scheduleCycle.cycleDayNames;
  }

  set cycleDayNames(value: string[]) {
    void this.scheduleCycleStore.updateScheduleCycleDayNames(value);
  }

  readonly canEditFirstCycleDay = false;

  @action
  addOrUpdateTerm(termId: string | undefined, name: string, startDay: Day, endDay: Day) {
    void this.scheduleCycleStore.createOrUpdateTerm(termId, name, startDay, endDay);
  }

  @action
  deleteTerm(id: string) {
    void this.scheduleCycleStore.deleteTerm(id);
  }

  private updateScheduleCycle() {
    void this.scheduleCycleStore.updateScheduleDetails(
      this._name ?? '',
      dateToPBDate(this._startDate),
      dateToPBDate(this._endDate),
      this._daysPerCycle,
      this._scheduleKind !== 'cycle-day'
    );
  }
}
