import { Day, dayToString } from '@/models';
import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { useTheme } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Droppable } from '../../../../utils';
import { ScheduleCycleSpecialDaysTableCell } from './ScheduleCycleSpecialDaysTableCell';

export interface ScheduleCycleSpecialDaysCalendarTableCellProps {
  style?: CSSProperties;
  className?: string;
  day: CalendarDay;
  onSelect?: (day: Day) => void;
  isCurrentMonth: (day: Day) => boolean;
  isSelected: boolean;
  canInteract: boolean;
}

export const ScheduleCycleSpecialDaysCalendarTableCell = observer(
  ({
    style,
    className,
    day,
    onSelect,
    isCurrentMonth,
    isSelected,
    canInteract
  }: ScheduleCycleSpecialDaysCalendarTableCellProps) => {
    const dayIsCurrentMonth = day.day != null && isCurrentMonth(day.day);
    const theme = useTheme();

    return (
      <Droppable id={`day/${dayToString(day.day!)}`} acceptedTypes={['special-days']} disabled={!canInteract}>
        {(setNodeRef, isOver) => (
          <td
            ref={setNodeRef}
            style={{
              ...style,
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: isOver ? theme.palette.primary.main : 'transparent'
            }}
            className={clsx(
              className,
              !dayIsCurrentMonth && 'otherMonth',
              isSelected && 'selected',
              canInteract && onSelect != null && 'selectable'
            )}
          >
            <ScheduleCycleSpecialDaysTableCell
              label={String(day.day?.day ?? '')}
              cycleDayLabel={!day.isDayOfWeekCycleDayName ? day.cycleDayName : ''}
              annotations={day.annotations}
              onClick={canInteract && onSelect != null ? () => onSelect(day.day!) : undefined}
            />
          </td>
        )}
      </Droppable>
    );
  }
);
