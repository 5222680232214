import { useActivePlannerId, useViewModel } from '@/hooks';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, getMonth, getYear } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FormPopoverHeader } from '../../../utils';
import { UserDashboardCalendarDatePickerMonth } from './UserDashboardCalendarDatePickerMonth';

export interface UserDashboardCalendarDatePickerProps {
  sx?: SxProps;
  className?: string;
  initialDate?: Date;
  selectedDates: () => Date[];
  highlightedDates: (startDate: Date, endDate: Date) => Date[];
  onSelectDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const UserDashboardCalendarDatePicker = observer(
  ({
    sx = [],
    className,
    initialDate,
    selectedDates,
    highlightedDates,
    onSelectDate,
    minDate,
    maxDate
  }: UserDashboardCalendarDatePickerProps) => {
    const plannerId = useActivePlannerId();
    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createUserDashboardCalendarDatePickerViewModel(
          plannerId,
          initialDate,
          selectedDates,
          highlightedDates,
          minDate,
          maxDate
        ),
      [plannerId]
    );

    return (
      <Stack sx={sx} className={className} spacing={2}>
        <FormPopoverHeader
          title={format(viewModel.currentDate, 'MMMM y')}
          isLoading={viewModel.isFetching}
          actions={[
            { icon: <ArrowBackIosRounded fontSize="small" />, onClick: () => viewModel.goToPreviousMonth() },
            { icon: <ArrowForwardIosRounded fontSize="small" />, onClick: () => viewModel.goToNextMonth() }
          ]}
        />

        <UserDashboardCalendarDatePickerMonth
          sx={{ pb: 1, px: 0.5 }}
          weeks={viewModel.weeks}
          monthInfo={{ month: getMonth(viewModel.currentDate), year: getYear(viewModel.currentDate) }}
          getDayInfo={(date) => viewModel.getDayInfoForDate(date)}
          onSelectDate={onSelectDate}
        />
      </Stack>
    );
  }
);
