import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { dateToPBDate } from '@/models';
import { Box, Grid2, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { parse } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isSxArray } from '../../../../utils';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../../utils';
import { UserDashboardCalendarDaySpecificDateView } from './UserDashboardCalendarDaySpecificDateView';

export interface UserDashboardCalendarDayViewProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarDayView = observer(({ sx = [], className }: UserDashboardCalendarDayViewProps) => {
  const { dateService, storeInvalidator } = useServices();
  const plannerId = useActivePlannerId();
  const [searchParams] = useSearchParams();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createUserDashboardCalendarDayViewModel(plannerId),
    [plannerId]
  );

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const dateParam = searchParams.get('date');
  const currentDate = dateParam ? parse(dateParam, 'y-MM-dd', new Date()) : dateService.now;

  useEffect(() => {
    void viewModel.fetchDays(currentDate, false);
  }, [currentDate]);

  useEffect(() => {
    void viewModel.fetchDays(currentDate, true);
  }, [storeInvalidator.calendarRevision]);

  useSync('UserDashboardCalendarDay', () => viewModel.fetchDays(currentDate, true), [plannerId, currentDate]);

  return (
    <Box
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex'
        }}
      >
        <Grid2
          container
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Grid2
            size={{ xs: 12, lg: 10, xl: 8 }}
            offset={{ lg: 1, xl: 2 }}
            sx={{
              height: '100%'
            }}
          >
            <BackgroundImageScreenPaper
              sx={{
                maxHeight: !isExtraSmallScreen ? '100%' : undefined,
                height: isExtraSmallScreen ? '100%' : undefined,
                width: '100%',
                overflow: 'hidden',
                display: 'flex'
              }}
            >
              <UpdatablePresenter
                isFlex
                sx={{ flex: 1, overflow: 'hidden' }}
                viewModel={viewModel}
                renderData={() => (
                  <UserDashboardCalendarDaySpecificDateView
                    viewModel={viewModel.getViewModelForDay(dateToPBDate(currentDate))}
                    hasCalendarSyncError={viewModel.hasCalendarSyncError}
                    retryFetchData={() => viewModel.fetchDays(currentDate, true)}
                  />
                )}
              />
            </BackgroundImageScreenPaper>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
});
