import { TimeOfDay, timeOfDayToDate } from '@/models';
import { Theme, alpha, darken, lighten } from '@mui/material';
import { format } from 'date-fns';
import { ApplicationSettingsService } from '../../../../services';

export function formatCalendarItemTimes(start: TimeOfDay, end: TimeOfDay, showEndTime: boolean): string {
  let formattedStartTime = formatItemTime(start);

  if (!showEndTime) {
    return formattedStartTime.trim();
  }

  const formattedEndTime = formatItemTime(end);

  function removeTimeIndicatorFromStartStartTimeIfDuplicate(indicator: string) {
    if (formattedStartTime.includes(indicator) && formattedEndTime.includes(indicator)) {
      formattedStartTime = formattedStartTime.replace(indicator, '');
    }
  }

  // If both start and end time contains the same combination of am/pm, we remove it for the start time (saves space).
  removeTimeIndicatorFromStartStartTimeIfDuplicate('am');
  removeTimeIndicatorFromStartStartTimeIfDuplicate('AM');
  removeTimeIndicatorFromStartStartTimeIfDuplicate('pm');
  removeTimeIndicatorFromStartStartTimeIfDuplicate('PM');

  formattedStartTime = formattedStartTime.trim();
  return `${formattedStartTime} – ${formattedEndTime}`;
}

export function formatCalendarItemTimesLong(start: TimeOfDay, end: TimeOfDay) {
  return `${format(timeOfDayToDate(start), 'p')} - ${format(timeOfDayToDate(end), 'p')}`;
}

export function formatItemTime(time: TimeOfDay): string {
  const value = format(timeOfDayToDate(time), 'p').replace(/^0+/, '').replace(':00', '');
  return value.length > 0 ? value : '0';
}

export function formatItemStartTimeLong(time: TimeOfDay): string {
  return format(timeOfDayToDate(time), 'p');
}

export function resolvedPeriodBackgroundColor(
  color: string | undefined,
  settings: ApplicationSettingsService | undefined,
  theme: Theme
): string {
  const isDarkTheme = theme.palette.mode === 'dark';
  const resolvedColor = color ?? theme.palette.background.paper;

  if (color != null) {
    return isDarkTheme ? darken(color, 0.55) : lighten(color, 0.6);
  } else if (settings?.backgroundImage != null && !settings.reduceTransparency) {
    return alpha(resolvedColor, isDarkTheme ? 0.4 : 0.8);
  } else {
    return isDarkTheme ? lighten(resolvedColor, 0.08) : darken(resolvedColor, 0.02);
  }
}
