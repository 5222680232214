import { SchoolAccountCourseSections } from '@/models';
import { SchoolTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolAccountCourseSectionsLoadable = LoadableValue<SchoolAccountCourseSections>;

export class AppSchoolAccountCourseSectionsLoadable
  extends BaseLoadableValue<SchoolAccountCourseSections>
  implements SchoolAccountCourseSectionsLoadable
{
  constructor(
    private readonly _accountId: string,
    private readonly _schoolId: string,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<SchoolAccountCourseSections> {
    return await this._schoolTransport.getSchoolCourseSectionsForAccount(this._schoolId, this._accountId);
  }
}
