import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { SyncRounded } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { CourseSectionEditDialog, CourseSectionList, SettingsCard } from '../../shared';
import { UpdatablePresenter } from '../../utils';

interface LocationState {
  courseSectionListEditCourseId?: string;
}

export interface SchoolCourseSectionsProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  disabled?: boolean;
}

export const SchoolCourseSections = observer(
  ({ sx = [], className, schoolId, disabled = false }: SchoolCourseSectionsProps) => {
    const { connectedApps } = useServices();
    const plannerId = useActivePlannerId();
    const viewModel = useViewModel(
      (viewModels) => viewModels.createSchoolCourseSectionListViewModel(schoolId, plannerId),
      [plannerId, schoolId]
    );

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();

    function editCourseSection(id: string) {
      const newState: LocationState = { ...state, courseSectionListEditCourseId: id };
      navigate(location, { state: newState });
    }

    return (
      <SettingsCard
        sx={sx}
        className={className}
        title={LocalizedStrings.settings.school.details.classes.title()}
        subtitle={LocalizedStrings.settings.school.details.classes.subtitle()}
        canBeCollapsed
        collapsedByDefault
        actions={[
          {
            icon: <SyncRounded />,
            action: async () => connectedApps.sync(plannerId),
            showLoading: true
          }
        ]}
      >
        <UpdatablePresenter
          viewModel={viewModel}
          renderData={() => (
            <CourseSectionList
              sx={{ width: '100%' }}
              viewModel={viewModel}
              editCourse={editCourseSection}
              canManageConnectedApps={false}
              canEditCourse={!disabled}
            />
          )}
        />

        {state.courseSectionListEditCourseId != null && (
          <CourseSectionEditDialog isOpen={true} courseSectionId={state.courseSectionListEditCourseId} />
        )}
      </SettingsCard>
    );
  }
);
