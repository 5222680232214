import { ListItemIcon, MenuItem } from '@mui/material';
import _ from 'lodash';
import { ReactNode, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionMenu, ActionMenuButton } from '../../utils';

export type TableRowActionSelectionKind =
  | { case: 'link'; to: string }
  | { case: 'external-link'; to: string }
  | { case: 'action'; onSelect: () => void };

export interface TableRowAction {
  readonly text: string;
  readonly icon?: ReactNode;
  readonly selection?: TableRowActionSelectionKind;
  readonly disabled?: boolean;
  readonly isHidden?: boolean;
}

export interface TableRowActionsProps {
  actions: (TableRowAction | undefined)[];
}

export function TableRowActions({ actions }: TableRowActionsProps) {
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const actionsMenuAnchorRef = useRef<HTMLButtonElement>(null);

  function onActionSelect(action: TableRowAction) {
    setShowActionsMenu(false);
    if (action.selection?.case === 'action') {
      action.selection.onSelect();
    }
  }

  const visibleActions = _.compact(actions.filter((a) => a?.isHidden !== true));

  if (visibleActions.length === 0) {
    return undefined;
  }

  return (
    <>
      <ActionMenuButton ref={actionsMenuAnchorRef} onClick={() => setShowActionsMenu(true)} />
      <ActionMenu
        open={showActionsMenu}
        anchorEl={actionsMenuAnchorRef.current}
        onClose={() => setShowActionsMenu(false)}
      >
        {visibleActions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => onActionSelect(action)}
            disabled={action.disabled}
            {...(action.selection?.case === 'link' ? { component: Link, to: action.selection.to } : undefined)}
            {...(action.selection?.case === 'external-link'
              ? { component: 'a', href: action.selection.to, target: '_blank', rel: 'noreferrer' }
              : undefined)}
          >
            {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
            {action.text}
          </MenuItem>
        ))}
      </ActionMenu>
    </>
  );
}
