import { plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, SyncService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { computed, makeObservable } from 'mobx';
import { MainAppBarViewModel } from './MainAppBarViewModel';

export class PlannerMainAppBarViewModel implements MainAppBarViewModel {
  constructor(
    private readonly _plannerId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _syncService: SyncService = ServiceContainer.services.syncService,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    makeObservable(this);
  }

  @computed
  private get planner(): Planner {
    const planner = this._userStore.getPlannerForId(this._plannerId);

    if (planner == null) {
      throw new Error(`No available planner matching id ${this._plannerId}.`);
    }

    return planner;
  }

  @computed
  get title(): string {
    return this.planner.title;
  }

  @computed
  get isRefreshing(): boolean {
    return this._syncService.isSyncing;
  }

  @computed
  get canCreateWork(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get canPublishWork(): boolean {
    return this._plannerStore.getCanPublishWorkInPlanner(this._plannerId);
  }

  @computed
  get connectedAppsAlert(): 'error' | 'warning' | undefined {
    return this._connectedApps.getIsSyncing(this._plannerId)
      ? 'error'
      : this._connectedApps.getHasWarning(this._plannerId)
        ? 'warning'
        : undefined;
  }

  async reloadData() {
    await this._syncService.syncPlanner(this._plannerId, true);
  }
}
