import { differenceInMilliseconds } from 'date-fns';
import { find, remove } from 'lodash';
import { when } from 'mobx';
import { AutoSyncHandler, AutoSyncService, DateService, NetworkService } from '../contracts';

export type AutoSyncLogVerbosity = 'low' | 'medium' | 'high';

export class AppAutoSyncService implements AutoSyncService {
  private _handlers: AutoSyncHandler[] = [];
  private _suspendSyncCounter = 0;

  constructor(
    private readonly _network: NetworkService,
    private readonly _dateService: DateService,
    // This is more something for development. We can end up with a lot a "skipping sync" logs which can make it
    // difficult to find other logs at a glance.
    private readonly _logVerbosity: AutoSyncLogVerbosity = 'low'
  ) {
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);
  }

  addHandler(handler: Omit<AutoSyncHandler, 'timeout' | 'lastSync'>): void {
    if (find(this._handlers, { key: handler.key }) != null) {
      if (this.showLog('low')) {
        console.warn(`Handler with key "${handler.key}" already exists.`);
      }
      return;
    }

    if (handler.threshold < handler.checkInterval) {
      throw new Error(`The handler threshold must be greater or equal to the checkInterval.`);
    }

    this._handlers.push(handler);
    this.sync(handler);
    if (handler.clearWhen != null) {
      when(handler.clearWhen, () => this.removeHandler(handler.key));
    }
    this.startAutoSync(handler);
  }

  removeHandler(key: string): void {
    const handler = find(this._handlers, { key });
    if (handler == null) {
      if (this.showLog('low')) {
        console.warn(`Handler with key "${key}" does not exist`);
      }
      return;
    }

    this.stopAutoSync(handler);
    handler.onClear?.();
    remove(this._handlers, { key });
  }

  resumeSync() {
    this._suspendSyncCounter = Math.max(0, this._suspendSyncCounter - 1);
  }

  suspendSync() {
    this._suspendSyncCounter++;
  }

  /**
   * Start the sync timeout if the application is in the foreground.
   * @param handler The handler for whom to start the timeout.
   * @private
   */
  private startAutoSync(handler: AutoSyncHandler) {
    if (document.visibilityState === 'visible') {
      this.stopAutoSync(handler);
      handler.timeout = setTimeout(() => this.sync(handler), handler.checkInterval);
    }
  }

  /**
   * Clear the sync timeout.
   * @param handler The handler for whom to clear the timeout.
   * @private
   */
  private stopAutoSync(handler: AutoSyncHandler) {
    if (handler.timeout != null) {
      clearTimeout(handler.timeout);
      delete handler.timeout;
    }
  }

  /**
   * Start or stop all sync handlers depending on the application visibility.
   * @private
   */
  private handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this._handlers.forEach((handler) => this.startAutoSync(handler));
    } else {
      this._handlers.forEach((handler) => this.stopAutoSync(handler));
    }
  }

  /**
   * Sync if the `lastSync` was more than `threshold` milliseconds ago.
   * @param handler The handler to sync.
   * @private
   */
  private sync(handler: AutoSyncHandler) {
    try {
      if (this.showLog('medium')) {
        console.group(`AutoSyncService - ${handler.key}`);
      }

      const isOutdated =
        handler.lastSync == null ||
        differenceInMilliseconds(this._dateService.now, handler.lastSync) >= handler.threshold;

      if (
        !this._network.isOnline ||
        !isOutdated ||
        (handler.ignoreSuspendCount !== true && this._suspendSyncCounter > 0)
      ) {
        if (this.showLog('high')) {
          console.group('Skipping sync');
          console.log('Network is online:', this._network.isOnline);
          console.log('Out of date:', isOutdated);
          console.log('Sync suspended with count:', this._suspendSyncCounter);
          console.groupEnd();
        }

        return;
      }

      if (this.showLog('medium')) {
        console.log(`Syncing...`);
      }
      handler.sync();

      if (this.showLog('medium')) {
        console.log(`Sync succeeded.`);
      }
      handler.lastSync = this._dateService.now;
    } finally {
      console.groupEnd();
      this.startAutoSync(handler);
    }
  }

  private showLog(minimumLevel: AutoSyncLogVerbosity): boolean {
    return (
      this.autoSyncLogVerbosityNumericValue(minimumLevel) <= this.autoSyncLogVerbosityNumericValue(this._logVerbosity)
    );
  }

  private autoSyncLogVerbosityNumericValue(level: AutoSyncLogVerbosity): number {
    switch (level) {
      case 'low':
        return 0;
      case 'medium':
        return 1;
      case 'high':
        return 2;
    }
  }
}
