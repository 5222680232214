import { Locale } from '@/utils';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { format } from 'date-fns';
import { compareDays, Day, dayToDate } from '../Day';

export function compareTerms(term1: Term, term2: Term, locale: Locale): number {
  if (term1.id === '') {
    return -1;
  } else if (term2.id === '') {
    return 1;
  }

  return (
    compareDays(term1.startDay!, term2.startDay!) ||
    compareDays(term2.endDay!, term1.endDay!) ||
    term1.name.localeCompare(term2.name, locale, { sensitivity: 'base' })
  );
}

export function formatTermDates(start: Day | undefined, end: Day | undefined): string {
  if (start == null || end == null) {
    return '';
  }

  return `${format(dayToDate(start), 'PP')} - ${format(dayToDate(end), 'PP')}`;
}
