import { IconButton, ListSubheader, Stack, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactElement } from 'react';
import { isSxArray } from '../../utils';

export interface SubheaderProps {
  sx?: SxProps;
  className?: string;
  renderIcon?: (sx: SxProps) => ReactElement;
  children: string;
  disableGutters?: boolean;
  action?: { icon: ReactElement; action: () => void; tooltip?: string; color: 'primary' | 'inherit' };
}

/**
 * Uses a ListSubheader but makes it static instead of sticky and makes the background clear. Useful for
 * creating section header in a form.
 */
export const Subheader = ({ sx = [], className, renderIcon, children, disableGutters, action }: SubheaderProps) => {
  const theme = useTheme();
  return (
    <Stack
      className={className}
      direction="row"
      sx={[
        {
          alignItems: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <ListSubheader
        sx={{
          flex: 1,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          ...theme.typography.subtitle2,
          py: 1
        }}
        disableGutters={disableGutters}
      >
        {renderIcon?.({ fontSize: 'inherit', mr: 1 })}
        {children}
      </ListSubheader>
      {action && (
        <Tooltip title={action.tooltip ?? ''}>
          <IconButton color={action.color} onClick={() => action.action()}>
            {action.icon}
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
