import { EnglishDateStrings, FrenchDateStrings } from './utils/DateStrings';

export interface ExDateTimeStrings {
  readonly allDayPlannedWork: string;
  readonly plannedWorkStartTimeFormat: string;

  readonly calendarLastWeek: string;
  readonly calendarLastDay: string;
  readonly calendarSameDay: string;
  readonly calendarNextDay: string;
  readonly dayOfWeekTitle: Record<number, string>;
  readonly dayOfWeekSingleLetterTitle: Record<number, string>;
  readonly dayOfWeekShortTitle: Record<number, string>;
  readonly durationFormat: (duration: number, separator?: string) => string;
  readonly shortDurationFormat: (duration: number, separator?: string) => string;
}

export const EnglishDateTimeStrings: ExDateTimeStrings = {
  allDayPlannedWork: 'All day',
  plannedWorkStartTimeFormat: 'p',
  calendarLastWeek: "'Last' dddd",
  calendarLastDay: "'Yesterday'",
  calendarSameDay: "'Today'",
  calendarNextDay: "'Tomorrow'",
  dayOfWeekTitle: {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
  },
  dayOfWeekSingleLetterTitle: {
    1: 'M',
    2: 'T',
    3: 'W',
    4: 'T',
    5: 'F',
    6: 'S',
    7: 'S'
  },
  dayOfWeekShortTitle: {
    1: 'Mo',
    2: 'Tu',
    3: 'We',
    4: 'Th',
    5: 'Fr',
    6: 'Sa',
    7: 'Su'
  },
  durationFormat: EnglishDateStrings.durationFormat,
  shortDurationFormat: EnglishDateStrings.shortDurationFormat
};

export const FrenchDateTimeStrings: ExDateTimeStrings = {
  allDayPlannedWork: 'Toute la journée',
  plannedWorkStartTimeFormat: 'p',
  calendarLastWeek: "dddd 'dernier'",
  calendarLastDay: "'Hier'",
  calendarSameDay: "'Aujourd’hui'",
  calendarNextDay: "'Demain'",
  dayOfWeekTitle: {
    1: 'Lundi',
    2: 'Mardi',
    3: 'Mercredi',
    4: 'Jeudi',
    5: 'Vendredi',
    6: 'Samedi',
    7: 'Dimanche'
  },
  dayOfWeekSingleLetterTitle: {
    1: 'L',
    2: 'M',
    3: 'M',
    4: 'J',
    5: 'V',
    6: 'S',
    7: 'D'
  },
  dayOfWeekShortTitle: {
    1: 'Lu',
    2: 'Ma',
    3: 'Me',
    4: 'Je',
    5: 'Ve',
    6: 'Sa',
    7: 'Di'
  },
  durationFormat: FrenchDateStrings.durationFormat,
  shortDurationFormat: FrenchDateStrings.shortDurationFormat
};
