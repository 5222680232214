import i18next from 'i18next';
import { observable, runInAction } from 'mobx';
import { initReactI18next } from 'react-i18next';
import enStrings from './resources/strings/locales/en/translation.json';
import frStrings from './resources/strings/locales/fr/translation.json';
import { Locale } from './utils';

void i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: { escapeValue: true },
  resources: {
    en: { translation: enStrings },
    fr: { translation: frStrings }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(
    ['../resources/strings/locales/en/translation.json', '../resources/strings/locales/fr/translation.json'],
    ([en, fr]) => {
      if (en) {
        i18next.addResourceBundle('en', 'translation', en, true, true);
      }
      if (fr) {
        i18next.addResourceBundle('fr', 'translation', fr, true, true);
      }

      void changeLanguage(i18next.language as Locale);
    }
  );
}

const i18n = observable({ t: i18next.t });

export default i18n;

export async function changeLanguage(locale: Locale) {
  await i18next.changeLanguage(locale);
  runInAction(() => {
    i18n.t = i18next.t;
  });
}
