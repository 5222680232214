import { css } from '@emotion/css';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../utils';
import { Welcome } from './Welcome';

export interface WelcomePageProps {
  sx?: SxProps;
  className?: string;
}

export const WelcomePage = observer(({ sx = [], className }: WelcomePageProps) => {
  const childrenClassName = css([{ width: '100%', height: '100%' }]);

  return (
    <Box
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Welcome className={childrenClassName} />
    </Box>
  );
});
