import i18n from 'translate';

export const ScheduleCycleEditMasterScheduleStrings = {
  allYearTermName: () => i18n.t('scheduleCycle.edit.masterSchedule.allYearTermName'),
  emptyGridMessage: () => i18n.t('scheduleCycle.edit.masterSchedule.emptyGridMessage'),
  emptyPeriodTitle: () => i18n.t('scheduleCycle.edit.masterSchedule.emptyPeriodTitle'),
  hasOtherOccurrencesWithSameTarget: () =>
    i18n.t('scheduleCycle.edit.masterSchedule.hasOtherOccurrencesWithSameTarget'),
  inheritedActivityScheduleFromOtherTerm: () =>
    i18n.t('scheduleCycle.edit.masterSchedule.inheritedActivityScheduleFromOtherTerm'),
  multiplePeriodsWithSameLabelWarning: () =>
    i18n.t('scheduleCycle.edit.masterSchedule.multiplePeriodsWithSameLabelWarning'),
  noScheduleForDay: () => i18n.t('scheduleCycle.edit.masterSchedule.noScheduleForDay'),
  periodMultipleActivitySchedulesInTermTooltip: () =>
    i18n.t('scheduleCycle.edit.masterSchedule.periodMultipleActivitySchedulesInTermTooltip'),
  scheduleTagPickerLabel: () => i18n.t('scheduleCycle.edit.masterSchedule.scheduleTagPickerLabel'),
  termPickerTitle: () => i18n.t('scheduleCycle.edit.masterSchedule.termPickerTitle')
};
