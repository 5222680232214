import LateIcon from '@mui/icons-material/WarningRounded';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface PlannerListItemLateIndicatorProps {
  className?: string;

  sx?: SxProps;
}

export const PlannerListItemLateIndicator = observer(({ className, sx }: PlannerListItemLateIndicatorProps) => (
  <LateIcon color="error" className={className} sx={sx} />
));
