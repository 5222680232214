import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { ApplicationSettingsStorage } from '../../services';
import { StoreInvalidator } from '../contracts';

export class AppStoreInvalidator implements StoreInvalidator {
  @observable private _calendarRevision = 0;
  @observable private _schoolsRevision = 0;
  @observable private _plannersRevision = 0;

  constructor(settingsStorage: ApplicationSettingsStorage) {
    makeObservable(this);

    reaction(
      () => settingsStorage.calendarShowFreePeriods,
      () => this.invalidateCalendar()
    );
  }

  @computed
  get calendarRevision(): number {
    return this._calendarRevision;
  }

  @computed
  get schoolsRevision(): number {
    return this._schoolsRevision;
  }

  @computed
  get plannersRevision(): number {
    return this._plannersRevision;
  }

  @action
  invalidateCalendar() {
    this._calendarRevision++;
  }

  @action
  invalidateSchools(): void {
    this._schoolsRevision++;
  }

  @action
  invalidatePlanners(): void {
    this._plannersRevision++;
  }

  @action
  invalidateAll(): void {
    this._calendarRevision++;
    this._schoolsRevision++;
    this._plannersRevision++;
  }
}
