import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { TimeSlotGroup } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/time_slot_group_pb';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { Planners } from '@buf/studyo_studyo-today-planners.connectrpc_es/studyo/today/planners/v1/planners_connect';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { Schools } from '@buf/studyo_studyo-today-schools.connectrpc_es/studyo/today/schools/v1/schools_connect';
import { Timestamp, WorkIcons } from '../../models';
import { CreatePublishedWorkRequest, UpdatePublishedWorkRequest, WorkTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppWorkTransportService extends AppBaseTransportService implements WorkTransportService {
  async getWork(workId: string): Promise<Work> {
    return await this.performReadRequest(
      'getWork',
      Planners,
      Planners.methods.getWork,
      { workId },
      { isDemoObject: this.isDemoId(workId), supportsDemoMode: true }
    );
  }

  async getWorks(
    plannerId: string,
    courseSectionId: string | undefined,
    externalSourceName: string | undefined,
    includeCancelledWorks: boolean
  ): Promise<Work[]> {
    const response = await this.performReadRequest(
      'getWorks',
      Planners,
      Planners.methods.getWorks,
      {
        plannerId,
        courseSectionId,
        externalSourceName,
        includeCancelledWorks
      },
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );

    return response.works;
  }

  async createWork(work: Work): Promise<Work> {
    return await this.performWriteRequest(
      'createWork',
      Planners,
      Planners.methods.createWork,
      {
        title: work.title,
        iconId: work.iconId,
        dueTime: work.dueTime,
        description: work.description,
        isDueAllDay: work.isDueAllDay,
        plannerId: work.plannerId,
        importance: work.importance,
        courseSectionId: work.courseSectionId,
        attachments: work.attachments
      },
      { isDemoObject: this.isDemoId(work.plannerId) }
    );
  }

  async updateWork(work: Work): Promise<Work> {
    return await this.performWriteRequest(
      'updateWork',
      Planners,
      Planners.methods.updateWork,
      {
        workId: work.id,
        title: work.title,
        iconId: work.iconId,
        dueTime: work.dueTime,
        description: work.description,
        isDueAllDay: work.isDueAllDay,
        importance: work.importance,
        courseSectionId: work.courseSectionId,
        attachments: work.attachments,
        syncToken: work.syncToken
      },
      { isDemoObject: this.isDemoId(work.plannerId) }
    );
  }

  async setWorkStatus(workId: string, status: WorkStatus, syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'setWorkStatus',
      Planners,
      Planners.methods.setWorkStatus,
      { workId, newStatus: status, syncToken },
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async getNotes(
    plannerId: string,
    courseSectionId: string | undefined,
    includeArchivedNotes: boolean
  ): Promise<Note[]> {
    const response = await this.performReadRequest(
      'getNotes',
      Planners,
      Planners.methods.getNotes,
      { plannerId, courseSectionId, includeArchivedNotes },
      { isDemoObject: this.isDemoId(plannerId) }
    );

    return response.notes;
  }

  async getNote(noteId: string): Promise<Note> {
    return await this.performReadRequest(
      'getNote',
      Planners,
      Planners.methods.getNote,
      { noteId },
      { isDemoObject: this.isDemoId(noteId) }
    );
  }

  async createNote(note: Note): Promise<Note> {
    return await this.performWriteRequest('createNote', Planners, Planners.methods.createNote, {
      plannerId: note.plannerId,
      text: note.text,
      time: note.time,
      isAllDay: note.isAllDay,
      courseSectionId: note.courseSectionId
    });
  }

  async updateNote(note: Note): Promise<Note> {
    return await this.performWriteRequest('updateNote', Planners, Planners.methods.updateNote, {
      noteId: note.id,
      text: note.text,
      time: note.time,
      isAllDay: note.isAllDay,
      courseSectionId: note.courseSectionId,
      syncToken: note.syncToken
    });
  }

  async setNoteStatus(noteId: string, isArchived: boolean, syncToken: bigint): Promise<Note> {
    return await this.performWriteRequest(
      'setNoteStatus',
      Planners,
      Planners.methods.setNoteStatus,
      { noteId, isArchived, syncToken },
      { isDemoObject: this.isDemoId(noteId) }
    );
  }

  async getAvailableTimeSlots(
    plannerId: string,
    workId: string,
    timezone: string,
    timeSlotDuration: number,
    increment: number,
    extraDays: number,
    plannedWorkId?: string
  ): Promise<TimeSlotGroup[]> {
    const response = await this.performReadRequest(
      'getAvailableTimeSlots',
      Planners,
      Planners.methods.getAvailableTimeSlots,
      { plannerId, workId, timezone, timeSlotDuration, increment, extraDays, plannedWorkId },
      { isDemoObject: this.isDemoId(plannerId) }
    );

    return response.timeSlotGroups;
  }

  async createPlannedWork(workId: string, timeSlot: TimeSlot, stepIds: string[], syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'createPlannedWork',
      Planners,
      Planners.methods.createPlannedWork,
      { timeSlot, workId, syncToken, stepIds, useStepIds: true },
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async updatePlannedWork(
    plannedWorkId: string,
    workId: string,
    timeSlot: TimeSlot,
    stepIds: string[],
    syncToken: bigint
  ): Promise<Work> {
    return await this.performWriteRequest(
      'updatePlannedWork',
      Planners,
      Planners.methods.updatePlannedWork,
      { plannedWorkId, workId, timeSlot, syncToken, stepIds, useStepIds: true },
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async cancelPlannedWork(plannedWorkId: string, workId: string, syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'cancelPlannedWork',
      Planners,
      Planners.methods.cancelPlannedWork,
      { plannedWorkId, workId, syncToken },
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async setWorkSteps(workId: string, steps: WorkStep[], syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'setWorkSteps',
      Planners,
      Planners.methods.setWorkSteps,
      { workId, steps, useSteps: true, syncToken },
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async getPublishedWork(publishedWorkId: string, schoolId: string): Promise<PublishedWork> {
    return await this.performReadRequest(
      'getPublishedWork',
      Schools,
      Schools.methods.getPublishedWork,
      {
        publishedWorkId,
        schoolId
      },
      { isDemoObject: this.isDemoId(publishedWorkId) }
    );
  }

  async getPublishedWorks(schoolId: string, courseSectionId: string | undefined): Promise<PublishedWork[]> {
    const response = await this.performReadRequest(
      'getPublishedWorks',
      Schools,
      Schools.methods.getPublishedWorks,
      {
        schoolId,
        courseSectionId
      },
      { isDemoObject: this.isDemoId(schoolId) || (courseSectionId != null && this.isDemoId(courseSectionId)) }
    );

    return response.publishedWorks;
  }

  async createPublishedWork(
    courseSectionId: string,
    schoolId: string,
    plannerId: string,
    request: CreatePublishedWorkRequest
  ): Promise<PublishedWork> {
    return await this.performWriteRequest('createPublishedWork', Schools, Schools.methods.createPublishedWork, {
      schoolId,
      plannerId,
      courseSectionId,
      title: request.title,
      iconId: request.iconId,
      description: request.description,
      importance: request.importanceLevel,
      dueTime: request.dueTime != null ? Timestamp.fromDate(request.dueTime) : undefined,
      isDueAllDay: request.isDueAllDay,
      maxGrade: request.maxGrade,
      recipientIds: request.recipientIds,
      status: request.status,
      attachments: request.attachments,
      scheduledPublishTime:
        request.scheduledPublishedTime != null ? Timestamp.fromDate(request.scheduledPublishedTime) : undefined,
      alsoPublishToExternalSourceName: request.alsoPublishToExternalSourceName
    });
  }

  async updatePublishedWork(
    publishedWorkId: string,
    schoolId: string,
    request: UpdatePublishedWorkRequest,
    syncToken: bigint
  ): Promise<PublishedWork> {
    return await this.performWriteRequest('updatePublishedWork', Schools, Schools.methods.updatePublishedWork, {
      publishedWorkId,
      schoolId,
      title: request.title,
      iconId: request.iconId,
      description: request.description,
      importance: request.importanceLevel,
      dueTime: request.dueTime != null ? Timestamp.fromDate(request.dueTime) : undefined,
      isDueAllDay: request.isDueAllDay,
      maxGrade: request.maxGrade,
      recipientIds: request.recipientIds,
      status: request.status,
      attachments: request.attachments,
      scheduledPublishTime:
        request.scheduledPublishedTime != null ? Timestamp.fromDate(request.scheduledPublishedTime) : undefined,
      syncToken
    });
  }

  async setPublishedWorkStatus(
    publishedWorkId: string,
    schoolId: string,
    status: PublishedWorkStatus,
    scheduledPublishedTime: Date | undefined,
    syncToken: bigint
  ): Promise<PublishedWork> {
    return await this.performWriteRequest('setPublishedWorkStatus', Schools, Schools.methods.setPublishedWorkStatus, {
      publishedWorkId,
      schoolId,
      status,
      scheduledPublishTime: scheduledPublishedTime != null ? Timestamp.fromDate(scheduledPublishedTime) : undefined,
      syncToken
    });
  }

  async getWorkIcons(): Promise<WorkIcons> {
    const response = await this.performReadRequest('getWorkIcons', Planners, Planners.methods.getWorkIcons, {});

    const iconsById = response.workIcons.reduce(
      (value, icon) => value.set(icon.iconId, icon),
      new Map<string, WorkIcon>()
    );

    return {
      defaultIconId: response.defaultIconId,
      iconsById,
      externalBadgeUrl: response.externalBadgeUrl
    };
  }

  createCopyOfWork(): Promise<Work> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  distributeWork(): Promise<Work[]> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  repeatWork(): Promise<Work[]> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  createCopyOfNote(): Promise<Note> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  distributeNote(): Promise<Note[]> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  repeatNote(): Promise<Note[]> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  createCopyOfPublishedWork(): Promise<PublishedWork> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  distributePublishedWork(): Promise<PublishedWork[]> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }

  repeatPublishedWork(): Promise<PublishedWork[]> {
    // TODO: Api not available
    throw new Error('Not implemented');
  }
}
