import { WorkPlannedWorksViewModel } from '@/viewmodels';
import { AddRounded } from '@mui/icons-material';
import { List, Popover, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useServices } from '../../../hooks';
import { FormPopoverActions, FormPopoverHeader, Subheader } from '../../utils';
import { PlannedWorkEditDialog } from '../planning';
import { WorkDetailsPlannedWorkCard } from './WorkDetailsPlannedWorkCard';

interface LocationState {
  workDetailsPlannedWorksListPopoverShowCreatePlannedWork?: boolean;
}

export interface WorkDetailsPlannedWorksListPopoverProps {
  sx?: SxProps;
  className?: string;
  viewModel: WorkPlannedWorksViewModel;
  open: boolean;
  onClose: () => void;
  anchorEl?: HTMLElement;
}

export const WorkDetailsPlannedWorksListPopover = observer(
  ({ open, anchorEl, sx = [], className, viewModel, onClose }: WorkDetailsPlannedWorksListPopoverProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;

    function close() {
      onClose();
      return Promise.resolve();
    }

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();

    function showCreatePlannedWork() {
      const newState: LocationState = { ...state, workDetailsPlannedWorksListPopoverShowCreatePlannedWork: true };
      navigate(location, { state: newState });
    }

    return (
      <>
        <Popover
          open={open}
          anchorEl={anchorEl}
          sx={sx}
          className={className}
          slotProps={{ paper: { sx: { width: 360 } } }}
          elevation={1}
          onClose={() => void close()}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <FormPopoverHeader
            title={strings.plannedWorkListTitle}
            actions={!viewModel.isReadOnly ? [{ icon: <AddRounded />, onClick: showCreatePlannedWork }] : undefined}
          />
          <List sx={{ px: 2, py: 1 }}>
            <Stack spacing={1}>
              {viewModel.nextPlannedWorks.length > 0 && (
                <Stack>
                  <Subheader>{strings.plannedWorkListUpcomingSectionTitle}</Subheader>

                  <Stack spacing={1}>
                    {viewModel.nextPlannedWorks.map((plannedWork) => (
                      <WorkDetailsPlannedWorkCard
                        key={plannedWork.plannedWork.id}
                        plannedWork={plannedWork}
                        onDelete={() => viewModel.cancelPlannerWork(plannedWork.plannedWork.id)}
                        isReadOnly={viewModel.isReadOnly}
                        showHeader={false}
                        canLinkSteps={viewModel.canLinkPlannedWorkToSteps}
                        makeLinkStepsViewModel={(plannedWork) => viewModel.makeLinkStepsViewModel(plannedWork)}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}

              {viewModel.pastPlannedWorks.length > 0 && (
                <Stack>
                  <Subheader>{strings.plannedWorkListPastSectionTitle}</Subheader>

                  <Stack spacing={1}>
                    {viewModel.pastPlannedWorks.map((plannedWork) => (
                      <WorkDetailsPlannedWorkCard
                        key={plannedWork.plannedWork.id}
                        plannedWork={plannedWork}
                        onDelete={() => viewModel.cancelPlannerWork(plannedWork.plannedWork.id)}
                        isReadOnly={viewModel.isReadOnly}
                        showHeader={false}
                        canLinkSteps={viewModel.canLinkPlannedWorkToSteps}
                        makeLinkStepsViewModel={(plannedWork) => viewModel.makeLinkStepsViewModel(plannedWork)}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </List>

          <FormPopoverActions onSubmit={close} canSubmit customSubmitTitle={strings.plannedWorkListCloseButtonLabel} />
        </Popover>

        {state.workDetailsPlannedWorksListPopoverShowCreatePlannedWork === true && (
          <PlannedWorkEditDialog isOpen={true} workId={viewModel.workId} plannedWorkId={undefined} />
        )}
      </>
    );
  }
);
