import { useServices } from '@/hooks';
import { SelectableThemeVariant } from '@/services';
import DarkModeIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightModeRounded';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightnessRounded';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Subheader, ToggleGroup } from '../utils';

export interface ThemeVariantPickerProps {
  className?: string;
  sx?: SxProps;

  /**
   * The selected theme variant.
   */
  variant: SelectableThemeVariant;
  /**
   * Callback when a variant is selected.
   * @param locale The new variant.
   */
  onChange: (variant: SelectableThemeVariant) => void;
}

export const ThemeVariantPicker = observer(({ sx = [], className, variant, onChange }: ThemeVariantPickerProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.settings;

  function onValueChanged(value: string | null) {
    if (value != null) {
      onChange(value as SelectableThemeVariant);
    }
  }

  return (
    <Stack sx={sx} className={className}>
      <Subheader>{strings.themePickerLabel}</Subheader>

      <ToggleGroup
        value={variant}
        onChange={onValueChanged}
        options={[
          { value: 'light', text: strings.themePickerLight, icon: (sx) => <LightModeIcon sx={sx} /> },
          { value: 'system', text: strings.themePickerSystem, icon: (sx) => <SettingsBrightnessIcon sx={sx} /> },
          { value: 'dark', text: strings.themePickerDark, icon: (sx) => <DarkModeIcon sx={sx} /> }
        ]}
      />
    </Stack>
  );
});
