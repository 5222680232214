import { useServices } from '@/hooks';
import { UserDashboardKind } from '@/models';
import { ApartmentRounded, SchoolRounded } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface UserDashboardAddMenuProps {
  sx?: SxProps;
  className?: string;
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onCreate: (dashboardKind: UserDashboardKind) => void;
  onClose: () => void;
}

export const UserDashboardAddMenu = observer(
  ({ sx = [], className, isOpen, onClose, onCreate, anchorEl }: UserDashboardAddMenuProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    return (
      <Menu
        sx={sx}
        className={className}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => onClose()}
        slotProps={{ paper: { sx: { minWidth: 320 }, elevation: 2 } }}
      >
        <MenuItem disabled>{strings.createDashboardMenuSubtitle}</MenuItem>

        <MenuItem onClick={() => onCreate('planner')}>
          <ListItemIcon>
            <SchoolRounded />
          </ListItemIcon>
          <ListItemText>{strings.createDashboardMenuPlannerLabel}</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => onCreate('school')}>
          <ListItemIcon>
            <ApartmentRounded />
          </ListItemIcon>
          <ListItemText>{strings.createDashboardMenuSchoolLabel}</ListItemText>
        </MenuItem>
      </Menu>
    );
  }
);
