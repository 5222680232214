import { Box, ChipProps, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';
import { ChoicePickerOption } from './ChoicePicker';
import { ChoicePickerLabel } from './ChoicePickerLabel';
import { ChoicePickerOptionChip } from './ChoicePickerOptionChip';

export interface ChoicePickerInlineProps<T> {
  className?: string;

  /**
   * The options to display
   */
  options: ChoicePickerOption<T>[];

  /**
   * The selected option. Optional.
   */
  selectedOption?: string;

  /**
   * The label. Optional.
   */
  label?: string | ReactNode;

  /**
   * Indicates to disable the choice picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when clicking on an option.
   * @param option The clicked option.
   */
  onOptionClick: (option: ChoicePickerOption<T>) => void;

  /**
   * The props to pass to the option chip. Optional.
   * @param option The option for which to get the props.
   * @return MUI.ChipProps The option's chip props.
   */
  chipProps?: (option: ChoicePickerOption<T>) => ChipProps;

  helperText?: string;
}

export const ChoicePickerInline = <T,>({
  className,
  label,
  disabled,
  options,
  onOptionClick,
  chipProps,
  selectedOption,
  helperText
}: ChoicePickerInlineProps<T>) => {
  return (
    <Box
      className={className}
      sx={{
        display: 'contents'
      }}
    >
      {label && (
        <Box
          sx={{
            display: 'inline-block'
          }}
        >
          <ChoicePickerLabel label={label} />
        </Box>
      )}
      {options.map((option) => (
        <Box
          key={`choice-picker-option-${option.key}`}
          sx={{
            display: 'inline-block',
            m: 0.5
          }}
        >
          <ChoicePickerOptionChip
            option={option}
            onOptionClick={onOptionClick}
            chipProps={chipProps}
            isSelected={option.key === selectedOption}
            disabled={disabled}
          />
        </Box>
      ))}
      {helperText != null && <FormHelperText sx={{ mt: 1 }}>{helperText}</FormHelperText>}
    </Box>
  );
};
