import { createContext, useContext } from 'react';

export interface PlannedWorkEditInteractionContextValue {
  containerSize: { height: number; width: number };
  isCreatingNewInfo: boolean;
  isResizing: boolean;
  isDragging: boolean;
  setIsCreatingNewInfo: (value: boolean) => void;
  setIsResizing: (value: boolean) => void;
  setIsDragging: (value: boolean) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const PlannedWorkEditInteractionContext = createContext<PlannedWorkEditInteractionContextValue>({
  containerSize: { height: 0, width: 0 },
  isCreatingNewInfo: false,
  isResizing: false,
  isDragging: false,
  setIsCreatingNewInfo: () => {},
  setIsResizing: () => {},
  setIsDragging: () => {}
});
/* eslint-enable @typescript-eslint/no-empty-function */

export const usePlannedWorkEditInteractionContext = () => useContext(PlannedWorkEditInteractionContext);
