import { Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { isSxArray } from '../../../utils';
import { ScheduleCycleSpecialDays } from '../../shared/settings/schedule-cycle/special-days/ScheduleCycleSpecialDays';

export interface AdminSchoolScheduleCycleSpecialDaysPageProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleSpecialDaysPage = observer(
  ({ sx = [], className, schoolId, scheduleCycleId, isReadOnly }: AdminSchoolScheduleCycleSpecialDaysPageProps) => {
    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCycleSpecialDaysPageViewModel(scheduleCycleId, { kind: 'school', id: schoolId }),
      [scheduleCycleId]
    );

    return (
      <Stack
        className={className}
        sx={[
          {
            overflow: 'hidden'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Stack
          spacing={1}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            maxWidth: 1000
          }}
        >
          <Typography variant="h6">{LocalizedStrings.admin.schedule.calendarTitle()}</Typography>
          <Divider />
          <ScheduleCycleSpecialDays
            sx={{ overflow: 'hidden' }}
            viewModel={viewModel.contentViewModel}
            isReadOnly={isReadOnly}
          />
        </Stack>
      </Stack>
    );
  }
);
