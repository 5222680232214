import { DragOverlay, useDraggable } from '@dnd-kit/core';
import { CSSProperties, ReactNode } from 'react';
import { useDragContext } from '../../../hooks';

import { alpha, Card, useTheme } from '@mui/material';
import { createPortal } from 'react-dom';

export interface DraggableProps {
  style?: CSSProperties;
  id: string;
  type: string;
  children: ReactNode;
  disabled?: boolean;
  renderPreview?: () => ReactNode;
}

export function Draggable({ style, id, type, children, renderPreview, disabled }: DraggableProps) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data: { type },
    disabled
  });
  const theme = useTheme();

  const { item: draggedItem } = useDragContext();
  const isDragged = draggedItem?.id === id && draggedItem?.type === type;

  return (
    <div style={{ ...style, opacity: isDragging ? 0.5 : undefined }} ref={setNodeRef} {...attributes} {...listeners}>
      {children}

      {renderPreview != null &&
        createPortal(
          <DragOverlay dropAnimation={null}>
            {isDragged && (
              <Card
                elevation={2}
                sx={{ backdropFilter: 'blur(12px)', backgroundColor: alpha(theme.palette.background.paper, 0.6) }}
              >
                {renderPreview()}
              </Card>
            )}
          </DragOverlay>,
          document.body
        )}
    </div>
  );
}
