import { PlannedWorkEditViewModel } from '@/viewmodels';
import { alpha, Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { differenceInBusinessDays, isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useServices } from '../../../hooks';
import { PlannedWorkEditComponentIds } from './PlannedWorkEditComponentIds';
import { usePlannedWorkEditInteractionContext } from './PlannedWorkEditInteractionContext';
import { PlannedWorkEditInteractionLayer } from './PlannedWorkEditInteractionLayer';
import { PlannedWorkEditDayColumn } from './PlannerWorkEditDayColumn';

export interface PlannerWorkEditScheduleBodyProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannedWorkEditViewModel;
  dates: Date[];
}

export const PlannedWorkEditScheduleBody = observer(
  ({ sx = [], className, viewModel, dates }: PlannerWorkEditScheduleBodyProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const dividerStyle = `1px solid ${theme.palette.divider}`;
    const { containerSize } = usePlannedWorkEditInteractionContext();

    const todayIndex = useMemo(() => {
      const index = dates.findIndex((date) => isSameDay(date, dateService.now));
      if (index > 0) {
        return index;
      }

      return differenceInBusinessDays(dates[0], dateService.now) < 0 ? 7 : 0;
    }, [dateService.now, dates]);

    const pastDaysIndicatorColor = alpha(theme.palette.action.focus, 0.05);

    return (
      <Box
        sx={{
          ...sx,
          ...containerSize
        }}
        className={className}
        id={PlannedWorkEditComponentIds.weekBody}
      >
        <Box
          sx={{
            ...sx,
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'relative'
          }}
          className={className}
        >
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: ` ${(100 / 7) * todayIndex}%`,
              backgroundColor: 'transparent',
              background: `repeating-linear-gradient(30deg, ${pastDaysIndicatorColor}, ${pastDaysIndicatorColor} 2px, rgba(0,0,0,0) 2px, rgba(0,0,0,0) 16px)`,
              zIndex: 1
            }}
          />

          <Box sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex', zIndex: 2 }}>
            {dates.map((d, i) => (
              <PlannedWorkEditDayColumn
                key={d.toString()}
                sx={{
                  borderRight: i < 6 ? dividerStyle : undefined,
                  width: `${100 / 7}%`,
                  flex: 1,
                  flexShrink: 0,
                  height: containerSize.height
                }}
                date={d}
                viewModel={viewModel}
              />
            ))}
          </Box>

          <PlannedWorkEditInteractionLayer
            sx={{ width: '100%', height: '100%', zIndex: 3 }}
            dates={dates}
            viewModel={viewModel}
          />
        </Box>
      </Box>
    );
  }
);
