import { Theme } from '@mui/material';
import { SxProps, SystemStyleObject } from '@mui/system';

export function randomElement<T>(values: T[]): T | undefined {
  return values[Math.floor(Math.random() * values.length)];
}

export function arraysAreEqual<T>(a1: T[], a2: T[], compare?: (v1: T, v2: T) => boolean): boolean {
  if (a1.length !== a2.length) {
    return false;
  }

  return !a1.some((v1) => (compare != null ? a2.findIndex((v2) => compare(v1, v2)) < 0 : !a2.includes(v1)));
}

export function arrayMoveMutable(array: unknown[], fromIndex: number, toIndex: number): void {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable<ValueType>(
  array: readonly ValueType[],
  fromIndex: number,
  toIndex: number
): ValueType[] {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export function isSxArray(
  sx: SxProps<Theme> | undefined
): sx is readonly (boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>))[] {
  return Array.isArray(sx);
}
