import { SyncEventListener } from '@/services';
import { DependencyList, useEffect } from 'react';
import { useServices } from './UseServicesHook';

export function useSync(key: string, callback: SyncEventListener, deps: DependencyList = []) {
  const { syncService } = useServices();

  useEffect(() => {
    syncService.addListener(key, callback);
    return () => syncService.removeListener(key);
  }, deps);
}
