import ExpandIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useServices } from '../../../hooks';
import { isSxArray } from '../../../utils';

export interface SideBarExpandButtonProps {
  sx?: SxProps;
  className?: string;
  onPress: () => void;
}

export function SideBarExpandButton({ sx = [], className, onPress }: SideBarExpandButtonProps) {
  const { localization } = useServices();
  const strings = localization.localizedStrings.school;
  const theme = useTheme();

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Tooltip title={strings.sidebarExpandTooltip} placement="right" arrow>
        <IconButton onClick={onPress} sx={{ color: theme.palette.text.secondary }}>
          <ExpandIcon sx={{ color: theme.palette.text.secondary }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
