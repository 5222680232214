import { ScheduleCycleDetailsEditViewModel, ScheduleCycleKind, titleForCycleDay } from '@/viewmodels';
import { AbcRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { SxProps } from '@mui/system';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { Subheader } from '../../../../utils';
import { ScheduleCycleCycleDayNamesEdit } from './ScheduleCycleCycleDayNamesEdit';
import { ScheduleCycleDetailsScheduleCycleDayOptions } from './ScheduleCycleDetailsScheduleCycleDayOptions';
import { ScheduleCycleDetailsScheduleMultipleWeeksOptions } from './ScheduleCycleDetailsScheduleMultipleWeeksOptions';

export interface ScheduleCycleDetailsScheduleSectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleDetailsEditViewModel;
  isReadOnly?: boolean;
}

export const ScheduleCycleDetailsScheduleSection = observer(
  ({ sx = [], className, viewModel, isReadOnly }: ScheduleCycleDetailsScheduleSectionProps) => {
    const [customizeScheduleCycleNamesAnchor, setCustomizeScheduleCycleNamesAnchor] = useState<HTMLButtonElement>();
    const strings = LocalizedStrings.scheduleCycle.create;

    return (
      <Box sx={sx} className={className}>
        <Stack spacing={1}>
          <Subheader>{strings.scheduleKindSectionTitle()}</Subheader>
          <Stack spacing={2}>
            <FormControl fullWidth size="small" disabled={isReadOnly}>
              <InputLabel id="schedule-kind-select-label">{strings.scheduleKindPickerLabel()}</InputLabel>
              <Select
                autoFocus
                labelId="schedule-kind-select-label"
                value={viewModel.scheduleKind}
                label={strings.scheduleKindPickerLabel()}
                onChange={(e) => (viewModel.scheduleKind = e.target.value as ScheduleCycleKind)}
              >
                <MenuItem value="week">{strings.scheduleKindWeekLabel()}</MenuItem>
                <MenuItem value="multiple-week">{strings.scheduleKindMultipleWeeksLabel()}</MenuItem>
                <MenuItem value="cycle-day">{strings.scheduleKindCycleDayLabel()}</MenuItem>
              </Select>
            </FormControl>

            <Collapse in={viewModel.scheduleKind === 'cycle-day'} unmountOnExit>
              <ScheduleCycleDetailsScheduleCycleDayOptions
                cycleDayCount={viewModel.daysPerCycle}
                onCycleDayCountValueChanged={(v) => (viewModel.daysPerCycle = v)}
                canEditFirstCycleDay={viewModel.canEditFirstCycleDay}
                firstCycleDay={viewModel.firstCycleDay}
                onFirstCycleDayValueChanged={(v) => (viewModel.firstCycleDay = v)}
                isReadOnly={isReadOnly}
              />
            </Collapse>

            <Collapse in={viewModel.scheduleKind === 'multiple-week'} unmountOnExit>
              <ScheduleCycleDetailsScheduleMultipleWeeksOptions
                cycleDayCount={viewModel.daysPerCycle}
                onCycleDayCountValueChanged={(v) => (viewModel.daysPerCycle = v)}
                canEditFirstCycleDay={viewModel.canEditFirstCycleDay}
                firstCycleDay={viewModel.firstCycleDay}
                onFirstCycleDayValueChanged={(v) => (viewModel.firstCycleDay = v)}
                isReadOnly={isReadOnly}
              />
            </Collapse>

            {viewModel.isNew && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewModel.isPerDayPeriodSchedules}
                    onChange={(_, checked) => (viewModel.isPerDayPeriodSchedules = checked)}
                  />
                }
                label={strings.perDayPeriodSchedulesLabel()}
              />
            )}
          </Stack>

          {viewModel.canEditCycleNames && (
            <Stack spacing={1}>
              <Stack>
                <Subheader>{strings.cycleDayNamesSectionTitle()}</Subheader>
                <Typography
                  sx={{
                    px: 1
                  }}
                >
                  {times(viewModel.daysPerCycle)
                    .map(
                      (i) =>
                        titleForCycleDay(
                          i + 1,
                          viewModel.scheduleKind,
                          'short',
                          false,
                          viewModel.cycleDayNames
                        ).replace(' ', '') || `${strings.cycleDayNamesNone()}`
                    )
                    .join(' | ')}
                </Typography>
              </Stack>

              {!isReadOnly && (
                <Button
                  sx={{ alignSelf: 'flex-start' }}
                  size="small"
                  startIcon={<AbcRounded />}
                  onClick={(e) => setCustomizeScheduleCycleNamesAnchor(e.currentTarget)}
                >
                  {strings.cycleDayNamesCustomizeButtonTitle()}
                </Button>
              )}
            </Stack>
          )}

          {customizeScheduleCycleNamesAnchor != null && (
            <Popover
              open={true}
              anchorEl={customizeScheduleCycleNamesAnchor}
              slotProps={{ paper: { sx: { width: 400 } } }}
              onClose={() => setCustomizeScheduleCycleNamesAnchor(undefined)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <ScheduleCycleCycleDayNamesEdit
                initialCycleDayNames={viewModel.cycleDayNames}
                scheduleKind={viewModel.scheduleKind}
                cycleDayCount={viewModel.daysPerCycle}
                onClose={(cycleDayNames) => {
                  setCustomizeScheduleCycleNamesAnchor(undefined);

                  if (cycleDayNames != null) {
                    viewModel.cycleDayNames = cycleDayNames;
                  }
                }}
              />
            </Popover>
          )}
        </Stack>
      </Box>
    );
  }
);
