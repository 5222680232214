import { Course } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/resources/course_pb';
import { Curriculum } from '@buf/studyo_studyo-today-curriculum.connectrpc_es/studyo/today/curriculum/v1/curriculum_connect';
import { CurriculumTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppCurriculumTransportService extends AppBaseTransportService implements CurriculumTransportService {
  async getCourses(): Promise<Course[]> {
    const response = await this.performReadRequest(
      'getCurriculumCourses',
      Curriculum,
      Curriculum.methods.getCourses,
      {}
    );

    return response.courses;
  }

  async getEnrolledCourseIdsInPlanner(plannerId: string): Promise<string[]> {
    const response = await this.performReadRequest(
      'getEnrolledCourseIdsInPlanner',
      Curriculum,
      Curriculum.methods.getEnrolledCourseIds,
      { plannerId }
    );

    return response.courseIds;
  }

  async setEnrolledCourseIdsInPlanner(plannerId: string, courseIds: string[]): Promise<void> {
    await this.performWriteRequest(
      'setEnrolledCourseIdsInPlanner',
      Curriculum,
      Curriculum.methods.setEnrolledCourseIds,
      { plannerId, courseIds }
    );
  }
}
