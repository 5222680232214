import { ItemDistributionPatternKind, ItemRepeatPatternKind } from '@/models';
import { ApplicationSettingsStorage, LocalizationService, UserService } from '@/services';
import { CreatePublishedWorkRequest, UpdatePublishedWorkRequest, WorkTransportService } from '@/transports';
import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { TimeSlotGroup } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/time_slot_group_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { getOrCreateInObservableMap } from '../../utils';
import { StoreInvalidator, WorkDataStore } from '../contracts';
import {
  AppCourseSectionNotesLoadable,
  AppCourseSectionWorksLoadable,
  AppNoteLoadable,
  AppNotesLoadable,
  AppPublishedWorkLoadable,
  AppSchoolCourseSectionPublishedWorksLoadable,
  AppWorkIconsLoadable,
  AppWorkLoadable,
  AppWorksLoadable,
  CourseSectionNotesLoadable,
  CourseSectionWorksLoadable,
  NoteLoadable,
  NotesLoadable,
  PublishedWorkLoadable,
  SchoolCourseSectionPublishedWorksLoadable,
  WorkIconsLoadable,
  WorkLoadable,
  WorksLoadable
} from '../loadables';

export class AppWorkDataStore implements WorkDataStore {
  @observable private readonly _workIconLoadable: WorkIconsLoadable;
  private readonly _workLoadables = observable.map<string, WorkLoadable>();
  private readonly _worksLoadables = observable.map<string, WorksLoadable>();
  private readonly _courseSectionWorksLoadables = observable.map<string, CourseSectionWorksLoadable>();
  private readonly _notesLoadables = observable.map<string, NotesLoadable>();
  private readonly _courseSectionNotesLoadables = observable.map<string, CourseSectionNotesLoadable>();
  private readonly _noteLoadables = observable.map<string, NoteLoadable>();
  private readonly _publishedWorkLoadables = observable.map<string, PublishedWorkLoadable>();
  private readonly _publishedWorksByCourseSectionId = observable.map<
    string,
    SchoolCourseSectionPublishedWorksLoadable
  >();

  constructor(
    private readonly _workTransport: WorkTransportService,
    user: UserService,
    private readonly _storeInvalidator: StoreInvalidator,
    settingsStorage: ApplicationSettingsStorage,
    localization: LocalizationService
  ) {
    makeObservable(this);
    this._workIconLoadable = new AppWorkIconsLoadable(_workTransport);

    reaction(
      () => ({ user: user.currentUser, isDemoMode: settingsStorage.isDemoMode }),
      (value, oldValue) => {
        if (value.user?.userId !== oldValue.user?.userId || value.isDemoMode !== oldValue.isDemoMode) {
          this.invalidateAll();
        }

        if (
          value.user != null &&
          value.user.userId === oldValue.user?.userId &&
          value.user.cultureName !== oldValue.user?.cultureName
        ) {
          // If same user, but with a different cultureName, we fetch WorkIcons.
          void this._workIconLoadable.fetch(true);
        }
      }
    );

    reaction(
      () => localization.currentLocale,
      () => {
        if (user.currentUser != null) {
          void this._workIconLoadable.fetch(true);
        }
      }
    );

    reaction(
      () => _storeInvalidator.plannersRevision,
      () => this.invalidateAll()
    );
  }

  getWorkLoadable(workId: string): WorkLoadable {
    return getOrCreateInObservableMap(
      this._workLoadables,
      workId,
      () => new AppWorkLoadable(workId, this._workTransport)
    );
  }

  getWorksLoadable(plannerId: string): WorksLoadable {
    return getOrCreateInObservableMap(
      this._worksLoadables,
      plannerId,
      () => new AppWorksLoadable(plannerId, this._workTransport)
    );
  }

  getCourseSectionWorksLoadable(
    plannerId: string,
    courseSectionId: string,
    externalSourceName: string | undefined
  ): CourseSectionWorksLoadable {
    const key = `${plannerId}~~${courseSectionId}~~${externalSourceName ?? 'n/a'}`;
    return getOrCreateInObservableMap(
      this._courseSectionWorksLoadables,
      key,
      () => new AppCourseSectionWorksLoadable(plannerId, courseSectionId, externalSourceName, this._workTransport)
    );
  }

  getNotesLoadable(plannerId: string): NotesLoadable {
    return getOrCreateInObservableMap(
      this._notesLoadables,
      plannerId,
      () => new AppNotesLoadable(plannerId, this._workTransport)
    );
  }

  getCourseSectionNotesLoadable(plannerId: string, courseSectionId: string): CourseSectionNotesLoadable {
    const key = `${plannerId}~~${courseSectionId}`;
    return getOrCreateInObservableMap(
      this._courseSectionNotesLoadables,
      key,
      () => new AppCourseSectionNotesLoadable(plannerId, courseSectionId, this._workTransport)
    );
  }

  getNoteLoadable(noteId: string): NoteLoadable {
    return getOrCreateInObservableMap(
      this._noteLoadables,
      noteId,
      () => new AppNoteLoadable(noteId, this._workTransport)
    );
  }

  getPublishedWorksInCourseSection(
    courseSectionId: string,
    schoolId: string
  ): SchoolCourseSectionPublishedWorksLoadable {
    const key = `${schoolId}-${courseSectionId}`;
    return getOrCreateInObservableMap(
      this._publishedWorksByCourseSectionId,
      key,
      () => new AppSchoolCourseSectionPublishedWorksLoadable(courseSectionId, schoolId, this._workTransport)
    );
  }

  getPublishedWorkLoadable(publishedWorkId: string, schoolId: string): PublishedWorkLoadable {
    const id = `${publishedWorkId}-${schoolId}`;
    return getOrCreateInObservableMap(
      this._publishedWorkLoadables,
      id,
      () => new AppPublishedWorkLoadable(publishedWorkId, schoolId, this._workTransport)
    );
  }

  @computed
  get workIcons(): WorkIconsLoadable {
    return this._workIconLoadable;
  }

  async setWorkStatus(workId: string, status: WorkStatus, syncToken: bigint) {
    const work = await this._workTransport.setWorkStatus(workId, status, syncToken);
    // Updating work loadable if one exists.
    this.updateWorkLoadablesForUpdatedWork(work);
  }

  async createWork(work: Work): Promise<Work> {
    const result = await this._workTransport.createWork(work);
    this.updateWorkLoadablesForUpdatedWork(result);
    return result;
  }

  async updateWork(work: Work): Promise<Work> {
    const updatedWork = await this._workTransport.updateWork(work);
    // Updating work loadable if one exists.
    this.updateWorkLoadablesForUpdatedWork(updatedWork);
    return updatedWork;
  }

  async cancelPlannedWork(plannedWorkId: string, workId: string, syncToken: bigint): Promise<Work> {
    const work = await this._workTransport.cancelPlannedWork(plannedWorkId, workId, syncToken);
    // Updating work loadable if one exists.
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async setWorkSteps(workId: string, steps: WorkStep[], syncToken: bigint): Promise<Work> {
    const work = await this._workTransport.setWorkSteps(workId, steps, syncToken);
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async getAvailableTimeSlots(
    plannerId: string,
    workId: string,
    currentTimezone: string,
    durationMinutes: number,
    incrementMinutes: number,
    extraDays: number,
    plannedWorkId?: string
  ): Promise<TimeSlotGroup[]> {
    return await this._workTransport.getAvailableTimeSlots(
      plannerId,
      workId,
      currentTimezone,
      durationMinutes,
      incrementMinutes,
      extraDays,
      plannedWorkId
    );
  }

  async planWork(workId: string, timeSlot: TimeSlot, stepIds: string[], syncToken: bigint): Promise<Work> {
    const work = await this._workTransport.createPlannedWork(workId, timeSlot, stepIds, syncToken);
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async updatePlannedWork(
    plannedWorkId: string,
    workId: string,
    timeSlot: TimeSlot,
    stepIds: string[],
    syncToken: bigint
  ): Promise<Work> {
    const work = await this._workTransport.updatePlannedWork(plannedWorkId, workId, timeSlot, stepIds, syncToken);
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async createNote(note: Note): Promise<Note> {
    const result = await this._workTransport.createNote(note);
    this.updateNoteLoadablesForUpdatedNote(result);
    return result;
  }

  async updateNote(note: Note): Promise<Note> {
    const result = await this._workTransport.updateNote(note);
    this.updateNoteLoadablesForUpdatedNote(result);
    return result;
  }

  async setNoteStatus(noteId: string, isArchived: boolean, syncToken: bigint): Promise<Note> {
    const result = await this._workTransport.setNoteStatus(noteId, isArchived, syncToken);
    this.updateNoteLoadablesForUpdatedNote(result);
    return result;
  }

  async createPublishedWork(
    courseSectionId: string,
    schoolId: string,
    plannerId: string,
    request: CreatePublishedWorkRequest
  ): Promise<PublishedWork> {
    const publishedWork = await this._workTransport.createPublishedWork(courseSectionId, schoolId, plannerId, request);
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork);
    return publishedWork;
  }

  async updatePublishedWork(
    publishedWorkId: string,
    schoolId: string,
    request: UpdatePublishedWorkRequest,
    syncToken: bigint
  ): Promise<PublishedWork> {
    const publishedWork = await this._workTransport.updatePublishedWork(publishedWorkId, schoolId, request, syncToken);
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork);
    return publishedWork;
  }

  async setPublishedWorkStatus(
    publishedWorkId: string,
    schoolId: string,
    status: PublishedWorkStatus,
    scheduledPublishedTime: Date | undefined,
    syncToken: bigint
  ): Promise<PublishedWork> {
    const publishedWork = await this._workTransport.setPublishedWorkStatus(
      publishedWorkId,
      schoolId,
      status,
      scheduledPublishedTime,
      syncToken
    );
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork);
    return publishedWork;
  }

  async createCopyOfWork(
    plannerId: string,
    workId: string,
    dueTime: Date | undefined,
    isDueAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<Work> {
    const copy = await this._workTransport.createCopyOfWork(
      plannerId,
      workId,
      dueTime,
      isDueAllDay,
      courseSectionId,
      linkCopy
    );
    this.updateWorkLoadablesForUpdatedWork(copy);
    return copy;
  }

  async distributeWork(
    workId: string,
    plannerId: string,
    courseSectionIds: string[],
    pattern: ItemDistributionPatternKind
  ): Promise<Work[]> {
    const copies = await this._workTransport.distributeWork(workId, plannerId, courseSectionIds, pattern);
    copies.forEach((c) => this.updateWorkLoadablesForUpdatedWork(c));
    return copies;
  }

  async repeatWork(
    workId: string,
    plannerId: string,
    untilDate: Date,
    pattern: ItemRepeatPatternKind
  ): Promise<Work[]> {
    const copies = await this._workTransport.repeatWork(workId, plannerId, untilDate, pattern);
    copies.forEach((c) => this.updateWorkLoadablesForUpdatedWork(c));
    return copies;
  }

  async createCopyOfNote(
    plannerId: string,
    noteId: string,
    time: Date | undefined,
    isAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<Note> {
    const copy = await this._workTransport.createCopyOfNote(
      plannerId,
      noteId,
      time,
      isAllDay,
      courseSectionId,
      linkCopy
    );
    this.updateNoteLoadablesForUpdatedNote(copy);
    return copy;
  }

  async distributeNote(
    noteId: string,
    plannerId: string,
    courseSectionIds: string[],
    pattern: ItemDistributionPatternKind
  ): Promise<Note[]> {
    const copies = await this._workTransport.distributeNote(noteId, plannerId, courseSectionIds, pattern);
    copies.forEach((c) => this.updateNoteLoadablesForUpdatedNote(c));
    return copies;
  }

  async repeatNote(
    noteId: string,
    plannerId: string,
    untilDate: Date,
    pattern: ItemRepeatPatternKind
  ): Promise<Note[]> {
    const copies = await this._workTransport.repeatNote(noteId, plannerId, untilDate, pattern);
    copies.forEach((c) => this.updateNoteLoadablesForUpdatedNote(c));
    return copies;
  }

  async createCopyOfPublishedWork(
    schoolId: string,
    workId: string,
    dueTime: Date | undefined,
    isDueAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<PublishedWork> {
    const copy = await this._workTransport.createCopyOfPublishedWork(
      schoolId,
      workId,
      dueTime,
      isDueAllDay,
      courseSectionId,
      linkCopy
    );
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(copy);
    return copy;
  }

  async distributePublishedWork(
    workId: string,
    schoolId: string,
    plannerId: string,
    courseSectionIds: {
      courseId: string;
      schoolId: string;
    }[],
    pattern: ItemDistributionPatternKind
  ): Promise<PublishedWork[]> {
    const copies = await this._workTransport.distributePublishedWork(
      workId,
      schoolId,
      plannerId,
      courseSectionIds,
      pattern
    );
    copies.forEach((c) => this.updatePublishedWorkLoadablesForUpdatedPublishedWork(c));
    return copies;
  }

  async repeatPublishedWork(
    workId: string,
    schoolId: string,
    plannerId: string,
    untilDate: Date,
    pattern: ItemRepeatPatternKind
  ): Promise<PublishedWork[]> {
    const copies = await this._workTransport.repeatPublishedWork(workId, schoolId, plannerId, untilDate, pattern);
    copies.forEach((c) => this.updatePublishedWorkLoadablesForUpdatedPublishedWork(c));
    return copies;
  }

  @action
  invalidateAllPublishedWorks() {
    this._publishedWorksByCourseSectionId.clear();
  }

  @action
  private invalidateAll() {
    void this._workIconLoadable.invalidate(false);
    this._workLoadables.clear();
    this._courseSectionWorksLoadables.clear();
    this._notesLoadables.clear();
    this._courseSectionNotesLoadables.clear();
    this._publishedWorkLoadables.clear();
    this.invalidateAllPublishedWorks();
  }

  private updateWorkLoadablesForUpdatedWork(work: Work) {
    this._storeInvalidator.invalidateCalendar();
    this._workLoadables.get(work.id)?.updateWithValue(work);
    void this._worksLoadables.get(work.plannerId)?.fetch(true);

    if (work.courseSectionId.length > 0) {
      const key = `${work.plannerId}~~${work.courseSectionId}`;
      void this._courseSectionWorksLoadables.get(key)?.fetch(true);
    }
  }

  private updateNoteLoadablesForUpdatedNote(note: Note) {
    this._storeInvalidator.invalidateCalendar();
    this._noteLoadables.get(note.id)?.updateWithValue(note);
    void this._notesLoadables.get(note.plannerId)?.fetch(true);

    if (note.courseSectionId.length > 0) {
      const key = `${note.plannerId}~~${note.courseSectionId}`;
      void this._courseSectionNotesLoadables.get(key)?.fetch(true);
    }
  }

  private updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork: PublishedWork) {
    this._storeInvalidator.invalidateCalendar();
    const key = `${publishedWork.id}-${publishedWork.schoolId}`;
    this._publishedWorkLoadables.get(key)?.updateWithValue(publishedWork);
    const keyForCourse = `${publishedWork.schoolId}-${publishedWork.courseSectionId}`;
    void this._publishedWorksByCourseSectionId.get(keyForCourse)?.fetch(true);
  }
}
