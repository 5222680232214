import { useServices } from '@/hooks';
import { AdminClassesListViewModel } from '@/viewmodels';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { CalendarMonthRounded, ClassRounded, InfoRounded } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { Table, TableRowActions } from '../../../lists';
import { AdminTableTextCell } from '../../shared';
import { AdminSchoolClassesTableTeachersCell } from './AdminSchoolClassesTableTeachersCell';
import { AdminSchoolClassesTableTitleCell } from './AdminSchoolClassesTableTitleCell';

type RenderCellData = GridRenderCellParams<CourseSection, CourseSection>;

export interface AdminSchoolClassesTableProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminClassesListViewModel;
}

export const AdminSchoolClassesTable = observer(({ sx = [], className, viewModel }: AdminSchoolClassesTableProps) => {
  const { route } = useServices();
  const strings = LocalizedStrings.admin.classes.list;

  const baseStatColumn: Partial<GridColDef> = {
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'left',
    align: 'left'
  };

  const columns = useMemo(
    () =>
      [
        {
          ...baseStatColumn,
          headerName: strings.courseTitleColumnHeader(),
          field: 'title',
          minWidth: 200,
          renderCell: (data: RenderCellData) => (
            <AdminSchoolClassesTableTitleCell
              sx={{ width: '100%' }}
              {...data.row}
              schoolId={viewModel.schoolId}
              courseSectionId={data.row.id}
            />
          ),
          sortable: true,
          flex: 1
        },
        {
          ...baseStatColumn,
          headerName: strings.sectionColumnHeader(),
          field: 'section',
          sortable: true,
          renderCell: (data: RenderCellData) => <AdminTableTextCell value={data.row.section} />
        },
        {
          ...baseStatColumn,
          headerName: strings.teachersColumnHeader(),
          field: 'teachers',
          flex: 1,
          sortable: true,
          renderCell: (data: RenderCellData) => (
            <AdminSchoolClassesTableTeachersCell teachers={data.row.details?.teachers ?? []} />
          )
        },
        {
          ...baseStatColumn,
          headerName: strings.studentCountColumnHeader(),
          field: 'studentCount',
          renderCell: (data: RenderCellData) => (
            <AdminTableTextCell value={String(data.row.details?.studentCount ?? 0)} />
          )
        },
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          renderCell: (data: RenderCellData) => (
            <TableRowActions
              actions={[
                {
                  icon: <InfoRounded />,
                  text: strings.showDetailsAction(),
                  selection: {
                    case: 'link',
                    to: route.resolveAdminClassDetailsLocation(data.row.schoolId, data.row.id)
                  }
                },

                {
                  icon: <CalendarMonthRounded />,
                  text: strings.editScheduleAction(),
                  selection: {
                    case: 'link',
                    to: route.resolveAdminClassScheduleEditLocation(
                      viewModel.schoolId,
                      viewModel.currentScheduleCycleId ?? '',
                      data.row.id
                    )
                  },
                  isHidden: !viewModel.canEditSchedules || viewModel.currentScheduleCycleId == null
                }
              ]}
            />
          )
        }
      ] as GridColDef[],
    []
  );

  return (
    <Table
      sx={sx}
      className={className}
      tableKey="admin_school_classes"
      rows={viewModel.courses}
      columns={columns}
      searchText={viewModel.searchText}
      emptyOverlayParams={{
        title: strings.emptyTitle(),
        subtitle: strings.emptySubtitle(),
        renderIcon: (className) => <ClassRounded className={className} />
      }}
    />
  );
});
