import { Day, StudentInsightsData } from '@/models';
import { DateService, LocalizationService } from '@/services';
import { SchoolTransportService, WorkTransportService } from '@/transports';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { getDate, getMonth, getYear, sub } from 'date-fns';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type StudentInsightsLoadable = LoadableValue<StudentInsightsData>;

export class AppStudentInsightsLoadable
  extends BaseLoadableValue<StudentInsightsData>
  implements StudentInsightsLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _courseSectionId: string,
    private readonly _studentId: string,
    private readonly _schoolTransport: SchoolTransportService,
    private readonly _workTransport: WorkTransportService,
    private readonly _localization: LocalizationService,
    private readonly _dateService: DateService
  ) {
    super();
  }

  protected async loadData(): Promise<StudentInsightsData> {
    const courseSection = await this._schoolTransport.getCourseSectionInSchool(
      this._schoolId,
      this._courseSectionId,
      this._localization.currentTimezone
    );

    const student = courseSection.details?.students.find((s) => s.accountId === this._studentId);
    if (student == null) {
      throw new Error(`No student found with id ${this._studentId} in course ${this._courseSectionId}`);
    }

    const { minDay, maxDay } = this.getDates();
    const [dailyActivity, dailyEmotionalStatus] = await Promise.all([
      this._schoolTransport.getStudentDailyActivity(student.accountId, student.schoolId, minDay, maxDay),
      this._schoolTransport.getStudentDailyEmotionalStatus(student.accountId, student.schoolId, minDay, maxDay)
    ]);

    const works: Work[] =
      student.plannerId != null
        ? await this._workTransport.getWorks(
            student.plannerId,
            courseSection.externalSource?.externalId ?? courseSection.id,
            courseSection.externalSource?.sourceName,
            false
          )
        : [];

    return { courseSection, student, dailyActivity, works, dailyEmotionalStatus };
  }

  private getDates(): { minDay: Day; maxDay: Day } {
    const now = this._dateService.now;
    const maxDay: Day = {
      day: getDate(now),
      month: getMonth(now) + 1,
      year: getYear(now)
    };

    const minDate = sub(now, { days: 15 });
    const minDay: Day = {
      day: getDate(minDate),
      month: getMonth(minDate) + 1,
      year: getYear(minDate)
    };

    return { minDay, maxDay };
  }
}
