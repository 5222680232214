import { ServiceContainer } from '@/providers';
import { LocalizationService, RouteService } from '@/services';
import {
  Loadable,
  SchoolDataStore,
  StudentInsightsLoadable,
  UserDataStore,
  WorkDataStore,
  WorkIconsLoadable
} from '@/stores';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { computed, makeObservable } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';
import {
  AppStudentInsightsDailyActivityViewModel,
  StudentInsightsDailyActivityViewModel
} from './StudentInsightsDailyActivityViewModel';
import { AppStudentInsightsWorksViewModel, StudentInsightsWorksViewModel } from './StudentInsightsWorksViewModel';
import {
  AppStudentsPlannerSummaryTableRowViewModel,
  StudentsPlannerSummaryTableRowViewModel
} from './StudentsPlannerSummaryTableRowViewModel';

export interface StudentInsightsViewModel extends UpdatableViewModel {
  readonly student: StudentsPlannerSummaryTableRowViewModel;
  readonly plannerId: string | undefined;
  readonly userActivity: StudentInsightsDailyActivityViewModel;
  readonly courseSection: CourseSection;
  readonly works: StudentInsightsWorksViewModel;

  cancel(): Promise<void>;
}

export class AppStudentInsightsViewModel extends BaseUpdatableViewModel implements StudentInsightsViewModel {
  private readonly _data: StudentInsightsLoadable;

  constructor(
    private readonly _schoolId: string,
    private readonly _studentId: string,
    private readonly _courseId: string,
    private _onCancel: () => Promise<void>,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _route: RouteService = ServiceContainer.services.route
  ) {
    super();
    makeObservable(this);

    this._data = this._schoolStore.getStudentInsightsData(_schoolId, _courseId, _studentId);
  }

  @computed
  private get workIconsLoadable(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this._data, this.workIconsLoadable];
  }

  @computed
  get student(): StudentsPlannerSummaryTableRowViewModel {
    return new AppStudentsPlannerSummaryTableRowViewModel(this._data.data.student, this._courseId, this._localization);
  }

  @computed
  get plannerId(): string | undefined {
    return this._data.data.student.plannerId;
  }

  @computed
  get userActivity(): StudentInsightsDailyActivityViewModel {
    return new AppStudentInsightsDailyActivityViewModel(
      this._data.data.dailyActivity,
      this._data.data.dailyEmotionalStatus
    );
  }

  @computed
  get courseSection(): CourseSection {
    return this._data.data.courseSection;
  }

  @computed
  get works(): StudentInsightsWorksViewModel {
    const works = this._data.data.works;
    const workIcons = this.workIconsLoadable.data;
    return new AppStudentInsightsWorksViewModel(
      works,
      workIcons,
      this.courseSection.title,
      this._localization,
      this._route
    );
  }

  cancel() {
    return this._onCancel();
  }
}
