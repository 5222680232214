import { AuthProvider } from '@/models';
import { CredentialsAlreadyLinkedError } from '@/utils';
import { FirebaseAuthentication, User } from '@capacitor-firebase/authentication';
import { AuthStateChange } from '@capacitor-firebase/authentication/dist/esm/definitions';
import { FirebaseError, initializeApp } from 'firebase/app';
import { autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { EnvironmentService, FirebaseContract, LocalizationService } from '../contracts';

export abstract class BaseFirebaseService implements FirebaseContract {
  @observable protected _isInitialized = false;
  @observable protected _user: User | undefined;
  @observable protected _isAnonymous = true;
  @observable protected _idToken: string | undefined;

  constructor(environment: EnvironmentService, localization: LocalizationService) {
    makeObservable(this);
    try {
      initializeApp(environment.firebase);
    } catch {
      // We skip the "already exists" error which is
      // not an actual error when we're hot-reloading
    }

    void FirebaseAuthentication.addListener('authStateChange', (state) => void this.onAuthStateChanged(state));
    autorun(() => FirebaseAuthentication.setLanguageCode({ languageCode: localization.currentLocale }));
  }

  @computed
  get isInitialized(): boolean {
    return this._isInitialized;
  }

  @computed
  get user(): User | undefined {
    return this._isInitialized && this._idToken != null ? this._user : undefined;
  }

  @computed
  get isAnonymous(): boolean {
    return this._isAnonymous;
  }

  @computed
  get idToken(): string | undefined {
    return this._isInitialized ? this._idToken : undefined;
  }

  async signIn(authProvider: AuthProvider): Promise<void> {
    switch (authProvider) {
      case 'apple':
        await FirebaseAuthentication.signInWithApple();
        break;

      case 'google':
        await FirebaseAuthentication.signInWithGoogle({
          customParameters: [{ key: 'prompt', value: 'select_account' }]
        });
        break;

      case 'microsoft':
        await FirebaseAuthentication.signInWithMicrosoft();
        break;
    }
  }

  async signInAnonymously(): Promise<void> {
    await FirebaseAuthentication.signInAnonymously();
  }

  async linkCurrentUser(authProvider: AuthProvider): Promise<void> {
    if (this._user == null) {
      throw new Error('Cannot link current user.');
    }

    try {
      switch (authProvider) {
        case 'apple':
          await FirebaseAuthentication.signInWithApple();
          break;

        case 'google':
          await FirebaseAuthentication.signInWithGoogle({
            customParameters: [{ key: 'prompt', value: 'select_account' }]
          });
          break;

        case 'microsoft':
          await FirebaseAuthentication.signInWithMicrosoft();
          break;
      }

      // Though the above generates an onAuthStateChanged, the user's isAnonymous is not

      // changed yet and the idToken is not up-to-date.
      const idToken = await FirebaseAuthentication.getIdToken();

      runInAction(() => {
        this._isAnonymous = this._user?.isAnonymous ?? true;
        this._idToken = idToken.token;
      });
    } catch (e) {
      const error = e as FirebaseError;
      if (CredentialsAlreadyLinkedError.codes.includes(error.code)) {
        throw new CredentialsAlreadyLinkedError(error.message);
      }

      throw e;
    }
  }

  async signOut(): Promise<void> {
    await FirebaseAuthentication.signOut();
  }

  async refreshIdToken() {
    const refreshedToken = await FirebaseAuthentication.getIdToken();
    runInAction(() => (this._idToken = refreshedToken.token));
  }

  abstract setUser(id: string, properties?: Record<string, string>): void;

  abstract resetUser(): void;

  abstract logError(message: string, isFatal?: boolean): void;

  protected async onAuthStateChanged(state: AuthStateChange) {
    const user = state.user;
    // No need to ask for a refreshed idToken
    const idToken = user != null ? await FirebaseAuthentication.getIdToken() : undefined;

    runInAction(() => {
      this._user = user ?? undefined;
      this._isAnonymous = user?.isAnonymous ?? true;
      this._idToken = idToken?.token;
      this._isInitialized = true;
    });
  }
}
