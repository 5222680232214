import { ScheduleCycleDetailsEditViewModel } from '@/viewmodels';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Subheader } from '../../../../utils';
import { ScheduleCycleDetailsEditDateSection } from './ScheduleCycleDetailsEditDateSection';
import { ScheduleCycleDetailsScheduleSection } from './ScheduleCycleDetailsScheduleSection';
import { ScheduleCycleTermsList } from './ScheduleCycleTermsList';

export interface ScheduleCycleEditDetailsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleDetailsEditViewModel;
  isReadOnly: boolean;
}

export const ScheduleCycleEditDetails = observer(
  ({ sx = [], className, viewModel, isReadOnly }: ScheduleCycleEditDetailsProps) => {
    const strings = LocalizedStrings.scheduleCycle.details;

    return (
      <Stack sx={{ ...sx }} className={className} spacing={1}>
        <Stack>
          <Subheader>{strings.nameSectionTitle()}</Subheader>
          <TextField
            value={viewModel.name ?? ''}
            onChange={(e) => (viewModel.name = e.target.value)}
            size="small"
            disabled={isReadOnly}
          />
        </Stack>

        <ScheduleCycleDetailsEditDateSection
          startDate={viewModel.startDate}
          endDate={viewModel.endDate}
          onStartDateValueChanged={(v) => (viewModel.startDate = v)}
          onEndDateValueChanged={(v) => (viewModel.endDate = v)}
          isReadOnly={isReadOnly}
        />

        <ScheduleCycleDetailsScheduleSection viewModel={viewModel} isReadOnly={isReadOnly} />

        <ScheduleCycleTermsList
          sx={{ pt: 2, maxWidth: 600 }}
          terms={viewModel.terms}
          onAddOrUpdateTerm={(id, name, startDay, endDay) => viewModel.addOrUpdateTerm(id, name, startDay, endDay)}
          onDeleteTerm={(id) => viewModel.deleteTerm(id)}
          minDate={viewModel.startDate}
          maxDate={viewModel.endDate}
          isInitialTerms={false}
          isReadOnly={isReadOnly}
        />
      </Stack>
    );
  }
);
