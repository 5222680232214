import { CalendarFilters, Day, dayToDate, WorkIcons } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService, DateService } from '@/services';
import { WorkDataStore } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { isToday as dateIsToday } from 'date-fns';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UserDashboardCalendarMonthDayItemInfo } from './UserDashboardCalendarMonthItems';

export interface UserDashboardCalendarMonthDayViewModel {
  readonly date: Date;
  readonly isToday: boolean;
  readonly cycleDayName: string;
  readonly items: UserDashboardCalendarMonthDayItemInfo[];
}

export abstract class AppUserDashboardCalendarMonthDayViewModel implements UserDashboardCalendarMonthDayViewModel {
  @observable protected _now: Date;

  protected constructor(
    protected readonly _day: Day,
    private readonly _displayedCourseSectionIds: () => string[] | undefined,
    protected readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    protected readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings,
    dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
    this._now = dateService.now;
    setInterval(() => runInAction(() => (this._now = dateService.now)), 60 * 1_000);
  }

  protected abstract get courseSections(): CourseSectionDetails[];

  @computed
  protected get courseSectionsById(): Map<string, CourseSectionDetails> {
    return new Map(this.courseSections.map((cs) => [cs.courseSection!.id, cs]));
  }

  @computed
  protected get workIcons(): WorkIcons {
    return this._workStore.workIcons.data;
  }

  @computed
  protected get filters(): CalendarFilters {
    return this._settings.calendarMonthFilters;
  }

  @computed
  protected get displayedCourseSectionIds(): string[] | undefined {
    return this._displayedCourseSectionIds();
  }

  @computed
  get date(): Date {
    return dayToDate(this._day);
  }

  @computed
  get isToday(): boolean {
    return dateIsToday(this.date);
  }

  abstract get cycleDayName(): string;

  abstract get items(): UserDashboardCalendarMonthDayItemInfo[];
}
