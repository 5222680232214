import { BackgroundLocationState } from '@/BackgroundLocationState';
import { RouteService } from '@/services';
import { NavigateFunction } from 'react-router-dom';

export function onDialogClose(
  route: RouteService,
  navigate: NavigateFunction,
  state: BackgroundLocationState,
  plannerId: string
) {
  if (state.backgroundLocation != null) {
    navigate(-1);
  } else {
    const newLocation = route.resolvePlannerLocation(plannerId);
    navigate(newLocation);
  }
}
