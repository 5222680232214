import { ScheduleCycleCreateViewModel } from '@/viewmodels';
import { Button, Collapse, Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent } from 'react';
import LocalizedStrings from 'strings';
import { Subheader } from '../../../utils';
import {
  ScheduleCycleDetailsEditDateSection,
  ScheduleCycleDetailsScheduleSection,
  ScheduleCycleTermsList
} from './details';

export interface ScheduleCycleCreateProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleCreateViewModel;
}

export const ScheduleCycleCreate = observer(({ sx = [], className, viewModel }: ScheduleCycleCreateProps) => {
  const showDateButtonDisabled = viewModel.name == null || viewModel.name.length <= 0;

  function onNameTextFieldKeyDown(e: KeyboardEvent<HTMLDivElement>) {
    if (e.code === 'Enter' && !viewModel.isShowingDates && !showDateButtonDisabled) {
      viewModel.showDates();
    }
  }

  return (
    <Stack sx={{ ...sx }} className={className} spacing={1}>
      <Stack spacing={3}>
        <Stack>
          <Subheader>{LocalizedStrings.scheduleCycle.create.scheduleNameHeader()}</Subheader>
          <TextField
            autoFocus
            value={viewModel.name ?? ''}
            label={LocalizedStrings.scheduleCycle.create.scheduleNamePlaceholder()}
            onChange={(e) => (viewModel.name = e.target.value)}
            size="small"
            onKeyDown={onNameTextFieldKeyDown}
          />
        </Stack>

        {!viewModel.isShowingDates && (
          <NextButton onClick={() => viewModel.showDates()} disabled={showDateButtonDisabled} />
        )}
      </Stack>

      <Stack spacing={3}>
        <Collapse in={viewModel.isShowingDates} unmountOnExit>
          <ScheduleCycleDetailsEditDateSection
            startDate={viewModel.startDate}
            endDate={viewModel.endDate}
            onStartDateValueChanged={(v) => (viewModel.startDate = v)}
            onEndDateValueChanged={(v) => (viewModel.endDate = v)}
          />
        </Collapse>

        {!viewModel.isShowingScheduleKind && viewModel.isShowingDates && (
          <NextButton onClick={() => viewModel.showScheduleKind()} />
        )}
      </Stack>

      <Stack spacing={3}>
        <Collapse in={viewModel.isShowingScheduleKind} unmountOnExit>
          <ScheduleCycleDetailsScheduleSection viewModel={viewModel} />
        </Collapse>

        {!viewModel.isShowingTerms && viewModel.isShowingScheduleKind && viewModel.isShowingDates && (
          <NextButton onClick={() => viewModel.showTerms()} />
        )}
      </Stack>

      <Collapse in={viewModel.isShowingTerms} unmountOnExit>
        <ScheduleCycleTermsList
          sx={{ pt: 2, maxWidth: 600 }}
          terms={viewModel.terms}
          onAddOrUpdateTerm={(id, name, startDay, endDay) => viewModel.addOrUpdateTerm(id, name, startDay, endDay)}
          onDeleteTerm={(id) => viewModel.deleteTerm(id)}
          minDate={viewModel.startDate}
          maxDate={viewModel.endDate}
          isInitialTerms
        />
      </Collapse>
    </Stack>
  );
});

interface NextButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const NextButton = observer(({ onClick, disabled }: NextButtonProps) => {
  return (
    <Button sx={{ alignSelf: 'flex-start', width: 320 }} variant="contained" onClick={onClick} disabled={disabled}>
      {LocalizedStrings.scheduleCycle.create.nextButtonTitle()}
    </Button>
  );
});
