import { useServices } from '@/hooks';
import { Picture } from '@/models';
import { Box, Link, Typography, TypographyProps, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { getGradientClassName } from './GradientStyle';

export interface UnsplashAttributionProps extends TypographyProps {
  /**
   * The picture.
   */
  picture: Picture;

  /**
   * Indicates whether to display a gradient on the background to increase readability when displayed on a background images. Default is `false`.
   */
  withGradient?: boolean;

  /**
   * Indicates whether to use the short version of attribution. This will only display the author name. Default is `false`.
   */
  shortText?: boolean;

  className?: string;

  sx?: SxProps;
}

export const UnsplashAttribution = observer(
  ({ className, sx = [], picture, withGradient, shortText, ...props }: UnsplashAttributionProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;
    const theme = useTheme();

    const defaultColor = withGradient ? theme.palette.common.white : theme.palette.text.secondary;
    const color = props.color ?? defaultColor;

    return (
      <Box className={clsx(className, withGradient && getGradientClassName(theme, true))} sx={sx}>
        <Typography
          {...props}
          sx={[
            {
              fontSize: 8,
              color: color
            }
          ]}
        >
          {!shortText && strings.unsplashPictureBy}

          <Link
            href={picture.authorUrl}
            target="_blank"
            rel="noreferrer"
            sx={{
              color: color
            }}
          >
            {picture.authorName}
          </Link>

          {!shortText && (
            <>
              {strings.unsplashPictureOn}
              <Link
                href={'https://unsplash.com?utm_source=today&utm_medium=referral'}
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: color
                }}
              >
                {strings.unsplash}
              </Link>
            </>
          )}
        </Typography>
      </Box>
    );
  }
);
