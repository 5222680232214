import { CourseSectionInfo } from '@/models/CourseSectionInfo';
import { Locale, localizedCompare } from '@/utils';
import { AccessKind as PlannerAccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { chain } from 'lodash';

export function plannerHasAccessKindsForUser(
  userId: string,
  planner: Planner,
  ...accessKinds: PlannerAccessKind[]
): boolean {
  return planner.accessRights.some((a) => a.userId === userId && accessKinds.includes(a.kind));
}

/**
 * Returns whether a user has one of the relationship kinds for the specified planner.
 */
export function plannerHasRelationshipKindsForUser(
  userId: string,
  planner: Planner,
  ...relationshipKinds: PlannerRelationshipKind[]
): boolean {
  const userRelationshipKinds = planner.relationships
    .filter((relationship) => relationship.userId === userId)
    .map((relationship) => relationship.kind);

  return relationshipKinds.some((relationshipKind) => userRelationshipKinds.includes(relationshipKind));
}

export function plannerHasScheduleCycle(planner: Planner, schools: SchoolInformation[]): boolean {
  if (planner.scheduleCycleIds.length > 0) {
    return true;
  }

  return schools.some((s) => s.school?.isArchived !== true && (s?.school?.scheduleCycleIds ?? []).length > 0);
}

export function plannerSchools(planner: Planner, schools: SchoolInformation[]): SchoolInformation[] {
  return chain(planner.schoolIds)
    .map((id) => schools.find((s) => s.school!.id === id))
    .compact()
    .value();
}

export function comparePlannerCourseSectionDetails(
  first: CourseSectionDetails,
  second: CourseSectionDetails,
  locale: Locale
): number {
  return (
    localizedCompare(first.courseSection!.title, second.courseSection!.title, locale) ||
    localizedCompare(first.courseSection!.section, second.courseSection!.section, locale)
  );
}

export function comparePlannerCourseSectionInfos(
  first: CourseSectionInfo,
  second: CourseSectionInfo,
  locale: Locale
): number {
  return localizedCompare(first.title, second.title, locale) || localizedCompare(first.section, second.section, locale);
}
