import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Day, dayToDate } from '../../../../../models';
import { isSxArray } from '../../../../../utils';
import { ScheduleCycleSpecialDaysSectionTitle } from './ScheduleCycleSpecialDaysSectionTitle';

export interface ScheduleCycleSpecialDaysCalendarHeaderProps {
  sx?: SxProps;
  className?: string;
  currentDate: Day;
  canGoToPreviousMonth: boolean;
  goToPreviousMonth: () => void;
  canGoToNextMonth: boolean;
  goToNextMonth: () => void;
}

export const ScheduleCycleSpecialDaysCalendarHeader = observer(
  ({
    sx = [],
    className,
    currentDate,
    canGoToPreviousMonth,
    goToPreviousMonth,
    canGoToNextMonth,
    goToNextMonth
  }: ScheduleCycleSpecialDaysCalendarHeaderProps) => {
    return (
      <Stack
        className={className}
        direction="row"
        spacing={1}
        sx={[
          {
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <ScheduleCycleSpecialDaysSectionTitle sx={{ flex: 1 }} title={format(dayToDate(currentDate), 'MMMM y')} />
        <IconButton onClick={goToPreviousMonth} disabled={!canGoToPreviousMonth}>
          <ArrowBackIosRounded fontSize="small" />
        </IconButton>
        <IconButton onClick={goToNextMonth} disabled={!canGoToNextMonth}>
          <ArrowForwardIosRounded fontSize="small" />
        </IconButton>
      </Stack>
    );
  }
);
