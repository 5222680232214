import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { addMonths, format, isThisMonth, subMonths } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { UserDashboardCalendarHeaderView } from '../shared';
import { UserDashboardCalendarMonthFiltersPopover } from './UserDashboardCalendarMonthFiltersPopover';
import { UserDashboardCalendarMonthOptionsPopover } from './UserDashboardCalendarMonthOptionsPopover';

export interface UserDashboardCalendarMonthHeaderViewProps {
  sx?: SxProps;
  className?: string;
  currentDate: Date;
  courseSections: CourseSectionDetails[];
  scheduleCycleWithDraftId: string | undefined;
  hasCalendarSyncError: boolean;
  retryFetchData: () => Promise<void>;
}

export const UserDashboardCalendarMonthHeaderView = observer(
  ({
    sx = [],
    className,
    currentDate,
    courseSections,
    scheduleCycleWithDraftId,
    hasCalendarSyncError,
    retryFetchData
  }: UserDashboardCalendarMonthHeaderViewProps) => {
    const [filterButtonRef, setFilterButtonRef] = useState<HTMLButtonElement | undefined>();
    const [optionsButtonRef, setOptionsButtonRef] = useState<HTMLButtonElement | undefined>();
    const previousMonthDate = subMonths(currentDate, 1);
    const nextMonthDate = addMonths(currentDate, 1);
    const isDisplayingToday = isThisMonth(currentDate);

    return (
      <Box sx={sx} className={className}>
        <UserDashboardCalendarHeaderView
          previousDate={previousMonthDate}
          nextDate={nextMonthDate}
          isDisplayingToday={isDisplayingToday}
          title={format(currentDate, 'MMMM y')}
          onOptionsButtonClick={(e) => setOptionsButtonRef(e.currentTarget)}
          onFiltersButtonClick={(e) => setFilterButtonRef(e.currentTarget)}
          scheduleCycleWithDraftId={scheduleCycleWithDraftId}
          hasCalendarSyncError={hasCalendarSyncError}
          retryFetchData={() => retryFetchData()}
        />

        <UserDashboardCalendarMonthFiltersPopover
          open={filterButtonRef != null}
          anchorEl={filterButtonRef}
          close={() => setFilterButtonRef(undefined)}
          courseSections={courseSections}
        />

        <UserDashboardCalendarMonthOptionsPopover
          open={optionsButtonRef != null}
          anchorEl={optionsButtonRef}
          close={() => setOptionsButtonRef(undefined)}
        />
      </Box>
    );
  }
);
