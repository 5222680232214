import {
  CourseSectionInfo,
  ItemDistributionPatternKind,
  WorkIconInfo,
  dateToPBDate,
  plannerCourseSectionDetailsToInfo,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon,
  urlForWorkIconFromWork
} from '@/models';
import { ServiceContainer } from '@/providers';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { captureException } from '@sentry/react';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { localizedCompareWithProperties } from '../../utils';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { PlannerCopyItemInfo } from './PlannerCopyItemInfo';
import { PlannerCopyItemKind } from './PlannerCopyItemKind';

export interface PlannerDistributeItemViewModel extends UpdatableDialogViewModel {
  readonly item: PlannerCopyItemInfo;
  readonly courseSections: CourseSectionInfo[];
  readonly selectedCourseSectionIds: string[];
  readonly availableTimeOptions: ItemDistributionPatternKind[];
  timeOption: ItemDistributionPatternKind | undefined;
  toggleSelectionOfCourse(id: string): void;
}

export class AppPlannerDistributeItemViewModel
  extends AppBaseUpdatableDialogViewModel
  implements PlannerDistributeItemViewModel
{
  @observable private _isSaving = false;
  @observable private _state: UpdatableViewModelState = 'pending';
  @observable private _courseSections: CourseSectionInfo[] = [];
  @observable private _originalCourseSectionId = '';
  @observable private _originalItem: PlannerCopyItemInfo | undefined;
  @observable private _availableTimeOptions: ItemDistributionPatternKind[] = [];
  @observable private _plannerDistributeTimeOption: ItemDistributionPatternKind | undefined;
  private _selectedCourseSectionIds = observable.set<string>();

  private readonly _saveButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    private readonly _plannerId: string,
    private readonly _itemKind: PlannerCopyItemKind,
    onDismiss: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _workStore = ServiceContainer.services.workStore
  ) {
    super(localization, onDismiss);
    makeObservable(this);

    this._saveButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.planner.distributeItem.saveButtonTitle(),
      'contained',
      () => this.save()
    );

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );
  }

  @computed
  private get courseSectionsLoadable() {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIconsLoadable() {
    return this._workStore.workIcons;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled = !this._isSaving && this.hasChanges;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get item(): PlannerCopyItemInfo {
    return this._originalItem!;
  }

  @computed
  get courseSections() {
    return this._courseSections.filter((cs) => cs.id !== this._originalCourseSectionId);
  }

  @computed
  get selectedCourseSectionIds(): string[] {
    return Array.from(this._selectedCourseSectionIds);
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  @computed
  get timeOption(): ItemDistributionPatternKind | undefined {
    return this._plannerDistributeTimeOption;
  }

  set timeOption(value: ItemDistributionPatternKind | undefined) {
    this._plannerDistributeTimeOption = value;
  }

  @computed
  get availableTimeOptions(): ItemDistributionPatternKind[] {
    return this._availableTimeOptions;
  }

  @computed
  get hasChanges(): boolean {
    return this.selectedCourseSectionIds.length > 0 && this._plannerDistributeTimeOption != null;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSaving;
  }

  @computed
  get hasData(): boolean {
    return this._state === 'fulfilled' && this._originalItem != null && this._availableTimeOptions.length > 0;
  }

  @action
  toggleSelectionOfCourse(id: string) {
    if (this._selectedCourseSectionIds.has(id)) {
      this._selectedCourseSectionIds.delete(id);
    } else {
      this._selectedCourseSectionIds.add(id);
    }
  }

  async reloadData() {
    runInAction(() => (this._state = 'pending'));

    try {
      switch (this._itemKind.case) {
        case 'work':
          await this.loadDataForWork(this._itemKind.id);
          break;

        case 'note':
          await this.loadDataForNote(this._itemKind.id);
          break;

        case 'publishedWork':
          await this.loadDataForPublishedWork(this._itemKind.id, this._itemKind.schoolId);
          break;
      }
    } catch (e) {
      captureException(e);
      runInAction(() => (this._state = e as Error));
    }
  }

  private async loadDataForWork(id: string) {
    const workLoadable = this._workStore.getWorkLoadable(id);
    await workLoadable.fetch(false);

    const work = workLoadable.data;
    const course = this.courseSectionsLoadable.data.get(work.courseSectionId);
    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(work.iconId) ?? icons.iconsById.get(icons.defaultIconId);
    const iconInfo: WorkIconInfo | undefined =
      icon != null
        ? {
            id: icon.iconId,
            title: icon.iconName,
            lightUrl: urlForWorkIconFromWork(icon, work, 'light'),
            darkUrl: urlForWorkIconFromWork(icon, work, 'dark'),
            externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, icons)
          }
        : undefined;

    const info: PlannerCopyItemInfo = {
      kind: 'work',
      id: work.id,
      title: work.title,
      subtitle: work.description?.text,
      color: course?.courseSection?.color ?? '',
      courseId: course?.courseSection?.id,
      icon: iconInfo
    };
    const timeOptions = await this.makeTimeOptions(course?.courseSection?.id, work.dueTime?.toDate(), work.isDueAllDay);

    runInAction(() => {
      this._state = 'fulfilled';
      this._originalItem = info;
      this._originalCourseSectionId = course?.courseSection?.id ?? '';
      this._courseSections = this.makeCourseSectionInfos(this.courseSectionsLoadable.values);
      this._availableTimeOptions = timeOptions;
    });
  }

  private async loadDataForNote(id: string) {
    const noteLoadable = this._workStore.getNoteLoadable(id);
    await noteLoadable.fetch(false);

    const note = noteLoadable.data;
    const course = this.courseSectionsLoadable.data.get(note.courseSectionId);

    const info: PlannerCopyItemInfo = {
      kind: 'note',
      id: note.id,
      title: LocalizedStrings.planner.distributeItem.noteTitle(),
      subtitle: note.text!.text,
      color: course?.courseSection?.color ?? '',
      courseId: course?.courseSection?.id,
      icon: undefined
    };

    const timeOptions = await this.makeTimeOptions(course?.courseSection?.id, note.time?.toDate(), note.isAllDay);

    runInAction(() => {
      this._state = 'fulfilled';
      this._originalItem = info;
      this._originalCourseSectionId = course?.courseSection?.id ?? '';
      this._courseSections = this.makeCourseSectionInfos(this.courseSectionsLoadable.values);
      this._availableTimeOptions = timeOptions;
    });
  }

  private async loadDataForPublishedWork(id: string, schoolId: string) {
    const workLoadable = this._workStore.getPublishedWorkLoadable(id, schoolId);
    await workLoadable.fetch(false);

    const work = workLoadable.data;
    const allCourseSections = this.courseSectionsLoadable.values;
    const course = allCourseSections.find((cs) => cs.schoolsCourseSection?.id === work.courseSectionId);

    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(work.iconId) ?? icons.iconsById.get(icons.defaultIconId);
    const iconInfo: WorkIconInfo | undefined =
      icon != null
        ? {
            id: icon.iconId,
            title: icon.iconName,
            lightUrl: urlForPublishedWorkWorkIcon(icon, work.importance, 'light'),
            darkUrl: urlForPublishedWorkWorkIcon(icon, work.importance, 'dark'),
            externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, icons)
          }
        : undefined;

    const info: PlannerCopyItemInfo = {
      kind: 'publishedWork',
      id: work.id,
      title: work.title,
      subtitle: work.description?.text,
      color: course?.courseSection?.color ?? '',
      courseId: course?.courseSection?.id,
      icon: iconInfo,
      schoolId: work.schoolId
    };

    const taughtClasses = allCourseSections.filter(
      (cs) => cs.schoolsCourseSection != null && cs.role === CourseSectionRole.TEACHER
    );
    const timeOptions = await this.makeTimeOptions(course?.courseSection?.id, work.dueTime?.toDate(), work.isDueAllDay);

    runInAction(() => {
      this._state = 'fulfilled';
      this._originalItem = info;
      this._originalCourseSectionId = course?.courseSection?.id ?? '';
      this._courseSections = this.makeCourseSectionInfos(taughtClasses);
      this._availableTimeOptions = timeOptions;
    });
  }

  private makeCourseSectionInfos(courseSections: CourseSectionDetails[]): CourseSectionInfo[] {
    return courseSections
      .map(plannerCourseSectionDetailsToInfo)
      .sort((c1, c2) =>
        localizedCompareWithProperties([{ value1: c1.title, value2: c2.title }], this._localization.currentLocale)
      );
  }

  private async makeTimeOptions(
    courseId: string | undefined,
    date: Date | undefined,
    isAllDay: boolean
  ): Promise<ItemDistributionPatternKind[]> {
    const options: ItemDistributionPatternKind[] = ['same-time', 'same-day'];
    const canDistributeToOccurrence = await this.canDistributeToOccurrence(courseId, date, isAllDay);

    if (canDistributeToOccurrence) {
      options.push('same-occurrence');
    }

    return options;
  }

  private async canDistributeToOccurrence(
    courseId: string | undefined,
    date: Date | undefined,
    isAllDay: boolean
  ): Promise<boolean> {
    if (courseId == null || courseId.length === 0 || date == null || isAllDay) {
      return false;
    }

    try {
      const day = dateToPBDate(date);
      const calendarStore = this._plannerStore.getCalendarStore(this._plannerId);
      // Making sure the day is loaded.
      await calendarStore.fetchDays(day, day, false);
      const occurrencesForDate = calendarStore.getCourseSectionsOccurrencesForDate(date);
      // Can distribute to same occurrence if original item occurs during a period of its course section.
      return occurrencesForDate.some((o) => o.courseSectionId === courseId);
    } catch (e) {
      captureException(e);
      // If an error occurs while loading periods, we simply hide to 'same-occurrence' option.
      return false;
    }
  }

  private async save() {
    if (this._plannerDistributeTimeOption == null) {
      return;
    }

    runInAction(() => (this._isSaving = true));

    try {
      switch (this._itemKind.case) {
        case 'work':
          await this._workStore.distributeWork(
            this._itemKind.id,
            this._plannerId,
            this.selectedCourseSectionIds,
            this._plannerDistributeTimeOption
          );
          break;

        case 'note':
          await this._workStore.distributeNote(
            this._itemKind.id,
            this._plannerId,
            this.selectedCourseSectionIds,
            this._plannerDistributeTimeOption
          );
          break;

        case 'publishedWork': {
          await this.distributePublishedWork(this._plannerDistributeTimeOption);
          break;
        }
      }

      await this.dismiss();
    } catch (e) {
      captureException(e);
      runInAction(() => (this._error = LocalizedStrings.planner.distributeItem.saveErrorMessage()));
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }

  private async distributePublishedWork(pattern: ItemDistributionPatternKind) {
    const courses = chain(this.selectedCourseSectionIds)
      .map((id) => {
        const course = this.courseSectionsLoadable.data.get(id);
        if (course?.schoolsCourseSection != null) {
          return { courseId: course.courseSection!.id, schoolId: course.schoolsCourseSection.schoolId };
        }

        return undefined;
      })
      .compact()
      .value();

    await this._workStore.distributePublishedWork(
      this._itemKind.id,
      this.item.schoolId!,
      this._plannerId,
      courses,
      pattern
    );
  }
}
