import { ObservableMap } from 'mobx';

export function getOrCreateInMap<T>(map: Map<string, T>, key: string, createFn: () => T): T {
  const existingValue = map.get(key);

  if (existingValue != null) {
    return existingValue;
  }

  const newValue = createFn();
  map.set(key, newValue);
  return newValue;
}

export function getOrCreateInObservableMap<T>(map: ObservableMap<string, T>, key: string, createFn: () => T): T {
  const existingValue = map.get(key);

  if (existingValue != null) {
    return existingValue;
  }

  const newValue = createFn();
  map.set(key, newValue);
  return newValue;
}

export function mergeObservableMaps<T>(source: ObservableMap<string, T>, newValues: ObservableMap<string, T>) {
  for (const [key, value] of newValues) {
    source.set(key, value);
  }
}
