import { SchoolTransportService } from '@/transports';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type ParticipatingSchoolsLoadable = LoadableMap<SchoolInformation>;

export class AppParticipatingSchoolsLoadable
  extends BaseLoadableMap<SchoolInformation>
  implements ParticipatingSchoolsLoadable
{
  constructor(private readonly _schoolTransport: SchoolTransportService) {
    super();
  }

  protected async loadData(): Promise<Map<string, SchoolInformation>> {
    const schools = await this._schoolTransport.getParticipatingSchools();
    return new Map(schools.map((s) => [s.school!.id, s]));
  }
}
