import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { makeObservable } from 'mobx';
import { WorkTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type WorksLoadable = LoadableValue<Work[]>;

export class AppWorksLoadable extends BaseLoadableValue<Work[]> implements WorksLoadable {
  constructor(
    private readonly _plannerId: string,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  protected async loadData(): Promise<Work[]> {
    return await this._workTransport.getWorks(this._plannerId, undefined, undefined, false);
  }
}
