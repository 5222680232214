import { useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { UpdatablePresenter } from '../../utils';
import { AdminSchoolParticipationCode } from './AdminSchoolParticipationCode';

export interface AdminSchoolParticipationCodesListProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminSchoolParticipationCodesList = observer(
  ({ sx = [], className, schoolId }: AdminSchoolParticipationCodesListProps) => {
    const viewModel = useViewModel(
      (viewModels) => viewModels.createAdminSchoolParticipationCodesViewModel(schoolId),
      [schoolId]
    );

    return (
      <UpdatablePresenter
        sx={sx}
        className={className}
        viewModel={viewModel}
        renderData={() => (
          <Stack spacing={2}>
            <AdminSchoolParticipationCode code={viewModel.teacherCode} />
          </Stack>
        )}
      />
    );
  }
);
