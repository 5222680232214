import { createContext } from 'react';

export interface DraggedItem {
  type: string;
  id: string;
}

export interface DragContextValue {
  item: DraggedItem | undefined;
  setItem: (item: DraggedItem | undefined) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const DragContext = createContext<DragContextValue>({ item: undefined, setItem: () => {} });
