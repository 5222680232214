import { computed, makeObservable, reaction } from 'mobx';
import { ServiceContainer } from '../../providers';
import { ConnectedAppsService, FeatureFlagService, LocalizationService } from '../../services';
import { PlannerDataStore, UserDataStore } from '../../stores';
import { AppConnectedAppViewModel, ConnectedAppViewModel } from './ConnectedAppViewModel';

export interface ConnectedAppsViewModel {
  readonly connectedApps: ConnectedAppViewModel[];
}

export class AppConnectedAppsViewModel implements ConnectedAppsViewModel {
  private readonly _connectedAppViewModels: ConnectedAppViewModel[] = [];

  constructor(
    private readonly _plannerId: string,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    featureFlag: FeatureFlagService = ServiceContainer.services.featureFlag
  ) {
    this._connectedAppViewModels.push(
      new AppConnectedAppViewModel(
        'classroom',
        this._plannerId,
        this._userStore,
        this._connectedApps,
        this._plannerStore,
        this._localization
      )
    );

    if (featureFlag.isEnabled('google-calendar')) {
      this._connectedAppViewModels.push(
        new AppConnectedAppViewModel(
          'googleCalendar',
          this._plannerId,
          this._userStore,
          this._connectedApps,
          this._plannerStore,
          this._localization
        )
      );
    }

    makeObservable(this);

    reaction(
      () => this.connectedApps.some((vm) => vm.userDashboards.some((u) => u.requiresRefresh)),
      () => void this.syncData()
    );
  }

  @computed
  get connectedApps(): ConnectedAppViewModel[] {
    return this._connectedAppViewModels;
  }

  private async syncData() {
    await Promise.all([this._userStore.plannersLoadable.fetch(true), this._userStore.subscription.fetch(true)]);

    const plannerContentsLoadable = this._plannerStore.getPlannerContentsInPlanner(this._plannerId);
    const inboxWorkCountLoadable = this._plannerStore.getInboxWorkCountInPlanner(this._plannerId);
    const courseSectionsLoadable = this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
    await Promise.all([
      plannerContentsLoadable.fetch(true),
      inboxWorkCountLoadable.fetch(true),
      courseSectionsLoadable.fetch(true)
    ]);
  }
}
