import { useServices } from '@/hooks';
import { propertiesHaveMatchForSearchText } from '@/utils';
import { Student } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_pb';
import { InfoRounded } from '@mui/icons-material';
import EmptyListIcon from '@mui/icons-material/AssignmentRounded';
import { SxProps } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { Table, TableRowActions } from '../../../lists';
import { AdminContentCard, AdminTableCellLink } from '../../shared';

type RenderCellData = GridRenderCellParams<Student, Student>;

export interface AdminSchoolClassStudentsListProps {
  sx?: SxProps;
  className?: string;
  students: Student[];
}

export const AdminSchoolClassStudentsList = observer(
  ({ sx = [], className, students }: AdminSchoolClassStudentsListProps) => {
    const { route } = useServices();
    const strings = LocalizedStrings.admin.classes.details;
    const [searchValue, setSearchValue] = useState('');

    const filteredStudents = useMemo(() => {
      if (searchValue.length > 0) {
        return students.filter((s) =>
          propertiesHaveMatchForSearchText(searchValue, [
            s.fullName,
            s.emailAddress,
            { value: s.accountId, method: 'exact', caseSensitive: true },
            { value: s.plannerId, method: 'exact', caseSensitive: true }
          ])
        );
      }

      return students;
    }, [students, searchValue]);
    const baseStatColumn: Partial<GridColDef> = {
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left'
    };

    const columns = useMemo(
      () =>
        [
          {
            ...baseStatColumn,
            headerName: strings.studentNameColumnHeader(),
            field: 'fullName',
            minWidth: 200,
            renderCell: (data: RenderCellData) => (
              <AdminTableCellLink
                value={data.row.fullName}
                link={route.resolveAdminStudentDetailsLocation(data.row.schoolId, data.row.accountId)}
                isExternal={false}
              />
            ),
            sortable: true,
            flex: 1
          },
          {
            ...baseStatColumn,
            headerName: strings.studentEmailColumnHeader(),
            field: 'emailAddress',
            flex: 1,
            sortable: true,
            renderCell: (data: RenderCellData) => (
              <AdminTableCellLink value={data.row.emailAddress} link={`mailto:${data.row.emailAddress}`} isExternal />
            )
          },
          {
            field: 'actions',
            headerName: '',
            type: 'actions',
            renderCell: (data: RenderCellData) => (
              <TableRowActions
                actions={[
                  {
                    icon: <InfoRounded />,
                    text: strings.studentShowDetailsOption(),
                    selection: {
                      case: 'link',
                      to: route.resolveAdminStudentDetailsLocation(data.row.schoolId, data.row.accountId)
                    }
                  }
                ]}
              />
            )
          }
        ] as GridColDef[],
      []
    );

    return (
      <AdminContentCard
        sx={sx}
        className={className}
        title={strings.studentsCardTitle()}
        searchValue={searchValue}
        onSearch={setSearchValue}
      >
        <Table
          sx={{ mx: 2 }}
          tableKey="admin_class_students"
          rows={filteredStudents}
          getRowId={(s) => s.accountId}
          columns={columns}
          searchText={searchValue}
          emptyOverlayParams={{
            title: strings.studentListEmptyTitle(),
            renderIcon: (className) => <EmptyListIcon className={className} />
          }}
        />
      </AdminContentCard>
    );
  }
);
