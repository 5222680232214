import { useActivePlannerId, useServices } from '@/hooks';
import { UserDashboardInfo, plannerHasRelationshipKindsForUser } from '@/models';
import { UserDashboardPlannerItemsLocationState } from '@/services';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { isSxArray } from '../../utils';
import { PlannerNewSchoolsDialog } from '../planner';
import {
  MainAppBar,
  ScheduleCycleCreateDialog,
  ScheduleCycleCreationUserDashboardPicker,
  UserDashboardSideBar
} from '../shared';
import { SubscriptionAlertBanner, SubscriptionsDialog } from '../subscriptions';
import { MediaQuery, UnsplashAttribution } from '../utils';
import { PasteboardContentBanner } from './PasteboardContentBanner';
import { StudentPlannerBanner } from './StudentPlannerBanner';
import { UserDashboardLayoutDialogs } from './UserDashboardLayoutDialogs';

interface LocationState {
  userDashboardLayoutShowCreateScheduleCycle?: UserDashboardInfo;
  userDashboardLayoutShowSubscribe?: boolean;
}

export interface UserDashboardLayoutProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
}

export const UserDashboardLayout = observer(({ sx = [], className, children }: UserDashboardLayoutProps) => {
  const { analytics, localization, route, settings, settingsStorage, syncService, userStore } = useServices();
  const plannerId = useActivePlannerId();
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const strings = localization.localizedStrings.utils;

  const [showScheduleUserDashboardPicker, setShowScheduleUserDashboardPicker] = useState(false);

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState & UserDashboardPlannerItemsLocationState;
  const navigate = useNavigate();

  useEffect(() => {
    settingsStorage.activeDashboard = { id: plannerId, kind: 'planner' };
  }, [plannerId]);

  useEffect(() => {
    const sync = async () => {
      await syncService.syncPlanner(plannerId, true);

      // Waiting for initial sync to complete before starting the auto sync.
      syncService.startAutoSyncForPlanner(plannerId);
    };

    void sync();

    settingsStorage.activeDashboard = { id: plannerId, kind: 'planner' };
    userStore.addPlannerToHistory(plannerId);
    return () => syncService.stopAutoSyncForPlanner(plannerId);
  }, [plannerId]);

  useEffect(() => analytics.logScreenView_Dashboard(), []);

  const planner = userStore.getPlannerForId(plannerId);
  const showViewingStudentPlannerBanner =
    planner != null &&
    plannerHasRelationshipKindsForUser(userStore.user.userId, planner, PlannerRelationshipKind.TEACHER);

  function goBackToOwnedPlanner() {
    const destinationPlannerId = userStore.idOfLastOwnedPlannerShowed;
    if (destinationPlannerId != null) {
      const destination = route.resolvePlannerLocation(destinationPlannerId);
      navigate(destination);
    }
  }

  function onCreateScheduleCycleUserDashboardSelection(dashboard: UserDashboardInfo | undefined) {
    setShowScheduleUserDashboardPicker(false);
    if (dashboard != null) {
      createScheduleCycleForUserDashboard(dashboard);
    }
  }

  function createScheduleCycleForUserDashboard(dashboard: UserDashboardInfo) {
    const newState: LocationState = {
      ...state,
      userDashboardLayoutShowCreateScheduleCycle: dashboard
    };
    navigate(location, { state: newState });
  }

  return (
    <Box
      className={className}
      sx={[
        {
          height: '100%',
          width: '100%',
          display: 'flex'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack
        spacing={0}
        sx={{
          height: '100%',
          width: '100%',
          flex: 1,
          backgroundImage: { xs: undefined, sm: `url(${settings.backgroundImage?.urls.large})` },
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        {showViewingStudentPlannerBanner && <StudentPlannerBanner onClick={goBackToOwnedPlanner} />}

        <MainAppBar />

        <Box
          className={className}
          sx={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor:
              settings.backgroundImage == null && theme.palette.mode === 'light' ? theme.palette.grey.A200 : undefined
          }}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
            <UserDashboardSideBar id="user-dashboard-layout" />
          </MediaQuery>

          <Box
            sx={{
              flex: 1,
              height: '100%',
              width: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {settingsStorage.isDemoMode && (
              <Box
                sx={{
                  p: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.info.main,
                  color: theme.palette.getContrastText(theme.palette.info.main)
                }}
              >
                <Typography variant="subtitle2">{strings.demoMode}</Typography>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '.banner': { marginBottom: 0.5 },
                '.banner:first-of-type': { marginTop: 1 },
                '.banner:last-child': { marginBottom: 0 },
                mx: 1
              }}
            >
              <SubscriptionAlertBanner plannerId={plannerId} />
            </Box>

            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'row',

                padding: {
                  xs: 0,
                  sm: settings.backgroundImage == null && isDarkTheme ? 0 : 1,
                  md: settings.backgroundImage == null && isDarkTheme ? 0 : 2
                },

                paddingBottom: {
                  xs: 0,
                  sm: settings.backgroundImage != null || isDarkTheme ? 0 : 1,
                  md: settings.backgroundImage != null || isDarkTheme ? 0 : 2
                }
              }}
            >
              {children}
            </Box>

            <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
              {settings.backgroundImage != null && (
                <UnsplashAttribution picture={settings.backgroundImage} withGradient />
              )}
            </MediaQuery>
          </Box>
        </Box>
      </Stack>
      <PasteboardContentBanner />
      <PlannerNewSchoolsDialog />
      {state.userDashboardLayoutShowCreateScheduleCycle != null && (
        <ScheduleCycleCreateDialog isOpen={true} userDashboard={state.userDashboardLayoutShowCreateScheduleCycle} />
      )}
      {showScheduleUserDashboardPicker && (
        <ScheduleCycleCreationUserDashboardPicker isOpen={true} onClose={onCreateScheduleCycleUserDashboardSelection} />
      )}
      {state.userDashboardLayoutShowSubscribe === true && <SubscriptionsDialog isOpen={true} />}
      <UserDashboardLayoutDialogs />
    </Box>
  );
});
