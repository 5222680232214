import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Browser } from '@capacitor/browser';
import { GroupsRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useActivePlannerId, useActiveSubscription, useServices } from '../../../hooks';
import { ScheduleCycleInfo, scheduleCycleIsPast } from '../../../models';
import { SubscriptionsDialog } from '../../subscriptions';
import { ConfirmationDialog, ConfirmationDialogWithChoices } from '../../utils';

interface LocationState {
  schoolSettingsHeaderShowSubscribe?: boolean;
}

export interface SchoolSettingsHeaderProps {
  sx?: SxProps;
  className?: string;
  school: SchoolInformation | undefined;
}

export const SchoolSettingsHeader = observer(({ school, className, sx }: SchoolSettingsHeaderProps) => {
  const { dateService, featureFlag, route, userStore } = useServices();
  const plannerId = useActivePlannerId();
  const schoolId = school?.school?.id ?? '';
  const userId = userStore.user.userId;
  const planner = userStore.getPlannerForId(plannerId)!;

  const location = useLocation();
  const state = (location.state || {}) as LocationState;
  const navigate = useNavigate();

  const strings = LocalizedStrings.settings.school.details;

  const isSharedSchoolFeatureEnabled = featureFlag.isEnabled('shared-schools');
  const canShareSchool =
    school?.school?.isArchived !== true && (school?.administrators.some((a) => a.userId === userId) ?? false);
  const { isSubscribed, isActive: isSubscriptionActive, isEntitledToSharedSchools } = useActiveSubscription();

  const owners =
    school?.owners
      .map((o) => (o.userId === userId ? `${o.fullName} (${strings.schoolOwnedByCurrentUser()})` : o.fullName))
      .join(',') ?? '';

  const [showShareSchoolConfirmation, setShowShareSchoolConfirmation] = useState<{
    isOpen: boolean;
    scheduleCycle: ScheduleCycleInfo | undefined;
  } | null>(null);
  const [isSharingSchool, setIsSharingSchool] = useState(false);
  const [shareSchoolError, setShareSchoolError] = useState<Error | undefined>(undefined);

  async function onShareSchoolButtonClick() {
    if (isSubscribed && isEntitledToSharedSchools) {
      if (isSubscriptionActive) {
        setIsSharingSchool(true);

        if (school!.school!.scheduleCycleIds.length > 0) {
          setShowShareSchoolConfirmation({ isOpen: true, scheduleCycle: undefined });
        } else {
          // If school has no schedule cycle and the planner has an active schedule cycle, we give the option to the
          // user to move the schedule to the school.
          const scheduleCyclesLoadable = userStore.getScheduleCycles(planner.scheduleCycleIds);
          await scheduleCyclesLoadable.fetch(true);
          let scheduleCycle: ScheduleCycleInfo | undefined;

          if (scheduleCyclesLoadable.hasData) {
            scheduleCycle = scheduleCyclesLoadable.data.find(
              (sc) => !scheduleCycleIsPast(sc.scheduleCycle, dateService)
            );
          }

          setShowShareSchoolConfirmation({ isOpen: true, scheduleCycle });
        }
      } else {
        const subscriptionUrl = await userStore.getManageSubscriptionUrl(window.location.href);
        await Browser.open({ url: subscriptionUrl });
      }
    } else {
      const newState: LocationState = { ...state, schoolSettingsHeaderShowSubscribe: true };
      navigate(location, { state: newState });
    }
  }

  async function onShareSchoolConfirmation(hasConfirmed: boolean) {
    setShowShareSchoolConfirmation(null);
    setShareSchoolError(undefined);

    if (hasConfirmed) {
      await shareSchool(undefined);
    }

    setIsSharingSchool(false);
  }

  async function onShareSchoolWithScheduleConfirmation(result: string[] | undefined) {
    setShowShareSchoolConfirmation(null);
    setShareSchoolError(undefined);

    if (result != null) {
      await shareSchool(result[0]);
    }

    setIsSharingSchool(false);
  }

  async function shareSchool(inheritedScheduleCycleId: string | undefined) {
    try {
      await userStore.shareSchool(schoolId, inheritedScheduleCycleId);
      navigate(route.resolveAdminSchoolInformationLocation(schoolId), { replace: true });
    } catch (e) {
      setShareSchoolError(e as Error);
      captureException(e);
    }
  }

  return (
    <Box sx={sx} className={className}>
      <Stack
        direction="row"
        sx={{
          alignItems: shareSchoolError != null ? 'flex-start' : 'center'
        }}
      >
        <Stack
          sx={{
            flex: 1
          }}
        >
          {school?.school != null && <Typography variant="h6">{school.school.name}</Typography>}

          {school != null && (
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            >
              {strings.schoolOwners(owners)}
            </Typography>
          )}
        </Stack>

        {isSharedSchoolFeatureEnabled && canShareSchool && (
          <Stack spacing={1}>
            <LoadingButton
              loading={isSharingSchool}
              variant="contained"
              startIcon={<GroupsRounded />}
              onClick={() => void onShareSchoolButtonClick()}
              disabled={isSharingSchool}
            >
              {strings.shareSchoolButton()}
            </LoadingButton>

            {shareSchoolError != null && (
              <Typography
                variant="caption"
                sx={{
                  fontWeight: '500',
                  color: (theme) => theme.palette.error.main
                }}
              >
                {strings.shareSchoolError()}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
      {showShareSchoolConfirmation != null &&
        (showShareSchoolConfirmation.scheduleCycle == null ? (
          <ConfirmationDialog
            isOpen={true}
            title={strings.shareSchoolConfirmationTitle()}
            message={strings.shareSchoolConfirmationMessage(school!.school!.name)}
            confirmButtonLabel={strings.shareSchoolConfirmationSubmit()}
            onSubmit={(hasConfirmed) => void onShareSchoolConfirmation(hasConfirmed)}
          />
        ) : (
          <ConfirmationDialogWithChoices
            isOpen={true}
            title={strings.shareSchoolConfirmationTitle()}
            message={strings.shareSchoolConfirmationMessage(school!.school!.name)}
            choices={[
              {
                key: showShareSchoolConfirmation.scheduleCycle.scheduleCycle.id,
                title: strings.shareSchoolConfirmationScheduleOption(
                  showShareSchoolConfirmation.scheduleCycle.scheduleCycle.name
                )
              }
            ]}
            initialSelectedChoicesKeys={[showShareSchoolConfirmation.scheduleCycle.scheduleCycle.id]}
            confirmButtonLabel={strings.shareSchoolConfirmationSubmit()}
            onSubmit={(result) => void onShareSchoolWithScheduleConfirmation(result)}
          />
        ))}
      {state.schoolSettingsHeaderShowSubscribe === true && <SubscriptionsDialog isOpen={true} />}
    </Box>
  );
});
