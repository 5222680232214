import { ServiceContainer } from '@/providers';
import { ClassroomConnectedAppService, LocalizationService } from '@/services';
import { PlannerDataStore, PlannerDetailedCourseSectionsLoadable, StoreInvalidator, WorkDataStore } from '@/stores';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AppPlannerClassroomCourseViewModel } from './AppPlannerClassroomCourseViewModel';
import { ClassroomCourseViewModel } from './ClassroomCourseViewModel';
import { AppEditableClassroomConnectedAppViewModel } from './EditableClassroomConnectedAppViewModel';

export class AppPlannerEditableClassroomConnectedAppViewModel extends AppEditableClassroomConnectedAppViewModel<PlannerDetailedCourseSectionsLoadable> {
  constructor(
    protected readonly _connectedApp: ClassroomConnectedAppService,
    plannerId: string,
    onSuccess: (hasChanges: boolean) => Promise<void>,
    startSync: boolean,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _invalidationStore: StoreInvalidator = ServiceContainer.services.storeInvalidator
  ) {
    super(_connectedApp, plannerId, onSuccess, startSync, localization);
    makeObservable(this);
  }

  @computed
  protected get courseSectionLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  get courses(): ClassroomCourseViewModel[] {
    return chain(this.data.value ?? [])
      .orderBy('isSelected', 'desc')
      .map((c) => {
        const matchingCourse = this.courseSectionLoadable.values.find(
          (cs) => cs.courseSection?.externalSource?.externalId === c.classroomCourseId
        );
        return new AppPlannerClassroomCourseViewModel(matchingCourse?.courseSection, c);
      })
      .value();
  }

  protected async refreshDashboardData(): Promise<void> {
    const plannerContentsLoadable = this._plannerStore.getPlannerContentsInPlanner(this._plannerId);
    const inboxWorkCountLoadable = this._plannerStore.getInboxWorkCountInPlanner(this._plannerId);
    const courseSectionsLoadable = this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
    this._invalidationStore.invalidateCalendar();
    this._workStore.invalidateAllPublishedWorks();
    await Promise.all([
      plannerContentsLoadable.fetch(true),
      inboxWorkCountLoadable.fetch(true),
      courseSectionsLoadable.fetch(true)
    ]);
  }
}
