import { ServiceContainer } from '@/providers';
import { AnalyticsService, UserService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable } from 'mobx';
import { AppBaseSharePlannerViewModel, BaseSharePlannerViewModel } from '../planner';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface SharePlannerStepViewModel extends BaseOnboardingScreenViewModel {
  readonly userPersona: UserPersona;
  readonly sharePlannerViewModel: BaseSharePlannerViewModel;
}

export class AppSharePlannerStepViewModel
  extends AppBaseOnboardingScreenViewModel
  implements SharePlannerStepViewModel
{
  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _user: UserService = ServiceContainer.services.user,
    private readonly _analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    super(onboardingState);
    makeObservable(this);
  }

  readonly canSubmit = true;

  get userPersona(): UserPersona {
    return this._user.currentUser?.persona ?? UserPersona.UNSPECIFIED;
  }

  @computed
  get sharePlannerViewModel(): BaseSharePlannerViewModel {
    // This step is only used for parent personas.
    return new AppBaseSharePlannerViewModel(
      PlannerRelationshipKind.INDIVIDUAL,
      this._onboardingState.plannerId!,
      this.getRelationshipKindForUserPersona(this._onboardingState.userPersona),
      this._userStore,
      this._plannerStore,
      this._user,
      this._analytics
    );
  }

  submit() {
    return this._onboardingState.complete();
  }

  private getRelationshipKindForUserPersona(persona: UserPersona | undefined): PlannerRelationshipKind {
    switch (persona) {
      case UserPersona.OTHER:
      case UserPersona.STUDENT:
        return PlannerRelationshipKind.INDIVIDUAL;

      case UserPersona.PARENT:
        return PlannerRelationshipKind.PARENT;

      case UserPersona.TEACHER:
        return PlannerRelationshipKind.TEACHER;

      default:
        return PlannerRelationshipKind.UNSPECIFIED;
    }
  }
}
