import { DirectionsBikeRounded } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDayStateIndicator } from './UserDashboardCalendarDayStateIndicator';

export interface UserDashboardCalendarDayUpNextEmptyIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarDayUpNextEmptyIndicator = observer(
  ({ sx = [], className }: UserDashboardCalendarDayUpNextEmptyIndicatorProps) => {
    const strings = LocalizedStrings.calendar.day;

    return (
      <UserDashboardCalendarDayStateIndicator
        sx={sx}
        className={className}
        title={strings.upNextEmptyIndicatorTitle()}
        subtitle={strings.upNextEmptyIndicatorSubtitle()}
        renderIcon={(size, color) => <DirectionsBikeRounded sx={{ width: size, height: size, color }} />}
      />
    );
  }
);
