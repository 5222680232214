import { useServices } from '@/hooks';
import { WorkStepInfo } from '@/viewmodels';
import { CheckCircleRounded, CircleOutlined } from '@mui/icons-material';
import { Box, CardActionArea, IconButton, Stack, StackProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode, forwardRef } from 'react';
import { isSxArray } from '../../../utils';

export interface WorkDetailsStepProps extends StackProps {
  step: WorkStepInfo;
  onEdit: (step: WorkStepInfo) => void;
  isReadOnly: boolean;
  dragHandle?: () => ReactNode;
}

const WorkDetailsStepComponent = forwardRef<HTMLDivElement, WorkDetailsStepProps>(
  ({ sx = [], step, onEdit, dragHandle, isReadOnly, ...props }: WorkDetailsStepProps, ref) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;

    return (
      <Stack
        {...props}
        ref={ref}
        direction="row"
        spacing={0.5}
        sx={[
          {
            alignItems: 'flex-start'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <IconButton
          color={step.isCompleted ? 'primary' : undefined}
          size="small"
          onClick={() => (step.isCompleted = !step.isCompleted)}
          disabled={isReadOnly}
        >
          {step.isCompleted ? <CheckCircleRounded fontSize="small" /> : <CircleOutlined fontSize="small" />}
        </IconButton>
        <CardActionArea
          sx={{
            flex: 1,
            py: 0.5,
            px: 0.5,
            borderRadius: 0.5
          }}
          onClick={() => onEdit(step)}
          disabled={isReadOnly}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'baseline'
            }}
          >
            <Typography
              variant="body2"
              sx={{
                flex: 1,
                color: step.isCompleted ? (theme) => theme.palette.text.secondary : undefined,
                textDecoration: step.isCompleted ? 'line-through' : undefined
              }}
            >
              {step.title}
            </Typography>

            {step.duration > 0 && (
              <Typography
                variant="caption"
                noWrap
                sx={{
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {strings.durationFormat(step.duration)}
              </Typography>
            )}
          </Stack>
        </CardActionArea>
        {dragHandle != null && (
          <Box
            sx={{
              pt: 0.5
            }}
          >
            {dragHandle()}
          </Box>
        )}
      </Stack>
    );
  }
);

WorkDetailsStepComponent.displayName = 'WorkDetailsStep';
export const WorkDetailsStep = observer(WorkDetailsStepComponent);
