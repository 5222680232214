import { TimeOfDay } from '@/models';
import { FormHelperText, Stack } from '@mui/material';
import {
  DateValidationError,
  PickerChangeHandlerContext,
  TimeStepOptions,
  TimeValidationError
} from '@mui/x-date-pickers';
import { getHours, getMinutes, set, startOfDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { CalendarDatePicker, CalendarDatePickerKind } from './CalendarDatePicker';
import { TimePicker } from './TimePicker';

export type DateTimePickerDirection = 'column' | 'row';

export interface DateTimePickerProps {
  className?: string;

  /**
   * The selected dateTime.
   */
  value: Date | undefined;

  /**
   * Indicates if the `value` has a time.
   */
  hasTime: boolean;

  /**
   * The date format.
   */
  dateFormat: string;

  /**
   * The date picker field label. Optional.
   */
  datePickerLabel?: string;

  /**
   * Kind of the date picker. Optional.
   */
  datePickerKind?: CalendarDatePickerKind;

  canClearDate?: boolean;

  /**
   * Dates to highlight in date picker. Optional. Only affects picker when kind is 'user-dashboard-calendar'.
   */
  highlightedDates?: (startDate: Date, endDate: Date) => Date[];

  getPeriodTimesForDate?: (date: Date) => TimeOfDay[];

  /**
   * The time picker field label. Optional.
   */
  timePickerLabel?: string;

  /**
   * Steps over minutes for TimePicker.
   */
  timePickerMinutesStep?: number;

  /**
   *  The time steps between two time unit options. For example, if timeStep.minutes = 8, then the available minute
   *  options will be [0, 8, 16, 24, 32, 40, 48, 56]. When single column time renderer is used, only timeStep.minutes
   *  will be used.
   */
  timePickerTimeSteps?: TimeStepOptions;

  /**
   * Indicates whether the field should be disabled. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Force direction of pickers. Optional.
   */
  direction?: DateTimePickerDirection;
  /**
   * Callback when the dateTime value changes.
   * @param value The new dateTime
   * @param withTime Indicates if the time has been selected.
   */
  onChange: (value: Date | undefined, hasTime: boolean) => void;

  error?: string;
}

export const DateTimePicker = observer(
  ({
    className,
    value,
    hasTime,
    dateFormat,
    datePickerLabel,
    timePickerLabel,
    datePickerKind,
    disabled = false,
    highlightedDates,
    getPeriodTimesForDate,
    onChange,
    direction,
    timePickerMinutesStep,
    timePickerTimeSteps,
    canClearDate = true,
    error
  }: DateTimePickerProps) => {
    const [date, setDate] = useState(value ?? null);
    const [time, setTime] = useState(hasTime ? (value ?? null) : null);

    const [hasDateError, setHasDateError] = useState(false);
    const [hasTimeError, setHasTimeError] = useState(false);

    // The date and time merged together
    const dateTime = useMemo(() => {
      if (date != null) {
        return set(startOfDay(date), {
          hours: time != null ? getHours(time) : 0,
          minutes: time != null ? getMinutes(time) : 0
        });
      }

      return null;
    }, [date, time]);

    useEffect(() => onChange(dateTime ?? undefined, time != null), [dateTime, time, onChange]);

    function onDateChange(date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) {
      if (context.validationError != null) {
        setHasDateError(true);
        return;
      }

      setHasDateError(false);
      setDate(date);

      if (date == null) {
        setTime(null);
      } else if (getPeriodTimesForDate != null) {
        const times = getPeriodTimesForDate(date);
        if (times.length > 0) {
          const time = times[0];
          const newTimeDate = set(date, { hours: time.hours, minutes: time.minutes });
          setTime(newTimeDate);
        }
      }
    }

    function onTimeChange(time: Date | null, context: PickerChangeHandlerContext<TimeValidationError>) {
      if (context.validationError != null) {
        setHasTimeError(true);
        return;
      }

      setHasTimeError(false);
      setTime(time);
    }

    return (
      <Stack
        className={className}
        spacing={2}
        direction={direction ?? { xs: 'column', sm: 'row' }}
        sx={{
          justifyContent: 'space-evenly'
        }}
      >
        {/* Date picker */}
        <CalendarDatePicker
          value={date ?? null}
          disabled={disabled}
          label={datePickerLabel}
          format={dateFormat}
          slotProps={{
            actionBar: { actions: date != null && canClearDate ? ['clear'] : [] },
            textField: { error: error != null || hasDateError }
          }}
          onChange={(v, context) => onDateChange(v, context)}
          showDaysOutsideCurrentMonth
          kind={datePickerKind}
          highlightedDates={highlightedDates}
        />
        {/* Time picker */}
        <TimePicker
          value={time}
          label={timePickerLabel}
          disabled={disabled || date == null || hasDateError}
          sx={{ minWidth: 110, flex: 1 }}
          onChange={onTimeChange}
          minutesStep={timePickerMinutesStep}
          timeSteps={timePickerTimeSteps}
          canClear={true}
          error={error != null || hasTimeError}
        />
        {error != null && <FormHelperText error>{error}</FormHelperText>}
      </Stack>
    );
  }
);
