import { ServiceContainer } from '@/providers';
import { AnalyticsService, UserService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { DialogActionButtonConfiguration, StaticDialogViewModel } from '../utils';
import { AppBaseSharePlannerViewModel, BaseSharePlannerViewModel } from './BaseSharePlannerViewModel';

export interface SharePlannerDialogViewModel extends BaseSharePlannerViewModel, StaticDialogViewModel {}

export class AppSharePlannerDialogViewModel
  extends AppBaseSharePlannerViewModel
  implements SharePlannerDialogViewModel
{
  readonly kind = 'static' as const;
  readonly actions: DialogActionButtonConfiguration[] = [];
  readonly supplementaryActions: DialogActionButtonConfiguration[] = [];

  constructor(
    targetKind: PlannerRelationshipKind,
    plannerId: string,
    private _dismiss: () => Promise<void>,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    user: UserService = ServiceContainer.services.user,
    analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    super(targetKind, plannerId, undefined, userStore, plannerStore, user, analytics);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearError() {}

  dismiss() {
    return this._dismiss();
  }
}
