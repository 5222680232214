export type ScheduleSymbolKind =
  | ':badge:'
  | ':circle:'
  | ':close-square-bracket:'
  | ':five-point-star:'
  | ':hexagon:'
  | ':inversed-five-point-star:'
  | ':lozenge:'
  | ':open-square-bracket:'
  | ':outlined-circle:'
  | ':outlined-hexagon:'
  | ':outlined-lozenge:'
  | ':outlined-square:'
  | ':six-point-star:'
  | ':square:';

export const OrderedScheduleSymbolKinds: ScheduleSymbolKind[] = [
  ':square:',
  ':outlined-square:',
  ':circle:',
  ':outlined-circle:',
  ':hexagon:',
  ':outlined-hexagon:',
  ':lozenge:',
  ':outlined-lozenge:',
  ':badge:',
  ':six-point-star:',
  ':five-point-star:',
  ':inversed-five-point-star:',
  ':open-square-bracket:',
  ':close-square-bracket:'
];

export const ScheduleSymbolKindsWithBackground: ScheduleSymbolKind[] = [
  ':square:',
  ':circle:',
  ':hexagon:',
  ':lozenge:',
  ':badge:',
  ':six-point-star:',
  ':five-point-star:',
  ':inversed-five-point-star:'
];
