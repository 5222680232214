import { Currency } from '@/models';
import { Loadable, SubscriptionLoadable, SubscriptionProductsLoadable, UserDataStore } from '@/stores';
import { computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';
import { AppProductViewModel, ProductViewModel } from './ProductViewModel';

export interface ProductsListViewModel extends UpdatableViewModel {
  readonly products: ProductViewModel[];
  currency: Currency;
}

export class AppProductsListViewModel extends BaseUpdatableViewModel implements ProductsListViewModel {
  @observable private _currency: Currency = 'usd';

  constructor(
    private readonly _onInvoiceCreation: () => void,
    private readonly _redirectUrl: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get subscriptionLoadable(): SubscriptionLoadable {
    return this._userStore.subscription;
  }

  @computed
  private get availableProductsLoadable(): SubscriptionProductsLoadable {
    return this._userStore.availableSubscriptionProducts;
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.subscriptionLoadable, this.availableProductsLoadable];
  }

  @computed
  get products(): ProductViewModel[] {
    return this.availableProductsLoadable.data.map(
      (product) =>
        new AppProductViewModel(
          product,
          [],
          this._redirectUrl,
          () => this._currency,
          () => this._onInvoiceCreation(),
          this._localization,
          this._userStore
        )
    );
  }

  @computed
  get currency(): Currency {
    return this._currency;
  }

  set currency(value: Currency) {
    this._currency = value;
  }
}
