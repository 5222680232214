import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Box, Divider, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { SettingsCard, SettingsContent } from '../shared';
import { ConnectedAppsSection } from './ConnectedAppsSection';

export interface ConnectedAppsDialogProps {
  sx?: SxProps;
  className?: string;
}

export const ConnectedApps = observer(({ sx = [], className }: ConnectedAppsDialogProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.connectedApps;
  const plannerId = useActivePlannerId();

  const viewModel = useViewModel((viewModels) => viewModels.createConnectedAppsViewModel(plannerId), [plannerId]);

  return (
    <SettingsContent sx={sx} className={className}>
      <SettingsCard title={strings.title}>
        <Stack sx={{ width: '100%', p: 2, pr: 3 }} spacing={1}>
          {viewModel.connectedApps.map((connectedApp, i, allValues) => (
            <Box key={`connected-app-${i}`}>
              <ConnectedAppsSection viewModel={connectedApp} />

              {i < allValues.length - 1 && <Divider variant="middle" sx={{ mt: 1, opacity: 0.6 }} />}
            </Box>
          ))}
        </Stack>
      </SettingsCard>
    </SettingsContent>
  );
});
