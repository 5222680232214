import {
  numberOfDaysInInboxForWork,
  totalPlannedMinutesForWork,
  urlForExternalSourceBadge,
  urlForWorkIconFromWork,
  workCompletionDate,
  WorkIconInfo,
  WorkIcons
} from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, RouteService } from '@/services';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { ExternalSource } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/external_source_pb';
import { isBefore, isSameDay } from 'date-fns';
import { computed, makeObservable } from 'mobx';

export type StudentInsightsWorkState = 'late' | 'today' | 'active';

export interface StudentInsightsWorkViewModel {
  readonly id: string;
  readonly icon: WorkIconInfo;
  readonly title: string;
  readonly description: string | undefined;
  readonly inboxDays: number | undefined;
  readonly numberOfPlannedSessions: number;
  readonly totalPlannedMinutes: number | undefined;
  readonly state: StudentInsightsWorkState;
  readonly completionDate: Date | undefined;
  readonly dueDate: Date | undefined;
  readonly urlInPlanner: string;
  readonly externalSource: ExternalSource | undefined;
}

export class AppStudentInsightsWorkViewModel implements StudentInsightsWorkViewModel {
  constructor(
    private readonly _work: Work,
    private readonly _workIcons: WorkIcons,
    private readonly _route: RouteService = ServiceContainer.services.route,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
  }

  @computed
  get id(): string {
    return this._work.id;
  }

  @computed
  get icon(): WorkIconInfo {
    const icon =
      this._workIcons.iconsById.get(this._work.iconId) ?? this._workIcons.iconsById.get(this._workIcons.defaultIconId)!;

    return {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: urlForWorkIconFromWork(icon, this._work, 'light'),
      darkUrl: urlForWorkIconFromWork(icon, this._work, 'dark'),
      externalBadgeUrl: urlForExternalSourceBadge(this._work.externalSource?.sourceName, this._workIcons)
    };
  }

  @computed
  get title(): string {
    return this._work.title;
  }

  @computed
  get description(): string | undefined {
    return this._work.description?.text;
  }

  @computed
  get inboxDays(): number | undefined {
    return numberOfDaysInInboxForWork(this._work);
  }

  @computed
  get numberOfPlannedSessions(): number {
    return this._work.plannedWorks.length;
  }

  @computed
  get totalPlannedMinutes(): number | undefined {
    return totalPlannedMinutesForWork(this._work);
  }

  @computed
  get completionDate(): Date | undefined {
    return workCompletionDate(this._work);
  }

  @computed
  get dueDate(): Date | undefined {
    return this._work.dueTime?.toDate();
  }

  @computed
  get state(): StudentInsightsWorkState {
    const dueDate = this._work.dueTime?.toDate();

    if (this._work.status !== WorkStatus.ACTIVE || dueDate == null) {
      return 'active';
    }

    if (isSameDay(dueDate, this._dateService.now)) {
      return 'today';
    }

    if (isBefore(dueDate, this._dateService.now)) {
      return 'late';
    }

    return 'active';
  }

  @computed
  get urlInPlanner(): string {
    return this._route.resolvePlannerWorkLocation(this._work.plannerId, this._work.id);
  }

  @computed
  get externalSource(): ExternalSource | undefined {
    return this._work.externalSource;
  }
}
