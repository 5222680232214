import { isDemoId, plannerHasRelationshipKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsStorage, FeatureFlagService, LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable } from 'mobx';

export interface UserDashboardPickerViewModel {
  readonly currentPlanner: Planner;
  readonly otherPlanners: Planner[];
  readonly canCreateSchool: boolean;

  getUserRelationshipForPlanner(planner: Planner): PlannerRelationshipKind;
}

export class AppUserDashboardPickerViewModel implements UserDashboardPickerViewModel {
  constructor(
    private readonly _currentPlannerId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _settingsStorage: ApplicationSettingsStorage = ServiceContainer.services.settingsStorage,
    private readonly _featureFlag: FeatureFlagService = ServiceContainer.services.featureFlag
  ) {
    makeObservable(this);
  }

  @computed
  get currentPlanner(): Planner {
    const planner = this._userStore.getPlannerForId(this._currentPlannerId);
    if (planner == null) {
      throw new Error(`No planner found for id ${this._currentPlannerId}`);
    }
    return planner;
  }

  @computed
  get otherPlanners(): Planner[] {
    const currentSelection = this.currentPlanner;
    const userId = this._userStore.user.userId;

    return this._userStore.planners
      .filter(
        (planner) =>
          currentSelection.id !== planner.id &&
          // Not showing planners with a teacher relationship. Will be accessible via an "open student planner" button.

          !plannerHasRelationshipKindsForUser(userId, planner, PlannerRelationshipKind.TEACHER) &&
          (this._settingsStorage.isDemoMode === true || !isDemoId(planner.id))
      )
      .sort((a, b) => {
        return a.title.localeCompare(b.title, this._localization.currentLocale, { sensitivity: 'base' });
      });
  }

  @computed
  get canCreateSchool() {
    const isFeaturedEnabled = this._featureFlag.isEnabled('teacher-dashboard');
    const isTeacher = this._userStore.user.persona === UserPersona.TEACHER;
    const hasExistingSchool = this._userStore.participatingSchools.values.some((school) =>
      school.connectedPlannerIds.includes(this._currentPlannerId)
    );

    return isFeaturedEnabled && isTeacher && !hasExistingSchool;
  }

  getUserRelationshipForPlanner(planner: Planner): PlannerRelationshipKind {
    const relationship = planner.relationships.find(
      (r) => r.kind !== PlannerRelationshipKind.CREATOR && r.userId === this._userStore.user.userId
    );

    return relationship?.kind ?? PlannerRelationshipKind.UNSPECIFIED;
  }
}
