import HideDemoCoursesIcon from '@mui/icons-material/VisibilityOffRounded';
import ShowDemoCoursesIcon from '@mui/icons-material/VisibilityRounded';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { SideBarButton } from '../../shared';

export interface MainScreenPlannerSideBarDemoCoursesVisibilityButtonProps {
  sx?: SxProps;
  className?: string;
  isCompact: boolean;
  demoCoursesAreVisible: boolean;
  toggleDemoCoursesVisibility: () => Promise<void>;
}

export const MainScreenPlannerSideBarDemoCoursesVisibilityButton = observer(
  ({
    sx = [],
    className,
    demoCoursesAreVisible,
    toggleDemoCoursesVisibility,
    isCompact
  }: MainScreenPlannerSideBarDemoCoursesVisibilityButtonProps) => {
    const strings = LocalizedStrings.sidebar.school;

    const [isSaving, setIsSaving] = useState(false);
    const title = demoCoursesAreVisible ? strings.hideDemoCoursesTitle() : strings.showDemoCoursesTitle();
    const tooltip = demoCoursesAreVisible ? strings.hideDemoCoursesTooltip() : strings.showDemoCoursesTooltip();
    const icon = demoCoursesAreVisible ? <HideDemoCoursesIcon /> : <ShowDemoCoursesIcon />;

    async function onClick() {
      setIsSaving(true);
      try {
        await toggleDemoCoursesVisibility();
      } finally {
        setIsSaving(false);
      }
    }

    return (
      <SideBarButton
        sx={sx}
        className={className}
        isCompact={isCompact}
        onSelect={() => void onClick()}
        title={title}
        icon={icon}
        tooltip={tooltip}
        disabled={isSaving}
      />
    );
  }
);
