import { matchPath, PathPattern, useLocation } from 'react-router-dom';

export function useIsMatchPath(pattern: string | PathPattern | undefined): boolean {
  const location = useLocation();
  const matchesPath = matchPath(pattern ?? '', location.pathname);

  if (pattern == null) {
    return false;
  }

  return Boolean(matchesPath);
}
