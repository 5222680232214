import { Teacher } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_pb';
import LocalizedStrings from 'strings';
import { AdminTableTextCell } from '../../shared';

export interface AdminSchoolClassesTableTeachersCellProps {
  teachers: Teacher[];
}

export function AdminSchoolClassesTableTeachersCell({ teachers }: AdminSchoolClassesTableTeachersCellProps) {
  const teachersNames = teachers.map((t) => t.fullName);
  let label = LocalizedStrings.admin.classes.list.teachersNoneLabel();

  if (teachers.length > 2) {
    label = `${teachersNames.slice(0, 2).join(', ')}, +${teachers.length - 2}`;
  } else if (teachers.length > 0) {
    label = teachersNames.join(', ');
  }

  return <AdminTableTextCell value={label} />;
}
