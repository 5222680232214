import { ScheduleCycleListViewModel } from '@/viewmodels';
import { Box, List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UpdatablePresenter } from '../../../utils';
import { ScheduleCycleListItem } from './ScheduleCycleListItem';

export interface ScheduleCycleListProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleListViewModel;
  showSource: boolean;
  showEmptyLabel?: boolean;
}

export const ScheduleCycleList = observer(
  ({ sx = [], className, viewModel, showSource, showEmptyLabel = true }: ScheduleCycleListProps) => {
    return (
      <Box sx={{ ...sx, width: '100%', flex: 1, overflow: 'hidden' }} className={className}>
        <UpdatablePresenter
          sx={{ width: '100%', flex: 1, overflow: 'hidden' }}
          viewModel={viewModel}
          renderData={() => {
            const { schedules } = viewModel;
            const visibleSchedules = viewModel.showPastSchedules ? schedules : schedules.filter((s) => !s.isPast);

            return (
              <List sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                {visibleSchedules.map((schedule) => (
                  <ScheduleCycleListItem
                    key={schedule.scheduleCycle.scheduleCycle.id}
                    scheduleCycleInfo={schedule}
                    showSource={showSource}
                  />
                ))}
                {showEmptyLabel && visibleSchedules.length === 0 && (
                  <Stack
                    spacing={2}
                    sx={{
                      p: 2,
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontStyle: 'italic'
                      }}
                    >
                      {LocalizedStrings.scheduleCycle.list.noActiveSchedule(schedules.length > 0)}
                    </Typography>
                  </Stack>
                )}
              </List>
            );
          }}
        />
      </Box>
    );
  }
);
