import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { isSxArray } from '../../../utils';
import { CopyText } from '../../utils';

export interface AdminSchoolParticipationCodeProps {
  sx?: SxProps;
  className?: string;
  code: string;
}

export function AdminSchoolParticipationCode({ sx = [], code, className }: AdminSchoolParticipationCodeProps) {
  return (
    <Stack
      className={className}
      spacing={1}
      direction="row"
      sx={[
        {
          alignItems: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <CopyText text={code}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: '400'
          }}
        >
          {code}
        </Typography>
      </CopyText>
    </Stack>
  );
}
