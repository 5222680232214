import { useServices } from '@/hooks';
import { PlannerListViewModel } from '@/viewmodels';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannerList } from './PlannerList';
import { PlannerListItem } from './PlannerListItem';
import { PlannerUpcomingListEmptyIndicator } from './PlannerUpcomingListEmptyIndicator';

export interface PlannerUpcomingListProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerListViewModel;
}

export const PlannerUpcomingList = observer(({ sx = [], className, viewModel }: PlannerUpcomingListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <PlannerList
      sx={sx}
      className={className}
      viewModel={viewModel}
      title={strings.upcomingTitle}
      subtitle={strings.upcomingSubtitle}
      renderItem={(item) => <PlannerListItem item={item} displaySteps />}
      renderEmptyIndicator={() => <PlannerUpcomingListEmptyIndicator />}
    />
  );
});
