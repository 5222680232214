import { useServices, useViewModel } from '@/hooks';
import { KeyboardShortcutConfiguration } from '@/utils';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../dialog';
import { ConfirmationDialogMessage } from './ConfirmationDialogMessage';

const submitShortcutConfig: KeyboardShortcutConfiguration = {
  alt: false,
  code: 'Enter',
  ctrl: false,
  enabled: true,
  supportMetaAsCtrl: false,
  shift: false
};

export interface ConfirmationDialogProps {
  isOpen: boolean;

  /**
   * The confirmation message.
   */
  message: string;

  /**
   * Indicates whether to display the cancel button. Optional. Default is `true`.
   */
  displayCancelButton?: boolean;

  /**
   * The dialog title. Optional. Default is `UtilsStrings.confirmationDialogTitle`.
   */
  title?: string;

  /**
   * The confirm button label. Optional. Default is `UtilsStrings.confirmationDialogConfirmButton`.
   */
  confirmButtonLabel?: string;

  /**
   * The cancel button label. Optional. Default is `UtilsStrings.confirmationDialogCancelButton`.
   */
  cancelButtonLabel?: string;

  /**
   * If true, the confirm button will be shown in red.
   */
  isDestructive?: boolean;

  onSubmit: (hasConfirmed: boolean) => void;
}

export const ConfirmationDialog = observer(
  ({
    isOpen,
    message,
    displayCancelButton = true,
    title,
    confirmButtonLabel,
    cancelButtonLabel,
    onSubmit,
    isDestructive
  }: ConfirmationDialogProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    const viewModel = useViewModel((viewModels) =>
      viewModels.createConfirmationDialogViewModel(
        () => onSubmit(true),
        () => onSubmit(false),
        displayCancelButton,
        confirmButtonLabel,
        cancelButtonLabel,
        isDestructive
      )
    );

    return (
      <Dialog
        isOpen={isOpen}
        viewModel={viewModel}
        title={title ?? strings.confirmationDialogTitle}
        width="sm"
        submit={() => onSubmit(true)}
        customSubmitShortcut={{ ...submitShortcutConfig, enabled: isOpen }}
        disableClose
        renderData={() => <ConfirmationDialogMessage message={message} />}
      />
    );
  }
);
