import { SchoolEntitlementInfo } from '@/models';
import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { SubscriptionsTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolEntitlementLoadable = LoadableValue<SchoolEntitlementInfo>;

export class AppSchoolEntitlementLoadable
  extends BaseLoadableValue<SchoolEntitlementInfo>
  implements SchoolEntitlementLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _entitlement: Entitlement,
    private readonly _subscriptionTransport: SubscriptionsTransportService
  ) {
    super();
  }

  protected loadData(): Promise<SchoolEntitlementInfo> {
    return this._subscriptionTransport.getIsSchoolEntitled(this._schoolId, this._entitlement);
  }
}
