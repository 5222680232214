import { alpha, ButtonProps, Interpolation, Theme } from '@mui/material';

export const elevatedButtonVariants: {
  props: Partial<ButtonProps<'button', unknown>>;
  style: Interpolation<{ theme: Theme }>;
}[] = [
  {
    props: { variant: 'contained-grey' },
    style: (theme) => ({
      backgroundColor: getBackgroundColor(theme.theme)
    })
  },
  {
    props: { variant: 'contained-grey', color: 'primary' },
    style: (theme) => ({
      color: theme.theme.palette.primary.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.primary.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'secondary' },
    style: (theme) => ({
      color: theme.theme.palette.secondary.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.secondary.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'error' },
    style: (theme) => ({
      color: theme.theme.palette.error.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.error.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'info' },
    style: (theme) => ({
      color: theme.theme.palette.info.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.info.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'success' },
    style: (theme) => ({
      color: theme.theme.palette.success.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.success.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'warning' },
    style: (theme) => ({
      color: theme.theme.palette.warning.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.warning.main)
      }
    })
  }
];

function getBackgroundColor(theme: Theme): string {
  switch (theme.palette.mode) {
    case 'dark':
      return alpha(theme.palette.action.active, 0.08);
    case 'light':
      return theme.palette.action.selected;
  }
}

function getHoverBackgroundColor(color: string): string {
  return alpha(color, 0.15);
}
