import { dayToDate, UserDashboardInfo, UserDashboardKind } from '@/models';
import { ServiceContainer } from '@/providers';
import { mergeLoadableStates, UserDashboardScheduleCyclesLoadable, UserDataStore } from '@/stores';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { differenceInCalendarDays } from 'date-fns';
import { chain } from 'lodash';
import { computed, makeObservable, observable, override } from 'mobx';
import LocalizedStrings from 'strings';
import { DateService } from '../../../services';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../../utils';
import { UpdatableViewModelState } from '../UpdatableViewModel';

export interface ScheduleCycleCreationUserDashboardPickerDashboardInfo {
  readonly kind: UserDashboardKind;
  readonly id: string;
  readonly name: string;
  readonly canCreateSchedule: boolean;
  readonly hasExistingScheduleCycle: boolean;
}

export interface ScheduleCycleCreationUserDashboardPickerViewModel extends UpdatableDialogViewModel {
  readonly schools: ScheduleCycleCreationUserDashboardPickerDashboardInfo[];
  readonly planners: ScheduleCycleCreationUserDashboardPickerDashboardInfo[];
  selectedDashboardId: string | undefined;
}

export class AppScheduleCycleCreationUserDashboardPickerViewModel
  extends AppBaseUpdatableDialogViewModel
  implements ScheduleCycleCreationUserDashboardPickerViewModel
{
  private readonly _scheduleCyclesLoadables: UserDashboardScheduleCyclesLoadable[];
  @observable private _selectedDashboardId: string | undefined;

  private _saveButtonConfig = new SaveDialogActionButtonConfiguration(
    'main',
    this._localization,
    () => this.submit(),
    () => LocalizedStrings.scheduleCycle.userDashboardPicker.submitButton()
  );
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  constructor(
    private readonly _plannerId: string,
    private readonly _onSubmit: (dashboard: UserDashboardInfo) => Promise<void>,
    onCancel: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    private readonly _dateService: DateService = ServiceContainer.services.dateService,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(localization, onCancel);
    makeObservable(this);

    this._scheduleCyclesLoadables = this._userStore.getScheduleCyclesForPlannerAndItsSchools(this._plannerId);
  }

  @computed
  private get dashboards(): ScheduleCycleCreationUserDashboardPickerDashboardInfo[] {
    return chain(this._scheduleCyclesLoadables)
      .filter((l) => l.userDashboard.kind === 'planner' || !l.userDashboard.school.school!.isArchived)
      .map<ScheduleCycleCreationUserDashboardPickerDashboardInfo>((l) => ({
        id: l.userDashboard.id,
        name: l.userDashboard.title,
        kind: l.userDashboard.kind,
        canCreateSchedule: l.userDashboard.kind === 'school' ? this.isSchoolAdmin(l.userDashboard.school) : true,
        hasExistingScheduleCycle: this.userDashboardHasExistingScheduleCycle(l)
      }))
      .orderBy(['name'])
      .value();
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._saveButtonConfig.isEnabled = this.selectedDashboardId != null;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get schools(): ScheduleCycleCreationUserDashboardPickerDashboardInfo[] {
    return this.dashboards.filter((d) => d.kind === 'school');
  }

  @computed
  get planners(): ScheduleCycleCreationUserDashboardPickerDashboardInfo[] {
    return this.dashboards.filter((d) => d.kind === 'planner');
  }

  @computed
  get selectedDashboardId(): string | undefined {
    return this._selectedDashboardId;
  }

  set selectedDashboardId(value: string | undefined) {
    this._selectedDashboardId = value;
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates(this._scheduleCyclesLoadables.map((l) => l.loadable.state));
  }

  readonly hasChanges = false;
  readonly isSubmitting = false;

  @computed
  get hasData(): boolean {
    return this._scheduleCyclesLoadables.every((l) => l.loadable.hasData);
  }

  async reloadData() {
    await Promise.all(this._scheduleCyclesLoadables.map((l) => l.loadable.fetch(true)));
  }

  private async submit() {
    if (this.selectedDashboardId == null) {
      return await this.dismiss();
    }

    if (this.selectedDashboardId === this._plannerId) {
      return await this._onSubmit({ id: this.selectedDashboardId, kind: 'planner' });
    }

    return await (this._userStore.getSchoolForId(this.selectedDashboardId) != null
      ? this._onSubmit({ id: this.selectedDashboardId, kind: 'school' })
      : this.dismiss());
  }

  private userDashboardHasExistingScheduleCycle(dashboard: UserDashboardScheduleCyclesLoadable): boolean {
    if (dashboard.userDashboard.kind === 'school') {
      return dashboard.loadable.data.length > 0;
    }

    const schoolSchedulesIds = this._scheduleCyclesLoadables.reduce<string[]>((ids, loadable) => {
      ids.push(...loadable.loadable.data.map((s) => s.scheduleCycle.id));
      return ids;
    }, []);

    return dashboard.loadable.data.some((sc) => {
      // We have some schedule cycle that is not in the past
      return (
        !schoolSchedulesIds.includes(sc.scheduleCycle.id) &&
        differenceInCalendarDays(this._dateService.now, dayToDate(sc.scheduleCycle.endDay!)) <= 0
      );
    });
  }

  private isSchoolAdmin(school: SchoolInformation): boolean {
    return school.administrators.some((a) => a.userId === this._userStore.user.userId);
  }
}
