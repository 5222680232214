import { useDroppable } from '@dnd-kit/core';
import { ReactNode } from 'react';

export interface DroppableProps {
  id: string;
  acceptedTypes: string[];
  disabled?: boolean;
  children: (setNodeRef: (ref: HTMLElement | null) => void, isOver: boolean) => ReactNode;
}

export function Droppable({ id, acceptedTypes, disabled = false, children }: DroppableProps) {
  const { setNodeRef, isOver } = useDroppable({
    id,
    disabled,
    data: {
      accepts: acceptedTypes
    }
  });

  return <>{children(setNodeRef, isOver)}</>;
}
