import { useDelayedEffect } from '@/hooks';
import { ReactNode, useState } from 'react';

export interface AutoHideProps {
  /**
   * The duration in milliseconds before hiding the `children`.
   */
  durationMs: number;

  /**
   * Callback when hiding. Optional.
   */
  onHide?: () => void;
  children: ReactNode;
}

export const AutoHide = ({ children, durationMs, onHide }: AutoHideProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useDelayedEffect(() => {
    setIsHidden(true);
    onHide?.();
  }, durationMs);

  return !isHidden ? <>{children}</> : null;
};
