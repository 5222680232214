import { ConnectedAppKind } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService } from '@/services';
import { Loadable, ParticipatingSchoolsLoadable, PlannerDataStore, UserDataStore, mergeLoadableStates } from '@/stores';
import { ForbiddenError } from '@/utils';
import { computed, makeObservable, override } from 'mobx';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  CloseDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { AppConnectedAppEditViewModel, ConnectedAppEditViewModel } from './ConnectedAppEditViewModel';

export interface ConnectedAppEditDialogViewModel extends UpdatableDialogViewModel {
  readonly editViewModel: ConnectedAppEditViewModel;
}

export class AppConnectedAppEditDialogViewModel
  extends AppBaseUpdatableDialogViewModel
  implements ConnectedAppEditDialogViewModel
{
  private _closeButtonConfig = new CloseDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () =>
    this.editViewModel.editableConnectedApp.save()
  );
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  constructor(
    private readonly _connectedAppKind: ConnectedAppKind,
    private readonly _plannerId: string,
    private readonly _schoolId: string | undefined,
    private readonly _startSync: boolean,
    private readonly _onSuccess: (hasChanges: boolean) => Promise<void>,
    onCancel: () => Promise<void>,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(localization, onCancel, true);
    makeObservable(this);
  }

  @computed
  private get courseSectionsLoadable(): Loadable<unknown> {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get participatingSchoolsLoadable(): ParticipatingSchoolsLoadable {
    return this._userStore.participatingSchools;
  }

  @computed
  get hasData(): boolean {
    return (
      this.courseSectionsLoadable.hasData &&
      this.participatingSchoolsLoadable.hasData &&
      this.editViewModel.editableConnectedApp.hasData
    );
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([
      this.courseSectionsLoadable.state,
      this.participatingSchoolsLoadable.state,
      this.editViewModel.editableConnectedApp.state
    ]);
  }

  @computed
  get hasChanges(): boolean {
    return this.editViewModel.editableConnectedApp.hasChanges;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    if (this.hasChanges) {
      this._cancelButtonConfig.isEnabled = !this.isApplying;
      this._saveButtonConfig.isEnabled = !this.isApplying && this.hasChanges;
      this._saveButtonConfig.showLoading = this.isApplying;
      return [this._cancelButtonConfig, this._saveButtonConfig];
    } else {
      return [this._closeButtonConfig];
    }
  }

  @computed
  get isApplying(): boolean {
    return this.editViewModel.editableConnectedApp.isApplying;
  }

  @override
  get error(): string | undefined {
    return this.editViewModel.editableConnectedApp.error;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get editViewModel(): ConnectedAppEditViewModel {
    return new AppConnectedAppEditViewModel(
      this._connectedAppKind,
      this._plannerId,
      this._schoolId,
      this._startSync,
      this._onSuccess,
      this._connectedApps,
      this._localization
    );
  }

  errorMessageSelector(error: Error): string | undefined {
    if (error instanceof ForbiddenError) {
      return this._localization.localizedStrings.connectedApps.forbiddenErrorMessage;
    }
  }

  clearError() {
    this.editViewModel.editableConnectedApp.clearError();
  }

  reloadData(): Promise<void> {
    return this.editViewModel.editableConnectedApp.reloadData();
  }
}
