import { useViewModel } from '@/hooks';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { SettingsCancelButton, SettingsSaveButton } from '../../shared';

export interface SchoolEditProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  disabled?: boolean;
}

export const SchoolEdit = observer(({ sx = [], className, schoolId, disabled = false }: SchoolEditProps) => {
  const strings = LocalizedStrings.settings.school.details.generalInformation;

  const viewModel = useViewModel((viewModels) => viewModels.createSchoolEditViewModel(schoolId));

  return (
    <Stack spacing={3} sx={sx} className={className}>
      <TextField
        label={strings.nameInputLabel()}
        value={viewModel.name}
        placeholder={strings.nameInputPlaceholder()}
        fullWidth
        disabled={viewModel.isApplying || viewModel.isReadOnly || disabled}
        onChange={(e) => (viewModel.name = e.target.value)}
      />
      <TextField
        label={strings.subtitleInputLabel()}
        value={viewModel.subtitle}
        placeholder={strings.subtitleInputPlaceholder()}
        fullWidth
        disabled={viewModel.isApplying || viewModel.isReadOnly || disabled}
        onChange={(e) => (viewModel.subtitle = e.target.value)}
      />
      {!viewModel.isReadOnly && viewModel.canApply && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'flex-end'
          }}
        >
          <SettingsCancelButton onClick={() => viewModel.resetChanges()} disabled={viewModel.isApplying || disabled} />

          <SettingsSaveButton
            onClick={() => void viewModel.apply()}
            disabled={viewModel.isApplying || disabled}
            loading={viewModel.isApplying}
          />
        </Stack>
      )}
    </Stack>
  );
});
