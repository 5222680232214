import { AnalyticsService, LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface CreatePlannerViewModel {
  readonly selectableTargetKinds: PlannerRelationshipKind[];

  targetKind: PlannerRelationshipKind;
  title: string;

  readonly isApplying: boolean;
  readonly createPlannerError?: string;
  clearCreatePlannerError(): void;
}

export class AppCreatePlannerViewModel implements CreatePlannerViewModel {
  @observable private _targetKind: PlannerRelationshipKind;
  @observable private _title = '';
  @observable private _isApplying = false;
  @observable private _createPlannerError: string | undefined;

  readonly selectableTargetKinds: PlannerRelationshipKind[];

  constructor(
    protected readonly _userStore: UserDataStore,
    protected readonly _localization: LocalizationService,
    protected readonly _analytics: AnalyticsService,
    protected readonly _onClose: (newPlannerId: string | undefined) => void
  ) {
    makeObservable(this);

    switch (_userStore.user.persona) {
      case UserPersona.TEACHER:
      case UserPersona.OTHER:
      case UserPersona.PARENT:
        this.selectableTargetKinds = [PlannerRelationshipKind.INDIVIDUAL, PlannerRelationshipKind.PARENT];
        this._targetKind = PlannerRelationshipKind.INDIVIDUAL;
        break;

      default:
        this.selectableTargetKinds = [PlannerRelationshipKind.INDIVIDUAL];
        this._targetKind = PlannerRelationshipKind.INDIVIDUAL;
        break;
    }
  }

  @computed
  get targetKind(): PlannerRelationshipKind {
    return this._targetKind;
  }

  set targetKind(value: PlannerRelationshipKind) {
    this._targetKind = value;
  }

  @computed
  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get createPlannerError(): string | undefined {
    return this._createPlannerError;
  }

  @action
  clearCreatePlannerError() {
    this._createPlannerError = undefined;
  }

  @action
  async apply() {
    this._isApplying = true;

    try {
      const plannerId = await this._userStore.createPlanner(this.targetKind, this.title, true);
      this._onClose(plannerId);
    } catch (error) {
      captureException(error);
      runInAction(() => (this._createPlannerError = (error as Error).message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }
}
