import {
  emotionalPulseRatingToStatus,
  isAdminOfSharedSchool,
  plannerHasAccessKindsForUser,
  StudentEmotionalStatus,
  SubscriptionInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import LocalizedStrings from '@/resources/strings';
import { DefaultDemoModeMockDate } from '@/services';
import { PlannerContentsLoadable, PlannerDetailedCourseSectionsLoadable } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { Browser } from '@capacitor/browser';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ProfileMenuViewModel {
  readonly fullName: string;
  readonly email: string;
  readonly emotionalStatusEmoji: string | undefined;
  readonly imageUrl: string;
  readonly isRefreshing: boolean;
  readonly canEditUserProperties: boolean;
  readonly canSubscribe: boolean;
  readonly canManageSubscription: boolean;
  readonly canChangeEmotionalStatus: boolean;
  readonly isDemoMode: boolean;
  readonly showEmotionalSurveyOption: boolean;

  readonly isApplying: boolean;
  readonly canCreateSchool: boolean;
  error: string | undefined;

  refreshData(): Promise<void>;
  logout(): Promise<void>;
  openSupportEmail(): void;
  openIntercom(): void;
  manageSubscription(): Promise<void>;
  toggleDemoMode(): void;
}

export class AppProfileMenuViewModel implements ProfileMenuViewModel {
  @observable private _isApplying = false;
  @observable private _error: string | undefined;

  constructor(
    private readonly _plannerId: string,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _authentication = ServiceContainer.services.authentication,
    private readonly _analytics = ServiceContainer.services.analytics,
    private readonly _localization = ServiceContainer.services.localization,
    private readonly _user = ServiceContainer.services.user,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _connectedApps = ServiceContainer.services.connectedApps,
    private readonly _featureFlags = ServiceContainer.services.featureFlag,
    private readonly _settingsStorage = ServiceContainer.services.settingsStorage,
    private readonly _dateService = ServiceContainer.services.dateService,
    private readonly _intercom = ServiceContainer.services.intercom
  ) {
    makeObservable(this);
  }

  @computed
  private get plannerContentsLoadable(): PlannerContentsLoadable {
    return this._plannerStore.getPlannerContentsInPlanner(this._plannerId);
  }

  @computed
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get hasFullAccess(): boolean {
    const planner = this._userStore.getPlannerForId(this._plannerId);
    return planner != null
      ? plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS)
      : false;
  }

  @computed
  private get emotionPulseRating(): number | undefined {
    const { emotionalPulse } = this._userStore;

    if (emotionalPulse.state !== 'fulfilled') {
      return -1;
    }

    return emotionalPulse.data?.rating;
  }

  @computed
  private get subscription(): SubscriptionInfo | undefined {
    return this._userStore.subscription.data;
  }

  @computed
  get fullName(): string {
    return this._user.currentUser?.fullName ?? this._localization.localizedStrings.mainScreen.anonymousName;
  }

  @computed
  get email(): string {
    return this._user.currentUser?.emailAddress ?? this._localization.localizedStrings.mainScreen.anonymousEmail;
  }

  @computed
  get imageUrl(): string {
    return this._user.currentUser?.pictureUrl ?? '';
  }

  @computed
  get isRefreshing(): boolean {
    return (
      this._userStore.plannersLoadable.state === 'pending' ||
      this._userStore.subscription.state === 'pending' ||
      this.plannerContentsLoadable.state === 'pending' ||
      this.courseSectionsLoadable.state === 'pending' ||
      this._connectedApps.getIsSyncing(this._plannerId)
    );
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  set error(value: string | undefined) {
    this._error = value;
  }

  @computed
  get canChangeEmotionalStatus(): boolean {
    return this.hasFullAccess && this._userStore.user.persona === UserPersona.STUDENT;
  }

  @computed
  get canEditUserProperties(): boolean {
    return this._featureFlags.isEnabled('edit-user-properties');
  }

  @computed
  get canSubscribe(): boolean {
    const adminSharedSchools = this._userStore.schools.filter((s) =>
      isAdminOfSharedSchool(this._userStore.user.userId, s)
    );

    return (
      this.subscription == null && this._userStore.user.persona === UserPersona.TEACHER && adminSharedSchools.length > 0
    );
  }

  @computed
  get canManageSubscription(): boolean {
    return this.subscription != null;
  }

  @computed
  get isDemoMode(): boolean {
    return this._settingsStorage.isDemoMode ?? false;
  }

  @computed
  get showEmotionalSurveyOption(): boolean {
    return this._user.currentUser?.persona === UserPersona.STUDENT;
  }

  @computed
  get canCreateSchool(): boolean {
    return this._user.currentUser?.persona === UserPersona.TEACHER;
  }

  @computed
  get emotionalStatusEmoji(): string | undefined {
    const user = this._user.currentUser;

    if (user == null || user.persona !== UserPersona.STUDENT) {
      return undefined;
    }

    const emotionalRating = this.emotionPulseRating;
    if (emotionalRating == null || emotionalRating < 0) {
      return undefined;
    }

    const status = emotionalPulseRatingToStatus(emotionalRating);
    switch (status) {
      case StudentEmotionalStatus.SAD:
        return '🙁';
      case StudentEmotionalStatus.OK:
        return '😐';
      case StudentEmotionalStatus.HAPPY:
        return '😃';
    }
  }

  async refreshData(): Promise<void> {
    await Promise.all([this._userStore.plannersLoadable.fetch(true), this._userStore.subscription.fetch(true)]);

    await this._connectedApps.refreshSyncStatus(this._plannerId);

    // Loading contents and courses after the connected apps have synced.
    await Promise.all([this.plannerContentsLoadable.fetch(true), this.courseSectionsLoadable.fetch(true)]);
  }

  async logout(): Promise<void> {
    await this._authentication.signOut();
  }

  @action
  openSupportEmail() {
    this._analytics.logHelpEvent('menu');
    const strings = LocalizedStrings.utils;
    window.open(`mailto:support@studyo.co?subject=${strings.supportSubject()}`);
  }

  openIntercom() {
    this._intercom.showChat();
  }

  toggleDemoMode() {
    this._settingsStorage.isDemoMode = this._settingsStorage.isDemoMode !== true;

    if (this._settingsStorage.isDemoMode) {
      this._dateService.mockCurrentDate(DefaultDemoModeMockDate);
    } else {
      this._dateService.unmockCurrentDate();
    }
  }

  async manageSubscription(): Promise<void> {
    runInAction(() => {
      this._isApplying = true;
      this._error = undefined;
    });

    try {
      const url = await this.getManageSubscriptionUrl();
      await Browser.open({ url });
    } catch (e) {
      captureException(e);
      const error = e as Error;
      const strings = this._localization.localizedStrings.utils;
      runInAction(() => (this._error = strings.manageSubscriptionErrorMessage(error.message)));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  private async getManageSubscriptionUrl(): Promise<string> {
    return await this._userStore.getManageSubscriptionUrl(window.location.href);
  }
}
