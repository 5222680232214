import { CircleRounded } from '@mui/icons-material';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../../../utils';

export interface UserDashboardCalendarHeaderCurrentDateViewProps {
  sx?: SxProps;
  className?: string;
  isToday: boolean;
  title: string;
  subtitle?: string;
}

export const UserDashboardCalendarHeaderCurrentDateView = observer(
  ({ sx = [], className, isToday, subtitle, title }: UserDashboardCalendarHeaderCurrentDateViewProps) => {
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    return (
      <Stack
        className={className}
        direction="row"
        spacing={2}
        sx={[
          {
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {isToday && <CircleRounded color="error" sx={{ width: 26, height: 26 }} />}
        <Stack
          sx={{
            flex: 1
          }}
        >
          <Typography
            variant={isExtraSmallScreen ? 'h6' : 'h5'}
            sx={{
              fontWeight: isExtraSmallScreen ? '500' : '400'
            }}
          >
            {title}
          </Typography>

          {subtitle != null && (
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }
);
