import { CurriculumTransportService } from '@/transports';
import { Course } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/resources/course_pb';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type CurriculumCoursesLoadable = LoadableMap<Course>;

export class AppCurriculumCoursesLoadable extends BaseLoadableMap<Course> implements CurriculumCoursesLoadable {
  constructor(private readonly _curriculumTransport: CurriculumTransportService) {
    super();
  }

  protected async loadData(): Promise<Map<string, Course>> {
    const courses = await this._curriculumTransport.getCourses();
    return new Map<string, Course>(courses.map((c) => [c.id, c]));
  }
}
