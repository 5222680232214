import { SxProps } from '@mui/system';
import {
  TimePicker as MuiTimePicker,
  PickerChangeHandlerContext,
  TimeStepOptions,
  TimeValidationError
} from '@mui/x-date-pickers';
import { observer } from 'mobx-react-lite';

export interface TimePickerProps {
  /**
   * The value.
   */
  value: Date | null;

  /**
   * The label. Optional.
   */
  label?: string;

  /**
   * Indicates whether to disable.
   */
  disabled?: boolean;

  className?: string;

  sx?: SxProps;
  error?: boolean;
  helperText?: string;
  minutesStep?: number;
  timeSteps?: TimeStepOptions;
  canClear?: boolean;

  /**
   * Callback when updating value.
   * @param value The new value.
   * @param context The new context of the picker. Contains a validation error if one exists.
   */
  onChange: (value: Date | null, context: PickerChangeHandlerContext<TimeValidationError>) => void;
}

export const TimePicker = observer(
  ({
    className,
    sx = [],
    value,
    label,
    disabled,
    onChange,
    canClear,
    error,
    helperText,
    minutesStep,
    timeSteps
  }: TimePickerProps) => {
    return (
      <MuiTimePicker
        sx={sx}
        className={className}
        value={value}
        label={label}
        format={'HH:mm'}
        disabled={disabled}
        slotProps={{
          textField: {
            helperText,
            error
          },
          actionBar: {
            actions: (canClear ?? false) ? ['clear', 'accept'] : ['accept']
          }
        }}
        timeSteps={timeSteps}
        minutesStep={minutesStep}
        onChange={(date, context) => {
          onChange(date, context);
        }}
      />
    );
  }
);
