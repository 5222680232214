import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSection as PlannerCourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_details_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { Planners } from '@buf/studyo_studyo-today-planners.connectrpc_es/studyo/today/planners/v1/planners_connect';
import { Day, PlannerContents, PlannerDailyContents } from '../../models';
import { PlannerTransportService, ValidateSharingCodeResponse } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppPlannerTransportService extends AppBaseTransportService implements PlannerTransportService {
  async getPlanners(): Promise<Planner[]> {
    const response = await this.performReadRequest(
      'getPlanners',
      Planners,
      Planners.methods.getPlanners,
      {},
      { supportsDemoMode: true }
    );
    return response.planners;
  }

  async getPlanner(plannerId: string): Promise<Planner> {
    const response = await this.performReadRequest('getPlanner', Planners, Planners.methods.getPlanner, { plannerId });
    return response;
  }

  async getDemoPlanners(): Promise<Planner[]> {
    if (this._settingsStorage.isDemoMode) {
      // GetPlanners will already return the demo planners.
      return [];
    }

    const response = await this.performReadRequest(
      'getDemoPlanners',
      Planners,
      Planners.methods.getPlanners,
      {},
      { isDemoObject: true }
    );
    return response.planners;
  }

  async createPlanner(relationshipKind: PlannerRelationshipKind, title: string): Promise<Planner> {
    return await this.performWriteRequest('createPlanner', Planners, Planners.methods.createPlanner, {
      relationshipKind,
      title
    });
  }

  async renamePlanner(plannerId: string, title: string): Promise<Planner> {
    return await this.performWriteRequest(
      'renamePlanner',
      Planners,
      Planners.methods.renamePlanner,
      { plannerId, title },
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async attachSchoolToPlanner(
    schoolId: string,
    plannerId: string
  ): Promise<{ schoolIds: string[]; ignoredSchoolIds: string[] }> {
    const response = await this.performWriteRequest(
      'attachSchoolToPlanner',
      Planners,
      Planners.methods.attachSchoolToPlanner,
      {
        schoolId,
        plannerId
      }
    );

    return { schoolIds: response.schoolIds, ignoredSchoolIds: response.ignoredSchoolIds };
  }

  async ignoreSchoolInPlanner(
    plannerId: string,
    schoolId: string
  ): Promise<{
    schoolIds: string[];
    ignoredSchoolIds: string[];
  }> {
    const response = await this.performWriteRequest('ignoreSchoolInPlanner', Planners, Planners.methods.ignoreSchool, {
      schoolId,
      plannerId
    });

    return { ...response };
  }

  async getPlannerContents(plannerId: string, timezone: string): Promise<PlannerContents> {
    const response = await this.performReadRequest(
      'getPlannerContents',
      Planners,
      Planners.methods.getPlannerContents,
      { plannerId, timezone },
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );

    return {
      inboxList: response.inboxList!,
      todayList: response.todayList!,
      upcomingList: response.upcomingList!,
      doneList: response.doneList!
    };
  }

  async getPlannerDailyContents(
    plannerId: string,
    startDay: Day,
    endDay: Day,
    includeFreePeriods: boolean,
    timezone: string
  ): Promise<PlannerDailyContents> {
    const response = await this.performReadRequest(
      'getPlannerDailyContents',
      Planners,
      Planners.methods.getPlannerDailyContents,
      { plannerId, startingDay: startDay, endingDay: endDay, timezone, includeFreePeriods }
    );

    return {
      days: response.days,
      scheduleCycleIds: response.scheduleCycleIds,
      moreRecentDraftScheduleCycleIds: response.moreRecentDraftScheduleCycleIds
    };
  }

  async getInboxWorkCountForPlanner(plannerId: string): Promise<number> {
    const response = await this.performReadRequest('getInboxWorkCount', Planners, Planners.methods.getInboxWorkCount, {
      plannerId,
      timezone: this._localization.currentTimezone
    });

    return response.inboxWorkCount;
  }

  async getCourseSectionsInPlanner(plannerId: string): Promise<PlannerCourseSection[]> {
    const response = await this.performReadRequest(
      'getCourseSectionsInPlanner',
      Planners,
      Planners.methods.getCourseSections,
      { plannerId },
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );
    return response.courseSections;
  }

  async getDetailedCourseSectionsInPlanner(plannerId: string): Promise<CourseSectionDetails[]> {
    const response = await this.performReadRequest(
      'getDetailedCourseSectionsInPlanner',
      Planners,
      Planners.methods.getDetailedCourseSections,
      { plannerId, timezone: this._localization.currentTimezone },
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );

    return response.courseSections;
  }

  async getDemoDetailedCourseSectionsInPlanner(plannerId: string): Promise<CourseSectionDetails[]> {
    if (this._settingsStorage.isDemoMode) {
      // GetSchoolCourseSections will already return the demo course sections.
      return [];
    }

    const response = await this.performReadRequest(
      'getDemoDetailedCourseSectionsInPlanner',
      Planners,
      Planners.methods.getDetailedCourseSections,
      { plannerId, timezone: this._localization.currentTimezone },
      { isDemoObject: true }
    );

    return response.courseSections;
  }

  async createCourseSectionInPlanner(
    plannerId: string,
    title: string,
    section: string,
    backgroundColor: string
  ): Promise<PlannerCourseSection> {
    return await this.performWriteRequest(
      'createCourseSectionInPlanner',
      Planners,
      Planners.methods.createCourseSection,
      { title, section, color: backgroundColor, plannerId },
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async updateCourseSectionInPlanner(
    courseId: string,
    title: string,
    section: string,
    backgroundColor: string,
    isVisible: boolean,
    syncToken: bigint
  ): Promise<PlannerCourseSection> {
    return await this.performWriteRequest(
      'updateCourseSectionInPlanner',
      Planners,
      Planners.methods.updateCourseSection,
      { courseSectionId: courseId, title, section, color: backgroundColor, syncToken, isVisible },
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async deleteCourseSectionInPlanner(courseId: string, syncToken: bigint, shouldAlsoCancelWork: boolean) {
    await this.performWriteRequest(
      'deleteCourseSectionInPlanner',
      Planners,
      Planners.methods.deleteCourseSection,
      { courseSectionId: courseId, shouldAlsoCancelWork, syncToken },
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async changeCourseSectionVisibilityInPlanner(
    courseId: string,
    syncToken: bigint,
    isVisible: boolean
  ): Promise<PlannerCourseSection> {
    return await this.performWriteRequest(
      'changeCourseSectionVisibility',
      Planners,
      Planners.methods.changeCourseSectionVisibility,
      { courseSectionId: courseId, syncToken, isVisible },
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async ensureCustomWorksCompleted(
    plannerId: string,
    effect: CustomActionEffect,
    externalSourceName: string,
    externalId: string
  ): Promise<number> {
    const response = await this.performWriteRequest(
      'ensureCustomWorksCompleted',
      Planners,
      Planners.methods.ensureCustomWorkCompleted,
      { plannerId, customActionEffect: effect, externalSourceName, externalId },
      { isDemoObject: this.isDemoId(plannerId) }
    );
    return response.affectedWorkCount;
  }

  async getPlannerRelationshipDetails(plannerId: string): Promise<PlannerRelationshipDetails[]> {
    const response = await this.performReadRequest(
      'getPlannerRelationshipDetails',
      Planners,
      Planners.methods.getPlannerRelationshipDetails,
      { plannerId },
      { isDemoObject: this.isDemoId(plannerId) }
    );
    return response.details;
  }

  async useSharingInvitationCode(invitationCode: string): Promise<string> {
    const response = await this.performWriteRequest(
      'useSharingInvitationCode',
      Planners,
      Planners.methods.useSharingInvitationCode,
      { invitationCode }
    );

    return response.plannerId;
  }

  async getSharingInvitationCode(
    plannerId: string,
    accessKind: AccessKind,
    plannerRelationshipKind: PlannerRelationshipKind
  ): Promise<string> {
    const response = await this.performReadRequest(
      'getSharingInvitationCode',
      Planners,
      Planners.methods.getSharingInvitationCode,
      { plannerId, recipientAccessKind: accessKind, recipientRelationshipKind: plannerRelationshipKind },
      { isDemoObject: this.isDemoId(plannerId) }
    );
    return response.invitationCode;
  }

  async getTeacherParticipationCode(): Promise<string> {
    const response = await this.performReadRequest(
      'getTeacherParticipationCode',
      Planners,
      Planners.methods.getTeacherParticipationCode,
      {}
    );
    return response.participationCode;
  }

  async validateSharingCode(code: string): Promise<ValidateSharingCodeResponse> {
    const response = await this.performReadRequest(
      'validateSharingCode',
      Planners,
      Planners.methods.validateSharingCode,
      { sharingCode: code }
    );

    return {
      isValid: response.isValid,
      kind: response.codeKind,
      senderDisplayName: response.senderDisplayName.length == 0 ? undefined : response.senderDisplayName,
      relationshipKind: response.relationshipKind
    };
  }

  async useParticipationCodeForPlanner(code: string, plannerId: string) {
    await this.performReadRequest(
      'useParticipationCodeForPlanner',
      Planners,
      Planners.methods.useParticipationCode,
      { participationCode: code, plannerId },
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async requestPlannerAccess(plannerIds: string[], accessKind: AccessKind, relationshipKind: PlannerRelationshipKind) {
    await this.performWriteRequest('requestPlannerAccess', Planners, Planners.methods.requestPlannerAccess, {
      plannerIds,
      accessKind,
      relationshipKind
    });
  }

  async acknowledgePlannerAccessRequest(
    plannerId: string,
    acceptRequest: boolean,
    requesterUserId: string
  ): Promise<Planner> {
    return await this.performWriteRequest(
      'acknowledgePlannerAccessRequest',
      Planners,
      Planners.methods.acknowledgePlannerAccessRequest,
      { plannerId, acceptRequest, requesterUserId },
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async ignoreScheduleCycleInPlanner(plannerId: string, scheduleCycleId: string): Promise<string[]> {
    const response = await this.performWriteRequest(
      'ignoreScheduleCycleInPlanner',
      Planners,
      Planners.methods.ignoreScheduleCycle,
      { plannerId, scheduleCycleId }
    );

    return response.ignoredScheduleCycleIds;
  }

  async restoreScheduleCycleInPlanner(plannerId: string, scheduleCycleId: string): Promise<string[]> {
    const response = await this.performWriteRequest(
      'restoreScheduleCycleInPlanner',
      Planners,
      Planners.methods.restoreScheduleCycle,
      { plannerId, scheduleCycleId }
    );

    return response.ignoredScheduleCycleIds;
  }
}
