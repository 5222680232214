import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { computed, makeObservable } from 'mobx';

export interface WorkDetailsGradeViewModel {
  readonly isGraded: boolean;
  readonly isMarked: boolean;
  readonly grade: number;
  readonly maxPoints: number | undefined;
  readonly percentage: number | undefined;
}

export class AppWorkDetailsGradeViewModel implements WorkDetailsGradeViewModel {
  constructor(private _work: () => Work) {
    makeObservable(this);
  }

  @computed
  private get work() {
    return this._work();
  }

  @computed
  get isGraded(): boolean {
    return this.work.grade >= 0;
  }

  @computed
  get isMarked(): boolean {
    return this.maxPoints != null;
  }

  @computed
  get grade(): number {
    return this.work.grade;
  }

  @computed
  get maxPoints(): number | undefined {
    return this.work.maxGrade !== 0 ? this.work.maxGrade : undefined;
  }

  @computed
  get percentage(): number | undefined {
    if (this.maxPoints == null || !this.isGraded) {
      return undefined;
    }

    return (this.grade / this.maxPoints) * 100;
  }
}
