import { connectionStateForStudent, StudentConnectionState } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, LocalizationService, RouteService } from '@/services';
import { Student } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_pb';
import { computed, makeObservable } from 'mobx';
import { getDailyActivityBehaviorLevel, StudentBehaviorLevel } from '../utils';

export interface ActiveTime {
  date: Date;
  activeMinutes: number;
}

export interface StudentsPlannerSummaryTableRowViewModel {
  readonly id: string;
  readonly courseSectionId: string;
  readonly plannerId: string | undefined;
  readonly name: string;
  readonly lastConnection?: Date;
  readonly lastConnectionIndicatorLevel: StudentBehaviorLevel;
  readonly pictureUrl?: string;
  readonly connectionState: StudentConnectionState;
  readonly plannerExternalSourceNames: string[];
  readonly plannerUrl: string | undefined;
  readonly isPlannerAccessAuthorized: boolean;

  readonly lateItemsCount: number;
  readonly inboxItemsCount: number;
  readonly todayItemsCount: number;
  readonly upcomingItemsCount: number;
  readonly doneItemsCount: number;
  readonly emotionalStateRating: number | undefined;
}

export class AppStudentsPlannerSummaryTableRowViewModel implements StudentsPlannerSummaryTableRowViewModel {
  constructor(
    private readonly _student: Student,
    public readonly courseSectionId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _route: RouteService = ServiceContainer.services.route,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
  }

  @computed
  get id(): string {
    return this._student.accountId;
  }

  @computed
  get name(): string {
    return this._student.fullName;
  }

  @computed
  get plannerId(): string | undefined {
    return this._student.plannerId;
  }

  @computed
  get lastConnection(): Date | undefined {
    return this._student.stats?.lastSeenTime?.toDate();
  }

  @computed
  get lastConnectionIndicatorLevel(): StudentBehaviorLevel {
    return getDailyActivityBehaviorLevel(this.lastConnection, this._dateService);
  }

  @computed
  get connectionState(): StudentConnectionState {
    return connectionStateForStudent(this._student);
  }

  @computed
  get plannerExternalSourceNames(): string[] {
    return this._student.plannerExternalSourceNames;
  }

  @computed
  get isPlannerAccessAuthorized(): boolean {
    return this._student.isPlannerAccessAuthorized;
  }

  @computed
  get plannerUrl(): string | undefined {
    if (this.plannerId == null || this.plannerId.length <= 0 || !this.isPlannerAccessAuthorized) {
      return undefined;
    }

    return this._route.resolvePlannerLocation(this.plannerId);
  }

  @computed
  get pictureUrl(): string | undefined {
    return this._student.pictureUrl.length > 0 ? this._student.pictureUrl : undefined;
  }

  @computed
  get lateItemsCount(): number {
    return this._student.stats?.lateWorkCount ?? 0;
  }

  @computed
  get inboxItemsCount(): number {
    return this._student.stats?.inboxWorkCount ?? 0;
  }

  @computed
  get todayItemsCount(): number {
    return this._student.stats?.todayWorkCount ?? 0;
  }

  @computed
  get upcomingItemsCount(): number {
    return this._student.stats?.upcomingWorkCount ?? 0;
  }

  @computed
  get doneItemsCount(): number {
    return this._student.stats?.doneWorkCount ?? 0;
  }

  @computed
  get emotionalStateRating(): number | undefined {
    return this._student.stats?.emotionalStateRating;
  }
}
