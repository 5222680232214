import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { AccessKind as SchoolAccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { computed, makeObservable } from 'mobx';
import {
  UserDashboard,
  UserDashboardInfo,
  UserDashboardPlanner,
  UserDashboardSchool,
  plannerHasAccessKindsForUser,
  schoolHasAccessKindsForUser
} from '../../../models';
import { ServiceContainer } from '../../../providers';
import { ScheduleCycleDataStore, UserDataStore } from '../../../stores';
import {
  AppScheduleCycleDetailsEditViewModel,
  ScheduleCycleDetailsEditViewModel
} from './ScheduleCycleDetailsEditViewModel';

export interface ScheduleCycleDetailsEditPageViewModel {
  readonly scheduleCycle: ScheduleCycle;
  readonly editViewModel: ScheduleCycleDetailsEditViewModel;
  readonly isReadOnly: boolean;
}

export class AppScheduleCycleDetailsEditPageViewModel implements ScheduleCycleDetailsEditPageViewModel {
  constructor(
    private readonly _dashboard: UserDashboardInfo,
    private readonly _scheduleCycleId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  private get currentDashboard(): UserDashboard {
    switch (this._dashboard.kind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboard.id)!;
        return { kind: 'planner', planner } as UserDashboardPlanner;
      }

      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboard.id)!;
        return { kind: 'school', school } as UserDashboardSchool;
      }
    }
  }

  @computed
  get isReadOnly(): boolean {
    const { userId } = this._userStore.user;

    switch (this.currentDashboard.kind) {
      case 'planner':
        return !plannerHasAccessKindsForUser(userId, this.currentDashboard.planner, AccessKind.FULL_ACCESS);

      case 'school':
        return !schoolHasAccessKindsForUser(userId, this.currentDashboard.school, SchoolAccessKind.FULL_ACCESS);
    }
  }

  @computed
  get editViewModel(): ScheduleCycleDetailsEditViewModel {
    return new AppScheduleCycleDetailsEditViewModel(this._scheduleCycleId, this._dashboard);
  }
}
