import { Day, DayOfWeek, UserDashboardInfo, compareDays, dateToPBDate, dayToDate } from '@/models';
import { ServiceContainer } from '@/providers';
import { SpecialDayOccurrenceWhen } from '@/stores';
import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { Calendar } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_pb';
import { DayAnnotation } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/day_annotation_pb';
import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { SpecialDayOccurrence } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_occurrence_pb';
import { SpecialDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_pb';
import {
  addMonths,
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths
} from 'date-fns';
import { chain, compact, isEqual, times } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { ScheduleCycleKind, getScheduleCycleKind, titleForCycleDay } from '../ScheduleCycleUtils';
import { SpecialDayOccurrenceInfo } from './SpecialDayEditViewModel';

export type ScheduleCycleSpecialDaysViewModelSelection =
  | { case: 'day'; value: Day }
  | { case: 'cycleDay'; value: number }
  | { case: 'dayOfWeek'; value: DayOfWeek };

export interface ScheduleCycleSpecialDayInfo {
  readonly scheduleCycleId: string;
  readonly specialDay: SpecialDay;
  readonly schedules: PeriodSchedule[];
  readonly occurrences: SpecialDayOccurrenceInfo[];
}

export interface ScheduleCycleSpecialDaysViewModel {
  readonly scheduleCycleId: string;
  readonly isReadOnly: boolean;
  readonly currentMonth: (CalendarDay | undefined)[][];
  readonly currentCalendarDate: Day;
  readonly startDay: Day | undefined;
  readonly endDay: Day | undefined;
  readonly canGoToPreviousMonth: boolean;
  readonly canGoToNextMonth: boolean;
  selection: ScheduleCycleSpecialDaysViewModelSelection | undefined;
  readonly allSpecialDays: ScheduleCycleSpecialDayInfo[];
  readonly selectionSpecialDays: ScheduleCycleSpecialDayInfo[];
  readonly selectionOtherSpecialDays: ScheduleCycleSpecialDayInfo[];
  readonly cycleDayCount: number;
  readonly cycleDayNames: string[];
  readonly scheduleCycleKind: ScheduleCycleKind;

  toggleSelectionOfSpecialDay(id: string): void;
  deleteSpecialDay(id: string): void;
  goToPreviousMonth(): void;
  goToNextMonth(): void;
  getDayAnnotationsForCycleDayOrDayOfWeek(kind: 'cycleDay' | 'dayOfWeek', value: number): DayAnnotation[];
  addSpecialDayOccurrence(specialDayId: string, when: ScheduleCycleSpecialDaysViewModelSelection): void;
}

export class AppScheduleCycleSpecialDaysViewModel implements ScheduleCycleSpecialDaysViewModel {
  @observable private _calendar: Calendar | undefined;
  @observable private _currentCalendarDate: Day;
  @observable private _selection: ScheduleCycleSpecialDaysViewModelSelection | undefined;

  constructor(
    private readonly _scheduleCycleFn: () => ScheduleCycle,
    private readonly _dashboard: UserDashboardInfo,
    private readonly _isReadOnly: () => boolean,
    private readonly _addOrRemoveOccurrence: (specialDayId: string, when: SpecialDayOccurrenceWhen) => void,
    private readonly _addOccurrence: (specialDayId: string, when: SpecialDayOccurrenceWhen) => void,
    private readonly _removeSpecialDay: (specialDayId: string) => void,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _dateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);

    const today = startOfDay(this._dateService.now);
    const startDate = dayToDate(this.scheduleCycle.startDay!, true);
    const endDate = dayToDate(this.scheduleCycle.endDay!, true);

    // If current date is included in the date range of the schedule, we start with the current month.
    // Otherwise, we default to the start month of the schedule.
    this._currentCalendarDate = dateToPBDate(isBefore(today, startDate) || isAfter(today, endDate) ? startDate : today);

    reaction(
      () => [this.scheduleCycle, this._currentCalendarDate],
      () => void this.getCalendar()
    );

    void this.getCalendar();
  }

  @computed
  private get scheduleCycle() {
    return this._scheduleCycleFn();
  }

  @computed
  get scheduleCycleId(): string {
    return this.scheduleCycle.id;
  }

  @computed
  get isReadOnly(): boolean {
    return this._isReadOnly();
  }

  @computed
  get scheduleCycleKind(): ScheduleCycleKind {
    return getScheduleCycleKind(this.scheduleCycle);
  }

  @computed
  get currentMonth(): (CalendarDay | undefined)[][] {
    if (this._calendar == null) {
      // Displaying 5 empty weeks while loading the calendar. Prevents the UI jumping too much. Using 5 as most of the
      // months have 5 weeks and the other possibilities are 6 and 4 (although 4 is rare).
      return times(5).map(() => times(7).map(() => undefined));
    }

    const date = dayToDate(this.currentCalendarDate);
    const start = startOfWeek(startOfMonth(date));
    const end = endOfWeek(endOfMonth(date));
    const numberOfDays = differenceInCalendarDays(end, start) + 1;
    const startIndex = differenceInCalendarDays(start, dayToDate(this._calendar.calendarDays[0].day!));

    return times(numberOfDays / 7).map((weekIndex) => {
      return times(7).map((dayIndex) => this._calendar!.calendarDays[startIndex + weekIndex * 7 + dayIndex]);
    });
  }

  @computed
  get currentCalendarDate(): Day {
    return this._currentCalendarDate;
  }

  @computed
  get startDay(): Day | undefined {
    return this.scheduleCycle.startDay;
  }

  @computed
  get endDay(): Day | undefined {
    return this.scheduleCycle.endDay;
  }

  @computed
  get canGoToPreviousMonth(): boolean {
    const minimumDay = this.startDay;
    const currentDate = dayToDate(this.currentCalendarDate);
    return minimumDay == null || differenceInCalendarDays(startOfMonth(currentDate), dayToDate(minimumDay)) > 0;
  }

  @computed
  get canGoToNextMonth(): boolean {
    const maximumDay = this.endDay;
    const currentDate = dayToDate(this.currentCalendarDate);
    return maximumDay == null || differenceInCalendarDays(endOfMonth(currentDate), dayToDate(maximumDay)) < 0;
  }

  @computed
  get selection(): ScheduleCycleSpecialDaysViewModelSelection | undefined {
    return this._selection;
  }

  set selection(value: ScheduleCycleSpecialDaysViewModelSelection | undefined) {
    if (value?.case === 'day') {
      // Prevent selection of a day outside the start and end dates of a schedule cycle, if some are set.
      if (this.startDay != null && differenceInCalendarDays(dayToDate(value.value), dayToDate(this.startDay)) < 0) {
        return;
      }

      if (this.endDay != null && differenceInCalendarDays(dayToDate(value.value), dayToDate(this.endDay)) > 0) {
        return;
      }
    }

    this._selection = value;
  }

  @computed
  get allSpecialDays(): ScheduleCycleSpecialDayInfo[] {
    return this.createSpecialDaysInfosAndSort('all');
  }

  @computed
  get selectionSpecialDays(): ScheduleCycleSpecialDayInfo[] {
    if (this._selection == null) {
      return [];
    }

    return this.createSpecialDaysInfosAndSort('selection');
  }

  @computed
  get selectionOtherSpecialDays(): ScheduleCycleSpecialDayInfo[] {
    if (this._selection == null) {
      return [];
    }

    return this.createSpecialDaysInfosAndSort('others');
  }

  @computed
  get cycleDayCount(): number {
    return this.scheduleCycle.cycleDayCount;
  }

  @computed
  get cycleDayNames(): string[] {
    return this.scheduleCycle.cycleDayNames;
  }

  toggleSelectionOfSpecialDay(id: string) {
    if (this._selection == null) {
      return;
    }

    this._addOrRemoveOccurrence(id, this._selection);
  }

  @action
  goToPreviousMonth() {
    if (this.canGoToPreviousMonth) {
      const previousMonth = subMonths(dayToDate(this.currentCalendarDate), 1);
      this._currentCalendarDate = dateToPBDate(previousMonth);
    }
  }

  @action
  goToNextMonth() {
    if (this.canGoToNextMonth) {
      const nextMonth = addMonths(dayToDate(this.currentCalendarDate), 1);
      this._currentCalendarDate = dateToPBDate(nextMonth);
    }
  }

  getDayAnnotationsForCycleDayOrDayOfWeek(kind: 'cycleDay' | 'dayOfWeek', value: number): DayAnnotation[] {
    const specialDays = this.scheduleCycle.specialDays.filter((sp) =>
      this.scheduleCycle.specialDayOccurrences.some(
        (spo) => !spo.shouldDelete && spo.specialDayId === sp.id && spo.when.case === kind && spo.when.value === value
      )
    );
    return this.dayAnnotationsFromSpecialDays(specialDays);
  }

  addSpecialDayOccurrence(specialDayId: string, when: ScheduleCycleSpecialDaysViewModelSelection) {
    this._addOccurrence(specialDayId, when);
  }

  deleteSpecialDay(id: string) {
    this._removeSpecialDay(id);
  }

  private async getCalendar(): Promise<void> {
    if (this._calendar?.calendarDays.find((d) => isEqual(this.currentCalendarDate, d.day)) == null) {
      // Invalidating the calendar if the currentDate is not in our cache.
      runInAction(() => (this._calendar = undefined));
    }

    const currentDate = dayToDate(this.currentCalendarDate);
    // Fetching current month, but also the previous and next ones. Prevents having a flicker when changing months
    // has we already have the data.
    const firstDay = dateToPBDate(startOfWeek(subMonths(startOfMonth(currentDate), 1)));
    const lastDay = dateToPBDate(endOfWeek(addMonths(lastDayOfMonth(currentDate), 1)));
    const scheduleCycleStore = this._userStore.getScheduleCycleStore(this.scheduleCycle.id, this._dashboard);
    const calendar = await scheduleCycleStore.getVolatileCalendar(firstDay, lastDay);
    runInAction(() => (this._calendar = calendar));
  }

  private getSpecialDayOccurrenceMatchesSelection(
    occurrence: SpecialDayOccurrence,
    selection: ScheduleCycleSpecialDaysViewModelSelection
  ): boolean {
    switch (selection.case) {
      case 'cycleDay':
        return occurrence.when.case === 'cycleDay' && occurrence.when.value === selection.value;
      case 'dayOfWeek':
        return occurrence.when.case === 'dayOfWeek' && occurrence.when.value === selection.value;
      case 'day':
        return occurrence.when.case === 'day' && compareDays(occurrence.when.value, selection.value) === 0;
    }
  }

  private createSpecialDaysInfosAndSort(filter: 'all' | 'selection' | 'others'): ScheduleCycleSpecialDayInfo[] {
    const periodSchedulesById: Record<string, PeriodSchedule> = Object.fromEntries(
      this.scheduleCycle.periodSchedules.filter((ps) => !ps.shouldDelete).map((s) => [s.id, s])
    );

    const mapSpecialDayToInfo = (specialDay: SpecialDay): ScheduleCycleSpecialDayInfo => {
      const schedules = compact(specialDay.periodScheduleIds.map((id) => periodSchedulesById[id]));
      const occurrences: SpecialDayOccurrenceInfo[] = chain(this.scheduleCycle.specialDayOccurrences)
        .filter((o) => !o.shouldDelete && o.specialDayId === specialDay.id && o.when != null)
        .map((o) => ({ occurrence: o.when as SpecialDayOccurrenceWhen, title: this.titleForOccurrence(o) }))
        .value();
      return { specialDay, schedules, scheduleCycleId: this.scheduleCycle.id, occurrences };
    };

    return chain(this.scheduleCycle.specialDays)
      .filter((sp) => {
        if (sp.shouldDelete) {
          return false;
        }

        if (filter === 'all') {
          return true;
        }

        const matchesSelection = this.scheduleCycle.specialDayOccurrences.some(
          (o) =>
            !o.shouldDelete &&
            o.specialDayId === sp.id &&
            this.getSpecialDayOccurrenceMatchesSelection(o, this._selection!)
        );

        return filter === 'selection' ? matchesSelection : !matchesSelection;
      })
      .sortBy([(sp) => sp.title])
      .map(mapSpecialDayToInfo)
      .compact()
      .value();
  }

  private dayAnnotationsFromSpecialDays(specialDays: SpecialDay[]): DayAnnotation[] {
    return specialDays.map(
      (sp) =>
        new DayAnnotation({
          title: sp.title,
          color: sp.color,
          symbol: sp.symbol
        })
    );
  }
  private titleForOccurrence(occurrence: SpecialDayOccurrence): string {
    switch (occurrence.when.case) {
      case 'dayOfWeek': {
        const dowTitle = LocalizedStrings.dateTime.dayOfWeekTitle[occurrence.when.value as DayOfWeek]();
        return LocalizedStrings.scheduleCycle.edit.specialDays.dayOfWeekOccurrenceLabel(dowTitle);
      }

      case 'cycleDay': {
        const cycleDayTitle = titleForCycleDay(
          occurrence.when.value,
          this.scheduleCycleKind,
          'long',
          true,
          this.cycleDayNames
        );
        return LocalizedStrings.scheduleCycle.edit.specialDays.cycleDayOccurrenceLabel(cycleDayTitle);
      }

      case 'day':
        return format(dayToDate(occurrence.when.value), 'PPP');

      default:
        return '';
    }
  }
}
