import { computed, makeObservable, observable } from 'mobx';
import { NetworkService } from '../contracts';

export class AppNetworkService implements NetworkService {
  @observable private _isOnline = true;

  constructor() {
    makeObservable(this);
    window.addEventListener('online', () => (this._isOnline = true));
    window.addEventListener('offline', () => (this._isOnline = false));
  }

  @computed
  get isOnline(): boolean {
    return this._isOnline;
  }
}
