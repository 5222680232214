import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';

export type SchoolCourseSectionStudentPickerDialogViewModel = StaticDialogViewModel;

export class AppSchoolCourseSectionStudentPickerDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements SchoolCourseSectionStudentPickerDialogViewModel
{
  constructor(onDismiss: () => void) {
    super(async () => {
      onDismiss();
      await Promise.resolve();
    });
  }
}
