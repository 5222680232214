import { useServices } from '@/hooks';
import { SearchRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EmptyListIndicator } from '../../utils';
import { TableEmptyParams } from './TableUtils';

export interface TableNoRowsOverlayProps {
  searchText: string;
  params: TableEmptyParams;
}

export const TableNoRowsOverlay = observer(({ searchText, params }: TableNoRowsOverlayProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.utils;
  const hasSearchText = searchText.length > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <EmptyListIndicator
        renderIcon={(className) =>
          hasSearchText ? <SearchRounded className={className} /> : params.renderIcon(className)
        }
        title={hasSearchText ? strings.tableNoResultsLabel(searchText) : params.title}
        subtitle={hasSearchText ? undefined : params.subtitle}
        button={params.action}
      />
    </Box>
  );
});
