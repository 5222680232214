import { Environment } from '@/services/contracts';
import { Capacitor } from '@capacitor/core';
import { BrowserOptions, captureException, init as initSentry, replayIntegration } from '@sentry/react';
import 'core-js/actual';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { ServicesProvider } from './components';
import './i18n';
import './index.css';
import { unregister } from './serviceWorker';

const isAndroid = Capacitor.getPlatform() === 'android';

const sentryOptions: BrowserOptions = {
  dsn: isAndroid
    ? 'https://3f215d4c6e39bdb92dd07739d6bbc9d8@o187895.ingest.sentry.io/4505766074777600'
    : 'https://92c1e2955e91b6c9c06ab790b55432ef@o187895.ingest.sentry.io/4505757147201536',
  integrations: [replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

fetch(`/environment.json`)
  .then((response) => {
    response
      .json()
      .then((environment: Environment) => {
        const semverRegex = /^v\d+.\d+.\d+/;
        const semver = semverRegex.exec(environment.version)?.at(0)?.replace('v', '').trim() ?? environment.version;

        sentryOptions.release = `${isAndroid ? 'today-android' : 'today-web'}@${semver}+${environment.buildNumber}`;
        sentryOptions.environment = environment.environment;

        initSentry(sentryOptions);
      })
      .catch((error) => {
        initSentry(sentryOptions);
        captureException(error);
      });
  })
  .catch((error) => {
    initSentry(sentryOptions);
    captureException(error);
  });

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ServicesProvider>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </ServicesProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
