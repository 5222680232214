import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { alpha, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { SettingsCard } from '../../../shared';
import { SchoolSettingsArchiveAction } from './SchoolSettingsArchiveAction';

export interface SchoolSettingsDangerActionsProps {
  sx?: SxProps;
  className?: string;
  school: SchoolInformation;
}

export const SchoolSettingsDangerActions = observer(
  ({ sx = [], className, school }: SchoolSettingsDangerActionsProps) => {
    const theme = useTheme();

    return (
      <SettingsCard
        sx={sx}
        className={className}
        title={LocalizedStrings.settings.school.details.dangerActions.title()}
        canBeCollapsed
        collapsedByDefault
        borderColor={alpha(theme.palette.error.main, 0.5)}
        titleColor={theme.palette.error.main}
      >
        <Stack
          sx={{
            p: 2
          }}
        >
          <SchoolSettingsArchiveAction school={school} />
        </Stack>
      </SettingsCard>
    );
  }
);
