import { RouteTemplates } from '@/RouteTemplates';
import { InitializingScreen, ServerErrorScreen, UserPropertiesDialog } from '@/components';
import { useServices } from '@/hooks';
import { isError } from '@/utils';
import { App as CapacitorApp } from '@capacitor/app';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

export const InitialStateLayout = observer(() => {
  const { authentication, userStore, user } = useServices();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    void CapacitorApp.addListener('backButton', (event) => {
      if (event.canGoBack) {
        navigate(-1);
      } else {
        void CapacitorApp.exitApp();
      }
    });

    return () => {
      void CapacitorApp.removeAllListeners();
    };
  }, [location]);

  // If there is an error with the authentication, we display the ServerErrorScreen.
  if (authentication.error != null) {
    return <ServerErrorScreen error={authentication.error} />;
  }

  if (!authentication.isInitialized) {
    return <InitializingScreen />;
  }

  if (user.currentUser == null) {
    return location.pathname === RouteTemplates.welcome ? <Outlet /> : <Navigate to={RouteTemplates.welcome} replace />;
  } else if (location.pathname === RouteTemplates.welcome) {
    return <Navigate to={RouteTemplates.home} replace />;
  }

  // If auth is not initialized, or we are still fetching basic info for user, we display the initializing screen on
  // the current location.
  if (
    !userStore.plannersLoadable.hasData ||
    !userStore.participatingSchools.hasData ||
    !userStore.subscription.hasData ||
    !userStore.getPreference('seen-curriculum-course-ids').hasData
  ) {
    return <InitializingScreen />;
  }

  if (isError(userStore.plannersLoadable.state)) {
    return <ServerErrorScreen error={userStore.plannersLoadable.state} />;
  }

  if (isError(userStore.subscription.state)) {
    return <ServerErrorScreen error={userStore.subscription.state} />;
  }

  const hasSchools = userStore.schools.length > 0;
  // Filtering out demo planners and planners where user as a teacher relationship. For the latter, a
  // teacher who only has access to its students planners should be shown the "create planner screen".
  const hasPlanners = userStore.nonDemoNorTeacherPlanners.length > 0;

  if (location.pathname === RouteTemplates.home && !hasPlanners) {
    return <Navigate to={hasSchools ? RouteTemplates.noPlanner : RouteTemplates.onboarding} replace />;
  }

  // If we are on the "onboarding" route...
  if (location.pathname === RouteTemplates.onboarding) {
    if (!hasPlanners) {
      // ...and the user doesn't have any dashboard, we display the route element.

      if (hasSchools) {
        return <Navigate to={RouteTemplates.noPlanner} replace />;
      }

      return <Outlet />;
    } else {
      // Otherwise, we redirect to the "home" as there is at least one dashboard to display.
      return <Navigate to={RouteTemplates.home} replace />;
    }
  }

  if (location.pathname === RouteTemplates.noPlanner) {
    if (hasPlanners) {
      return <Navigate to={RouteTemplates.home} replace />;
    } else if (!hasSchools) {
      return <Navigate to={RouteTemplates.onboarding} replace />;
    } else {
      return <Outlet />;
    }
  }

  const shouldRequireName = userStore.user.fullName.length === 0;
  // Only asking name if user has a dashboard to display.
  return (
    <>
      <Outlet />
      {shouldRequireName && <UserPropertiesDialog isOpen={true} />}
    </>
  );
});
