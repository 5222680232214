import { PlannerContents } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, LocalizationService, RouteService } from '@/services';
import { Loadable, PlannerContentsLoadable, PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import { PlannerListGroup } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_list_group_pb';
import { PlannerList } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_list_pb';
import { computed, makeObservable } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../../shared';
import { CalendarEventPlannerListItemInfo } from './CalendarEventPlannerListItemInfo';
import { AppPlannerListGroupViewModel, PlannerListGroupViewModel } from './PlannerListGroupViewModel';
import { PlannerListItemInfo } from './PlannerListItemInfo';
import { WorkPlannerListItemInfo } from './WorkPlannerListItemInfo';

export interface PlannerListViewModel extends UpdatableViewModel {
  readonly groups: PlannerListGroupViewModel[];
  readonly canPublishWork: boolean;
}

export abstract class AppPlannerListViewModel extends BaseUpdatableViewModel implements PlannerListViewModel {
  protected abstract get showGroupTitles(): boolean;
  protected abstract get plannerList(): PlannerList;

  protected constructor(
    private readonly _plannerId: string,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    protected readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _route: RouteService = ServiceContainer.services.route,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    super();
    makeObservable(this);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [
      this._plannerStore.getCourseSectionsInPlanner(this._plannerId),
      this.plannerContentLoadable,
      this._workStore.workIcons
    ];
  }

  @computed
  private get plannerContentLoadable(): PlannerContentsLoadable {
    return this._plannerStore.getPlannerContentsInPlanner(this._plannerId);
  }

  @computed
  protected get plannerContents(): PlannerContents {
    return this.plannerContentLoadable.data;
  }

  @computed
  get groups(): PlannerListGroupViewModel[] {
    return this.plannerList.groups.map(
      (group) =>
        new AppPlannerListGroupViewModel(
          group,
          this.getItemsInfoInGroup(group),
          this.showGroupTitles,
          this._localization
        )
    );
  }

  @computed
  get canPublishWork(): boolean {
    return this._plannerStore.getCanPublishWorkInPlanner(this._plannerId);
  }

  private getItemsInfoInGroup(group: PlannerListGroup): PlannerListItemInfo[] {
    const items = group.items.map<PlannerListItemInfo | undefined>((item) => {
      switch (item.item.case) {
        case 'work':
          return new WorkPlannerListItemInfo(
            item.item.value,
            item.plannedWork,
            group.relativeDate,
            this._userStore,
            this._workStore,
            this._plannerStore,
            this._localization,
            this._route
          );
        case 'calendarEvent':
          return new CalendarEventPlannerListItemInfo(
            item.item.value,
            group.relativeDate,
            this._localization,
            this._dateService
          );

        default:
          return undefined;
      }
    });

    // Removes undefined values from list.
    return items.filter((item): item is PlannerListItemInfo => !!item);
  }
}
