import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { Student } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_pb';
import { Teacher } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_pb';
import { last } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { Loadable, SchoolCourseSectionLoadable, SchoolDataStore, UserDataStore } from '../../stores';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminClassDetailsViewModel extends UpdatableViewModel {
  readonly courseSection: CourseSection;
  readonly teachers: Teacher[];
  readonly students: Student[];
  readonly currentScheduleCycleId: string | undefined;
  readonly isReadOnly: boolean;
}

export class AppAdminClassDetailsViewModel extends BaseUpdatableViewModel implements AdminClassDetailsViewModel {
  constructor(
    private readonly _courseSectionId: string,
    private readonly _schoolId: string,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get courseSectionLoadable(): SchoolCourseSectionLoadable {
    return this._schoolStore.getCourseSection(this._schoolId, this._courseSectionId);
  }

  @computed
  private get school(): SchoolInformation {
    return this._userStore.getSchoolForId(this._schoolId)!;
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionLoadable];
  }

  @computed
  get courseSection(): CourseSection {
    return this.courseSectionLoadable.data;
  }

  @computed
  get students(): Student[] {
    return this.courseSection.details?.students ?? [];
  }

  @computed
  get teachers(): Teacher[] {
    return this.courseSection.details?.teachers ?? [];
  }

  @computed
  get currentScheduleCycleId() {
    if (this.school.school == null) {
      return undefined;
    }

    return last(
      this.school.school.scheduleCycleIds.filter((id) => !this.school.school?.ignoredScheduleCycleIds.includes(id))
    );
  }

  @computed
  get isReadOnly(): boolean {
    return this.school.school?.isArchived === true;
  }
}
