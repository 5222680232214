import { useActivePlannerId, useServices } from '@/hooks';
import { UserDashboardSideBarViewModel } from '@/viewmodels';
import { Box, List, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackgroundImageScreenPaper, MediaQuery } from '../../utils';
import { UserProfileIcon } from '../UserProfileIcon';
import { ProfileMenu } from '../profile-menu';
import { SideBarAlert } from './SideBarAlert';
import { SideBarButton } from './SideBarButton';
import { SideBarCollapseButton } from './SideBarCollapseButton';
import { SideBarExpandButton } from './SideBarExpandButton';
import { SideBarHeader } from './SideBarHeader';

export interface SideBarProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardSideBarViewModel;
  scrollableItems: (isCompact: boolean) => ReactNode;
  staticItems?: (isCompact: boolean) => ReactNode;
  forceFullScreenWidth?: boolean;
  title?: string;
  onClose?: () => void;
  id: string;
}

export const SideBar = observer(
  ({
    sx = [],
    className,
    forceFullScreenWidth = false,
    title,
    scrollableItems,
    staticItems,
    viewModel,
    onClose,
    id
  }: SideBarProps) => {
    const { localization, route, settings } = useServices();
    const [fullWidth, setFullWidth] = useState(!settings.isSchoolSidebarCollapsed);
    const isSmallScreen = useMediaQuery<Theme>((th) => th.breakpoints.down('md'));
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    const profileButtonRef = useRef<HTMLElement | null>(null);
    const strings = localization.localizedStrings.utils;
    const theme = useTheme();

    const plannerId = useActivePlannerId();
    const navigate = useNavigate();

    function collapse() {
      setFullWidth(false);
      settings.isSchoolSidebarCollapsed = true;
    }

    function expand() {
      setFullWidth(true);
      settings.isSchoolSidebarCollapsed = false;
    }

    function showConnectedApps() {
      const destination = route.resolvePlannerSettingsLocation('connected-apps', plannerId);
      navigate(destination);
    }

    const isCollapsed = !fullWidth || isSmallScreen;
    const isCompact = !forceFullScreenWidth && isCollapsed;

    return (
      <BackgroundImageScreenPaper
        square
        className={className}
        sx={{
          ...sx,
          height: '100%',
          width: forceFullScreenWidth ? '100%' : undefined,
          borderRight: settings.backgroundImage == null ? `1px solid ${theme.palette.divider}` : undefined
        }}
      >
        <Stack
          sx={{
            width: forceFullScreenWidth ? '100%' : isCollapsed ? 100 : 250,
            height: '100%',
            overflow: 'hidden'
          }}
        >
          {title && <SideBarHeader isCompact={isCompact} title={title} />}

          <List sx={{ flex: 1, pt: title ? 0 : 2, overflow: 'auto' }}>{scrollableItems(isCompact)}</List>

          <Stack
            spacing={1}
            sx={{
              my: 2,
              mx: 1
            }}
          >
            {viewModel.hasConnectedAppsWarning && (
              <SideBarAlert
                severity="warning"
                isCompact={isCompact}
                title={strings.sideBarConnectedAppWarningTitle}
                message={strings.sideBarConnectedAppWarningMessage}
                action={{ title: strings.sideBarConnectedAppWarningButtonTitle, onClick: showConnectedApps }}
              />
            )}

            {viewModel.hasConnectedAppsError && (
              <SideBarAlert
                severity="error"
                isCompact={isCompact}
                title={strings.sideBarConnectedAppErrorTitle}
                message={strings.sideBarConnectedAppErrorMessage}
                action={{ title: strings.sideBarConnectedAppErrorButtonTitle, onClick: showConnectedApps }}
              />
            )}

            {staticItems != null && <Box>{staticItems(isCompact)}</Box>}

            <SideBarButton
              sx={sx}
              ref={profileButtonRef}
              className={className}
              isCompact={isCompact}
              onSelect={() => setShowProfileMenu(true)}
              title={viewModel.fullName}
              secondary={viewModel.email}
              secondaryCanWrap={false}
              icon={
                <UserProfileIcon
                  url={viewModel.imageUrl}
                  userFullName={viewModel.fullName}
                  emotionalStatusEmoji={viewModel.userEmotionalStatusEmoji}
                />
              }
              iconKind="avatar"
              tooltip={strings.appBarAccountTooltip}
            />

            {!forceFullScreenWidth && (
              <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
                {fullWidth ? (
                  <SideBarCollapseButton onPress={collapse} sx={{ m: 3 }} />
                ) : (
                  <SideBarExpandButton onPress={expand} sx={{ mb: 3, width: '100%' }} />
                )}
              </MediaQuery>
            )}
          </Stack>
        </Stack>
        <ProfileMenu
          id={id}
          isOpen={showProfileMenu}
          onClose={(actionPressed) => {
            setShowProfileMenu(false);
            if (actionPressed) {
              onClose?.();
            }
          }}
          anchorEl={profileButtonRef.current}
        />
      </BackgroundImageScreenPaper>
    );
  }
);
