import { PlannerTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type InboxWorkCountLoadable = LoadableValue<number>;

export class AppInboxWorkCountLoadable extends BaseLoadableValue<number> implements InboxWorkCountLoadable {
  constructor(
    private readonly _plannerId: string,
    private readonly _plannerTransport: PlannerTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<number> {
    return this._plannerTransport.getInboxWorkCountForPlanner(this._plannerId);
  }
}
