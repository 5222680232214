import { ConnectedAppUserDashboard, GoogleCalendarSyncBlocker, SyncStatusInfo } from '@/models';
import { GoogleCalendarTransportService } from '@/transports';
import { Calendar } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/calendar_pb';
import { SyncBlocker } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/sync_blocker_pb';
import { computed, makeObservable, override } from 'mobx';
import { AutoSyncService, GapiService, GoogleCalendarConnectedAppService, UserService } from '../../contracts';
import { BaseConnectedAppService } from './BaseConnectedAppService';

export class AppGoogleCalendarConnectedAppService
  extends BaseConnectedAppService<GoogleCalendarSyncBlocker>
  implements GoogleCalendarConnectedAppService
{
  constructor(
    userDashboard: ConnectedAppUserDashboard,
    user: UserService,
    autoSync: AutoSyncService,
    private readonly _transport: GoogleCalendarTransportService,
    private readonly _gapi: GapiService
  ) {
    super(userDashboard, user, autoSync, 'googleCalendar');

    makeObservable(this);
  }

  @override
  get hasSyncError(): boolean {
    return (
      this._syncFailed ||
      this.allSyncBlockers.includes('internal-error') ||
      this.allSyncBlockers.includes('unexpected-error') ||
      this.allSyncBlockers.includes('unspecified')
    );
  }

  @computed
  get warningSyncBlockers(): GoogleCalendarSyncBlocker[] {
    return this.allSyncBlockers.filter((syncBlocker) => {
      switch (syncBlocker) {
        case 'internal-error':
        case 'unexpected-error':
        case 'unspecified':
          return true;

        case 'no-google-offline-access':
          return false;

        case 'missing-google-access-scopes':
          return !this.allSyncBlockers.includes('no-google-offline-access');
      }
    });
  }

  @computed
  get isConnected(): boolean {
    return (
      this._syncStatus != null &&
      !this.allSyncBlockers.includes('no-google-offline-access') &&
      !this.allSyncBlockers.includes('missing-google-access-scopes')
    );
  }

  async connect() {
    const offlineAccessCode = await this._gapi.grantOfflineAccess(this._syncStatus?.missingGoogleScopes ?? []);
    await this._transport.registerOfflineAccessCode(offlineAccessCode, this.userDashboard);
  }

  async disconnect() {
    await this._transport.revokeOfflineAccessCode(this.userDashboard);
    await this.sync();
  }

  async getCalendars(): Promise<Calendar[]> {
    return await this._transport.getCalendars(this.userDashboard);
  }

  async updateCalendarSelection(calendarIds: string[]): Promise<Calendar[]> {
    const calendars = await this._transport.updateCalendarSelection(calendarIds, this.userDashboard);
    await this.sync();
    return calendars;
  }

  protected async getSyncStatus(): Promise<SyncStatusInfo<GoogleCalendarSyncBlocker>> {
    const syncStatus = await this._transport.getSyncStatus(this.userDashboard);
    const blockers = syncStatus.syncBlockers.map((b) => this.syncBlockerToString(b));

    return {
      isCurrentlySynchronizing: syncStatus.isCurrentlySynchronizing,
      lastSuccessfulSyncTime: syncStatus.lastSuccessfulSyncTime,
      syncBlockers: blockers,
      missingGoogleScopes: syncStatus.missingGoogleScopes
    };
  }

  protected loadSync(): Promise<void> {
    return this._transport.synchronize(this.userDashboard);
  }

  private syncBlockerToString(blocker: SyncBlocker): GoogleCalendarSyncBlocker {
    switch (blocker) {
      case SyncBlocker.INTERNAL_ERROR:
        return 'internal-error';
      case SyncBlocker.MISSING_GOOGLE_ACCESS_SCOPES:
        return 'missing-google-access-scopes';
      case SyncBlocker.NO_GOOGLE_OFFLINE_ACCESS:
        return 'no-google-offline-access';
      case SyncBlocker.UNEXPECTED_ERROR:
        return 'unexpected-error';
      case SyncBlocker.UNSPECIFIED:
        return 'unspecified';
    }
  }
}
