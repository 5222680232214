import { useServices } from '@/hooks';
import { dateToTimeOfDay, timeOfDayIsBetweenOthers, timeOfDayToDate } from '@/models';
import { UserDashboardCalendarDayTimedItemInfo } from '@/viewmodels';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, isSameMinute } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { UserDashboardCalendarDayTimedItemView } from './UserDashboardCalendarDayTimedItemView';

export interface UserDashboardCalendarDayTimedItemsStackProps {
  sx?: SxProps;
  className?: string;
  title?: string;
  headerAction?: { title: string; onClick: () => void };
  items: UserDashboardCalendarDayTimedItemInfo[];
  isToday: boolean;
  isReadOnly: boolean;
  canPublishWork: boolean;
  renderEmpty?: () => JSX.Element;
}

export const UserDashboardCalendarDayTimedItemsStack = observer(
  ({
    sx = [],
    className,
    title,
    items,
    headerAction,
    isToday,
    isReadOnly,
    canPublishWork,
    renderEmpty
  }: UserDashboardCalendarDayTimedItemsStackProps) => {
    const theme = useTheme();
    const { dateService } = useServices();

    const itemTitles = useMemo(() => {
      const titleInfos: ({ title: string; isHighlighted: boolean } | undefined)[] = [];
      items.forEach((item) => {
        const titleInfo = getTitleForItem(item, dateService.now, isToday);
        const isExisting = titleInfos.some((t) => t?.title === titleInfo.title);
        titleInfos.push(!isExisting ? titleInfo : undefined);
      });
      return titleInfos;
    }, [items]);

    return (
      <Stack sx={sx} className={className} spacing={1}>
        {title != null && (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center'
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: '600',
                flex: 1
              }}
            >
              {title}
            </Typography>

            {headerAction != null && (
              <Button
                size="small"
                color="inherit"
                sx={{ color: theme.palette.text.secondary }}
                onClick={() => headerAction.onClick()}
              >
                {headerAction.title}
              </Button>
            )}
          </Stack>
        )}
        <Stack
          spacing={2}
          sx={{
            pl: 1
          }}
        >
          {items.map((item, i) => (
            <UserDashboardCalendarDayTimedItemView
              key={item.value.id}
              item={item}
              isToday={isToday}
              isReadOnly={isReadOnly}
              canPublishWork={canPublishWork}
              titleInfo={itemTitles[i]}
            />
          ))}

          {items.length === 0 && renderEmpty?.()}
        </Stack>
      </Stack>
    );
  }
);

function getTitleForItem(
  item: UserDashboardCalendarDayTimedItemInfo,
  now: Date,
  isToday: boolean
): { title: string; isHighlighted: boolean } {
  const { startTime, endTime } = item.value;
  const startTimeFormatted = format(timeOfDayToDate(item.value.startTime), 'p');
  const endTimeFormatted = format(timeOfDayToDate(item.value.endTime), 'p');

  return startTimeFormatted === endTimeFormatted
    ? {
        title: format(timeOfDayToDate(item.value.startTime), 'p'),
        isHighlighted: isToday && isSameMinute(timeOfDayToDate(item.value.startTime), now)
      }
    : {
        title: `${startTimeFormatted} - ${endTimeFormatted}`,
        isHighlighted: isToday && timeOfDayIsBetweenOthers(dateToTimeOfDay(now), startTime, endTime)
      };
}
