import { useServices } from '@/hooks';
import { PlannerItemsNoteInfo, contextMenuActionsForNote } from '@/viewmodels';
import { Box, CardActionArea, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { NoteIcon } from '../../lists';
import { ContextMenuHandler } from '../../shared';

export interface PlannerItemsNoteViewProps {
  sx?: SxProps;
  className?: string;
  note: PlannerItemsNoteInfo;
  displayCourseColor: boolean;
  isReadOnly: boolean;
}

export const PlannerItemsNoteView = observer(
  ({ sx = [], className, note, displayCourseColor, isReadOnly }: PlannerItemsNoteViewProps) => {
    const { featureFlag, navigation, pasteboard } = useServices();
    const location = useLocation();
    const navigate = useNavigate();

    const { text, time, isAllDay } = note.note;
    const resolvedText = text?.text ?? '';
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
    const displayDate = time != null;

    function formatDueTime() {
      return time != null ? format(time.toDate(), isAllDay ? 'PP' : 'PPp') : '';
    }

    function onSelect() {
      navigation.navigateToNoteEdit(navigate, location, note.id);
    }

    return (
      <ContextMenuHandler actions={contextMenuActionsForNote(note.note, pasteboard, featureFlag)}>
        {(contextMenuHandler) => (
          <CardActionArea
            component="div"
            sx={{
              ...sx,
              py: 1.5,
              px: { xs: 1, sm: 3 },
              display: 'flex',
              alignItems: 'stretch',
              width: '100%',
              overflow: 'hidden'
            }}
            className={className}
            onClick={onSelect}
            disabled={isReadOnly}
            onContextMenu={contextMenuHandler}
          >
            <NoteIcon size={isExtraSmallScreen ? 28 : 36} color={displayCourseColor ? note.courseColor : undefined} />

            <Box
              sx={{
                flex: 1,
                ml: { xs: 1, sm: 2 },
                mr: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  height: !isExtraSmallScreen ? 40 : undefined,
                  display: '-webkit-box',
                  WebkitLineClamp: !isExtraSmallScreen ? '2' : '1',
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {resolvedText}
              </Typography>

              {displayDate && isExtraSmallScreen && (
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {formatDueTime()}
                </Typography>
              )}
            </Box>

            {displayDate && !isExtraSmallScreen && (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary
                }}
              >
                {formatDueTime()}
              </Typography>
            )}
          </CardActionArea>
        )}
      </ContextMenuHandler>
    );
  }
);
