import { PlannerContents } from '@/models';
import { LocalizationService } from '@/services';
import { PlannerTransportService } from '@/transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type PlannerContentsLoadable = LoadableValue<PlannerContents>;

export class AppPlannerContentsLoadable extends BaseLoadableValue<PlannerContents> implements PlannerContentsLoadable {
  constructor(
    private readonly _plannerId: string,
    private readonly _plannerTransport: PlannerTransportService,
    private readonly _localization: LocalizationService
  ) {
    super();
  }

  protected async loadData(): Promise<PlannerContents> {
    return await this._plannerTransport.getPlannerContents(this._plannerId, this._localization.currentTimezone);
  }
}
