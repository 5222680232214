import { useServices, useViewModel } from '@/hooks';
import {
  StudentsPlannerSummaryTableRowViewModel,
  StudentsPlannerSummaryViewModel,
  getDailyActivityBehaviorTooltip,
  getInboxBehaviorLevel,
  getInboxBehaviorTooltip,
  getStudentEmotionalStatusLevel,
  getStudentEmotionalStatusTooltip
} from '@/viewmodels';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { List } from '../../../lists';
import { ContentCard } from '../../../shared';
import { Dialog, LegendButton, SearchBar } from '../../../utils';
import { StudentBehaviors } from '../../student';
import { SchoolCourseSectionDetailsStudentGridItem } from './SchoolCourseSectionDetailsStudentGridItem';
import { makeSchoolCourseSectionStudentsLegendSections } from './SchoolCourseSectionDetailsUtils';

export interface SchoolCourseSectionStudentPickerProps {
  sx?: SxProps;
  className?: string;
  viewModel: StudentsPlannerSummaryViewModel;
  student: StudentsPlannerSummaryTableRowViewModel;
}

export const SchoolCourseSectionStudentPicker = observer(
  ({ sx = [], className, viewModel, student }: SchoolCourseSectionStudentPickerProps) => {
    const { localization, dateService } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;
    const theme = useTheme();
    const [showPicker, setShowPicker] = useState(false);

    function closePicker() {
      setShowPicker(false);
    }
    const dialogViewModel = useViewModel(
      (viewModels) => viewModels.createSchoolCourseSectionStudentPickerDialogViewModel(closePicker),
      []
    );
    const inboxBehaviorLevel = getInboxBehaviorLevel(student.inboxItemsCount);
    const lastEmotionalStatusLevel = getStudentEmotionalStatusLevel(student.emotionalStateRating);

    return (
      <Box sx={sx} className={className}>
        <ContentCard onClick={() => setShowPicker(true)}>
          <CardActionArea sx={{ px: 2, py: 1 }}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center'
              }}
            >
              <Stack
                spacing={0}
                sx={{
                  flex: 1
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary
                  }}
                >
                  {strings.detailsStudentPickerTitle}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.main
                  }}
                >
                  {student.name}
                </Typography>
              </Stack>

              <StudentBehaviors
                isCompact
                behaviors={{
                  dailyActivity: {
                    kind: 'daily-activity',
                    level: student.lastConnectionIndicatorLevel,
                    tooltip: getDailyActivityBehaviorTooltip(student.lastConnection, localization, dateService)
                  },
                  inbox: {
                    kind: 'inbox',
                    level: inboxBehaviorLevel,
                    tooltip: getInboxBehaviorTooltip(inboxBehaviorLevel, localization)
                  },
                  lastEmotionalStatus: {
                    kind: 'last-emotional-status',
                    level: lastEmotionalStatusLevel,
                    tooltip: getStudentEmotionalStatusTooltip(student.emotionalStateRating, localization)
                  }
                }}
              />
              <ExpandMoreIcon sx={{ color: theme.palette.text.secondary }} />
            </Stack>
          </CardActionArea>
        </ContentCard>
        <Dialog
          isOpen={showPicker}
          viewModel={dialogViewModel}
          width="md"
          fullScreenWidth="sm"
          fullHeight
          title={strings.detailsStudentPickerTitle}
          renderData={() => (
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                height: '100%'
              }}
            >
              <SearchBar
                placeholder={strings.detailsStudentSearchBarPlaceholder}
                onSearch={(text) => (viewModel.searchText = text ?? '')}
              />

              <Box
                sx={{
                  flex: 1,
                  overflowY: 'auto'
                }}
              >
                <List
                  style={{ width: '100%', height: '100%' }}
                  sections={[{ id: 'main', title: undefined, numberOfRows: viewModel.students.length }]}
                  rowHeight={130}
                  hideDivider
                  keyForItem={(_, row) => viewModel.students[row].id}
                  renderItem={(_, row) => {
                    const student = viewModel.students[row];
                    return (
                      <SchoolCourseSectionDetailsStudentGridItem
                        student={student}
                        sx={{ height: 130 }}
                        onClick={() => setShowPicker(false)}
                      />
                    );
                  }}
                />
              </Box>

              <Box>
                <LegendButton
                  sections={makeSchoolCourseSectionStudentsLegendSections(localization)}
                  buttonTitle={strings.detailsStudentsGridLegendButtonTitle}
                  popoverTitle={strings.detailsStudentsGridLegendTitle}
                />
              </Box>
            </Stack>
          )}
        />
      </Box>
    );
  }
);
