import { useActivePlannerId, useServices } from '@/hooks';
import {
  UserDashboardCalendarItemState,
  UserDashboardCalendarPublishedWorkInfo,
  UserDashboardCalendarWeekTimedPublishedWorkInfo,
  UserDashboardCalendarWeekTimedWorkInfo,
  UserDashboardCalendarWorkInfo
} from '@/viewmodels';
import { Box, Card, CardActionArea, Stack, Tooltip, Typography, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { BackgroundLocationState } from '../../../../../BackgroundLocationState';
import { ColorIndicator, WorkIcon } from '../../../../lists';
import { MultilineTooltipTitle } from '../../../../utils';
import { ContextMenuHandler } from '../../../ContextMenuHandler';
import { formatItemStartTimeLong, formatItemTime } from '../../shared';

function isTimedWork(
  work:
    | UserDashboardCalendarWorkInfo
    | UserDashboardCalendarWeekTimedWorkInfo
    | UserDashboardCalendarPublishedWorkInfo
    | UserDashboardCalendarWeekTimedPublishedWorkInfo
): work is UserDashboardCalendarWeekTimedWorkInfo | UserDashboardCalendarWeekTimedPublishedWorkInfo {
  return (work as UserDashboardCalendarWeekTimedWorkInfo)?.position != null;
}

export interface UserDashboardCalendarWeekWorkProps {
  sx?: SxProps;
  className?: string;
  work:
    | { case: 'work'; value: UserDashboardCalendarWorkInfo | UserDashboardCalendarWeekTimedWorkInfo }
    | {
        case: 'publishedWork';
        value: UserDashboardCalendarPublishedWorkInfo | UserDashboardCalendarWeekTimedPublishedWorkInfo;
      };
  showBorder: boolean;
}

export const UserDashboardCalendarWeekWork = observer(
  ({ sx = [], className, work, showBorder }: UserDashboardCalendarWeekWorkProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();
    const hasDescription = work.value.description.length > 0;
    const defaultHeight = hasDescription ? 37 : 20;
    const [showTooltip, setShowTooltip] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as BackgroundLocationState;
    const navigate = useNavigate();

    function showWorkDetails() {
      const newLocation =
        work.case === 'work'
          ? route.resolvePlannerWorkLocation(plannerId, work.value.id)
          : route.resolvePublishedWorkLocation(plannerId, work.value.id, work.value.schoolId);
      navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={work.value.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={
                  isTimedWork(work.value)
                    ? [work.value.title, formatItemStartTimeLong(work.value.startTime)]
                    : [work.value.title]
                }
              />
            }
            open={showTooltip && state.backgroundLocation == null}
            disableHoverListener
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            disableInteractive
          >
            <StyledWorkView
              sx={{ ...sx, height: isTimedWork(work.value) ? work.value.position.height : defaultHeight }}
              className={className}
              // Prevents background click event when user clicks on the left or right padding of the period.
              onClick={(e) => e.stopPropagation()}
              showBorder={showBorder}
              hasDescription={hasDescription}
              onContextMenu={contextMenuHandler}
            >
              <Card className="card" elevation={0} sx={{ borderRadius: isTimedWork(work.value) ? 1 : 0.5 }}>
                <CardActionArea
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTooltip(false);
                    showWorkDetails();
                  }}
                  className="cardAction"
                >
                  <Box
                    className="mainContainer"
                    sx={{
                      alignItems: 'center'
                    }}
                  >
                    <Box className="colorIndicatorContainer">
                      <ColorIndicator color={work.value.color} isRounded sx={{ height: '100%' }} />
                    </Box>

                    <Stack className="contentContainer" spacing={0.5}>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{
                          alignItems: 'center',
                          overflow: 'hidden',
                          flex: 1
                        }}
                      >
                        <WorkIcon icon={work.value.icon} size={hasDescription ? 20 : 16} />

                        <Stack
                          spacing={-0.5}
                          sx={{
                            flex: 1,
                            overflow: 'hidden'
                          }}
                        >
                          <Typography
                            noWrap
                            variant="caption"
                            color={getColorForTitle(work.value.state)}
                            sx={{
                              fontWeight: '600'
                            }}
                          >
                            {work.value.title}
                          </Typography>

                          {hasDescription && (
                            <Typography
                              noWrap
                              variant="caption"
                              sx={{
                                fontSize: 10
                              }}
                            >
                              {work.value.description}
                            </Typography>
                          )}
                        </Stack>

                        {isTimedWork(work.value) && work.value.startTime != null && (
                          <Typography
                            noWrap
                            variant="caption"
                            color={work.value.state === 'completed' ? 'textSecondary' : 'textPrimary'}
                            sx={{
                              fontSize: 10
                            }}
                          >
                            {formatItemTime(work.value.startTime)}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            </StyledWorkView>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

interface StyledWorkViewProps {
  hasDescription: boolean;
  showBorder: boolean;
}

const StyledWorkView = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasDescription' && prop !== 'showBorder'
})<StyledWorkViewProps>(({ theme, hasDescription, showBorder }) => {
  const colorIndicatorPadding = showBorder ? 4 : hasDescription ? 6 : 2;

  return {
    '.badge': {
      height: '100%',
      width: '100%'
    },

    '.card': {
      height: '100%',
      width: '100%',
      backgroundImage: 'none',
      backgroundColor: !showBorder ? 'transparent' : undefined,
      border: showBorder ? `1px solid ${theme.palette.divider}` : undefined
    },

    '.cardAction': {
      paddingRight: '6px',
      overflow: 'hidden',
      height: '100%'
    },

    '.mainContainer': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row'
    },

    '.colorIndicatorContainer': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      height: '100%',
      paddingTop: colorIndicatorPadding,
      paddingBottom: colorIndicatorPadding
    },

    '.contentContainer': {
      flex: 1,
      display: 'flex',
      overflow: 'hidden',
      paddingTop: '2px',
      paddingBottom: '2px',
      minWidth: 120
    }
  };
});

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
