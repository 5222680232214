import { useServices } from '@/hooks';
import { AttachmentInfo } from '@/models';
import { CloseRounded } from '@mui/icons-material';
import { Box, Button, Dialog, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Dashboard } from '@uppy/react';
import { AssemblyResponse } from '@uppy/transloadit';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FormPopoverHeader } from '../../utils';

import '@uppy/audio/dist/style.min.css';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/screen-capture/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import LocalizedStrings from 'strings';

export interface WorkAddFileAttachmentDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: (attachmentInfos: AttachmentInfo[] | undefined) => void;
}

export const WorkAddFileAttachmentDialog = observer(
  ({ sx = [], className, onClose, isOpen }: WorkAddFileAttachmentDialogProps) => {
    const { attachmentService } = useServices();
    const strings = LocalizedStrings.work.attachments.addFileDialog;
    const theme = useTheme();
    const uppy = attachmentService.uppyInstance;
    const [isUploading, setIsUploading] = useState(false);

    function cancel() {
      onClose(undefined);
    }

    useEffect(() => {
      const onUploadStartHandler = () => setIsUploading(true);

      uppy.on('upload', onUploadStartHandler);

      const onUploadCompleteHandler = (assembly: AssemblyResponse) => {
        const attachments = attachmentService.createFileAttachmentsFromResults(assembly.results);
        onClose(attachments);
        setIsUploading(false);
      };

      uppy.on('transloadit:complete', onUploadCompleteHandler);

      const onErrorHandler = () => {
        setIsUploading(false);
      };

      uppy.on('error', onErrorHandler);

      return () => {
        uppy.off('upload', onUploadStartHandler);
        uppy.off('transloadit:complete', onUploadCompleteHandler);
        uppy.off('error', onErrorHandler);
        uppy.cancelAll();
      };
    }, []);

    return (
      <Dialog
        sx={sx}
        className={className}
        open={isOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => (!isUploading ? cancel() : undefined)}
      >
        <FormPopoverHeader title={strings.title()} />
        <Box
          sx={{
            p: 1
          }}
        >
          <Dashboard
            uppy={uppy}
            theme={theme.palette.mode}
            style={{ borderRadius: theme.shape.borderRadius, borderColor: 'none' }}
            hideCancelButton={false}
          />
        </Box>
        <Stack
          spacing={1}
          direction="row"
          sx={{
            p: 2,
            pt: 1,
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant="contained-grey"
            color="error"
            startIcon={<CloseRounded />}
            disabled={isUploading}
            onClick={cancel}
          >
            {strings.cancelButtonTitle()}
          </Button>
        </Stack>
      </Dialog>
    );
  }
);
