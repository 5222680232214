import { alpha, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../../../utils';

export interface ScheduleCycleSpecialDaysSectionTitleProps {
  sx?: SxProps;
  className?: string;
  title: string;
  isHighlighted?: boolean;
  clearHighlight?: () => void;
}

export function ScheduleCycleSpecialDaysSectionTitle({
  sx = [],
  className,
  title,
  isHighlighted = false,
  clearHighlight
}: ScheduleCycleSpecialDaysSectionTitleProps) {
  const theme = useTheme();

  return (
    <Stack
      className={className}
      direction="column"
      spacing={0.5}
      sx={[
        {
          alignItems: 'flex-start',
          px: 1
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: '500',
          color: isHighlighted ? theme.palette.primary.main : undefined
        }}
      >
        {title}
      </Typography>
      {isHighlighted && clearHighlight != null && (
        <Typography
          variant="caption"
          onClick={() => clearHighlight()}
          sx={{
            color: theme.palette.text.secondary,
            cursor: 'pointer',
            ['&:hover']: {
              color: alpha(theme.palette.text.secondary, 0.3)
            }
          }}
        >
          {LocalizedStrings.scheduleCycle.edit.specialDays.clearSelectionButtonTitle()}
        </Typography>
      )}
    </Stack>
  );
}
