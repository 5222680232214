import { dateToPBDate, dayToString } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService } from '@/services';
import { PlannerDataStore } from '@/stores';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { makeObservable } from 'mobx';
import { UserDashboardCalendarAnnotationInfo } from '../../shared';
import {
  AppUserDashboardCalendarDatePickerViewModel,
  UserDashboardCalendarDatePickerDayInfo
} from './UserDashboardCalendarDatePickerViewModel';

export class AppPlannerUserDashboardCalendarDatePickerViewModel extends AppUserDashboardCalendarDatePickerViewModel<PlannerDay> {
  constructor(
    plannerId: string,
    initialDate: Date | undefined,
    selectedDates: () => Date[],
    highlightedDates: (startDate: Date, endDate: Date) => Date[],
    minDate: Date | undefined,
    maxDate: Date | undefined,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    dateService: DateService = ServiceContainer.services.dateService
  ) {
    const calendarStore = plannerStore.getCalendarStore(plannerId);
    super(initialDate, selectedDates, highlightedDates, minDate, maxDate, calendarStore, dateService);
    makeObservable(this);
  }

  getDayInfoForDate(date: Date): UserDashboardCalendarDatePickerDayInfo {
    const day = dateToPBDate(date);
    const plannerDay = this._calendarStore.days.get(dayToString(day));

    const annotations: UserDashboardCalendarAnnotationInfo[] =
      plannerDay?.annotations.map((a) => ({ id: a.symbol, color: a.color, symbol: a.symbol, title: a.title })) ?? [];

    const isDayOfWeekCycleDayName = plannerDay?.isDayOfWeekCycleDayName ?? true;

    return {
      day,
      cycleDayName: !isDayOfWeekCycleDayName ? (plannerDay?.cycleDayName ?? '') : '',
      annotations,
      isSelected: this.getDateIsSelected(date),
      isHighlighted: this.getDateIsHighlighted(date),
      isDisabled: this.getDateIsDisabled(date)
    };
  }
}
