import { TimeOfDay, UserDashboardInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { PlannerDataStore, SchoolDataStore, UserDataStore } from '@/stores';
import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { chain, intersection, uniq } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { ScheduleCycleActivitySchedulesDayColumnInfo } from './ScheduleCycleActivitySchedulesDayColumnInfo';
import {
  AppScheduleCyclePeriodSchedulePeriodEditViewModel,
  ScheduleCyclePeriodSchedulePeriodEditViewModel
} from './ScheduleCyclePeriodSchedulePeriodEditViewModel';
import { BaseScheduleCyclePeriodSchedulesGridViewModel } from './ScheduleCyclePeriodSchedulesGridViewModel';

export class AppScheduleCycleBellTimesViewModel extends BaseScheduleCyclePeriodSchedulesGridViewModel {
  constructor(
    dashboard: UserDashboardInfo,
    plannerId: string | undefined,
    scheduleCycleId: string,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(dashboard, plannerId, scheduleCycleId, '', undefined, plannerStore, schoolStore, userStore, localization);
    makeObservable(this);
  }

  @computed
  private get periodSchedulesById(): Map<string, PeriodSchedule> {
    return this.scheduleCycle.periodSchedules.reduce((value, schedule) => {
      value.set(schedule.id, schedule);
      return value;
    }, new Map<string, PeriodSchedule>());
  }

  @computed
  get columns(): ScheduleCycleActivitySchedulesDayColumnInfo[] {
    return this.scheduleCycle.periodSchedules
      .filter((ps) => !ps.shouldDelete)
      .map((ps, index) => {
        const occurrences = this.scheduleCycle.periodScheduleOccurrences.filter(
          (pso) => pso.periodScheduleId === ps.id
        );
        const otherOccurrencesWithSameTarget = this.scheduleCycle.periodScheduleOccurrences.filter(
          (pso) =>
            !pso.shouldDelete &&
            pso.periodScheduleId !== ps.id &&
            occurrences.find((o) => o.when.case === pso.when.case && o.when.value === pso.when.value) != null
        );

        const periodSchedulesWithSameTarget = chain(otherOccurrencesWithSameTarget)
          .map((o) => o.periodScheduleId)
          .uniq()
          .map((id) => this.periodSchedulesById.get(id))
          .compact()
          .filter((schedule) => intersection(schedule.scheduleTags, ps.scheduleTags).length > 0)
          .value();

        return {
          id: ps.id,
          index,
          canEdit: true,
          title: ps.name,
          subtitle: ps.scheduleTags.join(', '),
          periods: ps.periods.map((p) => ({
            id: p.id,
            periodLabel: p.label,
            startTime: p.startTime!,
            endTime: p.endTime!,
            primaryActivityScheduleInfo: undefined,
            hasConflictingActivitySchedules: false
          })),
          hasMultiplePeriodsWithSameLabel: uniq(ps.periods.map((p) => p.label)).length !== ps.periods.length,
          hasOtherOccurrencesWithSameTarget: periodSchedulesWithSameTarget.length > 0
        };
      });
  }

  get emptyMessage(): string | undefined {
    return undefined;
  }

  async createPeriodEditViewModel(
    columnIndex: number,
    periodId: string | undefined,
    time: TimeOfDay
  ): Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel> {
    const periodSchedule = this.scheduleCycle.periodSchedules[columnIndex];
    const { period, suggestions } = await this.getPeriodAndSuggestions(periodSchedule.id, periodId, time);

    return new AppScheduleCyclePeriodSchedulePeriodEditViewModel(
      this._dashboard,
      this._scheduleCycleId,
      periodSchedule.id,
      period,
      periodSchedule.name,
      suggestions
    );
  }
}
