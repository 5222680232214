import { ServiceContainer } from '@/providers';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface UserPersonaStepViewModel extends BaseOnboardingScreenViewModel {
  readonly name: string;
  readonly pictureUrl?: string;
  persona?: UserPersona;
  readonly error?: string;
  showUseCode(): void;
}

export class AppUserPersonaStepViewModel extends AppBaseOnboardingScreenViewModel implements UserPersonaStepViewModel {
  @observable private _persona: UserPersona | undefined;
  @observable private _error: string | undefined;

  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _user = ServiceContainer.services.user,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _analytics = ServiceContainer.services.analytics,
    private readonly _localization = ServiceContainer.services.localization
  ) {
    super(onboardingState);
    this._persona = this._user.currentUser?.persona;
    makeObservable(this);
  }

  @computed
  get name(): string {
    return this._user.currentUser?.fullName ?? '';
  }

  @computed
  get pictureUrl(): string | undefined {
    return this._user.currentUser?.pictureUrl;
  }

  @computed
  get persona(): UserPersona | undefined {
    return this._persona;
  }

  set persona(value: UserPersona | undefined) {
    this._persona = value;
    void this._analytics.logAdConversionOnce('persona-selected');
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get canSubmit(): boolean {
    return !this._isSubmitting && this.persona != null && this._persona !== UserPersona.UNSPECIFIED;
  }

  @action
  async submit() {
    if (this._persona == null) {
      throw new Error('Should not be able to submit');
    }

    this._isSubmitting = true;
    this._error = undefined;

    try {
      await this._userStore.assignPersona(this.persona!);
      this._onboardingState.userPersona = this._persona!;
      const plannerId = await this._userStore.createPlanner(PlannerRelationshipKind.INDIVIDUAL, undefined, false);
      this._onboardingState.plannerId = plannerId;

      if (this._persona === UserPersona.TEACHER) {
        // If user selected 'Teacher', we create a default personal school.
        const { schoolId } = await this._userStore.createSchool(
          this._localization.localizedStrings.onboarding.defaultSchoolName,
          undefined,
          SchoolSharingMode.NOT_SHARED,
          false,
          plannerId
        );
        this._onboardingState.schoolId = schoolId;
      }

      this._onboardingState.displayScreenOfKind(
        this._persona === UserPersona.PARENT ? 'share-planner' : 'classroom-connection'
      );
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => (this._error = error.message));
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  showUseCode() {
    this._onboardingState.displayScreenOfKind('use-code');
  }
}
