import { AttachmentStrings, EnglishAttachmentStrings, FrenchAttachmentStrings } from './AttachmentStrings';
import { EditableWorkStrings, EnglishEditableWorkStrings, FrenchEditableWorkStrings } from './EditableWorkStrings';
import { EnglishInvitationStrings, FrenchInvitationStrings, InvitationStrings } from './InvitationStrings';
import { EnglishWorkDetailsStrings, FrenchWorkDetailsStrings, WorkDetailsStrings } from './WorkDetailsStrings';

export interface ExWorkStrings {
  readonly attachments: AttachmentStrings;
  readonly details: WorkDetailsStrings;
  readonly editableWork: EditableWorkStrings;
  readonly invitation: InvitationStrings;
}

export const EnglishWorkStrings: ExWorkStrings = {
  attachments: EnglishAttachmentStrings,
  details: EnglishWorkDetailsStrings,
  editableWork: EnglishEditableWorkStrings,
  invitation: EnglishInvitationStrings
};

export const FrenchWorkStrings: ExWorkStrings = {
  attachments: FrenchAttachmentStrings,
  details: FrenchWorkDetailsStrings,
  editableWork: FrenchEditableWorkStrings,
  invitation: FrenchInvitationStrings
};
