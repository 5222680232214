import { ListItemText, ListItemTextProps } from '@mui/material';

export function ListItemTextMultiline({
  primaryTypographyProps,
  secondaryTypographyProps,
  ...props
}: ListItemTextProps) {
  const { sx: primarySx, ...primaryProps } = primaryTypographyProps ?? {};
  const { sx: secondarySx, ...secondaryProps } = secondaryTypographyProps ?? {};

  return (
    <ListItemText
      {...props}
      primaryTypographyProps={{
        ...primaryProps,
        sx: { ...primarySx, whiteSpace: 'pre-line' }
      }}
      secondaryTypographyProps={{
        ...secondaryProps,
        sx: { ...secondarySx, whiteSpace: 'pre-line' }
      }}
    />
  );
}
