import { computed, makeObservable } from 'mobx';
import { ConnectedAppKind } from '../../models';
import { ServiceContainer } from '../../providers';
import {
  ClassroomConnectedAppService,
  ConnectedAppsService,
  GoogleCalendarConnectedAppService,
  LocalizationService
} from '../../services';
import { EditableConnectedAppViewModel } from './EditableConnectedAppViewModel';
import { AppPlannerEditableClassroomConnectedAppViewModel } from './classroom';
import { AppEditableGoogleCalendarConnectedAppViewModel } from './googleCalendar';

export interface ConnectedAppEditViewModel {
  readonly isApplying: boolean;
  editableConnectedApp: EditableConnectedAppViewModel;
}

export class AppConnectedAppEditViewModel implements ConnectedAppEditViewModel {
  constructor(
    private readonly _connectedAppKind: ConnectedAppKind,
    private readonly _plannerId: string,
    private readonly _schoolId: string | undefined,
    private readonly _startSync: boolean,
    private readonly _onSuccess: (hasChanges: boolean) => Promise<void>,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);
  }

  @computed
  get isApplying(): boolean {
    return this.editableConnectedApp.isApplying;
  }

  @computed
  get editableConnectedApp(): EditableConnectedAppViewModel {
    switch (this._connectedAppKind) {
      case 'classroom':
        return new AppPlannerEditableClassroomConnectedAppViewModel(
          this._connectedApps.getConnectedAppService(
            this._connectedAppKind,
            this._plannerId,
            this._schoolId
          )! as ClassroomConnectedAppService,
          this._plannerId,
          this._onSuccess,
          this._startSync,
          this._localization
        );

      case 'googleCalendar':
        return new AppEditableGoogleCalendarConnectedAppViewModel(
          this._connectedApps.getConnectedAppService(
            this._connectedAppKind,
            this._plannerId,
            this._schoolId
          )! as GoogleCalendarConnectedAppService,
          this._onSuccess,
          this._startSync,
          this._localization
        );
    }
  }
}
