import i18n from 'translate';

export const ScheduleCycleActivitySchedulesEditStrings = {
  addTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.addTitle'),
  datePickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.datePickerLabel'),
  dayPickerDayOfWeekSectionTitle: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerDayOfWeekSectionTitle'),
  dayPickerDayOfWeekSectionWeeklyScheduleTitle: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerDayOfWeekSectionWeeklyScheduleTitle'),
  dayPickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerLabel'),
  dayPickerRotatingDaySectionTitle: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerRotatingDaySectionTitle'),
  dayPickerRotatingDaySectionWeeklyScheduleTitle: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerRotatingDaySectionWeeklyScheduleTitle'),
  dayPickerSectionTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerSectionTitle'),
  dayPickerSpecificDateOption: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.dayPickerSpecificDateOption'),
  displayedPeriodLabelInputLabel: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.edit.displayedPeriodLabelInputLabel'),
  editTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.editTitle'),
  endTimePickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.endTimePickerLabel'),
  existingScheduleConflictWarning: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.edit.existingScheduleConflictWarning'),
  invalidTimesWarning: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.invalidTimesWarning'),
  moreInformationSectionTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.moreInformationSectionTitle'),
  periodLabelPickerEmptyOption: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.periodLabelPickerEmptyOption'),
  periodLabelPickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.periodLabelPickerLabel'),
  periodPickerPeriodOption: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.periodPickerPeriodOption'),
  periodPickerTimesOption: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.periodPickerTimesOption'),
  roomInputLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.roomInputLabel'),
  scheduleTagNoneLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.scheduleTagNoneLabel'),
  scheduleTagPickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.scheduleTagPickerLabel'),
  startTimePickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.startTimePickerLabel'),
  termPickerLabel: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.termPickerLabel'),
  termPickerNoneOption: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.termPickerNoneOption'),
  timeSectionTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.edit.timeSectionTitle')
};
