import { Theme, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';

export interface MediaQueryProps {
  children: ReactNode;
  /**
   * The media query. Used to determine if the children should be rendered or not.
   */
  mediaQuery: string | ((theme: Theme) => string);
}

export const MediaQuery = ({ children, mediaQuery }: MediaQueryProps) => {
  const canRender = useMediaQuery(mediaQuery);
  return canRender ? <>{children}</> : null;
};
