import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { EnglishDateStrings, FrenchDateStrings } from '../utils/DateStrings';

export interface EditableWorkStrings {
  readonly createWorkButton: string;
  readonly description: string;
  readonly descriptionPlaceholder: string;
  readonly dueDate: string;
  readonly dueDateFormat: string;
  readonly dueTime: string;
  readonly initialTitle: string;
  readonly skipCreateWorkButton: string;
  readonly title: string;
  readonly workIcon: string;

  createErrorMessage: (error: string) => string;
  initialDescription: (persona: UserPersona) => string;
}

export const EnglishEditableWorkStrings: EditableWorkStrings = {
  createWorkButton: 'Create homework',
  description: 'Instructions',
  descriptionPlaceholder: 'Add additional instructions…',
  dueDate: 'When is it due?',
  dueDateFormat: EnglishDateStrings.numericDateFormat,
  dueTime: 'At what time?',
  initialTitle: 'First homework',
  skipCreateWorkButton: 'Skip',
  title: 'Title (uses icon name by default)',
  workIcon: 'What type of homework is it?',

  createErrorMessage: (error) => `An error occurred while creating the homework. Details: ${error}`,
  initialDescription: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Please enter one piece of homework you need to accomplish soon.';
      case UserPersona.PARENT:
        return 'Please enter one piece of homework your child needs to accomplish soon.';
      case UserPersona.TEACHER:
        return 'Please enter one piece of homework your student needs to accomplish soon.';
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
      default:
        return 'Please enter one piece of homework you need to accomplish soon.';
    }
  }
};

export const FrenchEditableWorkStrings: EditableWorkStrings = {
  createWorkButton: 'Créer la tâche',
  description: 'Instructions',
  descriptionPlaceholder: 'Ajouter des instructions supplémentaires…',
  dueDate: 'Quand la tâche est-elle due?',
  dueDateFormat: FrenchDateStrings.numericDateFormat,
  dueTime: 'À quelle heure?',
  initialTitle: 'Première tâche',
  skipCreateWorkButton: 'Sauter',
  title: 'Titre (utilise le nom de l’icône par défaut)',
  workIcon: 'Quel est le type?',

  createErrorMessage: (error) => `Une erreur est survenue lors de la création de la tâche. Détails : ${error}`,
  initialDescription: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Veuillez entrer une tâche que vous devez accomplir bientôt.';
      case UserPersona.PARENT:
        return 'Veuillez entrer une tâche que votre enfant doit accomplir bientôt.';
      case UserPersona.TEACHER:
        return 'Veuillez entrer une tâche que votre élève doit accomplir bientôt.';
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
      default:
        return 'Veuillez entrer une tâche que vous devez accomplir bientôt.';
    }
  }
};
