import { RouteTemplates } from '@/RouteTemplates';
import { useServices } from '@/hooks';
import { DefaultDemoModeMockDate } from '@/services';
import { MailRounded, QuestionAnswerRounded } from '@mui/icons-material';
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ConfirmationDialog, Logo } from '../../utils';
import { SettingsContent } from './SettingsContent';

export interface AboutProps {
  sx?: SxProps;
  className?: string;
}

export const About = observer(({ sx = [], className }: AboutProps) => {
  const { dateService, environment, intercom, localization, settingsStorage } = useServices();
  const strings = localization.localizedStrings.utils;

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [demoClickCount, setDemoClickCount] = useState(0);
  const [showDemoModeConfirmation, setShowDemoModeConfirmation] = useState(false);
  const isDemoMode = settingsStorage.isDemoMode === true;
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if (demoClickCount === 5) {
      setShowDemoModeConfirmation(true);
      setDemoClickCount(0);
    }
  }, [demoClickCount]);

  function bumpClickCount() {
    setDemoClickCount(demoClickCount + 1);
    // After each click we start a new timeout. If after 3 seconds, the click count is still not of 5, we reset it to 0.
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setDemoClickCount(0), 3_000);
  }

  function onDemoModeConfirm(hasConfirmed: boolean) {
    setShowDemoModeConfirmation(false);

    if (hasConfirmed) {
      settingsStorage.isDemoMode = settingsStorage.isDemoMode !== true;
      navigate(RouteTemplates.home);

      if (settingsStorage.isDemoMode) {
        dateService.mockCurrentDate(DefaultDemoModeMockDate);
      } else {
        dateService.unmockCurrentDate();
      }
    }
  }

  return (
    <SettingsContent sx={sx} className={className}>
      <Stack
        className={className}
        spacing={3}
        sx={{
          alignItems: 'flex-start'
        }}
      >
        <Typography dangerouslySetInnerHTML={{ __html: strings.aboutMessage }} />

        <Stack spacing={1}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {strings.aboutVersionNumberTitle}
          </Typography>
          <Typography>
            <code
              style={{ fontFamily: 'inherit' }}
            >{`${environment.version}.${environment.buildNumber}-${environment.environment}`}</code>
          </Typography>
        </Stack>

        <Stack
          spacing={1}
          sx={{
            alignItems: 'flex-start'
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {strings.aboutContactUsTitle}
          </Typography>

          <Stack spacing={2}>
            {/* In a perfect world, this button would show the Intercom messenger. However, certain JavaScript */}
            {/* blocking browser plugin/extension can prevent it from working normally. And there is no way to know */}
            {/* if it's being blocked and the methods on Intercom are fire and forget so there is no way if they */}
            {/* failed. So, the first button will always be a "mailto", while the second will try to open Intercom. */}
            {/* We also display a message below directing to use the Intercom button to open the live chat. */}
            <Button
              variant="contained"
              component="a"
              href={`mailto:support@studyo.co?subject=${LocalizedStrings.utils.supportSubject()}`}
              startIcon={<MailRounded />}
            >
              {strings.aboutContactUsButtonTitle}
            </Button>

            <Button variant="contained-grey" onClick={() => intercom.showChat()} startIcon={<QuestionAnswerRounded />}>
              {strings.aboutContactUsChatButtonTitle}
            </Button>
          </Stack>

          <FormHelperText sx={{ maxWidth: 400 }}>{strings.aboutContactUsHelperText}</FormHelperText>
        </Stack>

        <Box>
          <Box onClick={bumpClickCount}>
            <Logo style={{ width: 120, height: 'auto' }} />
          </Box>
          {showDemoModeConfirmation && (
            <ConfirmationDialog
              isOpen={true}
              title={strings.demoMode}
              message={
                isDemoMode ? strings.demoModeDisableConfirmationMessage : strings.demoModeEnableConfirmationMessage
              }
              confirmButtonLabel={
                isDemoMode
                  ? strings.demoModeDisableConfirmationButtonLabel
                  : strings.demoModeEnableConfirmationButtonLabel
              }
              onSubmit={onDemoModeConfirm}
            />
          )}
        </Box>
      </Stack>
    </SettingsContent>
  );
});
