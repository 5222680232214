import { DayOfWeek, UserDashboardInfo } from '@/models';
import { UserDataStore } from '@/stores';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import LocalizedStrings from 'strings';

export type ScheduleCycleKind = 'week' | 'multiple-week' | 'cycle-day';

export function getScheduleCycleKind(scheduleCycle: ScheduleCycle) {
  return scheduleCycle.isDayOfWeekAligned
    ? scheduleCycle.cycleDayCount === 7
      ? 'week'
      : 'multiple-week'
    : 'cycle-day';
}

export function titleForCycleDay(
  cycleDay: number,
  kind: ScheduleCycleKind,
  variant: 'long' | 'short' | 'single',
  includePrefix: boolean,
  cycleDayNames: string[]
) {
  const cycleDayIndex = cycleDay - 1;
  const cycleDayName = cycleDayIndex < cycleDayNames.length ? cycleDayNames[cycleDayIndex] || undefined : undefined;

  function dayOfWeekTitle(value: DayOfWeek) {
    const strings = LocalizedStrings.dateTime;
    switch (variant) {
      case 'long':
        return strings.dayOfWeekTitle[value]();
      case 'short':
        return strings.dayOfWeekShortTitle[value]();
      case 'single':
        return strings.dayOfWeekSingleLetterTitle[value]();
    }
  }

  if (kind === 'week') {
    return cycleDayName ?? dayOfWeekTitle(cycleDay);
  }

  if (kind === 'multiple-week') {
    if (cycleDayName) {
      return cycleDayName;
    }

    const dayValue: DayOfWeek = (cycleDayIndex % 7) + 1;
    const weekDay = dayOfWeekTitle(dayValue);
    const weekNumber = Math.floor(cycleDayIndex / 7) + 1;
    return `${weekDay} ${weekNumber}`;
  }

  const cycleDayAsString = cycleDayName ?? String(cycleDay);
  return includePrefix ? LocalizedStrings.scheduleCycle.cycleDayTitleWithPrefix(cycleDayAsString) : cycleDayAsString;
}

export function getUserDashboardForScheduleCycle(
  id: string,
  plannerId: string,
  userStore: UserDataStore
): UserDashboardInfo {
  const schools = userStore.schools;
  const matchedSchool = schools.find((s) => s.school?.scheduleCycleIds.includes(id) ?? false);

  if (matchedSchool != null) {
    return { id: matchedSchool.school!.id, kind: 'school' };
  } else {
    const planner = userStore.getPlannerForId(plannerId);

    if (!(planner?.scheduleCycleIds.includes(id) ?? false)) {
      throw new Error('No dashboard found for schedule cycle.');
    }

    return { id: plannerId, kind: 'planner' };
  }
}
