import { TimeOfDay, WorkIcons, dateToPBDate, dateToString, plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { PlannerCalendarStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UpdatableViewModelState } from '../../../UpdatableViewModel';
import {
  UserDashboardCalendarPeriodInfo,
  plannerCourseSectionOccurrenceToInfo,
  plannerNoteToInfo,
  plannerWorkToInfo,
  publishedWorkToInfo
} from '../items-info';
import {
  UserDashboardPeriodInfoPopoverViewItem,
  UserDashboardPeriodInfoPopoverViewModel
} from './UserDashboardPeriodInfoPopoverViewModel';

export class AppPlannerUserDashboardPeriodInfoPopoverViewModel implements UserDashboardPeriodInfoPopoverViewModel {
  @observable private _state: UpdatableViewModelState = undefined;
  private readonly _calendarStore: PlannerCalendarStore;

  constructor(
    private readonly _plannerId: string,
    private readonly _date: Date,
    private readonly _startTime: TimeOfDay,
    private readonly _endTime: TimeOfDay,
    private readonly _periodLabel: string,
    private readonly _courseSectionId: string | undefined,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _workStore = ServiceContainer.services.workStore,
    private readonly _dateService = ServiceContainer.services.dateService,
    private readonly _pasteboard = ServiceContainer.services.pasteboard,
    private readonly _featureFlag = ServiceContainer.services.featureFlag
  ) {
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    makeObservable(this);
  }

  @computed
  protected get courseSections(): CourseSectionDetails[] {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId).values;
  }

  @computed
  protected get courseSectionsById(): Map<string, CourseSectionDetails> {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId).data;
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  protected get workIcons(): WorkIcons {
    return this._workStore.workIcons.data;
  }

  @computed
  private get day(): PlannerDay | undefined {
    return this._calendarStore.days.get(dateToString(this._date));
  }

  @computed
  private get courseSectionOccurrence(): CourseSectionOccurrence {
    return this._calendarStore.getCourseSectionOccurrence(
      this._date,
      this._startTime,
      this._endTime,
      this._periodLabel,
      this._courseSectionId ?? ''
    );
  }

  @computed
  get occurrence(): UserDashboardCalendarPeriodInfo {
    return plannerCourseSectionOccurrenceToInfo(
      this.courseSectionOccurrence,
      this.courseSectionsById,
      this._date,
      this._dateService.now,
      this.canCreateWork,
      this.canPublishWork,
      this._pasteboard,
      this._featureFlag
    );
  }

  @computed
  get items(): UserDashboardPeriodInfoPopoverViewItem[] {
    return this._calendarStore.getItemsDueInOccurrence(this._date, this.courseSectionOccurrence).map((item) => {
      switch (item.case) {
        case 'note':
          return {
            case: 'note',
            value: plannerNoteToInfo(
              item.value,
              this.courseSectionsById,
              this._dateService.now,
              this._pasteboard,
              this._featureFlag
            )
          };

        case 'work':
          return {
            case: 'work',
            value: plannerWorkToInfo(
              item.value,
              this.courseSectionsById,
              this.workIcons,
              this._dateService.now,
              this.canCreateWork,
              this._pasteboard,
              this._workStore,
              this._plannerStore,
              this._featureFlag
            )
          };

        case 'publishedWork':
          return {
            case: 'publishedWork',
            value: publishedWorkToInfo(
              item.value,
              this.courseSectionsById,
              this.workIcons,
              this._dateService.now,
              this.canCreateWork,
              this._pasteboard,
              this._featureFlag
            )
          };
      }
    });
  }

  @computed
  get canCreateWork(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get canPublishWork(): boolean {
    return this._plannerStore.getCanPublishWorkInPlanner(this._plannerId);
  }

  readonly hasChanges = false;

  @computed
  get hasData(): boolean {
    return this.day != null;
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  readonly isSubmitting = false;

  async reloadData() {
    if (this.day == null) {
      runInAction(() => (this._state = 'pending'));
      try {
        await this._calendarStore.fetchDays(dateToPBDate(this._date), dateToPBDate(this._date));
        runInAction(() => (this._state = 'fulfilled'));
      } catch (e) {
        runInAction(() => (this._state = e as Error));
      }
    } else {
      runInAction(() => (this._state = 'fulfilled'));
    }
  }
}
