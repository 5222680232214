import { useAppBarColors, useServices } from '@/hooks';
import { ArrowBackRounded } from '@mui/icons-material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { AppBar, Button, IconButton, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { Logo, RefreshButton } from '../../utils';

export interface AdminAppBarProps {
  sx?: SxProps;
  className?: string;
  onDrawerToggleClick: () => void;
}

export const AdminAppBar = observer(({ sx = [], className, onDrawerToggleClick }: AdminAppBarProps) => {
  const { route, syncService, userStore } = useServices();
  const destinationPlannerId = userStore.idOfLastOwnedPlannerShowed;
  const destination = destinationPlannerId != null ? route.resolvePlannerLocation(destinationPlannerId) : undefined;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = LocalizedStrings.admin;

  const { backgroundColor, textColor } = useAppBarColors(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        ...sx,
        backgroundColor,
        color: textColor
      }}
      color={backgroundColor != null ? 'transparent' : 'primary'}
      className={className}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggleClick}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuRoundedIcon />
        </IconButton>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            flexGrow: 1,
            p: { xs: 0, sm: 1 },
            pr: 1,
            alignItems: 'center'
          }}
        >
          <Logo forcedVariant="dark" style={{ width: isExtraSmallScreen ? 35 : 90, height: 'auto' }} />

          {/* Not localized */}
          <Typography
            variant={isExtraSmallScreen ? 'caption' : 'body1'}
            noWrap
            component="div"
            sx={{
              fontWeight: '700',
              fontStyle: 'italic',
              flexGrow: 1
            }}
          >
            admin
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center'
          }}
        >
          <RefreshButton isRefreshing={syncService.isSyncing} reloadData={() => void syncService.sync()} />

          <Button
            color="inherit"
            size="small"
            component={Link}
            to={destination ?? '/'}
            variant="outlined"
            startIcon={<ArrowBackRounded />}
          >
            {isExtraSmallScreen ? strings.appBarGoBackToPlannerCompactButton() : strings.appBarGoBackToPlannerButton()}
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
});
