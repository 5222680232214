import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';

export interface ExPlannersStrings {
  readonly createButton: string;
  readonly createPlannerDialogTitle: string;
  readonly createPlannerErrorMessage: string;
  readonly createPlannerErrorTitle: string;
  readonly creationPlannerTitleLabel: string;
  readonly editionPlannerReadOnlyLabel: string;
  readonly editionPlannerTitleLabel: string;
  readonly renameButton: string;
  readonly renamePlannerDialogTitle: string;
  readonly renamePlannerErrorMessage: string;
  readonly renamePlannerErrorTitle: string;
  readonly settingsClassesTitle: string;
  readonly settingsEditPlannerTitle: string;
  readonly settingsGeneralSectionTitle: string;
  readonly settingsScheduleConfigurationTitle: string;
  readonly settingsShareTitle: string;
  readonly sideBarClassesTitle: string;
  readonly sideBarConfigureScheduleTitle: string;
  readonly sideBarEditPlannerTitle: string;
  readonly sideBarEmotionalSurveyOptionCompactTitle: string;
  readonly sideBarHomeTitle: string;
  readonly sideBarPendingRequestsTitle: string;
  readonly sideBarPendingRequestsTooltip: (count: number) => string;
  readonly sideBarPlanningTitle: string;
  readonly sideBarSharePlannerTitle: string;
  readonly targetKindSelectLabel: string;

  defaultPlannerTitlePlaceholder: (kind: PlannerRelationshipKind) => string;
  relationshipKindTooltip: (kind: PlannerRelationshipKind) => string;
  targetKindLabel: (kind: PlannerRelationshipKind) => string;
}

export const ExEnglishPlannersStrings: ExPlannersStrings = {
  createButton: 'Create',
  createPlannerDialogTitle: 'Create Planner',
  createPlannerErrorMessage:
    'An unexpected error occurred while creating the planner.\nPlease try again later or contact support if it persists.',
  createPlannerErrorTitle: 'Oops',
  creationPlannerTitleLabel: 'Customized planner title (optional)',
  editionPlannerReadOnlyLabel: 'You do not have the rights to edit this planner.',
  editionPlannerTitleLabel: 'Planner title (empty for default)',
  renameButton: 'Rename',
  renamePlannerDialogTitle: 'Rename Planner',
  renamePlannerErrorMessage:
    'An unexpected error occurred while renaming your planner.\nPlease try again later or contact support if it persists.',
  renamePlannerErrorTitle: 'Oops',
  settingsClassesTitle: 'Classes',
  settingsEditPlannerTitle: 'Edit planner',
  settingsGeneralSectionTitle: 'Planner settings',
  settingsScheduleConfigurationTitle: 'My schedules',
  settingsShareTitle: 'Planner access',
  sideBarClassesTitle: 'My classes',
  sideBarConfigureScheduleTitle: 'Configure my schedule',
  sideBarEditPlannerTitle: 'Edit planner',
  sideBarEmotionalSurveyOptionCompactTitle: "I'm feeling…",
  sideBarHomeTitle: 'Home',
  sideBarPendingRequestsTitle: 'Teacher requests',
  sideBarPendingRequestsTooltip: (count) => `${count} request${count != 1 ? 's' : ''} waiting from teacher`,
  sideBarPlanningTitle: 'Planner',
  sideBarSharePlannerTitle: 'Share…',
  targetKindSelectLabel: 'This new planner is for…',

  defaultPlannerTitlePlaceholder(kind): string {
    // These are the latest values returned by the backend.
    // a serious issue if they change.
    switch (kind) {
      case PlannerRelationshipKind.STUDENT:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.CREATOR:
        return 'Your planner';
      case PlannerRelationshipKind.PARENT:
        return "Your child's planner";
      case PlannerRelationshipKind.TEACHER:
        return "Your student's planner";
      default:
        console.error('Unexpected target kind');
        return '';
    }
  },
  relationshipKindTooltip(kind): string {
    switch (kind) {
      case PlannerRelationshipKind.STUDENT:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.CREATOR:
        return 'Student or individual user';
      case PlannerRelationshipKind.PARENT:
        return 'Parent';
      case PlannerRelationshipKind.TEACHER:
        return 'Teacher';
      default:
        console.error('Unexpected relationship kind');
        return '';
    }
  },
  targetKindLabel(kind): string {
    switch (kind) {
      case PlannerRelationshipKind.STUDENT:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.CREATOR:
        return 'Myself';
      case PlannerRelationshipKind.PARENT:
        return 'My child';
      case PlannerRelationshipKind.TEACHER:
        return 'My student';
      default:
        throw new Error('Unexpected target kind');
    }
  }
};

export const ExFrenchPlannersStrings: ExPlannersStrings = {
  createButton: 'Créer',
  createPlannerDialogTitle: 'Créer un planificateur',
  createPlannerErrorMessage:
    'Une erreur inattendue est survenue lors de la création du planificateur.\nVeuillez essayer un peu plus tard ou contactez le support si l’erreur persiste.',
  createPlannerErrorTitle: 'Oups',
  creationPlannerTitleLabel: 'Titre du planificateur (optionnel)',
  editionPlannerReadOnlyLabel: 'Vous ne possédez pas les droits nécessaires pour modifier ce planificateur',
  editionPlannerTitleLabel: 'Titre du planificateur (vide pour défaut)',
  renameButton: 'Renommer',
  renamePlannerDialogTitle: 'Renommer ce planificateur',
  renamePlannerErrorMessage:
    'Une erreur inattendue est survenue lors de la modification du planificateur.\nVeuillez essayer un peu plus tard ou contactez le support si l’erreur persiste.',
  renamePlannerErrorTitle: 'Oups',
  settingsClassesTitle: 'Cours',
  settingsEditPlannerTitle: 'Modifier le planificateur',
  settingsGeneralSectionTitle: 'Réglages du planificateur',
  settingsScheduleConfigurationTitle: 'Mes horaires',
  settingsShareTitle: 'Contrôler l’accès',
  sideBarClassesTitle: 'Mes cours',
  sideBarConfigureScheduleTitle: 'Configurer mon horaire',
  sideBarEditPlannerTitle: 'Modifier le planificateur',
  sideBarEmotionalSurveyOptionCompactTitle: 'Je me sens…',
  sideBarHomeTitle: 'Accueil',
  sideBarPendingRequestsTitle: 'Demandes d’enseignants',
  sideBarPendingRequestsTooltip: (count) => `${count} demande${count > 1 ? 's' : ''} d’enseignant en attente`,
  sideBarPlanningTitle: 'Planificateur',
  sideBarSharePlannerTitle: 'Partager…',
  targetKindSelectLabel: 'Ce nouveau planificateur est pour…',

  defaultPlannerTitlePlaceholder(kind): string {
    // These are the latest values returned by the backend. It's not
    // a serious issue if they change.
    switch (kind) {
      case PlannerRelationshipKind.STUDENT:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.CREATOR:
        return 'Votre planificateur';
      case PlannerRelationshipKind.PARENT:
        return 'Planificateur de votre enfant';
      case PlannerRelationshipKind.TEACHER:
        return 'Planificateur de votre élève';
      default:
        console.error('Unexpected target kind');
        return '';
    }
  },
  relationshipKindTooltip(kind): string {
    switch (kind) {
      case PlannerRelationshipKind.STUDENT:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.CREATOR:
        return 'Élève ou utilisateur individuel';
      case PlannerRelationshipKind.PARENT:
        return 'Parent';
      case PlannerRelationshipKind.TEACHER:
        return 'Enseignant';
      default:
        console.error('Unexpected relationship kind');
        return '';
    }
  },
  targetKindLabel(kind): string {
    switch (kind) {
      case PlannerRelationshipKind.STUDENT:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.CREATOR:
        return 'Moi-même';
      case PlannerRelationshipKind.PARENT:
        return 'Mon enfant';
      case PlannerRelationshipKind.TEACHER:
        return 'Mon élève';
      default:
        throw new Error('Unexpected target kind');
    }
  }
};
