import { ServicesContext } from '@/contexts';
import { ServiceContainer } from '@/providers';
import { ThemeVariant } from '@/services';
import { createTheme, CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { makeDarkMuiThemeOptions, makeLightMuiThemeOptions } from '../../resources/themes';
import { InitializingScreen } from '../shared';

export interface ServicesProviderProps {
  children?: ReactNode;
}

export const ServicesProvider = observer(({ children }: ServicesProviderProps) => {
  const colorScheme: ThemeVariant = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  const theme = createTheme(
    colorScheme === 'light' ? makeLightMuiThemeOptions(undefined, true) : makeDarkMuiThemeOptions(undefined, true)
  );

  return ServiceContainer.instance.isLoaded ? (
    <ServicesContext.Provider value={ServiceContainer.services}>{children}</ServicesContext.Provider>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* Sets the background color of the "root" div according to the current theme. */}
        {/*  Would otherwise show the body background color. */}
        <GlobalStyles styles={{ '#root': { backgroundColor: theme.palette.background.default } }} />

        <InitializingScreen />
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
