import { ArrowDropDownRounded, ErrorOutlineRounded, TaskAltRounded, WarningAmberRounded } from '@mui/icons-material';
import { ThemeOptions, alpha, darken, lighten } from '@mui/material';
import tinycolor from 'tinycolor2';
import { elevatedButtonVariants } from './ElevatedButtonVariant';

export const DefaultLightMuiThemePrimaryColor = '#10B1CB';
export const LightMuiThemeSecondaryColor = '#7C4CFF';
export const LightMuiThemeAppBarColor = '#000080';

export function makeLightMuiThemeOptions(
  customAccentColor: string | undefined,
  reduceTransparency: boolean
): ThemeOptions {
  const primaryColor = customAccentColor ?? DefaultLightMuiThemePrimaryColor;
  const contrastText = tinycolor.mostReadable(primaryColor, ['#000', '#FFF']).toHexString();

  return {
    palette: {
      mode: 'light',
      primary: {
        main: primaryColor,
        light: lighten(primaryColor, 0.15),
        dark: darken(primaryColor, 0.15),
        contrastText
      },
      secondary: {
        main: LightMuiThemeSecondaryColor,
        light: lighten(LightMuiThemeSecondaryColor, 0.15),
        dark: darken(LightMuiThemeSecondaryColor, 0.15),
        contrastText: '#FFF'
      }
    },
    shape: {
      borderRadius: 8
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    },
    mixins: {
      MuiDataGrid: {
        // Pinned columns sections
        pinnedBackground: 'rgba(0, 0, 0, 0)',
        // Headers, and top & bottom fixed rows
        containerBackground: 'rgba(0, 0, 0, 0)'
      }
    },
    components: {
      MuiAppBar: {
        defaultProps: {
          elevation: 2
        }
      },
      MuiPaper: {
        defaultProps: {
          elevation: 2
        },
        styleOverrides: {
          rounded: {
            borderRadius: 12
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined'
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'outlined',
          size: 'small'
        }
      },
      MuiInput: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
          IconComponent: ArrowDropDownRounded
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: ({ theme }) => ({
            minWidth: 250,
            ...(!reduceTransparency
              ? {
                  backgroundImage: 'none',
                  backgroundColor: alpha(theme.palette.background.paper, 0.85),
                  backdropFilter: 'blur(12px)'
                }
              : {})
          })
        }
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true
        },
        styleOverrides: {
          root: {
            paddingTop: 4,
            paddingBottom: 4,

            '& .MuiListItemIcon-root': {
              marginLeft: 0,
              marginRight: 12
            }
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      },
      MuiButton: {
        defaultProps: {
          disableTouchRipple: true,
          disableElevation: true
        },
        styleOverrides: {
          root: {
            variants: [...elevatedButtonVariants],
            textTransform: 'none'
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            ['&.rounded']: {
              borderRadius: 8
            }
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '0 !important', // Setting !important otherwise it's not applied in MenuItems.
            marginRight: 20
          }
        }
      },
      MuiAlert: {
        defaultProps: {
          iconMapping: {
            success: <TaskAltRounded color="inherit" fontSize="inherit" />,
            warning: <WarningAmberRounded color="inherit" fontSize="inherit" />,
            error: <ErrorOutlineRounded color="inherit" fontSize="inherit" />
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            // So that snackbars are displayed behind dialogs (1300).
            zIndex: 1200
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      }
    }
  };
}
