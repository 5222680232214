import i18n from 'translate';
import { Currency } from '../../models';
import { Locale } from '../../utils';

export const SubscriptionsManageStrings = {
  cadPriceOptionTitle: () => i18n.t('subscriptions.manage.cadPriceOptionTitle'),
  legalText: () => i18n.t('subscriptions.manage.legalText'),
  message: () => i18n.t('subscriptions.manage.message'),
  title: () => i18n.t('subscriptions.manage.title'),
  usdPriceOptionTitle: () => i18n.t('subscriptions.manage.usdPriceOptionTitle')
};

export const SubscriptionsProductStrings = {
  currentSubscription: () => i18n.t('subscriptions.product.currentSubscription'),
  expiredTrialSubscription: () => i18n.t('subscriptions.product.expiredTrialSubscription'),
  formattedMonthlyPrice: (price: number, currency: Currency, locale: Locale) => {
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      minimumFractionDigits: 2,
      style: 'currency'
    });

    return i18n.t('subscriptions.product.formattedMonthlyPrice', { price: formatter.format(price) });
  },
  formattedYearlyPrice: (price: number, currency: Currency, locale: Locale) => {
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      minimumFractionDigits: 2,
      style: 'currency'
    });

    return i18n.t('subscriptions.product.formattedYearlyPrice', { price: formatter.format(price) });
  },
  freeTrialLabel: () => i18n.t('subscriptions.product.freeTrialLabel'),
  freeTrialSpecialOfferLabel: () => i18n.t('subscriptions.product.freeTrialSpecialOfferLabel'),
  manageSubscription: () => i18n.t('subscriptions.product.manageSubscription'),
  manageSubscriptionErrorMessage: (error: string) =>
    i18n.t('subscriptions.product.manageSubscriptionErrorMessage', { error }),
  noCreditCardRequired: () => i18n.t('subscriptions.product.noCreditCardRequired'),
  schoolSelectionMessage: () => i18n.t('subscriptions.product.schoolSelectionMessage'),
  schoolSelectionSubmitLabel: () => i18n.t('subscriptions.product.schoolSelectionSubmitLabel'),
  shareSchoolConfirmationMessage: (schoolName: string) =>
    i18n.t('subscriptions.product.shareSchoolConfirmationMessage', { schoolName }),
  shareSchoolConfirmationMoveScheduleOption: (scheduleName: string) =>
    i18n.t('subscriptions.product.shareSchoolConfirmationMoveScheduleOption', { scheduleName }),
  subscribeButtonLabel: () => i18n.t('subscriptions.product.subscribeButtonLabel'),
  subscribeError: () => i18n.t('subscriptions.product.subscribeError'),
  trialDaysRemaining: (daysRemaining: number) =>
    i18n.t('subscriptions.product.trialDaysRemaining', { count: daysRemaining }),
  upcomingPriceLabel: (formattedPrice: string) => i18n.t('subscriptions.product.upcomingPriceLabel', { formattedPrice })
};

export const SubscriptionsSchoolEntitlementBannerStrings = {
  expiredSubscription: (schoolName: string) =>
    i18n.t('subscriptions.schoolEntitlementBanner.expiredSubscription', { schoolName }),
  expiredTrial: (schoolName: string) => i18n.t('subscriptions.schoolEntitlementBanner.expiredTrial', { schoolName }),
  invalidSubscription: (schoolName: string) =>
    i18n.t('subscriptions.schoolEntitlementBanner.invalidSubscription', { schoolName }),
  limitsExceeded: (schoolName: string) =>
    i18n.t('subscriptions.schoolEntitlementBanner.limitsExceeded', { schoolName }),
  manageSubscriptionsButtonTitle: () => i18n.t('subscriptions.schoolEntitlementBanner.manageSubscriptionsButtonTitle'),
  noSubscription: (schoolName: string) =>
    i18n.t('subscriptions.schoolEntitlementBanner.noSubscription', { schoolName }),
  subscribeButtonTitle: () => i18n.t('subscriptions.schoolEntitlementBanner.subscribeButtonTitle'),
  trial: (schoolName: string) => i18n.t('subscriptions.schoolEntitlementBanner.trial', { schoolName })
};

export const SubscriptionsStrings = {
  manage: SubscriptionsManageStrings,
  product: SubscriptionsProductStrings,
  schoolEntitlementBanner: SubscriptionsSchoolEntitlementBannerStrings
};
