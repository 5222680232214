import i18n from 'translate';
import { OnboardingTeacherOptionsStrings } from './OnboardingTeacherOptionsStrings';

export const LinkPlannerToSchoolAutoStrings = {
  errorMessage: () => i18n.t('onboarding.link.auto.errorMessage'),
  retryButtonTitle: () => i18n.t('onboarding.link.auto.retryButtonTitle')
};

export const LinkPlannerToSchoolFormStrings = {
  errorMessage: () => i18n.t('onboarding.link.form.errorMessage'),
  plannerSelectLabel: () => i18n.t('onboarding.link.form.plannerSelectLabel'),
  schoolSelectLabel: () => i18n.t('onboarding.link.form.schoolSelectLabel'),
  signOutButtonTitle: () => i18n.t('onboarding.link.form.signOutButtonTitle'),
  submitButtonTitle: () => i18n.t('onboarding.link.form.submitButtonTitle'),
  subtitle: () => i18n.t('onboarding.link.form.subtitle'),
  title: () => i18n.t('onboarding.link.form.title')
};

export const LinkPlannerToSchoolStrings = {
  auto: LinkPlannerToSchoolAutoStrings,
  form: LinkPlannerToSchoolFormStrings
};

export const OnboardingClassroomStrings = {
  connectErrorMessage: () => i18n.t('onboarding.classroom.connectErrorMessage'),
  messagePlanner: () => i18n.t('onboarding.classroom.messagePlanner'),
  messageSchool: () => i18n.t('onboarding.classroom.messageSchool'),
  retryFetchSyncStatusButtonLabel: () => i18n.t('onboarding.classroom.retryFetchSyncStatusButtonLabel'),
  skipButtonLabel: () => i18n.t('onboarding.classroom.skipButtonLabel'),
  skipMessage: () => i18n.t('onboarding.classroom.skipMessage'),
  subtitle: () => i18n.t('onboarding.classroom.subtitle'),
  syncStatusFetchErrorMessage: () => i18n.t('onboarding.classroom.syncStatusFetchErrorMessage'),
  title: () => i18n.t('onboarding.classroom.title')
};

export const OnboardingSubscriptionsStrings = {
  backButtonTitle: () => i18n.t('onboarding.subscriptions.backButtonTitle'),
  cadPriceOptionTitle: () => i18n.t('onboarding.subscriptions.cadPriceOptionTitle'),
  legalText: () => i18n.t('onboarding.subscriptions.legalText'),
  title: () => i18n.t('onboarding.subscriptions.title'),
  usdPriceOptionTitle: () => i18n.t('onboarding.subscriptions.usdPriceOptionTitle')
};

export const OnboardingTeacherUseCode = {
  backButtonTitle: () => i18n.t('onboarding.teacherUseCode.backButtonTitle'),
  submitButtonTitle: () => i18n.t('onboarding.teacherUseCode.submitButtonTitle'),
  textFieldLabel: () => i18n.t('onboarding.teacherUseCode.textFieldLabel'),
  title: () => i18n.t('onboarding.teacherUseCode.title')
};

export const OnboardingStrings = {
  classroom: OnboardingClassroomStrings,
  link: LinkPlannerToSchoolStrings,
  subscriptions: OnboardingSubscriptionsStrings,
  teacherOptions: OnboardingTeacherOptionsStrings,
  teacherUseCode: OnboardingTeacherUseCode
};
