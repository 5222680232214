import i18n from 'translate';

export const AdminAccountClassesListStrings = {
  noSearchResultsMessage: (searchText: string) =>
    i18n.t('admin.classes.accountList.noSearchResultsMessage', { searchText }),
  studentEmptyMessage: () => i18n.t('admin.classes.accountList.studentEmptyMessage'),
  studentTitle: () => i18n.t('admin.classes.accountList.studentTitle'),
  teacherEmptyMessage: () => i18n.t('admin.classes.accountList.teacherEmptyMessage'),
  teacherTitle: () => i18n.t('admin.classes.accountList.teacherTitle')
};

export const AdminClassesDetailsStrings = {
  editTeacherScheduleOption: () => i18n.t('admin.classes.details.editTeacherScheduleOption'),
  externalSource: (externalSource: string) => i18n.t('admin.classes.details.externalSource', { externalSource }),
  sendEmailToTeacherOption: () => i18n.t('admin.classes.details.sendEmailToTeacherOption'),
  showTeacherDetailsOption: () => i18n.t('admin.classes.details.showTeacherDetailsOption'),
  studentEmailColumnHeader: () => i18n.t('admin.classes.details.studentEmailColumnHeader'),
  studentListEmptyTitle: () => i18n.t('admin.classes.details.studentListEmptyTitle'),
  studentNameColumnHeader: () => i18n.t('admin.classes.details.studentNameColumnHeader'),
  studentShowDetailsOption: () => i18n.t('admin.classes.details.studentShowDetailsOption'),
  studentsCardTitle: () => i18n.t('admin.classes.details.studentsCardTitle'),
  studentsCount: () => i18n.t('admin.classes.details.studentsCount'),
  teachersCardTitle: () => i18n.t('admin.classes.details.teachersCardTitle'),
  teachersCount: () => i18n.t('admin.classes.details.teachersCount')
};

export const AdminClassesListStrings = {
  courseTitleColumnHeader: () => i18n.t('admin.classes.list.courseTitleColumnHeader'),
  editScheduleAction: () => i18n.t('admin.classes.list.editScheduleAction'),
  emptySubtitle: () => i18n.t('admin.classes.list.emptySubtitle'),
  emptyTitle: () => i18n.t('admin.classes.list.emptyTitle'),
  sectionColumnHeader: () => i18n.t('admin.classes.list.sectionColumnHeader'),
  showDetailsAction: () => i18n.t('admin.classes.list.showDetailsAction'),
  studentCountColumnHeader: () => i18n.t('admin.classes.list.studentCountColumnHeader'),
  teachersColumnHeader: () => i18n.t('admin.classes.list.teachersColumnHeader'),
  teachersNoneLabel: () => i18n.t('admin.classes.list.teachersNoneLabel'),
  title: () => i18n.t('admin.classes.list.title')
};

export const AdminClassesStrings = {
  accountList: AdminAccountClassesListStrings,
  details: AdminClassesDetailsStrings,
  list: AdminClassesListStrings
};
