import { useActivePlannerId, useServices } from '@/hooks';
import { timeOfDayToDate } from '@/models';
import {
  UserDashboardCalendarDayTimedPublishedWorkInfo,
  UserDashboardCalendarDayTimedWorkInfo,
  UserDashboardCalendarItemState,
  UserDashboardCalendarPublishedWorkInfo,
  UserDashboardCalendarWorkInfo
} from '@/viewmodels';
import { CardActionArea, ListItemText, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ColorIndicator, WorkIcon } from '../../../lists';
import { ContextMenuHandler } from '../../ContextMenuHandler';

function isTimedWork(
  work:
    | UserDashboardCalendarWorkInfo
    | UserDashboardCalendarDayTimedWorkInfo
    | UserDashboardCalendarPublishedWorkInfo
    | UserDashboardCalendarDayTimedPublishedWorkInfo
): work is UserDashboardCalendarDayTimedWorkInfo | UserDashboardCalendarDayTimedPublishedWorkInfo {
  return (work as UserDashboardCalendarDayTimedWorkInfo)?.startTime != null;
}

export interface UserDashboardCalendarDayWorkViewProps {
  sx?: SxProps;
  className?: string;
  work:
    | { case: 'work'; value: UserDashboardCalendarDayTimedWorkInfo | UserDashboardCalendarWorkInfo }
    | {
        case: 'publishedWork';
        value: UserDashboardCalendarPublishedWorkInfo | UserDashboardCalendarDayTimedPublishedWorkInfo;
      };
  disabled?: boolean;
  showTime: boolean;
}

export const UserDashboardCalendarDayWorkView = observer(
  ({ sx = [], className, work, disabled = false, showTime }: UserDashboardCalendarDayWorkViewProps) => {
    const { route, settings } = useServices();
    const plannerId = useActivePlannerId();
    const location = useLocation();
    const navigate = useNavigate();

    function showWorkDetails() {
      const newLocation =
        work.case === 'work'
          ? route.resolvePlannerWorkLocation(plannerId, work.value.id)
          : route.resolvePublishedWorkLocation(plannerId, work.value.id, work.value.schoolId);

      navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={work.value.contextMenuActions()}>
        {(contextMenuHandler) => (
          <CardActionArea
            sx={{
              ...sx,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'stretch',
              p: 1,
              borderRadius: 1,
              overflow: 'hidden'
            }}
            className={className}
            disabled={disabled}
            onClick={showWorkDetails}
            onContextMenu={contextMenuHandler}
          >
            <ColorIndicator color={work.value.color} isRounded sx={{ mr: 1 }} />

            <Stack
              spacing={1}
              direction="row"
              sx={{
                alignItems: work.value.description.length > 0 ? 'flex-start' : 'center',
                flex: 1,
                overflow: 'hidden'
              }}
            >
              <WorkIcon icon={work.value.icon} size={32} />

              <ListItemText
                sx={{ m: 0, p: 0 }}
                primary={work.value.title}
                primaryTypographyProps={{
                  noWrap: !settings.calendarDayOptions.expandWorkDetails,
                  fontWeight: '500',
                  color: getColorForTitle(work.value.state)
                }}
                secondary={work.value.description}
                secondaryTypographyProps={{ noWrap: !settings.calendarDayOptions.expandWorkDetails }}
              />

              {showTime && isTimedWork(work.value) && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {format(timeOfDayToDate(work.value.startTime), 'p')}
                </Typography>
              )}
            </Stack>
          </CardActionArea>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
