import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { AddRounded, DomainAddRounded, YoutubeSearchedForRounded } from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices, useViewModel } from '../../hooks';
import { CreateSchoolDialog } from '../school';
import { AdminContentCard } from './shared';

interface LocationState {
  adminSchoolsListShowCreateSchool?: boolean;
}

export interface AdminSchoolsListProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolsList = observer(({ sx = [], className }: AdminSchoolsListProps) => {
  const { route, userStore } = useServices();
  const viewModel = useViewModel((viewModels) => viewModels.createAdminSchoolsListViewModel(), []);
  const { schools, filteredSchools, searchText } = viewModel;
  const theme = useTheme();
  const strings = LocalizedStrings.admin.schoolsList;

  const canCreateSchool = [UserPersona.TEACHER, UserPersona.OTHER].includes(userStore.user.persona);

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();

  function showSchoolCreationDialog(e: MouseEvent<HTMLButtonElement>) {
    e.currentTarget.blur();
    const newState: LocationState = { ...state, adminSchoolsListShowCreateSchool: true };
    navigate(location, { state: newState });
  }

  function onCreateSchoolClose(schoolId: string | undefined) {
    navigate(-1);

    if (schoolId != null) {
      navigate(route.resolveAdminSchoolInformationLocation(schoolId));
    }
  }

  return (
    <Box sx={sx} className={className}>
      <AdminContentCard
        title={strings.title()}
        subtitle={strings.subtitle()}
        searchValue={searchText}
        onSearch={(v) => (viewModel.searchText = v)}
        actions={[
          {
            label: { case: 'icon', value: <AddRounded /> },
            onPress: (e) => showSchoolCreationDialog(e),
            requiresActiveSubscription: true,
            isHidden: !canCreateSchool
          }
        ]}
      >
        <List>
          {filteredSchools.map((school) => (
            <ListItemButton
              key={school.school!.id}
              component={Link}
              to={route.resolveAdminSchoolInformationLocation(school.school!.id)}
            >
              <ListItemText primary={school.school!.name} secondary={school.school!.subtitle} />
            </ListItemButton>
          ))}

          {schools.length === 0 && (
            <ListItem>
              <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
                <DomainAddRounded color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={strings.emptyMessage()}
                primaryTypographyProps={{ color: theme.palette.text.secondary }}
              />
            </ListItem>
          )}

          {schools.length > 0 && filteredSchools.length === 0 && (
            <ListItem>
              <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
                <YoutubeSearchedForRounded color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={strings.noResultsMessage(searchText)}
                primaryTypographyProps={{ color: theme.palette.text.secondary }}
              />
            </ListItem>
          )}
        </List>
      </AdminContentCard>

      {state.adminSchoolsListShowCreateSchool === true && (
        <CreateSchoolDialog isOpen={true} onClose={onCreateSchoolClose} />
      )}
    </Box>
  );
});
