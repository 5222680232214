import { RouteTemplates } from '@/RouteTemplates';
import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { UserDashboardKind } from '@/models';
import { ArrowForwardIosRounded, MailRounded, QuestionAnswerRounded } from '@mui/icons-material';
import UseCodeIcon from '@mui/icons-material/AbcRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import ChangeEmotionalStatusIcon from '@mui/icons-material/EmojiEmotionsRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import SubscriptionIcon from '@mui/icons-material/PaymentRounded';
import PreferencesIcon from '@mui/icons-material/TuneRounded';
import TutorialsIcon from '@mui/icons-material/YouTube';
import {
  Button,
  CircularProgress,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { CreatePlannerDialog } from '../../planner';
import { CreateSchoolDialog } from '../../school';
import { StudentEmotionalSurveyCard } from '../../student';
import { SubscriptionsDialog } from '../../subscriptions';
import { UserProfileIcon } from '../UserProfileIcon';
import { UseSharingInvitationCodeDialog, UserDashboardAddMenu, UserDashboardPicker } from '../user-dashboard-picker';

interface LocationState {
  profileMenuShowUseCode?: string;
  profileMenuShowCreatePlanner?: string;
  profileMenuShowCreateSchool?: string;
  profileMenuShowSubscribe?: boolean;
}

export interface ProfileMenuProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: (actionPressed: boolean) => void;
  anchorEl: HTMLElement | null;
  id: string;
}

export const ProfileMenu = observer(({ anchorEl, isOpen, onClose, id }: ProfileMenuProps) => {
  const { localization, route } = useServices();
  const theme = useTheme();
  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();
  const strings = localization.localizedStrings.utils;

  const [showAddMenu, setShowAddMenu] = useState(false);
  const createDashboardOptionRef = useRef<HTMLLIElement | null>(null);

  const [dashboardPickerRef, setDashboardPickerRef] = useState<HTMLLIElement | null>(null);
  const [emotionalStatusRef, setEmotionalStatusRef] = useState<HTMLLIElement | null>(null);

  const plannerId = useActivePlannerId();
  const viewModel = useViewModel((vms) => vms.createProfileMenuViewModel(plannerId), [plannerId]);

  function onOptionClick(action: (() => void) | undefined) {
    return () => {
      onClose(true);
      action?.();
    };
  }

  function showUseSharingCode() {
    const newState: LocationState = { ...state, profileMenuShowUseCode: id };
    navigate(location, { state: newState });
  }

  function onCreateOptionClick() {
    if (viewModel.canCreateSchool) {
      setShowAddMenu(true);
    } else {
      onClose(true);
      const newState: LocationState = { ...state, profileMenuShowCreatePlanner: id };
      navigate(location, { state: newState });
    }
  }

  function onCreateDashboardClick(kind: UserDashboardKind) {
    setShowAddMenu(false);
    onClose(true);

    const newState: LocationState = {
      ...state,
      profileMenuShowCreatePlanner: kind === 'planner' ? id : undefined,
      profileMenuShowCreateSchool: kind === 'school' ? id : undefined
    };

    navigate(location, { state: newState });
  }

  function subscribe() {
    const newState: LocationState = {
      ...state,
      profileMenuShowSubscribe: true
    };

    navigate(location, { state: newState });
  }

  async function logout() {
    await viewModel.logout();
    navigate(RouteTemplates.welcome, { replace: true });
  }

  return (
    <>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={() => onClose(false)}
      >
        <Stack spacing={0.5} sx={{ width: 360, pb: 1 }}>
          <Stack spacing={1} sx={{ px: 1.5, pt: 1, pb: 0 }}>
            <ListItem disableGutters disablePadding>
              <ListItemAvatar>
                <UserProfileIcon
                  url={viewModel.imageUrl}
                  userFullName={viewModel.fullName}
                  emotionalStatusEmoji={viewModel.emotionalStatusEmoji}
                />
              </ListItemAvatar>
              <ListItemText primary={viewModel.fullName} secondary={viewModel.email} />
            </ListItem>

            <Button
              size="small"
              sx={{ alignSelf: 'flex-start' }}
              component={Link}
              to={route.resolvePlannerSettingsLocation('profile', plannerId)}
              onClick={onOptionClick(undefined)}
            >
              {strings.manageAccountLabel}
            </Button>
          </Stack>

          <Divider />

          <Stack>
            <MenuItem onClick={(e) => setDashboardPickerRef(e.currentTarget)}>
              <ListItemText>{strings.changeActiveDashboardOptionTitle}</ListItemText>
              <ArrowForwardIosRounded sx={{ width: 16, color: theme.palette.text.secondary }} />
            </MenuItem>

            {viewModel.canChangeEmotionalStatus && (
              <MenuItem onClick={(e) => setEmotionalStatusRef(e.currentTarget)}>
                <ListItemIcon>
                  <ChangeEmotionalStatusIcon />
                </ListItemIcon>
                <ListItemText>{strings.changeEmotionalStatusOptionTitle}</ListItemText>
              </MenuItem>
            )}
          </Stack>

          <Divider />

          <Stack>
            {viewModel.canSubscribe && (
              <MenuItem onClick={onOptionClick(subscribe)}>
                <ListItemIcon>
                  <SubscriptionIcon />
                </ListItemIcon>
                <ListItemText>{strings.subscribeLabel}</ListItemText>
              </MenuItem>
            )}

            {viewModel.canManageSubscription && (
              <MenuItem onClick={onOptionClick(() => void viewModel.manageSubscription())}>
                <ListItemIcon>
                  {viewModel.isApplying ? <CircularProgress size={20} /> : <SubscriptionIcon />}
                </ListItemIcon>
                <ListItemText>{strings.manageSubscriptionLabel}</ListItemText>
              </MenuItem>
            )}

            <MenuItem
              dense
              onClick={onOptionClick(undefined)}
              component={Link}
              to={route.resolvePlannerSettingsLocation('preferences', plannerId)}
            >
              <ListItemIcon>
                <PreferencesIcon />
              </ListItemIcon>
              <ListItemText>{strings.preferencesLabel}</ListItemText>
            </MenuItem>

            <MenuItem dense onClick={onOptionClick(showUseSharingCode)}>
              <ListItemIcon>
                <UseCodeIcon />
              </ListItemIcon>
              <ListItemText>{strings.useSharingInvitationCodeLabel}</ListItemText>
            </MenuItem>

            <MenuItem dense onClick={onCreateOptionClick} ref={createDashboardOptionRef}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>
                {viewModel.canCreateSchool ? strings.createDashboardMenuLabel : strings.createPlannerMenuLabel}
              </ListItemText>
            </MenuItem>
          </Stack>

          <Divider />

          <Stack>
            <MenuItem dense onClick={onOptionClick(() => viewModel.openSupportEmail())}>
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText>{strings.sendSupportEmail}</ListItemText>
            </MenuItem>

            <MenuItem dense onClick={onOptionClick(() => viewModel.openIntercom())}>
              <ListItemIcon>
                <QuestionAnswerRounded />
              </ListItemIcon>
              <ListItemText>{strings.openIntercom}</ListItemText>
            </MenuItem>

            <MenuItem
              dense
              onClick={onOptionClick(undefined)}
              component="a"
              href={strings.tutorialsLink}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemIcon>
                <TutorialsIcon />
              </ListItemIcon>
              <ListItemText>{strings.tutorialsLabel}</ListItemText>
            </MenuItem>

            <MenuItem dense onClick={onOptionClick(() => void logout())}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: theme.palette.error.main }} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ color: 'error' }}>{strings.logoutLabel}</ListItemText>
            </MenuItem>
          </Stack>
        </Stack>
      </Popover>

      {state.profileMenuShowUseCode === id && <UseSharingInvitationCodeDialog isOpen={true} />}
      {state.profileMenuShowCreatePlanner === id && <CreatePlannerDialog isOpen={true} />}
      {state.profileMenuShowCreateSchool === id && <CreateSchoolDialog isOpen={true} />}
      {state.profileMenuShowSubscribe === true && <SubscriptionsDialog isOpen={true} />}

      <UserDashboardPicker
        elevation={2}
        id={id}
        isOpen={dashboardPickerRef != null}
        anchorEl={dashboardPickerRef}
        onClose={() => setDashboardPickerRef(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      />

      <UserDashboardAddMenu
        anchorEl={createDashboardOptionRef.current}
        isOpen={showAddMenu}
        onCreate={onCreateDashboardClick}
        onClose={() => setShowAddMenu(false)}
      />

      <Popover
        open={emotionalStatusRef != null}
        anchorEl={emotionalStatusRef}
        slotProps={{ paper: { sx: { width: 240 }, elevation: 2 } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setEmotionalStatusRef(null)}
      >
        <StudentEmotionalSurveyCard canDismiss={false} close={() => setEmotionalStatusRef(null)} />
      </Popover>
    </>
  );
});
