import { inRange } from 'lodash';

export enum StudentEmotionalStatus {
  SAD,
  OK,
  HAPPY
}

export function emotionalPulseRatingToStatus(rating: number): StudentEmotionalStatus {
  if (rating < 0 || rating > 100) {
    throw new Error('Rating should be a numeric value between 0 and 100 inclusively.');
  }

  switch (true) {
    case inRange(rating, 0, 34):
      return StudentEmotionalStatus.SAD;

    case inRange(rating, 34, 67):
      return StudentEmotionalStatus.OK;

    default:
      return StudentEmotionalStatus.HAPPY;
  }
}

export function emotionalPulseRatingFromStatus(status: StudentEmotionalStatus | undefined): number {
  if (status == null) {
    return -1;
  }

  switch (status) {
    case StudentEmotionalStatus.SAD:
      return 0;
    case StudentEmotionalStatus.OK:
      return 50;
    case StudentEmotionalStatus.HAPPY:
      return 100;
  }
}

export function emojiForEmotionalPulseRating(status: StudentEmotionalStatus | undefined): string {
  if (status == null) {
    return '';
  }

  switch (status) {
    case StudentEmotionalStatus.SAD:
      return '🙁';
    case StudentEmotionalStatus.OK:
      return '😐';
    case StudentEmotionalStatus.HAPPY:
      return '😃';
  }
}
