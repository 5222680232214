import { UserTransportService } from '@/transports';
import { TeacherPreferences } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_preferences_pb';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type TeacherPreferencesLoadable = LoadableValue<TeacherPreferences>;

export class AppTeacherPreferencesLoadable
  extends BaseLoadableValue<TeacherPreferences>
  implements TeacherPreferencesLoadable
{
  constructor(private readonly _userTransport: UserTransportService) {
    super();
  }

  protected async loadData(): Promise<TeacherPreferences> {
    return await this._userTransport.getTeacherPreferences();
  }
}
