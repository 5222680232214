import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode } from 'react';
import { isSxArray } from '../../../utils';

export interface WorkDetailsCardHeaderProps {
  sx?: SxProps;
  className?: string;
  icon: ReactElement;
  title: string;
  subtitle: string;
  rightElement?: () => ReactNode;
}

export const WorkDetailsCardHeader = observer(
  ({ sx = [], className, icon, subtitle, rightElement, title }: WorkDetailsCardHeaderProps) => {
    return (
      <Stack
        className={className}
        direction="row"
        spacing={1.5}
        sx={[
          {
            alignItems: 'flex-start'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Box
          sx={{
            pt: 0.5
          }}
        >
          {icon}
        </Box>
        <Stack
          sx={{
            flex: 1
          }}
        >
          <Typography variant="subtitle2">{title}</Typography>
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
        {rightElement?.()}
      </Stack>
    );
  }
);
