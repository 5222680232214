import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { RichText } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { isSameDay, isSameMinute } from 'date-fns';
import { computed, makeObservable } from 'mobx';
import { Timestamp } from '../Timestamp';
import { EditableModel, EditableProperty } from './core';

export class EditableNote extends EditableModel<Note> {
  private _text: EditableProperty<RichText>;
  private _time: EditableProperty<Date>;
  private _isAllDay: EditableProperty<boolean>;
  private _courseSectionId: EditableProperty<string | undefined>;

  constructor(
    private readonly _plannerId: string,
    defaultValues: PartialMessage<Note> | undefined,
    private readonly _note?: Note
  ) {
    super(
      _note == null,
      _note ??
        new Note({
          plannerId: _plannerId,
          courseSectionId: '',
          text: undefined,
          isAllDay: true,
          time: undefined,
          ...defaultValues
        })
    );

    makeObservable(this);

    this.setFields([
      (this._text = new EditableProperty<RichText>(this.initialModel.text, (v1, v2) => (v1 ?? '') === (v2 ?? ''))),
      (this._time = new EditableProperty<Date>(this.initialModel.time?.toDate(), (value1, value2) => {
        if (value1 == null && value2 == null) {
          return true;
        }

        if (value1 != null && value2 != null) {
          return this.isAllDay ? isSameDay(value1, value2) : isSameMinute(value1, value2);
        }

        return false;
      })),
      (this._isAllDay = new EditableProperty<boolean>(this.initialModel.isAllDay)),
      (this._courseSectionId = new EditableProperty<string | undefined>(this.initialModel.courseSectionId))
    ]);
  }

  // Editable properties

  @computed
  get text(): RichText | undefined {
    return this._text.value;
  }

  set text(value: RichText | undefined) {
    this._text.value = value;
  }

  @computed
  get time(): Date | undefined {
    return this._time.value;
  }

  set time(value: Date | undefined) {
    this._time.value = value;
  }

  @computed
  get isAllDay(): boolean {
    return this._isAllDay.value ?? false;
  }

  set isAllDay(value: boolean) {
    this._isAllDay.value = value;
  }

  @computed
  get courseSectionId(): string | undefined {
    return this._courseSectionId.value;
  }

  set courseSectionId(value: string | undefined) {
    this._courseSectionId.value = value;
  }

  @computed
  get updatedModel(): Note {
    return new Note({
      ...this.initialModel,
      text: this._text.value,
      time: this._time.value != null ? Timestamp.fromDate(this._time.value) : undefined,
      isAllDay: this._isAllDay.value,
      courseSectionId: this._courseSectionId.value
    });
  }
}
