import { ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { SchoolSettingsDangerAction } from '../../school';
import { ConfirmationDialog } from '../../utils';

export interface AdminArchiveStateSchoolActionProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminArchiveStateSchoolAction = observer(
  ({ sx = [], className, schoolId }: AdminArchiveStateSchoolActionProps) => {
    const { userStore } = useServices();
    const school = userStore.getSchoolForId(schoolId)!;
    const isArchived = school.school!.isArchived;
    const strings = isArchived
      ? LocalizedStrings.admin.general.manageActions.unarchive
      : LocalizedStrings.admin.general.manageActions.archive;

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isTogglingState, setIsTogglingState] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    async function onConfirmation(hasConfirmed: boolean) {
      setShowConfirmation(false);

      if (hasConfirmed) {
        setIsTogglingState(true);
        setError(null);

        try {
          await (isArchived ? userStore.unarchiveSchool(schoolId) : userStore.archiveSchool(schoolId));
        } catch (error) {
          setError(error as Error);
          captureException(error);
          setIsTogglingState(false);
        }
      }
    }

    return (
      <Box sx={sx} className={className}>
        <SchoolSettingsDangerAction
          title={strings.title()}
          subtitle={strings.subtitle()}
          buttonTitle={strings.actionTitle()}
          buttonIcon={isArchived ? <UnarchiveRounded /> : <ArchiveRounded />}
          action={() => setShowConfirmation(true)}
          disabled={isTogglingState}
          isLoading={isTogglingState}
          error={error != null ? strings.errorMessage() : undefined}
        />

        {showConfirmation && (
          <ConfirmationDialog
            isOpen={true}
            title={strings.confirmationTitle()}
            message={strings.confirmationMessage(school.school!.name)}
            confirmButtonLabel={strings.confirmationSubmit()}
            isDestructive={!isArchived}
            onSubmit={(hasConfirmed) => void onConfirmation(hasConfirmed)}
          />
        )}
      </Box>
    );
  }
);
