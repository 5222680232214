import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';

export interface SharingInvitationCodeStrings {
  readonly anonymousUserEmail: string;
  readonly anonymousUserFullName: string;
  readonly cancelButton: string;
  readonly currentUserLabel: string;
  readonly currentUsersHeader: string;
  readonly invalidCodeError: string;
  readonly noMatchingPlannerAlertMessage: string;
  readonly noMatchingPlannerAlertTitle: string;
  readonly participationCodeConfirmationAlertConfirmButtonTitle: string;
  participationCodeConfirmationAlertMessage(senderName?: string): string;
  readonly participationCodeConfirmationAlertTitle: string;
  readonly pickerLabel: string;
  readonly readOnlyLabel: string;
  readonly shareButton: string;
  readonly sharePlannerDialogConfirmButton: string;
  readonly sharePlannerLoadingMessage: string;
  readonly sharePlannerStatusLoadingMessage: string;
  readonly shareSectionTitle: string;
  readonly useCodeButton: string;
  readonly useCodeDialogTitle: string;
  readonly useCodeFieldLabel: string;
  readonly useCodeHeader: string;

  sharePlannerLoadingErrorMessage(error: string): string;
  sharePlannerStatusLoadingErrorMessage(error: string): string;
  sharePlannerText(userRelationship: PlannerRelationshipKind, targetRelationship: PlannerRelationshipKind): string;
  sharePlannerTitle(userRelationship: PlannerRelationshipKind): string;
  sharePlannerTeacherOption: string;
  sharePlannerAnotherTeacherOption: string;
  sharePlannerParentOption: string;
  sharePlannerAnotherParentOption: string;
  sharePlannerChildOption: string;
  sharePlannerDefaultOption: string;
  unexpectedError(error: string): string;
}

export const EnglishSharingInvitationCodeStrings: SharingInvitationCodeStrings = {
  anonymousUserEmail: '(never logged in)',
  anonymousUserFullName: 'Anonymous user',
  cancelButton: 'Cancel',
  currentUserLabel: ' (you)',
  currentUsersHeader: 'Users with access to this planner',
  invalidCodeError: 'The code is not valid. Make sure you have entered the correct one.',
  noMatchingPlannerAlertMessage:
    'You were successfully linked to the planner. However, an error occurred while trying to fetch its data.',
  noMatchingPlannerAlertTitle: 'Success',
  participationCodeConfirmationAlertConfirmButtonTitle: 'Allow access',
  participationCodeConfirmationAlertMessage: (senderName?: string) =>
    `By using this code, your are allowing ${
      senderName ?? 'another person'
    } to access your planner. Do you wish to proceed?`,
  participationCodeConfirmationAlertTitle: 'Allow access to your planner',
  pickerLabel: 'Who do you wish to share your planner with?',
  readOnlyLabel: 'You do not have the rights to change who has access to this planner.',
  shareButton: 'Share',
  sharePlannerDialogConfirmButton: 'Done',
  sharePlannerLoadingMessage: 'Loading sharing code…',
  sharePlannerStatusLoadingMessage: 'Loading sharing status…',
  shareSectionTitle: 'Share my planner',
  useCodeButton: 'Use code',
  useCodeDialogTitle: 'Use sharing code',
  useCodeFieldLabel: 'Enter the sharing code you received',
  useCodeHeader: 'Received a sharing code for Studyo Go? Enter it here!',

  sharePlannerLoadingErrorMessage: (error) => `An error occurred while loading the sharing code. Details: ${error}`,
  sharePlannerStatusLoadingErrorMessage: (error) =>
    `An error occurred while loading the sharing status. Details: ${error}`,
  sharePlannerText: (userRelationship, targetRelationship) => {
    let relationshipKindLabel: string | undefined;

    switch (userRelationship) {
      case PlannerRelationshipKind.PARENT:
        relationshipKindLabel =
          targetRelationship === PlannerRelationshipKind.INDIVIDUAL ? 'your child' : 'another parent';
        break;
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.STUDENT:
        relationshipKindLabel = EnglishStudentLabelForRelationshipKind(targetRelationship);
        break;
      case PlannerRelationshipKind.UNSPECIFIED:
      case PlannerRelationshipKind.TEACHER:
      default:
        break;
    }

    if (relationshipKindLabel == null) {
      return 'Share this code to let someone access your planner:';
    }

    return `Once ${relationshipKindLabel} is connected to Studyo Go with their own account, have them enter this sharing code to access this planner.`;
  },
  sharePlannerTitle: () => {
    return 'Share your planner';
  },
  sharePlannerTeacherOption: 'A teacher or educator',
  sharePlannerAnotherTeacherOption: 'Another teacher or educator',
  sharePlannerParentOption: 'A parent',
  sharePlannerAnotherParentOption: 'Another parent',
  sharePlannerChildOption: 'Your child',
  sharePlannerDefaultOption: 'Another user',
  unexpectedError: (error) => `An error has occurred while using the sharing code. Details: ${error}`
};

function EnglishStudentLabelForRelationshipKind(relationshipKind: PlannerRelationshipKind): string {
  switch (relationshipKind) {
    case PlannerRelationshipKind.TEACHER:
      return 'your teacher or educator';
    case PlannerRelationshipKind.PARENT:
      return 'your parent';
    default:
      return '';
  }
}

export const FrenchSharingInvitationCodeStrings: SharingInvitationCodeStrings = {
  anonymousUserEmail: '(jamais connecté)',
  anonymousUserFullName: 'Usager anonyme',
  cancelButton: 'Annuler',
  currentUserLabel: ' (vous)',
  currentUsersHeader: 'Utilisateurs avec accès à ce planificateur',
  invalidCodeError: 'Le code n’est pas valide. Assurez-vous d’entrer le bon code.',
  noMatchingPlannerAlertMessage:
    'Vous avez bien été lié à l’agenda correspondant au code. Il y a cependant eu une erreur lors du chargement des informations.',
  noMatchingPlannerAlertTitle: 'Succès',
  participationCodeConfirmationAlertConfirmButtonTitle: 'Permettre l’accès',
  participationCodeConfirmationAlertMessage: (senderName?: string) =>
    `En utilisant ce code, vous permettez à ${
      senderName ?? 'une autre personne'
    } d’avoir accès à votre planificateur. Souhaitez-vous procéder?`,
  participationCodeConfirmationAlertTitle: 'Permettre l’accès à votre planificateur',
  pickerLabel: 'Avec qui souhaitez-vous partager votre planifiacteur?',
  readOnlyLabel:
    'Vous ne possédez pas les droits nécessaires pour changer la liste des personnes qui ont accès à ce planificateur.',
  shareButton: 'Partager',
  sharePlannerDialogConfirmButton: 'Terminé',
  sharePlannerLoadingMessage: 'Chargement du code de partage…',
  sharePlannerStatusLoadingMessage: 'Chargement de l’état du partage…',
  shareSectionTitle: 'Partager mon planificateur',
  useCodeButton: 'Utiliser le code de partage',
  useCodeDialogTitle: 'Utiliser un code de partage',
  useCodeFieldLabel: 'Entrez le code de partage reçu',
  useCodeHeader: 'Vous avez reçu un code de partage pour Studyo Go? Entrez le ici!',

  sharePlannerLoadingErrorMessage: (error) =>
    `Une erreur est survenue lors du chargement du code de partage. Détails : ${error}`,
  sharePlannerStatusLoadingErrorMessage: (error) =>
    `Une erreur est survenue lors du chargement de l’état du partage. Détails : ${error}`,
  sharePlannerText: (userRelationship, targetRelationship) => {
    let relationshipKindLabel: string | undefined;

    switch (userRelationship) {
      case PlannerRelationshipKind.PARENT:
        relationshipKindLabel =
          targetRelationship === PlannerRelationshipKind.INDIVIDUAL ? 'Lorsque votre enfant' : 'Lorsqu’un autre parent';
        break;
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.STUDENT:
        relationshipKindLabel = `Lorsque ${FrenchStudentLabelForRelationshipKind(targetRelationship)}`;
        break;

      case PlannerRelationshipKind.UNSPECIFIED:
      case PlannerRelationshipKind.TEACHER:
      default:
        break;
    }

    if (relationshipKindLabel == null) {
      return 'Partagez ce code avec quelqu’un pour lui permettre d’accéder à votre agenda :';
    }

    return `${relationshipKindLabel} sera connecté à Studyo Go, faites-lui entrer ce code pour obtenir l’accès à cet agenda :`;
  },
  sharePlannerTitle: () => {
    return 'Partager votre planificateur';
  },
  sharePlannerTeacherOption: 'Un enseignant ou éducateur',
  sharePlannerAnotherTeacherOption: 'Un autre enseignant ou éducateur',
  sharePlannerParentOption: 'Un parent',
  sharePlannerAnotherParentOption: 'Un autre parent',
  sharePlannerChildOption: 'Votre enfant',
  sharePlannerDefaultOption: 'Un autre usager',
  unexpectedError: (error) => `Une erreur est survenue lors de l’utilisation du code de partage. Détails : ${error}`
};

function FrenchStudentLabelForRelationshipKind(relationshipKind: PlannerRelationshipKind): string {
  switch (relationshipKind) {
    case PlannerRelationshipKind.TEACHER:
      return 'votre enseignant ou éducateur';
    case PlannerRelationshipKind.PARENT:
      return 'votre parent';
    default:
      return '';
  }
}
