import { orderBy } from 'lodash';
import { UserDashboardCalendarItemPosition } from './UserDashboardCalendarItemPosition';

export interface UserDashboardCalendarItemRankedPosition<T> {
  rank: number;
  othersCount: number;
  maxRank: number;
  item: T;
}

export function makeRankedPositionsForCalendarItems<T>(
  items: T[],
  getPosition: (item: T) => UserDashboardCalendarItemPosition
): UserDashboardCalendarItemRankedPosition<T>[] {
  const sortedItemInfos = orderBy(
    items,
    // Larger items before others
    [
      (item) => getPosition(item).top,
      (item) => {
        const position = getPosition(item);
        return position.top + position.height;
      }
    ],
    ['asc', 'desc']
  );

  const rankedItems = sortedItemInfos.map<UserDashboardCalendarItemRankedPosition<T>>((item) => ({
    item,
    othersCount: 0,
    rank: 0,
    maxRank: 0
  }));

  let deepestCollisionIndex = 0;

  for (let i = 0; i < rankedItems.length; i++) {
    const item = rankedItems[i];
    const itemPosition = getPosition(item.item);
    let nextDeepestCollisionIndex = i;
    const collidingRanks: number[] = [];
    const collidingItems: UserDashboardCalendarItemRankedPosition<T>[] = [];

    for (let j = i - 1; j >= deepestCollisionIndex; j--) {
      const other = rankedItems[j];
      const otherPosition = getPosition(other.item);
      const isOverlapping = itemsPositionsOverlap(itemPosition, otherPosition);

      if (isOverlapping) {
        collidingRanks.push(other.rank);
        collidingItems.push(other);
        item.othersCount += 1;
        other.othersCount += 1;
        nextDeepestCollisionIndex = j;
      }
    }

    // Looks weird, but just pairing each rank with their index, and picking the first that
    // don't match. It means there's a free rank at that index.
    const firstFreeRank = collidingRanks
      .sort()
      .map((r, i) => [r, i])
      .find((pair) => pair[0] != pair[1]) ?? [0, collidingRanks.length];

    item.rank = firstFreeRank[1];
    item.maxRank = Math.max(item.rank, ...collidingRanks);
    for (const other of collidingItems) {
      other.maxRank = Math.max(item.maxRank, other.maxRank);
    }
    deepestCollisionIndex = nextDeepestCollisionIndex;
  }

  return rankedItems;
}

function itemsPositionsOverlap(
  first: UserDashboardCalendarItemPosition,
  second: UserDashboardCalendarItemPosition
): boolean {
  const firstEnd = first.top + first.height;
  const secondEnd = second.top + second.height;

  return !(first.top > secondEnd || firstEnd < second.top);
}
