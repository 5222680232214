import { ApplicationStrings, EnglishApplicationStrings, FrenchApplicationStrings } from './ApplicationStrings';
import { ConnectedAppsStrings, EnglishConnectedAppsStrings, FrenchConnectedAppsStrings } from './ConnectedAppsStrings';
import { EnglishDateTimeStrings, ExDateTimeStrings, FrenchDateTimeStrings } from './ExDateTimeStrings';
import { EnglishOnboardingStrings, ExOnboardingStrings, FrenchOnboardingStrings } from './ExOnboardingStrings';
import { ExEnglishPlannersStrings, ExFrenchPlannersStrings, ExPlannersStrings } from './ExPlannersStrings';
import {
  EnglishTeacherCourseSectionStrings,
  ExSchoolCourseSectionStrings,
  FrenchTeacherCourseSectionStrings
} from './ExSchoolCourseSectionStrings';
import { EnglishSchoolStrings, ExSchoolStrings, FrenchSchoolStrings } from './ExSchoolStrings';
import { EnglishSettingsStrings, ExSettingsStrings, FrenchSettingsStrings } from './ExSettingsStrings';

import { EnglishUtilsStrings, ExUtilsStrings, FrenchUtilsStrings } from './ExUtilsStrings';
import { EnglishLoginStrings, FrenchLoginStrings, LoginStrings } from './LoginStrings';
import { EnglishMainScreenStrings, FrenchMainScreenStrings, MainScreenStrings } from './MainScreenStrings';
import {
  EnglishParticipationRequestsStrings,
  FrenchParticipationRequestsStrings,
  ParticipationRequestsStrings
} from './ParticipationRequestsStrings';
import { EnglishRouteNameStrings, FrenchRouteNameStrings, RouteNameStrings } from './RouteNameStrings';
import {
  EnglishSharingInvitationCodeStrings,
  FrenchSharingInvitationCodeStrings,
  SharingInvitationCodeStrings
} from './SharingInvitationCodeStrings';
import { EnglishUserStrings, FrenchUserStrings, UserStrings } from './UserStrings';
import { EnglishWelcomeStrings, FrenchWelcomeStrings, WelcomeStrings } from './WelcomeStrings';
import { EnglishWorkListStrings, FrenchWorkListStrings, WorkListStrings } from './WorkListStrings';
import {
  CourseSectionStrings,
  EnglishCourseSectionStrings,
  FrenchCourseSectionStrings
} from './course/CourseSectionStrings';
import { EnglishWorkStrings, ExWorkStrings, FrenchWorkStrings } from './work/ExWorkStrings';

export interface ExLocalizedStrings {
  readonly application: ApplicationStrings;
  readonly connectedApps: ConnectedAppsStrings;
  readonly courseSection: CourseSectionStrings;
  readonly planners: ExPlannersStrings;
  readonly dateTime: ExDateTimeStrings;
  readonly login: LoginStrings;
  readonly mainScreen: MainScreenStrings;
  readonly onboarding: ExOnboardingStrings;
  readonly participationRequests: ParticipationRequestsStrings;
  readonly routeNames: RouteNameStrings;
  readonly school: ExSchoolStrings;
  readonly schoolCourseSection: ExSchoolCourseSectionStrings;
  readonly settings: ExSettingsStrings;
  readonly sharingInvitationCode: SharingInvitationCodeStrings;
  readonly user: UserStrings;
  readonly utils: ExUtilsStrings;
  readonly welcome: WelcomeStrings;
  readonly work: ExWorkStrings;
  readonly workList: WorkListStrings;
}

export const EnglishLocalizedStrings: ExLocalizedStrings = {
  application: EnglishApplicationStrings,
  connectedApps: EnglishConnectedAppsStrings,
  courseSection: EnglishCourseSectionStrings,
  planners: ExEnglishPlannersStrings,
  dateTime: EnglishDateTimeStrings,
  login: EnglishLoginStrings,
  mainScreen: EnglishMainScreenStrings,
  onboarding: EnglishOnboardingStrings,
  participationRequests: EnglishParticipationRequestsStrings,
  routeNames: EnglishRouteNameStrings,
  school: EnglishSchoolStrings,
  schoolCourseSection: EnglishTeacherCourseSectionStrings,
  settings: EnglishSettingsStrings,
  sharingInvitationCode: EnglishSharingInvitationCodeStrings,
  user: EnglishUserStrings,
  utils: EnglishUtilsStrings,
  welcome: EnglishWelcomeStrings,
  work: EnglishWorkStrings,
  workList: EnglishWorkListStrings
};

export const FrenchLocalizedStrings: ExLocalizedStrings = {
  application: FrenchApplicationStrings,
  connectedApps: FrenchConnectedAppsStrings,
  courseSection: FrenchCourseSectionStrings,
  planners: ExFrenchPlannersStrings,
  dateTime: FrenchDateTimeStrings,
  login: FrenchLoginStrings,
  mainScreen: FrenchMainScreenStrings,
  onboarding: FrenchOnboardingStrings,
  participationRequests: FrenchParticipationRequestsStrings,
  routeNames: FrenchRouteNameStrings,
  school: FrenchSchoolStrings,
  schoolCourseSection: FrenchTeacherCourseSectionStrings,
  settings: FrenchSettingsStrings,
  sharingInvitationCode: FrenchSharingInvitationCodeStrings,
  user: FrenchUserStrings,
  utils: FrenchUtilsStrings,
  welcome: FrenchWelcomeStrings,
  work: FrenchWorkStrings,
  workList: FrenchWorkListStrings
};
