import { AppOnboardingViewModel, OnboardingScreenInfo, OnboardingViewModel } from '@/viewmodels';
import { Box, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { Splash } from '../Splash';
import { MediaQuery } from '../utils';
import { OnboardingClassroomConnectionStep } from './OnboardingClassroomConnectionStep';
import { OnboardingStepper, OnboardingStepperProps } from './OnboardingStepper';
import { SharePlannerStep } from './SharePlannerStep';
import { UseSharingInvitationCodeStep } from './UseSharingInvitationCodeStep';
import { UserPersonaStep } from './UserPersonaStep';

export const OnboardingScreen = observer(() => {
  const onboardingState = useRef<OnboardingViewModel>(new AppOnboardingViewModel());

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        <Splash width="100%" height="100%" contentWidth={600}>
          <StyledStepper onboardingState={onboardingState.current} renderScreen={renderScreen} />
        </Splash>
      </MediaQuery>
      <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            p: { xs: 2, sm: 3 }
          }}
        >
          <StyledStepper onboardingState={onboardingState.current} renderScreen={renderScreen} />
        </Box>
      </MediaQuery>
    </>
  );
});

function renderScreen(info: OnboardingScreenInfo) {
  switch (info.case) {
    case 'user-persona-selection':
      return <UserPersonaStep viewModel={info.viewModel} />;

    case 'use-code':
      return <UseSharingInvitationCodeStep viewModel={info.viewModel} />;

    case 'classroom-connection':
      return <OnboardingClassroomConnectionStep viewModel={info.viewModel} />;

    case 'share-planner':
      return <SharePlannerStep viewModel={info.viewModel} />;
  }
}

const StyledStepper = styled((props: OnboardingStepperProps) => <OnboardingStepper {...props} />)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));
