import { CourseSectionColors, Day, DayOfWeek, dayToDate, UserDashboardInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { ScheduleCycleDataStore, SpecialDayOccurrenceWhen, UserDataStore } from '@/stores';
import { arraysAreEqual } from '@/utils';
import { CycleDayEffect } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/cycle_day_effect_pb';
import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { SpecialDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_pb';
import { differenceInCalendarDays, format } from 'date-fns';
import { chain, isEqual, sample, times } from 'lodash';
import { action, computed, makeObservable, observable, override } from 'mobx';
import LocalizedStrings from 'strings';
import {
  AppBaseStaticDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../../../utils';
import { getScheduleCycleKind, ScheduleCycleKind, titleForCycleDay } from '../ScheduleCycleUtils';

export interface SpecialDayOccurrenceInfo {
  readonly occurrence: SpecialDayOccurrenceWhen;
  readonly title: string;
}

export interface SpecialDayEditViewModel extends StaticDialogViewModel {
  readonly scheduleCycleKind: ScheduleCycleKind;
  isTitleVisible: boolean;
  title: string;
  isSymbolVisible: boolean;
  symbol: string;
  color: string;
  cycleDayEffect: CycleDayEffect;
  cycleDay: number;
  readonly possibleCycleDays: { value: number; title: string }[];
  readonly periodSchedules: PeriodSchedule[];
  readonly possiblePeriodSchedule: PeriodSchedule[];
  readonly occurrences: SpecialDayOccurrenceInfo[];
  readonly minDay: Day | undefined;
  readonly maxDay: Day | undefined;

  addOccurrence(when: SpecialDayOccurrenceWhen): void;
  removeOccurrence(when: SpecialDayOccurrenceWhen): void;
  setPeriodScheduleIds(ids: string[]): void;
}

export class AppSpecialDayEditViewModel extends AppBaseStaticDialogViewModel implements SpecialDayEditViewModel {
  private readonly _scheduleCycleStore: ScheduleCycleDataStore;
  private readonly _specialDay: SpecialDay | undefined;
  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;
  private readonly _fallbackColor = sample(CourseSectionColors)!;

  @observable private _isSaving = false;
  @observable private _isTitleVisible: boolean;
  @observable private _title: string;
  @observable private _isSymbolVisible: boolean;
  @observable private _symbol: string;
  @observable private _color: string;
  @observable private _cycleDayEffect: CycleDayEffect;
  @observable private _cycleDay: number;
  @observable private _periodScheduleIds: string[];
  @observable private _occurrences: SpecialDayOccurrenceWhen[];

  constructor(
    private readonly _specialDayId: string | undefined,
    scheduleCycleId: string,
    dashboard: UserDashboardInfo,
    initialOccurrence:
      | { case: 'cycleDay'; value: number }
      | { case: 'dayOfWeek'; value: DayOfWeek }
      | { case: 'day'; value: Day }
      | undefined,
    onDismiss: () => Promise<void>,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(onDismiss);
    makeObservable(this);
    this._scheduleCycleStore = userStore.getScheduleCycleStore(scheduleCycleId, dashboard);
    const scheduleCycle = this._scheduleCycleStore.scheduleCycle;

    if (_specialDayId != null) {
      const specialDay = scheduleCycle.specialDays.find((sp) => sp.id === _specialDayId)!;
      this._specialDay = specialDay;
      this._isTitleVisible = specialDay.isTitleVisible;
      this._title = specialDay.title;
      this._isSymbolVisible = specialDay.isSymbolVisible;
      this._symbol = specialDay.symbol;
      this._color = specialDay.color;
      this._cycleDayEffect = specialDay.cycleDayEffect;
      this._cycleDay = specialDay.cycleDay;
      this._periodScheduleIds = specialDay.periodScheduleIds;
      this._occurrences = chain(scheduleCycle.specialDayOccurrences)
        .filter((o) => !o.shouldDelete && o.specialDayId === specialDay.id && o.when.case != undefined)
        .map((o) => o.when as SpecialDayOccurrenceWhen)
        .compact()
        .value();
    } else {
      this._isTitleVisible = true;
      this._title = '';
      this._isSymbolVisible = true;
      this._symbol = '';
      this._color = '';
      this._cycleDayEffect = CycleDayEffect.UNSPECIFIED;
      this._cycleDay = 0;
      this._periodScheduleIds = [];
      if (initialOccurrence != null) {
        this._occurrences = [initialOccurrence];
      } else {
        this._occurrences = [];
      }
    }

    this._saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, async () =>
      this._onDismiss()
    );
  }

  @computed
  private get scheduleCycle(): ScheduleCycle {
    return this._scheduleCycleStore.scheduleCycle;
  }

  @computed
  private get canSave(): boolean {
    if (!this.hasChanges) {
      return false;
    }

    return this.title.length > 0;
  }

  @computed
  get hasChanges(): boolean {
    const sp = this._specialDay;

    if (sp == null) {
      return true;
    }

    const originalOccurrences = chain(this.scheduleCycle.specialDayOccurrences)
      .filter((o) => o.specialDayId === this._specialDayId && o.when.case != undefined)
      .map((o) => o.when as SpecialDayOccurrenceWhen)
      .compact()
      .value();

    const occurrencesHasChanges = !arraysAreEqual(
      originalOccurrences,
      this.occurrences.map((o) => o.occurrence),
      (o1, o2) => {
        if (o1.case !== o2.case) {
          return false;
        }

        if (o1.case === 'day' && o2.case === 'day') {
          return isEqual(o1.value, o2.value);
        } else {
          return o1.value === o2.value;
        }
      }
    );

    return (
      sp.title !== this.title ||
      sp.isTitleVisible !== this.isTitleVisible ||
      sp.symbol !== this.symbol ||
      sp.color != this._color ||
      sp.isSymbolVisible !== this.isSymbolVisible ||
      sp.cycleDay !== this.cycleDay ||
      sp.cycleDayEffect !== this.cycleDayEffect ||
      !arraysAreEqual(
        sp.periodScheduleIds,
        this.periodSchedules.map((s) => s.id)
      ) ||
      occurrencesHasChanges
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled = this.canSave && !this._isSaving;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  readonly isSubmitting = false;

  @computed
  get scheduleCycleKind(): ScheduleCycleKind {
    return getScheduleCycleKind(this.scheduleCycle);
  }

  @computed
  get isTitleVisible(): boolean {
    return this._isTitleVisible;
  }

  set isTitleVisible(value: boolean) {
    this._isTitleVisible = value;
  }

  @computed
  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  @computed
  get isSymbolVisible(): boolean {
    return this._isSymbolVisible;
  }

  set isSymbolVisible(value: boolean) {
    this._isSymbolVisible = value;
  }

  @computed
  get symbol(): string {
    return this._symbol;
  }

  set symbol(value: string) {
    this._symbol = value;
  }

  @computed
  get color(): string {
    return this._color || this._fallbackColor;
  }

  set color(value: string) {
    this._color = value;
  }

  @computed
  get cycleDayEffect(): CycleDayEffect {
    return this._cycleDayEffect;
  }

  set cycleDayEffect(value: CycleDayEffect) {
    this._cycleDayEffect = value;
  }

  @computed
  get cycleDay(): number {
    return this._cycleDay;
  }

  set cycleDay(value: number) {
    if (value < 0) {
      this._cycleDay = 0;
      this.cycleDayEffect = CycleDayEffect.UNSPECIFIED;
    } else {
      this._cycleDay = value;

      if (this.cycleDayEffect === CycleDayEffect.UNSPECIFIED) {
        this.cycleDayEffect = this.scheduleCycleKind === 'week' ? CycleDayEffect.PUSH : CycleDayEffect.SKIP;
      }
    }
  }

  @computed
  get possibleCycleDays(): { value: number; title: string }[] {
    return times(this.scheduleCycle.cycleDayCount).map((cycleDayIndex) => {
      const cycleDay = cycleDayIndex + 1;
      const title = titleForCycleDay(cycleDay, this.scheduleCycleKind, 'long', true, this.scheduleCycle.cycleDayNames);
      return { value: cycleDay, title };
    });
  }

  @computed
  get periodSchedules(): PeriodSchedule[] {
    return chain(this._periodScheduleIds)
      .map((id) => this.scheduleCycle.periodSchedules.find((s) => s.id === id))
      .compact()
      .sortBy([(s) => s.name])
      .value();
  }

  @computed
  get possiblePeriodSchedule(): PeriodSchedule[] {
    return this.scheduleCycle.periodSchedules;
  }

  @computed
  get occurrences(): SpecialDayOccurrenceInfo[] {
    const kindSortOrder = { day: 0, cycleDay: 1, dayOfWeek: 2, other: 3 };

    return chain(this._occurrences)
      .sort((o1, o2) => {
        if (o1.case === 'day' && o2.case === 'day') {
          return differenceInCalendarDays(dayToDate(o1.value), dayToDate(o2.value));
        } else if (
          (o1.case === 'dayOfWeek' && o2.case === 'dayOfWeek') ||
          (o1.case === 'cycleDay' && o2.case === 'cycleDay')
        ) {
          return o1.value - o2.value;
        }

        return kindSortOrder[o1.case ?? 'other'] - kindSortOrder[o2.case ?? 'other'];
      })
      .map((o) => ({ occurrence: o, title: this.titleForOccurrence(o) }))
      .value();
  }

  @computed
  get minDay(): Day | undefined {
    return this.scheduleCycle.startDay;
  }

  @computed
  get maxDay(): Day | undefined {
    return this.scheduleCycle.endDay;
  }

  @action
  addOccurrence(when: SpecialDayOccurrenceWhen) {
    const existing = this._occurrences.find((o) => o.case === when.case && isEqual(o.value, when.value));

    if (existing != null) {
      return;
    }

    this._occurrences.push(when);
  }

  @action
  removeOccurrence(when: SpecialDayOccurrenceWhen) {
    const occurrenceIndex = this._occurrences.findIndex((o) => o.case === when.case && isEqual(o.value, when.value));
    if (occurrenceIndex >= 0) {
      this._occurrences.splice(occurrenceIndex, 1);
    }
  }

  @action
  setPeriodScheduleIds(ids: string[]) {
    this._periodScheduleIds = ids;
  }

  private titleForOccurrence(occurrence: SpecialDayOccurrenceWhen): string {
    switch (occurrence.case) {
      case 'dayOfWeek': {
        const dowTitle = LocalizedStrings.dateTime.dayOfWeekTitle[occurrence.value]();
        return LocalizedStrings.scheduleCycle.edit.specialDays.dayOfWeekOccurrenceLabel(dowTitle);
      }

      case 'cycleDay': {
        const cycleDayTitle = titleForCycleDay(
          occurrence.value,
          this.scheduleCycleKind,
          'long',
          true,
          this.scheduleCycle.cycleDayNames
        );
        return LocalizedStrings.scheduleCycle.edit.specialDays.cycleDayOccurrenceLabel(cycleDayTitle);
      }

      case 'day':
        return format(dayToDate(occurrence.value), 'PPP');
    }
  }

  private async save() {
    await this._scheduleCycleStore.createOrUpdateSpecialDay(
      this._specialDayId,
      {
        title: this.title,
        isTitleVisible: this.isTitleVisible,
        symbol: this.symbol,
        color: this.color,
        isSymbolVisible: this.isSymbolVisible,
        cycleDay: this.cycleDay,
        cycleDayEffect: this.cycleDayEffect,
        periodScheduleIds: this._periodScheduleIds
      },
      this.occurrences.map((o) => o.occurrence)
    );

    await this.dismiss();
  }
}
