import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import LocalizedStrings from 'strings';

export interface PlannerPastePlannerItemDateHeaderProps {
  sx?: SxProps;
  className?: string;
  date: Date | undefined;
}

export function PlannerPastePlannerItemDateHeader({
  sx = [],
  className,
  date
}: PlannerPastePlannerItemDateHeaderProps) {
  if (date == null) {
    return null;
  }

  const strings = LocalizedStrings.planner.pastePlannerItem;
  return (
    <Typography sx={sx} className={className} variant="subtitle2">
      {strings.existingDateHeaderLabel(format(date, 'PPP'))}
    </Typography>
  );
}
