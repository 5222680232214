import i18n from 'translate';

const ScheduleCycleSpecialDaysEditStrings = {
  addTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.addTitle'),
  assignedToAddOccurrenceButtonTitle: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.assignedToAddOccurrenceButtonTitle'),
  assignedToCycleDayPickerTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.assignedToCycleDayPickerTitle'),
  assignedToDayOfWeekPickerTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.assignedToDayOfWeekPickerTitle'),
  assignedToOccurrencePickerCycleDay: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.assignedToOccurrencePickerCycleDay'),
  assignedToOccurrencePickerCycleDayOfWeek: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.assignedToOccurrencePickerCycleDayOfWeek'),
  assignedToOccurrencePickerDayOfWeek: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.assignedToOccurrencePickerDayOfWeek'),
  assignedToOccurrencePickerSpecificDate: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.assignedToOccurrencePickerSpecificDate'),
  assignedToOccurrencePickerTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.assignedToOccurrencePickerTitle'),
  assignedToSectionTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.assignedToSectionTitle'),
  bellTimesPickerLabel: () => i18n.t('scheduleCycle.edit.specialDays.edit.bellTimesPickerLabel'),
  bellTimesPickerTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.bellTimesPickerTitle'),
  colorPickerTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.colorPickerTitle'),
  cycleDayEffectHelperTextPush: () => i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectHelperTextPush'),
  cycleDayEffectHelperTextResetCycleDay: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectHelperTextResetCycleDay'),
  cycleDayEffectHelperTextResetMultipleWeeks: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectHelperTextResetMultipleWeeks'),
  cycleDayEffectHelperTextSkipCycleDay: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectHelperTextSkipCycleDay'),
  cycleDayEffectHelperTextSkipMultipleWeeks: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectHelperTextSkipMultipleWeeks'),
  cycleDayEffectHelperTextUnspecified: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectHelperTextUnspecified'),
  cycleDayEffectPickerCycleDayLabel: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectPickerCycleDayLabel'),
  cycleDayEffectPickerMultipleWeeksLabel: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayEffectPickerMultipleWeeksLabel'),
  cycleDayPickerLabelCycleDay: () => i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayPickerLabelCycleDay'),
  cycleDayPickerLabelDayOfWeek: () => i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayPickerLabelDayOfWeek'),
  cycleDayPickerNoCycleDayOptionCycleDay: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayPickerNoCycleDayOptionCycleDay'),
  cycleDayPickerNoCycleDayOptionDayOfWeek: () =>
    i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayPickerNoCycleDayOptionDayOfWeek'),
  cycleDayPickerNoEffectOption: () => i18n.t('scheduleCycle.edit.specialDays.edit.cycleDayPickerNoEffectOption'),
  datePickerSubmitButtonTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.datePickerSubmitButtonTitle'),
  datePickerTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.datePickerTitle'),
  editTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.editTitle'),
  symbolNoneOption: () => i18n.t('scheduleCycle.edit.specialDays.edit.symbolNoneOption'),
  symbolSectionTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.symbolSectionTitle'),
  symbolSelectEmojiOption: () => i18n.t('scheduleCycle.edit.specialDays.edit.symbolSelectEmojiOption'),
  symbolVisibilityOptionTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.symbolVisibilityOptionTitle'),
  titleLabel: () => i18n.t('scheduleCycle.edit.specialDays.edit.titleLabel'),
  titlePlaceholder: () => i18n.t('scheduleCycle.edit.specialDays.edit.titlePlaceholder'),
  titleVisibilityOptionTitle: () => i18n.t('scheduleCycle.edit.specialDays.edit.titleVisibilityOptionTitle')
};

const ScheduleCycleSpecialDaysListStrings = {
  deleteConfirmationMessage: () => i18n.t('scheduleCycle.edit.specialDays.list.deleteConfirmationMessage'),
  deleteConfirmationSubmitLabel: () => i18n.t('scheduleCycle.edit.specialDays.list.deleteConfirmationSubmitLabel'),
  deleteConfirmationTitle: () => i18n.t('scheduleCycle.edit.specialDays.list.deleteConfirmationTitle'),
  noAssignedSpecialDays: () => i18n.t('scheduleCycle.edit.specialDays.list.noAssignedSpecialDays'),
  noSpecialDays: () => i18n.t('scheduleCycle.edit.specialDays.list.noSpecialDays'),
  otherSpecialDaysSectionTitle: () => i18n.t('scheduleCycle.edit.specialDays.list.otherSpecialDaysSectionTitle'),
  title: () => i18n.t('scheduleCycle.edit.specialDays.list.title'),
  specialDaySubtitleCycleDay: (cycleDayTitle: string) =>
    i18n.t('scheduleCycle.edit.specialDays.list.specialDaySubtitleCycleDay', { cycleDayTitle }),
  specialDaySubtitleNoCycleDay: () => i18n.t('scheduleCycle.edit.specialDays.list.specialDaySubtitleNoCycleDay'),
  specialDaySubtitleCycleDayEffectCycleDay: (effect: string) =>
    i18n.t('scheduleCycle.edit.specialDays.list.specialDaySubtitleCycleDayEffectCycleDay', { effect }),
  specialDaySubtitleCycleDayEffectDayOfWeek: (effect: string) =>
    i18n.t('scheduleCycle.edit.specialDays.list.specialDaySubtitleCycleDayEffectDayOfWeek', { effect }),
  specialDayCycleDay: (cycleDay: string) =>
    i18n.t('scheduleCycle.edit.specialDays.list.specialDayCycleDay', { cycleDay }),
  specialDayDayOfWeek: (dayOfWeek: string) =>
    i18n.t('scheduleCycle.edit.specialDays.list.specialDayDayOfWeek', { dayOfWeek }),
  specialDayBellTimes: (bellTimeName: string) =>
    i18n.t('scheduleCycle.edit.specialDays.list.specialDayBellTimes', { bellTimeName })
};

export const ScheduleCycleSpecialDaysStrings = {
  clearSelectionButtonTitle: () => i18n.t('scheduleCycle.edit.specialDays.clearSelectionButtonTitle'),
  cycleDayOccurrenceLabel: (cycleDayTitle: string) =>
    i18n.t('scheduleCycle.edit.specialDays.cycleDayOccurrenceLabel', { cycleDayTitle }),
  dayOfWeekOccurrenceLabel: (dayOfWeek: string) =>
    i18n.t('scheduleCycle.edit.specialDays.dayOfWeekOccurrenceLabel', { dayOfWeek }),
  edit: ScheduleCycleSpecialDaysEditStrings,
  list: ScheduleCycleSpecialDaysListStrings,
  rotatingDaysSectionTitleCycleDay: () => i18n.t('scheduleCycle.edit.specialDays.rotatingDaysSectionTitleCycleDay'),
  rotatingDaysSectionTitleWeek: () => i18n.t('scheduleCycle.edit.specialDays.rotatingDaysSectionTitleWeek'),
  rotatingDaysSectionTitleMultipleWeeks: () =>
    i18n.t('scheduleCycle.edit.specialDays.rotatingDaysSectionTitleMultipleWeeks'),
  daysOfWeekSectionTitle: () => i18n.t('scheduleCycle.edit.specialDays.daysOfWeekSectionTitle'),
  showCalendarButtonTooltip: () => i18n.t('scheduleCycle.edit.specialDays.showCalendarButtonTooltip')
};
