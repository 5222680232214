import { useActivePlannerId, useServices } from '@/hooks';
import { CalendarMonthRounded, FilterListRounded, SettingsRounded } from '@mui/icons-material';
import GoPreviousDayIcon from '@mui/icons-material/ArrowBackRounded';
import GoNextDayIcon from '@mui/icons-material/ArrowForwardRounded';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, IconButton, Popover, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { MediaQuery, useArrowNavigation } from '../../../utils';
import { UserDashboardCalendarDatePicker } from '../date-picker';
import { UserDashboardCalendarHeaderCurrentDateView } from './UserDashboardCalendarHeaderCurrentDateView';

export interface UserDashboardCalendarHeaderViewProps {
  sx?: SxProps;
  className?: string;
  previousDate: Date;
  nextDate: Date;
  isDisplayingToday: boolean;
  title: string;
  subtitle?: string;
  showTodayIndicator?: boolean;
  scheduleCycleWithDraftId: string | undefined;
  hasCalendarSyncError: boolean;
  retryFetchData: () => Promise<void>;
  onOptionsButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onFiltersButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const UserDashboardCalendarHeaderView = observer(
  ({
    sx = [],
    className,
    previousDate,
    nextDate,
    title,
    subtitle,
    showTodayIndicator = false,
    hasCalendarSyncError,
    retryFetchData,
    isDisplayingToday,
    scheduleCycleWithDraftId,
    onOptionsButtonClick,
    onFiltersButtonClick
  }: UserDashboardCalendarHeaderViewProps) => {
    const { dateService, route } = useServices();
    const plannerId = useActivePlannerId();
    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
    const navigate = useNavigate();
    const strings = LocalizedStrings.calendar;

    const [datePickerButtonRef, setDatePickerButtonRef] = useState<HTMLButtonElement | undefined>();
    const [isRetryingFetchData, setIsRetryingFetchData] = useState(false);

    function goToPreviousDay() {
      goToDate(previousDate);
    }

    function goToNextDay() {
      goToDate(nextDate);
    }

    function goToToday() {
      const today = dateService.now;
      goToDate(today);
    }

    function goToDate(date: Date) {
      setDatePickerButtonRef(undefined);
      const dateString = format(date, 'y-MM-dd');
      searchParams.set('date', dateString);
      setSearchParams(searchParams);
    }

    function goToScheduleCycleWithDraftDetails() {
      if (scheduleCycleWithDraftId == null) {
        return;
      }

      const destination = route.resolvePlannerSettingsLocation(`schedule/${scheduleCycleWithDraftId}`, plannerId);
      navigate(destination);
    }

    async function onRetryFetchDataClick() {
      setIsRetryingFetchData(true);
      await retryFetchData();
      setIsRetryingFetchData(false);
    }

    useArrowNavigation(goToPreviousDay, goToNextDay);

    return (
      <Stack sx={sx} className={className}>
        {hasCalendarSyncError && (
          <Box
            sx={{
              p: 1
            }}
          >
            <Alert
              severity="error"
              action={
                <LoadingButton
                  color="inherit"
                  size="small"
                  loading={isRetryingFetchData}
                  onClick={() => void onRetryFetchDataClick()}
                >
                  {strings.syncError.retryButtonTitle()}
                </LoadingButton>
              }
            >
              <AlertTitle>{strings.syncError.title()}</AlertTitle>
              {strings.syncError.message()}
            </Alert>
          </Box>
        )}
        {scheduleCycleWithDraftId != null && (
          <Box
            sx={{
              p: 1
            }}
          >
            <Alert
              severity="warning"
              action={
                <Button color="inherit" size="small" onClick={goToScheduleCycleWithDraftDetails}>
                  {strings.scheduleCycleDraftWarning.goToScheduleButtonTitle()}
                </Button>
              }
            >
              <AlertTitle>{strings.scheduleCycleDraftWarning.title()}</AlertTitle>
              {strings.scheduleCycleDraftWarning.message()}
            </Alert>
          </Box>
        )}
        <Stack
          spacing={1}
          sx={{
            px: 2,
            py: 1
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: { xs: 'center', sm: subtitle != null ? 'flex-start' : 'center' }
            }}
          >
            <Stack direction="row" spacing={0}>
              <IconButton onClick={goToPreviousDay} size={isExtraSmallScreen ? 'small' : undefined}>
                <GoPreviousDayIcon fontSize={isExtraSmallScreen ? 'small' : undefined} />
              </IconButton>

              <Box>
                <IconButton
                  onClick={(e) => setDatePickerButtonRef(e.currentTarget)}
                  size={isExtraSmallScreen ? 'small' : undefined}
                >
                  <CalendarMonthRounded fontSize={isExtraSmallScreen ? 'small' : undefined} />
                </IconButton>

                {datePickerButtonRef != null && (
                  <Popover
                    open={true}
                    anchorEl={datePickerButtonRef}
                    slotProps={{ paper: { sx: { width: 400 } } }}
                    onClose={() => setDatePickerButtonRef(undefined)}
                  >
                    <UserDashboardCalendarDatePicker
                      selectedDates={() => []}
                      highlightedDates={() => []}
                      onSelectDate={(date) => goToDate(date)}
                    />
                  </Popover>
                )}
              </Box>

              <IconButton onClick={goToNextDay} size={isExtraSmallScreen ? 'small' : undefined}>
                <GoNextDayIcon fontSize={isExtraSmallScreen ? 'small' : undefined} />
              </IconButton>
            </Stack>

            <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
              <UserDashboardCalendarHeaderCurrentDateView
                title={title}
                subtitle={subtitle}
                isToday={showTodayIndicator}
              />
            </MediaQuery>

            <Box
              sx={{
                flex: 1
              }}
            />

            {(!isDisplayingToday || onOptionsButtonClick != null || onFiltersButtonClick != null) && (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center'
                }}
              >
                {!isDisplayingToday && (
                  <Button variant="contained-grey" onClick={goToToday} size="small">
                    {isExtraSmallScreen
                      ? LocalizedStrings.calendar.goToTodayButtonShortTitle()
                      : LocalizedStrings.calendar.goToTodayButtonTitle()}
                  </Button>
                )}

                {onOptionsButtonClick != null && (
                  <IconButton onClick={(e) => onOptionsButtonClick(e)} size={isExtraSmallScreen ? 'small' : undefined}>
                    <SettingsRounded fontSize={isExtraSmallScreen ? 'small' : undefined} />
                  </IconButton>
                )}

                {onFiltersButtonClick != null && (
                  <IconButton onClick={(e) => onFiltersButtonClick(e)} size={isExtraSmallScreen ? 'small' : undefined}>
                    <FilterListRounded fontSize={isExtraSmallScreen ? 'small' : undefined} />
                  </IconButton>
                )}
              </Stack>
            )}
          </Stack>
          <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
            <UserDashboardCalendarHeaderCurrentDateView
              title={title}
              subtitle={subtitle}
              isToday={showTodayIndicator}
            />
          </MediaQuery>
        </Stack>
      </Stack>
    );
  }
);
