import {
  plannerHasAccessKindsForUser,
  schoolHasAccessKindsForUser,
  UserDashboard,
  UserDashboardInfo,
  UserDashboardPlanner,
  UserDashboardSchool
} from '@/models';
import { ServiceContainer } from '@/providers';
import { ScheduleCycleDataStore, UserDataStore } from '@/stores';
import { AccessKind as PlannerAccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { AccessKind as SchoolAccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { computed, makeObservable } from 'mobx';
import {
  AppScheduleCycleSpecialDaysViewModel,
  ScheduleCycleSpecialDaysViewModel
} from './ScheduleCycleSpecialDaysViewModel';

export interface ScheduleCycleSpecialDaysPageViewModel {
  readonly contentViewModel: ScheduleCycleSpecialDaysViewModel;
}

export class AppScheduleCycleSpecialDaysPageViewModel implements ScheduleCycleSpecialDaysPageViewModel {
  readonly contentViewModel: ScheduleCycleSpecialDaysViewModel;

  constructor(
    private readonly _scheduleCycleId: string,
    private readonly _dashboard: UserDashboardInfo,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);

    this.contentViewModel = new AppScheduleCycleSpecialDaysViewModel(
      () => this.scheduleCycleStore.scheduleCycle,
      this._dashboard,
      () => this.isReadOnly,
      (specialDayId, when) => void this.scheduleCycleStore.addOrRemoveSpecialDayOccurrence(specialDayId, when),
      (specialDayId, when) => void this.scheduleCycleStore.addSpecialDayOccurrence(specialDayId, when),
      (id) => void this.scheduleCycleStore.deleteSpecialDay(id),
      this._userStore
    );
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  private get currentDashboard(): UserDashboard {
    switch (this._dashboard.kind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboard.id)!;
        return { kind: 'planner', planner } as UserDashboardPlanner;
      }

      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboard.id)!;
        return { kind: 'school', school } as UserDashboardSchool;
      }
    }
  }

  @computed
  private get isReadOnly(): boolean {
    switch (this.currentDashboard.kind) {
      case 'planner':
        return !plannerHasAccessKindsForUser(
          this._userStore.user.userId,
          this.currentDashboard.planner,
          PlannerAccessKind.FULL_ACCESS
        );

      case 'school':
        return !schoolHasAccessKindsForUser(
          this._userStore.user.userId,
          this.currentDashboard.school,
          SchoolAccessKind.FULL_ACCESS
        );
    }
  }
}
