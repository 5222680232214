export interface CourseSectionEditStrings {
  readonly addTitle: string;
  readonly colorContrastWarning: string;
  readonly editTitle: string;
  readonly titleLabel: string;
  readonly sectionLabel: string;
  readonly colorLabel: string;
  readonly visibilityLabel: string;
}

export const EnglishCourseSectionEditStrings: CourseSectionEditStrings = {
  addTitle: 'New class',
  colorContrastWarning:
    "This color has low contrast. To ensure you can properly see this class' color, consider choosing a different color.",
  editTitle: 'Edit class',
  titleLabel: 'Title',
  sectionLabel: 'Section',
  colorLabel: 'Color',
  visibilityLabel: 'Visible in the planner'
};

export const FrenchCourseSectionEditStrings: CourseSectionEditStrings = {
  addTitle: 'Nouveau cours',
  colorContrastWarning:
    'Cette couleur a un faible contraste. Pour que la couleur du cours soit bien visible, envisagez choisir une couleur différente.',
  editTitle: 'Édition de cours',
  titleLabel: 'Titre',
  sectionLabel: 'Groupe',
  colorLabel: 'Couleur',
  visibilityLabel: 'Visible dans le planificateur'
};
