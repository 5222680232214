import i18n from 'translate';

export const PlannerSettingsClassesStrings = {
  externalSubtitle: () => i18n.t('settings.planner.classes.externalSubtitle'),
  externalTitle: () => i18n.t('settings.planner.classes.externalTitle'),
  personalSubtitle: () => i18n.t('settings.planner.classes.personalSubtitle'),
  personalTitle: () => i18n.t('settings.planner.classes.personalTitle')
};

export const PlannerSettingsCurriculumClassesStrings = {
  emptyMessage: () => i18n.t('settings.planner.curriculumClasses.emptyMessage'),
  errorMessage: () => i18n.t('settings.planner.curriculumClasses.errorMessage'),
  titleOther: () => i18n.t('settings.planner.curriculumClasses.titleOther'),
  titleParent: () => i18n.t('settings.planner.curriculumClasses.titleParent'),
  titleTeacher: () => i18n.t('settings.planner.curriculumClasses.titleTeacher')
};

export const PlannerSettingsStrings = {
  classes: PlannerSettingsClassesStrings,
  curriculumClasses: PlannerSettingsCurriculumClassesStrings
};
