import { useServices } from '@/hooks';
import {
  EditableClassroomConnectedAppViewModel,
  EditableConnectedAppViewModel,
  EditableGoogleCalendarConnectedAppViewModel
} from '@/viewmodels';
import { LinkOffRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { isSxArray } from '../../utils';
import { ConfirmationDialog } from '../utils';
import { ConnectedAppIcon } from './ConnectedAppIcon';
import { SyncInfo } from './SyncInfo';
import { EditableClassroomConnectedApp } from './classroom';
import { EditableGoogleCalendarConnectedApp } from './google-calendar';

export interface EditableConnectedAppProps {
  viewModel: EditableConnectedAppViewModel;
  className?: string;
  sx?: SxProps;
}

export const EditableConnectedApp = observer(({ viewModel, className, sx = [] }: EditableConnectedAppProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.connectedApps;
  const theme = useTheme();

  const [showDisconnectConfirmAlert, setShowDisconnectConfirmAlert] = useState(false);

  async function disconnect(force: boolean) {
    if (viewModel.hasChanges && !force) {
      setShowDisconnectConfirmAlert(true);
    } else {
      await viewModel.disconnect();
    }
  }

  function onDisconnectConfirm(result: boolean) {
    if (result) {
      void disconnect(true);
    }
  }

  return (
    <>
      <Stack
        className={className}
        spacing={1}
        sx={[
          {
            width: '100%'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center',
            px: 2
          }}
        >
          <ConnectedAppIcon kind={viewModel.kind} />

          <Stack
            sx={{
              flex: 1
            }}
          >
            <Typography
              sx={{
                fontWeight: '500'
              }}
            >
              {strings.connectedAppTitle(viewModel.kind)}
            </Typography>

            <SyncInfo
              viewModel={viewModel.syncInfo}
              disabled={viewModel.isApplying}
              textVariant="body2"
              textColor={theme.palette.text.secondary}
            />
          </Stack>
        </Stack>

        <EditableConnectedAppContent viewModel={viewModel} />

        <Box
          sx={{
            px: 2,
            pt: 1
          }}
        >
          <Button
            variant="outlined"
            color="error"
            size="small"
            startIcon={<LinkOffRounded />}
            disabled={viewModel.isApplying || viewModel.hasChanges}
            onClick={() => void disconnect(false)}
            sx={{ alignSelf: 'flex-start' }}
          >
            {strings.disconnect}
          </Button>
        </Box>
      </Stack>
      {showDisconnectConfirmAlert && (
        <ConfirmationDialog
          isOpen={true}
          message={strings.disconnectConfirmationMessage}
          confirmButtonLabel={strings.disconnect}
          onSubmit={onDisconnectConfirm}
        />
      )}
    </>
  );
});

const EditableConnectedAppContent = ({ viewModel, className, sx }: EditableConnectedAppProps) => {
  switch (viewModel.kind) {
    case 'classroom':
      return (
        <EditableClassroomConnectedApp
          className={className}
          sx={sx}
          viewModel={viewModel as EditableClassroomConnectedAppViewModel}
        />
      );

    case 'googleCalendar':
      return (
        <EditableGoogleCalendarConnectedApp
          className={className}
          sx={sx}
          viewModel={viewModel as EditableGoogleCalendarConnectedAppViewModel}
        />
      );

    default:
      return null;
  }
};
