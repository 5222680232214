import i18n from 'translate';

export const PlannedWorkEditStrings = {
  addSessionTitle: () => i18n.t('plannedWork.edit.addSessionTitle'),
  assignStepsSectionTitle: () => i18n.t('plannedWork.edit.assignStepsSectionTitle'),
  completedSessionLabel: () => i18n.t('plannedWork.edit.completedSessionLabel'),
  conflictErrorMessage: () => i18n.t('plannedWork.edit.conflictErrorMessage'),
  datePickerLabel: () => i18n.t('plannedWork.edit.datePickerLabel'),
  editSessionTitle: () => i18n.t('plannedWork.edit.editSessionTitle'),
  endTimePickerLabel: () => i18n.t('plannedWork.edit.endTimePickerLabel'),
  endTimesPresetsTitle: () => i18n.t('plannedWork.edit.endTimesPresetsTitle'),
  moveToPastConfirmationMessage: () => i18n.t('plannedWork.edit.moveToPastConfirmation.message'),
  moveToPastConfirmationSubmit: () => i18n.t('plannedWork.edit.moveToPastConfirmation.submit'),
  moveToPastConfirmationTitle: () => i18n.t('plannedWork.edit.moveToPastConfirmation.title'),
  noDescription: () => i18n.t('plannedWork.edit.noDescription'),
  pastSessionWarning: () => i18n.t('plannedWork.edit.pastSessionWarning'),
  removeSessionButtonLabel: () => i18n.t('plannedWork.edit.removeSessionButtonLabel'),
  resetButtonLabel: () => i18n.t('plannedWork.edit.resetButtonLabel'),
  startTimePickerLabel: () => i18n.t('plannedWork.edit.startTimePickerLabel'),
  subtitle: () => i18n.t('plannedWork.edit.subtitle'),
  title: () => i18n.t('plannedWork.edit.title')
};

export const PlannedWorkStrings = {
  edit: PlannedWorkEditStrings
};
