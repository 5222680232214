import i18n from 'translate';
import { ScheduleCycleCreateStrings } from './ScheduleCycleCreateStrings';
import { ScheduleCycleEditStrings } from './ScheduleCycleEditStrings';
import { ScheduleCycleListStrings } from './ScheduleCycleListStrings';

const ScheduleCycleDetailsStrings = {
  nameSectionTitle: () => i18n.t('scheduleCycle.details.nameSectionTitle')
};

const ScheduleCycleTermEditStrings = {
  createTitle: () => i18n.t('scheduleCycle.term.edit.createTitle'),
  editTitle: () => i18n.t('scheduleCycle.term.edit.editTitle'),
  endDate: () => i18n.t('scheduleCycle.term.edit.endDate'),
  nameLabel: () => i18n.t('scheduleCycle.term.edit.nameLabel'),
  startDateLabel: () => i18n.t('scheduleCycle.term.edit.startDateLabel')
};

const ScheduleCycleTermListStrings = {
  addButtonTitle: () => i18n.t('scheduleCycle.term.list.addButtonTitle'),
  emptyLabel: () => i18n.t('scheduleCycle.term.list.emptyLabel'),
  subtitle: () => i18n.t('scheduleCycle.term.list.subtitle'),
  title: () => i18n.t('scheduleCycle.term.list.title')
};

const ScheduleCycleTermStrings = {
  edit: ScheduleCycleTermEditStrings,
  list: ScheduleCycleTermListStrings
};

const ScheduleCycleUserDashboardPickerStrings = {
  message: () => i18n.t('scheduleCycle.userDashboardPicker.message'),
  plannerExistingScheduleLabel: () => i18n.t('scheduleCycle.userDashboardPicker.plannerExistingScheduleLabel'),
  plannersSectionTitle: () => i18n.t('scheduleCycle.userDashboardPicker.plannersSectionTitle'),
  schoolExistingScheduleLabel: () => i18n.t('scheduleCycle.userDashboardPicker.schoolExistingScheduleLabel'),
  schoolNotOwnerLabel: () => i18n.t('scheduleCycle.userDashboardPicker.schoolNotOwnerLabel'),
  schoolsSectionTitle: () => i18n.t('scheduleCycle.userDashboardPicker.schoolsSectionTitle'),
  submitButton: () => i18n.t('scheduleCycle.userDashboardPicker.submitButton'),
  title: () => i18n.t('scheduleCycle.userDashboardPicker.title')
};

export const ScheduleCycleStrings = {
  create: ScheduleCycleCreateStrings,
  cycleDayTitleWithPrefix: (cycleDay: string) => i18n.t('scheduleCycle.cycleDayTitleWithPrefix', { cycleDay }),
  details: ScheduleCycleDetailsStrings,
  edit: ScheduleCycleEditStrings,
  list: ScheduleCycleListStrings,
  term: ScheduleCycleTermStrings,
  userDashboardPicker: ScheduleCycleUserDashboardPickerStrings
};
