import { useServices } from '@/hooks';
import { Teacher } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_pb';
import { CalendarMonthRounded, InfoRounded, MailRounded } from '@mui/icons-material';
import { Box, ListItem, ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ActionMenu, ActionMenuButton } from '../../../utils';

export interface AdminSchoolClassTeachersListItemProps {
  sx?: SxProps;
  className?: string;
  teacher: Teacher;
  scheduleCycleId?: string;
  isReadOnly: boolean;
}

export const AdminSchoolClassTeachersListItem = observer(
  ({ sx = [], teacher, scheduleCycleId, className, isReadOnly }: AdminSchoolClassTeachersListItemProps) => {
    const { route } = useServices();
    const strings = LocalizedStrings.admin.classes.details;
    const theme = useTheme();

    const teacherDetailsLocation = route.resolveAdminTeacherDetailsLocation(teacher.schoolId, teacher.accountId);
    const teacherScheduleLocation =
      scheduleCycleId != null
        ? route.resolveAdminTeacherScheduleEditLocation(teacher.schoolId, scheduleCycleId, teacher.accountId)
        : undefined;

    const [actionMenuAnchorRef, setActionMenuAnchorRef] = useState<HTMLButtonElement | null>();

    function onActionButtonPressed(e: MouseEvent<HTMLButtonElement>) {
      e.stopPropagation();
      setActionMenuAnchorRef(e.currentTarget);
    }

    return (
      <Box
        className={className}
        sx={{
          ...sx,
          display: 'flex',
          alignItems: 'center',
          pr: 2
        }}
      >
        <ListItem
          sx={{ color: theme.palette.text.primary, '&:hover': { textDecoration: 'underline' } }}
          component={Link}
          to={teacherDetailsLocation}
        >
          <ListItemText primary={teacher.fullName} secondary={teacher.emailAddress} />
        </ListItem>
        <ActionMenuButton onClick={onActionButtonPressed} />
        <ActionMenu
          open={actionMenuAnchorRef != null}
          anchorEl={actionMenuAnchorRef}
          onClose={() => setActionMenuAnchorRef(null)}
        >
          <MenuItem component={Link} to={teacherDetailsLocation} onClick={() => setActionMenuAnchorRef(null)}>
            <ListItemIcon>
              <InfoRounded />
            </ListItemIcon>
            <ListItemText>{strings.showTeacherDetailsOption()}</ListItemText>
          </MenuItem>

          {!isReadOnly && teacherScheduleLocation != null && (
            <MenuItem component={Link} to={teacherScheduleLocation} onClick={() => setActionMenuAnchorRef(null)}>
              <ListItemIcon>
                <CalendarMonthRounded />
              </ListItemIcon>
              <ListItemText>{strings.editTeacherScheduleOption()}</ListItemText>
            </MenuItem>
          )}

          <MenuItem component="a" href={`mailto:${teacher.emailAddress}`}>
            <ListItemIcon>
              <MailRounded />
            </ListItemIcon>
            <ListItemText>{strings.sendEmailToTeacherOption()}</ListItemText>
          </MenuItem>
        </ActionMenu>
      </Box>
    );
  }
);
