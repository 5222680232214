import { useServices } from '@/hooks';
import { CalendarEventDark, CalendarEventLight } from '@/resources/icons/calendarEvent';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { SVGProps } from 'react';
import { isSxArray } from '../../utils';
import { ColorIndicator } from './ColorIndicator';

export interface CalendarEventIconProps {
  color?: string;

  /**
   * The icon size. Optional. Default is `24`.
   */
  size?: number;

  className?: string;

  sx?: SxProps;
}

export const CalendarEventIcon = observer(({ className, sx = [], color, size = 24 }: CalendarEventIconProps) => {
  const { theme } = useServices();

  const svgProps: SVGProps<SVGSVGElement> = {
    width: size,
    height: size
  };

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {color != null && <ColorIndicator color={color} sx={{ mr: 1 }} />}
      {theme.variant === 'light' ? <CalendarEventLight {...svgProps} /> : <CalendarEventDark {...svgProps} />}
    </Box>
  );
});
