import {
  compareTimeOfDays,
  dateToString,
  dateToTimeOfDay,
  Day,
  dayToString,
  isItemDueDuringPeriodOccurrence,
  TimeOfDay,
  timeOfDayIsBetweenOthers
} from '@/models';
import { ApplicationSettingsService, LocalizationService } from '@/services';
import { PlannerTransportService } from '@/transports';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PlannerItem } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { chain } from 'lodash';
import { makeObservable, runInAction } from 'mobx';
import {
  PlannerCalendarStore,
  PlannerCalendarStoreItem,
  PlannerCourseSectionOccurrenceInfo,
  StoreInvalidator
} from '../contracts';
import { AppUserDashboardCalendarStore } from './AppUserDashboardCalendarStore';

export class AppPlannerCalendarStore extends AppUserDashboardCalendarStore<PlannerDay> implements PlannerCalendarStore {
  constructor(
    private readonly _plannerId: string,
    private readonly _localization: LocalizationService,
    private readonly _settings: ApplicationSettingsService,
    private readonly _plannerTransport: PlannerTransportService,
    storeInvalidator: StoreInvalidator
  ) {
    super(storeInvalidator);
    makeObservable(this);
  }

  getOccurrencesForCourseSection(
    courseSectionId: string,
    fromDate: Date,
    toDate: Date
  ): PlannerCourseSectionOccurrenceInfo[] {
    const occurrences: PlannerCourseSectionOccurrenceInfo[] = [];
    let currentDate = fromDate;

    while (differenceInCalendarDays(currentDate, toDate) <= 0) {
      const day = this.days.get(dateToString(currentDate));
      if (day != null) {
        chain(day.items)
          .map((item) => {
            if (item.item.case === 'courseSectionOccurrence') {
              const occurrence = item.item.value;

              if (occurrence.courseSectionId === courseSectionId) {
                return occurrence;
              }
            }

            return null;
          })
          .compact()
          .forEach((occurrence) => occurrences.push({ date: currentDate, occurrence }))
          .value();
      }

      currentDate = addDays(currentDate, 1);
    }

    return occurrences;
  }

  getCourseSectionOccurrencesStartTimeForDate(courseSectionId: string, date: Date): TimeOfDay[] {
    const day = this.days.get(dateToString(date));

    if (day == null || courseSectionId.length === 0) {
      return [];
    }

    return chain(day.items)
      .map((item) => {
        if (item.item.case === 'courseSectionOccurrence') {
          const occurrence = item.item.value;

          if (occurrence.courseSectionId === courseSectionId) {
            return occurrence.startTime;
          }
        }

        return null;
      })
      .compact()
      .value();
  }

  getCourseSectionOccurrence(
    date: Date,
    startTime: TimeOfDay,
    endTime: TimeOfDay,
    periodLabel: string,
    courseSectionId: string
  ): CourseSectionOccurrence {
    const dateString = dateToString(date);
    const day = this.days.get(dateString);

    if (day == null) {
      throw new Error(`No day found for date ${dateString}. Make sure to fetch that day first.`);
    }

    const foundOccurrence = day.items.find((item) => {
      if (item.item.case !== 'courseSectionOccurrence') {
        return false;
      }

      const occurrence = item.item.value;
      return (
        compareTimeOfDays(occurrence.startTime!, startTime) === 0 &&
        compareTimeOfDays(occurrence.endTime!, endTime) === 0 &&
        occurrence.periodLabel === periodLabel &&
        occurrence.courseSectionId === courseSectionId
      );
    });

    if (foundOccurrence != null) {
      return foundOccurrence.item.value as CourseSectionOccurrence;
    }

    throw new Error('No course occurrence found found for provided date and times.');
  }

  getItemsDueInOccurrence(date: Date, occurrence: CourseSectionOccurrence): PlannerCalendarStoreItem[] {
    const dateString = dateToString(date);
    const day = this.days.get(dateString);

    if (day == null) {
      throw new Error(`No day found for date ${dateString}. Make sure to fetch that day first.`);
    }

    return chain(day.items)
      .map((item) => {
        if (
          (item.item.case !== 'work' && item.item.case !== 'publishedWork' && item.item.case !== 'note') ||
          item.plannedWork != null
        ) {
          return null;
        }

        const dueTime = this.getTimeForItem(item);
        return dueTime != null &&
          isItemDueDuringPeriodOccurrence(dueTime, item.item.value.courseSectionId, occurrence, day.items)
          ? item.item
          : null;
      })
      .compact()
      .value();
  }

  getCourseSectionsOccurrencesForDate(date: Date): CourseSectionOccurrence[] {
    const dateString = dateToString(date);
    const day = this.days.get(dateString);

    if (day == null) {
      throw new Error(`No day found for date ${dateString}. Make sure to fetch that day first.`);
    }

    const timeOfDay = dateToTimeOfDay(date);

    return chain(day.items)
      .map((item) => {
        if (
          item.item.case === 'courseSectionOccurrence' &&
          timeOfDayIsBetweenOthers(timeOfDay, item.item.value.startTime!, item.item.value.endTime!, {
            endInclusive: true
          })
        ) {
          return item.item.value;
        }

        return undefined;
      })
      .compact()
      .value();
  }

  protected async loadData(startDay: Day, endDay: Day): Promise<PlannerDay[]> {
    const dailyContents = await this._plannerTransport.getPlannerDailyContents(
      this._plannerId,
      startDay,
      endDay,
      this._settings.calendarShowFreePeriods,
      this._localization.currentTimezone
    );
    runInAction(() => (this._moreRecentDraftScheduleCycleIds = dailyContents.moreRecentDraftScheduleCycleIds));
    return dailyContents.days;
  }

  protected keyForDayContent(dayContent: PlannerDay): string {
    return dayToString(dayContent.day!);
  }

  private getTimeForItem(item: PlannerItem): Timestamp | undefined {
    switch (item.item.case) {
      case 'note':
        return item.item.value.time;

      case 'publishedWork':
        return item.item.value.publishedWork?.dueTime;

      case 'work':
        return item.item.value.dueTime;
    }
  }
}
