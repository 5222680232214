import { UserDashboardCalendarEventInfo } from '@/viewmodels';
import { Box, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface UserDashboardCalendarAllDayCalendarEventProps {
  sx?: SxProps;
  className?: string;
  event: UserDashboardCalendarEventInfo;
}

export const UserDashboardCalendarAllDayCalendarEvent = observer(
  ({ sx = [], className, event }: UserDashboardCalendarAllDayCalendarEventProps) => {
    const theme = useTheme();
    const color = event.color || theme.palette.action.focus;

    return (
      <Box className={className} sx={sx}>
        <CardActionArea
          sx={{
            borderRadius: 0.5,
            px: 0.5,
            backgroundColor: color,
            color: event.color.length > 0 ? theme.palette.getContrastText(color) : undefined
          }}
          component="a"
          href={event.externalUrl}
          disabled={event.externalUrl == null}
          target="_blank"
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center'
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontWeight: '500'
              }}
            >
              {event.title}
            </Typography>
          </Stack>
        </CardActionArea>
      </Box>
    );
  }
);
