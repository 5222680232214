import { ScheduleCycleInfo } from '@/models';
import { ScheduleCycleTransportService } from '@/transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type ScheduleCyclesLoadable = LoadableValue<ScheduleCycleInfo[]>;

export class AppScheduleCyclesLoadable
  extends BaseLoadableValue<ScheduleCycleInfo[]>
  implements ScheduleCyclesLoadable
{
  constructor(
    private readonly _scheduleCycleIds: string[],
    private readonly _scheduleCycleTransport: ScheduleCycleTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<ScheduleCycleInfo[]> {
    return this._scheduleCycleTransport.getScheduleCycles(this._scheduleCycleIds);
  }
}
