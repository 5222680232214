import { ServiceContainer } from '@/providers';
import {
  CurriculumCoursesLoadable,
  CurriculumDataStore,
  CurriculumEnrolledCourseIdsInPlannerLoadable,
  Loadable,
  PlannerDataStore,
  PlannerDetailedCourseSectionsLoadable,
  UserDataStore
} from '@/stores';
import { Course } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/resources/course_pb';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable } from 'mobx';
import LocalizedStrings from 'strings';
import { curriculumCoursesForUser, plannerHasAccessKindsForUser } from '../../models';
import { LocalizationService } from '../../services';
import { localizedCompareWithProperties } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface PlannerCurriculumCourseInfo {
  readonly course: Course;
  readonly isEnrolled: boolean;
}

export interface PlannerCurriculumCoursesViewModel extends UpdatableViewModel {
  readonly isReadOnly: boolean;
  readonly title: string;
  readonly courses: PlannerCurriculumCourseInfo[];
  toggleEnrollmentOfCourse(courseId: string): Promise<void>;
}

export class AppPlannerCurriculumCoursesViewModel
  extends BaseUpdatableViewModel
  implements PlannerCurriculumCoursesViewModel
{
  constructor(
    private readonly _plannerId: string,
    private readonly _curriculumStore: CurriculumDataStore = ServiceContainer.services.curriculumStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  private get curriculumCoursesLoadable(): CurriculumCoursesLoadable {
    return this._curriculumStore.getCourses();
  }

  @computed
  private get plannerCourseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get enrolledCourseIdsLoadable(): CurriculumEnrolledCourseIdsInPlannerLoadable {
    return this._curriculumStore.getEnrolledCourseIdsInPlanner(this._plannerId);
  }

  @computed
  private get hasTaughtClasses(): boolean {
    return this.plannerCourseSectionsLoadable.values.some((cs) => cs.role === CourseSectionRole.TEACHER);
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.curriculumCoursesLoadable, this.plannerCourseSectionsLoadable, this.enrolledCourseIdsLoadable];
  }

  @computed
  get isReadOnly(): boolean {
    return !plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get title(): string {
    const strings = LocalizedStrings.settings.planner.curriculumClasses;

    if (this.hasTaughtClasses) {
      return strings.titleTeacher();
    }

    switch (this._userStore.user.persona) {
      case UserPersona.TEACHER:
        return strings.titleTeacher();

      case UserPersona.PARENT:
        return strings.titleParent();

      case UserPersona.STUDENT:
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
        return strings.titleOther();
    }
  }

  @computed
  get courses(): PlannerCurriculumCourseInfo[] {
    const curriculumCourses = this.curriculumCoursesLoadable.values;
    const enrolledCourseIds = this.enrolledCourseIdsLoadable.data;

    const resolvedCourse = curriculumCoursesForUser(
      curriculumCourses,
      this._userStore.user.persona,
      this.hasTaughtClasses
    );

    return resolvedCourse
      .map((course) => ({ course, isEnrolled: enrolledCourseIds.includes(course.id) }))
      .sort((c1, c2) =>
        localizedCompareWithProperties(
          [
            { value1: c1.course.title, value2: c2.course.title },
            { value1: c1.course.description, value2: c2.course.description }
          ],
          this._localization.currentLocale
        )
      );
  }

  async toggleEnrollmentOfCourse(courseId: string) {
    const enrolledCourseIds = Array.from(this.enrolledCourseIdsLoadable.data);
    const existingIndex = enrolledCourseIds.indexOf(courseId);

    if (existingIndex >= 0) {
      enrolledCourseIds.splice(existingIndex, 1);
    } else {
      enrolledCourseIds.push(courseId);
    }

    await this._curriculumStore.setEnrolledCourseIdsInPlanner(this._plannerId, enrolledCourseIds);
  }
}
