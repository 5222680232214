import { Planners } from '@buf/studyo_studyo-today-planners.connectrpc_es/studyo/today/planners/v1/planners_connect';
import { TeacherPreferences } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_preferences_pb';
import { Schools } from '@buf/studyo_studyo-today-schools.connectrpc_es/studyo/today/schools/v1/schools_connect';
import { EmotionalPulse } from '@buf/studyo_studyo-today-sel.bufbuild_es/studyo/today/sel/v1/resources/emotional_pulse_pb';
import { Sel } from '@buf/studyo_studyo-today-sel.connectrpc_es/studyo/today/sel/v1/sel_connect';
import { Platform } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/platform_pb';
import { PreferenceValue } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/preference_value_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { Users } from '@buf/studyo_studyo-today-users.connectrpc_es/studyo/today/users/v1/users_connect';
import { UserPreferenceKey } from '../../models';
import { AutoDetectPersonaAndCreatePlannerResponse, UserTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppUserTransportService extends AppBaseTransportService implements UserTransportService {
  async assignPersona(persona: UserPersona): Promise<UserProfile> {
    return await this.performWriteRequest('assignPersona', Users, Users.methods.assignPersona, { persona });
  }

  async completeOnboardingProcess(schoolIds: string[] | undefined): Promise<void> {
    await this.performReadRequest(
      'completeOnboardingProcess',
      Users,
      Users.methods.completeOnboardingProcess,
      {},
      schoolIds != null ? { customHeaders: { 'X-Today-School-Ids': schoolIds.join('|') } } : undefined
    );
  }

  async getCurrentEmotionalPulse(): Promise<EmotionalPulse | undefined> {
    const response = await this.performReadRequest(
      'getCurrentEmotionalPulse',
      Sel,
      Sel.methods.getCurrentEmotionalPulse,
      {}
    );

    return response.emotionalPulse;
  }

  async setEmotionalState(rating: number): Promise<EmotionalPulse> {
    return await this.performWriteRequest('setEmotionalState', Sel, Sel.methods.setEmotionalState, { rating });
  }

  async getCurrentUserProfile(): Promise<UserProfile> {
    return await this.performReadRequest('getCurrentUserProfile', Users, Users.methods.getCurrentUserProfile, {});
  }

  async autoDetectPersonaAndCreatePlanner(): Promise<AutoDetectPersonaAndCreatePlannerResponse> {
    const response = await this.performWriteRequest(
      'autoDetectPersonaAndCreatePlanner',
      Planners,
      Planners.methods.autoDetectPersonaAndCreatePlanner,
      {}
    );

    return {
      personaAutoDetectedAndPlannerCreated: response.personaAutoDetectedAndPlannerCreated,
      sharedParticipatingSchoolIds: response.sharedParticipatingSchoolIds
    };
  }

  async getIntercomUserHash(): Promise<string> {
    const response = await this.performReadRequest(
      'getIntercomUserHash',
      Users,
      Users.methods.getPlatformIntercomUserHash,
      { platform: Platform.WEB }
    );
    return response.userHash;
  }

  async setCurrentUserCulture(cultureName: string): Promise<void> {
    await this.performWriteRequest('setCurrentUserCulture', Users, Users.methods.setCurrentUserCulture, {
      cultureName
    });
  }

  async getTeacherPreferences(): Promise<TeacherPreferences> {
    return await this.performReadRequest('getTeacherPreferences', Schools, Schools.methods.getTeacherPreferences, {});
  }

  async updateTeacherPreferences(userId: string, showDemoCourseSection: boolean): Promise<TeacherPreferences> {
    return await this.performWriteRequest(
      'updateTeacherPreferences',
      Schools,
      Schools.methods.updateTeacherPreferences,
      { userId, showDemoCourseSection }
    );
  }

  async getPreference(key: UserPreferenceKey): Promise<PreferenceValue | undefined> {
    const response = await this.performReadRequest('getPreference', Users, Users.methods.getPreference, { key });
    return response.value;
  }

  async setPreference(key: UserPreferenceKey, value: PreferenceValue | undefined): Promise<void> {
    await this.performWriteRequest('setPreference', Users, Users.methods.setPreference, { key, value });
  }

  async createProfilePictureDestination(filename: string): Promise<{ uploadUrl: string; downloadUrl: string }> {
    return await this.performWriteRequest(
      'createProfilePictureDestination',
      Users,
      Users.methods.createProfilePictureDestination,
      { filename }
    );
  }

  async customizeCurrentUser(fullName: string, pictureUrl: string): Promise<void> {
    await this.performWriteRequest('customizeCurrentUser', Users, Users.methods.customizeCurrentUser, {
      fullName,
      pictureUrl
    });
  }

  async permanentlyDeleteUserData(userId: string): Promise<void> {
    await this.performWriteRequest('permanentlyDeleteUserData', Users, Users.methods.permanentlyDeleteUserData, {
      userId
    });
  }
}
