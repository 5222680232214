import { useServices } from '@/hooks';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import OtherIcon from '@mui/icons-material/QuestionMarkRounded';
import TeacherIcon from '@mui/icons-material/RecordVoiceOverRounded';
import StudentIcon from '@mui/icons-material/SchoolRounded';
import ParentIcon from '@mui/icons-material/SupervisorAccountRounded';
import { SvgIconPropsColorOverrides, Tooltip } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

export interface PlannerIconProps {
  /**
   * The user relationship with the planner.
   */
  relationship: PlannerRelationshipKind;

  /**
   * The color of the underlying SvgIcon (MUI).
   */
  color?: OverridableStringUnion<
    'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    SvgIconPropsColorOverrides
  >;

  /**
   * Determines if a tooltip should be displayed when hovering the icon.
   */
  showTooltip?: true;

  size?: 'inherit' | 'large' | 'medium' | 'small';
}

const PlannerIconOnly = ({ relationship, color, size }: PlannerIconProps) => {
  switch (relationship) {
    case PlannerRelationshipKind.STUDENT:
    case PlannerRelationshipKind.INDIVIDUAL:
      return <StudentIcon color={color} fontSize={size} />;
    case PlannerRelationshipKind.PARENT:
      return <ParentIcon color={color} fontSize={size} />;
    case PlannerRelationshipKind.TEACHER:
      return <TeacherIcon color={color} fontSize={size} />;
    case PlannerRelationshipKind.CREATOR:
    case PlannerRelationshipKind.UNSPECIFIED:
    default:
      return <OtherIcon color={color} fontSize={size} />;
  }
};

export const PlannerIcon = ({ showTooltip, relationship, ...props }: PlannerIconProps) => {
  const { localization } = useServices();

  return showTooltip === true ? (
    <Tooltip title={localization.localizedStrings.planners.relationshipKindTooltip(relationship)}>
      <div>
        <PlannerIconOnly relationship={relationship} {...props} />
      </div>
    </Tooltip>
  ) : (
    <PlannerIconOnly relationship={relationship} {...props} />
  );
};
