import { ConnectedAppKind } from '@/models';
import { ButtonProps } from '@mui/material';
import { GoogleLoginButton } from '../utils';

export interface ConnectedAppConnectButtonProps extends ButtonProps {
  /**
   * The connected app kind. Used to determine which button to render.
   */
  kind: ConnectedAppKind;
}

export const ConnectedAppConnectButton = ({ kind, ...props }: ConnectedAppConnectButtonProps) => {
  switch (kind) {
    case 'classroom':
    case 'googleCalendar':
      return <GoogleLoginButton small {...props} />;
  }
};
