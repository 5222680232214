import { UserDashboardCalendarAnnotationInfo } from '@/viewmodels';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarAnnotationView } from '../shared';

export interface UserDashboardCalendarDayAnnotationsViewProps {
  sx?: SxProps;
  className?: string;
  annotations: UserDashboardCalendarAnnotationInfo[];
}

export const UserDashboardCalendarDayAnnotationsView = observer(
  ({ sx = [], className, annotations }: UserDashboardCalendarDayAnnotationsViewProps) => {
    return (
      <Box
        className={className}
        sx={{
          display: 'flex',
          px: 2,
          width: '100%',
          flexWrap: 'wrap',
          ...sx,
          rowGap: 1
        }}
      >
        {annotations.map((a) => (
          <UserDashboardCalendarAnnotationView key={a.id} annotation={a} sx={{ mr: 1 }} />
        ))}
      </Box>
    );
  }
);
