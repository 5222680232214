export interface CalendarFilters {
  annotations: boolean;
  calendarEvents: boolean;
  periods: boolean;
  works: boolean;
  plannedWorks: boolean;
  notes: boolean;
}

export type CalendarWeekDensity = 'small' | 'regular' | 'large';

export const DefaultCalendarWeekFilters: CalendarFilters = {
  annotations: true,
  calendarEvents: true,
  periods: true,
  works: true,
  plannedWorks: true,
  notes: true
};

export const DefaultCalendarMonthFilters: CalendarFilters = {
  annotations: true,
  calendarEvents: false,
  periods: false,
  works: true,
  plannedWorks: false,
  notes: false
};

export interface CalendarDayOptions {
  alwaysShowCurrentDatePastItems: boolean;
  expandWorkDetails: boolean;
}
