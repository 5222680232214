import i18n from 'translate';

export const AdminScheduleClassSchedulesStrings = {
  currentSelectionLabel: () => i18n.t('admin.schedule.classSchedules.currentSelectionLabel'),
  noClassesForTeacherMessage: () => i18n.t('admin.schedule.classSchedules.noClassesForTeacherMessage'),
  noSchedulesForCourseMessage: () => i18n.t('admin.schedule.classSchedules.noSchedulesForCourseMessage'),
  noSelectionMessage: () => i18n.t('admin.schedule.classSchedules.noSelectionMessage'),
  searchCoursesSectionTitle: () => i18n.t('admin.schedule.classSchedules.searchCoursesSectionTitle'),
  searchLabel: () => i18n.t('admin.schedule.classSchedules.searchLabel'),
  searchTeachersSectionTitle: () => i18n.t('admin.schedule.classSchedules.searchTeachersSectionTitle'),
  teacherDefaultName: () => i18n.t('admin.schedule.classSchedules.teacherDefaultName'),
  title: () => i18n.t('admin.schedule.classSchedules.title'),
  viewAs: () => i18n.t('admin.schedule.classSchedules.viewAs'),
  viewAsListOption: () => i18n.t('admin.schedule.classSchedules.viewAsListOption'),
  viewAsMasterScheduleOption: () => i18n.t('admin.schedule.classSchedules.viewAsMasterScheduleOption')
};

export const AdminScheduleDetailsStrings = {
  createCopyOptionTitle: () => i18n.t('admin.schedule.details.createCopyOptionTitle'),
  deleteConfirmationMessage: () => i18n.t('admin.schedule.details.deleteConfirmationMessage'),
  deleteConfirmationSubmitTitle: () => i18n.t('admin.schedule.details.deleteConfirmationSubmitTitle'),
  deleteConfirmationTitle: () => i18n.t('admin.schedule.details.deleteConfirmationTitle'),
  deleteOptionTitle: () => i18n.t('admin.schedule.details.deleteOptionTitle'),
  title: () => i18n.t('admin.schedule.details.title')
};
export const AdminScheduleNoScheduleStrings = {
  createButtonTitle: () => i18n.t('admin.schedule.noSchedule.createButtonTitle'),
  subtitle: () => i18n.t('admin.schedule.noSchedule.subtitle'),
  title: () => i18n.t('admin.schedule.noSchedule.title')
};

export const AdminSchedulePendingChangesDiscardConfirmationStrings = {
  cancelButtonLabel: () => i18n.t('admin.schedule.pendingChanges.discardChangesConfirmation.cancelButtonLabel'),
  message: () => i18n.t('admin.schedule.pendingChanges.discardChangesConfirmation.message'),
  submitButtonLabel: () => i18n.t('admin.schedule.pendingChanges.discardChangesConfirmation.submitButtonLabel'),
  title: () => i18n.t('admin.schedule.pendingChanges.discardChangesConfirmation.title')
};

export const AdminSchedulePendingChangesStrings = {
  discardChangesButtonTitle: () => i18n.t('admin.schedule.pendingChanges.discardChangesButtonTitle'),
  discardChangesConfirmation: AdminSchedulePendingChangesDiscardConfirmationStrings,
  discardErrorMessage: () => i18n.t('admin.schedule.pendingChanges.discardErrorMessage'),
  publishChangesButtonTitle: () => i18n.t('admin.schedule.pendingChanges.publishChangesButtonTitle'),
  publishDroppedChangesMessage: () => i18n.t('admin.schedule.pendingChanges.publishChangesButtonTitle'),
  publishErrorMessage: () => i18n.t('admin.schedule.pendingChanges.publishErrorMessage'),
  subtitle: () => i18n.t('admin.schedule.pendingChanges.subtitle'),
  title: () => i18n.t('admin.schedule.pendingChanges.title'),
  undoButtonTooltip: () => i18n.t('admin.schedule.pendingChanges.undoButtonTooltip'),
  undoErrorMessage: () => i18n.t('admin.schedule.pendingChanges.undoErrorMessage')
};

export const AdminScheduleSidebarStrings = {
  bellTimes: () => i18n.t('admin.schedule.sidebar.bellTimes'),
  calendar: () => i18n.t('admin.schedule.sidebar.calendar'),
  classSchedules: () => i18n.t('admin.schedule.sidebar.classSchedules'),
  details: () => i18n.t('admin.schedule.sidebar.details')
};

export const AdminScheduleStrings = {
  bellTimesTitle: () => i18n.t('admin.schedule.bellTimesTitle'),
  calendarTitle: () => i18n.t('admin.schedule.calendarTitle'),
  classSchedules: AdminScheduleClassSchedulesStrings,
  createSchedule: () => i18n.t('admin.schedule.createSchedule'),
  details: AdminScheduleDetailsStrings,
  listTitle: () => i18n.t('admin.schedule.listTitle'),
  noSchedule: AdminScheduleNoScheduleStrings,
  pendingChanges: AdminSchedulePendingChangesStrings,
  sidebar: AdminScheduleSidebarStrings,
  title: () => i18n.t('admin.schedule.title')
};
