import { useServices } from '@/hooks';
import microsoftEn from '@/resources/images/login/microsoft_en.png';
import microsoftFr from '@/resources/images/login/microsoft_fr.png';
import { Button, ButtonProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

export type MicrosoftLoginButtonProps = ButtonProps;

export const MicrosoftLoginButton = observer(({ className, ...props }: MicrosoftLoginButtonProps) => {
  const { localization } = useServices();

  const buttonImgSrc = useMemo(() => {
    switch (localization.currentLocale) {
      case 'fr':
        return microsoftFr;

      case 'en':
      default:
        return microsoftEn;
    }
  }, [localization.currentLocale]);

  return (
    <Button {...props} className={className} sx={{ padding: 0, width: 'fit-content' }}>
      <img src={buttonImgSrc} alt="Microsoft Login Button" style={{ width: 240 }} />
    </Button>
  );
});
