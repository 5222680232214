import i18n from 'translate';

export const CalendarMonthOptionsStrings = {
  showFreePeriods: () => i18n.t('calendar.month.options.showFreePeriods'),
  showOptionsSectionTitle: () => i18n.t('calendar.month.options.showOptionsSectionTitle'),
  showPeriodLabels: () => i18n.t('calendar.month.options.showPeriodLabels'),
  title: () => i18n.t('calendar.month.options.title')
};

export const CalendarMonthStrings = {
  dayItems: (count: number) => i18n.t('calendar.month.dayItems', { count }),
  dayMoreItems: (count: number) => i18n.t('calendar.month.dayMoreItems', { count }),
  dayMorePopoverTitle: () => i18n.t('calendar.month.dayMorePopoverTitle'),
  options: CalendarMonthOptionsStrings,
  periodOccurrenceNoClass: () => i18n.t('calendar.month.periodOccurrenceNoClass')
};
