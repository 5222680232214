import { useServices } from '@/hooks';
import { StudentInsightsViewModel } from '@/viewmodels';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { UpdatablePresenter } from '../../../utils';
import { StudentActivityChart } from './StudentActivityChart';
import { StudentInsightsTabs } from './StudentInsightsTabs';
import { StudentInsightsWorksList } from './StudentInsightsWorksList';
import { StudentInsightsWorksTable } from './StudentInsightsWorksTable';

export interface StudentInsightsProps {
  viewModel: StudentInsightsViewModel;
  sx?: SxProps;
  className?: string;
}

export const StudentInsights = observer(({ viewModel, sx }: StudentInsightsProps) => {
  const { settings } = useServices();
  const theme = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const smallScreenBreakpoint = settings.isSchoolSidebarCollapsed ? 'sm' : 'md';
  const showTabs = useMediaQuery(theme.breakpoints.down(smallScreenBreakpoint));

  return (
    <UpdatablePresenter
      sx={{ ...sx, flex: 1, height: '100%', overflow: 'hidden' }}
      isFlex
      viewModel={viewModel}
      renderData={() => (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            flex: 1,
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
            width: '100%'
          }}
        >
          {showTabs && (
            <StudentInsightsTabs
              currentTabIndex={currentTabIndex}
              onTabIndexChange={handleTabChange}
              sx={{ position: 'relative', top: 0, left: 0, right: 0 }}
            />
          )}

          {showTabs && currentTabIndex === 0 && (
            <Box
              sx={{
                flex: 1
              }}
            >
              {/* This is the only way I found for this section only being scrollable instead of the whole page. */}
              {/* However it breaks the layout on larger screens so only using it when tabs are visible. */}
              <AutoSizer>
                {({ width, height }) => (
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{ overflowY: 'auto', width, height: { xs: height, sm: 500 } }}
                  >
                    <StudentActivityChart viewModel={viewModel.userActivity} smallScreenWidth={smallScreenBreakpoint} />
                    {!showTabs && <StudentInsightsWorksTable viewModel={viewModel.works} />}
                  </Stack>
                )}
              </AutoSizer>
            </Box>
          )}

          {!showTabs && (
            <Stack
              direction="column"
              spacing={2}
              sx={{
                flex: 1,
                display: 'flex',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <StudentActivityChart viewModel={viewModel.userActivity} smallScreenWidth={smallScreenBreakpoint} />

              <StudentInsightsWorksTable viewModel={viewModel.works} />
            </Stack>
          )}

          {showTabs && currentTabIndex === 1 && (
            <StudentInsightsWorksList sx={{ flex: 1 }} viewModel={viewModel.works} />
          )}
        </Stack>
      )}
    />
  );
});
