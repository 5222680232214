import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { ParticipatingSchoolsLoadable, UserDataStore } from '../../stores';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface PlannerAttachSchoolPickerSchoolInfo {
  readonly school: SchoolInformation;
  readonly isAlreadyAttached: boolean;
}

export interface PlannerAttachSchoolPickerDialogViewModel extends UpdatableDialogViewModel {
  readonly schools: PlannerAttachSchoolPickerSchoolInfo[];
  readonly selectedSchoolId: string | undefined;
  selectSchool(id: string): void;
}

export class AppPlannerAttachSchoolPickerDialogViewModel
  extends AppBaseUpdatableDialogViewModel
  implements PlannerAttachSchoolPickerDialogViewModel
{
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration(
    'main',
    this._localization,
    () => this.apply(),
    () => LocalizedStrings.settings.school.list.linkDialog.saveButtonTitle()
  );
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  @observable private _selectedSchoolId: string | undefined;
  @observable private _isApplying = false;

  constructor(
    private readonly _plannerId: string,
    close: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(localization, close);
    makeObservable(this);
  }

  @computed
  private get schoolsLoadable(): ParticipatingSchoolsLoadable {
    return this._userStore.participatingSchools;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isApplying;
    this._saveButtonConfig.isEnabled = !this._isApplying && this._selectedSchoolId != null;
    this._saveButtonConfig.showLoading = this._isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get schools(): PlannerAttachSchoolPickerSchoolInfo[] {
    return this.schoolsLoadable.values.map((school) => ({
      school,
      isAlreadyAttached: school.connectedPlannerIds.includes(this._plannerId)
    }));
  }

  @computed
  get selectedSchoolId(): string | undefined {
    return this._selectedSchoolId;
  }

  readonly hasChanges = false;

  @computed
  get hasData(): boolean {
    return this.schoolsLoadable.hasData;
  }

  @computed
  get state(): UpdatableViewModelState {
    return this.schoolsLoadable.state;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isApplying;
  }

  async reloadData(): Promise<void> {
    await this.schoolsLoadable.fetch(true);
  }

  @action
  selectSchool(id: string) {
    if (this._selectedSchoolId === id) {
      this._selectedSchoolId = undefined;
    } else {
      this._selectedSchoolId = id;
    }
  }

  private async apply() {
    if (this._selectedSchoolId == null) {
      return;
    }

    runInAction(() => (this._isApplying = true));
    await this._userStore.attachSchoolToPlanner(this._selectedSchoolId, this._plannerId, true);
    await this.dismiss();
  }
}
