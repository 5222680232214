import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UpdatableViewModel, UpdatableViewModelState } from './UpdatableViewModel';

export interface ObservableDataViewModel<T> extends UpdatableViewModel {
  readonly value: T | undefined;
}

export class AppObservableDataViewModel<T> implements ObservableDataViewModel<T> {
  @observable private _state: UpdatableViewModelState = 'pending';
  @observable private _data: T | undefined;

  constructor(private _loadData: () => Promise<T>) {
    makeObservable(this);
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  @computed
  get hasData(): boolean {
    return this._data != null;
  }

  @computed
  get value(): T | undefined {
    return this._data;
  }

  async reloadData() {
    runInAction(() => {
      this._state = 'pending';
      this._data = undefined;
    });

    try {
      const data = await this._loadData();
      runInAction(() => {
        this._data = data;
        this._state = 'fulfilled';
      });
    } catch (e) {
      runInAction(() => {
        this._data = undefined;
        this._state = e as Error;
      });
    }
  }
}
