import { StudentBehavior } from '@/viewmodels';
import { Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { StudentBehaviorDot } from './StudentBehaviorDot';

export interface StudentBehaviorItems {
  readonly inbox: StudentBehavior;
  readonly dailyActivity: StudentBehavior;
  readonly lastEmotionalStatus: StudentBehavior;
}

export interface StudentBehaviorsProps {
  sx?: SxProps;
  className?: string;
  isCompact: boolean;
  behaviors: StudentBehaviorItems;
}

export const StudentBehaviors = observer(({ sx = [], className, isCompact, behaviors }: StudentBehaviorsProps) => {
  const theme = useTheme();
  const dotSize = isCompact ? 20 : 30;

  const sortedBehaviors = [behaviors.inbox, behaviors.dailyActivity, behaviors.lastEmotionalStatus];

  return (
    <Stack
      direction="row"
      className={className}
      spacing={0.1}
      sx={{
        p: 0.5,
        ...sx,
        backgroundColor: theme.palette.divider,
        borderRadius: 1
      }}
    >
      {sortedBehaviors.map((behavior) => (
        <StudentBehaviorDot
          key={`student-behaviors-dow-${behavior.kind}`}
          isCompact={isCompact}
          size={dotSize}
          behavior={behavior}
        />
      ))}
    </Stack>
  );
});
