import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';

export interface AdminDemoSchoolCreateSnapshotActionProps {
  sx?: SxProps;
  className?: string;
  onCreate: (name: string) => Promise<void>;
}

export const AdminDemoSchoolCreateSnapshotAction = observer(
  ({ sx = [], className, onCreate }: AdminDemoSchoolCreateSnapshotActionProps) => {
    const strings = LocalizedStrings.admin.general.manageActions.snapshots;

    const [name, setName] = useState('');
    const [isCreating, setIsCreating] = useState(false);

    async function create() {
      setIsCreating(true);

      try {
        await onCreate(name);
      } finally {
        setIsCreating(false);
      }
    }

    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              flex: 1
            }}
          >
            <TextField
              label={strings.createNameLabel()}
              value={name}
              placeholder={strings.createNamePlaceholder()}
              fullWidth
              onChange={(e) => setName(e.target.value)}
              disabled={isCreating}
            />
          </Stack>
          <LoadingButton
            onClick={() => void create()}
            sx={{ minWidth: 150, maxWidth: 250 }}
            color="primary"
            variant="contained"
            disabled={isCreating || name.length === 0}
            loading={isCreating}
          >
            {strings.createButton()}
          </LoadingButton>
        </Stack>
      </Stack>
    );
  }
);
