import { useActivePlannerId, usePlannerUserHasAccess, useServices, useViewModel } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { SettingsContent } from '../../shared';
import { SharePlannerStatus } from '../management';

export interface PlannerAccessManagementProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerAccessManagement = observer(({ sx = [], className }: PlannerAccessManagementProps) => {
  const { localization } = useServices();
  const plannerId = useActivePlannerId();
  const viewModel = useViewModel((viewModels) => viewModels.createSharePlannerStatusViewModel(plannerId), [plannerId]);
  const hasAccess = usePlannerUserHasAccess([AccessKind.FULL_ACCESS]);

  return (
    <SettingsContent sx={sx} className={className}>
      {hasAccess ? (
        <SharePlannerStatus viewModel={viewModel} />
      ) : (
        <Typography
          variant="subtitle2"
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {localization.localizedStrings.sharingInvitationCode.readOnlyLabel}
        </Typography>
      )}
    </SettingsContent>
  );
});
