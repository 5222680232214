import { autorun } from 'mobx';
import { UnleashClient } from 'unleash-proxy-client';
import { EnvironmentService, FeatureFlagService, FeatureName, UserService } from '../contracts';

export class UnleashFeatureFlagService implements FeatureFlagService {
  private unleashClient: UnleashClient;

  constructor(
    environment: EnvironmentService,
    private readonly _userService: UserService
  ) {
    this.unleashClient = new UnleashClient({
      url: environment.unleash.url,
      clientKey: environment.unleash.clientKey,
      appName: environment.unleash.appName
    });

    autorun(() => {
      const currentUser = this._userService.currentUser;
      void this.unleashClient.updateContext({ userId: currentUser?.userId });
    });

    void this.unleashClient.start();
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  isEnabled(featureName: FeatureName | string): boolean {
    return this.unleashClient.isEnabled(featureName);
  }
}
