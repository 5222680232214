import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Forces the caret to stay where it's supposed to be in text fields
 * when the actual value and the text field value are not the same.
 * This can happen when `event.target.value` is mutated inside the `onChange` callback.
 * In this case, the caret would go to the end of the text field every time the user types a key,
 * but this prevents it and makes it stay in place where it's supposed to be
 * @see https://www.npmjs.com/package/react-use-caret-position/v/1.0.1
 */
export function useCaretPosition<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>() {
  const ref = useRef<T>(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  /**
   * No choice to add this, otherwise the `ref` would not be updated
   * after calling `updateCaret` when the wanted `start` and `end` did not change
   */
  const [forceUpdate, setForceUpdate] = useState(false);

  const updateCaret = useCallback((diff = 0) => {
    if (ref.current != null) {
      const { selectionStart, selectionEnd } = ref.current;

      setStart(selectionStart! + diff);
      setEnd(selectionEnd! + diff);
      setForceUpdate(true);
    }
  }, []);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.setSelectionRange(start, end);
      setForceUpdate(false);
    }
  }, [start, end, forceUpdate]);

  return { ref, updateCaret };
}
