import { useServices } from '@/hooks';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  addWeeks,
  endOfWeek,
  format,
  intlFormat,
  isSameMonth,
  isSameYear,
  isThisWeek,
  startOfWeek,
  subWeeks
} from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { UserDashboardCalendarHeaderView } from '../shared';
import { UserDashboardCalendarWeekFiltersPopover } from './UserDashboardCalendarWeekFiltersPopover';
import { UserDashboardCalendarWeekOptionsPopover } from './UserDashboardCalendarWeekOptionsPopover';

export interface UserDashboardCalendarWeekHeaderProps {
  sx?: SxProps;
  className?: string;
  currentDate: Date;
  scheduleCycleWithDraftId: string | undefined;
  courseSections: CourseSectionDetails[];
  hasCalendarSyncError: boolean;
  retryFetchData: () => Promise<void>;
}

export const UserDashboardCalendarWeekHeader = observer(
  ({
    sx = [],
    className,
    currentDate,
    courseSections,
    scheduleCycleWithDraftId,
    hasCalendarSyncError,
    retryFetchData
  }: UserDashboardCalendarWeekHeaderProps) => {
    const { localization } = useServices();
    const [optionsButtonRef, setOptionsButtonRef] = useState<HTMLButtonElement | undefined>();
    const [filterButtonRef, setFilterButtonRef] = useState<HTMLButtonElement | undefined>();

    const previousWeekDate = subWeeks(currentDate, 1);
    const nextWeekDate = addWeeks(currentDate, 1);

    const isDisplayingToday = isThisWeek(currentDate);
    const firstDayOfWeek = startOfWeek(currentDate);
    const lastDayOfWeek = endOfWeek(currentDate);
    let title: string;
    if (!isSameYear(firstDayOfWeek, lastDayOfWeek)) {
      title = `${format(firstDayOfWeek, 'MMM y')} - ${format(lastDayOfWeek, 'MMM y')}`;
    } else if (!isSameMonth(firstDayOfWeek, lastDayOfWeek)) {
      title = `${format(firstDayOfWeek, 'MMM')} - ${format(lastDayOfWeek, 'MMM y')}`;
    } else {
      title = intlFormat(currentDate, { month: 'long', year: 'numeric' }, { locale: localization.currentLocale });
    }

    return (
      <Box sx={sx} className={className}>
        <UserDashboardCalendarHeaderView
          previousDate={previousWeekDate}
          nextDate={nextWeekDate}
          isDisplayingToday={isDisplayingToday}
          title={title}
          onOptionsButtonClick={(e) => setOptionsButtonRef(e.currentTarget)}
          onFiltersButtonClick={(e) => setFilterButtonRef(e.currentTarget)}
          scheduleCycleWithDraftId={scheduleCycleWithDraftId}
          hasCalendarSyncError={hasCalendarSyncError}
          retryFetchData={() => retryFetchData()}
        />

        <UserDashboardCalendarWeekFiltersPopover
          open={filterButtonRef != null}
          anchorEl={filterButtonRef}
          close={() => setFilterButtonRef(undefined)}
          courseSections={courseSections}
        />

        <UserDashboardCalendarWeekOptionsPopover
          open={optionsButtonRef != null}
          anchorEl={optionsButtonRef}
          close={() => setOptionsButtonRef(undefined)}
        />
      </Box>
    );
  }
);
