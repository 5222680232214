import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { SchoolTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolAccountLoadable = LoadableValue<Account>;

export class AppSchoolAccountLoadable extends BaseLoadableValue<Account> implements SchoolAccountLoadable {
  constructor(
    private readonly _accountId: string,
    private readonly _schoolId: string,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<Account> {
    return await this._schoolTransport.getSchoolAccount(this._accountId, this._schoolId);
  }
}
