import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { CourseSectionInfo, isAdminOfSharedSchool, schoolCourseSectionToInfo } from '../../models';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { Loadable, SchoolDataStore, UserDataStore } from '../../stores';
import {
  AppBaseScheduleCycleActivitySchedulesViewModel,
  ScheduleCycleActivitySchedulesViewModel,
  filterSchoolCourseSectionForActivitySchedules
} from '../shared';
import { AdminClassSchedulesFilterOption } from './AdminClassSchedulesViewModel';

export class AppAdminClassSchedulesListViewModel
  extends AppBaseScheduleCycleActivitySchedulesViewModel
  implements ScheduleCycleActivitySchedulesViewModel
{
  constructor(
    private readonly _schoolId: string,
    scheduleCycleId: string,
    private readonly _filter: AdminClassSchedulesFilterOption | undefined,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(undefined, { id: _schoolId, kind: 'school' }, scheduleCycleId, userStore, localization);
    makeObservable(this);
  }

  @computed
  private get schoolCourseSectionsLoadable() {
    return this._schoolStore.getCourseSections(this._schoolId);
  }

  @computed
  protected get courseSectionsLoadable(): Loadable<unknown> {
    return this.schoolCourseSectionsLoadable;
  }

  @computed
  protected get courseSections(): CourseSectionInfo[] {
    const { values, data } = this.schoolCourseSectionsLoadable;

    if (this._filter == null) {
      return values.map(schoolCourseSectionToInfo);
    }

    switch (this._filter.case) {
      case 'teacher': {
        return chain(values)
          .filter((cs) =>
            filterSchoolCourseSectionForActivitySchedules(cs, { case: 'teacher', value: this._filter!.id })
          )
          .map(schoolCourseSectionToInfo)
          .value();
      }

      case 'course': {
        const course = data.get(this._filter.id);
        return course != null ? [schoolCourseSectionToInfo(course)] : [];
      }
    }
  }

  canEditActivitySchedulesForCourseId(): boolean {
    const school = this._userStore.getSchoolForId(this._schoolId);
    return school != null && isAdminOfSharedSchool(this._userStore.user.userId, school);
  }
}
