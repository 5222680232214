import {
  AttachmentInfo,
  WorkIconInfo,
  canEditPublishedWork,
  schoolAttachmentKindToAttachmentInfoKind,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon
} from '@/models';
import { ServiceContainer } from '@/providers';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { LocalizationService, UserDashboardPlannerItemsLocationState } from '@/services';
import {
  PlannerDataStore,
  PlannerDetailedCourseSectionsLoadable,
  PublishedWorkLoadable,
  WorkDataStore,
  WorkIconsLoadable,
  mergeLoadableStates
} from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { computed, makeObservable, override } from 'mobx';
import { Location } from 'react-router';
import { NavigateFunction } from 'react-router-dom';
import { BackgroundLocationState } from '../../BackgroundLocationState';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  BaseDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  EditDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface PublishedWorkDetailsViewModel extends UpdatableDialogViewModel {
  readonly publishedWork: PublishedWork;
  readonly workIcon: WorkIconInfo;
  readonly courseTitle: string | undefined;
  readonly section: string | undefined;
  readonly courseColor: string | undefined;
  readonly attachments: AttachmentInfo[];
  readonly canCancelWork: boolean;

  duplicate(navigate: NavigateFunction, location: Location): void;
  distribute(navigate: NavigateFunction, location: Location): void;
  repeat(navigate: NavigateFunction, location: Location): void;
  cancelWork(): Promise<void>;
}

export class AppPublishedWorkDetailsViewModel
  extends AppBaseUpdatableDialogViewModel
  implements PublishedWorkDetailsViewModel
{
  private readonly _editButtonConfig: EditDialogActionButtonConfiguration;

  private readonly _openExternalWorkButtonConfig: BaseDialogActionButtonConfiguration;

  constructor(
    private readonly _id: string,
    private readonly _schoolId: string,
    private readonly _plannerId: string,
    onDismiss: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore
  ) {
    super(localization, onDismiss);
    makeObservable(this);

    this._editButtonConfig = new EditDialogActionButtonConfiguration(
      'main',
      this._localization,
      {
        url: undefined,
        state: { publishedWorkDetailsShowWorkEdit: true }
      },
      true
    );

    this._openExternalWorkButtonConfig = new BaseDialogActionButtonConfiguration(
      'secondary',
      'bottom-only',
      'hidden',
      'open-in',
      'start',
      () => '', // Title will be set in supplementaryActions
      'contained-grey',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      async () => {}
    );
  }

  @computed
  private get publishedWorkLoadable(): PublishedWorkLoadable {
    return this._workStore.getPublishedWorkLoadable(this._id, this._schoolId);
  }

  @computed
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIconsLoadable(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  private get courseSection(): CourseSectionDetails | undefined {
    return this.courseSectionsLoadable.values.find(
      (cs) =>
        cs.schoolsCourseSection?.id === this.publishedWork.courseSectionId &&
        cs.schoolsCourseSection?.schoolId === this.publishedWork.schoolId
    );
  }

  @computed
  private get canEdit(): boolean {
    return canEditPublishedWork(this.publishedWork);
  }

  readonly hasChanges = false;

  @computed
  get hasData(): boolean {
    return this.publishedWorkLoadable.hasData && this.courseSectionsLoadable.hasData && this.workIconsLoadable.hasData;
  }

  @override
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    const actions: DialogActionButtonConfiguration[] = [];

    if (this.canEdit) {
      this._editButtonConfig.isEnabled = !this.isSubmitting;
      actions.push(this._editButtonConfig);
    }

    if (this.publishedWork.externalSource != null && this.publishedWork.externalSource.url.length > 0) {
      this._openExternalWorkButtonConfig.isEnabled = !this.isSubmitting;
      this._openExternalWorkButtonConfig.title = () =>
        this._localization.localizedStrings.work.details.openWorkLinkButtonLabel(
          localizedExternalSourceName(this.publishedWork.externalSource!.sourceName)
        );
      this._openExternalWorkButtonConfig.url = this.publishedWork.externalSource.url;
      actions.push(this._openExternalWorkButtonConfig);
    }

    return actions;
  }

  readonly isSubmitting = false;

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([
      this.publishedWorkLoadable.state,
      this.courseSectionsLoadable.state,
      this.workIconsLoadable.state
    ]);
  }

  @computed
  get publishedWork(): PublishedWork {
    return this.publishedWorkLoadable.data;
  }

  @computed
  get workIcon(): WorkIconInfo {
    const icon = this.workIconsLoadable.data.iconsById.get(this.publishedWork.iconId)!;

    return {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: urlForPublishedWorkWorkIcon(icon, this.publishedWork.importance, 'light'),
      darkUrl: urlForPublishedWorkWorkIcon(icon, this.publishedWork.importance, 'dark'),
      externalBadgeUrl: urlForExternalSourceBadge(
        this.publishedWork.externalSource?.sourceName,
        this.workIconsLoadable.data
      )
    };
  }

  @computed
  get courseColor(): string | undefined {
    return this.courseSection?.courseSection?.color;
  }

  @computed
  get courseTitle(): string | undefined {
    return this.courseSection?.courseSection?.title;
  }

  @computed
  get section(): string | undefined {
    return this.courseSection?.courseSection?.section;
  }

  @computed
  get attachments(): AttachmentInfo[] {
    return this.publishedWork.attachments.map((attachment) => ({
      ...attachment,
      kind: schoolAttachmentKindToAttachmentInfoKind(attachment.kind)
    }));
  }

  @computed
  get canCancelWork(): boolean {
    return (
      (this.publishedWork.externalSource == null || this.publishedWork.canExternalSourceBeUpdated) &&
      this.publishedWork.status !== PublishedWorkStatus.CANCELLED
    );
  }

  async reloadData(): Promise<void> {
    await this.publishedWorkLoadable.fetch(true);
  }

  duplicate(navigate: NavigateFunction, location: Location) {
    this.showPlannerItemDialog(navigate, location, {
      publishedWorkEdit: {
        ids: undefined,
        newPublishedWorkInfo: {
          isDueAllDay: true,
          dueDate: undefined,
          schedulePublishTime: undefined,
          attachments: this.publishedWork.attachments,
          title: this.publishedWork.title,
          iconId: this.publishedWork.iconId,
          courseSectionId: this.courseSection?.courseSection?.id,
          importance: this.publishedWork.importance,
          description: this.publishedWork.description?.text,
          maxGrade: this.publishedWork.maxGrade
        }
      }
    });
  }

  distribute(navigate: NavigateFunction, location: Location) {
    this.showPlannerItemDialog(navigate, location, {
      distributeItem: { kind: { case: 'publishedWork', id: this._id, schoolId: this._schoolId } }
    });
  }

  repeat(navigate: NavigateFunction, location: Location) {
    this.showPlannerItemDialog(navigate, location, {
      repeatItem: { kind: { case: 'publishedWork', id: this._id, schoolId: this._schoolId } }
    });
  }

  async cancelWork() {
    await this._workStore.setPublishedWorkStatus(
      this._id,
      this._schoolId,
      PublishedWorkStatus.CANCELLED,
      undefined,
      this.publishedWork.syncToken
    );
    await this._plannerStore.fetchPlannerContents(this._plannerId);
  }

  private showPlannerItemDialog(
    navigate: NavigateFunction,
    location: Location,
    state: UserDashboardPlannerItemsLocationState
  ) {
    const newLocation = ((location.state || {}) as BackgroundLocationState)?.backgroundLocation ?? location;
    navigate(newLocation, { state, replace: true });
  }
}
