import { Course } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/course_pb';
import { CourseSection as PlannerCourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { computed, makeObservable, observable } from 'mobx';
import { ClassroomCourseViewModel } from './ClassroomCourseViewModel';

export class AppPlannerClassroomCourseViewModel implements ClassroomCourseViewModel {
  @observable private _isSelected: boolean;

  constructor(
    private readonly _courseSection: PlannerCourseSection | undefined,
    private readonly _course: Course
  ) {
    makeObservable(this);
    this._isSelected = _course.isSelected;
  }

  @computed
  get id(): string {
    return this._course.classroomCourseId;
  }

  @computed
  get title(): string {
    return this._course.title;
  }

  @computed
  get description(): string {
    return this._course.section.length > 0 ? this._course.section : this._course.description;
  }

  @computed
  get color(): string | undefined {
    return this._courseSection?.color;
  }

  @computed
  get hasChanges(): boolean {
    return this._course.isSelected !== this.isSelected;
  }

  @computed
  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }
}
