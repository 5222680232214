import i18n from 'translate';

export const OnboardingTeacherOptionsSchoolKindExplanationStrings = {
  personalSchoolPanelMessage: () =>
    i18n.t('onboarding.teacherOptions.schoolKindExplanation.personalSchoolPanelMessage'),
  personalSchoolPanelTitle: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.personalSchoolPanelTitle'),
  sharedSchoolPanelMessage: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.sharedSchoolPanelMessage'),
  sharedSchoolPanelTitle: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.sharedSchoolPanelTitle'),
  title: () => i18n.t('onboarding.teacherOptions.schoolKindExplanation.title')
};

export const OnboardingTeacherOptionsStrings = {
  personalSchoolOptionSubtitle: () => i18n.t('onboarding.teacherOptions.personalSchoolOptionSubtitle'),
  personalSchoolOptionTitle: () => i18n.t('onboarding.teacherOptions.personalSchoolOptionTitle'),
  personalSchoolShareMessage: () => i18n.t('onboarding.teacherOptions.personalSchoolShareMessage'),
  schoolKindExplanation: OnboardingTeacherOptionsSchoolKindExplanationStrings,
  sharedSchoolOptionSubtitle: () => i18n.t('onboarding.teacherOptions.sharedSchoolOptionSubtitle'),
  sharedSchoolOptionTitle: () => i18n.t('onboarding.teacherOptions.sharedSchoolOptionTitle'),
  title: () => i18n.t('onboarding.teacherOptions.title'),
  useCodeButton: () => i18n.t('onboarding.teacherOptions.useCodeButton')
};
