import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { PlannerPastePlannerItemViewModel } from '../../../../viewmodels';
import { DatePicker, Subheader } from '../../../utils';
import { PlannerPastePlannerItemOption } from './PlannerPastePlannerItemOption';

export interface PlannerPastePlannerItemDateSelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemViewModel;
}

export const PlannerPastePlannerItemDateSelection = observer(
  ({ sx = [], className, viewModel }: PlannerPastePlannerItemDateSelectionProps) => {
    const strings = LocalizedStrings.planner.pastePlannerItem;

    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Subheader>{strings.dateSelectionSectionTitle()}</Subheader>

        <DatePicker
          value={viewModel.date}
          dateFormat={'P'}
          onChange={(date) => (viewModel.date = date)}
          canClearDate
          datePickerKind="user-dashboard-calendar"
          datePickerLabel={strings.datePickerLabel()}
        />

        <PlannerPastePlannerItemOption
          isSelected={viewModel.date == null}
          title={strings.noDueDateOptionLabel()}
          onSelect={() => (viewModel.date = undefined)}
        />
      </Stack>
    );
  }
);
