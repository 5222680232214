import i18n from 'translate';
import { ScheduleCycleBellTimesEditStrings } from './ScheduleCycleBellTimesEditStrings';
import { ScheduleCycleBellTimesPeriodStrings } from './ScheduleCycleBellTimesPeriodStrings';

export const ScheduleCycleBellTimesStrings = {
  addBellTimes: () => i18n.t('scheduleCycle.edit.bellTimes.addBellTimes'),
  edit: ScheduleCycleBellTimesEditStrings,
  noBellTimes: () => i18n.t('scheduleCycle.edit.bellTimes.noBellTimes'),
  period: ScheduleCycleBellTimesPeriodStrings
};
