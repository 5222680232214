import { AttachmentInfoKind } from '@/models';

export interface AttachmentStrings {
  attachmentKindText: (kind: AttachmentInfoKind) => string;
}

export const EnglishAttachmentStrings: AttachmentStrings = {
  attachmentKindText: (kind) => {
    switch (kind) {
      case 'file':
        return 'File';
      case 'shared_file':
        return 'Shared file';
      case 'video':
        return 'Video';
      case 'unspecified':
        return 'Unknown';
      case 'form':
        return 'Form';
      case 'link':
        return 'Link';
    }
  }
};

export const FrenchAttachmentStrings: AttachmentStrings = {
  attachmentKindText: (kind) => {
    switch (kind) {
      case 'file':
        return 'Fichier';
      case 'shared_file':
        return 'Fichier partagé';
      case 'video':
        return 'Vidéo';
      case 'unspecified':
        return 'Inconnu';
      case 'form':
        return 'Questionnaire';
      case 'link':
        return 'Lien';
    }
  }
};
