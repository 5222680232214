import { Alert, AlertColor, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import _ from 'lodash';

export interface AlertTooltipProps extends TooltipProps {
  severity: AlertColor;
  titles?: (React.ReactNode | undefined)[];
}

export function AlertTooltip({ title, titles = [], severity, children, ...props }: AlertTooltipProps) {
  const setTitles = _.compact(titles);
  const actualTitles = setTitles.length === 0 ? [title] : setTitles;

  return (
    <StyledTooltip
      {...props}
      title={actualTitles.map((t, i) => (
        <Alert key={`alert-tooltip-${i}`} variant="outlined" severity={severity}>
          {t}
        </Alert>
      ))}
    >
      {children}
    </StyledTooltip>
  );
}

const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    padding: 0,
    borderRadius: theme.shape.borderRadius * 2.5
  }
}));
