import { action, computed, makeObservable, observable } from 'mobx';
import { PasteboardContent, PasteboardContentKind, PasteboardPasteContext, PasteboardService } from '../contracts';

export class AppPasteboardService implements PasteboardService {
  @observable private _content: PasteboardContent | undefined;
  @observable private _pasteContext: PasteboardPasteContext | undefined;

  constructor() {
    makeObservable(this);
  }

  @computed
  get content(): PasteboardContent | undefined {
    return this._content;
  }

  @computed
  get pasteContext(): PasteboardPasteContext | undefined {
    return this._pasteContext;
  }

  @action
  setContent(content: PasteboardContent) {
    this._content = content;
  }

  currentContentForKinds(supportedKinds: PasteboardContentKind[]): PasteboardContent | undefined {
    return this._content != null && supportedKinds.includes(this._content.case) ? this._content : undefined;
  }

  @action
  clearContent() {
    this._content = undefined;
  }

  getSummaryTextForContent(content: PasteboardContent): string {
    switch (content.case) {
      case 'work':
        return this.formatContentText(content.value.title);

      case 'note':
        return this.formatContentText(content.value.text!.text);

      case 'published-work':
        return this.formatContentText(content.value.title);
    }
  }

  @action
  setPasteContext(context: PasteboardPasteContext) {
    this._pasteContext = context;
  }

  @action
  clearPasteContext() {
    this._pasteContext = undefined;
  }

  private formatContentText(value: string) {
    const maxLength = 30;
    const chars = Array.from(value);
    return chars.length > maxLength ? `${chars.slice(0, maxLength).join('')}…` : value;
  }
}
