import i18n from 'translate';
import { CalendarDayStrings } from './CalendarDayStrings';
import { CalendarMonthStrings } from './CalendarMonthStrings';
import { CalendarPeriodInfoStrings } from './CalendarPeriodInfoStrings';
import { CalendarWeekStrings } from './CalendarWeekStrings';

export const CalendarFiltersStrings = {
  calendarEventsOption: () => i18n.t('calendar.filters.calendarEventsOption'),
  coursesListSectionTitle: () => i18n.t('calendar.filters.coursesListSectionTitle'),
  coursesOption: () => i18n.t('calendar.filters.coursesOption'),
  dueWorksOption: () => i18n.t('calendar.filters.dueWorksOption'),
  notesOption: () => i18n.t('calendar.filters.notesOption'),
  plannedWorksOption: () => i18n.t('calendar.filters.plannedWorksOption'),
  specialDaysOption: () => i18n.t('calendar.filters.specialDaysOption'),
  title: () => i18n.t('calendar.filters.title')
};

export const CalendarScheduleCycleDraftWarningStrings = {
  goToScheduleButtonTitle: () => i18n.t('calendar.scheduleCycleDraftWarning.goToScheduleButtonTitle'),
  message: () => i18n.t('calendar.scheduleCycleDraftWarning.message'),
  title: () => i18n.t('calendar.scheduleCycleDraftWarning.title')
};

export const CalendarSyncErrorStrings = {
  message: () => i18n.t('calendar.syncError.message'),
  retryButtonTitle: () => i18n.t('calendar.syncError.retryButtonTitle'),
  title: () => i18n.t('calendar.syncError.title')
};

export const CalendarStrings = {
  day: CalendarDayStrings,
  filters: CalendarFiltersStrings,
  goToTodayButtonShortTitle: () => i18n.t('calendar.goToTodayButtonShortTitle'),
  goToTodayButtonTitle: () => i18n.t('calendar.goToTodayButtonTitle'),
  month: CalendarMonthStrings,
  periodInfo: CalendarPeriodInfoStrings,
  scheduleCycleDraftWarning: CalendarScheduleCycleDraftWarningStrings,
  syncError: CalendarSyncErrorStrings,
  week: CalendarWeekStrings
};
