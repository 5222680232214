import { useServices } from '@/hooks';
import { Box, Grid2, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { isSxArray } from '../../../utils';
import { BackgroundImageScreenPaper } from '../../utils';
import { SettingsHeader } from './SettingsHeader';
import { SettingsSection } from './SettingsSection';
import { SettingsSideBar } from './SettingsSideBar';

export interface SettingsLayoutProps {
  sx?: SxProps;
  className?: string;
  sections: SettingsSection[];
}

export const SettingsLayout = observer(({ sx = [], className, sections }: SettingsLayoutProps) => {
  const { settingsStorage } = useServices();
  const theme = useTheme();
  const showSideBar = useMediaQuery(() => theme.breakpoints.up(settingsStorage.isSidebarCollapsed ? 'md' : 'lg'));

  return (
    <Box
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%',
          display: 'flex'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Grid2 container sx={{ width: '100%', height: '100%' }}>
          <Grid2
            size={{ xs: 12 }}
            sx={{
              height: '100%'
            }}
          >
            <BackgroundImageScreenPaper sx={{ height: '100%' }}>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                sx={{
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%'
                }}
              >
                {showSideBar && <SettingsSideBar sections={sections} sx={{ width: 200, height: '100%' }} />}

                <Stack
                  sx={{
                    flex: 1,
                    p: { xs: 0, sm: 2 },
                    overflowY: 'hidden'
                  }}
                >
                  <SettingsHeader
                    sections={sections}
                    showsMenu={!showSideBar}
                    sx={{ p: { xs: 2, sm: 0 }, pb: !showSideBar ? 2 : { xs: 0 } }}
                  />
                  <Stack
                    sx={{
                      flex: 1,
                      overflow: 'hidden'
                    }}
                  >
                    <Outlet />
                  </Stack>
                </Stack>
              </Stack>
            </BackgroundImageScreenPaper>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
});
