import { Stack, Typography, TypographyVariant, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { reduce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Location, useLocation } from 'react-router';
import { useActivePlannerId, useServices } from '../../../hooks';
import { SettingsMenu } from './SettingsMenu';
import { SettingsItem, SettingsSection } from './SettingsSection';

export interface SettingsHeaderProps {
  sx?: SxProps;
  className?: string;
  sections: SettingsSection[];
  showsMenu: boolean;
}

export const SettingsHeader = observer(({ sx = [], className, sections, showsMenu }: SettingsHeaderProps) => {
  const { localization, route } = useServices();
  const plannerId = useActivePlannerId();
  const baseRoute = `${route.resolvePlannerLocation(plannerId)}/settings`;
  const location = useLocation();
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const strings = localization.localizedStrings.utils;
  const textVariant: TypographyVariant = 'h6';
  const fontWeight = isExtraSmallScreen ? '500' : undefined;
  const currentPageTitle = titleForCurrentPage(location, baseRoute, sections);

  if (showsMenu) {
    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: '600'
          }}
        >
          {strings.settingsTitle}
        </Typography>
        <SettingsMenu sections={sections} />
      </Stack>
    );
  }

  return (
    <Stack sx={sx} className={className} direction="row" spacing={1}>
      <Typography
        variant={textVariant}
        sx={{
          fontWeight: fontWeight,
          color: theme.palette.text.secondary
        }}
      >
        {strings.settingsTitle}
      </Typography>
      <Typography
        variant={textVariant}
        sx={{
          fontWeight: fontWeight,
          color: theme.palette.text.secondary
        }}
      >
        ›
      </Typography>
      <Typography
        variant={textVariant}
        sx={{
          fontWeight: fontWeight,
          color: showsMenu ? theme.palette.primary.main : theme.palette.text.secondary
        }}
      >
        {currentPageTitle}
      </Typography>
    </Stack>
  );
});

function titleForCurrentPage(location: Location, baseRoute: string, sections: SettingsSection[]): string {
  const items = reduce(
    sections,
    (prev, curr) => {
      return prev.concat(curr.items);
    },
    [] as SettingsItem[]
  );

  const matchingItem = items.find((item) => location.pathname.startsWith(`${baseRoute}/${item.link}`));
  return matchingItem?.title ?? '';
}
