import i18n from 'translate';
import { PlannerItemsStrings } from './PlannerItemsStrings';

export const PlannerContextMenuOptions = {
  createItemInPeriodSectionTitle: () => i18n.t('planner.contextMenuOptions.createItemInPeriodSectionTitle'),
  createNote: () => i18n.t('planner.contextMenuOptions.createNote'),
  createPublishedWork: () => i18n.t('planner.contextMenuOptions.createPublishedWork'),
  createWork: () => i18n.t('planner.contextMenuOptions.createWork')
};

export const PlannerDistributeItemStrings = {
  courseSectionExternalSource: (sourceName: string) =>
    i18n.t('planner.distributeItem.courseSectionExternalSource', { sourceName }),
  coursesSelectionSectionTitle: () => i18n.t('planner.distributeItem.coursesSelectionSectionTitle'),
  noteTitle: () => i18n.t('planner.distributeItem.noteTitle'),
  patternSelectionSectionTitle: () => i18n.t('planner.distributeItem.patternSelectionSectionTitle'),
  sameDayDetails: () => i18n.t('planner.distributeItem.sameDayDetails'),
  sameDayOptionTitle: () => i18n.t('planner.distributeItem.sameDayOptionTitle'),
  sameOccurrenceDetails: () => i18n.t('planner.distributeItem.sameOccurrenceDetails'),
  sameOccurrenceOptionTitle: () => i18n.t('planner.distributeItem.sameOccurrenceOptionTitle'),
  sameTimeDetails: () => i18n.t('planner.distributeItem.sameTimeDetails'),
  sameTimeOptionTitle: () => i18n.t('planner.distributeItem.sameTimeOptionTitle'),
  saveButtonTitle: () => i18n.t('planner.distributeItem.saveButtonTitle'),
  saveErrorMessage: () => i18n.t('planner.distributeItem.saveErrorMessage'),
  title: () => i18n.t('planner.distributeItem.title')
};

export const PlannerNewCurriculumCoursesStrings = {
  descriptionOther: () => i18n.t('planner.newCurriculumCourses.descriptionOther'),
  descriptionParent: () => i18n.t('planner.newCurriculumCourses.descriptionParent'),
  descriptionTeacher: () => i18n.t('planner.newCurriculumCourses.descriptionTeacher'),
  enrollButtonTitle: () => i18n.t('planner.newCurriculumCourses.enrollButtonTitle'),
  ignoreButtonTitle: () => i18n.t('planner.newCurriculumCourses.ignoreButtonTitle'),
  title: () => i18n.t('planner.newCurriculumCourses.title')
};

export const PlannerNewSchoolsSelectionStrings = {
  errorMessage: () => i18n.t('planner.newSchools.selection.errorMessage'),
  message: () => i18n.t('planner.newSchools.selection.message'),
  messageTeacher: () => i18n.t('planner.newSchools.selection.messageTeacher'),
  selectionHelperText: () => i18n.t('planner.newSchools.selection.selectionHelperText')
};

export const PlannerNewSchoolsClassroomConnectionStrings = {
  connected: () => i18n.t('planner.newSchools.classroomConnection.connected'),
  errorMessage: () => i18n.t('planner.newSchools.classroomConnection.errorMessage'),
  fetchingDataMessage: () => i18n.t('planner.newSchools.classroomConnection.fetchingDataMessage'),
  message: () => i18n.t('planner.newSchools.classroomConnection.message'),
  messageTeacher: () => i18n.t('planner.newSchools.classroomConnection.messageTeacher'),
  subtitle: (schoolsCount: number) =>
    i18n.t('planner.newSchools.classroomConnection.subtitle', { count: schoolsCount }),
  syncStatusError: () => i18n.t('planner.newSchools.classroomConnection.syncStatusError'),
  syncStatusErrorCompact: () => i18n.t('planner.newSchools.classroomConnection.syncStatusErrorCompact'),
  title: () => i18n.t('planner.newSchools.classroomConnection.title')
};

export const PlannerNewSchoolStrings = {
  backButtonLabel: () => i18n.t('planner.newSchools.backButtonLabel'),
  classroomConnection: PlannerNewSchoolsClassroomConnectionStrings,
  nextButtonLabel: () => i18n.t('planner.newSchools.nextButtonLabel'),
  schoolOwners: (owners: string) => i18n.t('planner.newSchools.schoolOwners', { owners }),
  selection: PlannerNewSchoolsSelectionStrings,
  submitButtonLabel: () => i18n.t('planner.newSchools.submitButtonLabel'),
  title: () => i18n.t('planner.newSchools.title'),
  unSelectAllButtonLabel: () => i18n.t('planner.newSchools.unSelectAllButtonLabel')
};

export const PlannerNoPlannerStrings = {
  createButtonTitle: () => i18n.t('planner.noPlanner.createButtonTitle'),
  errorMessage: () => i18n.t('planner.noPlanner.errorMessage'),
  subtitle: () => i18n.t('planner.noPlanner.subtitle'),
  title: () => i18n.t('planner.noPlanner.title')
};

export const PlannerPastePlannerItemStrings = {
  courseSelectionSectionTitle: () => i18n.t('planner.pastePlannerItem.courseSelectionSectionTitle'),
  datePickerLabel: () => i18n.t('planner.pastePlannerItem.datePickerLabel'),
  dateSelectionSectionTitle: () => i18n.t('planner.pastePlannerItem.dateSelectionSectionTitle'),
  existingDateHeaderLabel: (formattedDate: string) =>
    i18n.t('planner.pastePlannerItem.existingDateHeaderLabel', { formattedDate }),
  existingSpecificTimeHeaderLabel: (formattedTime: string) =>
    i18n.t('planner.pastePlannerItem.existingSpecificTimeHeaderLabel', { formattedTime }),
  existingTargetPeriodHeaderLabel: (periodName: string, startTime: string, endTime: string) =>
    i18n.t('planner.pastePlannerItem.existingTargetPeriodHeaderLabel', { periodName, startTime, endTime }),
  generalOptionsSectionTitle: () => i18n.t('planner.pastePlannerItem.generalOptionsSectionTitle'),
  linkCopyToOriginalOptionLabel: () => i18n.t('planner.pastePlannerItem.linkCopyToOriginalOptionLabel'),
  noClassLabel: () => i18n.t('planner.pastePlannerItem.noClassLabel'),
  noDueDateOptionLabel: () => i18n.t('planner.pastePlannerItem.noDueDateOptionLabel'),
  noTimeOptionLabel: () => i18n.t('planner.pastePlannerItem.noTimeOptionLabel'),
  periodsLoadingMessage: () => i18n.t('planner.pastePlannerItem.periodsLoadingMessage'),
  saveButtonTitle: () => i18n.t('planner.pastePlannerItem.saveButtonTitle'),
  saveErrorAlertMessage: () => i18n.t('planner.pastePlannerItem.saveErrorAlertMessage'),
  specificTimeOptionLabel: () => i18n.t('planner.pastePlannerItem.specificTimeOptionLabel'),
  timePickerInvalidTimeErrorMessage: () => i18n.t('planner.pastePlannerItem.timePickerInvalidTimeErrorMessage'),
  timeSelectionSectionTitle: () => i18n.t('planner.pastePlannerItem.timeSelectionSectionTitle'),
  title: (itemTitle: string) => i18n.t('planner.pastePlannerItem.title', { itemTitle }),
  useOriginalItemClassOptionLabel: () => i18n.t('planner.pastePlannerItem.useOriginalItemClassOptionLabel'),
  usePeriodClassOptionLabel: () => i18n.t('planner.pastePlannerItem.usePeriodClassOptionLabel')
};

export const PlannerRepeatItemStrings = {
  byWeekOption: () => i18n.t('planner.repeatPlannerItem.byWeekOption'),
  dayOfWeekSelectionSectionTitle: () => i18n.t('planner.repeatPlannerItem.dayOfWeekSelectionSectionTitle'),
  errorMessage: () => i18n.t('planner.repeatPlannerItem.errorMessage'),
  everyDayOption: () => i18n.t('planner.repeatPlannerItem.everyDayOption'),
  everyOccurrenceOption: () => i18n.t('planner.repeatPlannerItem.everyOccurrenceOption'),
  everyWeekCountSelectLabel: () => i18n.t('planner.repeatPlannerItem.everyWeekCountSelectLabel'),
  everyXWeekSelectOption: (count: number) => i18n.t('planner.repeatPlannerItem.everyXWeekSelectOption', { count }),
  patternSelectionSectionTitle: () => i18n.t('planner.repeatPlannerItem.patternSelectionSectionTitle'),
  specificCycleDaysOption: () => i18n.t('planner.repeatPlannerItem.specificCycleDaysOption'),
  submitButtonLabel: () => i18n.t('planner.repeatPlannerItem.submitButtonLabel'),
  title: () => i18n.t('planner.repeatPlannerItem.title'),
  untilDateLabel: () => i18n.t('planner.repeatPlannerItem.untilDateLabel')
};

export const PlannerStrings = {
  contextMenuOptions: PlannerContextMenuOptions,
  distributeItem: PlannerDistributeItemStrings,
  items: PlannerItemsStrings,
  newCurriculumCourses: PlannerNewCurriculumCoursesStrings,
  newSchools: PlannerNewSchoolStrings,
  noPlanner: PlannerNoPlannerStrings,
  pastePlannerItem: PlannerPastePlannerItemStrings,
  repeatPlannerItem: PlannerRepeatItemStrings
};
