import { ConnectedAppUserDashboard } from '@/models';
import { ClientAppKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/client_app_kind_pb.js';
import { Calendar } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/calendar_pb';
import { SyncStatus } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/sync_status_pb';
import { GoogleCalendar } from '@buf/studyo_studyo-today-google-calendar.connectrpc_es/studyo/today/google_calendar/v1/google_calendar_connect';
import { GoogleCalendarTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppGoogleCalendarTransportService
  extends AppBaseTransportService
  implements GoogleCalendarTransportService
{
  async getSyncStatus(dashboard: ConnectedAppUserDashboard): Promise<SyncStatus> {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    return await this.performReadRequest(
      'getSyncStatus',
      GoogleCalendar,
      GoogleCalendar.methods.getSyncStatus,
      { plannerId: dashboard.plannerId },
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async synchronize(dashboard: ConnectedAppUserDashboard) {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    await this.performReadRequest(
      'synchronize',
      GoogleCalendar,
      GoogleCalendar.methods.synchronize,
      { plannerId: dashboard.plannerId },
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async registerOfflineAccessCode(code: string, dashboard: ConnectedAppUserDashboard) {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    await this.performWriteRequest(
      'registerOfflineAccessCode',
      GoogleCalendar,
      GoogleCalendar.methods.registerOfflineAccessCode,
      { code, plannerId: dashboard.plannerId, clientAppKind: ClientAppKind.WEB },
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async revokeOfflineAccessCode(dashboard: ConnectedAppUserDashboard) {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    await this.performWriteRequest(
      'revokeOfflineAccessCode',
      GoogleCalendar,
      GoogleCalendar.methods.revokeOfflineAccessCode,
      { plannerId: dashboard.plannerId },
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async getCalendars(dashboard: ConnectedAppUserDashboard): Promise<Calendar[]> {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    const response = await this.performReadRequest(
      'getCalendars',
      GoogleCalendar,
      GoogleCalendar.methods.getCalendars,
      { plannerId: dashboard.plannerId },
      { isDemoObject: this.isDemoId(dashboard.plannerId) }
    );

    return response.calendars;
  }

  async updateCalendarSelection(calendarIds: string[], dashboard: ConnectedAppUserDashboard): Promise<Calendar[]> {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    const response = await this.performWriteRequest(
      'updateCalendarSelection',
      GoogleCalendar,
      GoogleCalendar.methods.updateCalendarSelection,
      { selectedCalendarIds: calendarIds, plannerId: dashboard.plannerId },
      { isDemoObject: this.isDemoId(dashboard.plannerId) }
    );
    return response.calendars;
  }
}
