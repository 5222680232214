import { SxProps } from '@mui/system';
import { Navigate } from 'react-router';
import { RouteObject } from 'react-router-dom';
import {
  AdminSchoolClassDetailsPage,
  AdminSchoolClassesListPage,
  AdminSchoolGeneralInformation,
  AdminSchoolSchedulePage,
  AdminSchoolSchedulesPage,
  AdminSchoolsList,
  AdminSchoolStudentDetailsPage,
  AdminSchoolStudentsListPage,
  AdminSchoolTeacherDetailsPage,
  AdminSchoolTeachersListPage
} from '../components';

const schoolRouteSx: SxProps = {
  p: { xs: 1, sm: 2 }
};

const overflowSx: SxProps = {
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const AdminSchoolRoutes: RouteObject[] = [
  {
    path: 'teachers',
    children: [
      { path: ':teacherId', element: <AdminSchoolTeacherDetailsPage sx={{ ...overflowSx, height: '100%' }} /> },
      { path: '', element: <AdminSchoolTeachersListPage sx={{ ...schoolRouteSx, ...overflowSx }} /> }
    ]
  },
  {
    path: 'students',
    children: [
      { path: ':studentId', element: <AdminSchoolStudentDetailsPage sx={{ ...overflowSx, height: '100%' }} /> },
      { path: '', element: <AdminSchoolStudentsListPage sx={{ ...schoolRouteSx, ...overflowSx }} /> }
    ]
  },
  {
    path: 'classes',
    children: [
      { path: ':courseSectionId', element: <AdminSchoolClassDetailsPage sx={{ ...overflowSx, height: '100%' }} /> },
      { path: '', element: <AdminSchoolClassesListPage sx={{ ...schoolRouteSx, ...overflowSx }} /> }
    ]
  },
  {
    path: 'schedules',
    children: [
      {
        path: ':scheduleCycleId',
        element: <AdminSchoolSchedulePage sx={{ ...schoolRouteSx, ...overflowSx, height: '100%' }} />
      },
      { path: '', element: <AdminSchoolSchedulesPage sx={{ ...schoolRouteSx, ...overflowSx, height: '100%' }} /> }
    ]
  },
  // Old name, redirecting in case of bookmarks. Used to only support one schedule cycle,
  // so no children expected.
  { path: 'schedule', element: <Navigate to="../schedules" replace /> },
  { path: 'dashboard', element: <AdminSchoolGeneralInformation sx={{ ...schoolRouteSx, ...overflowSx }} /> },
  { path: '', element: <Navigate to="dashboard" replace /> }
];

export const AdminRoutes: RouteObject[] = [
  { path: ':schoolId', children: AdminSchoolRoutes },
  { path: '', element: <AdminSchoolsList sx={{ ...schoolRouteSx, ...overflowSx }} /> }
];
