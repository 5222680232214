import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../hooks';

export interface RequiresTodaySchoolsSubscriptionButtonProps extends ButtonProps {
  requiresSubscription?: boolean;
}

export const RequiresTodaySchoolsSubscriptionButton = observer(
  ({ disabled = false, requiresSubscription = true, ...props }: RequiresTodaySchoolsSubscriptionButtonProps) => {
    const { userStore } = useServices();
    const params = useParams();
    const schoolId = params.schoolId ?? '';
    const hasSchool = schoolId.length > 0;
    const [isEntitled, setIsEntitled] = useState<boolean | null>(null);

    const entitlementLoadable = useMemo(
      () => (hasSchool ? userStore.getSchoolSharedSchoolEntitlement(schoolId) : undefined),
      [schoolId, userStore]
    );

    useEffect(() => {
      const fetchIsEntitled = async () => {
        if (!requiresSubscription) {
          setIsEntitled(true);
          return;
        }

        await entitlementLoadable?.fetch(true);

        if (entitlementLoadable != null) {
          if (entitlementLoadable.hasData) {
            setIsEntitled(entitlementLoadable.data.isEntitled);
          } else {
            setIsEntitled(false);
          }
        } else {
          await userStore.subscription.fetch(true);

          if (userStore.subscription.hasData) {
            const subscription = userStore.subscription.data!.subscription;
            setIsEntitled(subscription.isActive && subscription.entitlements.includes(Entitlement.SHARED_SCHOOLS));
          } else {
            setIsEntitled(false);
          }
        }
      };

      void fetchIsEntitled();
    }, [schoolId]);

    if (!hasSchool) {
      return <Button {...props} />;
    }

    return (
      <Tooltip
        title={
          isEntitled === false ? LocalizedStrings.utils.requiresTodaySchoolsSubscriptionButtonTooltip() : undefined
        }
        disableInteractive
      >
        <Button {...props} disabled={isEntitled !== true || disabled} />
      </Tooltip>
    );
  }
);
