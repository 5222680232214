export interface ApplicationStrings {
  readonly defaultDescription: string;
  readonly defaultTitle: string;
}

export const EnglishApplicationStrings: ApplicationStrings = {
  defaultDescription:
    'Studyo Go allows students to gather tasks from multiple sources, like Google Classroom, plan them efficiently, and share their planner with their parents and teachers.',
  defaultTitle: 'Studyo Go'
};

export const FrenchApplicationStrings: ApplicationStrings = {
  defaultDescription:
    'Studyo Go permet aux élèves de regrouper des tâches de différentes sources comme Google Classroom, de les planifier et partager le tout avec leurs parents et enseignants.',
  // For the browser title, we keep "Studyo Go" for referencing.
  defaultTitle: 'Studyo Go'
};
