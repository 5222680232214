import { compareSchoolCourseSections } from '@/models';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { Loadable, SchoolCourseSectionsLoadable, SchoolDataStore, SchoolTeacherAccountsLoadable } from '../../stores';
import { localizedCompare } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export type AdminClassSchedulesFilterOption =
  | { case: 'teacher'; value: Account; id: string }
  | { case: 'course'; value: CourseSection; id: string };

export interface AdminClassSchedulesViewModel extends UpdatableViewModel {
  readonly options: AdminClassSchedulesFilterOption[];
  getOption(teacherId: string | undefined, courseId: string | undefined): AdminClassSchedulesFilterOption | undefined;
}

export class AppAdminClassSchedulesViewModel extends BaseUpdatableViewModel implements AdminClassSchedulesViewModel {
  constructor(
    private readonly _schoolId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get teachersLoadable(): SchoolTeacherAccountsLoadable {
    return this._schoolStore.getTeachers(this._schoolId);
  }

  @computed
  private get courseSectionsLoadable(): SchoolCourseSectionsLoadable {
    return this._schoolStore.getCourseSections(this._schoolId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.teachersLoadable, this.courseSectionsLoadable];
  }
  @computed
  private get teachersOptions(): AdminClassSchedulesFilterOption[] {
    return chain(this.teachersLoadable.values)
      .sort((a1, a2) => localizedCompare(a1.fullName, a2.fullName, this._localization.currentLocale))
      .map<AdminClassSchedulesFilterOption>((a) => ({ case: 'teacher', value: a, id: a.id }))
      .value();
  }

  @computed
  private get coursesOptions(): AdminClassSchedulesFilterOption[] {
    return chain(this.courseSectionsLoadable.values)
      .sort((a1, a2) => compareSchoolCourseSections(a1, a2, this._localization.currentLocale))
      .map<AdminClassSchedulesFilterOption>((cs) => ({ case: 'course', value: cs, id: cs.id }))
      .value();
  }

  @computed
  get options(): AdminClassSchedulesFilterOption[] {
    return [...this.teachersOptions, ...this.coursesOptions];
  }

  getOption(teacherId: string | undefined, courseId: string | undefined): AdminClassSchedulesFilterOption | undefined {
    if (teacherId != null && teacherId.length > 0) {
      const matchingTeacher = this.teachersLoadable.data.get(teacherId);

      if (matchingTeacher != null) {
        return { case: 'teacher', value: matchingTeacher, id: matchingTeacher.id };
      }
    }

    if (courseId != null && courseId.length > 0) {
      const matchingCourse = this.courseSectionsLoadable.data.get(courseId);

      if (matchingCourse != null) {
        return { case: 'course', value: matchingCourse, id: matchingCourse.id };
      }
    }

    return undefined;
  }
}
