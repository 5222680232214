import { useActivePlannerId, useSync, useViewModel } from '@/hooks';
import { Box, Grid2 } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isSxArray } from '../../../utils';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../utils';
import { PlannerItems } from './PlannerItems';

export interface PlannerItemsPageProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerItemsPage = observer(({ sx = [], className }: PlannerItemsPageProps) => {
  const params = useParams();
  const plannerId = useActivePlannerId();
  const courseSectionId = params.courseSectionId;

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPlannerItemsViewModel(plannerId, courseSectionId),
    [plannerId, courseSectionId]
  );

  useEffect(() => {
    void viewModel.reloadData();
  }, [plannerId, courseSectionId]);

  useSync(
    'PlannerItems',
    async () => {
      await viewModel.reloadData();
    },
    [plannerId, courseSectionId]
  );

  return (
    <Box
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Grid2 container sx={{ width: '100%', height: '100%' }}>
          <Grid2
            size={{ xs: 12, md: 10, lg: 8, xl: 6 }}
            offset={{ md: 1, lg: 2, xl: 3 }}
            sx={{
              height: '100%'
            }}
          >
            <BackgroundImageScreenPaper sx={{ height: 'calc(100% - 2px)' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <UpdatablePresenter
                  sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
                  viewModel={viewModel}
                  renderData={() => (
                    <PlannerItems sx={{ width: '100%', height: '100%', overflow: 'hidden' }} viewModel={viewModel} />
                  )}
                />
              </Box>
            </BackgroundImageScreenPaper>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
});
