import {
  CourseSectionInfo,
  plannerCourseSectionDetailsToInfo,
  plannerHasAccessKindsForUser,
  UserDashboardInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { Loadable, PlannerDataStore, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { computed, makeObservable } from 'mobx';
import {
  AppBaseScheduleCycleActivitySchedulesViewModel,
  ScheduleCycleActivitySchedulesViewModel
} from './BaseScheduleCycleActivitySchedulesViewModel';

export class AppScheduleCycleActivitySchedulesViewModel
  extends AppBaseScheduleCycleActivitySchedulesViewModel
  implements ScheduleCycleActivitySchedulesViewModel
{
  constructor(
    dashboard: UserDashboardInfo,
    plannerId: string,
    scheduleCycleId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(plannerId, dashboard, scheduleCycleId, userStore, localization);
    makeObservable(this);
  }

  @computed
  private get plannerCourseSectionsLoadable() {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId!);
  }

  @computed
  protected get courseSectionsLoadable(): Loadable<unknown> {
    return this.plannerCourseSectionsLoadable;
  }

  @computed
  private get courseSectionsInfos(): CourseSectionDetails[] {
    return this.plannerCourseSectionsLoadable.values;
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionsLoadable];
  }

  @computed
  protected get courseSections(): CourseSectionInfo[] {
    return this.plannerCourseSectionsLoadable.values.map(plannerCourseSectionDetailsToInfo);
  }

  canEditActivitySchedulesForCourseId(id: string): boolean {
    const planner = this._plannerId != null ? this._userStore.getPlannerForId(this._plannerId) : undefined;

    if (
      planner != null &&
      !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS)
    ) {
      return false;
    }

    if (!this.isReadOnly) {
      return true;
    }

    if (id.length === 0) {
      return false;
    }

    const course = this.courseSectionsInfos.find((courseSection) => courseSection.courseSection!.id === id);
    if (course == null) {
      return false;
    }

    return course.schoolsCourseSection == null || course.role !== CourseSectionRole.STUDENT;
  }
}
