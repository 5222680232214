import { usePrevious } from '@/hooks';
import { UnsplashPictureViewModel } from '@/viewmodels';
import { Box, Grid2, GridSize } from '@mui/material';
import { range } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { UnsplashPicture } from './UnsplashPicture';

export interface UnsplashGalleryProps {
  className?: string;
  pictures: UnsplashPictureViewModel[];
  nbColumns?: number;
  onSelectPicture?: (picture?: UnsplashPictureViewModel) => void;
}

export const UnsplashGallery = observer(
  ({ className, pictures, nbColumns = 3, onSelectPicture }: UnsplashGalleryProps) => {
    const [selectedPicture, setSelectedPicture] = useState<UnsplashPictureViewModel | undefined>();
    const columns = getColumns(pictures, nbColumns);

    const selectPicture = (picture?: UnsplashPictureViewModel) => {
      if (onSelectPicture != null) {
        if (picture == null || selectedPicture?.id === picture?.id) {
          setSelectedPicture(undefined);
          onSelectPicture?.();
        } else {
          setSelectedPicture(picture);
          onSelectPicture(picture);
        }
      }
    };

    useEffect(selectPicture, [didPicturesChange(pictures, usePrevious(pictures))]);

    return (
      <Grid2 className={className} container spacing={1}>
        {columns.map((p, i) => (
          <Grid2 key={`gallery-column-index-${i}`} size={{ xs: Math.floor(12 / nbColumns) as GridSize }}>
            {p.map((picture) => (
              <Box key={picture.id}>
                <UnsplashPicture
                  viewModel={picture}
                  selected={selectedPicture?.id === picture.id}
                  onClick={() => selectPicture(picture)}
                />
              </Box>
            ))}
          </Grid2>
        ))}
      </Grid2>
    );
  }
);

function getColumns(pictures: UnsplashPictureViewModel[], nbCols: number): UnsplashPictureViewModel[][] {
  return pictures.reduce(
    (ac: UnsplashPictureViewModel[][], value, index) => {
      const colIndex = index % nbCols;
      ac[colIndex].push(value);
      return ac;
    },
    range(nbCols).map(() => [])
  );
}

function didPicturesChange(
  newPictures: UnsplashPictureViewModel[],
  oldPictures: UnsplashPictureViewModel[] = []
): boolean {
  const oldPictureIds = oldPictures.map((p) => p.id).join(',');
  const newPictureIds = newPictures.map((p) => p.id).join(',');
  return !newPictureIds.startsWith(oldPictureIds);
}
