import { useServices } from '@/hooks';
import LogoDark from '@/resources/images/logo_dark.svg?react';
import LogoLight from '@/resources/images/logo_light.svg?react';
import { CSSProperties } from 'react';
import { ThemeVariant } from '../../services';

export interface LogoProps {
  className?: string;
  style?: CSSProperties;
  forcedVariant?: ThemeVariant;
}

export function Logo({ style, className, forcedVariant }: LogoProps) {
  const { theme } = useServices();
  const resolvedVariant = forcedVariant ?? theme?.variant ?? 'light';

  if (resolvedVariant === 'light') {
    return <LogoLight className={className} style={style} />;
  } else {
    return <LogoDark className={className} style={style} />;
  }
}
