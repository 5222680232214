import { Width } from '@/utils';
import { LoadingButton } from '@mui/lab';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { MediaQuery } from './MediaQuery';

export type ButtonVariant = 'text' | 'outlined' | 'contained' | 'contained-grey';
export type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export interface ResponsiveButtonProps {
  /**
   * Screen size considered to be small. Used to determine whether to display a button or an icon.
   */
  smallScreenWidth: Width;

  /**
   * The button label. Will be the button tooltip on small screen.
   */
  label: string;

  /**
   * The icon on small screen.
   */
  icon: ReactNode;

  /**
   * Indicates whether to disable the button. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * The button variant. Will be used on bigger screens only. Optional.
   */
  variant?: ButtonVariant;

  /**
   * The button color. Optional.
   */
  color?: ButtonColor;

  /**
   * Indicates the button should be displayed as loading. Optional. Default is `false`.
   */
  loading?: boolean;

  className?: string;

  sx?: SxProps;

  /**
   * Callback when clicking the button.
   */
  onClick?: () => void;
}

export const ResponsiveButton = ({ smallScreenWidth, label, icon, ...props }: ResponsiveButtonProps) => {
  const desktopMediaQuery = (th: Theme) => th.breakpoints.up(smallScreenWidth);
  const mobileMediaQuery = (th: Theme) => th.breakpoints.down(smallScreenWidth);

  return (
    <>
      <MediaQuery mediaQuery={desktopMediaQuery}>
        <LoadingButton {...props}>{label}</LoadingButton>
      </MediaQuery>

      <MediaQuery mediaQuery={mobileMediaQuery}>
        <Tooltip title={label}>
          <LoadingButton component={IconButton} {...props} variant="text">
            {icon}
          </LoadingButton>
        </Tooltip>
      </MediaQuery>
    </>
  );
};
