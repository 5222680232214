import {
  ApplicationSettingsStorage,
  EnvironmentService,
  FeatureFlagService,
  FirebaseContract,
  LocalizationService
} from '@/services';
import type { Message, MethodInfo, PartialMessage, ServiceType } from '@bufbuild/protobuf';
import { Code, ConnectError, Interceptor, Transport } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { BaseTransportService } from '../contracts';

const GoogleClickIdHeader = 'X-Today-GCLID';
const FacebookClickIdHeader = 'X-Today-FBCLID';
const HubspotContactIdHeader = 'X-Today-HSCID';

interface ReadRequestParams {
  /**
   * Indicates to refresh the idToken. Used for recursion. Default is `false`.
   */
  forceRefreshIdToken?: boolean;

  /**
   * Indicates that the request is for a demo object. If true, X-Today-Demo header will be set to 0 if demo mode is
   * not enabled. Defaults to `false`.
   */
  isDemoObject?: boolean;

  /**
   * If true, the X-Today-Demo header will be set to 1 if demo mode is activated. Will not prevent the header
   * to be set to 0 if `isDemoObject` is set.
   */
  supportsDemoMode?: boolean;

  customHeaders?: Record<string, string>;
}

interface WriteRequestParams {
  /**
   * Indicates to refresh the idToken. Used for recursion only if error is `Unauthenticated`. Default is `false`.
   */
  forceRefreshIdToken?: boolean;

  /**
   * Indicates that the request is for a demo object. If true, X-Today-Demo header will be set to 0 if demo mode is
   * not enabled. Defaults to `false`.
   */
  isDemoObject?: boolean;

  /**
   * If true, the X-Today-Demo header will be set to 1 if demo mode is activated. Will not prevent the header
   * to be set to 0 if `isDemoObject` is set.
   */
  supportsDemoMode?: boolean;

  customHeaders?: Record<string, string>;
}

export abstract class AppBaseTransportService implements BaseTransportService {
  protected readonly _transport: Transport;

  constructor(
    protected readonly _environment: EnvironmentService,
    protected readonly _firebase: FirebaseContract,
    protected readonly _localization: LocalizationService,
    protected readonly _settingsStorage: ApplicationSettingsStorage,
    protected readonly _featureFlag: FeatureFlagService
  ) {
    // https://github.com/SafetyCulture/grpc-web-devtools#connect-web
    // __CONNECT_WEB_DEVTOOLS__ is loaded in as a script, so it is not guaranteed to be loaded before your code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const interceptors: Interceptor[] =
      window?.__CONNECT_WEB_DEVTOOLS__ != null ? [window.__CONNECT_WEB_DEVTOOLS__] : [];
    // To get around the fact that __CONNECT_WEB_DEVTOOLS__ might not be loaded, we can listen for a custom event,
    // and then push the interceptor to our array once loaded.
    window?.addEventListener('connect-web-dev-tools-ready', () => {
      if (window?.__CONNECT_WEB_DEVTOOLS__ != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
      }
    });

    this._transport = createGrpcWebTransport({ baseUrl: _environment.todayApiUrl, interceptors: interceptors });
  }

  /**
   * Send an authenticated request to the api that does not modify or create data.
   * If the request fails, the idToken will be refreshed. Then, if it still fails, the error will be thrown.
   * @param requestName The name of the request. Used in logging.
   * @param service The service the use for the request.
   * @param method The method the use for the request
   * @param request The request object.
   * @param options Additional options fo the request.
   * @private
   * @return Promise<TResponse> The api request response.
   */
  protected async performReadRequest<I extends Message<I>, O extends Message<O>>(
    requestName: string,
    service: ServiceType,
    method: MethodInfo<I, O>,
    request: PartialMessage<I>,
    options: ReadRequestParams = {}
  ): Promise<O> {
    const { forceRefreshIdToken = false, isDemoObject = false, supportsDemoMode = false, customHeaders } = options;

    if (forceRefreshIdToken) {
      await this._firebase.refreshIdToken();
    }

    const headers = this.makeHeaders(isDemoObject, supportsDemoMode, customHeaders);

    try {
      const response = await this._transport.unary(
        service,
        method,
        undefined, // AbortSignal
        undefined, // Timeout
        headers,
        request
      );
      return response.message;
    } catch (err) {
      console.log(`API call "${requestName}" failed with ${(err as Error).message}.`);

      if (err instanceof ConnectError && err.code === Code.Unauthenticated && !forceRefreshIdToken) {
        console.log(`Retrying with a force-refreshed access token...`);
        return await this.performReadRequest(requestName, service, method, request, {
          ...options,
          forceRefreshIdToken: true
        });
      }

      throw err;
    }
  }

  /**
   * Send an authenticated request to the api that can modify or create data. This request is
   * never retried.
   * @param requestName The name of the request. Used in logging.
   * @param service The service the use for the request.
   * @param method The method the use for the request
   * @param request The request object.
   * @param options Additional options fo the request.
   * @private
   * @return Promise<TResponse> The api request response.
   */
  protected async performWriteRequest<I extends Message<I>, O extends Message<O>>(
    requestName: string,
    service: ServiceType,
    method: MethodInfo<I, O>,
    request: PartialMessage<I>,
    options: WriteRequestParams = {}
  ): Promise<O> {
    const { forceRefreshIdToken = false, isDemoObject = false, supportsDemoMode = false, customHeaders } = options;

    if (forceRefreshIdToken) {
      await this._firebase.refreshIdToken();
    }

    const headers = this.makeHeaders(isDemoObject, supportsDemoMode, customHeaders);

    try {
      const response = await this._transport.unary(
        service,
        method,
        undefined, // AbortSignal
        undefined, // Timeout
        headers,
        request
      );
      return response.message;
    } catch (err) {
      console.log(`API call "${requestName}" failed with ${(err as Error).message}.`);

      if (err instanceof ConnectError && err.code === Code.Unauthenticated && !forceRefreshIdToken) {
        console.log(`Retrying with a force-refreshed access token...`);
        return await this.performReadRequest(requestName, service, method, request, {
          ...options,
          forceRefreshIdToken: true
        });
      }

      throw err;
    }
  }

  protected isDemoId(id: string) {
    return id.startsWith('demo');
  }

  private makeHeaders(
    isDemoObject: boolean,
    supportsDemoMode: boolean,
    customHeaders: Record<string, string> | undefined
  ): HeadersInit {
    const headers: HeadersInit = {
      Authorization: `Bearer ${this._firebase.idToken}`,
      'Accept-Language': this._localization.currentLocale,
      'X-Today-TimeZone': this._localization.currentTimezone,
      ...customHeaders
    };

    if (this._settingsStorage.googleClickId != null) {
      headers[GoogleClickIdHeader] = this._settingsStorage.googleClickId;
    }

    if (this._settingsStorage.facebookClickId != null) {
      headers[FacebookClickIdHeader] = this._settingsStorage.facebookClickId;
    }

    if (this._settingsStorage.hubspotContactId != null) {
      headers[HubspotContactIdHeader] = this._settingsStorage.hubspotContactId;
    }

    if (this._settingsStorage.isDemoMode && supportsDemoMode) {
      headers['X-Today-Demo'] = '1';
    } else if (isDemoObject) {
      headers['X-Today-Demo'] = '0';
    }

    return headers;
  }
}
