import { ScheduleCycleActivitySchedulesActivityInfo, ScheduleCycleActivitySchedulesCoursesFilter } from '@/viewmodels';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import LocalizedStrings from 'strings';
import { ActionMenu, ActionMenuButton, ListItemTextMultiline } from '../../../../utils';
import { ScheduleCycleActivityScheduleEdit } from './ScheduleCycleActivityScheduleEdit';

export interface ScheduleCycleActivitySchedulesListItemProps {
  sx?: SxProps;
  className?: string;
  activity: Activity;
  activitySchedule: ScheduleCycleActivitySchedulesActivityInfo;
  removeActivitySchedule: (id: string) => void;
  isReadOnly: boolean;
  filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined;
}

export const ScheduleCycleActivitySchedulesListItem = observer(
  ({
    sx = [],
    className,
    activity,
    activitySchedule,
    removeActivitySchedule,
    isReadOnly,
    filters
  }: ScheduleCycleActivitySchedulesListItemProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('md'));
    const [actionMenuButtonRef, setActionMenuButtonRef] = useState<HTMLButtonElement | undefined>();
    const [editButtonRef, setEditButtonRef] = useState<HTMLElement | undefined>();
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules;

    function onEditClick(e: MouseEvent<HTMLElement>) {
      setEditButtonRef(e.currentTarget);
    }

    function onRemoveClick() {
      setActionMenuButtonRef(undefined);
      removeActivitySchedule(activitySchedule.id);
    }

    const renderActions = () =>
      isReadOnly ? null : isSmallScreen ? (
        <>
          <ActionMenuButton onClick={(e) => setActionMenuButtonRef(e.currentTarget)} />
          <ActionMenu
            open={actionMenuButtonRef != null}
            anchorEl={actionMenuButtonRef}
            onClose={() => setActionMenuButtonRef(undefined)}
          >
            <MenuItem onClick={onEditClick}>
              <ListItemIcon>
                <EditRounded />
              </ListItemIcon>
              {strings.editScheduleOption()}
            </MenuItem>
            <MenuItem onClick={onRemoveClick}>
              <ListItemIcon>
                <DeleteRounded color="error" />
              </ListItemIcon>
              <ListItemText primary={strings.deleteScheduleOption()} primaryTypographyProps={{ color: 'error' }} />
            </MenuItem>
          </ActionMenu>
        </>
      ) : (
        <>
          <Tooltip title={strings.editScheduleTooltip()} disableInteractive>
            <IconButton size="small" onClick={onEditClick}>
              <EditRounded fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title={strings.deleteScheduleTooltip()} disableInteractive>
            <IconButton size="small" onClick={onRemoveClick}>
              <DeleteRounded fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      );

    return (
      <ListItem sx={sx} className={className} secondaryAction={renderActions()}>
        <ListItemTextMultiline primary={activitySchedule.primaryText} secondary={activitySchedule.secondaryText} />

        {editButtonRef != null && (
          <Popover
            open={true}
            anchorEl={editButtonRef}
            onClose={() => setEditButtonRef(undefined)}
            slotProps={{ paper: { sx: { width: 400, height: 600 } } }}
          >
            <ScheduleCycleActivityScheduleEdit
              activityScheduleId={activitySchedule.id}
              activity={activity}
              sx={{ height: '100%', width: '100%' }}
              dismiss={() => setEditButtonRef(undefined)}
              filters={filters}
            />
          </Popover>
        )}
      </ListItem>
    );
  }
);
