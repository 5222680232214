import { isAdminOfSharedSchool } from '@/models';
import { ServiceContainer } from '@/providers';
import LocalizedStrings from '@/resources/strings';
import { SchoolDataStore, UserDataStore } from '@/stores';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { UserSummary } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/user_summary_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface AdminSchoolAdminUsersListViewModel {
  readonly administrators: UserSummary[];
  readonly owners: UserSummary[];
  readonly currentUserId: string;
  readonly isCurrentUserAdmin: boolean;
  readonly isCurrentUserOwner: boolean;

  readonly isApplying: boolean;
  readonly error?: string;

  changeOwner(user: UserSummary): Promise<void>;
  removeAdmin(user: UserSummary): Promise<void>;
}

export class AppAdminSchoolAdminUsersListViewModel implements AdminSchoolAdminUsersListViewModel {
  @observable private _isApplying = false;
  @observable private _error: string | undefined;

  constructor(
    private readonly _schoolId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    makeObservable(this);
  }

  @computed
  private get school(): SchoolInformation | undefined {
    return this._userStore.getSchoolForId(this._schoolId);
  }

  @computed
  get administrators(): UserSummary[] {
    return this.school?.administrators ?? [];
  }

  @computed
  get owners(): UserSummary[] {
    return this.school?.owners ?? [];
  }

  @computed
  get currentUserId(): string {
    return this._userStore.user.userId;
  }

  @computed
  get isCurrentUserAdmin(): boolean {
    return this.school != null && isAdminOfSharedSchool(this._userStore.user.userId, this.school);
  }

  @computed
  get isCurrentUserOwner(): boolean {
    return this.school?.owners.some((o) => o.userId === this._userStore.user.userId) ?? false;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @action
  async changeOwner(user: UserSummary): Promise<void> {
    this._isApplying = true;
    this._error = undefined;

    try {
      await this._schoolStore.changeSchoolOwnership(this._schoolId, user.userId);
    } catch (error) {
      captureException(error);
      runInAction(() => (this._error = LocalizedStrings.admin.general.adminsList.unexpectedErrorMessage()));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  async removeAdmin(user: UserSummary): Promise<void> {
    this._isApplying = true;
    this._error = undefined;

    try {
      await this._schoolStore.removeSchoolAccess(this._schoolId, user.userId);
    } catch (error) {
      captureException(error);
      runInAction(() => (this._error = LocalizedStrings.admin.general.adminsList.unexpectedErrorMessage()));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }
}
