import { useServices } from '@/hooks';
import { PlannerListViewModel } from '@/viewmodels';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannerList } from './PlannerList';
import { PlannerListItem } from './PlannerListItem';
import { PlannerTodayListEmptyIndicator } from './PlannerTodayListEmptyIndicator';

export interface PlannerTodayListProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerListViewModel;
  goToInbox?: () => void;
}

export const PlannerTodayList = observer(({ sx = [], className, viewModel, goToInbox }: PlannerTodayListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <PlannerList
      sx={sx}
      className={className}
      viewModel={viewModel}
      title={strings.todayTitle}
      subtitle={strings.todaySubtitle}
      renderItem={(item) => <PlannerListItem item={item} displaySteps />}
      renderEmptyIndicator={() => <PlannerTodayListEmptyIndicator onButtonPress={goToInbox} />}
    />
  );
});
