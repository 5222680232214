import { ConnectedAppService } from '@/services';
import { UpdatableViewModelState } from '../shared';

export function updatableViewModelStateForConnectedApps(
  connectedAppServices: ConnectedAppService<string>[]
): UpdatableViewModelState {
  if (connectedAppServices.some((s) => s.isSyncing)) {
    return 'pending';
  } else if (connectedAppServices.some((s) => s.hasSyncError)) {
    return new Error();
  } else {
    return 'fulfilled';
  }
}
