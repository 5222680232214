import { CourseSectionColors } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService, ThemeService } from '@/services';
import { PlannerDataStore, PlannerDetailedCourseSectionsLoadable } from '@/stores';
import { randomElement } from '@/utils';
import { computed, makeObservable, runInAction } from 'mobx';
import { AppBaseCourseSectionEditViewModel, CourseSectionEditInfo } from './CourseSectionEditViewModel';

export class AppPlannerCourseSectionEditViewModel extends AppBaseCourseSectionEditViewModel {
  constructor(
    courseSectionId: string | undefined,
    plannerId: string,
    onSuccess: (courseSectionId: string) => Promise<void>,
    onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    theme: ThemeService = ServiceContainer.services.theme,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore
  ) {
    super(courseSectionId, plannerId, onSuccess, onCancel, localization, theme);
    makeObservable(this);
  }

  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._dashboardId);
  }

  @computed
  get canEditNames(): boolean {
    return !this._initialData!.hasExternalSource;
  }

  protected async createCourseSection(): Promise<string> {
    const newCourse = await this._plannerStore.createCourseSection(
      this._dashboardId,
      this.title,
      this.section,
      this.backgroundColor ?? ''
    );
    return newCourse.id;
  }

  protected async updateCourseSection(id: string): Promise<string> {
    const updatedCourse = await this._plannerStore.updateCourseSection(
      id,
      this._dashboardId,
      this.title,
      this.section,
      this.backgroundColor ?? '',
      this.isVisible,
      this._initialData!.syncToken
    );

    return updatedCourse.id;
  }

  protected async loadData(): Promise<void> {
    if (this._courseSectionId == null) {
      const value: CourseSectionEditInfo = {
        title: '',
        section: '',
        color: randomElement(CourseSectionColors)!,
        isVisible: true,
        syncToken: BigInt(0),
        hasExternalSource: false
      };

      runInAction(() => {
        this._initialData = value;
        this._title = value.title;
        this._section = value.section;
        this._color = value.color;
        this._isVisible = value.isVisible;
        this._state = 'fulfilled';
      });
      return;
    }

    runInAction(() => (this._state = 'pending'));

    try {
      await this.courseSectionsLoadable.fetch(true);

      if (!this.courseSectionsLoadable.hasData) {
        throw new Error('An error occurred while loading course sections.');
      }

      const courseSection = this.courseSectionsLoadable.data.get(this._courseSectionId);
      if (courseSection == null) {
        throw new Error(`No CourseSection found for id ${this._courseSectionId}`);
      }

      const { title, section, externalSource, color, isVisible, syncToken } = courseSection.courseSection!;

      const value: CourseSectionEditInfo = {
        title,
        section,
        color,
        isVisible,
        syncToken,
        hasExternalSource: externalSource != null
      };

      runInAction(() => {
        this._initialData = value;
        this._title = value.title;
        this._section = value.section;
        this._color = value.color;
        this._isVisible = value.isVisible;
        this._state = 'fulfilled';
      });
    } catch (e) {
      runInAction(() => (this._state = e as Error));
    }
  }
}
