import { ListSection } from '@/models';
import { Box, Divider, ListSubheader } from '@mui/material';
import { Observer } from 'mobx-react-lite';
import { ListChildComponentProps } from 'react-window';

export interface Row {
  section: number;
  index: number | undefined;
}

export interface ListRowProps extends ListChildComponentProps {
  /**
   * The row to render.
   */
  row: Row | undefined;
  /**
   * The row's section.
   */
  section: ListSection | undefined;

  /**
   * Render section's row
   * @param section The section's index
   * @param row The row's index in the section
   */
  renderItem: (section: number, row: number) => JSX.Element;

  /**
   * Hides the divider. Defaults to false.
   */
  hideDivider?: boolean;
}

export const ListRow = ({ row, section, renderItem, style, hideDivider = false }: ListRowProps) => {
  if (row?.index != null) {
    return (
      <Observer>
        {() => (
          <Box style={style}>
            {renderItem(row.section, row.index!)}
            {/* Not displaying divider for last item in section */}
            {!hideDivider && row.index! < section!.numberOfRows - 1 && <Divider variant="middle" />}
          </Box>
        )}
      </Observer>
    );
  } else if (row != null) {
    // The box overflow needs to be hidden otherwise its content "bleeds" over the next item.
    return (
      <Box style={style} sx={{ overflow: 'hidden' }}>
        <ListSubheader>{section!.title}</ListSubheader>
      </Box>
    );
  }

  console.warn("Rendering a row which doesn't exist in the data.");
  return null;
};
