import { WorkTransportService } from '@/transports';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { action, makeObservable } from 'mobx';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export interface NoteLoadable extends LoadableValue<Note> {
  // Some actions can result in obtaining an updated version of the Work model. Instead of doing a fetch, we can
  // simply update the value stored.
  updateWithValue(newValue: Note): void;
}

export class AppNoteLoadable extends BaseLoadableValue<Note> implements NoteLoadable {
  constructor(
    private readonly _workId: string,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  @action
  updateWithValue(newValue: Note) {
    this._data = newValue;
    this._state = 'fulfilled';
  }

  protected async loadData(): Promise<Note> {
    return await this._workTransport.getNote(this._workId);
  }
}
