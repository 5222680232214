import {
  AllConnectedAppKinds,
  ConnectedAppKind,
  ConnectedAppUserDashboard,
  ConnectedAppUserDashboardKind
} from '@/models';
import { chain } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { UserDataStore } from '../../stores';
import { ClassroomTransportService, GoogleCalendarTransportService } from '../../transports';
import { getOrCreateInObservableMap } from '../../utils';
import {
  AutoSyncService,
  ClassroomConnectedAppService,
  ConnectedAppService,
  ConnectedAppsService,
  GapiService,
  GoogleCalendarConnectedAppService,
  UserService
} from '../contracts';
import { AppClassroomConnectedAppService, AppGoogleCalendarConnectedAppService } from './connectedApps';

export class AppConnectedAppsService implements ConnectedAppsService {
  private _classroomServices = observable.map<string, ClassroomConnectedAppService>();
  private _googleCalendarServices = observable.map<string, GoogleCalendarConnectedAppService>();

  constructor(
    private readonly _userStore: UserDataStore,
    private readonly _user: UserService,
    private _autoSync: AutoSyncService,
    private readonly _classroomTransport: ClassroomTransportService,
    private readonly _googleCalendarTransport: GoogleCalendarTransportService,
    private readonly _gapi: GapiService
  ) {
    makeObservable(this);
  }

  @computed
  get connectedApps(): ConnectedAppService<string>[] {
    return [];
  }

  getHasWarning(plannerId: string): boolean {
    const services = this.getAllServicesForPlanner(plannerId, undefined);
    return services.some((s) => s.hasWarning);
  }

  getHasError(plannerId: string): boolean {
    const services = this.getAllServicesForPlanner(plannerId, undefined);
    return services.some((s) => s.hasSyncError);
  }

  getIsSyncing(plannerId: string): boolean {
    const services = this.getAllServicesForPlanner(plannerId, undefined);
    return services.some((s) => s.isSyncing);
  }

  async refreshSyncStatus(plannerId: string) {
    await this.refreshSyncStatusForUserDashboard(plannerId);
  }

  async sync(plannerId: string) {
    await this.syncUserDashboard(plannerId);
  }

  reset() {
    this.connectedApps.forEach((c) => c.reset());
  }

  getConnectedAppService(
    kind: ConnectedAppKind,
    plannerId: string | undefined,
    schoolId: string | undefined
  ): ConnectedAppService<string> | undefined {
    const dashboard = this.getUserDashboard(plannerId, schoolId);

    if (dashboard == null || !this.getSupportedConnectedKindsForDashboardKind(dashboard.case).includes(kind)) {
      return undefined;
    }

    const id = this.makeIdFromIds(plannerId, schoolId);

    switch (kind) {
      case 'classroom':
        return getOrCreateInObservableMap(this._classroomServices, id, () => this.makeClassroomService(dashboard));
      case 'googleCalendar':
        return getOrCreateInObservableMap(this._googleCalendarServices, id, () =>
          this.makeGoogleCalendarService(dashboard)
        );
    }
  }

  getConnectedAppServices(
    kinds: ConnectedAppKind[] | undefined,
    plannerId: string | undefined,
    schoolId: string | undefined
  ): ConnectedAppService<string>[] {
    return chain(kinds ?? AllConnectedAppKinds)
      .map((kind) => this.getConnectedAppService(kind, plannerId, schoolId))
      .compact()
      .value();
  }

  getAllServicesForPlanner(plannerId: string, kind: ConnectedAppKind | undefined): ConnectedAppService<string>[] {
    const planner = this._userStore.getPlannerForId(plannerId);

    if (planner == null) {
      return [];
    }

    const services = this.getConnectedAppServices(kind != null ? [kind] : undefined, plannerId, undefined);

    const schools = this._userStore.participatingSchools.values.filter(
      (s) =>
        s.school?.isArchived !== true &&
        planner.schoolIds.includes(s.school!.id) &&
        !planner.ignoredSchoolIds.includes(s.school!.id)
    );

    schools.forEach((school) => {
      services.push(...this.getConnectedAppServices(kind != null ? [kind] : undefined, plannerId, school.school!.id));

      // Creating services for legacy 'schoolId' connection.
      services.push(...this.getConnectedAppServices(kind != null ? [kind] : undefined, undefined, school.school!.id));
    });

    return services;
  }

  private async refreshSyncStatusForUserDashboard(plannerId: string) {
    const services = this.getAllServicesForPlanner(plannerId, undefined);
    await Promise.all(services.map((s) => s.refreshSyncStatus()));
  }

  private async syncUserDashboard(plannerId: string) {
    const services = this.getAllServicesForPlanner(plannerId, undefined);
    await Promise.all(services.map((s) => s.sync()));
  }

  private makeIdFromIds(plannerId: string | undefined, schoolId: string | undefined): string {
    return `${plannerId ?? '???'}/${schoolId ?? '???'}`;
  }

  private getUserDashboard(
    plannerId: string | undefined,
    schoolId: string | undefined
  ): ConnectedAppUserDashboard | undefined {
    if (schoolId != null && plannerId != null) {
      return { case: 'plannerSchool', plannerId, schoolId };
    } else if (plannerId != null) {
      return { case: 'planner', plannerId };
    } else if (schoolId != null) {
      return { case: 'school', schoolId };
    } else {
      return undefined;
    }
  }

  private getSupportedConnectedKindsForDashboardKind(kind: ConnectedAppUserDashboardKind): ConnectedAppKind[] {
    switch (kind) {
      case 'planner':
        return ['classroom', 'googleCalendar'];
      case 'school':
      case 'plannerSchool':
        return ['classroom'];
    }
  }

  private makeClassroomService(dashboard: ConnectedAppUserDashboard): ClassroomConnectedAppService {
    return new AppClassroomConnectedAppService(
      dashboard,
      this._user,
      this._autoSync,
      this._classroomTransport,
      this._gapi,
      this._userStore
    );
  }

  private makeGoogleCalendarService(dashboard: ConnectedAppUserDashboard) {
    return new AppGoogleCalendarConnectedAppService(
      dashboard,
      this._user,
      this._autoSync,
      this._googleCalendarTransport,
      this._gapi
    );
  }
}
