import { Currency, SubscriptionInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { UserDataStore } from '@/stores';
import { PlanRecurrenceKind } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/plan_recurrence_kind_pb';
import { Product } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_pb';
import { ProductPrice } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_price_pb';
import { Browser } from '@capacitor/browser';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { LocalizationService } from '../../services';

export interface ProductViewModel {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly isFree: boolean;
  readonly formattedPrice: string;
  readonly isCurrentSubscription: boolean;
  readonly isCurrentSubscriptionActive: boolean;
  readonly currentSubscriptionTrialDaysRemaining: number | undefined;
  readonly canSubscribe: boolean;
  readonly schoolIds: string[];

  readonly isApplying: boolean;
  readonly error: string | undefined;

  subscribe(sharedSchoolIds: string[] | undefined, inheritedScheduleCycleId: string | undefined): Promise<void>;
  manageSubscription(): Promise<void>;
}

export class AppProductViewModel implements ProductViewModel {
  @observable _planRecurrenceKind = PlanRecurrenceKind.PLAN_KIND_YEARLY;
  @observable private _isApplying = false;
  @observable private _error: string | undefined;

  constructor(
    private readonly _product: Product,
    public readonly schoolIds: string[],
    private readonly _redirectUrl: string,
    private readonly _currencyResolver: () => Currency,
    private readonly _onInvoiceCreation: (sharedSchoolIds: string[]) => void,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  private get subscription(): SubscriptionInfo | undefined {
    return this._userStore.subscription.data;
  }

  @computed
  get id(): string {
    return this._product.id;
  }

  @computed
  get title(): string {
    return this._product.title;
  }

  @computed
  get description(): string {
    return this._product.description;
  }

  readonly isFree = false;

  @computed
  get formattedPrice(): string {
    const { price, currency, kind } = this.productPrice;
    const priceInDollar = price * 0.01;
    const strings = LocalizedStrings.subscriptions.product;
    const locale = this._localization.currentLocale;

    switch (kind) {
      case PlanRecurrenceKind.PLAN_KIND_MONTHLY:
        return strings.formattedMonthlyPrice(priceInDollar, currency as Currency, locale);

      default:
        return strings.formattedYearlyPrice(priceInDollar, currency as Currency, locale);
    }
  }

  @computed
  get isCurrentSubscription(): boolean {
    return this.subscription?.product.id === this._product.id;
  }

  @computed
  get isCurrentSubscriptionActive(): boolean {
    return this.subscription?.subscription.isActive ?? false;
  }

  @computed
  get currentSubscriptionTrialDaysRemaining(): number | undefined {
    return this.subscription?.subscription.trialDaysRemaining;
  }

  @computed
  get canSubscribe(): boolean {
    return !this.isFree && !this.isCurrentSubscription;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  private get productPrice(): ProductPrice {
    // Only consider active prices here.
    const prices = this._product.prices.filter((p) => p.isArchived === false);
    const currency = this._currencyResolver();
    return prices.find((p) => p.currency === currency) ?? prices[0];
  }

  @action
  async subscribe(sharedSchoolIds: string[] | undefined, inheritedScheduleCycleId: string | undefined) {
    this._isApplying = true;
    this._error = undefined;

    try {
      if (this.productPrice.canSubscribeWithInvoice) {
        await this._userStore.createInvoicedSubscription(this._product, this.productPrice.id);

        if (sharedSchoolIds != null) {
          await Promise.all(sharedSchoolIds.map((id) => this._userStore.shareSchool(id, inheritedScheduleCycleId)));
        }

        this._onInvoiceCreation(sharedSchoolIds ?? []);
      } else {
        const url = await this._userStore.getSubscribeUrl(this.productPrice, this._redirectUrl);
        await Browser.open({ url });
      }
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => (this._error = error.message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  async manageSubscription(): Promise<void> {
    runInAction(() => {
      this._isApplying = true;
      this._error = undefined;
    });

    try {
      const url = await this.getManageSubscriptionUrl();
      await Browser.open({ url });
    } catch (e) {
      captureException(e);
      const error = e as Error;
      const strings = this._localization.localizedStrings.utils;
      runInAction(() => (this._error = strings.manageSubscriptionErrorMessage(error.message)));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  private async getManageSubscriptionUrl(): Promise<string> {
    return await this._userStore.getManageSubscriptionUrl(window.location.href);
  }
}
