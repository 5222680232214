import { useServices } from '@/hooks';
import { SyncInfoViewModel } from '@/viewmodels';
import SyncIcon from '@mui/icons-material/SyncRounded';
import { CircularProgress, IconButton, Stack, Typography, TypographyVariant } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../utils';

export interface SyncInfoProps {
  viewModel: SyncInfoViewModel;
  textVariant?: TypographyVariant | 'inherit';
  textColor?: string;

  /**
   * Indicates to disable the sync button.
   * It will also be disabled when `viewModel.isFetching` is `true`.
   * Optional. Default is `false`.
   */
  disabled?: boolean;

  className?: string;

  sx?: SxProps;
}

export const SyncInfo = observer(
  ({ className, sx = [], viewModel, disabled, textVariant, textColor }: SyncInfoProps) => {
    const { localization, dateService } = useServices();
    const strings = localization.localizedStrings.connectedApps;

    return (
      <Stack
        className={className}
        direction="row"
        spacing={1}
        sx={[
          {
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Typography
          variant={textVariant ?? 'body2'}
          sx={{
            color: (theme) => textColor ?? theme.palette.text.secondary
          }}
        >
          {viewModel.isSyncing
            ? strings.syncing
            : viewModel.lastSync == null
              ? strings.neverSynced
              : strings.lastSync(viewModel.lastSync, dateService)}
        </Typography>
        {viewModel.isSyncing ? (
          <CircularProgress size={15} />
        ) : (
          <IconButton
            color="primary"
            disabled={viewModel.isFetching || disabled}
            onClick={() => void viewModel.sync()}
            sx={{ padding: 0 }}
          >
            <SyncIcon />
          </IconButton>
        )}
      </Stack>
    );
  }
);
