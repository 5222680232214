import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { differenceInMinutes } from 'date-fns';

export function timeSlotDuration(timeSlot: TimeSlot | undefined): number | undefined {
  const startTime = timeSlot?.startTime;
  const endTime = timeSlot?.endTime;

  if (startTime == null || endTime == null) {
    return undefined;
  }

  return differenceInMinutes(endTime.toDate(), startTime.toDate());
}

export function isDemoId(id: string): boolean {
  return id.startsWith('demo');
}
