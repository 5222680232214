import { useServices } from '@/hooks';
import { CreatePlannerViewModel } from '@/viewmodels';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { FormControl, InputLabel, MenuItem, Select, Stack, StackProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AlertDialog } from '../../utils';

export interface CreatePlannerProps extends StackProps {
  className?: string;
  viewModel: CreatePlannerViewModel;
}

export const CreatePlanner = observer(({ className, viewModel, ...props }: CreatePlannerProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.planners;
  const canSelectTargetKind = viewModel.selectableTargetKinds.length > 1;

  return (
    <>
      <Stack {...props} className={className} spacing={3}>
        <FormControl fullWidth>
          <InputLabel id="create-planner-target-kind-picker-label">{strings.targetKindSelectLabel}</InputLabel>
          <Select
            labelId="create-planner-target-kind-picker-label"
            label={strings.targetKindSelectLabel}
            fullWidth
            value={viewModel.targetKind}
            disabled={viewModel.isApplying || !canSelectTargetKind}
            onChange={(e) => (viewModel.targetKind = e.target.value as PlannerRelationshipKind)}
          >
            {viewModel.selectableTargetKinds.map((kind) => (
              <MenuItem key={`create-planner-target-kind-${kind}`} value={kind}>
                {strings.targetKindLabel(kind)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label={strings.creationPlannerTitleLabel}
          value={viewModel.title}
          placeholder={strings.defaultPlannerTitlePlaceholder(viewModel.targetKind)}
          fullWidth
          disabled={viewModel.isApplying}
          onChange={(e) => (viewModel.title = e.target.value)}
        />
      </Stack>

      {viewModel.createPlannerError != null && (
        <AlertDialog
          isOpen={true}
          title={strings.createPlannerErrorTitle}
          message={strings.createPlannerErrorMessage}
          onSubmit={() => viewModel.clearCreatePlannerError()}
        />
      )}
    </>
  );
});
