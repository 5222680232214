import { courseSectionInfoToActivity, TimeOfDay, UserDashboardInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { PlannerDataStore, SchoolDataStore, UserDataStore } from '@/stores';
import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { chain, times, uniq } from 'lodash';
import { computed, makeObservable, override } from 'mobx';
import LocalizedStrings from 'strings';
import { titleForCycleDay } from '../ScheduleCycleUtils';
import { ScheduleCycleActivitySchedulesCoursesFilter } from './ScheduleCycleActivitySchedulesCoursesFilter';
import { ScheduleCycleActivitySchedulesDayColumnInfo } from './ScheduleCycleActivitySchedulesDayColumnInfo';
import { ScheduleCycleActivitySchedulesPeriodInfo } from './ScheduleCycleActivitySchedulesPeriodInfo';
import { AppScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel } from './ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel';
import { ScheduleCyclePeriodSchedulePeriodEditViewModel } from './ScheduleCyclePeriodSchedulePeriodEditViewModel';
import { BaseScheduleCyclePeriodSchedulesGridViewModel } from './ScheduleCyclePeriodSchedulesGridViewModel';

export class AppScheduleCycleMasterScheduleGridViewModel extends BaseScheduleCyclePeriodSchedulesGridViewModel {
  constructor(
    private readonly _termId: string,
    private readonly _scheduleTag: string,
    dashboard: UserDashboardInfo,
    scheduleCycleId: string,
    plannerId: string | undefined,
    displayedTermId: string,
    filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(
      dashboard,
      plannerId,
      scheduleCycleId,
      displayedTermId,
      filters,
      plannerStore,
      schoolStore,
      userStore,
      localization
    );
    makeObservable(this);
  }

  @computed
  get columns(): ScheduleCycleActivitySchedulesDayColumnInfo[] {
    const terms = this.scheduleCycleStore.getTermAndConflictingTermsForId(this._termId);
    const termIds = ['', ...terms.map((t) => t.id)];

    return chain(times(this.scheduleCycle.cycleDayCount))
      .map<ScheduleCycleActivitySchedulesDayColumnInfo | undefined>((cd) => {
        const cycleDay = cd + 1;

        const periodSchedule = this.scheduleCycleStore.getPeriodScheduleForCycleDay(
          cycleDay,
          this.scheduleCycleKind,
          this._scheduleTag
        );

        if (
          periodSchedule == null &&
          this.scheduleCycleKind !== 'cycle-day' &&
          (cycleDay % 7 === 6 || cycleDay % 7 === 0)
        ) {
          return undefined;
        }

        const periods =
          periodSchedule != null ? this.getPeriodInfosForPeriodSchedule(periodSchedule, cycleDay, termIds) : [];

        const allPeriodLabels = periods.map((p) => p.periodLabel);
        const uniquePeriodLabels = uniq(allPeriodLabels);
        return {
          id: periodSchedule?.id ?? undefined,
          index: cd,
          canEdit: periodSchedule != null,
          title: titleForCycleDay(cycleDay, this.scheduleCycleKind, 'long', true, this.scheduleCycle.cycleDayNames),
          subtitle: periodSchedule?.name ?? LocalizedStrings.scheduleCycle.edit.masterSchedule.noScheduleForDay(),
          periods,
          hasMultiplePeriodsWithSameLabel: allPeriodLabels.length !== uniquePeriodLabels.length,
          hasOtherOccurrencesWithSameTarget: false
        };
      })
      .compact()
      .value();
  }

  @computed
  get emptyMessage(): string | undefined {
    return !this.columns.some((column) => column.periods.length > 0)
      ? LocalizedStrings.scheduleCycle.edit.masterSchedule.emptyGridMessage()
      : undefined;
  }

  @override
  get canEditPeriods(): boolean {
    switch (this.currentDashboard.kind) {
      case 'planner':
        return super.canEditPeriods;
      case 'school':
        return super.canEditPeriods || this.currentDashboard.school.isTeacher;
    }
  }

  async createPeriodEditViewModel(
    columnIndex: number,
    periodId: string | undefined,
    time: TimeOfDay
  ): Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel> {
    const cycleDay = columnIndex + 1;
    const periodSchedule = this.scheduleCycleStore.getPeriodScheduleForCycleDay(
      cycleDay,
      this.scheduleCycleKind,
      this._scheduleTag
    );
    if (periodSchedule == null) {
      throw new Error(`Cannot edit period for cycle day ${cycleDay} as there are no PeriodSchedule.`);
    }
    const { period, suggestions } = await this.getPeriodAndSuggestions(periodSchedule.id, periodId, time);

    return new AppScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel(
      this._scheduleCycleId,
      periodSchedule.id,
      period,
      periodSchedule.name,
      suggestions,
      this._dashboard,
      this._scheduleTag,
      this._plannerId,
      cycleDay,
      this._displayedTermId,
      this._filters
    );
  }

  private getPeriodInfosForPeriodSchedule(
    periodSchedule: PeriodSchedule,
    cycleDay: number,
    termIds: string[]
  ): ScheduleCycleActivitySchedulesPeriodInfo[] {
    return periodSchedule.periods.map((period) => {
      const activitySchedules = this.scheduleCycleStore.getActivitySchedulesForPeriod(
        { case: 'periodLabel', value: period.label },
        cycleDay,
        termIds,
        this._scheduleTag,
        this.courseSections.map(courseSectionInfoToActivity)
      );

      const primaryActivitySchedule = this.scheduleCycleStore.getPrimaryActivitySchedule(
        activitySchedules,
        this._termId,
        this._scheduleTag
      );

      const activity = this.scheduleCycleStore.getCourseSectionForActivitySchedule(
        primaryActivitySchedule,
        this.courseSections
      );

      return {
        id: period.id,
        periodLabel: period.label,
        startTime: period.startTime!,
        endTime: period.endTime!,
        primaryActivityScheduleInfo:
          primaryActivitySchedule != null && activity != null
            ? {
                activitySchedule: primaryActivitySchedule,
                activity,
                isCurrentTerm: primaryActivitySchedule.termId === this._termId
              }
            : undefined,
        hasConflictingActivitySchedules: activitySchedules.length > 1
      };
    });
  }
}
