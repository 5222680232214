import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

export class EditableProperty<T> {
  @observable private _value?: T;

  constructor(
    public readonly originalValue: T | undefined,
    private readonly _isEqual?: (value1?: T, value2?: T) => boolean
  ) {
    makeObservable(this);
    this._value = originalValue;
  }

  @computed
  get value(): T | undefined {
    return this._value;
  }

  set value(v: T | undefined) {
    this._value = v;
  }

  @computed
  get isChanged() {
    return this._isEqual != null
      ? !this._isEqual(this._value, this.originalValue)
      : !isEqual(this._value, this.originalValue);
  }

  @action
  reset() {
    this._value = this.originalValue;
  }
}
