import {
  CourseSectionInfo,
  EditableScheduleCyclePeriodActivityInfo,
  UserDashboardInfo,
  compareTerms,
  courseSectionInfoToActivity,
  plannerCourseSectionDetailsToInfo,
  schoolCourseSectionToInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { CreateOrUpdateCycleDayActivityInfos, PlannerDataStore, SchoolDataStore } from '@/stores';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { PartialActivitySchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/partial_activity_schedule_pb';
import { Period } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { ScheduleCycleKind, getScheduleCycleKind, titleForCycleDay } from '../ScheduleCycleUtils';
import {
  ScheduleCycleActivitySchedulesCoursesFilter,
  filterPlannerCourseSectionsForActivitySchedules,
  filterSchoolCourseSectionForActivitySchedules
} from './ScheduleCycleActivitySchedulesCoursesFilter';
import {
  AppScheduleCyclePeriodSchedulePeriodEditViewModel,
  ScheduleCyclePeriodSchedulePeriodEditViewModel
} from './ScheduleCyclePeriodSchedulePeriodEditViewModel';

export interface ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo {
  readonly isHighlighted: boolean;
  readonly term: Term;
  readonly activities: EditableScheduleCyclePeriodActivityInfo[];
  readonly isConflict: boolean;
}

export interface ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel
  extends ScheduleCyclePeriodSchedulePeriodEditViewModel {
  readonly cycleDayTitle: string;

  readonly allActivities: CourseSectionInfo[];
  readonly terms: ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo[];
  getCourseSectionForActivity(activity: Activity): CourseSectionInfo | undefined;
  createActivitySchedule(termId: string, courseSectionId: string): void;
}

export class AppScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel
  extends AppScheduleCyclePeriodSchedulePeriodEditViewModel
  implements ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel
{
  private _activitiesByTerm = observable.map<string, EditableScheduleCyclePeriodActivityInfo[]>();

  constructor(
    scheduleCycleId: string,
    periodScheduleId: string,
    period: Period,
    scheduleName: string,
    suggestions: Period[],
    dashboard: UserDashboardInfo,
    private readonly _scheduleTag: string,
    private readonly _plannerId: string | undefined,
    private readonly _cycleDay: number,
    private readonly _displayedTermId: string,
    private readonly _filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(dashboard, scheduleCycleId, periodScheduleId, period, scheduleName, suggestions);
    makeObservable(this);
    this.setInitialActivityScheduleInfos();
  }

  @computed
  private get scheduleCycleKind(): ScheduleCycleKind {
    return getScheduleCycleKind(this.scheduleCycle);
  }

  @computed
  private get allTerms(): Term[] {
    return chain([new Term({ id: '' }), ...this.scheduleCycle.terms])
      .sort((a, b) => {
        if (a.id === this._displayedTermId) {
          return -1;
        } else if (b.id === this._displayedTermId) {
          return 1;
        }

        return compareTerms(a, b, this._localization.currentLocale);
      })
      .value();
  }

  @computed
  private get allTermIds(): string[] {
    return this.allTerms.map((t) => t.id);
  }

  @computed
  get cycleDayTitle(): string {
    return titleForCycleDay(this._cycleDay, this.scheduleCycleKind, 'long', true, this.scheduleCycle.cycleDayNames);
  }

  @computed
  get allActivities(): CourseSectionInfo[] {
    if (this._plannerId != null || this._dashboard.kind === 'planner') {
      const plannerId = this._plannerId ?? this._dashboard.id;
      const values = this._plannerStore.getCourseSectionsInPlanner(plannerId).values;

      if (this._dashboard.kind === 'planner' || this._filters == null) {
        return values.map(plannerCourseSectionDetailsToInfo);
      }

      return values
        .filter((cs) => this._filters != null && filterPlannerCourseSectionsForActivitySchedules(cs, this._filters))
        .map(plannerCourseSectionDetailsToInfo);
    } else {
      let values = this._schoolStore.getCourseSections(this._dashboard.id).values;

      if (this._filters != null) {
        values = values.filter((cs) => filterSchoolCourseSectionForActivitySchedules(cs, this._filters!));
      }

      return values.map(schoolCourseSectionToInfo);
    }
  }

  @computed
  get terms(): ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo[] {
    const terms = this.allTerms;

    return terms.map((term) => {
      const activities = this._activitiesByTerm.get(term.id) ?? [];
      const termAndConflictingTerms =
        term.id === ''
          ? terms
          : [new Term({ id: '' }), ...this.scheduleCycleStore.getTermAndConflictingTermsForId(term.id)];

      const conflictingTermsCount = chain(termAndConflictingTerms)
        .map((t) => this._activitiesByTerm.get(t.id))
        .filter((a) => a != null && a.length > 0 && a.some((a) => a.activity != null))
        .toLength()
        .value();

      return {
        isHighlighted: term.id.length > 0 && term.id === this._displayedTermId,
        term,
        activities,
        isConflict: conflictingTermsCount > 0
      };
    });
  }

  getCourseSectionForActivity(activity: Activity): CourseSectionInfo | undefined {
    return this.scheduleCycleStore.getCourseSectionForActivity(activity, this.allActivities);
  }

  @action
  createActivitySchedule(termId: string, courseSectionId: string) {
    const courseSection = this.allActivities.find((cs) => cs.id === courseSectionId);

    if (courseSection == null) {
      return;
    }

    const activity = courseSectionInfoToActivity(courseSection);
    const editableActivity = new EditableScheduleCyclePeriodActivityInfo(undefined, activity, '');
    this._activitiesByTerm.set(termId, [...(this._activitiesByTerm.get(termId) ?? []), editableActivity]);
  }

  async save(): Promise<void> {
    await Promise.resolve();
    const activitySchedules: PartialActivitySchedule[] = [];

    this.allTermIds.map((termId) => {
      const activitiesInTerm = this._activitiesByTerm.get(termId) ?? [];

      const activities: PartialActivitySchedule[] = activitiesInTerm.map((a) => {
        const activity = !a.markedAsDeleted ? a.activity : undefined;

        return new PartialActivitySchedule({
          id: a.activityScheduleId,
          activity,
          termId,
          roomName: activity != null ? a.roomName : undefined,
          scheduleTag: activity != null ? this._scheduleTag : undefined
        });
      });

      activitySchedules.push(...activities);
    });

    const infos: CreateOrUpdateCycleDayActivityInfos = {
      cycleDay: this._cycleDay,
      label: this._periodLabel,
      startTime: this._startTime,
      endTime: this._endTime,
      originalPeriodId: this._period.id,
      activitySchedules
    };

    await this.scheduleCycleStore.createOrUpdateCycleDayActivities(infos);
  }

  private setInitialActivityScheduleInfos() {
    const allActivitySchedules = this.scheduleCycleStore.getActivitySchedulesForPeriod(
      { case: 'periodLabel', value: this._period.label },
      this._cycleDay,
      this.allTermIds,
      this._scheduleTag,
      this.allActivities.map(courseSectionInfoToActivity)
    );

    this.allTermIds.forEach((termId) => {
      const activitySchedules = allActivitySchedules.filter((activitySchedule) => activitySchedule.termId === termId);

      const infos = activitySchedules.map((activitySchedule) => {
        const courseSection = this.scheduleCycleStore.getCourseSectionForActivitySchedule(
          activitySchedule,
          this.allActivities
        );

        const activity: Activity | undefined =
          courseSection != null ? courseSectionInfoToActivity(courseSection) : undefined;

        return new EditableScheduleCyclePeriodActivityInfo(activitySchedule.id, activity, activitySchedule.roomName);
      });

      // If we are editing the master schedule for a single course (ex: in the admin console), we want to select it by
      // default if the period is empty in the currently displayed term..
      if (this._filters != null && termId === this._displayedTermId && infos.length === 0) {
        const filteredCourse =
          this._filters.case === 'course' ? this.allActivities.find((a) => a.id === this._filters!.value) : undefined;

        if (filteredCourse != null) {
          const activity = courseSectionInfoToActivity(filteredCourse);
          infos.push(new EditableScheduleCyclePeriodActivityInfo(undefined, activity, ''));
        }
      }

      this._activitiesByTerm.set(termId, infos);
    });
  }
}
