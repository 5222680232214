/* eslint-disable @typescript-eslint/no-empty-function */

import { UserTransportService } from '@/transports';
import { Preferences } from '@capacitor/preferences';
import { AdConversion, AnalyticsService, FirebaseContract, IntercomService, MixpanelService } from '../contracts';

export class AppAnalyticsService implements AnalyticsService {
  constructor(
    private readonly _intercom: IntercomService,
    private readonly _firebase: FirebaseContract,
    private readonly _mixpanel: MixpanelService,
    private readonly _userTransport: UserTransportService
  ) {}

  async logOnboardingCompleted(schoolIds: string[]) {
    await this._intercom.update({ has_completed_onboarding: true });
    await this._userTransport.completeOnboardingProcess(schoolIds);
    void this.logAdConversionOnce('onboarding-completed');
  }

  logHelpEvent() {}

  logSettingsEvent() {}

  logRefreshEvent() {}

  logStudentInvitationEvent(): void {}

  logScreenView_Onboarding_Welcome() {}

  logScreenView_Onboarding_Login() {
    this._mixpanel.trackEvent('Viewed onboarding step', { onboardingStepName: 'Login' });
  }

  logScreenView_Onboarding_PersonaSelection() {
    this._mixpanel.trackEvent('Viewed onboarding step', { onboardingStepName: 'Persona selection' });
  }

  logScreenView_Onboarding_UseCode() {
    this._mixpanel.trackEvent('Viewed onboarding step', { onboardingStepName: 'Use code' });
  }

  logScreenView_Onboarding_SharePlanner() {
    this._mixpanel.trackEvent('Viewed onboarding step', { onboardingStepName: 'Share planner' });
  }

  logScreenView_Onboarding_CreateSchool() {
    this._mixpanel.trackEvent('Viewed onboarding step', { onboardingStepName: 'Create school' });
  }

  logScreenView_Onboarding_Completed() {
    this._mixpanel.trackEvent('Viewed onboarding step', { onboardingStepName: 'Completed' });
  }

  logScreenView_Dashboard() {}

  logScreenView_SubscriptionPlans() {
    this._mixpanel.trackEvent('Viewed subscription plans');
  }

  async logAdConversionOnce(conversion: AdConversion) {
    const localStorageKey = 'today:analytics:has_tracked_event:' + conversion;

    const existingValue = await Preferences.get({ key: localStorageKey });
    if (existingValue.value != null) {
      return;
    }

    switch (conversion) {
      case 'signup':
        this.logGoogleTagManagerEvent('Signup');
        break;

      case 'persona-selected':
        this.logGoogleTagManagerEvent('PersonaSelected');
        break;

      case 'trial-started':
        this.logGoogleTagManagerEvent('TrialStarted');
        break;

      case 'onboarding-completed':
        this.logGoogleTagManagerEvent('OnboardingCompleted');
        break;

      case 'task-created':
        this.logGoogleTagManagerEvent('TaskCreated');
        break;

      case 'invite-created':
        this.logGoogleTagManagerEvent('InviteCreated');
        break;

      case 'paid-subscription':
        this.logGoogleTagManagerEvent('PaidSubscription');
        break;
    }

    await Preferences.set({ key: localStorageKey, value: 'true' });
  }

  logError(message: string, isFatal?: boolean) {
    this._firebase.logError(message, isFatal);
  }

  private logGoogleTagManagerEvent(eventName: string) {
    if (window.dataLayer != null) {
      window.dataLayer.push({ event: eventName });
    }
  }
}
