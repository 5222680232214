import { useActivePlannerId, useServices } from '@/hooks';
import { UserDashboardCalendarItemState, UserDashboardCalendarWeekPlannedWorkInfo } from '@/viewmodels';
import { Box, Card, CardActionArea, Stack, Tooltip, Typography, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { BackgroundLocationState } from '../../../../../BackgroundLocationState';
import { MultilineTooltipTitle } from '../../../../utils';
import { ContextMenuHandler } from '../../../ContextMenuHandler';
import { PlannedWorkIcon } from '../../../PlannedWorkIcon';
import { formatCalendarItemTimes, formatCalendarItemTimesLong } from '../../shared';

export interface UserDashboardCalendarWeekPlannedWorkProps {
  sx?: SxProps;
  className?: string;
  plannedWork: UserDashboardCalendarWeekPlannedWorkInfo;
  showBorder: boolean;
}

export const UserDashboardCalendarWeekPlannedWork = observer(
  ({ sx = [], className, plannedWork, showBorder }: UserDashboardCalendarWeekPlannedWorkProps) => {
    const { height } = plannedWork.position;
    const isSmall = height < 36;
    const [showTooltip, setShowTooltip] = useState(false);

    const { route } = useServices();
    const plannerId = useActivePlannerId();
    const location = useLocation();
    const state = (location.state ?? {}) as BackgroundLocationState;
    const navigate = useNavigate();

    const formattedTime = formatCalendarItemTimes(plannedWork.startTime, plannedWork.endTime, !isSmall);

    function showPlannedWorkDetails() {
      const newLocation = route.resolvePlannerWorkLocation(plannerId, plannedWork.workId);
      navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={plannedWork.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={[plannedWork.title, formatCalendarItemTimesLong(plannedWork.startTime, plannedWork.endTime)]}
              />
            }
            disableInteractive
            open={showTooltip && state.backgroundLocation == null}
            disableHoverListener
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <StyledPlannedWorkView
              sx={{ ...sx, height }}
              className={className}
              // Prevents background click event when user clicks on the left or right padding of the period.
              onClick={(e) => e.stopPropagation()}
              showBorder={showBorder}
              onContextMenu={contextMenuHandler}
            >
              <Card className="card" elevation={0}>
                <CardActionArea
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTooltip(false);
                    showPlannedWorkDetails();
                  }}
                  className="cardAction"
                >
                  <Box
                    className="mainContainer"
                    sx={{
                      alignItems: isSmall ? 'center' : undefined
                    }}
                  >
                    <Box className="colorIndicatorContainer">
                      <PlannedWorkIcon color={plannedWork.color} icon={plannedWork.icon} sx={{ height: '100%' }} />
                    </Box>

                    <Stack className="contentContainer" spacing={0.5}>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{
                          alignItems: isSmall ? 'center' : undefined
                        }}
                      >
                        <Stack
                          spacing={-0.5}
                          sx={{
                            flex: 1,
                            overflow: 'hidden'
                          }}
                        >
                          <Typography
                            variant="caption"
                            noWrap
                            color={getColorForTitle(plannedWork.state)}
                            sx={{
                              fontWeight: '600'
                            }}
                          >
                            {plannedWork.title}
                          </Typography>

                          {!isSmall && (
                            <Typography
                              variant="caption"
                              noWrap
                              sx={{
                                fontSize: 10
                              }}
                            >
                              {formattedTime}
                            </Typography>
                          )}
                        </Stack>

                        {isSmall && (
                          <Typography
                            variant="caption"
                            noWrap
                            sx={{
                              fontSize: 10
                            }}
                          >
                            {formattedTime}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            </StyledPlannedWorkView>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

interface StyledPlannedWorkViewProps {
  showBorder: boolean;
}

const StyledPlannedWorkView = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBorder'
})<StyledPlannedWorkViewProps>(({ theme, showBorder }) => {
  const colorIndicatorPadding = 4;

  return {
    '.badge': {
      height: '100%',
      width: '100%'
    },

    '.card': {
      height: '100%',
      width: '100%',
      backgroundImage: 'none',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: !showBorder ? 'transparent' : undefined,
      border: showBorder ? `1px solid ${theme.palette.divider}` : undefined
    },

    '.cardAction': {
      paddingRight: '6px',
      overflow: 'hidden',
      height: '100%'
    },

    '.mainContainer': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row'
    },

    '.colorIndicatorContainer': {
      marginLeft: theme.spacing(0.5),
      marginRight: 0,
      height: '100%',
      paddingTop: colorIndicatorPadding,
      paddingBottom: colorIndicatorPadding
    },

    '.contentContainer': {
      flex: 1,
      display: 'flex',
      overflow: 'hidden',
      paddingTop: '2px',
      paddingBottom: '2px'
    }
  };
});

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
