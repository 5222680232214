import { plannerHasAccessKindsForUser } from '@/models';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { useActivePlannerId } from './UseActivePlannerIdHook';
import { useServices } from './UseServicesHook';

export function usePlannerUserHasAccess(accessKinds: AccessKind[]): boolean {
  const plannerId = useActivePlannerId();
  const { userStore } = useServices();

  const planner = userStore.getPlannerForId(plannerId);

  if (planner == null) {
    return false;
  }

  return plannerHasAccessKindsForUser(userStore.user.userId, planner, ...accessKinds);
}
