import { PlannerTransportService } from '@/transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type PlannerParticipationCodeLoadable = LoadableValue<string>;

export class AppPlannerParticipationCodeLoadable
  extends BaseLoadableValue<string>
  implements PlannerParticipationCodeLoadable
{
  constructor(private readonly _plannerTransport: PlannerTransportService) {
    super();
  }

  protected async loadData(): Promise<string> {
    return await this._plannerTransport.getTeacherParticipationCode();
  }
}
