import { Root as ChartRoot, Color, Tooltip } from '@amcharts/amcharts5';
import {
  Axis,
  AxisRenderer,
  AxisRendererX,
  AxisRendererY,
  CategoryAxis,
  ColumnSeries,
  DateAxis,
  SmoothedXLineSeries,
  ValueAxis
} from '@amcharts/amcharts5/xy';
import { Theme } from '@mui/material';
import { Locale } from './Locale';

export type XYChartKind = 'line' | 'bar';
export type XYChartAxisKind = 'value' | 'category' | 'date';

export interface XYChartAxis<T> {
  /**
   * The key in `T` that corresponds to the axis' values.
   */
  dataKey: keyof T;
  kind: XYChartAxisKind;
  zoomOptions?: { minZoomCount?: number; maxZoomCount?: number };
  label?: string;
  getValue?(item: T): string | Date | number;
  minValue?: number;
  maxValue?: number;
}

export function makeAMXYChartAxis<T>(direction: 'x' | 'y', axis: XYChartAxis<T>, root: ChartRoot): Axis<AxisRenderer> {
  const renderer = direction === 'x' ? AxisRendererX : AxisRendererY;
  let chartAxis: Axis<AxisRenderer>;

  switch (axis.kind) {
    case 'category':
      chartAxis = CategoryAxis.new(root, {
        categoryField: axis.dataKey.toString(),
        renderer: renderer.new(root, {})
      });
      break;

    case 'date':
      chartAxis = DateAxis.new(root, {
        renderer: renderer.new(root, {}),
        baseInterval: { timeUnit: 'day', count: 1 }
      });
      break;

    case 'value':
      chartAxis = ValueAxis.new(root, {
        renderer: renderer.new(root, {}),
        min: axis.minValue,
        max: axis.maxValue,
        maxPrecision: 0
      });
      break;
  }

  chartAxis.setAll({
    minZoomCount: axis.zoomOptions?.minZoomCount,
    maxZoomCount: axis.zoomOptions?.maxZoomCount
  });

  return chartAxis;
}

export function makeXYChartSeries<T>(
  xAxis: XYChartAxis<T>,
  chartXAxis: Axis<AxisRenderer>,
  yAxis: XYChartAxis<T>,
  chartYAxis: Axis<AxisRenderer>,
  kind: XYChartKind,
  root: ChartRoot,
  theme: Theme,
  locale: Locale
) {
  const xAxisDataKey = xAxis.dataKey.toString();
  const yAxisDataKey = yAxis.dataKey.toString();

  const xTooltipText = () => {
    switch (xAxis.kind) {
      case 'value':
        return '{valueX}';
      case 'category':
        return '{categoryX}';
      case 'date': {
        const dateFormat = locale === 'en' ? 'MMMM d, YYYY' : 'd MMMM yyyy';
        return `{valueX.formatDate('${dateFormat}')}`;
      }
    }
  };

  const yTooltipText = yAxis.kind === 'category' ? 'categoryY' : '{valueY}';
  const tooltipText = `${xTooltipText()}: [bold]${yTooltipText} {bullet}`;

  switch (kind) {
    case 'bar': {
      const series = ColumnSeries.new(root, {
        xAxis: chartXAxis,
        yAxis: chartYAxis,
        categoryXField: xAxis.kind === 'category' ? xAxisDataKey : undefined,
        valueXField: xAxis.kind !== 'category' ? xAxisDataKey : undefined,
        categoryYField: yAxis.kind === 'category' ? yAxisDataKey : undefined,
        valueYField: yAxis.kind !== 'category' ? yAxisDataKey : undefined,
        maskBullets: false
      });

      series.columns.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        fillOpacity: 0.4,
        fill: Color.fromString(theme.palette.primary.main),
        strokeWidth: 1,
        stroke: Color.fromString(theme.palette.primary.main)
      });

      const tooltip = series.set('tooltip', Tooltip.new(root, {}));
      tooltip.label.set('text', tooltipText);

      series.set('fill', Color.fromString(theme.palette.primary.main));

      return series;
    }

    case 'line': {
      const series = SmoothedXLineSeries.new(root, {
        xAxis: chartXAxis,
        yAxis: chartYAxis,
        categoryXField: xAxis.kind === 'category' ? xAxisDataKey : undefined,
        valueXField: xAxis.kind !== 'category' ? xAxisDataKey : undefined,
        categoryYField: yAxis.kind === 'category' ? yAxisDataKey : undefined,
        valueYField: yAxis.kind !== 'category' ? yAxisDataKey : undefined,
        maskBullets: false
      });

      series.set('fill', Color.fromString(theme.palette.primary.main));
      series.strokes.template.setAll({
        stroke: Color.fromString(theme.palette.primary.main),
        strokeWidth: 2
      });
      series.fills.template.setAll({
        fillOpacity: 0.6
      });

      const tooltip = series.set('tooltip', Tooltip.new(root, {}));
      tooltip.label.set('text', tooltipText);
      return series;
    }
  }
}
