import { css } from '@emotion/css';
import { alpha, Theme } from '@mui/material';

export function getBlurredClassName(
  backgroundStyle: 'paper' | 'default',
  theme: Theme,
  forcedBackgroundColor?: string
): string {
  const backgroundColor =
    forcedBackgroundColor ??
    (backgroundStyle === 'paper' ? theme.palette.background.paper : theme.palette.background.default);

  return css({
    backdropFilter: 'blur(12px)',
    backgroundColor: alpha(backgroundColor, theme.palette.mode === 'light' ? 0.75 : 0.85)
  });
}
