import { ServiceContainer } from '@/providers';
import { PeriodScheduleOccurrenceWhen, ScheduleCycleDataStore, UserDataStore } from '@/stores';
import { PeriodScheduleOccurrence } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_occurrence_pb';
import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { chain, intersection, isEqual, times } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import {
  UserDashboardInfo,
  getAllSchedulesTagsFromScheduleCycle,
  scheduleCycleSupportsScheduleTags
} from '../../../../models';
import { LocalizationService } from '../../../../services';
import { getScheduleCycleKind, titleForCycleDay } from '../ScheduleCycleUtils';

export interface PeriodScheduleOccurrenceInfo {
  readonly occurrence: PeriodScheduleOccurrenceWhen;
  readonly title: string;
  readonly hasOtherOccurrencesWithSameTarget: boolean;
}

export interface ScheduleCyclePeriodScheduleDetailsEditViewModel {
  readonly isNew: boolean;
  readonly canSave: boolean;
  readonly hasChanges: boolean;
  name: string;
  sourceScheduleId: string;
  scheduleTags: string[];
  readonly possibleSourceSchedules: PeriodSchedule[];
  readonly possibleCycleDays: { value: number; title: string }[];
  readonly occurrences: PeriodScheduleOccurrenceInfo[];
  readonly existingScheduleTags: string[];
  readonly supportsScheduleTag: boolean;
  addOccurrence(when: PeriodScheduleOccurrenceWhen): void;
  removeOccurrence(when: PeriodScheduleOccurrenceWhen): void;
  delete(): Promise<void>;
  save(): Promise<void>;
}

export class AppScheduleCyclePeriodScheduleDetailsEditViewModel
  implements ScheduleCyclePeriodScheduleDetailsEditViewModel
{
  @observable private _name = '';
  @observable private _sourceScheduleId = '';
  @observable private _scheduleTags: string[] = [];
  @observable private readonly _occurrences: PeriodScheduleOccurrenceWhen[] = [];

  constructor(
    private readonly _dashboard: UserDashboardInfo,
    private readonly _periodScheduleId: string | undefined,
    private readonly _scheduleCycleId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);

    const periodSchedule = this.periodSchedule;
    if (periodSchedule) {
      this._name = periodSchedule.name;
      this._scheduleTags = periodSchedule.scheduleTags;
      this._occurrences = this.scheduleCycle.periodScheduleOccurrences
        .filter((o) => !o.shouldDelete && o.periodScheduleId == this._periodScheduleId && o.when != null)
        .map((o) => o.when as PeriodScheduleOccurrenceWhen);
    }
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  private get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  private get periodSchedule(): PeriodSchedule | undefined {
    return this.scheduleCycle.periodSchedules.find((s) => s.id == this._periodScheduleId);
  }

  @computed
  private get periodSchedulesById(): Map<string, PeriodSchedule> {
    return this.scheduleCycle.periodSchedules.reduce((value, schedule) => {
      value.set(schedule.id, schedule);
      return value;
    }, new Map<string, PeriodSchedule>());
  }

  @computed
  private get otherOccurrences(): PeriodScheduleOccurrence[] {
    const otherIds = new Set(
      this.scheduleCycle.periodSchedules.map((ps) => ps.id).filter((id) => id != this._periodScheduleId)
    );

    return this.scheduleCycle.periodScheduleOccurrences.filter((pso) => otherIds.has(pso.periodScheduleId));
  }

  @computed
  get isNew(): boolean {
    return this._periodScheduleId == null;
  }

  @computed
  get canSave(): boolean {
    return this._name.length > 0;
  }

  @computed
  get hasChanges(): boolean {
    const periodSchedule = this.periodSchedule;
    if (periodSchedule != null) {
      const originalOccurrences = this.scheduleCycle.periodScheduleOccurrences
        .filter((o) => o.periodScheduleId == this._periodScheduleId && o.when != null)
        .map((o) => o.when as PeriodScheduleOccurrenceWhen);

      return this._name !== periodSchedule.name || !isEqual(originalOccurrences, this._occurrences);
    } else {
      return this._name.length > 0 || this._occurrences.length > 0;
    }
  }

  @computed
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @computed
  get sourceScheduleId(): string {
    return this._sourceScheduleId;
  }

  set sourceScheduleId(value: string) {
    this._sourceScheduleId = value;
  }

  @computed
  get scheduleTags(): string[] {
    return this._scheduleTags;
  }

  set scheduleTags(value: string[]) {
    this._scheduleTags = value;
  }

  @computed
  get possibleSourceSchedules(): PeriodSchedule[] {
    return this.scheduleCycle.periodSchedules;
  }

  @computed
  get possibleCycleDays(): { value: number; title: string }[] {
    return times(this.scheduleCycle.cycleDayCount).map((i) => {
      const cycleDay = i + 1;
      return {
        value: cycleDay,
        title: titleForCycleDay(
          cycleDay,
          getScheduleCycleKind(this.scheduleCycle),
          'long',
          true,
          this.scheduleCycle.cycleDayNames
        )
      };
    });
  }

  @computed
  get supportsScheduleTag(): boolean {
    return scheduleCycleSupportsScheduleTags(this._dashboard);
  }

  @computed
  get existingScheduleTags(): string[] {
    return getAllSchedulesTagsFromScheduleCycle(this.scheduleCycle, this._localization.currentLocale).filter(
      (t) => t.length > 0
    );
  }

  @computed
  get occurrences(): PeriodScheduleOccurrenceInfo[] {
    const kindSortOrder = { cycleDay: 0, dayOfWeek: 1, other: 2 };

    return chain(this._occurrences)
      .sort((o1, o2) => {
        if (o1.case === o2.case) {
          return o1.value - o2.value;
        }

        return kindSortOrder[o1.case ?? 'other'] - kindSortOrder[o2.case ?? 'other'];
      })
      .map((o) => {
        const hasOtherOccurrencesWithSameTarget = chain(this.otherOccurrences)
          .filter((pso) => pso.when.case === o.case && pso.when.value === o.value)
          .map((pso) => this.periodSchedulesById.get(pso.periodScheduleId))
          .compact()
          .some((ps) => intersection(ps.scheduleTags, this._scheduleTags).length > 0)
          .value();

        return {
          occurrence: o,
          title: this.titleForOccurrence(o),
          hasOtherOccurrencesWithSameTarget
        };
      })
      .value();
  }

  @action
  addOccurrence(when: PeriodScheduleOccurrenceWhen) {
    const existingIndex = this._occurrences.findIndex((o) => isEqual(o, when));

    if (existingIndex < 0) {
      this._occurrences.push(when);
    }
  }

  removeOccurrence(when: PeriodScheduleOccurrenceWhen) {
    const existingIndex = this._occurrences.findIndex((o) => isEqual(o, when));

    if (existingIndex >= 0) {
      runInAction(() => this._occurrences.splice(existingIndex, 1));
    }
  }

  async delete() {
    if (this._periodScheduleId != null) {
      await this.scheduleCycleStore.deletePeriodSchedule(this._periodScheduleId);
    }
  }

  async save() {
    if (this._periodScheduleId != null) {
      await this.scheduleCycleStore.updatePeriodSchedule(
        this._periodScheduleId,
        this.name,
        this.scheduleTags,
        this._occurrences
      );
    } else {
      await this.scheduleCycleStore.createNewPeriodSchedule(
        this.name,
        this.sourceScheduleId,
        this.scheduleTags,
        this._occurrences
      );
    }
  }

  private titleForOccurrence(occurrence: PeriodScheduleOccurrenceWhen): string {
    switch (occurrence.case) {
      case 'dayOfWeek':
        return LocalizedStrings.dateTime.dayOfWeekTitle[occurrence.value]();
      case 'cycleDay':
        return titleForCycleDay(
          occurrence.value,
          getScheduleCycleKind(this.scheduleCycle),
          'long',
          true,
          this.scheduleCycle.cycleDayNames
        );
    }

    return '';
  }
}
