import i18n from 'translate';

export const ScheduleCycleBellTimesPeriodStrings = {
  activitySchedulePeriodLabel: (label: string) =>
    i18n.t('scheduleCycle.edit.bellTimes.period.activitySchedulePeriodLabel', { label }),
  activityScheduleRoomNamePlaceholder: () =>
    i18n.t('scheduleCycle.edit.bellTimes.period.activityScheduleRoomNamePlaceholder'),
  activitySchedulesConflict: () => i18n.t('scheduleCycle.edit.bellTimes.period.activitySchedulesConflict'),
  deleteButtonTooltip: () => i18n.t('scheduleCycle.edit.bellTimes.period.deleteButtonTooltip'),
  deleteConfirmationMessage: () => i18n.t('scheduleCycle.edit.bellTimes.period.deleteConfirmationMessage'),
  deleteConfirmationSubmitButtonTitle: () =>
    i18n.t('scheduleCycle.edit.bellTimes.period.deleteConfirmationSubmitButtonTitle'),
  deleteConfirmationTitle: () => i18n.t('scheduleCycle.edit.bellTimes.period.deleteConfirmationTitle'),
  editTitle: () => i18n.t('scheduleCycle.edit.bellTimes.period.editTitle'),
  endTimeLabel: () => i18n.t('scheduleCycle.edit.bellTimes.period.endTimeLabel'),
  hasConflictWarning: () => i18n.t('scheduleCycle.edit.bellTimes.period.hasConflictWarning'),
  hideTimeSuggestionsButtonTitle: () => i18n.t('scheduleCycle.edit.bellTimes.period.hideTimeSuggestionsButtonTitle'),
  invalidTimesWarning: () => i18n.t('scheduleCycle.edit.bellTimes.period.invalidTimesWarning'),
  labelInputLabel: () => i18n.t('scheduleCycle.edit.bellTimes.period.labelInputLabel'),
  newTitle: () => i18n.t('scheduleCycle.edit.bellTimes.period.newTitle'),
  noClassesAvailable: () => i18n.t('scheduleCycle.edit.bellTimes.period.noClassesAvailable'),
  saveErrorMessage: () => i18n.t('scheduleCycle.edit.bellTimes.period.saveErrorMessage'),
  showTimeSuggestionsButtonTitle: () => i18n.t('scheduleCycle.edit.bellTimes.period.showTimeSuggestionsButtonTitle'),
  startTimeLabel: () => i18n.t('scheduleCycle.edit.bellTimes.period.startTimeLabel'),
  termActivityScheduleConflictWarning: () =>
    i18n.t('scheduleCycle.edit.bellTimes.period.termActivityScheduleConflictWarning'),
  timeSuggestionAppliedConfirmation: () =>
    i18n.t('scheduleCycle.edit.bellTimes.period.timeSuggestionAppliedConfirmation'),
  timeSuggestionsTitle: () => i18n.t('scheduleCycle.edit.bellTimes.period.timeSuggestionsTitle')
};
