import { useServices, useViewModel } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '../utils';
import { Login } from './Login';

export type LoginKind = 'sign-up' | 'log-in';

export interface LoginDialogProps {
  isOpen: boolean;

  /**
   * Action to execute when sign in.
   */
  kind: LoginKind | undefined;

  /**
   * Location to navigate to on successful login. Optional. Will navigate to /home by default.
   */
  referrer?: string;
}

export const LoginDialog = observer(({ isOpen, referrer, kind }: LoginDialogProps) => {
  const { localization } = useServices();
  const navigate = useNavigate();

  function close() {
    navigate(-1);
  }

  const viewModel = useViewModel((viewModels) => viewModels.createLoginDialogViewModel(close));
  const strings = localization.localizedStrings.login;

  return (
    <Dialog
      viewModel={viewModel}
      title={
        kind === 'log-in'
          ? strings.dialogTitleSignIn
          : kind === 'sign-up'
            ? strings.dialogTitleSignUp
            : strings.dialogTitleUnknown
      }
      width="xs"
      isOpen={isOpen}
      renderData={() => <Login referrer={referrer} />}
    />
  );
});
