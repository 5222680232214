import { useActivePlannerId, useViewModel } from '@/hooks';
import { UserDashboardInfo } from '@/models';
import { PersonRounded, SchoolRounded } from '@mui/icons-material';
import { List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ChoicePicker, Dialog, RadioChoicePickerOptionLabel, Subheader } from '../../../utils';

export interface ScheduleCycleCreationUserDashboardPickerProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: (userDashboard: UserDashboardInfo | undefined) => void;
}

export const ScheduleCycleCreationUserDashboardPicker = observer(
  ({ sx = [], isOpen, onClose, className }: ScheduleCycleCreationUserDashboardPickerProps) => {
    const plannerId = useActivePlannerId();
    const strings = LocalizedStrings.scheduleCycle.userDashboardPicker;

    function cancel() {
      onClose(undefined);
      return Promise.resolve();
    }

    function onSubmit(dashboard: UserDashboardInfo) {
      onClose(dashboard);
      return Promise.resolve();
    }

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCycleCreationUserDashboardPickerViewModel(plannerId, onSubmit, () => cancel()),
      [plannerId]
    );

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.title()}
        width={'sm'}
        renderData={() => (
          <Stack
            spacing={1}
            sx={{
              width: '100%'
            }}
          >
            <Typography
              variant="body1"
              sx={{
                flexShrink: 0,
                textAlign: 'center',
                fontWeight: '500'
              }}
            >
              {strings.message()}
            </Typography>

            <List sx={{ flex: 1, overflow: 'auto' }}>
              <Stack spacing={2}>
                {viewModel.schools.length > 0 && (
                  <Stack spacing={1}>
                    <Subheader>{strings.schoolsSectionTitle()}</Subheader>
                    <ChoicePicker
                      variant="radio"
                      value={viewModel.selectedDashboardId ?? null}
                      onSelect={(o) => (viewModel.selectedDashboardId = o?.value)}
                      options={viewModel.schools.map((d) => ({
                        key: d.id,
                        value: d.id,
                        isEnabled: !d.hasExistingScheduleCycle && d.canCreateSchedule,
                        label: (
                          <Observer>
                            {() => (
                              <RadioChoicePickerOptionLabel
                                icon={(sx) => <SchoolRounded sx={sx} />}
                                title={d.name}
                                subtitle={
                                  d.hasExistingScheduleCycle
                                    ? strings.schoolExistingScheduleLabel()
                                    : !d.canCreateSchedule
                                      ? strings.schoolNotOwnerLabel()
                                      : undefined
                                }
                                isSelected={viewModel.selectedDashboardId === d.id}
                                disabled={d.hasExistingScheduleCycle || !d.canCreateSchedule}
                              />
                            )}
                          </Observer>
                        )
                      }))}
                    />
                  </Stack>
                )}

                <Stack spacing={1}>
                  <Subheader sx={{ mt: 1 }}>{strings.plannersSectionTitle()}</Subheader>
                  <ChoicePicker
                    variant="radio"
                    value={viewModel.selectedDashboardId ?? null}
                    onSelect={(o) => (viewModel.selectedDashboardId = o?.value)}
                    options={viewModel.planners.map((d) => ({
                      key: d.id,
                      value: d.id,
                      isEnabled: !d.hasExistingScheduleCycle,
                      label: (
                        <Observer>
                          {() => (
                            <RadioChoicePickerOptionLabel
                              icon={(sx) => <PersonRounded sx={sx} />}
                              title={d.name}
                              subtitle={d.hasExistingScheduleCycle ? strings.plannerExistingScheduleLabel() : undefined}
                              disabled={d.hasExistingScheduleCycle}
                              isSelected={viewModel.selectedDashboardId === d.id}
                            />
                          )}
                        </Observer>
                      )
                    }))}
                  />
                </Stack>
              </Stack>
            </List>
          </Stack>
        )}
      />
    );
  }
);
