import { ConnectedAppKind, ConnectedAppUserDashboard } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppService, LocalizationService } from '@/services';
import { ForbiddenError } from '@/utils';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppObservableDataViewModel, ObservableDataViewModel, UpdatableViewModelState } from '../shared';
import { AppSyncInfoViewModel, SyncInfoViewModel } from './SyncInfoViewModel';

export interface EditableConnectedAppViewModel {
  readonly userDashboard: ConnectedAppUserDashboard;
  readonly syncInfo: SyncInfoViewModel;
  readonly hasData: boolean;
  readonly state: UpdatableViewModelState;
  readonly kind: ConnectedAppKind;
  readonly hasChanges: boolean;
  readonly isApplying: boolean;
  readonly error?: string;

  save(): Promise<void>;
  disconnect(): Promise<void>;
  reloadData(): Promise<void>;
  clearError(): void;
}

export abstract class AppEditableConnectedAppViewModel<T, S extends string> implements EditableConnectedAppViewModel {
  @observable protected _isApplying = false;
  @observable protected _error: string | undefined;
  protected _startSync = false;

  protected constructor(
    protected readonly _connectedApp: ConnectedAppService<S>,
    protected readonly _onSuccess: (hasChanges: boolean) => Promise<void>,
    startSync: boolean,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);
    this._startSync = startSync;
  }

  @computed
  protected get data(): ObservableDataViewModel<T> {
    return new AppObservableDataViewModel(async () => {
      if (this._startSync) {
        await this._connectedApp.sync();
      }
      // We want to force a sync only the first time we load data
      this._startSync = false;

      const data = await this.loadData();

      if (!this.canEdit) {
        throw new ForbiddenError('Cannot edit');
      }

      return data;
    });
  }

  /**
   * Indicates if the user has a sufficient subscription to use this connected app.
   * Used to determine if the user can edit the connected app.
   * @protected
   */
  protected abstract get isEntitled(): boolean;

  @computed
  get userDashboard(): ConnectedAppUserDashboard {
    return this._connectedApp.userDashboard;
  }

  abstract get hasChanges(): boolean;

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get kind(): ConnectedAppKind {
    return this._connectedApp.kind;
  }

  @computed
  get hasData(): boolean {
    return this.data.hasData;
  }

  @computed
  get state(): UpdatableViewModelState {
    return this.data.state;
  }

  @computed
  get syncInfo(): SyncInfoViewModel {
    return new AppSyncInfoViewModel(this._connectedApp, () => this.onSync());
  }

  @computed
  protected get canEdit(): boolean {
    return !this._connectedApp.hasSyncError && this.isEntitled && this._connectedApp.isConnected;
  }

  abstract save(): Promise<void>;

  protected abstract loadData(): Promise<T>;

  protected abstract onSync(): Promise<void>;

  @action
  async disconnect() {
    try {
      this._isApplying = true;
      this._error = undefined;

      await this._connectedApp.disconnect();
      await this._onSuccess(true);
    } catch (e) {
      captureException(e);
      runInAction(() => (this._error = (e as Error).message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  reloadData(): Promise<void> {
    return this.data.reloadData();
  }

  @action
  clearError() {
    this._error = undefined;
  }
}
