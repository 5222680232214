export interface RouteNameStrings {
  readonly home: string;
  readonly school: string;
  readonly schoolCourseSectionDetails: string;
  readonly schoolStudentInsights: string;
}

export const EnglishRouteNameStrings: RouteNameStrings = {
  home: 'Home',
  school: 'Dashboard',
  schoolCourseSectionDetails: 'Class details',
  schoolStudentInsights: 'Student'
};

export const FrenchRouteNameStrings: RouteNameStrings = {
  home: 'Accueil',
  school: 'Tableau de bord',
  schoolCourseSectionDetails: 'Détails du cours',
  schoolStudentInsights: 'Élève'
};
