import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';

export interface UserStrings {
  readonly applyChangesErrorMessage: string;
  readonly applyChangesErrorTitle: string;
  readonly changeImageButton: string;
  readonly clearImageButton: string;
  readonly cookiesHeader: string;
  readonly cookiePreferencesButton: string;
  readonly deleteAccountHeader: string;
  readonly deleteAccountButton: string;
  readonly deleteAccountConfirmationDeleteButton: string;
  readonly deleteAccountConfirmationMessage: string;
  readonly deleteAccountConfirmationTitle: string;
  readonly deleteAccountMessage: string;
  readonly deleteAccountUnexpectedErrorMessage: string;
  readonly deleteAccountUnexpectedErrorTitle: string;
  readonly editPropertiesDialogTitle: string;
  readonly fullNameLabel: string;
  readonly informationSectionTitle: string;
  readonly noFullNameNotice: string;
  readonly personaPickerLabel: string;
  readonly pictureLabel: string;
  readonly resetImageButton: string;

  personaLabel: (persona: UserPersona) => string;
}

export const EnglishUserStrings: UserStrings = {
  applyChangesErrorMessage: 'An unexpected error occurred while applying your changes. The error message was:\n',
  applyChangesErrorTitle: 'oops',
  changeImageButton: 'Pick new',
  clearImageButton: 'Remove',
  cookiesHeader: 'Cookies',
  cookiePreferencesButton: 'Cookie Preferences',
  deleteAccountHeader: 'Data Deletion',
  deleteAccountButton: 'Delete Account',
  deleteAccountConfirmationDeleteButton: 'Yes! Delete my account!',
  deleteAccountConfirmationMessage:
    'Are you sure you want to delete your Studyo Go account, including all planners you own?\nTHIS ACTION IS PERMANENT AND CANNOT BE UNDONE.',
  deleteAccountConfirmationTitle: 'Please confirm',
  deleteAccountMessage: 'Deleting your account is permanent, and all the planners you own will be deleted.',
  deleteAccountUnexpectedErrorMessage:
    'An unexpected error occurred while trying to delete your account. If the problem persists, please contact us at support@studyo.co.',
  deleteAccountUnexpectedErrorTitle: 'Oops',
  editPropertiesDialogTitle: 'Update your profile',
  fullNameLabel: 'Your full name',
  informationSectionTitle: 'Your information',
  noFullNameNotice: 'We could not get a name from your profile. Please provide a name to identify yourself.',
  personaPickerLabel: 'Your primary role',
  pictureLabel: 'Your avatar picture',
  resetImageButton: 'Reset',

  personaLabel: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Student';
      case UserPersona.PARENT:
        return 'Parent';
      case UserPersona.TEACHER:
        return 'Teacher';
      case UserPersona.OTHER:
        return 'Other';
      default:
        return 'Unknown';
    }
  }
};

export const FrenchUserStrings: UserStrings = {
  applyChangesErrorMessage:
    'Une erreur inattendue est survenue en appliquant vos changements. Le message de l’erreur est:\n',
  applyChangesErrorTitle: 'Oups',
  changeImageButton: 'Choisir',
  clearImageButton: 'Retirer',
  cookiesHeader: 'Fichiers témoins',
  cookiePreferencesButton: 'Personnaliser les fichiers témoins',
  deleteAccountHeader: 'Suppression des données',
  deleteAccountButton: 'Effacer ce compte',
  deleteAccountConfirmationDeleteButton: 'Oui! Effacez mon compte!',
  deleteAccountConfirmationMessage:
    'Êtes-vous certain de vouloir effacer votre compte Studyo Go ainsi que tous les planificateurs que vous possédez?\nCETTE ACTION EST PERMANENTE ET NE PEUT PAS ÊTRE RENVERSÉE.',
  deleteAccountConfirmationTitle: 'Veuillez confirmer',
  deleteAccountMessage:
    'Supprimer votre compte est une action permanente et tous les planificateurs que vous possédez seront supprimés.',
  deleteAccountUnexpectedErrorMessage:
    'Une erreur inattendue est survenue lors de l’effacement de votre compte. Si le problème perdure, contactez-nous à support@studyo.co.',
  deleteAccountUnexpectedErrorTitle: 'Oups',
  editPropertiesDialogTitle: 'Mettez à jour votre profil',
  fullNameLabel: 'Votre nom complet',
  informationSectionTitle: 'Vos informations',
  noFullNameNotice:
    'Nous n’avons pas pu obtenir un nom à partir de votre profil. Veuillez entrer votre nom pour vous identifier.',
  personaPickerLabel: 'Votre rôle principal',
  pictureLabel: 'Votre image d’avatar',
  resetImageButton: 'Annuler',

  personaLabel: (persona) => {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'Élève';
      case UserPersona.PARENT:
        return 'Parent';
      case UserPersona.TEACHER:
        return 'Enseignant';
      case UserPersona.OTHER:
        return 'Autre';
      default:
        return 'Inconnu';
    }
  }
};
