import { ServiceContainer } from '@/providers';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { action, computed, makeObservable, observable, ObservableSet } from 'mobx';
import { WorkStepInfo } from './WorkStepInfo';

export interface WorkPlannedWorkStepsEditViewModel {
  readonly steps: WorkStepInfo[];
  readonly selectedStepIds: string[];
  toggleSelectionForStepId(id: string): void;
  save(): Promise<void>;
}

export class AppWorkPlannedWorkStepsEditViewModel implements WorkPlannedWorkStepsEditViewModel {
  private readonly _selectedStepIds: ObservableSet<string>;

  constructor(
    private readonly _workId: string,
    private readonly _syncToken: bigint,
    private readonly _plannedWork: PlannedWork,
    private readonly _steps: () => WorkStepInfo[],
    private readonly _workStore = ServiceContainer.services.workStore
  ) {
    makeObservable(this);
    this._selectedStepIds = observable.set(_plannedWork.workStepIds);
  }

  @computed
  get steps(): WorkStepInfo[] {
    return this._steps();
  }

  @computed
  get selectedStepIds(): string[] {
    return Array.from(this._selectedStepIds);
  }

  @action
  toggleSelectionForStepId(id: string) {
    if (this._selectedStepIds.has(id)) {
      this._selectedStepIds.delete(id);
    } else {
      this._selectedStepIds.add(id);
    }
  }

  async save(): Promise<void> {
    await this._workStore.updatePlannedWork(
      this._plannedWork.id,
      this._workId,
      this._plannedWork.timeSlot!,
      this.selectedStepIds,
      this._syncToken
    );
  }
}
