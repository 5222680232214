import { dayToDate } from '@/models';
import { ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo } from '@/viewmodels';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { WarningRounded } from '@mui/icons-material';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../../../utils';

export interface ScheduleCyclePeriodEditTermHeaderProps {
  sx?: SxProps;
  className?: string;
  termInfo: ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo;
}

export const ScheduleCyclePeriodEditTermHeader = observer(
  ({ sx = [], className, termInfo }: ScheduleCyclePeriodEditTermHeaderProps) => {
    const theme = useTheme();
    const strings = LocalizedStrings.scheduleCycle.edit;

    return (
      <Stack
        className={className}
        direction="row"
        sx={[
          {
            px: 1,
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Stack
          sx={{
            flex: 1
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: '500',
              color: termInfo.isConflict
                ? theme.palette.warning.main
                : termInfo.isHighlighted
                  ? theme.palette.primary.main
                  : theme.palette.text.primary
            }}
          >
            {termInfo.term.name || strings.masterSchedule.allYearTermName()}
          </Typography>

          {termInfo.term.startDay != null && termInfo.term.endDay != null && (
            <Typography
              variant="caption"
              sx={{
                color: termInfo.isConflict ? theme.palette.warning.main : theme.palette.text.secondary
              }}
            >
              {termFormattedDates(termInfo.term)}
            </Typography>
          )}
        </Stack>
        {termInfo.isConflict && (
          <Tooltip title={strings.bellTimes.period.termActivityScheduleConflictWarning()}>
            <WarningRounded fontSize="small" color="warning" />
          </Tooltip>
        )}
      </Stack>
    );
  }
);

function termFormattedDates(term: Term): string {
  if (term.startDay != null && term.endDay != null) {
    return `${format(dayToDate(term.startDay), 'PP')} to ${format(dayToDate(term.endDay), 'PP')}`;
  }
  return '';
}
