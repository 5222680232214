import { ClassroomIcon } from '@/resources/images/connectedApps';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { Box, Card, Chip, Link, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface AdminSchoolClassDetailsHeaderProps {
  sx?: SxProps;
  className?: string;
  courseSection: CourseSection;
}

export const AdminSchoolClassDetailsHeader = observer(
  ({ sx = [], className, courseSection }: AdminSchoolClassDetailsHeaderProps) => {
    const strings = LocalizedStrings.admin.classes.details;

    return (
      <Card sx={{ ...sx, py: 2, px: 3, border: 0, boxShadow: 0 }} className={className} square>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center'
          }}
        >
          <Stack
            spacing={2}
            sx={{
              flex: 1,
              alignItems: 'flex-start'
            }}
          >
            <Stack direction="row" spacing={1}>
              <Box sx={{ width: 6, borderRadius: '23px', backgroundColor: courseSection.color }} />
              <Stack>
                <Typography variant="h5">{courseSection.title}</Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {courseSection.section}
                </Typography>
              </Stack>
            </Stack>

            {courseSection.externalSource != null && (
              <Chip
                sx={{ mt: 2 }}
                label={strings.externalSource(localizedExternalSourceName(courseSection.externalSource.sourceName))}
                icon={renderExternalSourceBadge(courseSection.externalSource.sourceName)}
                // The Link will handle the action, but we still want the effect offered by using onClick.
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
                component={Link}
                href={courseSection.externalSource.url}
                target="_blank"
                rel="noreferrer"
              />
            )}
          </Stack>

          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gridAutoColumns: '1fr'
            }}
          >
            {renderInfo(strings.teachersCount(), courseSection.details?.teachers.length)}
            {renderInfo(strings.studentsCount(), courseSection.details?.students.length)}
          </Box>
        </Stack>
      </Card>
    );
  }
);

function renderExternalSourceBadge(sourceName: string) {
  switch (sourceName) {
    case 'classroom':
      return <ClassroomIcon style={{ width: 16, height: 16, marginLeft: 16 }} />;
    default:
      return undefined;
  }
}

function renderInfo(title: string, value: string | number | undefined) {
  return (
    <Stack
      spacing={0.5}
      sx={{
        alignItems: 'center',
        ml: 1
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary
        }}
      >
        {title}
      </Typography>
      <Typography variant="h5">{value ?? ''}</Typography>
    </Stack>
  );
}
