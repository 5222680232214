import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PublishedWorkDetailsMaxGradeProps {
  sx?: SxProps;
  className?: string;
  maxGrade: number | undefined;
}

export const PublishedWorkDetailsMaxGrade = observer(
  ({ sx = [], className, maxGrade }: PublishedWorkDetailsMaxGradeProps) => {
    const strings = LocalizedStrings.publishedWork.details;

    return (
      <Stack sx={sx} className={className} direction="column">
        <Typography
          variant="caption"
          sx={{
            fontWeight: '500',
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {strings.maxGradeLabel()}
        </Typography>
        <Typography
          sx={{
            fontWeight: '500'
          }}
        >
          {maxGrade ?? strings.maxGradeNone()}
        </Typography>
      </Stack>
    );
  }
);
