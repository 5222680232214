import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { action, makeObservable } from 'mobx';
import { WorkTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export interface PublishedWorkLoadable extends LoadableValue<PublishedWork> {
  // Some actions can result in obtaining an updated version of the PublishedWork model. Instead of doing a fetch, we can
  // simply update the value stored.
  updateWithValue(newValue: PublishedWork): void;
}

export class AppPublishedWorkLoadable extends BaseLoadableValue<PublishedWork> implements PublishedWorkLoadable {
  constructor(
    private readonly _publishedWorkId: string,
    private readonly _schoolId: string,
    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  @action
  updateWithValue(newValue: PublishedWork) {
    this._data = newValue;
    this._state = 'fulfilled';
  }

  protected async loadData(): Promise<PublishedWork> {
    return this._workTransport.getPublishedWork(this._publishedWorkId, this._schoolId);
  }
}
