import { ServiceContainer } from '@/providers';
import { LocalizationService, UserService } from '@/services';
import { UserDataStore } from '@/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { DialogActionButtonConfiguration, SaveDialogActionButtonConfiguration, StaticDialogViewModel } from '../utils';
import { AppUserPropertiesViewModel, UserPropertiesViewModel } from './UserPropertiesViewModel';

export interface UserPropertiesDialogViewModel extends StaticDialogViewModel {
  viewModel: UserPropertiesViewModel;
}

export class AppUserPropertiesDialogViewModel implements UserPropertiesDialogViewModel {
  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  readonly viewModel: UserPropertiesViewModel;
  @observable private _error: string | undefined;

  constructor(
    private _onSuccess: () => Promise<void>,
    private _onCancel: () => Promise<void>,
    private _localization: LocalizationService = ServiceContainer.services.localization,
    user: UserService = ServiceContainer.services.user,
    userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);

    this.viewModel = new AppUserPropertiesViewModel(true, _localization, user, userStore);
    this._saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.apply());
  }

  @computed
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    return [];
  }

  readonly kind = 'static' as const;

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    this._saveButtonConfig.isEnabled = this.viewModel.canApply && !this.viewModel.isApplying;
    this._saveButtonConfig.showLoading = this.viewModel.isApplying;

    return [this._saveButtonConfig];
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  async apply(): Promise<void> {
    await this.viewModel.apply();
    if (this.viewModel.error == null) {
      await this._onSuccess();
    }
  }

  dismiss() {
    return this._onCancel();
  }

  @action
  clearError() {
    this._error = undefined;
  }
}
