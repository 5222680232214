import { Course } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/resources/course_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';

export function curriculumCoursesForUser(courses: Course[], persona: UserPersona, hasTaughtClasses: boolean): Course[] {
  return courses.filter((cs) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (cs.personas.some((p) => p === UserPersona.TEACHER) && hasTaughtClasses) {
      return true;
    }

    return cs.personas.some((p) => p === persona);
  });
}
