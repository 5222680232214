import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface ConfirmationDialogMessageProps {
  /**
   * The message to display. Linefeeds are converted to multiple paragraphs.
   */
  message: string;
}

export const ConfirmationDialogMessage = observer(({ message }: ConfirmationDialogMessageProps) => {
  return <Typography sx={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: message }} />;
});
