import { DateService } from '@/services';
import { EnglishDateStrings, FrenchDateStrings } from './utils/DateStrings';

export interface ExSchoolCourseSectionStrings {
  readonly detailsDemoCourseSubtitle: string;
  readonly detailsMenuReload: string;
  readonly detailsMenuShowColumns: string;
  readonly detailsMenuShowList: string;
  readonly detailsNoSelectedStudent: string;
  readonly detailsNoStudentsSubtitle: string;
  readonly detailsNoStudentsTitle: string;
  readonly detailsOpenCourseInExternalSource: string;
  readonly detailsStudentPickerTitle: string;
  readonly detailsStudentSearchBarPlaceholder: string;
  readonly detailsStudentsGridItemNotConnected: string;
  readonly detailsStudentsGridItemNotConnectedNoAccess: string;
  readonly detailsStudentsGridLegendButtonTitle: string;
  readonly detailsStudentsGridLegendDailyActivityBehaviorHigh: string;
  readonly detailsStudentsGridLegendDailyActivityBehaviorLow: string;
  readonly detailsStudentsGridLegendDailyActivityBehaviorMedium: string;
  readonly detailsStudentsGridLegendDailyActivityBehaviorNone: string;
  readonly detailsStudentsGridLegendDailyActivityBehaviorSectionTitle: string;
  readonly detailsStudentsGridLegendInboxBehaviorHigh: string;
  readonly detailsStudentsGridLegendInboxBehaviorLow: string;
  readonly detailsStudentsGridLegendInboxBehaviorMedium: string;
  readonly detailsStudentsGridLegendInboxBehaviorNone: string;
  readonly detailsStudentsGridLegendInboxBehaviorSectionTitle: string;
  readonly detailsStudentsGridLegendTitle: string;
  readonly detailsStudentsGridNoResults: (searchText: string) => string;
  readonly detailsStudentsGridTitle: string;
  readonly detailsStudentsUsingToday: (studentsUsingToday: number, totalStudents: number) => string;

  readonly inviteError: string;
  readonly inviteFormCancel: string;
  readonly inviteFormDescriptionLabel: string;
  readonly inviteFormHeader: string;
  readonly inviteFormInvite: string;
  readonly inviteFormSubtitle: string;
  readonly inviteFormTitleLabel: string;

  readonly listEmptyConnectClassesButtonTitle: string;
  readonly listEmptyMessage: string;
  readonly listModifySelectionButtonTitle: string;
  readonly listTitle: string;

  readonly studentConnectionStateConnectedAndHaveAccessTooltip: string;
  readonly studentConnectionStateConnectedNoAccessTooltip: string;
  readonly studentConnectionStateNotConnectedTooltip: string;

  readonly studentDetailsConnectedNoAccessMessage: string;
  readonly studentDetailsInsightsTabTitle: string;
  readonly studentDetailsNotConnectedMessage: string;
  readonly studentDetailsPlannerDoneTitle: string;
  readonly studentDetailsPlannerEmptyTitle: string;
  readonly studentDetailsPlannerInboxTitle: string;
  readonly studentDetailsPlannerTabTitle: string;
  readonly studentDetailsPlannerTodayTitle: string;
  readonly studentDetailsPlannerUpcomingTitle: string;
  readonly studentDetailsRequestPlannerAccessButtonTitle: string;
  readonly studentDetailsUnknown: string;

  readonly studentInboxCountGreenTooltip: string;
  readonly studentInboxCountNoneTooltip: string;
  readonly studentInboxCountOrangeTooltip: string;
  readonly studentInboxCountRedTooltip: string;

  readonly studentLastConnectionWithPrefix: (date: Date | undefined, dateService: DateService) => string;
  readonly studentLastConnectionNever: string;
  readonly studentPlannerOpenStudentPlannerButtonTitle: string;

  readonly studentsInsightsChartActiveMinutesLabel: string;
  readonly studentsInsightsInformationTabTitle: string;
  readonly studentsInsightsNoActivityIndicatorSubtitle: string;
  readonly studentsInsightsNoActivityIndicatorTitle: string;
  readonly studentsInsightsOpenPlanner: string;
  readonly studentsInsightsTasksCountTitle: string;
  readonly studentsInsightsTitle: string;

  readonly studentsListConnectedSources: string;
  readonly studentsListDoneColumnTitle: string;
  readonly studentsListInboxColumnTitle: string;
  readonly studentsListLateColumnTitle: string;
  readonly studentsListTitle: string;
  readonly studentsListTodayColumnTitle: string;
  readonly studentsListUpcomingColumnTitle: string;
}

export const EnglishTeacherCourseSectionStrings: ExSchoolCourseSectionStrings = {
  detailsDemoCourseSubtitle: 'Explore what Studyo Go has to offer with this demo class.',
  detailsMenuReload: 'Reload',
  detailsMenuShowColumns: 'Show details',
  detailsMenuShowList: 'Show list',
  detailsNoSelectedStudent: 'Select a student to see their planner…',
  detailsNoStudentsSubtitle: 'A teacher without students is like \na flower without bees',
  detailsNoStudentsTitle: 'No students!',
  detailsOpenCourseInExternalSource: 'Open in',
  detailsStudentPickerTitle: 'Current student',
  detailsStudentSearchBarPlaceholder: 'Search for a student…',
  detailsStudentsGridItemNotConnected: 'This student is not yet using Studyo Go.',
  detailsStudentsGridItemNotConnectedNoAccess: 'This student has not granted you access to their Studyo Go planner.',
  detailsStudentsGridLegendButtonTitle: 'What do the colored dots mean?',
  detailsStudentsGridLegendDailyActivityBehaviorHigh: 'Last connection is more than 5 days ago.',
  detailsStudentsGridLegendDailyActivityBehaviorLow: 'Last connection is 2 or less days ago.',
  detailsStudentsGridLegendDailyActivityBehaviorMedium: 'Last connection is between 3 and 5 days ago.',
  detailsStudentsGridLegendDailyActivityBehaviorNone: 'No data available.',
  detailsStudentsGridLegendDailyActivityBehaviorSectionTitle: 'Second dot - Daily activity',
  detailsStudentsGridLegendInboxBehaviorHigh: 'More than 4 items in inbox.',
  detailsStudentsGridLegendInboxBehaviorLow: '2 items or less in inbox.',
  detailsStudentsGridLegendInboxBehaviorMedium: '3 or 4 items in inbox.',
  detailsStudentsGridLegendInboxBehaviorNone: 'No data available.',
  detailsStudentsGridLegendInboxBehaviorSectionTitle: 'First dot - Inbox',
  detailsStudentsGridLegendTitle: 'What do the colored dots mean?',
  detailsStudentsGridNoResults: (searchText) => `No results for "${searchText}"`,
  detailsStudentsGridTitle: 'Your students',
  detailsStudentsUsingToday: (studentsUsingToday, totalStudents) =>
    `${studentsUsingToday}/${totalStudents} student${
      studentsUsingToday === 1 || totalStudents === 1 ? ' is' : 's are'
    } using Studyo Go`,

  inviteError: 'An error occurred while posting the assignment.',
  inviteFormCancel: 'Cancel',
  inviteFormDescriptionLabel: 'Instructions',
  inviteFormHeader: 'Invite students',
  inviteFormInvite: 'Invite',
  inviteFormSubtitle: "This will post an assignment to this course's students who have not yet used Studyo Go.",
  inviteFormTitleLabel: 'Title',

  listEmptyConnectClassesButtonTitle: 'Connect my classes from Google Classroom',
  listEmptyMessage: 'You currently do not have any classes connected to Studyo Go.',
  listModifySelectionButtonTitle: 'Modify my classes from Google Classroom',
  listTitle: 'My Classes',

  studentConnectionStateConnectedAndHaveAccessTooltip:
    'Student has a Studyo Go account and you have access to their planner.',
  studentConnectionStateConnectedNoAccessTooltip:
    "Student has a Studyo Go account, but you don't have access to their planner.",
  studentConnectionStateNotConnectedTooltip: "Student doesn't have a Studyo Go account",

  studentDetailsConnectedNoAccessMessage:
    'This student is using Studyo Go but has not yet granted you access to their Studyo Go Planner.',
  studentDetailsInsightsTabTitle: 'Insights',
  studentDetailsNotConnectedMessage:
    'This student has not yet connected to Studyo Go.<br/><br/>Instruct them to go to <b>go.studyo.app</b> and sign in with their Google account.',
  studentDetailsPlannerDoneTitle: 'Done',
  studentDetailsPlannerEmptyTitle: 'No items to display',
  studentDetailsPlannerInboxTitle: 'Inbox',
  studentDetailsPlannerTabTitle: 'Planner',
  studentDetailsPlannerTodayTitle: 'Today',
  studentDetailsPlannerUpcomingTitle: 'Upcoming',
  studentDetailsRequestPlannerAccessButtonTitle: 'Request access',
  studentDetailsUnknown: 'Unknown student',

  // TODO sc15916: Add real thresholds
  studentInboxCountGreenTooltip: 'Two or less items in the inbox',
  studentInboxCountNoneTooltip: 'No data available for inbox',
  studentInboxCountOrangeTooltip: 'Between 3 and 4 items in the inbox',
  studentInboxCountRedTooltip: 'More than 4 items in the inbox',

  studentLastConnectionWithPrefix: (date, dateService) => {
    const prefix = 'Last seen:';
    return `${prefix} ${date == null ? 'never' : EnglishDateStrings.shortCalendarDateFormat(date, dateService)}`;
  },
  studentLastConnectionNever: 'Never',
  studentPlannerOpenStudentPlannerButtonTitle: 'Open planner',

  studentsInsightsChartActiveMinutesLabel: 'Total Daily Usage (minutes)',
  studentsInsightsInformationTabTitle: 'General information',
  studentsInsightsNoActivityIndicatorSubtitle: 'This student has not used Studyo Go recently.',
  studentsInsightsNoActivityIndicatorTitle: 'No activity',
  studentsInsightsOpenPlanner: 'Open their planner',
  studentsInsightsTasksCountTitle: 'Number of items',
  studentsInsightsTitle: 'Details of ',

  studentsListConnectedSources: 'Connected apps',
  studentsListDoneColumnTitle: 'Done',
  studentsListInboxColumnTitle: 'Inbox',
  studentsListLateColumnTitle: 'Late',
  studentsListTitle: 'Students - See who is using Studyo Go',
  studentsListTodayColumnTitle: 'Today',
  studentsListUpcomingColumnTitle: 'Later'
};

export const FrenchTeacherCourseSectionStrings: ExSchoolCourseSectionStrings = {
  detailsDemoCourseSubtitle: 'Explorez ce que Studyo Go a à offrir avec cet exemple de cours.',
  detailsMenuReload: 'Rafraîchir',
  detailsMenuShowColumns: 'Détails du cours',
  detailsMenuShowList: 'Liste des élèves',
  detailsNoSelectedStudent: 'Choisissez un élève pour voir son planificateur…',
  detailsNoStudentsSubtitle: 'Un enseignant sans ses élèves est comme \nune fleur sans abeilles',
  detailsNoStudentsTitle: 'Aucun élève!',
  detailsOpenCourseInExternalSource: 'Ouvrir dans',
  detailsStudentPickerTitle: 'Élève courant',
  detailsStudentSearchBarPlaceholder: 'Rechercher un élève…',
  detailsStudentsGridItemNotConnected: 'Cet élève n’utilise pas encore Studyo Go.',
  detailsStudentsGridItemNotConnectedNoAccess: 'Cet élève ne vous a pas permis l’accès à son planificateur Studyo Go.',
  detailsStudentsGridLegendButtonTitle: 'Que signifient les points de couleur?',
  detailsStudentsGridLegendDailyActivityBehaviorHigh: 'Dernière connexion il y a plus de 5 jours.',
  detailsStudentsGridLegendDailyActivityBehaviorLow: 'Dernière connexion il y a 2 jours ou moins.',
  detailsStudentsGridLegendDailyActivityBehaviorMedium: 'Dernière connexion il y a entre 3 et 5 jours.',
  detailsStudentsGridLegendDailyActivityBehaviorNone: 'Aucune donnée disponible.',
  detailsStudentsGridLegendDailyActivityBehaviorSectionTitle: 'Deuxième point - Utilisation quotidienne',
  detailsStudentsGridLegendInboxBehaviorHigh: 'Plus de 4 items à traiter.',
  detailsStudentsGridLegendInboxBehaviorLow: '2 items ou moins à traiter.',
  detailsStudentsGridLegendInboxBehaviorMedium: '3 ou 4 items à traiter.',
  detailsStudentsGridLegendInboxBehaviorNone: 'Aucune donnée disponible.',
  detailsStudentsGridLegendInboxBehaviorSectionTitle: 'Premier point - Tâches à traiter',
  detailsStudentsGridLegendTitle: 'Que signifient les points de couleur?',
  detailsStudentsGridNoResults: (searchText) => `Aucun résultat pour "${searchText}"`,
  detailsStudentsGridTitle: 'Vos élèves',
  detailsStudentsUsingToday: (studentsUsingToday, totalStudents) =>
    `${studentsUsingToday}/${totalStudents} élève${studentsUsingToday > 1 ? 's utilisent' : ' utilise'} Studyo Go`,

  inviteError: 'Une erreur est survenue lors de la publication du devoir.',
  inviteFormCancel: 'Annuler',
  inviteFormDescriptionLabel: 'Instructions',
  inviteFormHeader: 'Inviter les élèves',
  inviteFormInvite: 'Inviter',
  inviteFormSubtitle: 'Ceci publiera un devoir aux élèves de ce cours qui n’utilisent pas encore Studyo Go.',
  inviteFormTitleLabel: 'Titre',

  listEmptyConnectClassesButtonTitle: 'Connecter mes cours Google Classroom',
  listEmptyMessage: 'Vous n’avez actuellement aucun cours connecté à Studyo Go',
  listModifySelectionButtonTitle: 'Modifier ma sélection de cours Google Classroom',
  listTitle: 'Mes cours',

  studentConnectionStateConnectedAndHaveAccessTooltip:
    'Cet élève a un compte Studyo Go et vous avez accès à son planificateur',
  studentConnectionStateConnectedNoAccessTooltip:
    'Cet élève a un compte Studyo Go, mais vous n’avez pas accès à son planificateur Studyo Go.',
  studentConnectionStateNotConnectedTooltip: 'Cet élève n’a pas de compte Studyo Go',

  studentDetailsConnectedNoAccessMessage:
    'Cet élève utilise Studyo Go, mais ne vous a pas donné accès à son planificateur Studyo Go.',
  studentDetailsInsightsTabTitle: 'Détails',
  studentDetailsNotConnectedMessage:
    'Cet élève n’est pas connecté à Studyo Go.<br/><br/>Demandez-leur de visiter <b>go.studyo.app</b> et de se créer un compte en utilisant leur identifiant Google Classroom.',
  studentDetailsPlannerDoneTitle: 'Complétées',
  studentDetailsPlannerEmptyTitle: 'Aucun item à afficher',
  studentDetailsPlannerInboxTitle: 'À traiter',
  studentDetailsPlannerTabTitle: 'Planificateur',
  studentDetailsPlannerTodayTitle: 'Aujourd’hui',
  studentDetailsPlannerUpcomingTitle: 'Prochainement',
  studentDetailsRequestPlannerAccessButtonTitle: 'Demander l’accès',
  studentDetailsUnknown: 'Élève inconnu',

  // TODO sc15916: Add real thresholds
  studentInboxCountGreenTooltip: 'Deux items ou moins à traiter',
  studentInboxCountNoneTooltip: 'Aucune donnée disponible pour les items à traiter',
  studentInboxCountOrangeTooltip: 'Entre 3 et 4 items à traiter',
  studentInboxCountRedTooltip: 'Plus de 4 items à traiter',

  studentLastConnectionWithPrefix: (date, dateService) => {
    const prefix = 'Dernière visite:';
    return `${prefix} ${date == null ? 'jamais' : FrenchDateStrings.shortDateFormat(date, dateService)}`;
  },
  studentLastConnectionNever: 'Jamais',
  studentPlannerOpenStudentPlannerButtonTitle: 'Ouvrir le planificateur',

  studentsInsightsChartActiveMinutesLabel: 'Utilisation quotidienne de Studyo Go (minutes)',
  studentsInsightsInformationTabTitle: 'Informations générales',
  studentsInsightsNoActivityIndicatorSubtitle: 'Cet élève n’a pas utilisé Studyo Go récemment.',
  studentsInsightsNoActivityIndicatorTitle: 'Aucune activité',
  studentsInsightsOpenPlanner: 'Ouvrir le planificateur',
  studentsInsightsTasksCountTitle: 'Nombre d’items',
  studentsInsightsTitle: 'Détails pour ',

  studentsListConnectedSources: 'Applications connectées',
  studentsListDoneColumnTitle: 'Complétées',
  studentsListInboxColumnTitle: 'À traiter',
  studentsListLateColumnTitle: 'En retard',
  studentsListTitle: 'Élèves - Voir qui utilise Studyo Go',
  studentsListTodayColumnTitle: 'Aujourd’hui',
  studentsListUpcomingColumnTitle: 'Prochainement'
};
