import { useServices } from '@/hooks';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { LoadingIndicator } from '../LoadingIndicator';

export interface UpdatablePresenterLoadingProps {
  className?: string;
  sx?: SxProps;

  /**
   * The loading message. Optional. Used when `customLoadingComponent` is not specified.
   */
  loadingMessage?: string;

  /**
   * The indicator size. Optional. Default is `normal`.
   */
  size?: 'small' | 'normal';

  /**
   * The component to display when loading the data. Optional. `LoadingIndicator` will be used if not specified.
   */
  customLoadingComponent?: () => JSX.Element;
}

export const UpdatablePresenterLoading = observer(
  ({ customLoadingComponent, loadingMessage, className, size, sx }: UpdatablePresenterLoadingProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    return (
      customLoadingComponent?.() ?? (
        <LoadingIndicator
          sx={sx}
          message={loadingMessage ?? strings.loadingDataLabel}
          className={className}
          size={size}
        />
      )
    );
  }
);
