import { LocalizationService } from '@/services';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UpdatableViewModel, UpdatableViewModelState } from '../shared';
import { DialogActionButtonConfiguration } from './DialogActionButtonConfiguration';

export interface BaseDialogViewModel {
  readonly error?: string;
  readonly actions: DialogActionButtonConfiguration[];
  readonly supplementaryActions: DialogActionButtonConfiguration[];

  clearError: () => void;
  dismiss: () => Promise<void>;
}

export abstract class AppBaseDialogViewModel implements BaseDialogViewModel {
  @observable protected _error: string | undefined;

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    return [];
  }

  @computed
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    return [];
  }

  protected constructor(protected readonly _onDismiss: () => Promise<void>) {
    makeObservable(this);
  }

  clearError() {
    runInAction(() => (this._error = undefined));
  }

  dismiss() {
    return this._onDismiss();
  }

  logError(error: Error) {
    captureException(error);
  }
}

export abstract class AppBaseStaticDialogViewModel extends AppBaseDialogViewModel {
  readonly kind = 'static';
}

export abstract class AppBaseUpdatableDialogViewModel
  extends AppBaseDialogViewModel
  implements UpdatableDialogViewModel
{
  @observable private _showDismissConfirmAlert = false;
  readonly kind = 'updatable';

  protected constructor(
    protected readonly _localization: LocalizationService,
    onDismiss: () => Promise<void>,
    protected readonly _displayDismissConfirmation = false
  ) {
    super(onDismiss);
    makeObservable(this);
  }

  @computed
  get showDismissConfirmAlert(): boolean {
    return this._showDismissConfirmAlert;
  }

  abstract get state(): UpdatableViewModelState;

  abstract get hasChanges(): boolean;

  abstract get isSubmitting(): boolean;

  abstract get hasData(): boolean;

  abstract reloadData(): Promise<void>;

  async dismiss() {
    if (this.state !== 'fulfilled') {
      await super.dismiss();
      return;
    }

    if (this.isSubmitting) {
      return;
    }

    if (await this.confirmCanDismiss()) {
      await super.dismiss();
    }
  }

  async forceDismiss() {
    await super.dismiss();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorMessageSelector(error: Error): string | undefined {
    return undefined;
  }

  @action
  closeDismissConfirmAlert() {
    this._showDismissConfirmAlert = false;
  }

  private async confirmCanDismiss(): Promise<boolean> {
    if (this._displayDismissConfirmation && this.state === 'fulfilled' && this.hasChanges) {
      runInAction(() => (this._showDismissConfirmAlert = true));
      // Alert handler should call forceDismiss() if user confirms.
      return Promise.resolve(false);
    } else {
      return Promise.resolve(true);
    }
  }
}

/**
 * View model for a dialog where there is no data to be loaded before displaying the content.
 */
export interface StaticDialogViewModel extends BaseDialogViewModel {
  kind: 'static';
}

/**
 * View model for a dialog where the content being displayed is dependent on some data being loaded.
 */
export interface UpdatableDialogViewModel extends BaseDialogViewModel, UpdatableViewModel {
  readonly kind: 'updatable';
  readonly showDismissConfirmAlert: boolean;
  closeDismissConfirmAlert(): void;
  errorMessageSelector?(error: Error): string | undefined;
  forceDismiss(): Promise<void>;
}

export type DialogViewModel = StaticDialogViewModel | UpdatableDialogViewModel;
