import { ClassroomSyncBlocker, ConnectedAppKind, GoogleCalendarSyncBlocker } from '@/models';
import { DateService } from '@/services';
import { formatDistance } from 'date-fns';
import { enUS as enLocale, fr as frLocale } from 'date-fns/locale';

export interface ConnectedAppsStrings {
  readonly classroomCourseListTitle: (schoolName: string | undefined, schoolSubtitle: string | undefined) => string;
  readonly classroomSyncBlockerError: (syncBlocker: ClassroomSyncBlocker) => string;
  readonly closeDialog: string;
  readonly connectLabel: string;
  readonly connectedAppTitle: (kind: ConnectedAppKind) => string;
  readonly disconnect: string;
  readonly disconnectConfirmationMessage: string;
  readonly editDialogTitle: string;
  readonly editLabel: string;
  readonly forbiddenErrorMessage: string;
  readonly googleCalendarListTitle: string;
  readonly googleCalendarSyncBlockerError: (syncBlocker: GoogleCalendarSyncBlocker) => string;
  readonly lastSync: (date: Date, dateService: DateService) => string;
  readonly legacyConnectionTitle: (title: string) => string;
  readonly neverSynced: string;
  readonly noClassroomCoursesSubtitle: string;
  readonly noGoogleCalendarsSubtitle: string;
  readonly notConnected: string;
  readonly subscribeLabel: string;
  readonly syncError: string;
  readonly syncing: string;
  readonly title: string;
}

export const EnglishConnectedAppsStrings: ConnectedAppsStrings = {
  classroomCourseListTitle: (schoolName, schoolSubtitle) =>
    schoolName != null
      ? `The selected classes will be displayed in ${schoolName}${schoolSubtitle != null && schoolSubtitle.length > 0 ? ` (${schoolSubtitle})` : ''} and your planner.`
      : 'The selected classes will be displayed in your planner.',
  classroomSyncBlockerError: (syncBlocker) => {
    switch (syncBlocker) {
      case 'no-google-offline-access':
        return 'Please connect your Google account';
      case 'missing-google-access-scopes':
        return 'Please connect your Google account';
      case 'insufficient-entitlements':
        return 'Please select a subscription plan';
      case 'no_classroom_access':
        return 'This Google account does not have access to Classroom';

      case 'internal-error':
      case 'unexpected-error':
      case 'unspecified':
      default:
        return 'Unexpected error';
    }
  },
  closeDialog: 'Close',
  connectLabel: 'Connect',
  connectedAppTitle: (kind) => {
    switch (kind) {
      case 'classroom':
        return 'Google Classroom';

      case 'googleCalendar':
        return 'Google Calendar';

      default:
        return kind;
    }
  },
  disconnect: 'Disconnect',
  disconnectConfirmationMessage:
    'You have unsaved changes. Are you sure you wish to proceed? Your changes will be lost.',
  editDialogTitle: 'Manage connected app',
  editLabel: 'Manage',
  forbiddenErrorMessage: "You can't edit this connected app right now. Please close this dialog and retry later.",
  googleCalendarListTitle: 'Selected calendars will appear in your planner.',
  googleCalendarSyncBlockerError: (syncBlocker) => {
    switch (syncBlocker) {
      case 'no-google-offline-access':
        return 'Please connect your Google account';
      case 'missing-google-access-scopes':
        return 'Please connect your Google account';

      case 'internal-error':
      case 'unexpected-error':
      case 'unspecified':
      default:
        return 'Unexpected error';
    }
  },
  lastSync: (date, dateService) =>
    `Last sync: ${formatDistance(date, dateService.now, { locale: enLocale, addSuffix: true })}`,
  legacyConnectionTitle: (title) => `${title} (legacy)`,
  neverSynced: 'Never synced',
  noClassroomCoursesSubtitle: 'There are no classes associated with this account',
  noGoogleCalendarsSubtitle: 'There are no calendars associated with this account',
  notConnected: 'Not connected',
  subscribeLabel: 'Select plan',
  syncError: 'Fatal error',
  syncing: 'Syncing…',
  title: 'Manage your connected apps'
};

export const FrenchConnectedAppsStrings: ConnectedAppsStrings = {
  classroomCourseListTitle: (schoolName, schoolSubtitle) =>
    schoolName != null
      ? `Les cours sélectionnés seront visibles dans ${schoolName}${schoolSubtitle != null && schoolSubtitle.length > 0 ? ` (${schoolSubtitle})` : ''} et votre planificateur.`
      : 'Les cours sélectionnés seront visibles dans votre planificateur.',
  classroomSyncBlockerError: (syncBlocker) => {
    switch (syncBlocker) {
      case 'no-google-offline-access':
        return 'Veuillez connecter votre compte Google';
      case 'missing-google-access-scopes':
        return 'Veuillez connecter votre compte Google';
      case 'insufficient-entitlements':
        return 'Veuillez choisir un abonnement';
      case 'no_classroom_access':
        return 'Ce compte n’a pas accès à Classroom';

      case 'internal-error':
      case 'unexpected-error':
      case 'unspecified':
      default:
        return 'Erreur inattendue';
    }
  },
  closeDialog: 'Fermer',
  connectLabel: 'Connecter',
  connectedAppTitle: (kind) => {
    switch (kind) {
      case 'classroom':
        return 'Google Classroom';

      case 'googleCalendar':
        return 'Google Agenda';

      default:
        return kind;
    }
  },
  disconnect: 'Déconnexion',
  disconnectConfirmationMessage:
    'Vous avez des changements non-sauvegardés. Êtes-vous sûr de vouloir poursuivre? Vos changements seront perdus. ',
  editDialogTitle: 'Gérer l’application connectée',
  editLabel: 'Gérer',
  forbiddenErrorMessage:
    'Vous ne pouvez pas modifier cette application connectée pour le moment. Veuillez fermer la boîte de dialogue et réessayer plus tard.',
  googleCalendarListTitle: 'Les calendriers seront visibles dans votre planificateur.',
  googleCalendarSyncBlockerError: (syncBlocker) => {
    switch (syncBlocker) {
      case 'no-google-offline-access':
        return 'Veuillez connecter votre compte Google';
      case 'missing-google-access-scopes':
        return 'Veuillez connecter votre compte Google';

      case 'internal-error':
      case 'unspecified':
      default:
        return 'Erreur inattendue';
    }
  },
  lastSync: (date, dateService) =>
    `Dernière synchronisation : ${formatDistance(date, dateService.now, { locale: frLocale, addSuffix: true })}`,
  legacyConnectionTitle: (title) => `${title} (ancien)`,
  neverSynced: 'Jamais synchronisé',
  noClassroomCoursesSubtitle: 'Aucun cours n’est associé à ce compte',
  noGoogleCalendarsSubtitle: 'Aucun calendrier n’est associé à ce compte',
  notConnected: 'Non connecté',
  subscribeLabel: 'Choisir un plan',
  syncError: 'Erreur fatale',
  syncing: 'Synchronisation en cours…',
  title: 'Gérer vos applications connectées'
};
