// As @bufbuild/protobuf is a peer dependency, the intellisense doesn't seem to work. This helps as Timestamp can now
// be imported from models.
import { Timestamp } from '@bufbuild/protobuf';
import { dateToTimeOfDay, TimeOfDay } from './TimeOfDay';

export { Timestamp };

export function timestampToTimeOfDay(timestamp: Timestamp): TimeOfDay {
  const date = timestamp.toDate();
  return dateToTimeOfDay(date);
}
