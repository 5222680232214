import { SchoolTransportService } from '@/transports';
import { AccountKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_kind_pb';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolParticipationCodeLoadable = LoadableValue<string>;

export class AppSchoolParticipationCodeLoadable
  extends BaseLoadableValue<string>
  implements SchoolParticipationCodeLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _accountKind: AccountKind,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<string> {
    return await this._schoolTransport.getParticipationCode(this._schoolId, this._accountKind);
  }
}
