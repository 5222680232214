import { MouseEvent } from 'react';
import { Location } from 'react-router';
import { NavigateFunction } from 'react-router-dom';
import { BackgroundLocationState } from '../../BackgroundLocationState';

export interface LocationAction {
  // If undefined, it will use current location.
  readonly url?: string;
  readonly externalUrl?: string;
  readonly setBackgroundLocation?: boolean;
  readonly state?: unknown;
  readonly target?: string;
}

export function handleItemAction(
  event: MouseEvent,
  action: LocationAction | (() => Promise<void>),
  navigate: NavigateFunction,
  location: Location
) {
  event.stopPropagation();

  if (action instanceof Function) {
    void action();
  } else if (action.externalUrl != null) {
    const target = action.target ?? '_blank';
    window.open(action.externalUrl, target, target === '_blank' ? 'noreferrer' : undefined);
  } else {
    const newState = {
      ...(location.state || {}),
      ...(action.state || {}),
      backgroundLocation: action.setBackgroundLocation ? location : undefined
    } as BackgroundLocationState;
    navigate(action.url ?? location, { state: newState });
  }
}
