import { Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridTopLeftViewProps {
  sx?: SxProps;
  className?: string;
}

export function ScheduleCyclePeriodScheduleGridTopLeftView({
  sx = [],
  className
}: ScheduleCyclePeriodScheduleGridTopLeftViewProps) {
  const theme = useTheme();

  return (
    <Stack
      className={className}
      sx={{
        width: ScheduleCyclePeriodScheduleGridConstants.timesColumnWidth,
        zIndex: ScheduleCyclePeriodScheduleGridConstants.topLeftViewZIndex,
        position: 'sticky',
        left: 0,
        ...sx,
        backgroundColor: theme.palette.background.paper
      }}
    />
  );
}
