import { computed, makeObservable, observable } from 'mobx';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface BaseOnboardingScreenViewModel {
  readonly isSubmitting: boolean;
  readonly canSubmit: boolean;
  submit(): Promise<void>;
  signOut(): Promise<void>;
}

export abstract class AppBaseOnboardingScreenViewModel implements BaseOnboardingScreenViewModel {
  @observable protected _isSubmitting = false;

  protected constructor(protected readonly _onboardingState: OnboardingViewModel) {
    makeObservable(this);
  }

  abstract get canSubmit(): boolean;

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  abstract submit(): Promise<void>;

  signOut(): Promise<void> {
    return this._onboardingState.signOut();
  }
}
