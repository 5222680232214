import i18n from 'translate';

export const ScheduleCycleBellTimesEditStrings = {
  addSubmitButton: () => i18n.t('scheduleCycle.edit.bellTimes.edit.addSubmitButton'),
  addTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.addTitle'),
  assignToPickerDayOfWeek: () => i18n.t('scheduleCycle.edit.bellTimes.edit.assignToPickerDayOfWeek'),
  assignToPickerDayOfWeekPickerTitle: () =>
    i18n.t('scheduleCycle.edit.bellTimes.edit.assignToPickerDayOfWeekPickerTitle'),
  assignToPickerRotatingDay: () => i18n.t('scheduleCycle.edit.bellTimes.edit.assignToPickerRotatingDay'),
  assignToPickerRotatingDayPickerTitle: () =>
    i18n.t('scheduleCycle.edit.bellTimes.edit.assignToPickerRotatingDayPickerTitle'),
  assignToPickerTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.assignToPickerTitle'),
  assignedToAddButtonTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.assignedToAddButtonTitle'),
  assignedToSectionTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.assignedToSectionTitle'),
  copyFromLabel: () => i18n.t('scheduleCycle.edit.bellTimes.edit.copyFromLabel'),
  copyFromNoneOption: () => i18n.t('scheduleCycle.edit.bellTimes.edit.copyFromNoneOption'),
  deleteConfirmationMessage: () => i18n.t('scheduleCycle.edit.bellTimes.edit.deleteConfirmationMessage'),
  deleteConfirmationSubmitLabel: () => i18n.t('scheduleCycle.edit.bellTimes.edit.deleteConfirmationSubmitLabel'),
  deleteConfirmationTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.deleteConfirmationTitle'),
  deleteOptionTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.deleteOptionTitle'),
  editSubmitButton: () => i18n.t('scheduleCycle.edit.bellTimes.edit.editSubmitButton'),
  editTitle: () => i18n.t('scheduleCycle.edit.bellTimes.edit.editTitle'),
  hasOtherOccurrencesWithSameTarget: () =>
    i18n.t('scheduleCycle.edit.bellTimes.edit.hasOtherOccurrencesWithSameTarget'),
  nameLabel: () => i18n.t('scheduleCycle.edit.bellTimes.edit.nameLabel'),
  scheduleTagsInputLabel: () => i18n.t('scheduleCycle.edit.bellTimes.edit.scheduleTagsInputLabel'),
  scheduleTagsNew: (value: string) => i18n.t('scheduleCycle.edit.bellTimes.edit.scheduleTagsNew', { value })
};
