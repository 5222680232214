import { plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { UserService } from '@/services';
import {
  LoadableState,
  mergeLoadableStates,
  ParticipatingSchoolsLoadable,
  PlannerDataStore,
  PlannerDemoDetailedCourseSectionsLoadable,
  PlannerDetailedCourseSectionsLoadable,
  SchoolDataStore,
  TeacherPreferencesLoadable,
  UserDataStore
} from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { ParticipationRequestState } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/participation_request_state_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { computed, makeObservable } from 'mobx';

export interface MainScreenPlannerViewModel {
  readonly shouldShowPendingParticipationRequests: boolean;
  readonly state: LoadableState;

  setHasShownPendingParticipationRequests(): void;
}

export class AppMainScreenPlannerViewModel implements MainScreenPlannerViewModel {
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  private get demoCourseSectionsLoadable(): PlannerDemoDetailedCourseSectionsLoadable {
    return this._plannerStore.getDemoCourseSectionsInPlanner(this._plannerId);
  }

  private get participatingSchoolsLoadable(): ParticipatingSchoolsLoadable {
    return this._userStore.participatingSchools;
  }

  private get teacherPreferencesLoadable(): TeacherPreferencesLoadable {
    return this._schoolStore.getTeacherPreferences();
  }

  constructor(
    private readonly _plannerId: string,
    private readonly _user: UserService = ServiceContainer.services.user,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    makeObservable(this);

    void this.courseSectionsLoadable.fetch(true);
    void this.demoCourseSectionsLoadable.fetch(true);
    void this.participatingSchoolsLoadable.fetch(true);
    void this.teacherPreferencesLoadable.fetch(true);
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerLoadable(this._plannerId).data;
  }

  @computed
  get state(): LoadableState {
    if (
      this.courseSectionsLoadable.hasData &&
      this.participatingSchoolsLoadable.hasData &&
      this.demoCourseSectionsLoadable.hasData &&
      this.teacherPreferencesLoadable.hasData
    ) {
      return 'fulfilled';
    }

    return mergeLoadableStates([
      this.courseSectionsLoadable.state,
      this.participatingSchoolsLoadable.state,
      this.demoCourseSectionsLoadable.state,
      this.teacherPreferencesLoadable.state
    ]);
  }

  @computed
  get shouldShowPendingParticipationRequests(): boolean {
    const hasPendingRequests = this.planner.participationRequests.some(
      (req) =>
        req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNREAD ||
        req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNSPECIFIED
    );

    if (
      !hasPendingRequests ||
      this._user.currentUser == null ||
      !plannerHasAccessKindsForUser(this._user.currentUser.userId, this.planner, AccessKind.FULL_ACCESS)
    ) {
      return false;
    }

    return !this._plannerStore.displayedPlannerIdHistory.has(this._plannerId);
  }

  setHasShownPendingParticipationRequests() {
    this._plannerStore.addPlannerIdToHistory(this._plannerId);
  }
}
