export interface MainScreenStrings {
  readonly anonymousEmail: string;
  readonly anonymousLogoutConfirmButtonLabel: string;
  readonly anonymousLogoutMessage: string;
  readonly anonymousName: string;
  readonly contactUs: string;
  readonly errorMessage: string;
  readonly helpNeededDefaultMessage: string;
  readonly missingScopesErrorMessage: string;
  readonly noCourseSectionsContactUs: string;
  readonly noCourseSectionsLabelPart1: string;
  readonly noCourseSectionsLabelPart2: string;
  readonly refreshButtonLabel: string;
  readonly signoutButtonLabel: string;

  readonly noCourseSectionsHelpDefaultMessage: (email: string) => string;
}

export const EnglishMainScreenStrings: MainScreenStrings = {
  anonymousEmail: 'No email address',
  anonymousLogoutConfirmButtonLabel: 'Logout',
  anonymousLogoutMessage:
    'Logging out will result in losing your data. If you wish to keep it, you need to be logged in. Do you want to logout anyways?',
  anonymousName: 'New user',
  contactUs: 'contact us',
  errorMessage: 'An error occurred.\nPlease try again or ',
  helpNeededDefaultMessage: 'I am unable to access Studyo Go',
  missingScopesErrorMessage: 'Studyo Go was not fully authorized.\nPlease reconnect and allow each scope.',
  noCourseSectionsContactUs: 'contact us',
  noCourseSectionsLabelPart1: 'Studyo Go did not find any classes connected to your account.\n\nIf it should have, ',
  noCourseSectionsLabelPart2: " and we'll get you up and running in no time!",
  refreshButtonLabel: 'Refresh',
  signoutButtonLabel: 'Sign Out',

  noCourseSectionsHelpDefaultMessage: (email) =>
    `After signing in using my ${email} Google account, Studyo Go alerted me that it could not find any classes associated with my account.\n\nHowever, I confirm that I do have classes.\n\nCan you please help me?`
};

export const FrenchMainScreenStrings: MainScreenStrings = {
  anonymousEmail: 'Aucune adresse courriel',
  anonymousLogoutConfirmButtonLabel: 'Se déconnecter',
  anonymousLogoutMessage:
    'La déconnexion va résulter à une perte de vos données. Pour les conserver, veuillez vous connecter. Souhaitez-vous continuer tout de même?',
  anonymousName: 'Nouvel utilisateur',
  contactUs: 'nous contacter',
  errorMessage: 'Une erreur est survenue.\nVeuillez réessayer ou ',
  helpNeededDefaultMessage: 'Je ne suis pas en mesure d’accéder à Studyo Go',
  missingScopesErrorMessage:
    'Studyo Go n’est pas pleinement autorisé.\nVous devez vous reconnecter et accepter\nchaque privilège.',
  noCourseSectionsContactUs: 'contactez-nous',
  noCourseSectionsLabelPart1:
    'Studyo Go n’a pas trouvé de cours associé à votre compte.\n\nS’il aurait dû en trouver, ',
  noCourseSectionsLabelPart2: ' et nous vous dépannerons dans les plus brefs délais!',
  refreshButtonLabel: 'Rafraîchir',
  signoutButtonLabel: 'Se déconnecter',

  noCourseSectionsHelpDefaultMessage: (email) =>
    `Après m'être connecté avec mon compte Google ${email}, Studyo Go m'a indiqué qu'il n'a pas trouvé de cours associé à mon compte.\n\nCependant, je confirme que j'en ai.\n\nPouvez-vous m'aider?`
};
