import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface ColorIndicatorProps {
  /**
   * The color. Optional. Default is `theme.palette.action.disabled`.
   */
  color?: string;

  className?: string;

  sx?: SxProps;
  width?: number;
  isRounded?: boolean;
}

export const ColorIndicator = ({ color, className, sx = [], width = 4, isRounded = true }: ColorIndicatorProps) => {
  const theme = useTheme();
  const resolvedColor = color != null && color.length > 0 ? color : theme.palette.action.disabled;

  return (
    <Box
      className={className}
      sx={{
        width: width,
        borderRadius: isRounded ? `${width / 2}px` : undefined,
        backgroundColor: resolvedColor,
        ...sx
      }}
    />
  );
};
