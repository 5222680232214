import { WorkStepInfo } from '@/viewmodels';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleRounded } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { WorkDetailsStep } from './WorkDetailsStep';

export interface SortableWorkDetailsStepProps {
  step: WorkStepInfo;
  isReadOnly: boolean;
  onEdit: (step: WorkStepInfo) => void;
}

export function SortableWorkDetailsStep({ step, onEdit, isReadOnly }: SortableWorkDetailsStepProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: step.id });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('sm'));

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'grab',
    color: theme.palette.text.disabled,
    touchAction: 'none'
  };

  return (
    <WorkDetailsStep
      ref={setNodeRef}
      style={style}
      step={step}
      onEdit={onEdit}
      isReadOnly={isReadOnly}
      {...(!isSmallScreen ? attributes : {})}
      {...(!isSmallScreen ? listeners : {})}
      dragHandle={
        isSmallScreen ? () => <DragHandleRounded {...attributes} {...listeners} style={dragHandleStyle} /> : undefined
      }
    />
  );
}
