import i18n from 'translate';
import { ScheduleCycleActivitySchedulesStrings } from './ScheduleCycleActivitySchedulesStrings';
import { ScheduleCycleBellTimesStrings } from './ScheduleCycleBellTimesStrings';
import { ScheduleCycleEditMasterScheduleStrings } from './ScheduleCycleEditMasterScheduleStrings';
import { ScheduleCycleSpecialDaysStrings } from './ScheduleCycleSpecialDaysStrings';

const ScheduleCycleEditActionsDiscardConfirmationStrings = {
  cancelButtonLabel: () => i18n.t('scheduleCycle.edit.actions.discardChangesConfirmation.cancelButtonLabel'),
  message: () => i18n.t('scheduleCycle.edit.actions.discardChangesConfirmation.message'),
  submitButtonLabel: () => i18n.t('scheduleCycle.edit.actions.discardChangesConfirmation.submitButtonLabel'),
  title: () => i18n.t('scheduleCycle.edit.actions.discardChangesConfirmation.title')
};

const ScheduleCycleEditActionsStrings = {
  discardChanges: () => i18n.t('scheduleCycle.edit.actions.discardChanges'),
  discardChangesConfirmation: ScheduleCycleEditActionsDiscardConfirmationStrings,
  discardErrorMessage: () => i18n.t('scheduleCycle.edit.actions.discardErrorMessage'),
  publishChanges: () => i18n.t('scheduleCycle.edit.actions.publishChanges'),
  publishDroppedChangesMessage: () => i18n.t('scheduleCycle.edit.actions.publishDroppedChangesMessage'),
  publishErrorMessage: () => i18n.t('scheduleCycle.edit.actions.publishErrorMessage'),
  undo: () => i18n.t('scheduleCycle.edit.actions.undo'),
  undoErrorMessage: () => i18n.t('scheduleCycle.edit.actions.undoErrorMessage')
};

const ScheduleCycleEditSaveDraftError = {
  message: () => i18n.t('scheduleCycle.edit.saveDraftError.message'),
  retryButtonTitle: () => i18n.t('scheduleCycle.edit.saveDraftError.retryButtonTitle'),
  title: () => i18n.t('scheduleCycle.edit.saveDraftError.title')
};

const ScheduleCycleEditTabStrings = {
  activitySchedules: () => i18n.t('scheduleCycle.edit.tab.activitySchedules'),
  bellTimes: () => i18n.t('scheduleCycle.edit.tab.bellTimes'),
  details: () => i18n.t('scheduleCycle.edit.tab.details'),
  schedules: () => i18n.t('scheduleCycle.edit.tab.schedules'),
  specialDays: () => i18n.t('scheduleCycle.edit.tab.specialDays')
};

export const ScheduleCycleEditStrings = {
  actions: ScheduleCycleEditActionsStrings,
  activitySchedules: ScheduleCycleActivitySchedulesStrings,
  bellTimes: ScheduleCycleBellTimesStrings,

  completedScheduleMessage: () => i18n.t('scheduleCycle.edit.completedScheduleMessage'),
  createNextYearCopyButtonTitle: () => i18n.t('scheduleCycle.edit.createNextYearCopyButtonTitle'),
  createScheduleCopyOptionTitle: () => i18n.t('scheduleCycle.edit.createScheduleCopyOptionTitle'),
  deleteScheduleConfirmationMessage: () => i18n.t('scheduleCycle.edit.deleteScheduleConfirmationMessage'),
  deleteScheduleConfirmationSubmitButtonTitle: () =>
    i18n.t('scheduleCycle.edit.deleteScheduleConfirmationSubmitButtonTitle'),
  deleteScheduleConfirmationTitle: () => i18n.t('scheduleCycle.edit.deleteScheduleConfirmationTitle'),
  deleteScheduleOptionTitle: () => i18n.t('scheduleCycle.edit.deleteScheduleOptionTitle'),
  editInAdminConsoleButtonLabel: () => i18n.t('scheduleCycle.edit.editInAdminConsoleButtonLabel'),

  masterSchedule: ScheduleCycleEditMasterScheduleStrings,
  saveDraftError: ScheduleCycleEditSaveDraftError,
  scheduleNameSchool: (name: string) => i18n.t('scheduleCycle.edit.scheduleNameSchool', { name }),
  specialDays: ScheduleCycleSpecialDaysStrings,
  tab: ScheduleCycleEditTabStrings
};
