import { useServices } from '@/hooks';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Splash } from '../Splash';
import { InitializingScreen, ServerErrorScreen } from '../shared';
import { StartTrialNoUser } from './StartTrialNoUser';
import { StartTrialSubscription } from './StartTrialSubscription';

export interface StartTrialPageProps {
  sx?: SxProps;
  className?: string;
}

export const StartTrialPage = observer(({ sx, className }: StartTrialPageProps) => {
  const { authentication, user } = useServices();

  // If there is an error with the authentication, we display the ServerErrorScreen.
  if (authentication.error != null) {
    return <ServerErrorScreen error={authentication.error} />;
  }

  if (!authentication.isInitialized) {
    return <InitializingScreen />;
  }

  const hasUser = user.currentUser != null;

  return (
    <Splash sx={sx} className={className} width="100%" height="100%" contentWidth={hasUser ? 600 : 400}>
      {user.currentUser != null ? (
        <StartTrialSubscription sx={{ width: '100%' }} user={user.currentUser} />
      ) : (
        <StartTrialNoUser sx={{ width: '100%' }} />
      )}
    </Splash>
  );
});
