import { PlannedWorkEditDayViewModel } from '@/viewmodels';
import { Circle } from '@mui/icons-material';
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { take } from 'lodash';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarAnnotationView } from '../../shared';

export interface PlanningWorkEditWeekDayHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannedWorkEditDayViewModel;
}

export const PlanningWorkEditWeekDayHeader = observer(
  ({ sx = [], className, viewModel }: PlanningWorkEditWeekDayHeaderProps) => {
    const theme = useTheme();
    const dow = format(viewModel.date, 'EEE');
    const annotations = take(viewModel.annotations, 2);

    return (
      <Stack
        className={className}
        sx={{
          justifyContent: 'flex-start',
          ...sx,
          backgroundColor: viewModel.isToday ? alpha(theme.palette.error.main, 0.1) : undefined
        }}
      >
        <Box
          sx={{
            p: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.text.secondary
            }}
          >
            {viewModel.cycleDayName.length > 0 ? `${dow}  (${viewModel.cycleDayName})` : dow}
          </Typography>

          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              alignItems: 'center'
            }}
          >
            {viewModel.isToday && <Circle sx={{ fontSize: theme.typography.caption.fontSize }} color="error" />}

            <Typography
              variant="caption"
              sx={{ fontWeight: '600', color: theme.palette.text[viewModel.isInThePast ? 'secondary' : 'primary'] }}
            >
              {format(viewModel.date, 'd MMM')}
            </Typography>
          </Stack>
        </Box>
        {annotations.length > 0 && (
          <Stack
            spacing="2px"
            sx={{
              px: '2px',
              pb: '2px'
            }}
          >
            {annotations.map((a) => (
              <UserDashboardCalendarAnnotationView key={a.id} annotation={a} />
            ))}
          </Stack>
        )}
      </Stack>
    );
  }
);
