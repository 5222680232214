import { CourseSectionInfo } from '@/models';
import {
  EditableScheduleCyclePeriodActivityInfo,
  ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo
} from '@/viewmodels';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { CourseSectionPicker } from '../../../course-section';
import { ScheduleCyclePeriodEditTermActivity } from './ScheduleCyclePeriodEditTermActivity';
import { ScheduleCyclePeriodEditTermHeader } from './ScheduleCyclePeriodEditTermHeader';

export interface ScheduleCyclePeriodEditTermActivitiesProps {
  sx?: SxProps;
  className?: string;
  termInfo: ScheduleCyclePeriodSchedulePeriodAndActivityEditTermInfo;
  courseSections: CourseSectionInfo[];
  createActivitySchedule: (termId: string, courseId: string) => void;
  getCourseSectionForActivity: (activity: Activity) => CourseSectionInfo | undefined;
  displayLabel: boolean;
}

export const ScheduleCyclePeriodEditTermActivities = observer(
  ({
    sx = [],
    className,
    termInfo,
    courseSections,
    createActivitySchedule,
    displayLabel,
    getCourseSectionForActivity
  }: ScheduleCyclePeriodEditTermActivitiesProps) => {
    const { activities } = termInfo;
    const displayedActivities = activities.filter((a) => !a.markedAsDeleted);

    const displayedCourseSections = useMemo(
      () =>
        courseSections.filter((cs) =>
          displayedActivities.some((a) => a.activity != null && courseSectionMatchesActivity(cs, a.activity))
        ),
      [courseSections, displayedActivities]
    );

    const availableCourseSections = useMemo(
      () => courseSections.filter((cs) => !displayedCourseSections.includes(cs)),
      [courseSections, displayedCourseSections]
    );

    // Only allow creation of new schedule for personal or taught classes.
    // Hidden classes are always visible when editing schedules.
    const selectableCourseSections = useMemo(
      () => availableCourseSections.filter((cs) => cs.isPersonal || cs.isTeacher),
      [availableCourseSections]
    );

    function courseSectionMatchesActivity(courseSection: CourseSectionInfo, activity: Activity): boolean {
      return (
        activity.activityId === (courseSection.externalSource?.externalId ?? courseSection.id) &&
        activity.sourceName === activity.sourceName
      );
    }

    function availableCourseSectionsForActivity(a: EditableScheduleCyclePeriodActivityInfo) {
      return courseSections.filter(
        (cs) =>
          (a.activity != null && courseSectionMatchesActivity(cs, a.activity)) || availableCourseSections.includes(cs)
      );
    }

    return (
      <Stack spacing={1} sx={sx} className={className}>
        {displayLabel && <ScheduleCyclePeriodEditTermHeader termInfo={termInfo} />}
        {courseSections.length === 0 && (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontStyle: 'italic',
              px: 1
            }}
          >
            {LocalizedStrings.scheduleCycle.edit.bellTimes.period.noClassesAvailable()}
          </Typography>
        )}
        {displayedActivities.length > 0 && (
          <Stack spacing={1}>
            {displayedActivities.map((a, i) => (
              <ScheduleCyclePeriodEditTermActivity
                key={i}
                activityInfo={a}
                courseSections={availableCourseSectionsForActivity(a)}
                getCourseSectionForActivity={getCourseSectionForActivity}
              />
            ))}
          </Stack>
        )}
        {selectableCourseSections.length > 0 && (
          <CourseSectionPicker
            selectedCourseId={undefined}
            allCourseSections={selectableCourseSections}
            onChange={(id) => (id != null && id.length > 0 ? createActivitySchedule(termInfo.term.id, id) : undefined)}
            elevation={3}
          />
        )}
      </Stack>
    );
  }
);
