import { ScheduleSymbolKind } from '@/models';
import { DayAnnotation } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/day_annotation_pb';
import { Box, lighten, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { EmojiSymbol, ScheduleSymbol } from '../../../calendar';

export interface ScheduleCycleSpecialDaysTableCellProps {
  sx?: SxProps;
  className?: string;
  label: string;
  cycleDayLabel: string | undefined;
  annotations: DayAnnotation[];
  onClick?: () => void;
}

export const ScheduleCycleSpecialDaysTableCell = observer(
  ({ sx = [], className, label, cycleDayLabel, onClick, annotations }: ScheduleCycleSpecialDaysTableCellProps) => {
    const theme = useTheme();
    const cycleDayIndicatorBackgroundColor = lighten(
      theme.palette.common.black,
      theme.palette.mode === 'dark' ? 0.25 : 0.5
    );

    const cycleDayTextColor = theme.palette.getContrastText(cycleDayIndicatorBackgroundColor);
    const isSmall = useMediaQuery(() => theme.breakpoints.down('lg'));
    const cycleDayIndicatorSize = isSmall ? 14 : 20;
    const isLongCycleDayLabel = (cycleDayLabel?.length ?? 0) > 2;

    return (
      <Stack
        sx={{ ...sx, p: 0.5, pl: isSmall ? 0.5 : 1, cursor: onClick != null ? 'pointer' : undefined }}
        className={className}
        onClick={onClick != null ? () => onClick() : undefined}
      >
        <Stack
          direction="row"
          spacing={'2px'}
          sx={{
            alignItems: 'flex-start'
          }}
        >
          <Typography
            variant={isSmall ? 'caption' : 'body1'}
            sx={{
              flex: 1,
              userSelect: 'none',
              lineHeight: isSmall ? '14px' : '1.2em'
            }}
          >
            {label}
          </Typography>
          {cycleDayLabel && (
            <Box
              sx={{
                position: 'relative',
                backgroundColor: cycleDayIndicatorBackgroundColor,
                borderRadius: 0.5,
                height: cycleDayIndicatorSize,
                lineHeight: `${cycleDayIndicatorSize}px`,
                width: cycleDayIndicatorSize,
                textAlign: 'center'
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: cycleDayTextColor,
                  userSelect: 'none',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: isSmall ? (isLongCycleDayLabel ? '7px' : '8px') : isLongCycleDayLabel ? '9px' : undefined
                }}
              >
                {cycleDayLabel}
              </Typography>
            </Box>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing="2px"
          sx={{
            flex: 1,
            flexWrap: 'wrap'
          }}
        >
          {annotations.map((a) =>
            a.symbol.startsWith(':') ? (
              <ScheduleSymbol key={a.symbol} symbolKind={a.symbol as ScheduleSymbolKind} size={14} color={a.color} />
            ) : (
              <EmojiSymbol key={a.symbol} emoji={a.symbol} size={14} />
            )
          )}
        </Stack>
      </Stack>
    );
  }
);
