import { UserDashboardCalendarItemState, UserDashboardCalendarMonthNoteInfo } from '@/viewmodels';
import { Box, CardActionArea, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { BackgroundLocationState } from '../../../../BackgroundLocationState';
import { useServices } from '../../../../hooks';
import { isSxArray } from '../../../../utils';
import { ColorIndicator, NoteIcon } from '../../../lists';
import { MultilineTooltipTitle } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { formatItemStartTimeLong, formatItemTime } from '../shared';

export interface UserDashboardCalendarMonthNoteViewProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarMonthNoteInfo;
}

export const UserDashboardCalendarMonthNoteView = observer(
  ({ sx = [], className, note }: UserDashboardCalendarMonthNoteViewProps) => {
    const { navigation } = useServices();
    const theme = useTheme();
    const color = note.color || theme.palette.action.focus;
    const [showTooltip, setShowTooltip] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as BackgroundLocationState;
    const navigate = useNavigate();

    function showWorkDetails() {
      navigation.navigateToNoteEdit(navigate, location, note.id);
    }

    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={note.startTime != null ? [note.text, formatItemStartTimeLong(note.startTime)] : [note.text]}
              />
            }
            disableInteractive
            disableHoverListener
            open={showTooltip && state.backgroundLocation == null}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Box
              className={className}
              sx={[
                {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'stretch'
                },
                ...(isSxArray(sx) ? sx : [sx])
              ]}
            >
              <CardActionArea
                sx={{
                  borderRadius: 0.5,
                  px: 0.5,
                  py: '2px',
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'hidden'
                }}
                onClick={showWorkDetails}
                onContextMenu={contextMenuHandler}
              >
                <ColorIndicator color={color} isRounded sx={{ height: '100%', mr: 0.5 }} />

                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    flex: 1,
                    alignItems: 'flex-start',
                    overflow: 'hidden'
                  }}
                >
                  <NoteIcon size={18} />

                  <Stack
                    sx={{
                      flex: 1,
                      overflow: 'hidden'
                    }}
                  >
                    <Typography
                      noWrap
                      color={getColorForTitle(note.state)}
                      sx={{
                        fontSize: 12
                      }}
                    >
                      {note.text}
                    </Typography>
                  </Stack>

                  {note.startTime != null && (
                    <Typography
                      variant="caption"
                      noWrap
                      sx={{
                        color: theme.palette.text.secondary
                      }}
                    >
                      {formatItemTime(note.startTime)}
                    </Typography>
                  )}
                </Stack>
              </CardActionArea>
            </Box>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
