import { MouseEvent, ReactNode } from 'react';

export type TablePageSizeOption = 5 | 10 | 25 | 50 | 100;
export const TablePageSizeOptions: TablePageSizeOption[] = [5, 10, 25, 50, 100];

export interface TableAction {
  readonly title: string;
  readonly isDisabled: boolean;
  readonly action: () => Promise<void>;
}

export interface TableEmptyAction {
  readonly title: string;
  readonly icon?: ReactNode;
  readonly action: (e: MouseEvent<HTMLElement>) => Promise<void>;
}

export interface TableEmptyParams {
  readonly title: string;
  readonly subtitle?: string;
  readonly renderIcon: (className: string) => ReactNode;
  readonly action?: TableEmptyAction;
}
