import { PlannerTransportService } from '@/transports';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type PlannerDemoDetailedCourseSectionsLoadable = LoadableMap<CourseSectionDetails>;

export class AppPlannerDemoDetailedCourseSectionsLoadable
  extends BaseLoadableMap<CourseSectionDetails>
  implements PlannerDemoDetailedCourseSectionsLoadable
{
  constructor(
    private readonly _plannerId: string,
    private readonly _plannerTransport: PlannerTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<Map<string, CourseSectionDetails>> {
    const courses = await this._plannerTransport.getDemoDetailedCourseSectionsInPlanner(this._plannerId);
    return new Map<string, CourseSectionDetails>(courses.map((c) => [c.courseSection!.id, c]));
  }
}
