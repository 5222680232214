import { LocalizationService } from '@/services';

export type DialogActionButtonKind = 'main' | 'secondary';
export type DialogActionButtonPlacement = 'both' | 'bottom-only' | 'top-only';
export type DialogActionButtonMobilePlacement = 'left' | 'right' | 'hidden';
export type DialogActionButtonIconPlacement = 'start' | 'end';
export type DialogActionButtonVariant = 'outlined' | 'contained' | 'contained-grey';
export type DialogActionButtonIconKind =
  | 'check'
  | 'close'
  | 'edit'
  | 'fullscreen'
  | 'reduce'
  | 'open-in'
  | 'reset'
  | 'add'
  | 'back'
  | 'forward'
  | 'send';

export type DialogActionButtonColor = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

export interface DialogActionButtonNavigationAction {
  url: string | undefined;
  state: unknown;
  replace?: boolean;
}

export type DialogActionButtonActionKind = DialogActionButtonNavigationAction | (() => Promise<void>);

export interface DialogActionButtonConfiguration {
  /**
   * Kind of button. Main buttons have precedence over secondary buttons.
   */
  readonly kind: DialogActionButtonKind;
  /**
   * Indicates if the button should be show in the header only, the bottom actions only or both, depending on the
   * current view port size.
   */
  readonly placement: DialogActionButtonPlacement;
  /**
   * Indicates which side the button should be shown own in the header on mobile.
   * If button should be hidden, use 'hidden'.
   */
  readonly mobilePlacement: DialogActionButtonMobilePlacement;
  /**
   * Icon used for an icon style button. If undefined, it defaults to displaying the title.
   */
  readonly icon: DialogActionButtonIconKind | undefined;
  /**
   * Placement of the icon if one is provided. If `undefined`, the icon is not displayed on desktop.
   */
  readonly desktopIconPlacement: DialogActionButtonIconPlacement | undefined;
  /**
   * Color used to render the button. If undefined, 'primary' is used.
   */
  readonly color: DialogActionButtonColor | undefined;
  /**
   * Title of the button. It is a function to allow the observability of the value for current locale change.
   */
  readonly title: () => string;
  /**
   * Show loading indicator on button. Only used for text buttons.
   */
  readonly showLoading: boolean;
  /**
   * State of the button.
   */
  readonly isEnabled: boolean;
  /**
   * Variant of the button.
   */
  readonly variant: DialogActionButtonVariant;
  /**
   * Tooltip. Optional. It is a function to allow the observability of the value for current locale change.
   */
  readonly tooltip: (() => string) | undefined;
  /**
   * Html type of button.
   */
  readonly type: 'submit' | 'reset' | 'button' | undefined;
  /**
   * Url to redirect to on click.
   */
  readonly url: string | undefined;
  /**
   * Action triggered
   */
  action: DialogActionButtonActionKind;
}

export class BaseDialogActionButtonConfiguration implements DialogActionButtonConfiguration {
  isEnabled = true;
  showLoading = false;

  constructor(
    readonly kind: DialogActionButtonKind,
    readonly placement: DialogActionButtonPlacement,
    readonly mobilePlacement: DialogActionButtonMobilePlacement,
    public icon: DialogActionButtonIconKind | undefined,
    public desktopIconPlacement: DialogActionButtonIconPlacement | undefined,
    public title: () => string,
    readonly variant: DialogActionButtonVariant,
    readonly action: DialogActionButtonActionKind,
    public tooltip: (() => string) | undefined = undefined,
    readonly type: 'submit' | 'reset' | 'button' | undefined = undefined,
    public url: string | undefined = undefined,
    public color: DialogActionButtonColor | undefined = undefined
  ) {}
}

export class SaveDialogActionButtonConfiguration extends BaseDialogActionButtonConfiguration {
  constructor(
    kind: DialogActionButtonKind,
    localization: LocalizationService,
    action: DialogActionButtonActionKind,
    title: (() => string) | undefined = undefined,
    icon?: DialogActionButtonIconKind,
    desktopIconPlacement?: DialogActionButtonIconPlacement,
    type: 'submit' | 'reset' | 'button' | undefined = undefined
  ) {
    super(
      kind,
      'both',
      'right',
      icon ?? 'check',
      desktopIconPlacement ?? 'start',
      title ?? (() => localization.localizedStrings.utils.dialogButtonSaveTitle),
      'contained',
      action,
      undefined,
      type
    );
  }
}

export class CancelDialogActionButtonConfiguration extends BaseDialogActionButtonConfiguration {
  constructor(
    kind: DialogActionButtonKind,
    localization: LocalizationService,
    action: () => Promise<void>,
    type: 'submit' | 'reset' | 'button' | undefined = undefined
  ) {
    super(
      kind,
      'both',
      'left',
      'close',
      'start',
      () => localization.localizedStrings.utils.dialogButtonCancelTitle,
      'contained-grey',
      action,
      undefined,
      type,
      undefined,
      'error'
    );
  }
}

export class CloseDialogActionButtonConfiguration extends BaseDialogActionButtonConfiguration {
  constructor(
    kind: DialogActionButtonKind,
    localization: LocalizationService,
    action: () => Promise<void>,
    type: 'submit' | 'reset' | 'button' | undefined = undefined
  ) {
    super(
      kind,
      'both',
      'left',
      'close',
      undefined,
      () => localization.localizedStrings.utils.dialogButtonCloseTitle,
      'contained-grey',
      action,
      undefined,
      type
    );
  }
}

export class EditDialogActionButtonConfiguration extends BaseDialogActionButtonConfiguration {
  constructor(
    kind: DialogActionButtonKind,
    localization: LocalizationService,
    action: DialogActionButtonActionKind,
    hideOnMobile: boolean,
    type: 'submit' | 'reset' | 'button' | undefined = undefined
  ) {
    super(
      kind,
      hideOnMobile ? 'bottom-only' : 'both',
      hideOnMobile ? 'hidden' : 'right',
      'edit',
      'start',
      () => localization.localizedStrings.utils.dialogButtonEditTitle,
      'contained-grey',
      action,
      undefined,
      type
    );
  }
}
