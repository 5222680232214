import { ServiceContainer } from '@/providers';
import { ConnectedAppsService } from '@/services';
import { UserDataStore } from '@/stores';
import { ParticipationRequestState } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/participation_request_state_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { computed, makeObservable } from 'mobx';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';
import {
  AppPlannerParticipationRequestViewModel,
  PlannerParticipationRequestViewModel
} from './PlannerParticipationRequestViewModel';

export interface PlannerParticipationRequestsDialogViewModel extends StaticDialogViewModel {
  readonly unreadRequests: PlannerParticipationRequestViewModel[];
}

export class AppPlannerParticipationRequestsDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements PlannerParticipationRequestsDialogViewModel
{
  constructor(
    private readonly _plannerId: string,
    onDismiss: () => Promise<void>,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super(onDismiss);
    makeObservable(this);
  }

  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  get unreadRequests(): PlannerParticipationRequestViewModel[] {
    return this.planner.participationRequests
      .filter(
        (req) =>
          req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNREAD ||
          req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNSPECIFIED
      )
      .map(
        (req) => new AppPlannerParticipationRequestViewModel(req, this._plannerId, this._userStore, this._connectedApps)
      );
  }
}
