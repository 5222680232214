import { AccountKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_kind_pb';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { SchoolTransportService } from '../../transports';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type SchoolTeacherAccountsLoadable = LoadableMap<Account>;

export class AppSchoolTeacherAccountsLoadable
  extends BaseLoadableMap<Account>
  implements SchoolTeacherAccountsLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<Map<string, Account>> {
    const teachers = await this._schoolTransport.getSchoolAccounts(
      this._schoolId,
      [AccountKind.TEACHER],
      undefined,
      false
    );
    return teachers.reduce((value, t) => value.set(t.id, t), new Map<string, Account>());
  }
}
