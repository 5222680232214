import { SubscriptionInfo } from '@/models';
import { SubscriptionsTransportService } from '@/transports';
import { BaseOptionalLoadableValue, LoadableValue } from './Loadable';

export type SubscriptionLoadable = LoadableValue<SubscriptionInfo | undefined>;

export class AppSubscriptionLoadable
  extends BaseOptionalLoadableValue<SubscriptionInfo | undefined>
  implements SubscriptionLoadable
{
  constructor(private readonly _subscriptionsTransport: SubscriptionsTransportService) {
    super();
  }

  protected async loadData(): Promise<SubscriptionInfo | undefined> {
    return await this._subscriptionsTransport.getSubscription();
  }
}
