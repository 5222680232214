import { ServiceContainer } from '@/providers';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import {
  AppOnboardingClassroomConnectionStepViewModel,
  OnboardingClassroomConnectionStepViewModel
} from './OnboardingClassroomConnectionStepViewModel';
import { AppSharePlannerStepViewModel, SharePlannerStepViewModel } from './SharePlannerStepViewModel';
import {
  AppUseSharingInvitationCodeStepViewModel,
  UseSharingInvitationCodeStepViewModel
} from './UseSharingInvitationCodeStepViewModel';
import { AppUserPersonaStepViewModel, UserPersonaStepViewModel } from './UserPersonaStepViewModel';

export type OnboardingScreenKind = 'user-persona-selection' | 'use-code' | 'classroom-connection' | 'share-planner';

export type OnboardingScreenInfo =
  | {
      case: 'user-persona-selection';
      viewModel: UserPersonaStepViewModel;
    }
  | {
      case: 'use-code';
      viewModel: UseSharingInvitationCodeStepViewModel;
    }
  | {
      case: 'classroom-connection';
      viewModel: OnboardingClassroomConnectionStepViewModel;
    }
  | {
      case: 'share-planner';
      viewModel: SharePlannerStepViewModel;
    };

export interface OnboardingViewModel {
  readonly activeScreenIndex: number;
  readonly previousScreenIndex: number;
  readonly screens: OnboardingScreenInfo[];

  userPersona: UserPersona | undefined;
  plannerId: string | undefined;
  schoolId: string | undefined;

  displayScreenOfKind(kind: OnboardingScreenKind): void;
  goBack(): void;
  goBackToLastScreenOfKind(kind: OnboardingScreenKind): void;
  signOut(): Promise<void>;
  complete(): Promise<void>;
}

export class AppOnboardingViewModel implements OnboardingViewModel {
  @observable private _activeScreenIndex = 0;
  private _previousScreenIndex = 0;
  @observable private _screens: OnboardingScreenInfo[] = [];
  @observable private _userPersona: UserPersona | undefined;
  @observable private _plannerId: string | undefined;
  @observable private _schoolId: string | undefined;

  constructor(
    private readonly _authentication = ServiceContainer.services.authentication,
    private readonly _user = ServiceContainer.services.user,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _localization = ServiceContainer.services.localization,
    private readonly _analytics = ServiceContainer.services.analytics,
    private readonly _connectedApps = ServiceContainer.services.connectedApps,
    private readonly _settingsStorage = ServiceContainer.services.settingsStorage
  ) {
    makeObservable(this);

    const initialScreen = this.makeScreenForKind('user-persona-selection');
    this._screens.push(initialScreen);

    reaction(
      () => this._activeScreenIndex,
      (_, oldValue) => (this._previousScreenIndex = oldValue)
    );
  }

  @computed
  get activeScreenIndex(): number {
    return this._activeScreenIndex;
  }

  get previousScreenIndex(): number {
    return this._previousScreenIndex;
  }

  @computed
  get screens(): OnboardingScreenInfo[] {
    return this._screens;
  }

  @computed
  get userPersona(): UserPersona | undefined {
    return this._userPersona;
  }

  set userPersona(value: UserPersona) {
    this._userPersona = value;
  }

  @computed
  get plannerId(): string | undefined {
    return this._plannerId;
  }

  set plannerId(value: string | undefined) {
    this._plannerId = value;
  }

  @computed
  get schoolId(): string | undefined {
    return this._schoolId;
  }

  set schoolId(value: string | undefined) {
    this._schoolId = value;
  }

  @action
  displayScreenOfKind(kind: OnboardingScreenKind): void {
    requestAnimationFrame(() => {
      runInAction(() => {
        const screen = this.makeScreenForKind(kind);
        this._screens.push(screen);
      });
    });

    requestAnimationFrame(() => {
      runInAction(() => {
        this._activeScreenIndex = this._screens.length - 1;
      });
    });
  }

  @action
  goBack(): void {
    this._activeScreenIndex--;

    requestAnimationFrame(() => {
      runInAction(() => {
        this._screens.pop();
      });
    });
  }

  @action
  goBackToLastScreenOfKind(kind: OnboardingScreenKind): void {
    const lastIndex = this._screens.findLastIndex((i) => i.case === kind);

    if (lastIndex < 0) {
      return;
    }

    this._activeScreenIndex = lastIndex;

    requestAnimationFrame(() => {
      runInAction(() => {
        this._screens.splice(lastIndex + 1);
      });
    });
  }

  signOut(): Promise<void> {
    return this._authentication.signOut();
  }

  async complete(): Promise<void> {
    await this._analytics.logOnboardingCompleted(this.schoolId != null ? [this.schoolId] : []);
    this._settingsStorage.activeDashboard = { kind: 'planner', id: this.plannerId! };
    await Promise.all([this._userStore.plannersLoadable.fetch(true), this._userStore.participatingSchools.fetch(true)]);
  }

  private makeScreenForKind(kind: OnboardingScreenKind): OnboardingScreenInfo {
    switch (kind) {
      case 'user-persona-selection':
        return {
          case: 'user-persona-selection',
          viewModel: new AppUserPersonaStepViewModel(
            this,
            this._user,
            this._userStore,
            this._analytics,
            this._localization
          )
        };

      case 'use-code':
        return {
          case: 'use-code',
          viewModel: new AppUseSharingInvitationCodeStepViewModel(
            this,
            this._localization,
            this._user,
            this._plannerStore,
            this._userStore
          )
        };

      case 'share-planner':
        return {
          case: 'share-planner',
          viewModel: new AppSharePlannerStepViewModel(
            this,
            this._userStore,
            this._plannerStore,
            this._user,
            this._analytics
          )
        };

      case 'classroom-connection':
        return {
          case: 'classroom-connection',
          viewModel: new AppOnboardingClassroomConnectionStepViewModel(this, this._connectedApps, this._plannerStore)
        };
    }
  }
}
