import { DialogProps, Dialog as MUIDialog, Paper, PaperProps, styled, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import Draggable from 'react-draggable';

interface DraggablePaperComponentProps extends PaperProps {
  handleId?: string;
}

function DraggablePaperComponent({ handleId, ...props }: DraggablePaperComponentProps) {
  const nodeRef = useRef(null);

  return (
    <Draggable handle={`#${handleId}`} cancel={'[class*="MuiDialogContent-root"]'} bounds="body" nodeRef={nodeRef}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export interface ResponsiveDialogProps extends DialogProps {
  children: ReactNode;

  /**
   * Indicates that the dialog is open.
   */
  open: boolean;

  /**
   * Indicates to disable closing the dialog. Optional. Default is false.
   */
  disableClose?: boolean;

  /**
   * The dialog max width. Optional.
   */
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

  /**
   * Indicates to make the dialog take the full width (`maxWidth`). Optional.
   */
  fullWidth?: boolean;

  fullHeight?: boolean;
  /**
   * The max full screen width. Optional. Used to determine if the dialog is in full screen.
   * The `maxWidth` will be used if not specified.
   */
  fullScreenWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   * If true, the dialog will be full screen ignoring the `fullScreenWidth` and `maxWidth` values.
   */
  fullScreen?: boolean;

  /**
   * Callback when closing the dialog.
   */
  onClose?: () => void;
  isDraggable: boolean;
  ariaLabelledBy: string;
}

export const ResponsiveDialog = ({
  open,
  maxWidth,
  fullWidth,
  fullHeight,
  fullScreenWidth,
  fullScreen,
  children,
  onClose,
  disableClose,
  isDraggable,
  ariaLabelledBy,
  ...props
}: ResponsiveDialogProps) => {
  const theme = useTheme();
  const fullscreenMediaQuery = useMediaQuery(
    fullScreenWidth != null
      ? theme.breakpoints.down(fullScreenWidth)
      : theme.breakpoints.up(maxWidth !== false && maxWidth != null ? maxWidth : 'sm')
  );
  const isFullScreen = fullScreen ?? (fullScreenWidth != null ? fullscreenMediaQuery : !fullscreenMediaQuery);

  const basePaperProps: PaperProps = {
    ...props.PaperProps,
    className: clsx(!isFullScreen && fullHeight && classes.fullHeight)
  };

  return (
    <Dialog
      {...props}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={!disableClose ? onClose : undefined}
      fullScreen={isFullScreen}
      PaperProps={{
        ...(isDraggable && !isFullScreen
          ? ({
              ...basePaperProps,
              handleId: isDraggable && !isFullScreen ? ariaLabelledBy : undefined
            } as DraggablePaperComponentProps)
          : basePaperProps)
      }}
      PaperComponent={isDraggable && !isFullScreen ? DraggablePaperComponent : undefined}
      aria-labelledby={ariaLabelledBy}
      scroll="paper"
    >
      {children}
    </Dialog>
  );
};

const PREFIX = 'ResponsiveDialog';
const classes = {
  fullHeight: `${PREFIX}-fullHeight`
};

const Dialog = styled(MUIDialog)(() => ({
  [`& .${classes.fullHeight}`]: {
    height: `calc(100% - 64px)`
  }
}));
