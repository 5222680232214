import { useServices } from '@/hooks';
import { WorkPlannedWorksViewModel } from '@/viewmodels';
import { AddRounded, EditCalendarRounded } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlannedWorkEditDialog } from '../planning';
import { WorkDetailsCardHeader } from './WorkDetailsCardHeader';
import { WorkDetailsNoUpcomingPlannedWork } from './WorkDetailsNoUpcomingPlannedWork';
import { WorkDetailsPlannedWorkCard } from './WorkDetailsPlannedWorkCard';
import { WorkDetailsPlannedWorksListPopover } from './WorkDetailsPlannedWorksListPopover';

interface LocationState {
  plannedWorksCardShowCreatePlannedWork?: boolean;
}

export interface WorkDetailsNextPlannedWorkCardProps {
  viewModel: WorkPlannedWorksViewModel;
  className?: string;
  sx?: SxProps;

  /**
   * Indicates whether to disable the components. Optional. Default is `false`.
   */
  disabled?: boolean;
}

export const WorkDetailsNextPlannedWorkCard = observer(
  ({ viewModel, disabled, className, sx }: WorkDetailsNextPlannedWorkCardProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;
    const [plannedWorkListRef, setPlannedWorkListRef] = useState<HTMLElement | undefined>(undefined);
    const theme = useTheme();

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();

    function showCreatePlannedWork() {
      const newState: LocationState = { ...state, plannedWorksCardShowCreatePlannedWork: true };
      navigate(location, { state: newState });
    }

    return (
      <>
        <Card
          className={className}
          sx={{ ...sx, py: 1.5, display: 'flex', flexDirection: 'column' }}
          elevation={theme.palette.mode === 'light' ? 1 : 8}
        >
          <Stack
            spacing={1.5}
            sx={{
              flex: 1
            }}
          >
            <WorkDetailsCardHeader
              sx={{ px: 2 }}
              icon={<EditCalendarRounded />}
              title={strings.plannedWorkCardTitle}
              subtitle={strings.plannedWorkCardSubtitle}
              rightElement={
                !viewModel.isReadOnly && viewModel.nextPlannedWorks.length > 0
                  ? () => (
                      <IconButton onClick={() => showCreatePlannedWork()} disabled={disabled}>
                        <AddRounded />
                      </IconButton>
                    )
                  : undefined
              }
            />

            <Box
              sx={{
                flex: 1,
                px: 1
              }}
            >
              {viewModel.nextPlannedWorks.length > 0 ? (
                <Stack spacing={1}>
                  <WorkDetailsPlannedWorkCard
                    plannedWork={viewModel.nextPlannedWorks[0]}
                    onDelete={() => viewModel.cancelPlannerWork(viewModel.nextPlannedWorks[0].plannedWork.id)}
                    isReadOnly={viewModel.isReadOnly}
                    showHeader
                    canLinkSteps={viewModel.canLinkPlannedWorkToSteps}
                    makeLinkStepsViewModel={(plannedWork) => viewModel.makeLinkStepsViewModel(plannedWork)}
                  />
                </Stack>
              ) : (
                <WorkDetailsNoUpcomingPlannedWork
                  sx={{ px: 1 }}
                  onCreate={showCreatePlannedWork}
                  isReadOnly={viewModel.isReadOnly}
                />
              )}
            </Box>

            {(viewModel.pastPlannedWorks.length > 0 || viewModel.nextPlannedWorks.length > 1) && (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  px: 1.5,
                  justifyContent: 'flex-end',
                  alignItems: 'baseline'
                }}
              >
                {viewModel.nextPlannedWorks.length > 1 && (
                  <Typography
                    variant="caption"
                    sx={{
                      flex: 1,
                      px: 1,
                      color: theme.palette.text.secondary
                    }}
                  >
                    {strings.plannedWorkCardMoreLabel(viewModel.nextPlannedWorks.length - 1)}
                  </Typography>
                )}

                <Button size="small" onClick={(e) => setPlannedWorkListRef(e.currentTarget)}>
                  {strings.plannedWorkCardShowAllButtonLabel}
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
        {(state.plannedWorksCardShowCreatePlannedWork ?? false) && (
          <PlannedWorkEditDialog isOpen={true} workId={viewModel.workId} plannedWorkId={undefined} />
        )}
        {plannedWorkListRef != null && (
          <WorkDetailsPlannedWorksListPopover
            viewModel={viewModel}
            open
            anchorEl={plannedWorkListRef}
            onClose={() => setPlannedWorkListRef(undefined)}
          />
        )}
      </>
    );
  }
);
