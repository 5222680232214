import { ClassroomSyncBlocker } from '@/models';
import { ServiceContainer } from '@/providers';
import { ClassroomConnectedAppService, LocalizationService } from '@/services';
import { Loadable } from '@/stores';
import { Course } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/course_pb';
import { captureException } from '@sentry/react';
import { keyBy } from 'lodash';
import { ObservableMap, action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppEditableConnectedAppViewModel, EditableConnectedAppViewModel } from '../EditableConnectedAppViewModel';
import { ClassroomCourseViewModel } from './ClassroomCourseViewModel';

export interface EditableClassroomConnectedAppViewModel extends EditableConnectedAppViewModel {
  readonly courses: ClassroomCourseViewModel[];
}

export abstract class AppEditableClassroomConnectedAppViewModel<CourseLoadable extends Loadable<unknown>>
  extends AppEditableConnectedAppViewModel<Course[], ClassroomSyncBlocker>
  implements EditableClassroomConnectedAppViewModel
{
  protected constructor(
    protected readonly _connectedApp: ClassroomConnectedAppService,
    protected readonly _plannerId: string,
    onSuccess: (hasChanges: boolean) => Promise<void>,
    startSync: boolean,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(_connectedApp, onSuccess, startSync, localization);
    makeObservable(this);
  }

  protected abstract get courseSectionLoadable(): CourseLoadable;

  abstract get courses(): ClassroomCourseViewModel[];

  @computed
  get hasChanges(): boolean {
    return this.courses.some((c) => c.hasChanges);
  }

  @computed
  protected get isEntitled(): boolean {
    return !this._connectedApp.allSyncBlockers.includes('insufficient-entitlements');
  }

  protected async loadData(): Promise<Course[]> {
    await this.courseSectionLoadable.fetch(true);
    return await this._connectedApp.getCourses();
  }

  protected async onSync(): Promise<void> {
    // Save the selected state
    const lastCoursesSelectedStateById = new Map<string, boolean>();
    this.coursesById.forEach((c, key) => lastCoursesSelectedStateById.set(key, c.isSelected));

    await this.reloadData();

    // Apply the saved selected state to the new data
    lastCoursesSelectedStateById.forEach((isSelected, key) => {
      const course = this.coursesById.get(key);
      if (course != null) {
        course.isSelected = isSelected;
      }
    });
  }

  @computed
  private get coursesById(): ObservableMap<string, ClassroomCourseViewModel> {
    return observable.map<string, ClassroomCourseViewModel>(keyBy(this.courses, (c) => c.id));
  }

  @action
  async save() {
    try {
      this._isApplying = true;
      this._error = undefined;

      await this._connectedApp.updateCourseSelection(this.courses.filter((c) => c.isSelected).map((c) => c.id));

      await runInAction(async () => {
        this._isApplying = false;
        await this._onSuccess(true);
      });

      void this.refreshDashboardData();
    } catch (e) {
      captureException(e);
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
    }
  }

  protected abstract refreshDashboardData(): Promise<void>;
}
