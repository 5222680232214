import { Day, dateToPBDate, dayToDate } from '@/models';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { CalendarDatePicker, FormPopoverActions, FormPopoverHeader } from '../../../../utils';

export interface ScheduleCycleTermEditProps {
  sx?: SxProps;
  className?: string;
  term?: Term;
  minDate: Date;
  maxDate: Date;
  onSave: (id: string | undefined, name: string, startDay: Day, endDay: Day) => void;
  dismiss: () => void;
}

export const ScheduleCycleTermEdit = observer(
  ({ sx = [], className, term, minDate, maxDate, onSave, dismiss }: ScheduleCycleTermEditProps) => {
    const [name, setName] = useState(term?.name ?? '');
    const [startDate, setStartDate] = useState(term?.startDay != null ? dayToDate(term.startDay) : minDate);
    const [endDate, setEndDate] = useState(term?.endDay != null ? dayToDate(term.endDay) : maxDate);

    function save() {
      onSave(term?.id, name, dateToPBDate(startDate), dateToPBDate(endDate));
      return Promise.resolve();
    }

    const strings = LocalizedStrings.scheduleCycle.term.edit;
    const title = term == null ? strings.createTitle() : strings.editTitle();

    return (
      <Stack sx={sx} className={className}>
        <FormPopoverHeader title={title} />
        <Stack
          spacing={2}
          sx={{
            p: 2
          }}
        >
          <TextField
            value={name || ''}
            label={strings.nameLabel()}
            onChange={(e) => setName(e.target.value)}
            size="small"
          />

          <CalendarDatePicker
            value={startDate}
            label={strings.startDateLabel()}
            minDate={minDate}
            maxDate={endDate}
            format="PPP"
            onChange={(v) => setStartDate(v!)}
            slotProps={{ desktopPaper: { elevation: 2 }, mobilePaper: { elevation: 2 } }}
            showDaysOutsideCurrentMonth
          />

          <CalendarDatePicker
            value={endDate ?? null}
            label={strings.endDate()}
            minDate={startDate}
            maxDate={maxDate}
            format="PPP"
            onChange={(v) => setEndDate(v!)}
            slotProps={{ desktopPaper: { elevation: 2 }, mobilePaper: { elevation: 2 } }}
            showDaysOutsideCurrentMonth
          />
        </Stack>
        <FormPopoverActions
          onCancel={() => {
            dismiss();
            return Promise.resolve();
          }}
          onSubmit={save}
          canSubmit={name.length > 0}
        />
      </Stack>
    );
  }
);
