import { useKeyboardShortcuts } from '@/hooks';
import { KeyboardShortcut } from '@/utils';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface KeyboardShortcutsListenerProps {
  shortcuts: (KeyboardShortcut | undefined)[];
  children: ReactNode;
}

export const KeyboardShortcutsListener = observer(({ shortcuts, children }: KeyboardShortcutsListenerProps) => {
  // Removes undefined values from array.
  const allShortcuts = shortcuts.flatMap((s) => (s ? [s] : []));
  useKeyboardShortcuts(allShortcuts);

  return <>{children}</>;
});
