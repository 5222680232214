import { compareSchoolCourseSections } from '@/models';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { chain, last } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { Loadable, SchoolCourseSectionsLoadable, SchoolDataStore, UserDataStore } from '../../stores';
import { propertiesHaveMatchForSearchText } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminClassesListViewModel extends UpdatableViewModel {
  readonly schoolId: string;
  readonly coursesCount: number;
  readonly courses: CourseSection[];
  readonly currentScheduleCycleId: string | undefined;
  readonly canEditSchedules: boolean;
  searchText: string;
}

export class AppAdminClassesListViewModel extends BaseUpdatableViewModel implements AdminClassesListViewModel {
  @observable private _searchText = '';

  constructor(
    public readonly schoolId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get courseSectionsLoadable(): SchoolCourseSectionsLoadable {
    return this._schoolStore.getCourseSections(this.schoolId);
  }

  @computed
  private get school(): SchoolInformation {
    return this._userStore.getSchoolForId(this.schoolId)!;
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionsLoadable];
  }

  @computed
  get coursesCount(): number {
    return this.courseSectionsLoadable.values.length;
  }

  @computed
  get courses(): CourseSection[] {
    return chain(this.courseSectionsLoadable.values)
      .filter((cs) =>
        propertiesHaveMatchForSearchText(this._searchText, [
          cs.title,
          cs.section,
          ...(cs.details?.teachers ?? []).map((t) => t.fullName),
          { value: cs.id, method: 'exact', caseSensitive: true }
        ])
      )
      .sort((cs1, cs2) => compareSchoolCourseSections(cs1, cs2, this._localization.currentLocale))
      .value();
  }

  @computed
  get currentScheduleCycleId() {
    if (this.school.school == null) {
      return undefined;
    }

    return last(
      this.school.school.scheduleCycleIds.filter((id) => !this.school.school?.ignoredScheduleCycleIds.includes(id))
    );
  }

  @computed
  get canEditSchedules(): boolean {
    return this.school.school?.isArchived !== true;
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }
}
