import { useServices } from '@/hooks';
import { ConfirmationDialogChoice, CourseSectionDetailsViewModel } from '@/viewmodels';
import { DeleteRounded, EditRounded, VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { IconButton, ListItem, ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { ColorIndicator } from '../../lists';
import { ActionMenu, ActionMenuButton, ConfirmationDialogWithChoices } from '../../utils';

export interface CourseSectionListItemProps {
  sx?: SxProps;
  className?: string;
  courseSection: CourseSectionDetailsViewModel;
  canEdit: boolean;
  editCourse: () => void;
}

export const CourseSectionListItem = observer(
  ({ sx = [], className, courseSection, editCourse, canEdit }: CourseSectionListItemProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.courseSection.lists;
    const theme = useTheme();

    const [showDeleteConfirmAlert, setShowDeleteConfirmAlert] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const menuAnchorRef = useRef<HTMLButtonElement>(null);

    const keepWorksOptionKey = 'keep-associated-works';
    const deleteConfirmAlertChoices: ConfirmationDialogChoice[] = [
      { key: keepWorksOptionKey, title: strings.deleteConfirmKeepWorks }
    ];

    function onSubmitDeleteConfirmation(result: string[] | undefined) {
      setShowDeleteConfirmAlert(false);
      if (result != null) {
        void courseSection.delete(!result.includes(keepWorksOptionKey));
      }
    }

    const isVisible = courseSection.courseSection.isVisible;

    return (
      <>
        <ListItem sx={sx} className={className}>
          <ColorIndicator
            color={courseSection.courseSection.color}
            sx={{
              height: courseSection.courseSection.externalSource != null ? 40 : 30,
              mr: 2,
              opacity: !isVisible ? 0.5 : undefined
            }}
          />
          <ListItemText
            primary={courseSection.courseSection.title}
            secondary={courseSection.courseSection.section}
            primaryTypographyProps={{ color: !isVisible ? 'textSecondary' : undefined }}
          />

          {canEdit && (
            <>
              <ActionMenuButton ref={menuAnchorRef} sx={{ mr: 1 }} onClick={() => setShowMenu(true)} />
              <ActionMenu open={showMenu} anchorEl={menuAnchorRef.current} onClose={() => setShowMenu(false)}>
                <MenuItem
                  onClick={() => {
                    setShowMenu(false);
                    editCourse();
                  }}
                >
                  <ListItemIcon>
                    <EditRounded />
                  </ListItemIcon>
                  <ListItemText>{strings.edit}</ListItemText>
                </MenuItem>

                {courseSection.courseSection?.externalSource == null && (
                  <MenuItem
                    onClick={() => {
                      setShowMenu(false);
                      setShowDeleteConfirmAlert(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteRounded color={'error'} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ color: theme.palette.error.main }}>
                      {strings.delete}
                    </ListItemText>
                  </MenuItem>
                )}
              </ActionMenu>

              <ListItemIcon>
                <IconButton onClick={() => void courseSection.toggleVisibility()}>
                  {isVisible ? <VisibilityRounded /> : <VisibilityOffRounded color="disabled" />}
                </IconButton>
              </ListItemIcon>
            </>
          )}
        </ListItem>

        {showDeleteConfirmAlert && (
          <ConfirmationDialogWithChoices
            isOpen={true}
            title={strings.deleteConfirmTitle}
            message={strings.deleteConfirmMessage}
            confirmButtonLabel={strings.deleteConfirmButton}
            choices={deleteConfirmAlertChoices}
            onSubmit={onSubmitDeleteConfirmation}
          />
        )}
      </>
    );
  }
);
