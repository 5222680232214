import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { differenceInCalendarDays } from 'date-fns';
import { chain, orderBy } from 'lodash';
import { DateService } from '../../services';
import { Locale } from '../../utils';
import { dayToDate } from '../Day';
import { OrderedScheduleSymbolKinds, ScheduleSymbolKind, ScheduleSymbolKindsWithBackground } from '../ScheduleSymbols';
import { UserDashboardInfo } from '../UserDashboard';

export function getPrimaryColorForSymbols(symbols: { kind: ScheduleSymbolKind; color: string }[]): string {
  const symbolsWithBackground = symbols.filter((s) => ScheduleSymbolKindsWithBackground.includes(s.kind));
  const orderedSymbols = orderBy(symbolsWithBackground, [(s) => OrderedScheduleSymbolKinds.indexOf(s.kind)], ['asc']);
  return orderedSymbols[orderedSymbols.length - 1]?.color ?? '';
}

export function getAllSchedulesTagsFromScheduleCycle(scheduleCycle: ScheduleCycle, locale: Locale): string[] {
  return chain(scheduleCycle.periodSchedules)
    .map((ps) => (!ps.shouldDelete ? ps.scheduleTags : []))
    .reduce((prev, curr) => {
      prev.push(...(curr.length > 0 ? curr : ['']));
      return prev;
    }, [] as string[])
    .uniq()
    .sort((a, b) =>
      a.toLocaleLowerCase(locale).localeCompare(b.toLocaleLowerCase(locale), locale, { sensitivity: 'base' })
    )
    .value();
}

export function scheduleCycleSupportsScheduleTags(userDashboard: UserDashboardInfo): boolean {
  // Makes no sense to expose this concept to a user who is creating a schedule for themselves.
  return userDashboard.kind === 'school';
}

export function scheduleCycleIsPast(scheduleCycle: ScheduleCycle, dateService: DateService) {
  return differenceInCalendarDays(dateService.now, dayToDate(scheduleCycle.endDay!)) > 0;
}
