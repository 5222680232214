import { useActivePlannerId, useViewModel } from '@/hooks';
import { SxProps } from '@mui/system';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Dialog } from '../../utils';
import { PlannerNewSchoolsClassroomConnection } from './PlannerNewSchoolsClassroomConnection';
import { PlannerNewSchoolsSelection } from './PlannerNewSchoolsSelection';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

export interface PlannerNewSchoolsDialogProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerNewSchoolsDialog = observer(({ sx = [], className }: PlannerNewSchoolsDialogProps) => {
  const plannerId = useActivePlannerId();
  const strings = LocalizedStrings.planner.newSchools;

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPlannerNewSchoolsDialogViewModel(plannerId),
    [plannerId]
  );

  const direction = viewModel.tab === 'classroom-connection' ? 1 : -1;

  return (
    <Dialog
      sx={sx}
      className={className}
      viewModel={viewModel}
      isOpen={viewModel.isOpen}
      title={strings.title()}
      width="xs"
      renderData={() => (
        <AnimatePresence initial={false} custom={direction} mode="popLayout">
          <motion.div
            key={viewModel.tab}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ opacity: { duration: 0.2 }, ease: 'easeInOut' }}
          >
            {viewModel.tab === 'selection' ? (
              <PlannerNewSchoolsSelection viewModel={viewModel} />
            ) : (
              <PlannerNewSchoolsClassroomConnection viewModel={viewModel} />
            )}
          </motion.div>
        </AnimatePresence>
      )}
    />
  );
});
