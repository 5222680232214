import { OrderedScheduleSymbolKinds, ScheduleSymbolKind } from '@/models';
import { SpecialDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_pb';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { EmojiSymbol } from './EmojiSymbol';
import { ScheduleSymbol } from './ScheduleSymbol';

export interface SpecialDaySymbolViewProps {
  sx?: SxProps;
  className?: string;
  size: number;
  specialDay: SpecialDay;
}

export const SpecialDaySymbolView = observer(({ sx = [], className, size, specialDay }: SpecialDaySymbolViewProps) => {
  if (specialDay.symbol.length === 0) {
    return null;
  }

  const resolvedSymbol = specialDay.symbol as ScheduleSymbolKind;
  const isProvidedSymbol = OrderedScheduleSymbolKinds.includes(resolvedSymbol);

  if (isProvidedSymbol) {
    return <ScheduleSymbol className={className} symbolKind={resolvedSymbol} size={size} color={specialDay.color} />;
  } else {
    return <EmojiSymbol sx={sx} className={className} emoji={resolvedSymbol} size={size} />;
  }
});
