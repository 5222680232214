import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { getOptionalDashConcatenation } from '@/models';
import {
  ArchiveRounded,
  ContentCopyRounded,
  DifferenceRounded,
  LowPriorityRounded,
  OpenInNewRounded,
  RepeatRounded
} from '@mui/icons-material';
import { Box, Grid2, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BackgroundLocationState } from '../../../BackgroundLocationState';
import { WorkAttachmentList } from '../../lists';
import { AlertDialog, ConfirmationDialog, Dialog, DialogAdditionalAction, Subheader, onDialogClose } from '../../utils';
import { WorkEditDialog } from '../edit';
import { WorkDescriptionCard } from './WorkDescriptionCard';
import { WorkDetailsGradeCard } from './WorkDetailsGradeCard';
import { WorkDetailsNextPlannedWorkCard } from './WorkDetailsNextPlannedWorkCard';
import { WorkDetailsStepsList } from './WorkDetailsStepsList';
import { WorkStatusBanner } from './WorkStatusBanner';

interface LocationState extends BackgroundLocationState {
  workDetailsShowWorkEdit?: boolean;
}

export interface WorkDetailsDialogProps {
  isOpen: boolean;
}

export const WorkDetailsDialog = observer(({ isOpen }: WorkDetailsDialogProps) => {
  const { featureFlag, localization, route } = useServices();

  const plannerId = useActivePlannerId();
  const params = useParams();
  const workId = params.workId ?? '';

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();

  function close() {
    onDialogClose(route, navigate, state, plannerId);
    return Promise.resolve();
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createWorkDetailsViewModel(workId, plannerId, close),
    [workId, plannerId]
  );

  const strings = localization.localizedStrings.work.details;

  const [showCancelWorkConfirmAlert, setShowCancelWorkConfirmAlert] = useState(false);

  async function dismissCancelWorkConfirmAlert(hasConfirmed: boolean) {
    setShowCancelWorkConfirmAlert(false);

    if (hasConfirmed) {
      await viewModel.cancelWork();
      await close();
    }
  }

  function duplicate() {
    viewModel.duplicate(navigate, location);
    return Promise.resolve();
  }

  function distribute() {
    viewModel.distribute(navigate, location);
    return Promise.resolve();
  }

  function repeat() {
    viewModel.repeat(navigate, location);
    return Promise.resolve();
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  const actions: DialogAdditionalAction[] = viewModel.hasData
    ? [
        {
          title: strings.copyWork,
          icon: <ContentCopyRounded />,
          isHidden: !isItemDuplicationEnabled,
          action: () => {
            viewModel.copyToPasteboard();
            return Promise.resolve();
          }
        },
        {
          title: strings.duplicateWork,
          icon: <DifferenceRounded />,
          isHidden: !isItemDuplicationEnabled || viewModel.isReadOnly,
          action: duplicate
        },
        {
          title: strings.distributeWork,
          icon: <LowPriorityRounded />,
          isHidden:
            !isItemDuplicationEnabled ||
            viewModel.work.dueTime == null ||
            viewModel.work.courseSectionId.length === 0 ||
            viewModel.isReadOnly,
          action: distribute
        },
        {
          title: strings.repeatWork,
          icon: <RepeatRounded />,
          isHidden: !isItemDuplicationEnabled || viewModel.isReadOnly,
          action: repeat
        },
        {
          title: strings.cancelWork,
          icon: <ArchiveRounded />,
          isHidden: viewModel.isCancelled || viewModel.externalSource != null || viewModel.isReadOnly,
          isDestructive: true,
          action: () => {
            setShowCancelWorkConfirmAlert(true);
            return Promise.resolve();
          }
        }
      ]
    : [];

  useEffect(() => {
    route.onDialogPresent();
    return () => route.onDialogDismiss();
  }, []);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        viewModel={viewModel}
        title={strings.title}
        width="md"
        canToggleFullscreen
        contentPadding={viewModel.hasData && !viewModel.isCancelled ? { top: 1 } : undefined}
        additionalActions={actions}
        renderData={() => {
          // Only showing grade for external source
          const showGrade = !viewModel.isCancelled && viewModel.isCompleted && viewModel.externalSource != null;
          const showPlannedWorks = !showGrade && !viewModel.isCancelled && !viewModel.isCompleted;

          return (
            <Box
              sx={{
                flex: 1,
                width: '100%'
              }}
            >
              {viewModel.isCancelled && (
                <Stack
                  sx={{
                    mb: 1
                  }}
                >
                  <WorkStatusBanner
                    isReadOnly={viewModel.isReadOnly}
                    actionIsEnabled={!viewModel.isApplying}
                    actionTitle={strings.cancelledAlertActionTitle}
                    actionEndIcon={viewModel.isStatusChangedExternally && <OpenInNewRounded />}
                    actionOnClick={() => void viewModel.restoreWork()}
                    title={strings.cancelledAlertTitle}
                    kind="error"
                  />
                </Stack>
              )}
              {viewModel.isCompleted && (
                <Stack
                  sx={{
                    mb: 1
                  }}
                >
                  <WorkStatusBanner
                    isReadOnly={viewModel.isReadOnly}
                    actionIsEnabled={!viewModel.isApplying}
                    actionTitle={strings.completedAlertActionTitle}
                    actionEndIcon={viewModel.isStatusChangedExternally && <OpenInNewRounded />}
                    actionOnClick={() => void viewModel.restoreWork()}
                    title={strings.completedAlertTitle(viewModel.work.statusChangedTime?.toDate())}
                    kind="success"
                  />
                </Stack>
              )}
              <Grid2 container spacing={1} rowSpacing={1}>
                <Grid2 size={{ xs: 12 }}>
                  <Subheader>{getOptionalDashConcatenation(viewModel.courseTitle, viewModel.section)}</Subheader>
                  <WorkDescriptionCard viewModel={viewModel} />
                </Grid2>

                <Grid2 size={{ xs: 12, sm: showGrade || showPlannedWorks ? 7 : 12 }}>
                  <WorkDetailsStepsList viewModel={viewModel.stepsViewModel} sx={{ height: '100%' }} />
                </Grid2>

                {showGrade && (
                  <Grid2 size={{ xs: 12, sm: 5 }}>
                    <WorkDetailsGradeCard viewModel={viewModel.gradeViewModel} sx={{ height: '100%' }} />
                  </Grid2>
                )}

                {showPlannedWorks && (
                  <Grid2 size={{ xs: 12, sm: 5 }}>
                    <WorkDetailsNextPlannedWorkCard
                      sx={{ height: '100%' }}
                      viewModel={viewModel.plannedWorksViewModel}
                      disabled={viewModel.isApplying}
                    />
                  </Grid2>
                )}
              </Grid2>
              {/* Attachments section */}
              {viewModel.attachments.length > 0 && (
                <Box
                  sx={{
                    mt: 2
                  }}
                >
                  <WorkAttachmentList attachments={viewModel.attachments} />
                </Box>
              )}
            </Box>
          );
        }}
      />
      {(state.workDetailsShowWorkEdit ?? false) && (
        <WorkEditDialog isOpen={true} workId={workId} locationStateKey="workDetailsShowWorkEdit" />
      )}
      {showCancelWorkConfirmAlert && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.cancelWorkConfirmationTitle}
          message={strings.cancelWorkConfirmationMessage}
          confirmButtonLabel={strings.cancelWorkConfirmationButton}
          onSubmit={(hasConfirmed) => void dismissCancelWorkConfirmAlert(hasConfirmed)}
        />
      )}
      {viewModel.showNoExternalUrlAlert && (
        <AlertDialog
          isOpen={true}
          title={strings.noExternalUrlTitle}
          message={strings.noExternalUrlMessage}
          onSubmit={() => (viewModel.showNoExternalUrlAlert = false)}
        />
      )}
    </>
  );
});
