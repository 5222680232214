import { useActivePlannerId, useServices } from '@/hooks';
import { CalendarFilters, comparePlannerCourseSectionDetails } from '@/models';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PopoverProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import LocalizedStrings from 'strings';
import { getProperty } from '../../../../utils';
import { OptionsPopover, OptionsPopoverItem, OptionsPopoverSection } from '../../../utils';
import { UserDashboardCalendarFiltersCourseItem } from './UserDashboardCalendarFiltersCourseItem';

export interface UserDashboardCalendarFiltersPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
  courseSections: CourseSectionDetails[];
  getFilters: () => CalendarFilters;
  setFilters: (filters: CalendarFilters) => void;
  resetFilter: () => void;
  getSelectedCourseSectionIds: (dashboardId: string) => Promise<string[] | undefined>;
  setSelectedCourseSectionIds: (ids: string[] | undefined, dashboardId: string) => void;
}

export const UserDashboardCalendarFiltersPopover = observer(
  ({
    courseSections,
    getFilters,
    setFilters,
    resetFilter,
    getSelectedCourseSectionIds,
    setSelectedCourseSectionIds,
    ...props
  }: UserDashboardCalendarFiltersPopoverProps) => {
    const { localization } = useServices();
    const plannerId = useActivePlannerId();
    const filters = getFilters();
    const [courseIds, setCourseIds] = useState<string[] | undefined>();
    const strings = LocalizedStrings.calendar.filters;

    useEffect(() => {
      const getCourseIds = async () => {
        const ids = await getSelectedCourseSectionIds(plannerId);
        setCourseIds(ids);
      };

      void getCourseIds();
    }, [plannerId]);

    function toggleFilter(name: keyof CalendarFilters) {
      const newFilters: CalendarFilters = { ...getFilters() };
      Object.assign(newFilters, { [name]: !getProperty(filters, name) });
      setFilters(newFilters);
    }

    function toggleVisibilityOfCourseSection(courseId: string) {
      if (courseIds == null) {
        const allIds = courseSections.map((c) => c.courseSection!.id);
        const otherIds = allIds.filter((id) => id !== courseId);
        setCourseIds(otherIds);
        setSelectedCourseSectionIds(otherIds, plannerId);
      } else {
        const newValues = Array.from(courseIds);
        const idIndex = courseIds.indexOf(courseId);

        if (idIndex < 0) {
          newValues.push(courseId);
        } else {
          newValues.splice(idIndex, 1);
        }

        setCourseIds(newValues);
        setSelectedCourseSectionIds(newValues, plannerId);
      }
    }

    function resetFilters() {
      resetFilter();
      setSelectedCourseSectionIds(undefined, plannerId);
      setCourseIds(undefined);
    }

    return (
      <OptionsPopover {...props} title={strings.title()} onResetOptions={resetFilters}>
        <OptionsPopoverSection>
          <OptionsPopoverItem
            title={strings.specialDaysOption()}
            isSelected={filters.annotations}
            onClick={() => toggleFilter('annotations')}
          />
          <OptionsPopoverItem
            title={strings.calendarEventsOption()}
            isSelected={filters.calendarEvents}
            onClick={() => toggleFilter('calendarEvents')}
          />
          <OptionsPopoverItem
            title={strings.coursesOption()}
            isSelected={filters.periods}
            onClick={() => toggleFilter('periods')}
          />
          <OptionsPopoverItem
            title={strings.dueWorksOption()}
            isSelected={filters.works}
            onClick={() => toggleFilter('works')}
          />
          <OptionsPopoverItem
            title={strings.plannedWorksOption()}
            isSelected={filters.plannedWorks}
            onClick={() => toggleFilter('plannedWorks')}
          />

          <OptionsPopoverItem
            title={strings.notesOption()}
            isSelected={filters.notes}
            onClick={() => toggleFilter('notes')}
          />
        </OptionsPopoverSection>

        {courseSections.length > 0 && (
          <OptionsPopoverSection title={strings.coursesListSectionTitle()}>
            {courseSections
              .sort((cs1, cs2) => comparePlannerCourseSectionDetails(cs1, cs2, localization.currentLocale))
              .map((courseSection) => {
                const { id, title, section, color } = courseSection.courseSection!;

                return (
                  <UserDashboardCalendarFiltersCourseItem
                    key={id}
                    title={title}
                    subtitle={section}
                    isSelected={courseIds == null || courseIds.includes(id)}
                    onClick={() => toggleVisibilityOfCourseSection(id)}
                    color={color}
                  />
                );
              })}
          </OptionsPopoverSection>
        )}
      </OptionsPopover>
    );
  }
);
