import { PlannerListKind } from '@/models';
import { PlannerPlanningViewModel } from '@/viewmodels';
import { Box, BoxProps, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { PlannerDoneList, PlannerInboxList, PlannerTodayList, PlannerUpcomingList } from './lists';

export interface PlannerPlanningListsPageProps extends BoxProps {
  className?: string;
  viewModel: PlannerPlanningViewModel;
  currentListKind: PlannerListKind;
  setCurrentListKind: (kind: PlannerListKind) => void;
}

export const PlannerPlanningListsPage = observer(
  ({ className, viewModel, currentListKind, setCurrentListKind, ...props }: PlannerPlanningListsPageProps) => {
    const currentPage = useMemo(
      () => getCurrentPage(viewModel, currentListKind, setCurrentListKind),
      [currentListKind]
    );

    return (
      <Root {...props} className={className}>
        {currentPage}
      </Root>
    );
  }
);

const getCurrentPage = (
  viewModel: PlannerPlanningViewModel,
  currentListKind: PlannerListKind,
  setCurrentListKind: (kind: PlannerListKind) => void
) => {
  switch (currentListKind) {
    case 'inbox':
      return (
        <PlannerInboxList
          viewModel={viewModel.inboxViewModel}
          goToTodayList={() => setCurrentListKind('today')}
          className={classes.list}
        />
      );

    case 'today':
      return (
        <PlannerTodayList
          viewModel={viewModel.todayViewModel}
          goToInbox={() => setCurrentListKind('inbox')}
          className={classes.list}
        />
      );

    case 'upcoming':
      return <PlannerUpcomingList viewModel={viewModel.upcomingViewModel} className={classes.list} />;

    case 'done':
      return <PlannerDoneList viewModel={viewModel.doneViewModel} className={classes.list} />;

    default:
      return null;
  }
};

const PREFIX = 'MainScreenPlannerPage';
const classes = {
  list: `${PREFIX}-list`
};

const Root = styled((props: BoxProps) => <Box {...props} />)(() => ({
  [`& .${classes.list}`]: {
    height: '100%'
  }
}));
