import { plannerHasAccessKindsForUser, workIsLate } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, LocalizationService } from '@/services';
import { PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { captureException } from '@sentry/react';
import { add, differenceInCalendarDays, isSameDay } from 'date-fns';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface WorkDetailsDueDateViewModel {
  readonly isReadOnly: boolean;
  readonly dueDate: Date | undefined;
  readonly hasDueDate: boolean;
  readonly isDueAllDay: boolean;
  readonly isLate: boolean;
  readonly isDueToday: boolean;
  readonly isDueTomorrow: boolean;
  readonly remainingDays: number | undefined;
  readonly isCompleted: boolean;
  readonly isCancelled: boolean;
  readonly isStatusChangedExternally: boolean;
  readonly isApplying: boolean;
  error?: string;
  showNoExternalUrlAlert: boolean;

  completeWork(): Promise<void>;
}

export class AppWorkDetailsDueDateViewModel implements WorkDetailsDueDateViewModel {
  @observable private _isApplying = false;
  @observable private _error: string | undefined;
  @observable private _showNoExternalUrlAlert = false;

  constructor(
    private readonly _work: () => Work,
    private readonly _onSuccess: () => Promise<void>,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
  }

  @computed
  private get work() {
    return this._work();
  }

  @computed
  get isReadOnly(): boolean {
    const planner = this._userStore.getPlannerForId(this.work.plannerId);

    if (planner == null) {
      return true;
    }

    return !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get dueDate(): Date | undefined {
    return this.work.dueTime?.toDate();
  }

  @computed
  get isDueAllDay(): boolean {
    return this.work.isDueAllDay;
  }

  @computed
  get hasDueDate(): boolean {
    return this.dueDate != null;
  }

  @computed
  get isLate(): boolean {
    return workIsLate(this.work, this._dateService);
  }

  @computed
  get isDueToday(): boolean {
    if (this.dueDate == null) {
      return false;
    }

    return isSameDay(this.dueDate, this._dateService.now);
  }

  @computed
  get isDueTomorrow(): boolean {
    if (this.dueDate == null) {
      return false;
    }

    const tomorrow = add(this._dateService.now, { days: 1 });
    return isSameDay(this.dueDate, tomorrow);
  }

  @computed
  get remainingDays(): number | undefined {
    return this.hasDueDate ? differenceInCalendarDays(this.dueDate!, this._dateService.now) : undefined;
  }

  @computed
  get isCompleted(): boolean {
    return this.work.status === WorkStatus.COMPLETED;
  }

  @computed
  get isCancelled(): boolean {
    return this.work.status === WorkStatus.CANCELLED;
  }

  @computed
  get isStatusChangedExternally(): boolean {
    return this.work.isStatusChangedExternally;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  set error(value: string | undefined) {
    this._error = value;
  }

  @computed
  get showNoExternalUrlAlert(): boolean {
    return this._showNoExternalUrlAlert;
  }

  set showNoExternalUrlAlert(value: boolean) {
    this._showNoExternalUrlAlert = value;
  }

  @action
  async completeWork() {
    if (this.isStatusChangedExternally) {
      if (this.work.externalSource?.url != null) {
        window.open(this.work.externalSource.url, '_blank', 'noreferrer');
      } else {
        this.showNoExternalUrlAlert = true;
      }

      return;
    }

    this._isApplying = true;
    this._error = undefined;

    try {
      await this._workStore.setWorkStatus(this.work.id, WorkStatus.COMPLETED, this.work.syncToken);
      await this._plannerStore.fetchPlannerContents(this.work.plannerId);
      await this._onSuccess();
    } catch (e) {
      captureException(e);
      const error = e as Error;
      const strings = this._localization.localizedStrings.work.details;
      runInAction(() => (this._error = strings.completeErrorMessage(error.message)));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }
}
