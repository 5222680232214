import { RouteObject } from 'react-router-dom';
import { SchoolCourseSectionDetailsColumns, StudentsPlannerSummary } from '../components';
import { SchoolCourseSectionStudentRoutes } from './SchoolCourseSectionStudentRoutes';

export const SchoolCourseSectionRoutes: RouteObject[] = [
  { path: 'list', element: <StudentsPlannerSummary sx={{ flex: 1 }} smallScreenWidth={'md'} /> },
  {
    path: '',
    element: <SchoolCourseSectionDetailsColumns sx={{ flex: 1 }} />,
    children: SchoolCourseSectionStudentRoutes
  }
];
