import { ConnectedAppService } from '@/services';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { SyncRounded } from '@mui/icons-material';
import { ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import LocalizedStrings from 'strings';
import { PlannerNewSchoolsClassroomConnectionState } from './PlannerNewSchoolsClassroomConnectionState';

export interface PlannerNewSchoolsClassroomConnectionSchoolItemProps {
  sx?: SxProps;
  className?: string;
  school: SchoolInformation;
  connectedAppService: ConnectedAppService<string>;
}

export const PlannerNewSchoolsClassroomConnectionSchoolItem = observer(
  ({ sx = [], className, school, connectedAppService }: PlannerNewSchoolsClassroomConnectionSchoolItemProps) => {
    const [connectError, setIsConnectError] = useState<Error>();
    const strings = LocalizedStrings.planner.newSchools.classroomConnection;

    const label =
      school.school!.subtitle.length > 0 ? `${school.school!.name} (${school.school!.subtitle})` : school.school!.name;

    const owners = school.owners.map((o) => o.fullName).join(', ');

    useEffect(() => {
      void connectedAppService.refreshSyncStatus();
    }, []);

    return (
      <Stack sx={sx} className={className}>
        <ListItem disablePadding>
          <ListItemText primary={label} secondary={LocalizedStrings.planner.newSchools.schoolOwners(owners)} />

          <PlannerNewSchoolsClassroomConnectionState
            isCompact
            onConnectErrorChange={setIsConnectError}
            service={connectedAppService}
          />
        </ListItem>
        {connectError != null && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.error.main
            }}
          >
            {strings.errorMessage()}
          </Typography>
        )}
        {connectedAppService.isSyncing && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.text.secondary,
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <SyncRounded fontSize="inherit" color="inherit" sx={{ mr: 0.5 }} />
            {strings.fetchingDataMessage()}
          </Typography>
        )}
      </Stack>
    );
  }
);
