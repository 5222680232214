import { ClassroomSyncBlocker } from '@/models';
import { ServiceContainer } from '@/providers';
import { ClassroomConnectedAppService, LocalizationService } from '@/services';
import { PlannerDataStore } from '@/stores';
import { computed, makeObservable } from 'mobx';
import {
  AppConnectedAppUserDashboardViewModel,
  ConnectedAppUserDashboardViewModel
} from '../ConnectedAppUserDashboardViewModel';

export type ClassroomConnectedAppViewModel = ConnectedAppUserDashboardViewModel;

export class AppClassroomConnectedAppViewModel
  extends AppConnectedAppUserDashboardViewModel<ClassroomSyncBlocker>
  implements ClassroomConnectedAppViewModel
{
  constructor(
    connectedApp: ClassroomConnectedAppService,
    plannerId: string,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(connectedApp, plannerId, plannerStore);

    makeObservable(this);
  }

  @computed
  get isEntitled(): boolean {
    return !this._connectedApp.allSyncBlockers.includes('insufficient-entitlements');
  }

  @computed
  get warnings(): string[] {
    const strings = this._localization.localizedStrings.connectedApps;
    return this._connectedApp.warningSyncBlockers.map(strings.classroomSyncBlockerError);
  }
}
