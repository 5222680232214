import { DraggedItem } from '@/contexts';
import { useDragContext } from '@/hooks';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  Modifier,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { ReactNode } from 'react';

export interface DndContainerProps {
  children: ReactNode;
  onDrop: (droppableId: string, item: DraggedItem) => void;
  modifiers?: Modifier[];
}

export function DndContainer({ children, onDrop, modifiers }: DndContainerProps) {
  const { item, setItem } = useDragContext();

  function handleDragStart(event: DragStartEvent) {
    const draggedItem = event.active;
    setItem({ id: draggedItem.id as string, type: draggedItem.data.current?.type as string });
  }

  function handleDragEnd(event: DragEndEvent) {
    if (item == null || event.over == null) {
      return;
    }

    const droppableId = event.over.id as string;
    onDrop(droppableId, item);
    setItem(undefined);
  }

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 5 pixels before activating.
    // Fixes issues with click events inside draggable elements.
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement.
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <DndContext
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={() => setItem(undefined)}
      modifiers={modifiers}
      sensors={sensors}
    >
      {children}
    </DndContext>
  );
}
