import { ImportanceLevel } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/importance_level_pb';
import { PlanRecurrenceKind } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/plan_recurrence_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { StudentEmotionalStatus } from './StudentEmotionalStatus';

export type AuthProvider = 'google' | 'apple' | 'microsoft';
export type ConnectedAppKind = 'classroom' | 'googleCalendar';
export const AllConnectedAppKinds: ConnectedAppKind[] = ['classroom', 'googleCalendar'];

// prettier-ignore
export type PlannerListKind =
  'inbox' |
  'today' |
  'upcoming' |
  'done' |
  'cancelled' |
  'unspecified';

// prettier-ignore
export type ClassroomSyncBlocker =
  'internal-error' |
  'no-google-offline-access' |
  'missing-google-access-scopes' |
  'insufficient-entitlements' |
  'no_classroom_access' |
  'unexpected-error' |
  'unspecified';

// prettier-ignore
export type GoogleCalendarSyncBlocker =
  'missing-google-access-scopes' |
  'no-google-offline-access' |
  'internal-error' |
  'unexpected-error' |
  'unspecified';

export type StudentConnectionState = 'connected' | 'connected-no-access' | 'not-connected';

// prettier-ignore
export const AllPlannerListKind: PlannerListKind[] = [
  'inbox',
  'today',
  'upcoming',
  'done',
  'cancelled',
  'unspecified'
];

export const AllUserPersonas: UserPersona[] = [
  UserPersona.STUDENT,
  UserPersona.PARENT,
  UserPersona.TEACHER,
  UserPersona.OTHER,
  UserPersona.UNSPECIFIED
];

export const AllFulfilledUserPersonas: UserPersona[] = [UserPersona.STUDENT, UserPersona.PARENT, UserPersona.TEACHER];

export const AllFulfilledPlanRecurrenceKind: PlanRecurrenceKind[] = [
  PlanRecurrenceKind.PLAN_KIND_MONTHLY,
  PlanRecurrenceKind.PLAN_KIND_YEARLY
];

export const AllFulfilledImportanceLevel: ImportanceLevel[] = [
  ImportanceLevel.REMINDER,
  ImportanceLevel.REGULAR,
  ImportanceLevel.MAJOR,
  ImportanceLevel.CRITICAL
];

// TODO: Temporary until the API is available
export interface StudentEmotionalStatusEntry {
  readonly status: StudentEmotionalStatus;
  readonly date: Date;
}
