export type SearchablePropertyMethod = 'contains' | 'exact' | 'startsWith';

export interface SearchableProperty {
  value: string;
  /// Defaults to 'contains'
  method?: SearchablePropertyMethod;
  /// Defaults to false
  caseSensitive?: boolean;
}

function isSearchableProperty(value: string | SearchableProperty | undefined): value is SearchableProperty {
  return value != null && (value as SearchableProperty).value != null;
}

export function propertiesHaveMatchForSearchText(
  searchText: string,
  properties: (string | SearchableProperty | undefined)[]
) {
  return properties.some((p) => propertyMatchesSearchText(searchText, p));
}

export function propertyMatchesSearchText(searchText: string, property: string | SearchableProperty | undefined) {
  if (property == null) {
    return false;
  }

  let value: string;
  let isCaseSensitive = false;
  let method: SearchablePropertyMethod = 'contains';

  if (isSearchableProperty(property)) {
    value = property.value;
    isCaseSensitive = property.caseSensitive ?? isCaseSensitive;
    method = property.method ?? method;
  } else {
    value = property;
  }

  const resolvedValue = isCaseSensitive ? value : value.toLowerCase();
  const resolvedSearchText = isCaseSensitive ? searchText : searchText.toLowerCase();

  switch (method) {
    case 'exact':
      return resolvedValue === resolvedSearchText;

    case 'startsWith':
      return resolvedValue.startsWith(resolvedSearchText);

    case 'contains':
      return resolvedValue.includes(resolvedSearchText);
  }
}
