import i18n from 'translate';

export const SchoolDetailsArchivedBannerStrings = {
  message: () => i18n.t('settings.school.details.archivedBanner.message'),
  title: () => i18n.t('settings.school.details.archivedBanner.title'),
  unarchiveButtonLabel: () => i18n.t('settings.school.details.archivedBanner.unarchiveButtonLabel'),
  unarchiveConfirmationMessage: (schoolName: string) =>
    i18n.t('settings.school.details.archivedBanner.unarchiveConfirmationMessage', { schoolName }),
  unarchiveConfirmationSubmit: () => i18n.t('settings.school.details.archivedBanner.unarchiveConfirmationSubmit'),
  unarchiveConfirmationTitle: () => i18n.t('settings.school.details.archivedBanner.unarchiveConfirmationTitle'),
  unarchiveError: () => i18n.t('settings.school.details.archivedBanner.unarchiveError')
};

export const SchoolDetailsClassesStrings = {
  subtitle: () => i18n.t('settings.school.details.classes.subtitle'),
  title: () => i18n.t('settings.school.details.classes.title')
};

export const SchoolDetailsArchiveDangerActionStrings = {
  actionTitle: () => i18n.t('settings.school.details.dangerActions.archive.actionTitle'),
  confirmationMessage: (schoolName: string) =>
    i18n.t('settings.school.details.dangerActions.archive.confirmationMessage', { schoolName }),
  confirmationSubmit: () => i18n.t('settings.school.details.dangerActions.archive.confirmationSubmit'),
  confirmationTitle: () => i18n.t('settings.school.details.dangerActions.archive.confirmationTitle'),
  errorMessage: () => i18n.t('settings.school.details.dangerActions.archive.errorMessage'),
  subtitle: () => i18n.t('settings.school.details.dangerActions.archive.subtitle'),
  title: () => i18n.t('settings.school.details.dangerActions.archive.title')
};

export const SchoolDetailsDangerActionsStrings = {
  archive: SchoolDetailsArchiveDangerActionStrings,
  title: () => i18n.t('settings.school.details.dangerActions.title')
};

export const SchoolDetailsGeneralInformationStrings = {
  nameInputLabel: () => i18n.t('settings.school.details.generalInformation.nameInputLabel'),
  nameInputPlaceholder: () => i18n.t('settings.school.details.generalInformation.nameInputPlaceholder '),
  subtitleInputLabel: () => i18n.t('settings.school.details.generalInformation.subtitleInputLabel'),
  subtitleInputPlaceholder: () => i18n.t('settings.school.details.generalInformation.subtitleInputPlaceholder'),
  title: () => i18n.t('settings.school.details.generalInformation.title')
};

export const SchoolDetailsStrings = {
  archivedBanner: SchoolDetailsArchivedBannerStrings,
  backButtonTitle: () => i18n.t('settings.school.details.backButtonTitle'),
  classes: SchoolDetailsClassesStrings,
  dangerActions: SchoolDetailsDangerActionsStrings,
  generalInformation: SchoolDetailsGeneralInformationStrings,
  notFoundSubtitle: () => i18n.t('settings.school.details.notFoundSubtitle'),
  notFoundTitle: () => i18n.t('settings.school.details.notFoundTitle'),
  schoolOwnedByCurrentUser: () => i18n.t('settings.school.details.schoolOwnedByCurrentUser'),
  schoolOwners: (owners: string) => i18n.t('settings.school.details.schoolOwners', { owners }),
  shareSchoolButton: () => i18n.t('settings.school.details.shareSchoolButton'),
  shareSchoolConfirmationMessage: (schoolName: string) =>
    i18n.t('settings.school.details.shareSchoolConfirmationMessage', { schoolName }),
  shareSchoolConfirmationScheduleOption: (scheduleName: string) =>
    i18n.t('settings.school.details.shareSchoolConfirmationScheduleOption', { scheduleName }),
  shareSchoolConfirmationSubmit: () => i18n.t('settings.school.details.shareSchoolConfirmationSubmit'),
  shareSchoolConfirmationTitle: () => i18n.t('settings.school.details.shareSchoolConfirmationTitle'),
  shareSchoolError: () => i18n.t('settings.school.details.shareSchoolError')
};

export const SchoolListLinkToPlannerStrings = {
  alreadyLinkSchoolLabel: () => i18n.t('settings.school.list.linkDialog.alreadyLinkSchoolLabel'),
  saveButtonTitle: () => i18n.t('settings.school.list.linkDialog.saveButtonTitle'),
  subtitle: () => i18n.t('settings.school.list.linkDialog.subtitle'),
  title: () => i18n.t('settings.school.list.linkDialog.title')
};

export const SchoolListStrings = {
  archivedSchoolTooltip: () => i18n.t('settings.school.list.archivedSchoolTooltip'),
  attachErrorAlertMessage: () => i18n.t('settings.school.list.attachErrorAlertMessage'),
  attachErrorAlertRetryButtonTitle: () => i18n.t('settings.school.list.attachErrorAlertRetryButtonTitle'),
  createNewSchool: () => i18n.t('settings.school.list.createNewSchool'),
  emptyMessage: () => i18n.t('settings.school.list.emptyMessage'),
  hiddenSchool: () => i18n.t('settings.school.list.hiddenSchool'),
  hideSchoolErrorMessage: () => i18n.t('settings.school.details.hideSchoolErrorMessage'),
  linkDialog: SchoolListLinkToPlannerStrings,
  schoolOwnedByCurrentUser: () => i18n.t('settings.school.list.schoolOwnedByCurrentUser'),
  schoolOwners: (owners: string) => i18n.t('settings.school.list.schoolOwners', { owners }),
  showSchoolErrorMessage: () => i18n.t('settings.school.details.showSchoolErrorMessage'),
  title: () => i18n.t('settings.school.list.title'),
  visibleSchool: () => i18n.t('settings.school.list.visibleSchool')
};

export const SchoolSettingsStrings = {
  details: SchoolDetailsStrings,
  list: SchoolListStrings
};
