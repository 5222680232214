import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../hooks';

export interface SettingsSaveButtonProps {
  sx?: SxProps;
  className?: string;
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  customTitle?: string;
}

export const SettingsSaveButton = observer(
  ({ sx = [], className, onClick, disabled, loading, customTitle }: SettingsSaveButtonProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    return (
      <LoadingButton
        sx={{ ...sx, minWidth: 150 }}
        className={className}
        color="primary"
        variant="contained"
        onClick={() => onClick()}
        disabled={disabled}
        loading={loading}
      >
        {customTitle ?? strings.genericSave}
      </LoadingButton>
    );
  }
);
