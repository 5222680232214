export type Locale = 'en' | 'fr';
export const SupportedLocales: Locale[] = ['en', 'fr'];

// These should NOT be localized.
export const LocaleTitles: Record<Locale, string> = {
  en: 'English',
  fr: 'Français'
};

export const LocaleFlags: Record<Locale, string> = {
  en: '🇺🇸',
  fr: '🇫🇷'
};

export function getSortedLocales(): Locale[] {
  return SupportedLocales.slice().sort((locale1, locale2) => (LocaleTitles[locale1] > LocaleTitles[locale2] ? 1 : -1));
}
