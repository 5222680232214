import { useActivePlannerId, useServices } from '@/hooks';
import { StudentsPlannerSummaryViewModel } from '@/viewmodels';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { isSxArray } from '../../../../utils';
import { List } from '../../../lists';
import { SearchBar } from '../../../utils';
import { StudentListItem } from './StudentListItem';

export interface StudentsPlannerSummaryListProps {
  sx?: SxProps;
  className?: string;
  viewModel: StudentsPlannerSummaryViewModel;
}

export const StudentsPlannerSummaryList = observer(
  ({ sx = [], className, viewModel }: StudentsPlannerSummaryListProps) => {
    const { localization, route } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;
    const plannerId = useActivePlannerId();
    const params = useParams();
    const schoolId = params.schoolId ?? '';

    return (
      <Stack
        className={className}
        direction="column"
        spacing={2}
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: '500',
            mx: 2
          }}
        >
          {strings.studentsListTitle}
        </Typography>
        <Box>
          <SearchBar onSearch={(searchText) => (viewModel.searchText = searchText ?? '')} sx={{ mx: 2 }} />
        </Box>
        <Box
          sx={{
            flex: 1
          }}
        >
          <List
            sections={[{ id: 'main', title: undefined, numberOfRows: viewModel.students.length }]}
            rowHeight={48}
            keyForItem={(_, row) => viewModel.students[row].id}
            renderItem={(_, row) => {
              const student = viewModel.students[row];
              const studentInsightsLocation = route.resolveSchoolStudentInsightsLocation(
                plannerId,
                schoolId,
                student.courseSectionId,
                student.id
              );

              return <StudentListItem viewModel={student} targetLocation={studentInsightsLocation} />;
            }}
          />
        </Box>
      </Stack>
    );
  }
);
