import { CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDayStateIndicator } from './UserDashboardCalendarDayStateIndicator';

export interface UserDashboardCalendarDayLoadingIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarDayLoadingIndicator = observer(
  ({ sx = [], className }: UserDashboardCalendarDayLoadingIndicatorProps) => {
    const strings = LocalizedStrings.calendar.day;
    return (
      <UserDashboardCalendarDayStateIndicator
        sx={sx}
        className={className}
        title={strings.dataFetchLoadingMessage()}
        renderIcon={(size) => <CircularProgress size={size} />}
      />
    );
  }
);
