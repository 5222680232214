import { SubscriptionsViewModel } from '@/viewmodels';
import { Button, Divider, Grid2, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Product } from './Product';

export interface SubscriptionsProductsViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: SubscriptionsViewModel;
}

export const SubscriptionsProductsView = observer(({ sx, viewModel, className }: SubscriptionsProductsViewProps) => {
  const strings = LocalizedStrings.subscriptions.manage;
  const theme = useTheme();

  return (
    <Grid2 container spacing={3} sx={sx} className={className}>
      <Grid2 size={{ xs: 12 }}>
        <Typography>{strings.message()}</Typography>
      </Grid2>

      {viewModel.products.map((product, _, ps) => (
        <Grid2
          key={`subscription-product-${product.id}`}
          size={{ xs: 12, sm: 6 }}
          offset={{ sm: ps.length === 1 ? 3 : 0 }}
        >
          <Product sx={{ height: '100%', width: '100%' }} viewModel={product} />
        </Grid2>
      ))}

      <Grid2 size={{ xs: 12, sm: 10 }} offset={{ sm: 1 }}>
        <Stack
          spacing={1}
          sx={{
            px: 2
          }}
        >
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              color: theme.palette.text.secondary
            }}
          >
            {strings.legalText()}
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              size="small"
              onClick={() => (viewModel.currency = 'usd')}
              color="inherit"
              sx={{ color: theme.palette.text.secondary }}
            >
              {`🇺🇸 ${strings.usdPriceOptionTitle()}`}
            </Button>

            <Divider orientation="vertical" sx={{ height: 22 }} />

            <Button
              size="small"
              onClick={() => (viewModel.currency = 'cad')}
              color="inherit"
              sx={{ color: theme.palette.text.secondary }}
            >
              {`🇨🇦 ${strings.cadPriceOptionTitle()}`}
            </Button>
          </Stack>
        </Stack>
      </Grid2>
    </Grid2>
  );
});
