import { EventRepeatRounded, TodayRounded, ViewWeekRounded } from '@mui/icons-material';

export function iconForSpecialDayOccurrence(kind: 'day' | 'cycleDay' | 'dayOfWeek') {
  switch (kind) {
    case 'cycleDay':
      return <EventRepeatRounded />;
    case 'dayOfWeek':
      return <ViewWeekRounded />;
    case 'day':
      return <TodayRounded />;
  }
}
