import { useServices } from '@/hooks';
import { EditableClassroomConnectedAppViewModel } from '@/viewmodels';
import EmptyIcon from '@mui/icons-material/AccountCircleRounded';
import { List } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { EmptyListIndicator, Subheader } from '../../utils';
import { ClassroomCourse } from './ClassroomCourse';

export interface EditableClassroomConnectedAppProps {
  viewModel: EditableClassroomConnectedAppViewModel;
  className?: string;
  sx?: SxProps;
}

export const EditableClassroomConnectedApp = observer(
  ({ viewModel, className, sx }: EditableClassroomConnectedAppProps) => {
    const { localization, userStore } = useServices();
    const strings = localization.localizedStrings.connectedApps;
    const school =
      viewModel.userDashboard.case === 'plannerSchool'
        ? userStore.getSchoolForId(viewModel.userDashboard.schoolId)
        : undefined;

    return (
      <List className={className} sx={sx}>
        {viewModel.courses.length === 0 ? (
          <EmptyListIndicator
            subtitle={strings.noClassroomCoursesSubtitle}
            renderIcon={(className) => <EmptyIcon className={className} />}
          />
        ) : (
          <>
            <Subheader>{strings.classroomCourseListTitle(school?.school?.name, school?.school?.subtitle)}</Subheader>
            {viewModel.courses.map((course, i) => (
              <ClassroomCourse key={`classroom-course-${i}`} viewModel={course} disabled={viewModel.isApplying} />
            ))}
          </>
        )}
      </List>
    );
  }
);
