import { SchoolTransportService } from '@/transports';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export type SchoolCourseSectionsLoadable = LoadableMap<CourseSection>;

export class AppSchoolCourseSectionsLoadable
  extends BaseLoadableMap<CourseSection>
  implements SchoolCourseSectionsLoadable
{
  constructor(
    private readonly _schoolId: string,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<Map<string, CourseSection>> {
    const courseSections = await this._schoolTransport.getSchoolCourseSections(this._schoolId);
    return courseSections.reduce((value, c) => value.set(c.id, c), new Map<string, CourseSection>());
  }
}
