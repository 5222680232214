import { ServiceContainer } from '@/providers';
import { Loadable, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable } from 'mobx';
import { plannerHasAccessKindsForUser } from '../../models';
import { FeatureFlagService, LocalizationService } from '../../services';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface PlannerSchoolsListViewModel extends UpdatableViewModel {
  readonly isReadOnly: boolean;
  readonly schools: SchoolInformation[];
  readonly canAddSchool: boolean;
  readonly hasNotLinkedSchools: boolean;
  attachSchoolToPlanner(schoolId: string): Promise<void>;
}

export class AppPlannerSchoolsListViewModel extends BaseUpdatableViewModel implements PlannerSchoolsListViewModel {
  constructor(
    private readonly _plannerId: string,
    private readonly _featureFlag: FeatureFlagService = ServiceContainer.services.featureFlag,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super();
    makeObservable(this);
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this._userStore.participatingSchools];
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  private get userId(): string {
    return this._userStore.user.userId;
  }

  @computed
  get schools(): SchoolInformation[] {
    return this._userStore.participatingSchools.values.toSorted((s1, s2) => {
      if (s1.school!.isArchived !== s2.school!.isArchived) {
        // Displaying archived schools last
        return s1.school!.isArchived ? 1 : -1;
      }

      const s1IsConnected = s1.connectedPlannerIds.includes(this._plannerId);
      const s2IsConnected = s2.connectedPlannerIds.includes(this._plannerId);

      if (s1IsConnected != s2IsConnected) {
        return s1IsConnected ? -1 : 1;
      }

      const locale = this._localization.currentLocale;
      return s1
        .school!.name.toLocaleLowerCase(locale)
        .localeCompare(s2.school!.name.toLocaleLowerCase(locale), locale, { sensitivity: 'base' });
    });
  }

  @computed
  get isReadOnly(): boolean {
    return !plannerHasAccessKindsForUser(this.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get canAddSchool(): boolean {
    return (
      this.hasData &&
      !this.isReadOnly &&
      [UserPersona.TEACHER, UserPersona.OTHER, UserPersona.UNSPECIFIED].includes(this._userStore.user.persona) &&
      (this._featureFlag.isEnabled('shared-schools') || this.schools.length === 0)
    );
  }

  @computed
  get hasNotLinkedSchools(): boolean {
    return this._userStore.participatingSchools.values.some(
      (school) => !school.connectedPlannerIds.includes(this._plannerId)
    );
  }

  async attachSchoolToPlanner(schoolId: string): Promise<void> {
    await this._userStore.attachSchoolToPlanner(schoolId, this._plannerId, true);
  }
}
