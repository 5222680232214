import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { UserDashboardKind } from '@/models';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { AddRounded } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverOrigin,
  PopoverPosition,
  Stack,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CreatePlannerDialog } from '../../planner';
import { CreateSchoolDialog } from '../../school';
import { Subheader } from '../../utils';
import { UserDashboardAddMenu } from './UserDashboardAddMenu';
import { UserDashboardPickerPlanner } from './UserDashboardPickerPlanner';

interface LocationState {
  userDashboardPickerShowCreatePlanner?: string;
  userDashboardPickerShowCreateSchool?: string;
}

export interface UserDashboardPickerProps {
  id: string;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  anchorOrigin: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  transformOrigin?: PopoverOrigin;
  elevation?: number;
}

export const UserDashboardPicker = observer(
  ({
    id,
    isOpen,
    onClose,
    anchorEl,
    anchorOrigin,
    anchorPosition,
    transformOrigin,
    elevation
  }: UserDashboardPickerProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    const plannerId = useActivePlannerId();
    const [showAddMenu, setShowAddMenu] = useState(false);
    const createDashboardOptionRef = useRef<HTMLDivElement | null>(null);

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();

    const viewModel = useViewModel(
      (viewModels) => viewModels.createUserDashboardPickerViewModel(plannerId),
      [plannerId]
    );

    function renderPlannerItem(planner: Planner, isSelected: boolean) {
      return (
        <UserDashboardPickerPlanner
          key={planner.id}
          id={planner.id}
          title={planner.title}
          onSelect={onClose}
          isSelected={isSelected}
        />
      );
    }

    function onCreateOptionClick() {
      if (viewModel.canCreateSchool) {
        setShowAddMenu(true);
      } else {
        onClose();
        const newState: LocationState = { ...state, userDashboardPickerShowCreatePlanner: id };
        navigate(location, { state: newState });
      }
    }

    function onCreateDashboardClick(kind: UserDashboardKind) {
      setShowAddMenu(false);
      onClose();

      const newState: LocationState = {
        ...state,
        userDashboardPickerShowCreatePlanner: kind === 'planner' ? id : undefined,
        userDashboardPickerShowCreateSchool: kind === 'school' ? id : undefined
      };

      navigate(location, { state: newState });
    }

    return (
      <>
        <Popover
          open={isOpen}
          elevation={elevation}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          anchorPosition={anchorPosition}
          transformOrigin={transformOrigin}
          onClose={() => onClose()}
        >
          <Stack
            spacing={1}
            sx={{
              p: 2,
              width: 320
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: '500'
              }}
            >
              {strings.userDashboardPickerTitle}
            </Typography>

            {renderPlannerItem(viewModel.currentPlanner, true)}

            {viewModel.otherPlanners.length > 0 && (
              <Stack>
                <Subheader>{strings.userDashboardPickerOtherDashboards}</Subheader>

                {viewModel.otherPlanners.map((planner) => renderPlannerItem(planner, false))}
              </Stack>
            )}

            <ListItemButton sx={{ borderRadius: 1 }} dense onClick={onCreateOptionClick} ref={createDashboardOptionRef}>
              <ListItemIcon>
                <AddRounded />
              </ListItemIcon>
              <ListItemText>
                {viewModel.canCreateSchool ? strings.createDashboardMenuLabel : strings.createPlannerMenuLabel}
              </ListItemText>
            </ListItemButton>
          </Stack>
        </Popover>
        <UserDashboardAddMenu
          anchorEl={createDashboardOptionRef.current}
          isOpen={showAddMenu}
          onCreate={onCreateDashboardClick}
          onClose={() => setShowAddMenu(false)}
        />
        {state.userDashboardPickerShowCreatePlanner === id && <CreatePlannerDialog isOpen={true} />}
        {state.userDashboardPickerShowCreateSchool === id && <CreateSchoolDialog isOpen={true} />}
      </>
    );
  }
);
