import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { useActivePlannerId, useServices } from '../../hooks';
import { plannerHasAccessKindsForUser } from '../../models';
import { FeatureFlagService } from '../../services';
import { SettingsItem, SettingsLayout, SettingsSection, generalSettingsSection } from '../shared';

function plannerSettingsSections(
  currentPersona: UserPersona,
  hasFullAccess: boolean,
  featureFlag: FeatureFlagService
): SettingsSection[] {
  const strings = LocalizedStrings.settings.sidebar.planner;

  const items: SettingsItem[] = [
    { link: 'edit', title: strings.edit() },
    { link: 'courses', title: strings.courses() },
    { link: 'curriculum-courses', title: strings.curriculumCourses() },
    { link: 'schedules', title: strings.schedule() },
    { link: 'access', title: strings.access() },
    { link: 'connected-apps', title: strings.connectedApps() }
  ];

  if (hasFullAccess && (featureFlag.isEnabled('shared-schools') || currentPersona === UserPersona.TEACHER)) {
    items.push({ link: 'schools', title: strings.schools() });
  }

  return [{ title: strings.generalSettingsSectionTitle(), items }, generalSettingsSection()];
}

export const PlannerSettings = observer(() => {
  const { featureFlag, user, userStore } = useServices();
  const plannerId = useActivePlannerId();
  const planner = useMemo(() => userStore.getPlannerForId(plannerId)!, [plannerId]);
  const userId = userStore.user.userId;
  const hasFullAccess = useMemo(
    () => plannerHasAccessKindsForUser(userId, planner, AccessKind.FULL_ACCESS),
    [userId, planner]
  );

  return (
    <SettingsLayout
      sections={plannerSettingsSections(
        user.currentUser?.persona ?? UserPersona.UNSPECIFIED,
        hasFullAccess,
        featureFlag
      )}
    />
  );
});
