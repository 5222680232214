import { isDemoId, plannerHasRelationshipKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsStorage, LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { chain } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';

export interface StudentPlannerInfo {
  readonly plannerId: string;
  readonly name: string;
  readonly email: string;
  readonly profileUrl: string | undefined;
  readonly emotionalStatusEmoji: string | undefined;
}

export interface StudentPlannerPickerViewModel extends StaticDialogViewModel {
  readonly planners: StudentPlannerInfo[];
  searchText: string;
}

export class AppStudentPlannerPickerViewModel
  extends AppBaseStaticDialogViewModel
  implements StudentPlannerPickerViewModel
{
  @observable private _searchText = '';

  constructor(
    onClose: () => Promise<void>,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _settingsStorage: ApplicationSettingsStorage = ServiceContainer.services.settingsStorage
  ) {
    super(onClose);
    makeObservable(this);
  }

  @computed
  private get allPlanners(): StudentPlannerInfo[] {
    const strings = this._localization.localizedStrings.utils;
    const userId = this._userStore.user.userId;

    return chain(this._userStore.planners)
      .filter(
        (planner) =>
          plannerHasRelationshipKindsForUser(userId, planner, PlannerRelationshipKind.TEACHER) &&
          (this._settingsStorage.isDemoMode === true || !isDemoId(planner.id))
      )
      .map<StudentPlannerInfo>((p) => {
        return {
          plannerId: p.id,
          email: p.ownerDetails?.emailAddress ?? strings.studentPlannerPickerNoOwnerEmail,
          name: p.ownerDetails?.fullName ?? strings.studentPlannerPickerNoOwnerName,
          profileUrl: p.ownerDetails?.pictureUrl,
          // TODO: Should be based on student answer for current day, if one exists
          emotionalStatusEmoji: ''
        };
      })
      .sort((p1, p2) =>
        p1.name.toLocaleLowerCase().localeCompare(p2.name, this._localization.currentLocale, { sensitivity: 'base' })
      )
      .value();
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get planners(): StudentPlannerInfo[] {
    if (this._searchText.length > 0) {
      const searchText = this._searchText.toLocaleLowerCase(this._localization.currentLocale);

      return this.allPlanners.filter((p) => {
        const name = p.name.toLocaleLowerCase(this._localization.currentLocale);
        const email = p.email.toLocaleLowerCase(this._localization.currentLocale);
        return name.includes(searchText) || email.includes(searchText);
      });
    }

    return this.allPlanners;
  }
}
