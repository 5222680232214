import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { computed, makeObservable } from 'mobx';
import { PlannerTransportService } from '../../transports';
import { BaseLoadableMap, LoadableMap } from './Loadable';

export interface PlannerDetailedCourseSectionsLoadable extends LoadableMap<CourseSectionDetails> {
  visibleCourseSections: CourseSectionDetails[];
}

export class AppPlannerDetailedCourseSectionsLoadable
  extends BaseLoadableMap<CourseSectionDetails>
  implements PlannerDetailedCourseSectionsLoadable
{
  constructor(
    private readonly _plannerId: string,
    private readonly _plannerTransport: PlannerTransportService
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get visibleCourseSections(): CourseSectionDetails[] {
    return this.values.filter((cs) => cs.courseSection?.isVisible ?? false);
  }

  protected async loadData(): Promise<Map<string, CourseSectionDetails>> {
    const courses = await this._plannerTransport.getDetailedCourseSectionsInPlanner(this._plannerId);
    return new Map<string, CourseSectionDetails>(courses.map((c) => [c.courseSection!.id, c]));
  }
}
