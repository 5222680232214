import { useActivePlannerId, useViewModel } from '@/hooks';
import { PublishedWorkEditInfo } from '@/models';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { Dialog } from '../../utils';
import { PublishedWorkEdit } from './PublishedWorkEdit';

export interface PublishedWorkEditProps {
  sx?: SxProps;
  className?: string;
  ids: { publishedWorkId: string; schoolId: string } | undefined;
  newPublishedWorkInfo?: Partial<PublishedWorkEditInfo>;
  isOpen: boolean;
}

export const PublishedWorkEditDialog = observer(
  ({ sx = [], className, ids, newPublishedWorkInfo, isOpen }: PublishedWorkEditProps) => {
    const plannerId = useActivePlannerId();
    const navigate = useNavigate();
    const strings = LocalizedStrings.publishedWork.edit;

    function onSuccess() {
      navigate(-1);
      return Promise.resolve();
    }

    function close() {
      navigate(-1);
      return Promise.resolve();
    }

    const viewModelRef = useRef(
      useViewModel((viewModels) =>
        viewModels.createPublishedWorkEditViewModel(ids, plannerId, newPublishedWorkInfo, onSuccess, close)
      )
    );
    const viewModel = viewModelRef.current;

    return (
      <Dialog
        sx={sx}
        className={className}
        isOpen={isOpen}
        canToggleFullscreen
        width="lg"
        viewModel={viewModel}
        title={ids == null ? strings.addTitle() : strings.editTitle()}
        fullScreenWidth="md"
        submit={() => void viewModel.save()}
        contentPadding={{ top: 2 }}
        renderData={() => <PublishedWorkEdit viewModel={viewModel} sx={{ width: '100%' }} />}
      />
    );
  }
);
