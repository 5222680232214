import { createApi, Language } from 'unsplash-js';
import { Basic } from 'unsplash-js/dist/methods/photos/types';
import { EnvironmentService, LocalizationService, UnsplashTransportService } from '../contracts';

export class AppUnsplashTransportService implements UnsplashTransportService {
  private readonly _unsplash;

  private get currentLanguage(): Language {
    switch (this._localization.currentLocale) {
      case 'en':
        return Language.English;
      case 'fr':
        return Language.French;
    }
  }

  constructor(
    private readonly _localization: LocalizationService,
    environment: EnvironmentService
  ) {
    this._unsplash = createApi({
      accessKey: environment.unsplashAccessKey
    });
  }

  async search(query: string, page?: number): Promise<Basic[]> {
    const response = await this._unsplash.search.getPhotos({ query, page, lang: this.currentLanguage });
    return response.response?.results ?? [];
  }

  async download(picture: Basic) {
    await this._unsplash.photos.trackDownload({ downloadLocation: picture.links.download_location });
  }
}
