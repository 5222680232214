import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { dateToPBDate } from '@/models';
import { Box, Grid2, Stack, styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { addDays, parse, startOfWeek } from 'date-fns';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BackgroundImageScreenPaper, DayTimesColumn, ScrollbarSpacer, UpdatablePresenter } from '../../../utils';
import { UserDashboardCalendarWeekDayColumn } from './UserDashboardCalendarWeekDayColumn';
import { UserDashboardCalendarWeekDayHeader } from './UserDashboardCalendarWeekDayHeader';
import { UserDashboardCalendarWeekHeader } from './UserDashboardCalendarWeekHeader';

export interface UserDashboardCalendarWeekViewProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarWeekView = observer(({ sx = [], className }: UserDashboardCalendarWeekViewProps) => {
  const { dateService, settings, storeInvalidator } = useServices();
  const plannerId = useActivePlannerId();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  const headerRowRef = useRef<HTMLDivElement | null>(null);
  const timesColumnRef = useRef<HTMLDivElement | null>(null);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  const viewModel = useViewModel(
    (viewModels) => viewModels.createUserDashboardCalendarWeekViewModel(plannerId),
    [plannerId]
  );

  const dateParam = searchParams.get('date');
  const currentDate = dateParam ? parse(dateParam, 'y-MM-dd', new Date()) : dateService.now;
  const firstDayOfWeek = startOfWeek(currentDate, { weekStartsOn: !settings.calendarWeekShowWeekends ? 1 : undefined });
  const numberOfDays = settings.calendarWeekShowWeekends ? 7 : 5;

  useEffect(() => {
    void viewModel.fetchDays(currentDate, false);
  }, [currentDate]);

  useEffect(() => {
    void viewModel.fetchDays(currentDate, true);
  }, [storeInvalidator.calendarRevision]);

  useSync('UserDashboardCalendarWeek', () => viewModel.fetchDays(currentDate, true), [plannerId, currentDate]);

  const gridRefCallback = useCallback((node: HTMLDivElement) => {
    if (node != null) {
      setIsScrolledToTop(node.scrollTop === 0);
      node.scrollTo({ top: viewModel.pointsPerHour * 8 - viewModel.pointsPerHour * 0.2 });
    }
  }, []);

  function onGridScroll(e: UIEvent<HTMLDivElement>) {
    const target = e.currentTarget;
    setIsScrolledToTop(target.scrollTop === 0);

    requestAnimationFrame(() => {
      timesColumnRef.current?.scrollTo({ top: target.scrollTop });
      headerRowRef.current?.scrollTo({ left: target.scrollLeft });
    });
  }

  return (
    <StyledWeekView sx={sx} className={className}>
      <Grid2 container className="containerGrid">
        <Grid2 size={{ xs: 12 }} className="containerGridItem">
          <BackgroundImageScreenPaper className="backgroundImagePaper">
            <UpdatablePresenter
              className="updatablePresenter"
              viewModel={viewModel}
              renderData={() => (
                <Box className="mainContainer">
                  <UserDashboardCalendarWeekHeader
                    currentDate={currentDate}
                    courseSections={viewModel.courseSections}
                    scheduleCycleWithDraftId={viewModel.scheduleCycleWithDraftId}
                    hasCalendarSyncError={viewModel.hasCalendarSyncError}
                    retryFetchData={() => viewModel.fetchDays(currentDate, true)}
                  />

                  <Stack
                    direction="row"
                    sx={{
                      flex: 1
                    }}
                  >
                    <Box className="mainGridContainer">
                      <Box className="gridContainer">
                        <Box className="grid">
                          {/* GRID HEADER */}
                          <Stack
                            className="gridHeader"
                            direction="row"
                            sx={{
                              boxShadow: isScrolledToTop ? undefined : theme.shadows['1']
                            }}
                          >
                            <Box className="gridHeaderPaddingLeftView" />

                            <Box className="gridHeaderDayHeadersContainer" ref={headerRowRef}>
                              {times(numberOfDays).map((i) => {
                                const date = addDays(firstDayOfWeek, i);
                                const day = dateToPBDate(date);
                                const dayViewModel = viewModel.getViewModelForDay(day);

                                return (
                                  <UserDashboardCalendarWeekDayHeader
                                    key={date.toString()}
                                    viewModel={dayViewModel}
                                    isLast={i === 6}
                                    className="dayHeader"
                                  />
                                );
                              })}
                            </Box>

                            <ScrollbarSpacer />
                          </Stack>

                          {/* GRID BODY */}
                          <Box className="gridBodyContainer">
                            <Box className="gridBody">
                              {/* TIMES COLUMN */}
                              <DayTimesColumn
                                ref={timesColumnRef}
                                pointsPerHour={viewModel.pointsPerHour}
                                sx={{ width: 46 }}
                              />

                              {/* DAY COLUMNS */}
                              <Box ref={gridRefCallback} onScroll={onGridScroll} className="dayColumnsContainer">
                                <Box className="dayColumnContainer" sx={{ height: viewModel.pointsPerHour * 24 }}>
                                  {times(numberOfDays).map((i) => {
                                    const date = addDays(firstDayOfWeek, i);
                                    const day = dateToPBDate(date);
                                    const dayViewModel = viewModel.getViewModelForDay(day);

                                    return (
                                      <UserDashboardCalendarWeekDayColumn
                                        key={i}
                                        pointsPerHour={viewModel.pointsPerHour}
                                        isFirstDay={i === 0}
                                        viewModel={dayViewModel}
                                        className="dayColumn"
                                        sx={{
                                          borderRight: i < 6 ? `1px solid ${theme.palette.divider}` : undefined
                                        }}
                                      />
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              )}
            />
          </BackgroundImageScreenPaper>
        </Grid2>
      </Grid2>
    </StyledWeekView>
  );
});

const StyledWeekView = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',

  '.containerGrid': {
    width: '100%',
    height: '100%'
  },

  '.containerGridItem': {
    height: '100%'
  },

  '.backgroundImagePaper': {
    height: '100%',
    overflow: 'hidden'
  },

  '.updatablePresenter': {
    height: '100%',
    width: '100%'
  },

  '.mainContainer': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },

  '.mainGridContainer': {
    position: 'relative',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  },

  '.gridContainer': {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },

  '.grid': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    position: 'static'
  },

  '.gridHeader': {
    position: 'static',
    width: '100%',
    zIndex: 100,
    flex: 'none',
    overflow: 'hidden'
  },

  '.gridHeaderPaddingLeftView': {
    width: 46,
    zIndex: 102
  },

  '.gridHeaderDayHeadersContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },

  '.dayHeader': {
    minWidth: 150,
    width: `${100 / 7}%`,
    height: '100%',
    flex: 1,
    flexShrink: 0
  },

  '.gridBodyContainer': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: '1 1 60%'
  },

  '.gridBody': {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    alignItems: 'stretch',
    position: 'static'
  },

  '.dayColumnsContainer': {
    overflow: 'scroll',
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1
  },

  '.dayColumnContainer': {
    minWidth: '100%',
    flex: 'none',
    display: 'inline-flex',
    verticalAlign: 'top',
    overflow: 'hidden',
    position: 'relative'
  },

  '.dayColumn': {
    minWidth: 150,
    width: `${100 / 7}%`,
    flex: 1,
    flexShrink: 0
  }
}));
