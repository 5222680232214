import {
  addMinutesToTimeOfDay,
  compareDays,
  compareTimeOfDays,
  dateToPBDate,
  dateToTimeOfDay,
  Day,
  dayToDate,
  dayToString,
  differenceInMinutesBetweenTimeOfDays,
  TimeOfDay,
  timeOfDaySpanOverlaps,
  timeOfDayToSeconds,
  Timestamp,
  timestampToTimeOfDay,
  urlForExternalSourceBadge,
  urlForWorkIconFromWork,
  WorkIconInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import {
  LoadableState,
  mergeLoadableStates,
  PlannerCalendarStore,
  PlannerDetailedCourseSectionsLoadable,
  WorkIconsLoadable,
  WorkLoadable
} from '@/stores';
import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { captureException } from '@sentry/react';
import { addDays, addWeeks, endOfWeek, isSameDay, startOfWeek, subWeeks } from 'date-fns';
import { chain, last, max, min, orderBy, times } from 'lodash';
import { action, computed, makeObservable, observable, override, reaction, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { arraysAreEqual, dateFromDayAndTimeOfDay } from '../../utils';
import { UpdatableViewModelState, UserDashboardCalendarItemPosition } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { AppPlannedWorkEditDayViewModel, PlannedWorkEditDayViewModel } from './PlannedWorkEditDayViewModel';
import { EditablePlannedWorkEditInfo, PlannedWorkEditInfo } from './PlannedWorkEditInfo';

interface InfoTimeSlot {
  startTime: TimeOfDay;
  endTime: TimeOfDay;
}

export interface PlannedWorkEditDisplayedInfo {
  dayIndex: number;
  info: PlannedWorkEditInfo;
}

export interface PlannedWorkEditViewModel extends UpdatableDialogViewModel {
  currentDate: Date;
  readonly pointsPerHour: number;
  readonly gridIncrement: number;
  readonly color: string;
  readonly dueDate: Date | undefined;
  readonly isDueAllDay: boolean;
  readonly editableInfo: EditablePlannedWorkEditInfo | undefined;
  readonly infos: PlannedWorkEditInfo[];
  readonly isApplying: boolean;
  readonly work: Work;
  readonly workIcon: WorkIconInfo;

  getDates(daysPerPage: number): Date[];
  roundVerticalOffset(offset: number): number;
  roundTimeOfDay(timeOfDay: TimeOfDay): TimeOfDay;
  timeOfDayFromVerticalOffset(offset: number): TimeOfDay;
  getHeightForTimeOfDays(start: TimeOfDay, end: TimeOfDay): number;
  verticalOffsetFromTimeOfDay(timeOfDay: TimeOfDay): number;
  getStartAndEndTimesForVerticalOffsets(
    startOffset: number,
    endOffset: number
  ): { startTime: TimeOfDay; endTime: TimeOfDay };
  getDateForHorizontalOffset(offset: number, width: number, dates: Date[]): Date | undefined;
  getViewModelForDay(day: Day): PlannedWorkEditDayViewModel;
  getDayIndexInDates(day: Day, dates: Date[]): number;
  adjustInfoForConflictIfNeeded(info: PlannedWorkEditInfo): PlannedWorkEditInfo;
  getInfoHasConflict(info: PlannedWorkEditInfo): boolean;

  saveEditableInfo(info: EditablePlannedWorkEditInfo): void;
  cancelEditableInfo(): void;
  editInfo(isNew: boolean, info: PlannedWorkEditInfo): void;
  updateInfo(newInfo: PlannedWorkEditInfo): void;
  deleteInfo(id: string): void;
  cancel(): Promise<void>;
  save(): Promise<void>;
  reset(): void;
}

export class AppPlannedWorkEditViewModel extends AppBaseUpdatableDialogViewModel implements PlannedWorkEditViewModel {
  @observable private _state: LoadableState = 'pending';
  @observable private _isApplying = false;
  @observable private _color = '';
  @observable private _currentDate: Date;
  @observable private _editableInfo: EditablePlannedWorkEditInfo | undefined;
  private _infos = observable.array<PlannedWorkEditInfo>();
  protected _viewModelByDay = observable.map<string, PlannedWorkEditDayViewModel>();
  private _originals: PlannedWorkEditInfo[] = [];
  private _calendarStore: PlannerCalendarStore;

  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.cancel()
  );

  private _resetButtonConfig = new BaseDialogActionButtonConfiguration(
    'secondary',
    'both',
    'right',
    'reset',
    'start',
    () => LocalizedStrings.plannedWork.edit.resetButtonLabel(),
    'outlined',
    async () => {
      this.reset();
      await Promise.resolve();
    }
  );

  @computed
  private get workLoadable(): WorkLoadable {
    return this._workStore.getWorkLoadable(this._workId);
  }

  @computed
  private get workIconsLoadable(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  constructor(
    private readonly _plannerId: string,
    private readonly _workId: string,
    private readonly _plannedWorkId: string | undefined,
    private readonly _onCancel: () => Promise<void>,
    private readonly _dismiss: (work: Work) => Promise<void>,
    private readonly _workStore = ServiceContainer.services.workStore,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _dateService = ServiceContainer.services.dateService,
    localization = ServiceContainer.services.localization
  ) {
    super(localization, _onCancel);
    makeObservable(this);
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    this._currentDate = _dateService.now;
    void this.loadData();

    reaction(
      () => this.currentDate,
      (d) => void this.fetchDays(d, false)
    );
  }

  readonly pointsPerHour = 60;

  @computed
  get work(): Work {
    return this.workLoadable.data;
  }

  @computed
  get workIcon(): WorkIconInfo {
    return this.getWorkIconInfo(this.work.iconId);
  }

  @computed
  get gridIncrement(): number {
    return this.pointsPerHour / 4;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isApplying;
    this._saveButtonConfig.isEnabled = !this._isApplying && this.canSave;
    this._saveButtonConfig.showLoading = this._isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @override
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    this._resetButtonConfig.isEnabled = !this._isApplying && this.hasChanges;
    return [this._resetButtonConfig];
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([this.workLoadable.state, this._state]);
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get hasData(): boolean {
    return this.workLoadable.hasData && this.workIconsLoadable.hasData;
  }

  @computed
  get hasChanges(): boolean {
    if (this._infos.length != this._originals.length) {
      return true;
    }

    return this._infos.some((info) => {
      if (info.shouldBeCreated) {
        return true;
      }

      const matchingOriginal = this._originals.find((i) => i.id === info.id);
      return matchingOriginal == null || !this.compareInfos(matchingOriginal, info);
    });
  }

  @computed
  get currentDate(): Date {
    return this._currentDate;
  }

  set currentDate(date: Date) {
    this._currentDate = date;
  }

  @computed
  get canSave(): boolean {
    return !this._isApplying && this.hasChanges;
  }

  @computed
  get infos(): PlannedWorkEditInfo[] {
    return this._infos;
  }

  @computed
  get editableInfo() {
    return this._editableInfo;
  }

  @computed
  get color(): string {
    return this._color;
  }

  @computed
  get dueDate(): Date | undefined {
    return this.workLoadable.data.dueTime?.toDate();
  }

  @computed
  get isDueAllDay(): boolean {
    return this.workLoadable.data.isDueAllDay;
  }

  @action
  saveEditableInfo(info: EditablePlannedWorkEditInfo) {
    const newInfo: PlannedWorkEditInfo = {
      day: info.day,
      startTime: info.startTime,
      endTime: info.endTime,
      shouldBeCreated: info.isNew,
      id: info.id,
      stepIds: info.stepIds
    };

    const existingInfoIndex = this._infos.findIndex((i) => i.id === newInfo.id);
    if (existingInfoIndex >= 0) {
      this._infos[existingInfoIndex] = newInfo;
    } else {
      this._infos.push(newInfo);
    }

    this._editableInfo = undefined;
  }

  @action
  cancelEditableInfo() {
    this._editableInfo = undefined;
  }

  @action
  editInfo(isNew: boolean, info: PlannedWorkEditInfo) {
    this._editableInfo = new EditablePlannedWorkEditInfo(isNew, info);
  }

  @action
  updateInfo(newInfo: PlannedWorkEditInfo) {
    this._editableInfo = undefined;
    const existingInfoIndex = this._infos.findIndex((i) => i.id === newInfo.id);
    if (existingInfoIndex >= 0) {
      this._infos[existingInfoIndex] = newInfo;
    }
  }

  @action
  deleteInfo(id: string) {
    const existingInfoIndex = this._infos.findIndex((i) => i.id === id);
    if (existingInfoIndex >= 0) {
      this._infos.splice(existingInfoIndex, 1);
    }

    this._editableInfo = undefined;
  }

  cancel() {
    return this._onCancel();
  }

  @action
  async save() {
    if (!this.canSave) {
      return;
    }

    this._isApplying = true;
    this._error = undefined;

    try {
      let syncToken = this.work.syncToken;
      syncToken = await this.createOrUpdatePlannedWorks(syncToken);
      void (await this.deletePlannedWorks(syncToken));

      await runInAction(async () => {
        this._isApplying = false;
        await this.dismiss();
      });

      // Reloading data after dismissing, otherwise it leads to a new modal presented for a brief instant as the
      // planner lists have been re-rendered.
      void Promise.all([
        this._plannerStore.fetchPlannerContents(this._plannerId),
        this._workStore.getWorkLoadable(this.work.id).fetch(true)
      ]);
    } catch (e) {
      captureException(e);
      const error = e as Error;
      const strings = this._localization.localizedStrings.work.details;

      runInAction(() => {
        this._error = strings.planErrorMessage(error.message);
        this._isApplying = false;
      });
    }
  }

  @action
  reset() {
    this._infos.replace(this._originals);
  }

  getDates(daysPerPage: number): Date[] {
    if (daysPerPage === 7) {
      const firstDayOfWeek = startOfWeek(this.currentDate);
      return times(7).map((_, i) => addDays(firstDayOfWeek, i));
    }

    return times(daysPerPage).map((_, i) => addDays(this.currentDate, i));
  }

  roundVerticalOffset(offset: number): number {
    const timeOfDay = this.roundTimeOfDay(this.timeOfDayFromVerticalOffset(offset));
    return this.verticalOffsetFromTimeOfDay(timeOfDay);
  }

  roundTimeOfDay(timeOfDay: TimeOfDay): TimeOfDay {
    return new TimeOfDay({ hours: timeOfDay.hours, minutes: timeOfDay.minutes - (timeOfDay.minutes % 15) });
  }

  timeOfDayFromVerticalOffset(offset: number): TimeOfDay {
    const totalMinutes = (60 * offset) / this.pointsPerHour;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return new TimeOfDay({ hours, minutes });
  }

  verticalOffsetFromTimeOfDay(timeOfDay: TimeOfDay): number {
    return this.pointsPerHour * timeOfDay.hours + this.pointsPerHour * (timeOfDay.minutes / 60);
  }

  getStartAndEndTimesForVerticalOffsets(
    startOffset: number,
    endOffset: number
  ): { startTime: TimeOfDay; endTime: TimeOfDay } {
    const startTimeOffset = min([startOffset, endOffset]) ?? 0;
    const endTimeOffset = max([startOffset, endOffset]) ?? 0;
    const startTime = this.roundTimeOfDay(this.timeOfDayFromVerticalOffset(startTimeOffset));
    let endTime = this.roundTimeOfDay(this.timeOfDayFromVerticalOffset(endTimeOffset));

    if (compareTimeOfDays(startTime, endTime) === 0) {
      // Returning a time interval of 15 if none was passed.
      endTime = addMinutesToTimeOfDay(endTime, 15);
    }

    return { startTime, endTime };
  }

  getHeightForTimeOfDays(start: TimeOfDay, end: TimeOfDay): number {
    return Math.abs(this.verticalOffsetFromTimeOfDay(end) - this.verticalOffsetFromTimeOfDay(start));
  }

  getDateForHorizontalOffset(offset: number, width: number, dates: Date[]): Date | undefined {
    if (offset < 0) {
      return dates[0];
    } else if (offset > width) {
      return last(dates);
    }

    const columnSize = width / dates.length;
    const columnIndex = Math.floor(offset / columnSize);
    return dates[columnIndex];
  }

  getViewModelForDay(day: Day): PlannedWorkEditDayViewModel {
    const key = dayToString(day);
    const existing = this._viewModelByDay.get(key);

    if (existing != null) {
      return existing;
    }

    const viewModel = this.makeDayViewModel(day, key);
    this._viewModelByDay.set(key, viewModel);
    return viewModel;
  }

  adjustInfoForConflictIfNeeded(info: PlannedWorkEditInfo): PlannedWorkEditInfo {
    const conflictingInfos = this.getSortedConflictingInfos(info, this.infos);

    if (conflictingInfos.length === 0) {
      return info;
    }

    // Finding all time slot already used between info start and end time.
    const timeSlots = this.resolveTimeSlotsForInfos(conflictingInfos);
    // Creating timeslots where there are no info.
    const timeSlotsAvailable = this.resolveAvailableTimeSlots(info, timeSlots);
    // No magic way to determine which time slot to select, so choosing the longest one.
    const longestTimeSlot = this.findLongestTimeSlot(timeSlotsAvailable);
    // Returning an updated info using the non-conflicting time slot
    return longestTimeSlot != null ? { ...info, ...longestTimeSlot } : info;
  }

  getDayIndexInDates(day: Day, dates: Date[]): number {
    const date = dayToDate(day);
    return dates.findIndex((d) => isSameDay(d, date));
  }

  getInfoHasConflict(info: PlannedWorkEditInfo): boolean {
    return this.getSortedConflictingInfos(info, this.infos).length > 0;
  }

  async reloadData(): Promise<void> {
    await this.loadData();
  }

  private async fetchDays(date: Date, force: boolean): Promise<void> {
    const startDate = startOfWeek(subWeeks(date, 2));
    const endDate = endOfWeek(addWeeks(date, 2));
    await this._calendarStore.fetchDays(dateToPBDate(startDate), dateToPBDate(endDate), force);
  }

  private async loadData() {
    runInAction(() => (this._state = 'pending'));

    try {
      await Promise.all([this.workLoadable.fetch(true), this.workIconsLoadable.fetch(false)]);

      if (!this.workLoadable.hasData) {
        throw new Error('Failed to load work.');
      }

      const work = this.workLoadable.data;
      const plannedWork = work.plannedWorks.find((p) => p.id === this._plannedWorkId);
      const color = this.courseSectionsLoadable.data.get(work.courseSectionId)?.courseSection?.color;

      const originals = chain(work.plannedWorks)
        .map((pw) => this.infoForPlannedWork(pw))
        .compact()
        .value();

      runInAction(() => {
        this._originals = originals;
        this._infos.replace(originals);
        this._currentDate = plannedWork?.timeSlot?.startTime?.toDate() ?? this._dateService.now;
        this._state = 'fulfilled';
        this._color = color ?? '';
      });
    } catch (e) {
      runInAction(() => (this._state = e as Error));
    }
  }

  private compareInfos(first: PlannedWorkEditInfo, second: PlannedWorkEditInfo): boolean {
    return (
      compareDays(first.day, second.day) === 0 &&
      compareTimeOfDays(first.startTime, second.startTime) === 0 &&
      compareTimeOfDays(first.endTime, second.endTime) === 0 &&
      arraysAreEqual(first.stepIds, second.stepIds)
    );
  }

  private infoForPlannedWork(plannedWork: PlannedWork | undefined): PlannedWorkEditInfo | undefined {
    if (plannedWork?.timeSlot?.startTime == null || plannedWork?.timeSlot?.endTime == null) {
      return undefined;
    }

    const day = dateToPBDate(plannedWork.timeSlot.startTime.toDate());
    const startTime = dateToTimeOfDay(plannedWork.timeSlot.startTime.toDate());
    const endTime = dateToTimeOfDay(plannedWork.timeSlot.endTime.toDate());
    return { shouldBeCreated: false, day, startTime, endTime, id: plannedWork.id, stepIds: plannedWork.workStepIds };
  }

  private async createOrUpdatePlannedWorks(syncToken: bigint): Promise<bigint> {
    let newSyncToken = syncToken;

    for (const info of this.infos) {
      const work = await this.createOrUpdatePlannedWorkForInfo(info, newSyncToken);
      newSyncToken = work.syncToken;
    }

    return newSyncToken;
  }

  private async createOrUpdatePlannedWorkForInfo(info: PlannedWorkEditInfo, syncToken: bigint): Promise<Work> {
    const startTime = dateFromDayAndTimeOfDay(info.day, info.startTime);
    const endTime = dateFromDayAndTimeOfDay(info.day, info.endTime);
    const timeSlot = new TimeSlot({ startTime: Timestamp.fromDate(startTime), endTime: Timestamp.fromDate(endTime) });

    return await (info.shouldBeCreated
      ? this._workStore.planWork(this._workId, timeSlot, info.stepIds, syncToken)
      : this._workStore.updatePlannedWork(info.id, this._workId, timeSlot, info.stepIds, syncToken));
  }

  private async deletePlannedWorks(syncToken: bigint): Promise<bigint> {
    let newSyncToken = syncToken;

    for (const info of this._originals) {
      const isDeleted = !this.infos.some((i) => i.id === info.id);

      if (isDeleted) {
        const work = await this._workStore.cancelPlannedWork(info.id, this._workId, newSyncToken);
        newSyncToken = work.syncToken;
      }
    }

    return newSyncToken;
  }

  private makeDayViewModel(day: Day, key: string): PlannedWorkEditDayViewModel {
    return new AppPlannedWorkEditDayViewModel(
      day,
      this._workId,
      this._plannerId,
      () => this._calendarStore.days.get(key),
      (startTime, endTime) => this.getPositionForItem(startTime, endTime),
      (iconId) => this.getWorkIconInfo(iconId),
      this._plannerStore
    );
  }

  protected getPositionForItem(
    startTime: Timestamp | undefined,
    endTime: Timestamp | undefined
  ): UserDashboardCalendarItemPosition {
    if (startTime == null || endTime == null) {
      return { top: 0, height: 0 };
    }

    const top = this.verticalOffsetFromTimeOfDay(timestampToTimeOfDay(startTime));
    const bottom = this.verticalOffsetFromTimeOfDay(timestampToTimeOfDay(endTime));
    // Removing 1 so that periods that end at the same time another one begins have a slight offset between.
    const height = Math.abs(bottom - top) - 1;
    return { top, height };
  }

  private getWorkIconInfo(iconId: string): WorkIconInfo {
    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(iconId) ?? icons.iconsById.get(icons.defaultIconId)!;

    return {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: urlForWorkIconFromWork(icon, this.work, 'light'),
      darkUrl: urlForWorkIconFromWork(icon, this.work, 'dark'),
      externalBadgeUrl: urlForExternalSourceBadge(this.work.externalSource?.sourceName, icons)
    };
  }

  private getSortedConflictingInfos(info: PlannedWorkEditInfo, infos: PlannedWorkEditInfo[]): PlannedWorkEditInfo[] {
    const conflictingInfos = infos.filter(
      (i) =>
        i.id !== info.id &&
        compareDays(i.day, info.day) === 0 &&
        timeOfDaySpanOverlaps(info.startTime, info.endTime, i.startTime, i.endTime, false)
    );

    return orderBy(conflictingInfos, [(i) => timeOfDayToSeconds(i.startTime), (i) => timeOfDayToSeconds(i.endTime)]);
  }

  private resolveTimeSlotsForInfos(infos: PlannedWorkEditInfo[]): InfoTimeSlot[] {
    const timeSlots: InfoTimeSlot[] = [];

    for (let i = 0; i < infos.length; i++) {
      const conflict = infos[i];
      const startTime = conflict.startTime;
      let endTime = conflict.endTime;

      for (let j = i; j < infos.length; j++) {
        const other = infos[j];

        if (compareTimeOfDays(endTime, other.startTime) >= 0) {
          endTime = other.endTime;
          i = j;
          break;
        }
      }

      timeSlots.push({ startTime, endTime });
    }

    return timeSlots;
  }

  private resolveAvailableTimeSlots(info: PlannedWorkEditInfo, existingTimeSlots: InfoTimeSlot[]): InfoTimeSlot[] {
    const timeSlotsAvailable: InfoTimeSlot[] = [];

    if (compareTimeOfDays(info.startTime, existingTimeSlots[0].startTime) < 0) {
      timeSlotsAvailable.push({ startTime: info.startTime, endTime: existingTimeSlots[0].startTime });
    }

    for (let i = 0; i < existingTimeSlots.length; i++) {
      const timeSlot = existingTimeSlots[i];
      const startTime = timeSlot.endTime;
      const nextTimeSlot = existingTimeSlots.at(i + 1);

      if (nextTimeSlot != null) {
        timeSlotsAvailable.push({ startTime: startTime, endTime: nextTimeSlot.startTime });
      } else if (compareTimeOfDays(info.endTime, timeSlot.endTime) > 0) {
        timeSlotsAvailable.push({ startTime: timeSlot.endTime, endTime: info.endTime });
      }
    }

    return timeSlotsAvailable;
  }

  private findLongestTimeSlot(timeSlots: InfoTimeSlot[]): InfoTimeSlot | undefined {
    return timeSlots.reduce(
      (prev, next) => {
        if (prev == null) {
          return next;
        }

        const prevDuration = Math.abs(differenceInMinutesBetweenTimeOfDays(prev.startTime, prev.endTime));
        const nextDuration = Math.abs(differenceInMinutesBetweenTimeOfDays(next.startTime, next.endTime));
        return prevDuration >= nextDuration ? prev : next;
      },
      undefined as InfoTimeSlot | undefined
    );
  }
}
