import { DayOfWeek, SortedDayOfWeek } from '@/models';
import { DayAnnotation } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/day_annotation_pb';
import { useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Droppable } from '../../../../utils';
import { ScheduleCycleSpecialDaysTable } from './ScheduleCycleSpecialDaysTable';
import { ScheduleCycleSpecialDaysTableCell } from './ScheduleCycleSpecialDaysTableCell';

export interface ScheduleCycleSpecialDaysDayOfWeekTableProps {
  sx?: SxProps;
  className?: string;
  selectedDayOfWeek?: DayOfWeek;
  onDayOfWeekSelection: (dow: DayOfWeek) => void;
  dayAnnotationsForDayOfWeek: (dow: number) => DayAnnotation[];
}

export const ScheduleCycleSpecialDaysDayOfWeekTable = observer(
  ({
    sx = [],
    className,
    selectedDayOfWeek,
    onDayOfWeekSelection,
    dayAnnotationsForDayOfWeek
  }: ScheduleCycleSpecialDaysDayOfWeekTableProps) => {
    const theme = useTheme();

    return (
      <ScheduleCycleSpecialDaysTable sx={sx} className={className}>
        <tbody>
          <tr>
            {SortedDayOfWeek.map((dow) => {
              const annotations = dayAnnotationsForDayOfWeek(dow);

              return (
                <Droppable key={`day-of-week-day-cell-${dow}`} id={`dow/${dow}`} acceptedTypes={['special-day']}>
                  {(setNodeRef, isOver) => (
                    <td
                      ref={setNodeRef}
                      className={clsx(selectedDayOfWeek === dow && 'selected')}
                      style={{
                        borderStyle: 'solid',
                        borderWidth: 2,
                        borderColor: isOver ? theme.palette.primary.main : 'transparent'
                      }}
                    >
                      <ScheduleCycleSpecialDaysTableCell
                        label={LocalizedStrings.dateTime.dayOfWeekSingleLetterTitle[dow]()}
                        cycleDayLabel={undefined}
                        onClick={() => onDayOfWeekSelection(dow)}
                        annotations={annotations}
                      />
                    </td>
                  )}
                </Droppable>
              );
            })}
          </tr>
        </tbody>
      </ScheduleCycleSpecialDaysTable>
    );
  }
);
