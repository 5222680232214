import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { computed, makeObservable, observable } from 'mobx';
import { UserService } from '../contracts';

export class AppUserService implements UserService {
  @observable private _currentUser?: UserProfile;

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentUser(): UserProfile | undefined {
    return this._currentUser;
  }

  set currentUser(value: UserProfile | undefined) {
    this._currentUser = value;
  }

  @computed
  get isLoggedIn(): boolean {
    return this._currentUser != null;
  }
}
