import { WorkTransportService } from '@/transports';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { makeObservable } from 'mobx';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type CourseSectionNotesLoadable = LoadableValue<Note[]>;

export class AppCourseSectionNotesLoadable extends BaseLoadableValue<Note[]> implements CourseSectionNotesLoadable {
  constructor(
    private readonly _plannerId: string,
    private readonly _courseSectionId: string,

    private readonly _workTransport: WorkTransportService
  ) {
    super();
    makeObservable(this);
  }

  protected async loadData(): Promise<Note[]> {
    return await this._workTransport.getNotes(this._plannerId, this._courseSectionId, false);
  }
}
