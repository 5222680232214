import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { action, computed, makeObservable } from 'mobx';
import {
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';
import {
  AppBaseUseSharingInvitationCodeViewModel,
  BaseUseSharingInvitationCodeViewModel,
  UserSharingInvitationCodeResult
} from './BaseUseSharingInvitationCodeViewModel';

export interface UseSharingInvitationCodeDialogViewModel
  extends BaseUseSharingInvitationCodeViewModel,
    StaticDialogViewModel {
  cancel(): Promise<void>;
}

export class AppUseSharingInvitationCodeDialogViewModel
  extends AppBaseUseSharingInvitationCodeViewModel
  implements UseSharingInvitationCodeDialogViewModel
{
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () =>
    this.apply(true)
  );
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.cancel()
  );

  readonly kind = 'static' as const;
  readonly supplementaryActions: DialogActionButtonConfiguration[] = [];

  constructor(
    plannerId: string | undefined,
    onSuccess: (result: UserSharingInvitationCodeResult) => Promise<void>,
    private _onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(plannerId, onSuccess, localization, plannerStore, userStore);
    makeObservable(this);
    this._saveButtonConfig.title = () => localization.localizedStrings.sharingInvitationCode.useCodeButton;
  }

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isApplying;
    this._saveButtonConfig.isEnabled = this.canApply;
    this._saveButtonConfig.showLoading = this._isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  cancel() {
    return this._onCancel();
  }

  @action
  clearError() {
    this._error = undefined;
  }

  dismiss() {
    return this._onCancel();
  }
}
