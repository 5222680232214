import { useServices } from '@/hooks';
import { PopoverProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { OptionsPopover, OptionsPopoverItem, OptionsPopoverSection } from '../../../utils';

export interface UserDashboardCalendarDayOptionsPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
}

export const UserDashboardCalendarDayOptionsPopover = observer((props: UserDashboardCalendarDayOptionsPopoverProps) => {
  const { settings } = useServices();
  const options = settings.calendarDayOptions;
  const strings = LocalizedStrings.calendar.day.options;

  return (
    <OptionsPopover {...props} title={strings.title()}>
      <OptionsPopoverSection>
        <OptionsPopoverItem
          title={strings.alwaysShowPastEventsOption()}
          isSelected={options.alwaysShowCurrentDatePastItems}
          onClick={() =>
            (settings.calendarDayOptions = {
              ...options,
              alwaysShowCurrentDatePastItems: !options.alwaysShowCurrentDatePastItems
            })
          }
        />
      </OptionsPopoverSection>

      <OptionsPopoverSection title={strings.showOptionsSectionTitle()}>
        <OptionsPopoverItem
          title={strings.expandWorkDetailsOption()}
          isSelected={options.expandWorkDetails}
          onClick={() =>
            (settings.calendarDayOptions = {
              ...options,
              expandWorkDetails: !options.expandWorkDetails
            })
          }
        />

        <OptionsPopoverItem
          title={strings.showPeriodLabels()}
          isSelected={settings.calendarShowPeriodLabels}
          onClick={() => (settings.calendarShowPeriodLabels = !settings.calendarShowPeriodLabels)}
        />

        <OptionsPopoverItem
          title={strings.showFreePeriods()}
          isSelected={settings.calendarShowFreePeriods}
          onClick={() => (settings.calendarShowFreePeriods = !settings.calendarShowFreePeriods)}
        />
      </OptionsPopoverSection>
    </OptionsPopover>
  );
});
