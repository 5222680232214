import EmptyListIcon from '@mui/icons-material/SchoolRounded';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../../hooks';
import { EmptyListIndicator } from '../../../utils';

export const PlannerDoneListEmptyIndicator = observer(() => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <EmptyListIndicator
      title={strings.doneEmptyListTitle}
      subtitle={strings.upcomingEmptyListSubtitle}
      renderIcon={(className) => <EmptyListIcon className={className} />}
    />
  );
});
