import { useServices } from '@/hooks';
import { Bullet, Root as ChartRoot, Color, DataProcessor, Label, percent } from '@amcharts/amcharts5';
import am5locales_en_US from '@amcharts/amcharts5/locales/en_US';
import am5locales_fr_FR from '@amcharts/amcharts5/locales/fr_FR';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated';
import MaterialTheme from '@amcharts/amcharts5/themes/Material';
import { XYChart as AMXYChart, XYCursor } from '@amcharts/amcharts5/xy';
import { Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef } from 'react';
import { XYChartAxis, XYChartKind, makeAMXYChartAxis, makeXYChartSeries } from '../../utils';

export interface XYChartProps<T> {
  className?: string;
  height: number;
  data: T[];
  xAxis: XYChartAxis<T>;
  yAxis: XYChartAxis<T>;
  kind: XYChartKind;
  title?: string;
  titleAlignment?: 'left' | 'right' | 'center' | 'start' | 'end';
}

export const XYChart = observer(
  <T,>({ className, data, height, xAxis, yAxis, title, titleAlignment, kind }: XYChartProps<T>) => {
    const { localization } = useServices();
    const theme = useTheme();

    const chartRef = useRef<AMXYChart | undefined>(undefined);

    const resolvedData: T[] = useMemo(
      () =>
        data.map((item) => ({
          ...item,
          [xAxis.dataKey]: xAxis.getValue != null ? xAxis.getValue(item) : item[xAxis.dataKey],
          [yAxis.dataKey]: yAxis.getValue != null ? yAxis.getValue(item) : item[yAxis.dataKey]
        })),
      [data, xAxis, yAxis]
    );

    const updateChart = () => {
      if (chartRef.current != null) {
        chartRef.current?.series.getIndex(0)?.data.setAll(resolvedData);
      }
    };

    const updateLocale = () => {
      if (chartRef.current != null) {
        chartRef.current.root.locale = localization.currentLocale === 'en' ? am5locales_en_US : am5locales_fr_FR;
      }
    };

    useEffect(() => {
      const root = ChartRoot.new('chartDiv');
      const chart = root.container.children.push(
        AMXYChart.new(root, {
          height,
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          maxTooltipDistance: 0
        })
      );

      const chartXAxis = makeAMXYChartAxis('x', xAxis, root);
      chartXAxis.template?.setAll({
        showTooltipOn: 'hover',
        start: 0.5,
        end: 0.5
      });
      chartXAxis.get('renderer').labels.template.setAll({
        fontSize: theme.typography.body2.fontSize
      });
      chartXAxis.get('renderer').grid.template.setAll({
        stroke: Color.fromString(theme.palette.text.primary)
      });
      chart.xAxes.push(chartXAxis);

      const chartYAxis = makeAMXYChartAxis('y', yAxis, root);
      chartYAxis.get('renderer').labels.template.setAll({
        fontSize: theme.typography.body2.fontSize
      });
      chart.yAxes.push(chartYAxis);
      chartYAxis.get('renderer').grid.template.setAll({
        stroke: Color.fromString(theme.palette.text.primary)
      });

      const series = makeXYChartSeries(
        xAxis,
        chartXAxis,
        yAxis,
        chartYAxis,
        kind,
        root,
        theme,
        localization.currentLocale
      );
      series.data.processor = DataProcessor.new(root, {
        numericFields: ['value'],
        dateFields: ['date']
      });
      chart.series.push(series);

      chart.set(
        'cursor',
        XYCursor.new(root, {
          snapToSeries: [series],
          snapToSeriesBy: 'x',
          xAxis: chartXAxis,
          behavior: 'none'
        })
      );

      const cursor = chart.get('cursor')!;
      cursor.lineY.setAll({
        visible: false
      });
      cursor.lineX.setAll({
        stroke: Color.fromString(theme.palette.text.primary)
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      series.bullets.push((root: ChartRoot) => {
        return Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: Label.new(root, {
            text: '{bullet}',
            centerX: percent(50),
            centerY: percent(90),
            textAlign: 'center',
            populateText: true
          })
        });
      });

      chartRef.current = chart;

      root.setThemes([AnimatedTheme.new(root), MaterialTheme.new(root)]);
      root.interfaceColors.set('text', Color.fromString(theme.palette.text.primary));
      root.interfaceColors.set('primaryButton', Color.fromString(theme.palette.primary.main));
      root.interfaceColors.set('primaryButtonDown', Color.lighten(Color.fromString(theme.palette.primary.main), 0.1));
      root.interfaceColors.set('primaryButtonHover', Color.lighten(Color.fromString(theme.palette.primary.main), 0.1));
      root.interfaceColors.set('primaryButtonActive', Color.lighten(Color.fromString(theme.palette.primary.main), 0.1));
      updateChart();
      updateLocale();
      return () => root.dispose();
    }, [theme]);

    useEffect(updateChart, [data]);
    useEffect(updateLocale, [localization.currentLocale]);

    return (
      <Stack className={className} spacing={1}>
        {title != null && (
          <Typography
            sx={{
              fontWeight: '500',
              px: 2,
              textAlign: titleAlignment
            }}
          >
            {title}
          </Typography>
        )}
        <div id="chartDiv" style={{ height }} />
      </Stack>
    );
  }
);
