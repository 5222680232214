import { action, computed, makeObservable, observable } from 'mobx';
import { reset as ResetMockDate, set as SetMockDate } from 'mockdate';
import { DateService } from '../contracts';

export class AppDateService implements DateService {
  @observable private _customNow: Date | undefined;

  constructor() {
    makeObservable(this);
  }

  get now(): Date {
    return this._customNow ?? new Date();
  }

  get realNow(): Date {
    return new Date();
  }

  @computed
  get isMocked(): boolean {
    return this._customNow != null;
  }

  @action
  mockCurrentDate(date: Date) {
    this._customNow = date;
    SetMockDate(date);
  }

  @action
  unmockCurrentDate() {
    this._customNow = undefined;
    ResetMockDate();
  }
}
