import { computed, makeObservable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { Loadable, SchoolDataStore, SchoolParticipationCodeLoadable } from '../../stores';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

export interface AdminSchoolParticipationCodesViewModel extends UpdatableViewModel {
  readonly teacherCode: string;
}

export class AppAdminSchoolParticipationCodesViewModel
  extends BaseUpdatableViewModel
  implements AdminSchoolParticipationCodesViewModel
{
  constructor(
    private readonly _schoolId: string,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get teacherCodeLoadable(): SchoolParticipationCodeLoadable {
    return this._schoolStore.getTeacherParticipationCode(this._schoolId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.teacherCodeLoadable];
  }

  @computed
  get teacherCode(): string {
    return this.teacherCodeLoadable.data;
  }
}
